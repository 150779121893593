import {
    PRODUCTFABRICSTRUCTURE_LOADING,
    RETRIEVE_PRODUCTFABRICSTRUCTURE,
    DELETE_PRODUCTFABRICSTRUCTURE,
} from "./types";

import ProductFabricStructureService from "../services/Master/ProductFabricStructure";

const ProductFabricStructureLoading = (isStatus) => ({
    type: PRODUCTFABRICSTRUCTURE_LOADING,
    payload: isStatus,
});

// Action to retrieve the product fabric structures
export const retrieveProductFabricStructure = () => async (dispatch) => {
    try {
        dispatch(ProductFabricStructureLoading(true));
        const res = await ProductFabricStructureService.GetProductFabricStructure();
        dispatch({
            type: RETRIEVE_PRODUCTFABRICSTRUCTURE,
            payload: res.data,
        });
        dispatch(ProductFabricStructureLoading(false));
    } catch (err) {
        console.error("Error fetching Product Fabric Structures:", err);
        dispatch(ProductFabricStructureLoading(false));
    }
};

// Action to delete a specific product fabric structure by ID
export const deleteProductFabricStructure = (structureID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PRODUCTFABRICSTRUCTURE,
            payload: { structureID },
        });
    } catch (err) {
        console.error("Error deleting Product Fabric Structure:", err);
    }
};
