import React, { Fragment, useState, useMemo, useEffect } from "react";
import Reactselect from 'react-select';
import FinalinspectionService from "../../../services/FinalInspection/FinalinspectionService";
import FinalinspecUpload from "../../Common/FinalinspecUpload";
import QcInspection from '../../../services/QCInspection/QcInspection';
import Lightbox from "react-image-lightbox"
import Nodify from "../../Common/ReactNotification";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

const AddQCStyleItemTab = ({ props, setrenderval, StrikeofflistVal, TrimListVal, isTrimvalidation, rerenderdep }) => {
    ;
    const [getStrikeoffInputFeild, SetStrikeoffInputFeild] = useState([]);
    const [dropDownlist, setDropdownlist] = useState([])
    const [artworksExpandState, setartworksExpandState] = useState({});
    const [artworksexpandedRows, setartworksExpandedRows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [getImagename, setImagename] = useState();
    const [isOpenUpl, setIsOpenUpl] = useState(false);
    const [getImagenameUpl, setImagenameUpl] = useState();
    const [gettrimInputField, SettrimInputField] = useState([]);
    const [trimExpandState, settrimExpandState] = useState({});
    const [trimexpandedRows, settrimExpandedRows] = useState([]);
    const [isOpenTrim, setIsOpenTrim] = useState(false);
    const [getImagenameTrim, setImagenameTrim] = useState();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const imagevalues = [...getStrikeoffInputFeild]
    var TrimValues = [...gettrimInputField]

    useEffect(() => {
        setSubmitted(isTrimvalidation);
    }, [isTrimvalidation === true]);
    const QcinpsectionId = JSON.parse(localStorage.getItem("QCInspectionID"));
    useEffect(() => {
        showLoader();

        let Multiselecteddate = [];
        let CommonResult = [];
        let StrikeOffEditSaveData = []
        let QATrimEditSaveData = []
        Multiselecteddate = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1);
        if (props.location.state.params.TaskStatus) {
            if (props.location.state.params.TaskStatus === 1 ? QcinpsectionId !== null : props.location.state.params.TNAQCInspectionID !== 0) {
                QcInspection.getAllQcInfoList(3, props.location.state.params.details.tnaid, props.location.state.params.TaskStatus === 1 ? QcinpsectionId : props.location.state.params.TNAQCInspectionID).then((res) => {
                    if (res.data.qcStrikeoffDetails) {
                        if (res.data.qcStrikeoffDetails.length > 0) {
                            res.data.qcStrikeoffDetails.map((x, index) => {

                                StrikeOffEditSaveData.push({
                                    TNAQCStrikeoffID: x.tnaqcStrikeoffID,
                                    QualityID: x.qualityID,
                                    PositionID: x.positionID,
                                    FindingsRemarks: x.findingsRemarks,
                                    StrikeOffName: x.strikeOffName,
                                    SkuName: x.skuName,
                                    MainIndex: index,
                                    ImagesList: []

                                })
                                res.data.qcStrikeoffSubDetails.filter(child => child.mainindex === x.mainindex).map((Child, childindex) => {
                                    StrikeOffEditSaveData[index].ImagesList.push({
                                        TNAQCStrikeoffSubID: Child.tnaqcStrikeoffSubID,
                                        //UploadedImagePath: "",
                                        skuFrontPicPath: Child.expectedFilepath,
                                        MainIndex: index,
                                        ExpectedFilename: Child.expectedFilename,
                                        SubIndex: childindex,
                                        StrikeoffImagesList: []
                                    })
                                    res.data.qcStrikeoffImagesDetails.filter(subchild => subchild.mainindex === Child.mainindex && subchild.subindex === Child.subindex).map((subchild, subchildindex) => {
                                        StrikeOffEditSaveData[index].ImagesList[childindex].StrikeoffImagesList.push({
                                            TNAQcInspecStrikeOffImagesID: subchild.tnaQcInspecStrikeOffImagesID,
                                            UploadedImagePath: subchild.uploadedImagePath,
                                            MainIndex: index,
                                            SubIndex: childindex,
                                            ImageIndex: subchildindex
                                        })
                                    })
                                })
                                // mainindex++
                            })
                            let uniquevalues = [...new Map(StrikeOffEditSaveData.map(item =>
                                [item['StrikeOffName'], item])).values()];
                            SetStrikeoffInputFeild(uniquevalues)
                            StrikeofflistVal(uniquevalues)
                        } else {
                            let StrikeOffSaveData = []
                            FinalinspectionService.GetFinalTrimStrikeoffList(1, props.location.state.params.styleID, '0').then((res) => {
                                // Multiselecteddate.map((y, N) => {
                                let mainindex = 0;
                                res.data.finalInsStrikeoffNamelist.map((x, index) => {
                                    // Split the skuName into an array of parts
                                    const splitSkuNames = x.skuName.split(',');

                                    // Find matching skuNames between Multiselecteddate and splitSkuNames
                                    const matchedSkuNames = splitSkuNames.filter(part =>
                                        Multiselecteddate.some(m => m.skuName.trim() === part.trim())
                                    );

                                    // Proceed only if there are matches
                                    if (matchedSkuNames.length > 0) {
                                        // Combine matched skuNames into a single string for StrikeOffName
                                        const matchedSkuNameString = matchedSkuNames.join(',');

                                        StrikeOffSaveData.push({
                                            TNAQCStrikeoffID: 0,
                                            QualityID: 0,
                                            PositionID: 0,
                                            FindingsRemarks: "",
                                            StrikeOffName: `${x.strikeoffFinalInspe} (${matchedSkuNameString})`,
                                            SkuName: matchedSkuNameString, // Include only matched skuNames
                                            MainIndex: mainindex,
                                            ImagesList: []
                                        });

                                        // Filter and process child data based on matched skuNames
                                        res.data.finalInsStrikeoffSkuImageList
                                            .filter(child =>
                                                matchedSkuNames.includes(child.skuName) && child.strikeoffID === x.strikeoffID
                                            )
                                            .map((Child, childindex) => {
                                                StrikeOffSaveData[mainindex].ImagesList.push({
                                                    TNAQCStrikeoffSubID: 0,
                                                    skuFrontPicPath: Child.strikeoffLogoPath,
                                                    MainIndex: mainindex,
                                                    ExpectedFilename: Child.strikeoffLogoName,
                                                    SubIndex: childindex,
                                                    StrikeoffImagesList: []
                                                });

                                                StrikeOffSaveData[mainindex].ImagesList[childindex].StrikeoffImagesList.push({
                                                    TNAQcInspecStrikeOffImagesID: 0,
                                                    UploadedImagePath: "",
                                                    MainIndex: mainindex,
                                                    SubIndex: childindex,
                                                    ImageIndex: 0
                                                });
                                            });

                                        mainindex++;
                                    }
                                });

                                //  })

                                let uniquevalues = [...new Map(StrikeOffSaveData.map(item =>
                                    [item['StrikeOffName'], item])).values()];
                                SetStrikeoffInputFeild(uniquevalues)
                                StrikeofflistVal(uniquevalues)
                                hideLoader();
                                //artWorkscallback(uniquevalues)
                            })
                        }
                    }
                    ;
                    if (res.data.qcTrimDetails) {
                        if (res.data.qcTrimDetails.length > 0) {
                            res.data.qcTrimDetails.map((x, index) => {
                                QATrimEditSaveData.push({
                                    TNAQCInspecTrimID: x.tnaqcInspecTrimID,
                                    TrimQualityID: x.trimQualityID,
                                    TrimPositionID: x.trimPositionID,
                                    TrimFindingsRemarks: x.trimFindingsRemarks,
                                    TrimName: x.trimName,
                                    SkuName: x.skuName,
                                    MainIndex: index,
                                    ImagesList: []
                                })
                                res.data.qcTrimSubDetails.filter(child => child.mainindex === x.mainindex).map((Child, childindex) => {
                                    QATrimEditSaveData[index].ImagesList.push({
                                        TNAQCInspecTrimSubID: Child.tnaqcStrikeoffSubID,
                                        //UploadedImagePath: "",
                                        skuFrontPicPath: Child.expectedFilepath,
                                        MainIndex: index,
                                        ExpectedFilename: Child.expectedFilename,
                                        SubIndex: childindex,
                                        TrimimagesList: []
                                    })
                                    res.data.qcTrimImagesDetails.filter(subchild => subchild.mainindex === x.mainindex && subchild.subindex === Child.subindex).map((subChild, subchildindex) => {
                                        QATrimEditSaveData[index].ImagesList[childindex].TrimimagesList.push({
                                            TNAQCInspecTrimImagesID: subChild.tnaqcInspecTrimImagesID,
                                            UploadedImagePath: subChild.uploadedImagePath,
                                            MainIndex: index,
                                            SubIndex: childindex,
                                            ImageIndex: subchildindex

                                        })
                                    })
                                })
                                // mainindex++
                            })
                                ;
                            let uniquevalues = [...new Map(QATrimEditSaveData.map(item =>
                                [item['TrimName'], item])).values()];
                            SettrimInputField(uniquevalues)
                            TrimListVal(uniquevalues)
                            hideLoader();
                        } else {
                            let FinalInsTrimSaveData = []
                            FinalinspectionService.GetFinalTrimStrikeoffList(2, props.location.state.params.styleID, '0').then((res) => {

                                //Multiselecteddate.map(y => (
                                let mainindex = 0;
                                res.data.finalInsTrimlist.map((x, index) => {
                                    // Split x.skuName into an array of individual SKU names
                                    const xSkuNamesArray = x.skuName.split(',').map(s => s.trim().toUpperCase());

                                    // Find the matching skuNames from Multiselecteddate
                                    const matchedSkuNames = [...new Set(Multiselecteddate
                                        .filter(m => xSkuNamesArray.includes(m.skuName.trim().toUpperCase()))
                                        .map(m => m.skuName))]; // Keep only the matched skuNames

                                    // If there are any matched skuNames, proceed
                                    if (matchedSkuNames.length > 0) {
                                        // Combine matched skuNames into a single string for TrimName and SkuName
                                        const matchedSkuNameString = matchedSkuNames.join(', ');

                                        FinalInsTrimSaveData.push({
                                            TNAQCInspecTrimID: 0,
                                            TrimQualityID: 0,
                                            TrimPositionID: 0,
                                            TrimFindingsRemarks: "",
                                            TrimName: `${x.trimFinalInspe} (${matchedSkuNameString})`,
                                            SkuName: matchedSkuNameString,
                                            MainIndex: mainindex,
                                            ImagesList: []
                                        });

                                        // Filter and process child data based on matched skuNames
                                        res.data.finalInsTrimSkuImageList
                                            .filter(child =>
                                                matchedSkuNames.includes(child.skuName) &&
                                                child.trimInfoID === x.trimInfoID &&
                                                child.styleTrimLogoInfoID === x.styleTrimLogoInfoID
                                            )
                                            .map((Child, childindex) => {
                                                FinalInsTrimSaveData[mainindex].ImagesList.push({
                                                    TNAQCInspecTrimSubID: 0,
                                                    skuFrontPicPath: Child.trimLogoName,
                                                    MainIndex: mainindex,
                                                    ExpectedFilename: Child.trimLogoName,
                                                    SubIndex: childindex,
                                                    TrimimagesList: []
                                                });

                                                FinalInsTrimSaveData[mainindex].ImagesList[childindex].TrimimagesList.push({
                                                    TNAQCInspecTrimImagesID: 0,
                                                    UploadedImagePath: "",
                                                    MainIndex: mainindex,
                                                    SubIndex: childindex,
                                                    ImageIndex: 0
                                                });
                                            });

                                        mainindex++;
                                    }
                                });

                                //))
                                let uniquevalues = [...new Map(FinalInsTrimSaveData.map(item =>
                                    [item['TrimName'], item])).values()];
                                SettrimInputField(uniquevalues)
                                TrimListVal(uniquevalues)
                                hideLoader();
                            })
                        }
                    }
                })
            } else {
                let StrikeOffSaveData = []
                let FinalInsTrimSaveData = []
                FinalinspectionService.GetFinalTrimStrikeoffList(1, props.location.state.params.styleID, '0').then((res) => {
                    // Multiselecteddate.map((y, N) => {
                    let mainindex = 0;
                    res.data.finalInsStrikeoffNamelist.map((x, index) => {
                        // Split the skuName into an array of parts
                        const splitSkuNames = x.skuName.split(',');

                        // Find matching skuNames between Multiselecteddate and splitSkuNames
                        const matchedSkuNames = splitSkuNames.filter(part =>
                            Multiselecteddate.some(m => m.skuName.trim() === part.trim())
                        );

                        // Proceed only if there are matches
                        if (matchedSkuNames.length > 0) {
                            // Combine matched skuNames into a single string for StrikeOffName
                            const matchedSkuNameString = matchedSkuNames.join(',');

                            StrikeOffSaveData.push({
                                TNAQCStrikeoffID: 0,
                                QualityID: 0,
                                PositionID: 0,
                                FindingsRemarks: "",
                                StrikeOffName: `${x.strikeoffFinalInspe} (${matchedSkuNameString})`,
                                SkuName: matchedSkuNameString, // Include only matched skuNames
                                MainIndex: mainindex,
                                ImagesList: []
                            });

                            // Filter and process child data based on matched skuNames
                            res.data.finalInsStrikeoffSkuImageList
                                .filter(child =>
                                    matchedSkuNames.includes(child.skuName) && child.strikeoffID === x.strikeoffID
                                )
                                .map((Child, childindex) => {
                                    StrikeOffSaveData[mainindex].ImagesList.push({
                                        TNAQCStrikeoffSubID: 0,
                                        skuFrontPicPath: Child.strikeoffLogoPath,
                                        MainIndex: mainindex,
                                        ExpectedFilename: Child.strikeoffLogoName,
                                        SubIndex: childindex,
                                        StrikeoffImagesList: []
                                    });

                                    StrikeOffSaveData[mainindex].ImagesList[childindex].StrikeoffImagesList.push({
                                        TNAQcInspecStrikeOffImagesID: 0,
                                        UploadedImagePath: "",
                                        MainIndex: mainindex,
                                        SubIndex: childindex,
                                        ImageIndex: 0
                                    });
                                });

                            mainindex++;
                        }
                    });

                    //  })

                    let uniquevalues = [...new Map(StrikeOffSaveData.map(item =>
                        [item['StrikeOffName'], item])).values()];
                    SetStrikeoffInputFeild(uniquevalues)
                    StrikeofflistVal(uniquevalues)
                    hideLoader();
                    //artWorkscallback(uniquevalues)
                })
                FinalinspectionService.GetFinalTrimStrikeoffList(2, props.location.state.params.styleID, '0').then((res) => {
                    let mainindex = 0;
                    res.data.finalInsTrimlist.map((x, index) => {
                        // Split x.skuName into an array of individual SKU names
                        const xSkuNamesArray = x.skuName.split(',').map(s => s.trim().toUpperCase());

                        // Find the matching skuNames from Multiselecteddate
                        const matchedSkuNames = [...new Set(Multiselecteddate
                            .filter(m => xSkuNamesArray.includes(m.skuName.trim().toUpperCase()))
                            .map(m => m.skuName))]; // Keep only the matched skuNames

                        // If there are any matched skuNames, proceed
                        if (matchedSkuNames.length > 0) {
                            // Combine matched skuNames into a single string for TrimName and SkuName
                            const matchedSkuNameString = matchedSkuNames.join(', ');

                            FinalInsTrimSaveData.push({
                                TNAQCInspecTrimID: 0,
                                TrimQualityID: 0,
                                TrimPositionID: 0,
                                TrimFindingsRemarks: "",
                                TrimName: `${x.trimFinalInspe} (${matchedSkuNameString})`,
                                SkuName: matchedSkuNameString,
                                MainIndex: mainindex,
                                ImagesList: []
                            });

                            // Filter and process child data based on matched skuNames
                            res.data.finalInsTrimSkuImageList
                                .filter(child =>
                                    matchedSkuNames.includes(child.skuName) &&
                                    child.trimInfoID === x.trimInfoID &&
                                    child.styleTrimLogoInfoID === x.styleTrimLogoInfoID
                                )
                                .map((Child, childindex) => {
                                    FinalInsTrimSaveData[mainindex].ImagesList.push({
                                        TNAQCInspecTrimSubID: 0,
                                        skuFrontPicPath: Child.trimLogoName,
                                        MainIndex: mainindex,
                                        ExpectedFilename: Child.trimLogoName,
                                        SubIndex: childindex,
                                        TrimimagesList: []
                                    });

                                    FinalInsTrimSaveData[mainindex].ImagesList[childindex].TrimimagesList.push({
                                        TNAQCInspecTrimImagesID: 0,
                                        UploadedImagePath: "",
                                        MainIndex: mainindex,
                                        SubIndex: childindex,
                                        ImageIndex: 0
                                    });
                                });

                            mainindex++;
                        }
                    });

                    //))
                    let uniquevalues = [...new Map(FinalInsTrimSaveData.map(item =>
                        [item['TrimName'], item])).values()];
                    SettrimInputField(uniquevalues)
                    TrimListVal(uniquevalues)
                    hideLoader();
                })

            }
        }
        QcInspection.getCommonResultList(2, 0).then((res) => {

            if (res.data.strikeoffTrimDpList) {
                res.data.strikeoffTrimDpList.map((element) => {
                    CommonResult.push({
                        value: element.resultID,
                        label: element.resultName
                    })
                })
                setDropdownlist(CommonResult);
            }
        })
    }, [rerenderdep])

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             showLoader();

    //             const { multiselecteddatas, TaskStatus, details, styleID, TNAQCInspectionID } = props.location.state.params;
    //             const Multiselecteddate = multiselecteddatas.filter(x => x.isSelected === 1);

    //             const fetchStrikeOffEditData = async (tnaId, qcId) => {
    //                 const res = await QcInspection.getAllQcInfoList(3, tnaId, qcId);

    //                 if (res.data.qcStrikeoffDetails?.length > 0) {
    //                     const strikeOffData = res.data.qcStrikeoffDetails.map((x, index) => {
    //                         const imagesList = res.data.qcStrikeoffSubDetails
    //                             .filter(child => child.mainindex === x.mainindex)
    //                             .map((child, childIndex) => ({
    //                                 TNAQCStrikeoffSubID: child.tnaqcStrikeoffSubID,
    //                                 skuFrontPicPath: child.expectedFilepath,
    //                                 MainIndex: index,
    //                                 ExpectedFilename: child.expectedFilename,
    //                                 SubIndex: childIndex,
    //                                 StrikeoffImagesList: res.data.qcStrikeoffImagesDetails
    //                                     .filter(subchild => subchild.mainindex === child.mainindex && subchild.subindex === child.subindex)
    //                                     .map((subchild, subchildIndex) => ({
    //                                         TNAQcInspecStrikeOffImagesID: subchild.tnaQcInspecStrikeOffImagesID,
    //                                         UploadedImagePath: subchild.uploadedImagePath,
    //                                         MainIndex: index,
    //                                         SubIndex: childIndex,
    //                                         ImageIndex: subchildIndex
    //                                     }))
    //                             }));

    //                         return {
    //                             TNAQCStrikeoffID: x.tnaqcStrikeoffID,
    //                             QualityID: x.qualityID,
    //                             PositionID: x.positionID,
    //                             FindingsRemarks: x.findingsRemarks,
    //                             StrikeOffName: x.strikeOffName,
    //                             SkuName: x.skuName,
    //                             MainIndex: index,
    //                             ImagesList: imagesList
    //                         };
    //                     });

    //                     const uniqueValues = [...new Map(strikeOffData.map(item => [item.StrikeOffName, item])).values()];
    //                     SetStrikeoffInputFeild(uniqueValues);
    //                     StrikeofflistVal(uniqueValues);
    //                 } else {
    //                     await fetchFinalStrikeOffList(4, styleID, TaskStatus === 1 ? QcinpsectionId : TNAQCInspectionID);
    //                 }

    //                 if (res.data.qcTrimDetails?.length > 0) {
    //                     const trimData = res.data.qcTrimDetails.map((x, index) => {
    //                         const imagesList = res.data.qcTrimSubDetails
    //                             .filter(child => child.mainindex === x.mainindex)
    //                             .map((child, childIndex) => ({
    //                                 TNAQCInspecTrimSubID: child.tnaqcStrikeoffSubID,
    //                                 skuFrontPicPath: child.expectedFilepath,
    //                                 MainIndex: index,
    //                                 ExpectedFilename: child.expectedFilename,
    //                                 SubIndex: childIndex,
    //                                 TrimimagesList: res.data.qcTrimImagesDetails
    //                                     .filter(subchild => subchild.mainindex === child.mainindex && subchild.subindex === child.subindex)
    //                                     .map((subchild, subchildIndex) => ({
    //                                         TNAQCInspecTrimImagesID: subchild.tnaqcInspecTrimImagesID,
    //                                         UploadedImagePath: subchild.uploadedImagePath,
    //                                         MainIndex: index,
    //                                         SubIndex: childIndex,
    //                                         ImageIndex: subchildIndex
    //                                     }))
    //                             }));

    //                         return {
    //                             TNAQCInspecTrimID: x.tnaqcInspecTrimID,
    //                             TrimQualityID: x.trimQualityID,
    //                             TrimPositionID: x.trimPositionID,
    //                             TrimFindingsRemarks: x.trimFindingsRemarks,
    //                             TrimName: x.trimName,
    //                             SkuName: x.skuName,
    //                             MainIndex: index,
    //                             ImagesList: imagesList
    //                         };
    //                     });

    //                     const uniqueValues = [...new Map(trimData.map(item => [item.TrimName, item])).values()];
    //                     SettrimInputField(uniqueValues);
    //                     TrimListVal(uniqueValues);
    //                 } else {
    //                     await fetchFinalTrimList(5, styleID, TaskStatus === 1 ? QcinpsectionId : TNAQCInspectionID);
    //                 }
    //             };

    //             const fetchFinalStrikeOffList = async (type, styleID, TNAispectionID) => {
    //                 debugger
    //                 const res = await FinalinspectionService.GetFinalTrimStrikeoffList(type, styleID, TNAispectionID.toString());

    //                 const strikeOffData = res.data.finalInsStrikeoffNamelist.map((x, index) => ({
    //                     TNAQCStrikeoffID: 0,
    //                     QualityID: 0,
    //                     PositionID: 0,
    //                     FindingsRemarks: "",
    //                     StrikeOffName: `${x.strikeoffFinalInspe} (${x.skuName})`,
    //                     SkuName: x.skuName,
    //                     MainIndex: index,
    //                     ImagesList: res.data.finalInsStrikeoffSkuImageList
    //                         .filter(child => child.skuName === x.skuName && child.strikeoffID === x.strikeoffID)
    //                         .map((child, childIndex) => ({
    //                             TNAQCStrikeoffSubID: 0,
    //                             skuFrontPicPath: child.strikeoffLogoPath,
    //                             MainIndex: index,
    //                             ExpectedFilename: child.strikeoffLogoName,
    //                             SubIndex: childIndex,
    //                             StrikeoffImagesList: [{
    //                                 TNAQcInspecStrikeOffImagesID: 0,
    //                                 UploadedImagePath: "",
    //                                 MainIndex: index,
    //                                 SubIndex: childIndex,
    //                                 ImageIndex: 0
    //                             }]
    //                         }))
    //                 }));

    //                 const uniqueValues = [...new Map(strikeOffData.map(item => [item.StrikeOffName, item])).values()];
    //                 SetStrikeoffInputFeild(uniqueValues);
    //                 StrikeofflistVal(uniqueValues);
    //             };

    //             const fetchFinalTrimList = async (type, styleID, TNAispectionID) => {
    //                 const res = await FinalinspectionService.GetFinalTrimStrikeoffList(type, styleID, TNAispectionID.toString());

    //                 const trimData = res.data.finalInsTrimlist.map((x, index) => ({
    //                     TNAQCInspecTrimID: 0,
    //                     TrimQualityID: 0,
    //                     TrimPositionID: 0,
    //                     TrimFindingsRemarks: "",
    //                     TrimName: `${x.trimFinalInspe} (${x.skuName})`,
    //                     SkuName: x.skuName,
    //                     MainIndex: index,
    //                     ImagesList: res.data.finalInsTrimSkuImageList
    //                         .filter(child => child.skuName === x.skuName && child.trimInfoID === x.trimInfoID)
    //                         .map((child, childIndex) => ({
    //                             TNAQCInspecTrimSubID: 0,
    //                             skuFrontPicPath: child.trimLogoName,
    //                             MainIndex: index,
    //                             ExpectedFilename: child.trimLogoName,
    //                             SubIndex: childIndex,
    //                             TrimimagesList: [{
    //                                 TNAQCInspecTrimImagesID: 0,
    //                                 UploadedImagePath: "",
    //                                 MainIndex: index,
    //                                 SubIndex: childIndex,
    //                                 ImageIndex: 0
    //                             }]
    //                         }))
    //                 }));

    //                 const uniqueValues = [...new Map(trimData.map(item => [item.TrimName, item])).values()];
    //                 SettrimInputField(uniqueValues);
    //                 TrimListVal(uniqueValues);
    //             };

    //             if (TaskStatus && (TaskStatus === 1 ? QcinpsectionId !== null : TNAQCInspectionID !== 0)) {
    //                 await fetchStrikeOffEditData(details.tnaid, TaskStatus === 1 ? QcinpsectionId : TNAQCInspectionID);
    //             } else {
    //                 await fetchFinalStrikeOffList(4, styleID, TaskStatus === 1 ? QcinpsectionId : TNAQCInspectionID);
    //                 await fetchFinalTrimList(5, styleID, TaskStatus === 1 ? QcinpsectionId : TNAQCInspectionID);
    //             }

    //             const commonResultRes = await QcInspection.getCommonResultList(2, 0);
    //             if (commonResultRes.data.strikeoffTrimDpList?.length > 0) {
    //                 const dropdownList = commonResultRes.data.strikeoffTrimDpList.map(element => ({
    //                     value: element.resultID,
    //                     label: element.resultName
    //                 }));
    //                 setDropdownlist(dropdownList);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data: ", error);
    //         } finally {
    //             hideLoader();
    //         }
    //     };

    //     fetchData();
    // }, [props.location.state.params, rerenderdep]);

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }
    const handleTrimExpandRow = (rowNumber) => {

        settrimExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = trimexpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        settrimExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        settrimExpandedRows(newExpandedRows);
    }
    function handleTrimInputChange(index, e, FeildName) {

        const values = [...gettrimInputField];
        let inputText = ""
        if (FeildName === "Quality") {
            if (e !== null) {
                values[index].TrimQualityID = e.value;
                values[index].TrimQualityName = e.label;
                values[index].MainIndex = index;
            } else {
                values[index].TrimQualityID = 0;
                values[index].TrimQualityName = '';
            }
        }
        else if (FeildName === "Position") {
            if (e !== null) {
                values[index].TrimPositionID = e.value;
                values[index].TrimPositionName = e.label;
                values[index].MainIndex = index;
            } else {
                values[index].TrimPositionID = 0;
                values[index].TrimPositionName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].TrimFindingsRemarks = inputText;
        }
        SettrimInputField(values);
        TrimListVal(values)
    }

    function ViewTrimFile(event, filename, ViewName) {

        if (ViewName === 'Image1') {
            setIsOpenTrim(true);
            setImagenameTrim(filename);
        } else {
            setIsOpenUpl(true);
            setImagenameUpl(filename);
        }
    }

    function TrimImageCallback(value, index, childindex, imageindex) {
        let profile = TrimValues !== '' ? "/QcFinalinspection/" : '';
        TrimValues[index].ImagesList[childindex].TrimimagesList[imageindex].MainIndex = index;
        TrimValues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImageName = value;
        TrimValues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath = profile + value;
        SettrimInputField(TrimValues);
        TrimListVal(TrimValues)
    }

    function handleTrimAddFields(index, childindex, imageindex,) {
        let isvalid = true;
        if (TrimValues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath === "") {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload the Image.');
        }

        if (isvalid) {
            TrimValues[index].ImagesList[childindex].TrimimagesList.push({ UploadedImagePath: "", UploadedImageName: '', MainIndex: index, imageindex: imageindex + 1 })
        }
        SettrimInputField(TrimValues);
        TrimListVal(TrimValues)
    }

    function handleTrimRemoveFields(index, childindex, imageindex, length) {
        if (length === 1) {
            TrimValues[index].ImagesList[childindex].TrimimagesList[imageindex].UploadedImagePath = '';
        } else {
            TrimValues[index].ImagesList[childindex].TrimimagesList.splice(imageindex, 1);

        }
        SettrimInputField(TrimValues);
        TrimListVal(TrimValues)
    }

    const handleArtworksExpandRow = (rowNumber) => {
        setartworksExpandedRows([]);
        let currentExpandedRows;
        let isRowExpanded = false;
        currentExpandedRows = artworksexpandedRows;
        isRowExpanded = currentExpandedRows.includes(rowNumber);
        let obj = {};
        isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
        setartworksExpandState(obj);
        currentExpandedRows = [];
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== rowNumber) :
            currentExpandedRows.concat(rowNumber);
        setartworksExpandedRows(newExpandedRows);
    }

    const handleCommonResultInputChange = (index, e, FeildName) => {
        const values = [...getStrikeoffInputFeild];
        let inputText = ""
        if (FeildName === "Quality") {
            if (e !== null) {
                values[index].QualityID = e.value;
                values[index].QualityName = e.label;
                values[index].MainIndex = index;
            } else {
                values[index].QualityID = 0;
                values[index].QualityName = '';
            }
        }
        else if (FeildName === "Position") {
            if (e !== null) {
                values[index].PositionID = e.value;
                values[index].PositionName = e.label;
                values[index].MainIndex = index;
            } else {
                values[index].PositionID = 0;
                values[index].PositionName = '';
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values[index].FindingsRemarks = inputText;
        }
        SetStrikeoffInputFeild(values);
        StrikeofflistVal(values)
    };

    function ViewFile(event, filename, ViewName) {

        if (ViewName === 'Image1') {
            setIsOpen(true);
            setImagename(filename);
        } else {
            setIsOpenUpl(true);
            setImagenameUpl(filename);
        }
    }

    function handleAddFields(index, childindex, imgindex, skuFrontPicPath, ExpectedFilename) {
        let isvalid = true;
        if (imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath === "") {
            isvalid = false;
            Nodify('Warning!', 'warning', 'Please Upload the Image.');
        }

        if (isvalid) {
            imagevalues[index].ImagesList[childindex].StrikeoffImagesList.push({ UploadedImagePath: "", UploadedImageName: '', MainIndex: index, ImageIndex: imgindex + 1 })
        }
        SetStrikeoffInputFeild(imagevalues);
        StrikeofflistVal(imagevalues)
    }

    const ImageCallback = (value, index, childindex, imgindex) => {
        let profile = imagevalues !== '' ? "/QcFinalinspection/" : '';
        imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].MainIndex = index;
        imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImageName = value;
        imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath = profile + value;
        SetStrikeoffInputFeild(imagevalues)
        StrikeofflistVal(imagevalues)

    }
    function handleRemoveFields(index, childindex, imgindex, length) {
        if (length === 1) {
            imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImageName = '';
            imagevalues[index].ImagesList[childindex].StrikeoffImagesList[imgindex].UploadedImagePath = '';
        } else {
            imagevalues[index].ImagesList[childindex].StrikeoffImagesList.splice(imgindex, 1);
        }
        SetStrikeoffInputFeild(imagevalues);
        StrikeofflistVal(imagevalues)
    }

    return (
        <div className="row">
            <div className="col-lg-12">
                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Strike off</th>
                            <th>Quality<span className="text-danger"> *</span></th>
                            <th>Position<span className="text-danger"> *</span></th>
                            <th>Notes</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getStrikeoffInputFeild.map((comment, index) => (

                                <Fragment>

                                    <tr key={
                                        index
                                    }>
                                        <td>
                                            <i
                                                className={
                                                    artworksExpandState[index] ?
                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                } onClick={event => handleArtworksExpandRow(index)}
                                            ></i>&nbsp;
                                            <b>{comment.StrikeOffName}</b>
                                            {/* -{comment.StyleStrikeOffSKUName} */}
                                        </td>
                                        <td>   <Reactselect className="basic-single" name="Result"
                                            id={
                                                "Result" + index
                                            }
                                            isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(event) => handleCommonResultInputChange(index, event, 'Quality')}
                                            options={dropDownlist}
                                            styles={submitted && comment.QualityID === 0 ? styles : ''}
                                            value={dropDownlist.filter(function (option) {
                                                return option.value === comment.QualityID;
                                            })}

                                        ></Reactselect></td>
                                        <td>   <Reactselect className="basic-single" name="Result"
                                            id={
                                                "Result" + index
                                            }
                                            isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(event) => handleCommonResultInputChange(index, event, 'Position')}
                                            options={dropDownlist}
                                            styles={submitted && comment.PositionID === 0 ? styles : ''}
                                            value={dropDownlist.filter(function (option) {
                                                return option.value === comment.PositionID;
                                            })}

                                        ></Reactselect></td>
                                        <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                                            id={index + "_txtRemarks"}
                                            value={comment.FindingsRemarks}
                                            style={{ border: submitted && (comment.ResultID === 2 && comment.FindingsRemarks === '') ? '1px solid red' : '' }}
                                            autoComplete="off"
                                            maxLength="200"
                                            disabled={props.location.state.params.Action === 'View' ? true : false}
                                            onChange={(event) => handleCommonResultInputChange(index, event, 'FindingRemarks')} /></td>

                                        <td>
                                            <buttton className='btn btn-link'>View Approval</buttton>

                                        </td>
                                    </tr><>
                                        {
                                            artworksexpandedRows.includes(index) ?
                                                <tr>
                                                    <td colspan="6">
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                            <tbody>
                                                                {
                                                                    comment.ImagesList.map((child, childindex) => (
                                                                        <Fragment>
                                                                            <tr key={
                                                                                child.index
                                                                            }>

                                                                                {

                                                                                    <td className="table-width-40">
                                                                                        <td className="table-width-40">
                                                                                            {<span>Expected : </span>}
                                                                                            <br />
                                                                                            <br />
                                                                                            {
                                                                                                <img src={window.$APIBaseURL + "Images/Style/Strikeoff/" + child.skuFrontPicPath} alt="" width="150px" height="150px" />
                                                                                            }&nbsp; &nbsp;
                                                                                            <i className="btn btn-info fa fa-eye Packingeyeadjust" title="View Logo" style={{ marginRight: "6px" }}
                                                                                                onClick={
                                                                                                    event => ViewFile(event, child.skuFrontPicPath, 'Image1')
                                                                                                }></i></td>
                                                                                    </td>


                                                                                }
                                                                                {<td className="table-width-60">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            Found :
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="row">
                                                                                        {child.StrikeoffImagesList.map((Images, imgindex) => (
                                                                                            <div className="col-lg-6 text-center">
                                                                                                <span className="text-danger">Click on the image to upload new</span><br /><br />

                                                                                                <div>
                                                                                                    <FinalinspecUpload UploadCallback={(e) => ImageCallback(e, index, childindex, imgindex)} PageName='QcFinalinspection' Buttonview={''} ShowDiv="1" filePath={Images.UploadedImagePath} />
                                                                                                </div>

                                                                                                {Images.UploadedImagePath !== '' ? <div className="row">
                                                                                                    <div className="col-md-7">
                                                                                                        {/* <i style={{ marginRight: "0" }} className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo"
                                                  onClick={event => deleteFile(index, childindex, "UploadedImagePath1", imgindex)}></i> */}

                                                                                                    </div>
                                                                                                </div> : ''}
                                                                                                <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>
                                                                                                    {Images.UploadedImagePath !== '' ? <button onClick={
                                                                                                        event => ViewFile(event, Images.UploadedImagePath, 'Image2')
                                                                                                    } className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "7%" }}>
                                                                                                        <i title="View Logo" style={{ marginRight: "6px" }}
                                                                                                        ></i>
                                                                                                    </button> : ''}

                                                                                                    &nbsp;
                                                                                                    {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: getStrikeoffInputFeild[index].ImagesList[childindex].StrikeoffImagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                                        onClick={() => handleAddFields(index, childindex, imgindex, child.skuFrontPicPath, child.ExpectedFilename)}
                                                                                                    >
                                                                                                        <i className="fa fa-plus"></i>
                                                                                                    </button> : ''}
                                                                                                    &nbsp;
                                                                                                    <button type="button"
                                                                                                        className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                                        onClick={() => handleRemoveFields(index, childindex, imgindex, getStrikeoffInputFeild[index].ImagesList[childindex].StrikeoffImagesList.length)}
                                                                                                    >
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                </span>
                                                                                            </div>
                                                                                        ))
                                                                                        }
                                                                                    </div>

                                                                                </td>
                                                                                }
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr> : ""
                                        }</>
                                    <div> {
                                        isOpen && (

                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Strikeoff/' + getImagename}
                                                alt="bg image"
                                                onCloseRequest={
                                                    () => setIsOpen(false)
                                                } />
                                        )
                                    } </div>
                                    <div> {
                                        isOpenUpl && (

                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameUpl}
                                                alt="bg image"
                                                onCloseRequest={
                                                    () => setIsOpenUpl(false)
                                                } />
                                        )
                                    } </div>
                                </Fragment>
                            ))
                        }
                        {
                            getStrikeoffInputFeild.length === 0 ?
                                <tr>
                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                </tr> : ''
                        }
                    </tbody>
                </table>
                <br />
                <br />
                <table className="table table-striped table-bordered table-hover dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Trim</th>
                            <th>Quality<span className="text-danger"> *</span></th>
                            <th>Position<span className="text-danger"> *</span></th>
                            <th>Notes</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gettrimInputField.map((comment, index) => (

                                <Fragment>
                                    <tr key={
                                        index
                                    }>
                                        <td>
                                            <i
                                                className={
                                                    trimExpandState[index] ?
                                                        'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                } onClick={event => handleTrimExpandRow(index)}
                                            ></i>&nbsp;
                                            <b>{comment.TrimName}</b>
                                            {/* -{comment.SkuName} */}
                                        </td>
                                        <td>   <Reactselect className="basic-single" name="Result"
                                            id={
                                                "Result" + index
                                            }
                                            isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(event) => handleTrimInputChange(index, event, 'Quality')}
                                            options={dropDownlist}
                                            styles={submitted && comment.TrimQualityID === 0 ? styles : ''}
                                            value={dropDownlist.filter(function (option) {
                                                return option.value === comment.TrimQualityID;
                                            })}

                                        ></Reactselect></td>
                                        <td>   <Reactselect className="basic-single" name="Result"
                                            id={
                                                "Result" + index
                                            }
                                            isDisabled={props.location.state.params.Action === 'View' ? true : false}
                                            isLoading={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(event) => handleTrimInputChange(index, event, 'Position')}
                                            options={dropDownlist}
                                            styles={submitted && comment.TrimPositionID === 0 ? styles : ''}
                                            value={dropDownlist.filter(function (option) {
                                                return option.value === comment.TrimPositionID;
                                            })}

                                        ></Reactselect></td>
                                        <td><input type="text" className="form-control" name={index + "_txtRemarks"}
                                            id={index + "_txtRemarks"} autoComplete="off"
                                            value={comment.TrimFindingsRemarks}
                                            maxLength="200"
                                            disabled={props.location.state.params.Action === 'View' ? true : false}
                                            style={{ border: submitted && (comment.ResultID === 2 && comment.TrimFindingsRemarks === '') ? '1px solid red' : '' }}
                                            onChange={(event) => handleTrimInputChange(index, event, 'FindingRemarks')} /></td>
                                        <td><buttton className='btn btn-link'>View Approval</buttton></td>
                                    </tr><>
                                        {
                                            trimexpandedRows.includes(index) ?
                                                <tr>
                                                    <td colspan="6">
                                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                            <tbody>
                                                                {
                                                                    comment.ImagesList.map((child, childindex) => (
                                                                        <Fragment>
                                                                            <tr key={
                                                                                child.index
                                                                            }>

                                                                                {

                                                                                    <td className="table-width-40">
                                                                                        <td className="table-width-40">
                                                                                            {<span>Expected :</span>}
                                                                                            <br />
                                                                                            <br />
                                                                                            {
                                                                                                <img src={window.$APIBaseURL + "Images/Style/Trim/" + child.skuFrontPicPath} alt="" width="150px" height="150px" />
                                                                                            }
                                                                                            &nbsp;&nbsp;
                                                                                            <i className="btn btn-info fa fa-eye Packingeyeadjust" title="View Logo" style={{ marginRight: "6px" }}
                                                                                                onClick={
                                                                                                    event => ViewTrimFile(event, child.skuFrontPicPath, 'Image1')
                                                                                                }></i></td>
                                                                                    </td>



                                                                                }
                                                                                {<td className="table-width-60">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            Found :
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="row">
                                                                                        {child.TrimimagesList.map((ImageList, imageindex) => (
                                                                                            <div className="col-lg-6 text-center">
                                                                                                <span className="text-danger">Click on the image to upload new</span><br /><br />
                                                                                                <div>
                                                                                                    <FinalinspecUpload UploadCallback={(e) => TrimImageCallback(e, index, childindex, imageindex)} PageName='QcFinalinspection' Buttonview={''} ShowDiv="1" filePath={ImageList.UploadedImagePath} />
                                                                                                </div>

                                                                                                {ImageList.UploadedImagePath !== '' ? <div className="row">
                                                                                                    <div className="col-md-7">
                                                                                                        {/* <i style={{ marginRight: "0" }} className="btn btn-danger fa fa-trash-o FloatRight" title="Delete Logo"
                                                  onClick={event => deleteFile(index, childindex, imageindex, "UploadedImagePath1")}></i> */}

                                                                                                    </div>
                                                                                                </div> : ''}
                                                                                                <span className="TnaFinaspecwrkmans" style={{ left: "120px" }}>
                                                                                                    {ImageList.UploadedImagePath !== '' ? <button onClick={
                                                                                                        event => ViewTrimFile(event, ImageList.UploadedImagePath, 'Image2')
                                                                                                    } className="btn btn-info fa fa-eye" style={{ padding: "5px 6px", width: "7%" }}>
                                                                                                        <i title="View Logo" style={{ marginRight: "6px" }}
                                                                                                        ></i>
                                                                                                    </button> : ''}

                                                                                                    &nbsp;

                                                                                                    {props.location.state.params.Action !== 'View' ? <button type="button" style={{ display: gettrimInputField[index].ImagesList[childindex].TrimimagesList.length < 2 ? '' : 'none' }} className="btn btn-xs btn-success" title="Add packing Image"
                                                                                                        onClick={() => handleTrimAddFields(index, childindex, imageindex, child.skuFrontPicPath, child.ExpectedFilename)}
                                                                                                    >
                                                                                                        <i className="fa fa-plus"></i>
                                                                                                    </button> : ''}
                                                                                                    &nbsp;
                                                                                                    <button type="button"
                                                                                                        className="btn btn-xs btn-danger" title="Delete packing Image" style={{ display: props.location.state.params.Action === 'View' ? 'none' : '' }}
                                                                                                        onClick={() => handleTrimRemoveFields(index, childindex, imageindex, gettrimInputField[index].ImagesList[childindex].TrimimagesList.length)}
                                                                                                    >
                                                                                                        <i className="fa fa-trash-o"></i>
                                                                                                    </button>
                                                                                                </span>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>

                                                                                </td>
                                                                                }
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr> : ""
                                        }</>
                                    <div> {
                                        isOpenTrim && (

                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images/Style/Trim/' + getImagenameTrim}
                                                alt="bg image"
                                                onCloseRequest={
                                                    () => setIsOpenTrim(false)
                                                } />
                                        )
                                    } </div>
                                    <div> {
                                        isOpenUpl && (

                                            <Lightbox mainSrc={window.$APIBaseURL + 'Images' + getImagenameUpl}
                                                alt="bg image"
                                                onCloseRequest={
                                                    () => setIsOpenUpl(false)
                                                } />
                                        )
                                    } </div>
                                </Fragment>



                            ))
                        }
                        {
                            gettrimInputField.length === 0 ?
                                <tr>
                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                </tr> : ''
                        }
                    </tbody>
                </table>
            </div>
            {loader}
        </div>
    )
}

export default React.memo(AddQCStyleItemTab);