import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestStandard } from '../../../../../actions/teststandard';
import Reactselect from 'react-select';
import { event } from 'jquery';
import TNAService from '../../../../../services/TNA/TNAService';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import { Modal, Button } from "react-bootstrap";
import Nodify from "../../../../Common/ReactNotification";
const GarmentTest = ({ props, GarmentDetails, GarmentlabDetails, labdata, labremarkdata, Testingdetail, DependencyReload, UOMDetails, Detailwashchildcall, Detailwashinfosubcall, SpiralDetailwashinfosubcall }) => {

  const dispatch = useDispatch();
  const [getTestNames, setTestNames] = useState([]);
  const [gettestselect, settestselect] = useState([]);
  const [getGarmentHeaderInfo, setGarmentHeaderInfo] = useState([]);
  const [getStandardOptions, setStandardOptions] = useState([]);
  const [getGradeValue, setGradeValue] = useState([]);
  const reducerState = useSelector((state) => state);
  const [getOrder, setOrder] = useState([]);
  const [getStabilityInfo, setStabilityInfo] = useState([]);
  const [getSpiralityInfo, setSpiralityInfo] = useState([]);
  const [getAppearanceInfo, setAppearanceInfo] = useState([]);
  const [getSpiralDetailedInformaion, setSpiralDetailedInformaion] = useState([]);
  const [getDetailedInformaion, setDetailedInformaion] = useState([]);
  const [getApperenceDetailedInformaion, setApperenceDetailedInformaion] = useState([]);
  const [show, setShow] = useState(false);
  const [submittedlabcomments, setSubmittedlabcomments] = useState(false);
  const [submittedlabremark, setSubmittedlabremark] = useState(false);
  const [submittedtestingdetails, setSubmittedtestingdetails] = useState(false);
  const comments = reducerState.TestStandardReducer.TestStandardList;
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));
  const [getLabComments, setLabComments] = useState([
    {
      value: 0,
      label: "",
      Remarks: ""
    }
  ]);



  const [getUOMLabel, setUOMLabel] = useState(
    [
      {
        value: 1,
        label: "CMS",
        TestName: "",
        TNATestingInspectionID: inpsectionId === null ? props.location.state.params.TestingInspectionID : inpsectionId
      }
    ]
  );


  const [getSpiUOMLabel, setSpiUOMLabel] = useState(
    [
      {
        value: 1,
        label: "CMS",
        TestName: "",
        TNATestingInspectionID: inpsectionId === null ? props.location.state.params.TestingInspectionID : inpsectionId
      }
    ]
  );

  const inputstyles = {
    border: '1px solid red',
  };


  const WashCountValues = [
    { value: 1, label: "AW1" },
    { value: 2, label: "AW2" },
    { value: 3, label: "AW3" },
    { value: 4, label: "AW4" },
    { value: 5, label: "AW5" },
    { value: 6, label: "AW6" },
    { value: 7, label: "AW7" },
    { value: 8, label: "AW8" },
    { value: 9, label: "AW9" },
    { value: 10, label: "AW10" },
    { value: 11, label: "AW11" },
    { value: 12, label: "AW12" },
    { value: 13, label: "AW13" },
    { value: 14, label: "AW14" },
    { value: 15, label: "AW15" },
    { value: 16, label: "AW16" },
    { value: 17, label: "AW17" },
    { value: 18, label: "AW18" },
    { value: 19, label: "AW19" },
    { value: 20, label: "AW20" },
    { value: 21, label: "AW21" },
    { value: 22, label: "AW22" },
    { value: 23, label: "AW23" },
    { value: 24, label: "AW24" },
    { value: 25, label: "AW25" },
    { value: 26, label: "AW26" },
    { value: 27, label: "AW27" },
    { value: 28, label: "AW28" },
    { value: 29, label: "AW29" },
    { value: 30, label: "AW30" },
    { value: 31, label: "AW31" },
    { value: 32, label: "AW32" },
    { value: 33, label: "AW33" },
    { value: 34, label: "AW34" },
    { value: 35, label: "AW35" },
    { value: 36, label: "AW36" },
    { value: 37, label: "AW37" },
    { value: 38, label: "AW38" },
    { value: 39, label: "AW39" },
    { value: 40, label: "AW40" },
    { value: 41, label: "AW41" },
    { value: 42, label: "AW42" },
    { value: 43, label: "AW43" },
    { value: 44, label: "AW44" },
    { value: 45, label: "AW45" },
    { value: 46, label: "AW46" },
    { value: 47, label: "AW47" },
    { value: 48, label: "AW48" },
    { value: 49, label: "AW49" },
    { value: 50, label: "AW50" },
  ];
  const UOMValues = [
    { value: 1, label: "CMS" },
    { value: 2, label: "Inches" },
    { value: 3, label: "Meter" },
    { value: 4, label: "MM" }
  ];

  let labResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Satisfied</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Not Satisfied</b></span></>,


    }
  ]



  let IsResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Pass</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Fail</b></span></>,


    }
  ]
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),
  }
  const textareaStyles = {
    border: '1px solid red',  // Red border for highlighting 
  };
  useEffect(() => {

    setSubmittedlabcomments(labdata)
  }, [labdata === true])
  useEffect(() => {

    setSubmittedlabremark(labremarkdata)
  }, [labremarkdata === true])

  useEffect(() => {

    setSubmittedtestingdetails(Testingdetail)
  }, [Testingdetail === true])

  const handleChangeNew = (newdata) => {
    GarmentlabDetails(newdata);
  }
  useEffect(() => {
    let TestNameList = []
    let GradeList = [];
    let StabilityInfo = []
    let SpiralityInfo = []
    let AppearanceInfo = [];
    let OrderDetailList = [];
    debugger
    let StyleIDs = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).map((data => { return data.styleID })).join(",");

    const buildArrayList = (res, TestName) => {
      const Arraylist = [
        {
          ArrayAwheaders: [],
          ArrayActionheaders: [],
          Gradingpoints: [],
        },
      ];
      if (TestName === "SPIRALITY") {
        res.data.detailWashTypeHeader.filter(x => x.testName === 'SPIRALITY').map((x, index) => {
          Arraylist[0].ArrayActionheaders.push({
            Actionrowno: index
          })

          Arraylist[0].ArrayAwheaders.push({
            DetailWashID: x.detailWashID,
            Awlabel: '',
            Awvalue: x.afterWashType,
            Name1: 'Dev',
            Name2: 'Change In',
            TestID: x.testID,
            TestTypeID: x.testTypeID,
            TestName: x.testName
          })
        })
        res.data.spiralDetailWashTypeinfo.filter(x => x.testName === 'SPIRALITY').map((x, index) => {
          Arraylist[0].Gradingpoints.push({
            DetailWashID: x.detailWashID,
            testTypeID: x.testTypeID,
            testName: x.testName,
            testID: x.testID,
            fieldID: x.feildID,
            fieldName: x.feildName,
            afterWash: x.afterWash,
            beforeWash: x.beforeWash,
            GradingAwchangeIn: []
          })
          let arrGradingSizes = x.afterWashType.split(",");
          let arrGradingSizesValue = x.changeIn.split(",");
          let arrGradingDevValue = x.deviation.split(",");
          arrGradingSizes.forEach((val, valIndex) => {
            Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
              AwchangeIN: val,
              ChangeIn: arrGradingSizesValue[valIndex],
              Deviation: arrGradingDevValue[valIndex],
            })
          })
        })
      } else if (TestName === 'DIMENSIONAL STABILTY TO WASHING') {
        res.data.detailWashTypeHeader.filter(x => x.testName === 'DIMENSIONAL STABILTY TO WASHING').map((x, index) => {
          Arraylist[0].ArrayActionheaders.push({
            Actionrowno: index
          })

          Arraylist[0].ArrayAwheaders.push({
            DetailWashID: x.detailWashID,
            Awlabel: '',
            Awvalue: x.afterWashType,
            Name: 'Change In',
            TestID: x.testID,
            TestTypeID: x.testTypeID,
            TestName: x.testName
          })
        })
        res.data.detailWashTypeinfo.filter(x => x.testName === 'DIMENSIONAL STABILTY TO WASHING').map((x, index) => {
          Arraylist[0].Gradingpoints.push({
            DetailWashID: x.detailWashID,
            testTypeID: x.testTypeID,
            testName: x.testName,
            testID: x.testID,
            fieldID: x.feildID,
            fieldName: x.feildName,
            afterWash: x.afterWash,
            beforeWash: x.beforeWash,
            GradingAwchangeIn: []
          })
          let arrGradingSizes = x.afterWashType.split(",");
          let arrGradingSizesValue = x.changeIn.split(",");
          arrGradingSizes.forEach((val, valIndex) => {
            Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
              AwchangeIN: val,
              ChangeIn: arrGradingSizesValue[valIndex]
            })
          })
        })
      } else {
        if (res.data.detailWashTypeHeader.length !== 0) {
          res.data.detailWashTypeHeader.filter(x => x.testName === 'APPEARANCE AFTER WASH').map((x, index) => {
            Arraylist[0].ArrayActionheaders.push({
              Actionrowno: index
            })

            Arraylist[0].ArrayAwheaders.push({
              DetailWashID: x.detailWashID,
              Awlabel: '',
              Awvalue: x.afterWashType,
              TestID: x.testID,
              TestTypeID: x.testTypeID,
              TestName: x.testName
            })
          })
          res.data.detailWashTypeinfo.filter(x => x.testName === 'APPEARANCE AFTER WASH').map((x, index) => {
            Arraylist[0].Gradingpoints.push({
              DetailWashID: x.detailWashID,
              testTypeID: x.testTypeID,
              testName: x.testName,
              testID: x.testID,
              fieldID: x.feildID,
              fieldName: x.feildName,
              afterWash: x.afterWash,
              beforeWash: x.beforeWash,
              GradingAwchangeIn: []
            })
            let arrGradingSizes = x.afterWashType.split(",").map(id => parseInt(id.trim(), 10));;
            arrGradingSizes.forEach((val, valIndex) => {
              Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
                AwchangeIN: val
              })
            })
          })
        }
      }
      return Arraylist;
    };
    const fetchDetailedWashInfo = async () => {
      const { TestingInspectionID, TaskStatus } = props.location.state.params;
      debugger;

      const fetchQcInfo = async (type) => {
        const res = await TNAService.getAllQcInfoList(
          type,
          0,
          TaskStatus === 1 ? inpsectionId : TestingInspectionID
        );

        if (res.data.detailWashTypeHeader.length > 0) {
          // Filter each test type one by one and process it
          const dimensionalStability = res.data.detailWashTypeHeader.find(
            (header) => header.testName === "DIMENSIONAL STABILTY TO WASHING"
          );
          if (dimensionalStability) {
            const dimensionalArrayList = buildArrayList(res, dimensionalStability.testName);
            setDetailedInformaion(dimensionalArrayList);
          }

          const appearanceAfterWash = res.data.detailWashTypeHeader.find(
            (header) => header.testName === "APPEARANCE AFTER WASH"
          );
          if (appearanceAfterWash) {
            const appearanceArrayList = buildArrayList(res, appearanceAfterWash.testName);
            setApperenceDetailedInformaion(appearanceArrayList);
          }

          const spirality = res.data.detailWashTypeHeader.find(
            (header) => header.testName === "SPIRALITY"
          );
          if (spirality) {
            const spiralityArrayList = buildArrayList(res, spirality.testName);
            setSpiralDetailedInformaion(spiralityArrayList);
          }
        }
      };

      // Fetch and handle all test details in one call
      await fetchQcInfo(2);
    };

    const processTestInfo = (testName, infoArray, testData) => {
      return testData
        .filter((xy) => xy.testName === testName)
        .map((x) => ({
          TestingInfoID: x.testingInfoID || 0,
          id: x.id,
          testID: x?.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          fabricDetailsID: x.fabricDetailID || "",
          fabricDetails: x.fabricDetails,
          standard: x.standard || "",
          standardName: x.standardName || "",
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          fieldID: x.fieldID?.toString() || "",
          fieldName: x.fieldName,
          resultType: x.resultType,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.resultID,
          ResultName: x.resultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.changeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.awDeviation,
          BWDeviation: x.bwDeviation,
          isChecked: x.isChecked,
        }));
    };
    const getLatestEntriesWithChildLatestDate = (responseData) => {
      // Create an object to store the latest entries by testID and latestDate
      const latestEntries = {};

      // Iterate through the response data to store entries and their indexes
      const entriesWithIndexes = responseData.map((item, index) => ({ item, index }));

      entriesWithIndexes.forEach(({ item, index }) => {
        const { testID, latestDate, childLatestDate } = item;
        const latestDateStr = new Date(latestDate).toJSON();
        const childLatestDateStr = new Date(childLatestDate).toJSON();

        if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
          // If it's the latest date or the same date, update the latest entry
          if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
            latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
          }

          // Check if childLatestDate is greater or equal to the current childLatestDate
          if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
            // If it's the child latest date or the same date, update the child latest entry
            if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
              latestEntries[testID].childLatestDate = childLatestDateStr;
              latestEntries[testID].entries = [];
            }
            latestEntries[testID].entries.push({ item, index });
          }
        }
      });

      // Convert the object to an array of the latest entries while maintaining the original order
      const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
        return [...acc, ...entry.entries];
      }, []);

      // Sort the latest entries based on their original order in the response.data array
      latestEntriesArray.sort((a, b) => a.index - b.index);

      return latestEntriesArray.map(entry => entry.item);
    };

    const loadTestInfoDetails = async (inspection, tnaID, styleIds) => {
      const res = await TNAService.LoadTestInfoDetails(inspection, tnaID, 3)
      const testData = res.data.item2;
      debugger
      if (testData.length > 0) {
        setGarmentHeaderInfo(res.data.item1);
        setOrder(res.data.item3);
        // Process test information for different categories
        const stabilityInfo = processTestInfo("DIMENSIONAL STABILTY TO WASHING", StabilityInfo, testData);
        const spiralityInfo = processTestInfo("SPIRALITY", SpiralityInfo, testData);
        const appearanceInfo = processTestInfo("APPEARANCE AFTER WASH", AppearanceInfo, testData);
        // Set all state at once
        setStabilityInfo(stabilityInfo);
        setSpiralityInfo(spiralityInfo);
        setAppearanceInfo(appearanceInfo);

        // Trigger other necessary handlers
        handleChangeInitalload(stabilityInfo, spiralityInfo, appearanceInfo);
        LoadDetailwashinformation(stabilityInfo, "Response2");
        LoadSpiralDetailwashinformation(spiralityInfo, "Response2");
        LoadAppDetailwashinformation(appearanceInfo, "Response2");

        // Fetch detailed wash information
        await fetchDetailedWashInfo();
      }
      else {

        const response = await TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 3, styleIds, inspection);
        // // Function to filter and get the latest entries
        const latestEntries = getLatestEntriesWithChildLatestDate(response.data);
        setGarmentHeaderInfo(latestEntries);
        const stabilityInfo = processTestInfo("DIMENSIONAL STABILTY TO WASHING", StabilityInfo, latestEntries);
        const spiralityInfo = processTestInfo("SPIRALITY", SpiralityInfo, latestEntries);
        const appearanceInfo = processTestInfo("APPEARANCE AFTER WASH", AppearanceInfo, latestEntries);
        // Set all state at once
        setStabilityInfo(stabilityInfo);
        setSpiralityInfo(spiralityInfo);
        setAppearanceInfo(appearanceInfo);

        // Trigger other necessary handlers
        handleChangeInitalload(stabilityInfo, spiralityInfo, appearanceInfo);
        LoadDetailwashinformation(stabilityInfo, "Response1");
        LoadSpiralDetailwashinformation(spiralityInfo, "Response1");
        LoadAppDetailwashinformation(appearanceInfo, "Response1");

        // Fetch detailed wash information
        await fetchDetailedWashInfo();

        hideLoader();
      }



      await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {
        let newdata = [];
        if (res.data.testingMainDetails) {
          res.data.testingMainDetails.map((x) => {
            newdata.push({
              value: x.labCommentID,
              Remarks: x.labRemarks
            })

          })
          setLabComments(newdata)
          handleChangeNew(newdata)
          hideLoader();
        }
      })

      let StablityUOMValue = [];
      let SpiralityUOMValue = [];
      if (props.location.state.params.TaskStatus === 2) {
        await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {
          if (res.data.testingMainDetails) {
            const newdata = res.data.testingMainDetails.map((x) => ({
              value: x.labCommentID,
              Remarks: x.labRemarks,
            }));

            setLabComments(newdata);
            handleChangeNew(newdata);
            hideLoader();
          }
        });
        await TNAService.LoadUOMInfo(props.location.state.params.TestingInspectionID).then((res) => {
          if (res.data.length > 0) {
            const testNames = {
              "DIMENSIONAL STABILTY TO WASHING": StablityUOMValue,
              "SPIRALITY": SpiralityUOMValue
            };

            res.data.forEach((item) => {
              const { testName, value, label, testUOMID } = item;
              if (testNames[testName]) {
                testNames[testName].push({ TestName: testName, value, label, testUOMID });
              }
            });

            setUOMLabel(StablityUOMValue);
            setSpiUOMLabel(SpiralityUOMValue);
          }
        });
      }
    }

    TNAService.LoadGradeDetail().then((res) => {
      if (res.data) {
        res.data.map((x, index) => {
          GradeList.push({
            Id: x.id,
            value: x.value,
            label: x.label
          })
        })

      }
      setGradeValue(GradeList)
    })
    if (getGarmentHeaderInfo.length > 0) {
      dispatch(retrieveTestStandard(0));

      for (var i = 0; i <= getGarmentHeaderInfo.length - 1; i++) {
        if (TestNameList.length == 0) {
          let StandardOptions;
          StandardOptions = getGarmentHeaderInfo.filter((data => {
            if (data.testID == getGarmentHeaderInfo[i].testID) {
              return getGarmentHeaderInfo[i].standard;
            }
          })).map(a => a.standard);
          TestNameList.push({
            testID: getGarmentHeaderInfo[i].testID
            , ID: getGarmentHeaderInfo[i].id
            , testName: getGarmentHeaderInfo[i].testName
            , standard: StandardOptions.join(",")
            , isChecked: getGarmentHeaderInfo[i].isChecked
          });
        }
        else if (TestNameList.length > 0) {
          let filterDuplicate = TestNameList.filter((data => {
            return data.testID == getGarmentHeaderInfo[i].testID;
          }))
          if (filterDuplicate.length > 0) {

          }
          else {
            TestNameList.push({
              testID: getGarmentHeaderInfo[i].testID
              , ID: getGarmentHeaderInfo[i].id
              , testName: getGarmentHeaderInfo[i].testName
              , standard: getGarmentHeaderInfo[i].standard
              , isChecked: getGarmentHeaderInfo[i].isChecked
            });
          }
        }
      }
      setTestNames(...getTestNames, TestNameList);
      settestselect(...getTestNames, TestNameList)
    }
    loadTestInfoDetails(props.location.state.params.TaskStatus === 1 ? inpsectionId : props.location.state.params.TestingInspectionID,
      props.location.state.params.TnaID, props.location.state.params.TaskStatus === 1 ? StyleIDs : props.location.state.params.styleID);
  }, [getGarmentHeaderInfo && getGarmentHeaderInfo.length > 0]);



  useEffect(() => {
    if (comments && comments.item2 && comments.item2.length > 0) {
      let Options = [];
      comments.item2.forEach(element => {
        Options.push({ id: element.commentID, value: element.commentID, name: element.comment, label: element.comment });
      });
      setStandardOptions(Options);
    }
  }, [comments && comments.item2 && comments.item2.length > 0]);

  // Function to handle changes and send data to the parent
  const handleChangeInitalload = (StabilityInfo, SpiralityInfo, AppearanceInfo) => {
    debugger
    const arry1 = { getStabilityInfo: StabilityInfo };
    const arry2 = { getSpiralityInfo: SpiralityInfo };
    const arry3 = { getAppearanceInfo: AppearanceInfo };
    const combinedArray = [
      arry1,
      arry2,
      arry3,
    ]
    //test
    GarmentDetails(combinedArray);
    GarmentlabDetails(getLabComments);
  };
  const handleChange = () => {
    debugger
    const arry1 = { getStabilityInfo: getStabilityInfo };
    const arry2 = { getSpiralityInfo: getSpiralityInfo };
    const arry3 = { getAppearanceInfo: getAppearanceInfo };
    const combinedArray = [
      arry1,
      arry2,
      arry3,
    ]
    //test
    GarmentDetails(combinedArray);
    GarmentlabDetails(getLabComments);
  };
  async function LoadSpiralDetailwashinformation(val, Feild) {

    let Arraylist = [
      {
        ArrayAwheaders: [],
        ArrayActionheaders: [],
        Gradingpoints: []
      }
    ]
    if (Feild === 'Response1') {
      for (var i = 0; i < 5; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name1: 'Dev',
          Name2: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: 0,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard === undefined ? "" : x.standard,
          standardName: x.standardName === undefined ? "" : x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.field === undefined ? "" : x.field.toString(),
          fieldID: x.field === undefined ? "" : x.field.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: 0,
          ResultName: "",
          gradeID: 0,
          gradeName: '',
          ChangeIn: 0,
          rowindex: x.rowIndex,
          found: "",
          beforeWashChangeIn: 0,
          beforeWash: '',
          afterWash: '',
          afterWashChangeIn: 0,
          foundGSM: "",
          isChecked: 1,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 5; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            Deviation: '',
            ChangeIn: '',
          })
        }
      })
    } else {
      for (var i = 0; i < 5; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name1: 'Dev',
          Name2: 'Change In',
          TestID: val && val[0]?.testID,
          TestTypeID: val && val[0]?.testTypeID,
          TestName: val && val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: x.TestingInfoID,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard,
          standardName: x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldID: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName == undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.ChangeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.AWDeviation,
          BWDeviation: x.BWDeviation,
          isChecked: x.isChecked,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 5; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            Deviation: '',
            ChangeIn: '',
          })
        }
      })
    }

    //Arraylist[0].Gradingpoints = val;
    setSpiralDetailedInformaion(Arraylist)
  }
  async function LoadAppDetailwashinformation(val, Feild) {

    let Arraylist = [
      {
        ArrayAwheaders: [],
        ArrayActionheaders: [],
        Gradingpoints: []
      }
    ]
    if (Feild === 'Response1') {
      for (var i = 0; i < 5; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })
        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })

      }

      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: 0,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard === undefined ? "" : x.standard,
          standardName: x.standardName === undefined ? "" : x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.field === undefined ? "" : x.field.toString(),
          fieldID: x.field === undefined ? "" : x.field.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: 0,
          ResultName: "",
          gradeID: 0,
          gradeName: '',
          ChangeIn: 0,
          rowindex: x.rowIndex,
          found: "",
          beforeWashChangeIn: 0,
          beforeWash: '',
          afterWash: '',
          afterWashChangeIn: 0,
          foundGSM: "",
          isChecked: 1,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 5; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: 0,
          })
        }
      })
    } else {
      for (var i = 0; i < 5; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })
        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })

      }

      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: x.TestingInfoID,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard,
          standardName: x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldID: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName == undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.ChangeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.AWDeviation,
          BWDeviation: x.BWDeviation,
          isChecked: x.isChecked,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 5; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: 0,
          })
        }
      })
    }
    //Arraylist[0].Gradingpoints = val;
    setApperenceDetailedInformaion(Arraylist)
  }

  async function LoadDetailwashinformation(val, Feild) {

    let Arraylist = [
      {
        ArrayAwheaders: [],
        ArrayActionheaders: [],
        Gradingpoints: []
      }
    ]
    if (Feild === 'Response1') {
      for (var i = 0; i < 5; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: 0,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID === undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard === undefined ? "" : x.standard,
          standardName: x.standardName === undefined ? "" : x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.field === undefined ? "" : x.field.toString(),
          fieldID: x.field === undefined ? "" : x.field.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName === undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: 0,
          ResultName: "",
          gradeID: 0,
          gradeName: '',
          ChangeIn: 0,
          rowindex: x.rowIndex,
          found: "",
          beforeWashChangeIn: 0,
          beforeWash: '',
          afterWash: '',
          afterWashChangeIn: 0,
          foundGSM: "",
          isChecked: 1,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 5; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            ChangeIn: '',
          })
        }
      })
    } else {
      for (var i = 0; i < 5; i++) {
        Arraylist[0].ArrayActionheaders.push({
          Actionrowno: i
        })

        Arraylist[0].ArrayAwheaders.push({
          DetailWashID: 0,
          Awlabel: '',
          Awvalue: 0,
          Name: 'Change In',
          TestID: val[0]?.testID,
          TestTypeID: val[0]?.testTypeID,
          TestName: val[0]?.testName
        })
      }
      val.map((x, index) => {
        Arraylist[0].Gradingpoints.push({
          TestingInfoID: x.TestingInfoID,
          id: x.id,
          testID: x.testID,
          testTypeID: x.testTypeID,
          testName: x.testName,
          group: x.group,
          fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
          fabricDetails: x.fabricDetails,
          standard: x.standard,
          standardName: x.standardName,
          genderID: x.genderID,
          colorDepthID: x.colorDepthID,
          colorDepth: x.colorDepth,
          minResult: x.minResult,
          maxResult: x.maxResult,
          field: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldID: x.fieldID == undefined ? "" : x.fieldID.toString(),
          fieldName: x.fieldName,
          resultType: x.resultType,
          resultTypeID: x.resultType,
          optionName: x.optionName == undefined ? "" : x.optionName,
          gradeValue: x.gradeValue,
          gradeOptionID: x.gradeOptionID,
          ResultID: x.ResultID,
          ResultName: x.ResultName,
          gradeID: x.gradeID,
          gradeName: x.gradeName,
          ChangeIn: x.ChangeIn,
          rowindex: x.rowIndex,
          found: x.foundGSM,
          beforeWashChangeIn: x.beforeWashChangeIn,
          beforeWash: x.beforeWash,
          afterWash: x.afterWash,
          afterWashChangeIn: x.afterWashChangeIn,
          foundGSM: x.foundGSM,
          AWDeviation: x.AWDeviation,
          BWDeviation: x.BWDeviation,
          isChecked: x.isChecked,
          GradingAwchangeIn: []
        })
        for (var i = 0; i < 5; i++) {
          Arraylist[0].Gradingpoints[index].GradingAwchangeIn.push({
            AwchangeIN: '',
            ChangeIn: '',
          })
        }
      })
    }

    //Arraylist[0].Gradingpoints = val;
    setDetailedInformaion(Arraylist)
  }

  // Function to handle changes and send data to the parent
  const handleChangeUOM = () => {
    const combinedData = {
      getUOMLabel,
      getSpiUOMLabel

    };
    //test
    UOMDetails(combinedData);
  };


  // Function to handle changes and send data to the parent
  const handleGarmentChange = () => {
    //test
    GarmentlabDetails(getLabComments);
  };


  const handleLabResultOnchange = (value, index, fieldName) => {

    let labComments = [...getLabComments]
    if (value !== null && fieldName === 'labcomment') {
      labComments[index].label = value.label
      labComments[index].value = value.value
    }
    else if (fieldName === 'Remarks') {
      labComments[index].Remarks = value.target.value
    }
    else {
      labComments[index].label = ''
      labComments[index].value = 0
    }
    //test
    setLabComments(labComments)
    handleGarmentChange()
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckboxChange = (event, testname) => {

    // Create a copy of the headerData array
    const updatedHeaderData = [...gettestselect];
    let StablityInfo = [...getStabilityInfo]
    let afterwash = [...getAppearanceInfo]
    let SpiralityInfo = [...getSpiralityInfo]

    // Find the index of the object with the matching testName
    const index = updatedHeaderData.findIndex((item) => item.testName === testname);

    if (index !== -1) {
      // Update the isChecked property of the matching object to 1 (checked)
      updatedHeaderData[index].isChecked = updatedHeaderData[index].isChecked === 1 ? 0 : 1;;

      const checkedItems = updatedHeaderData.filter(item => item.isChecked === 1);


      // Update the detailed information arrays
      updateDetailedInfoArray(StablityInfo, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(afterwash, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(SpiralityInfo, testname, updatedHeaderData[index].isChecked);
      setTestNames(updatedHeaderData);
    }
  };


  // Function to update the isChecked property in a detailed information array
  const updateDetailedInfoArray = (detailedInfoArray, testName, isChecked) => {

    const updatedArray = [...detailedInfoArray];
    const index = updatedArray.findIndex((item) => item.testName === testName);
    if (index !== -1) {
      updatedArray.forEach((item) => {
        if (item.testName === testName) {
          item.isChecked = isChecked;
        }
      })
    }


    // You may want to set the state for the detailed information array here
    // setStateForDetailedInfoArray(updatedArray);
  };





  const handleStabilityOnChange = (e, index, field) => {
    ;
    let StabilityResult = [...getStabilityInfo];
    let StabilityCal = [...getDetailedInformaion];
    let inputtext = ''
    if (field === "BeforeWash") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)
      }
      else {
        inputtext = ''
      }
      StabilityResult[index].beforeWash = inputtext;
      StabilityCal[0].Gradingpoints[index].beforeWash = inputtext;
      debugger;
      if (StabilityResult[index].afterWash !== undefined && StabilityResult[index].afterWash !== '') {
        let finalvalue = StabilityResult[index].afterWash - inputtext
        let newfinal = finalvalue / inputtext * 100

        //let newfinal = inputtext / StabilityResult[index].afterWash * 100
        //let newfinal = StabilityResult[index].afterWash /  inputtext  * 100
        StabilityResult[index].ChangeIn = parseFloat(newfinal.toFixed(1))

        if (StabilityCal[0].Gradingpoints[index].GradingAwchangeIn[0].AwchangeIN !== '') {

          const AwBW = StabilityCal[0].Gradingpoints[index].GradingAwchangeIn[0].AwchangeIN - inputtext;
          const NewReturnVal = (AwBW / inputtext) * 100;
          StabilityCal[0].Gradingpoints[index].GradingAwchangeIn[0].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1));


        }
      }
    }


    else if (StabilityResult[index].resultType === 2) {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }


      let beforeWash = StabilityResult[index].beforeWash
      let afterwash = inputtext
      if (afterwash === '') {
        afterwash = 1
        beforeWash = 1
      }
      else {
        afterwash = afterwash
        beforeWash = beforeWash
      }

      let finalvalue = afterwash - (beforeWash)
      let newfinal = finalvalue / beforeWash * 100

      StabilityResult[index].ChangeIn = parseFloat(newfinal.toFixed(1))


      if (parseInt(StabilityResult[index].minResult) <= newfinal && parseInt(StabilityResult[index].maxResult) >= newfinal) {
        StabilityResult[index].ResultName = "Pass"
        StabilityResult[index].ResultID = 1

        StabilityResult[index].afterWash = inputtext;

      }
      else {
        StabilityResult[index].ResultName = "Fail"
        StabilityResult[index].ResultID = 2

        StabilityResult[index].afterWash = inputtext;

      }
    }




    setStabilityInfo(StabilityResult);
    setDetailedInformaion(StabilityCal);
    handleChange()
  };


  const handleSpiralityWashOnChange = (e, index, field) => {
    ;
    let SpiralityResult = [...getSpiralityInfo];
    let inputtext = ''

    if (field === "BeforeWash") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].beforeWash = inputtext;
      if (SpiralityResult[index].BWDeviation !== undefined && SpiralityResult[index].BWDeviation !== '') {
        //let newfinal = inputtext / SpiralityResult[index].BWDeviation * 100
        let newfinal = SpiralityResult[index].BWDeviation / inputtext * 100
        SpiralityResult[index].beforeWashChangeIn = parseFloat(newfinal.toFixed(1))
      }

    }
    else if (field === "AfterWash") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].afterWash = inputtext;
      if (SpiralityResult[index].BWDeviation !== undefined && SpiralityResult[index].BWDeviation !== '') {
        //let newfinal = inputtext / SpiralityResult[index].BWDeviation * 100
        let newfinal = SpiralityResult[index].BWDeviation / inputtext * 100
        SpiralityResult[index].afterWashChangeIn = parseFloat(newfinal.toFixed(1))
      }
    }

    setSpiralityInfo(SpiralityResult)
    handleChange()
  };

  const handleSpiralityOnChange = (e, index, field) => {

    let SpiralityResult = [...getSpiralityInfo];
    let inputtext = ''


    if (field === "BeforeWash Deviation") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].BWDeviation = inputtext;

      if (SpiralityResult[index].resultType === 2) {
        if (e.target.value !== '') {
          inputtext = parseFloat(e.target.value)

        }
        else {
          inputtext = ''
        }

        ;
        let beforeWash = SpiralityResult[index].beforeWash
        let afterwash = inputtext
        let finalvalue = SpiralityResult[index].beforeWash - inputtext
        let newfinal = inputtext / SpiralityResult[index].beforeWash * 100

        SpiralityResult[index].beforeWashChangeIn = parseFloat(newfinal.toFixed(1))

      }
    }
    else if (field === "AfterWash Deviation") {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }
      SpiralityResult[index].AWDeviation = inputtext;

      if (SpiralityResult[index].resultType === 2) {
        if (e.target.value !== '') {
          inputtext = parseFloat(e.target.value)

        }
        else {
          inputtext = ''
        }

        ;

        let afterWash = SpiralityResult[index].afterWash
        let afterwashDeviation = inputtext
        let finalvalue = SpiralityResult[index].afterWash - inputtext
        let newfinal = inputtext / SpiralityResult[index].afterWash * 100

        SpiralityResult[index].afterWashChangeIn = parseFloat(newfinal.toFixed(1))

        if (parseInt(SpiralityResult[index].minResult) <= newfinal && parseInt(SpiralityResult[index].maxResult) >= newfinal) {
          SpiralityResult[index].ResultName = "Pass"
          SpiralityResult[index].ResultID = 1

        }
        else {
          SpiralityResult[index].ResultName = "Fail"
          SpiralityResult[index].ResultID = 2

        }

      }
    }


    setSpiralityInfo(SpiralityResult)
    // let AppearanceResult = [...getAppearanceInfo];
    // let WashValue = [...getAppearanceInfo.WashCount];
    // AppearanceResult.WashCount = WashValue;
    // setAppearanceInfo(AppearanceResult);
    handleChange()
  };

  const handleAfterWashOnChange = (e, index) => {
    let AppearanceResult = [...getAppearanceInfo]
    //let WashValue = [...getAppearanceInfo.WashCount];
    let inputtext = ''

    if (AppearanceResult[index].resultType === 3) {
      if (e !== null) {
        AppearanceResult[index].gradeID = e.value
        AppearanceResult[index].gradeName = e.label
      }
      else {
        AppearanceResult[index].gradeID = ''
        AppearanceResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (AppearanceResult[index].gradeOptionID >= e.value) {
        AppearanceResult[index].ResultName = "Pass"
        AppearanceResult[index].ResultID = 1

      } else {
        AppearanceResult[index].ResultName = "Fail"
        AppearanceResult[index].ResultID = 2
      }
    }



    //AppearanceResult.WashCount = WashValue;
    setAppearanceInfo(AppearanceResult)
    handleChange()
  };

  const handleAfterWashUOMOnChange = (e, index, fieldName) => {

    let UomResult = [...getUOMLabel]
    if (e !== null && fieldName === "DIMENSIONAL STABILTY TO WASHING") {
      UomResult[index].label = e.label
      UomResult[index].value = e.value
      UomResult[index].TestName = fieldName
    }
    else {
      UomResult[index].label = ''
      UomResult[index].value = 0
      UomResult[index].TestName = fieldName
    }

    setUOMLabel(UomResult)
    handleChangeUOM()
  };

  const handleSPIUOMOnChange = (e, index, fieldName) => {

    let UomResult = [...getSpiUOMLabel]
    if (e !== null && fieldName === "SPIRALITY") {
      UomResult[index].label = e.label
      UomResult[index].value = e.value
      UomResult[index].TestName = fieldName
    }
    else {
      UomResult[index].label = ''
      UomResult[index].value = 0
      UomResult[index].TestName = fieldName
    }

    setSpiUOMLabel(UomResult)
    handleChangeUOM()
  };
  const addSpiralSizeHeader = (index, actIndex, info) => {
    const Values = [...getSpiralDetailedInformaion];

    Values[0].ArrayActionheaders.push({
      Actionrowno: actIndex
    })

    Values[0].ArrayAwheaders.push({
      TestID: info.Gradingpoints[0].testID,
      TestTypeID: info.Gradingpoints[0].testTypeID,
      Awlabel: '',
      Awvalue: 0,
      Name1: 'Dev',
      Name2: 'Change In',
      TestName: info.Gradingpoints[0].testName
    })
    Values[0].Gradingpoints.forEach((x) => {
      // Ensure there is an object at actIndex in the GradingAwchangeIn array
      // if (!x.GradingAwchangeIn[actIndex]) {
      //   x.GradingAwchangeIn[actIndex] = {};
      // }
      x.GradingAwchangeIn.push({});
      // Set the properties AwchangeIN and ChangeIn
      x.GradingAwchangeIn[actIndex].AwchangeIN = '';
      x.GradingAwchangeIn[actIndex].ChangeIn = '';
    });
    setSpiralDetailedInformaion(Values);
    CombineArrayHearder();
    Spiralhandledetailwashrow();
  }
  const RemoveSpiralHeader = (index, actIndex) => {
    const Values = [...getSpiralDetailedInformaion];
    Values[0].ArrayAwheaders.splice(actIndex, 1);
    Values[0].ArrayActionheaders.splice(actIndex, 1);
    Values[0].Gradingpoints.map((x, ind) => {
      x.GradingAwchangeIn.splice(actIndex, 1)
    })
    setSpiralDetailedInformaion(Values);
    CombineArrayHearder();
    Spiralhandledetailwashrow();
  }

  const addSizeHeader = (index, actIndex, info) => {

    const Values = [...getDetailedInformaion];

    Values[0].ArrayActionheaders.push({
      Actionrowno: actIndex
    })

    Values[0].ArrayAwheaders.push({
      TestID: info.Gradingpoints[0].testID,
      TestTypeID: info.Gradingpoints[0].testTypeID,
      Awlabel: '',
      Awvalue: 0,
      Name: 'Change In',
      TestName: info.Gradingpoints[0].testName
    })
    Values[0].Gradingpoints.forEach((x) => {
      // Ensure there is an object at actIndex in the GradingAwchangeIn array
      // if (!x.GradingAwchangeIn[actIndex]) {
      //   x.GradingAwchangeIn[actIndex] = {};
      // }
      x.GradingAwchangeIn.push({});
      // Set the properties AwchangeIN and ChangeIn
      x.GradingAwchangeIn[actIndex].AwchangeIN = '';
      x.GradingAwchangeIn[actIndex].ChangeIn = '';
    });
    setDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }

  const RemoveHeader = (index, actIndex) => {
    ;
    const Values = [...getDetailedInformaion];
    Values[0].ArrayAwheaders.splice(actIndex, 1);
    Values[0].ArrayActionheaders.splice(actIndex, 1);
    Values[0].Gradingpoints.map((x, ind) => {
      x.GradingAwchangeIn.splice(actIndex, 1)
    })
    setDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }

  function handleDuplicateDetailWash(e, index, headerindex) {
    ;
    const Values = [...getDetailedInformaion];
    let val = Values[0].ArrayAwheaders.length;
    for (var i = 0; i < val; i++) {
      if (i !== headerindex) {
        if (Values[0].ArrayAwheaders[i].Awvalue === Values[0].ArrayAwheaders[headerindex].Awvalue) {
          Values[0].ArrayAwheaders[headerindex].Awlabel = '';
          Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
          setDetailedInformaion(Values);
          Nodify('Warning!', 'warning', "Aw field already exists");
          return false;
        }
      }
    }
  }
  function handleSpiralDuplicateDetailWash(e, index, headerindex) {
    const Values = [...getSpiralDetailedInformaion];
    let val = Values[0].ArrayAwheaders.length;
    for (var i = 0; i < val; i++) {
      if (i !== headerindex) {
        if (Values[0].ArrayAwheaders[i].Awvalue === Values[0].ArrayAwheaders[headerindex].Awvalue) {
          Values[0].ArrayAwheaders[headerindex].Awlabel = '';
          Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
          setSpiralDetailedInformaion(Values);
          Nodify('Warning!', 'warning', "Aw field already exists");
          return false;
        }
      }
    }
  }
  const CombineArrayHearder = () => {
    ;
    let Arry1 = getDetailedInformaion[0]?.ArrayAwheaders;
    let Arry2 = getApperenceDetailedInformaion[0]?.ArrayAwheaders;
    let Arry3 = getSpiralDetailedInformaion[0]?.ArrayAwheaders;
    let CombinedArray = Arry1?.concat(Arry2, Arry3);
    Detailwashchildcall(CombinedArray);
  }
  const handledetailwashrow = () => {
    ;
    let Arry1 = getDetailedInformaion[0]?.Gradingpoints;
    let Arry2 = getApperenceDetailedInformaion[0]?.Gradingpoints;
    let CombinedArray = Arry1?.concat(Arry2);
    Detailwashinfosubcall(CombinedArray);
  }
  const AsyncCombineArrayHearder = (data) => {

    let Arry1 = data[0]?.ArrayAwheaders;
    let Arry2 = data[0]?.ArrayAwheaders;
    let Arry3 = data[0]?.ArrayAwheaders;
    let CombinedArray = Arry1?.concat(Arry2, Arry3);
    Detailwashchildcall(CombinedArray);
  }
  const Asynchandledetailwashrow = (data) => {

    let Arry1 = data[0]?.Gradingpoints;
    let Arry2 = data[0]?.Gradingpoints;
    let CombinedArray = Arry1?.concat(Arry2);
    Detailwashinfosubcall(CombinedArray);
  }


  const Spiralhandledetailwashrow = () => {
    ;
    let Arry1 = getSpiralDetailedInformaion[0].Gradingpoints;
    SpiralDetailwashinfosubcall(Arry1);
  }

  function handleDetailWashTypeChange(e, index, headerindex, sectionValue) {

    // const greaterOrEqual = String.fromCharCode(8805);
    const Values = [...getDetailedInformaion];
    if (e) {
      if (
        headerindex > 0 && sectionValue.value <= Values[0].ArrayAwheaders[headerindex - 1].Awvalue
      ) {
        // Here you can handle the case where the selection is not allowed (e.g., show an error message)
        Nodify('Warning!', 'warning', "Please select a value greater than the previous selection.");
        return;
      }
      Values[0].ArrayAwheaders[headerindex].Awlabel = e.label;
      Values[0].ArrayAwheaders[headerindex].Awvalue = e.value;
      Values[0].ArrayAwheaders[headerindex].index = index;
    } else {
      Values[0].ArrayAwheaders[headerindex].Awlabel = '';
      Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
    }

    setDetailedInformaion(Values);
    CombineArrayHearder();
  }

  const handleSpiralwashTypeChange = (e, index, headerindex, sectionValue) => {

    // const greaterOrEqual = String.fromCharCode(8805);
    const Values = [...getSpiralDetailedInformaion];
    if (e !== null) {
      if (
        headerindex > 0 && sectionValue.value <= Values[0].ArrayAwheaders[headerindex - 1].Awvalue
      ) {
        // Here you can handle the case where the selection is not allowed (e.g., show an error message)
        Nodify('Warning!', 'warning', "Please select a value greater than the previous selection.");
        return;
      }
      Values[0].ArrayAwheaders[headerindex].Awlabel = e.label;
      Values[0].ArrayAwheaders[headerindex].Awvalue = e.value;
      Values[0].ArrayAwheaders[headerindex].index = index;
    } else {
      Values[0].ArrayAwheaders[headerindex].Awlabel = '';
      Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
    }
    setSpiralDetailedInformaion(Values);
    CombineArrayHearder();
  }

  // const handleDimDetailwashChange = (e, index, smindex, cindex, chil) => {

  //   const Values = [...getDetailedInformaion];
  //   if (chil.beforeWash) {
  //     Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = e.target.value;
  //     let AwBW = parseInt(e.target.value) - chil.beforeWash;
  //     let NewReturnVal = AwBW / chil.beforeWash * 100;
  //     Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : Math.round(NewReturnVal);
  //   }
  //   setDetailedInformaion(Values);
  //   handledetailwashrow();
  // }

  const handleDimDetailwashChange = (e, index, smindex, cindex, chil) => {
    const Values = [...getDetailedInformaion];
    const inputValue = e.target.value;

    // Ensure the input value is parsed correctly for decimal numbers
    const parsedValue = parseFloat(inputValue);

    // Only proceed if `beforeWash` exists and the parsed value is a valid number
    if (chil.beforeWash && !isNaN(parsedValue)) {
      // Update AwchangeIN with the input value as is (string for input display)
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = inputValue;

      // Calculate the difference and percentage change
      const AwBW = parsedValue - chil.beforeWash;
      const NewReturnVal = (AwBW / chil.beforeWash) * 100;

      // Update ChangeIn with a rounded percentage or empty if invalid
      //Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : Math.round(NewReturnVal);
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1));

    } else {
      // Reset or clear if the input is invalid
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = '';
      Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = '';
    }

    // Update the state with the new values
    setDetailedInformaion(Values);
    handledetailwashrow();
  };


  const handleSpiralDetailwashChange = (e, index, smindex, cindex, chil, Feild) => {

    const Values = [...getSpiralDetailedInformaion];
    const { value } = e.target;
    if (Feild === 'AWB') {
      if (value === '' || /^[0-9]+(\.[0-9]?)?$/.test(value)) {
        Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = value;
      }
    } else {
      if (value === '' || /^[0-9]+(\.[0-9]?)?$/.test(value)) {
        Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].Deviation = value;
      }
    }

    ;
    // let AwBW = parseInt(Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN) - parseFloat(Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].Deviation);
    // let NewReturnVal = AwBW / parseInt(Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN) * 100;
    // Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : Math.round(NewReturnVal);

    let AwBW = Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN;
    let NewReturnVal = parseFloat(Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].Deviation) / AwBW * 100;
    Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].ChangeIn = isNaN(NewReturnVal) ? '' : parseFloat(NewReturnVal.toFixed(1)); // Math.round(NewReturnVal);
    setSpiralDetailedInformaion(Values);
    Spiralhandledetailwashrow();
  }

  function handleAppDuplicateDetailWash(e, head, index, headerindex) {
    ;
    const Values = [...getApperenceDetailedInformaion];
    let val = Values[0].ArrayAwheaders.length;
    for (var i = 0; i < val; i++) {
      if (i !== headerindex) {
        if (Values[0].ArrayAwheaders[i].Awvalue === Values[0].ArrayAwheaders[headerindex].Awvalue) {
          Values[0].ArrayAwheaders[headerindex].Awlabel = '';
          Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
          setApperenceDetailedInformaion(Values);
          Nodify('Warning!', 'warning', "Aw field already exists");
          return false;
        }
      }
    }
  }

  function handleAppWashTypeChange(e, head, index, headerindex, sectionValue) {

    const Values = [...getApperenceDetailedInformaion];
    if (e) {
      if (
        headerindex > 0 && sectionValue.value <= Values[0].ArrayAwheaders[headerindex - 1].Awvalue
      ) {
        // Here you can handle the case where the selection is not allowed (e.g., show an error message)
        Nodify('Warning!', 'warning', "Please select a value greater than the previous selection.");
        return;
      }
      Values[0].ArrayAwheaders[headerindex].Awlabel = e.label;
      Values[0].ArrayAwheaders[headerindex].Awvalue = e.value;
      Values[0].ArrayAwheaders[headerindex].index = index;
    } else {
      Values[0].ArrayAwheaders[headerindex].Awlabel = '';
      Values[0].ArrayAwheaders[headerindex].Awvalue = 0;
    }

    setApperenceDetailedInformaion(Values);
    CombineArrayHearder();
  }
  const handleDimAppDetailwashChange = (e, index, smindex, cindex) => {
    const Values = [...getApperenceDetailedInformaion];
    Values[0].Gradingpoints[smindex].GradingAwchangeIn[cindex].AwchangeIN = e.value;
    setApperenceDetailedInformaion(Values);
    handledetailwashrow();
  }
  const addApperenceSizeHeader = (index, actIndex, info) => {
    const Values = [...getApperenceDetailedInformaion];

    Values[0].ArrayActionheaders.push({
      Actionrowno: actIndex
    })

    Values[0].ArrayAwheaders.push({
      Awlabel: '',
      Awvalue: 0,
      TestName: info.Gradingpoints[0].testName
    })
    Values[0].Gradingpoints.forEach((x) => {
      // Ensure there is an object at actIndex in the GradingAwchangeIn array
      // if (!x.GradingAwchangeIn[actIndex]) {
      //   x.GradingAwchangeIn[actIndex] = {};
      // }
      x.GradingAwchangeIn.push({});
      // Set the properties AwchangeIN and ChangeIn
      x.GradingAwchangeIn[actIndex].AwchangeIN = '';
      x.GradingAwchangeIn[actIndex].ChangeIn = '';
    });
    setApperenceDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }
  const RemoveApperenceHeader = (index, actIndex) => {
    ;
    const Values = [...getApperenceDetailedInformaion];
    Values[0].ArrayAwheaders.splice(actIndex, 1);
    Values[0].ArrayActionheaders.splice(actIndex, 1);
    Values[0].Gradingpoints.map((x, ind) => {
      x.GradingAwchangeIn.splice(actIndex, 1)
    })
    setApperenceDetailedInformaion(Values);
    CombineArrayHearder();
    handledetailwashrow();
  }

  const handleResultOnchange = (value, index, fieldName) => {
    let StabilityResult = [...getStabilityInfo]
    let SpiralityResult = [...getSpiralityInfo]
    let AppearanceResult = [...getAppearanceInfo]

    if (value !== null && fieldName === "DIMENSIONAL STABILTY TO WASHING") {
      StabilityResult[index].ResultName = value.label
      StabilityResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "SPIRALITY") {
      SpiralityResult[index].ResultName = value.label
      SpiralityResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "APPEARANCE AFTER WASH") {
      AppearanceResult[index].ResultName = value.label
      AppearanceResult[index].ResultID = value.value
    }
    setStabilityInfo(StabilityResult)
    setSpiralityInfo(SpiralityResult)
    handleChange()
    // let WashValue = [...getAppearanceInfo.WashCount];
    // AppearanceResult.WashCount = WashValue;
    setAppearanceInfo(AppearanceResult);
  };

  return (
    <div className="widget-body">
      <div id="registration-form">
        <div className="row">
          <h1>
            <span>
              {/* <input className='btn btn-info' onClick={handleShow}>Test Selection</input> */}
              <button className='btn btn-info' onClick={handleShow}>Test Selection</button>
            </span>
          </h1>

          <form>
            {
              getTestNames.length > 0 ?
                getTestNames != undefined && getTestNames.map((fabric, index) => (
                  <Fragment>
                    <div
                      style={{
                        display: fabric.isChecked !== 1 ? "none" : ''
                      }}

                      className="row">
                      <div className="col-md-12">
                        <div className="panel-group accordion" id="acc_Garment">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info"
                                  href={"#" + fabric.testID + "Accordian"}>
                                  {index + 1 + ". " + fabric.testName}
                                </a>
                              </h4>
                            </div>
                            <div id={fabric.testID + "Accordian"} className="panel-collapse collapse">
                              <div className="panel-body border-red">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <div className="row">
                                        <div className='col-md-3'>

                                          <label>Standard</label>
                                          <Reactselect className="basic-single"
                                            name={"StandardDetails" + index}
                                            id={"StandardDetails" + index}
                                            isDisabled={true}
                                            isLoading={false}
                                            isClearable={false}
                                            isSearchable={true}
                                            isMulti
                                            menuPosition="fixed"
                                            value={getStandardOptions.filter(data => {
                                              return fabric?.standard?.split(",").includes((data.id).toString());
                                            })}
                                            options={getStandardOptions}
                                          >
                                          </Reactselect>

                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                  <div className="col-md-12" style={{ marginTop: "10px" }}>

                                    {fabric.testName === "DIMENSIONAL STABILTY TO WASHING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>



                                          <th style={{ width: "20%" }}>

                                            <tr style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                              <th>
                                                Details
                                              </th>
                                              <th>
                                                {
                                                  getUOMLabel.map((x, index) => (

                                                    <Reactselect className="basic-single" name="IsProductionStatusID"
                                                      options={UOMValues}
                                                      classNamePrefix="washcount"
                                                      isClearable={true}
                                                      onChange={e => handleAfterWashUOMOnChange(e, index, "DIMENSIONAL STABILTY TO WASHING")}
                                                      //value={GetResult}
                                                      value={UOMValues.filter(function (option) {
                                                        return option.value === x.value;
                                                      })}
                                                    ></Reactselect>
                                                  )
                                                  )
                                                }

                                              </th>
                                            </tr>

                                          </th>

                                          <th>Before Wash </th>
                                          <th>After Wash (Final)</th>
                                          <th>Change In</th>
                                          <th>Allowed Tol</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getStabilityInfo !== undefined && getStabilityInfo.filter(x => x.isChecked === 1).map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.beforeWash} onChange={e => handleStabilityOnChange(e, index, "BeforeWash")}
                                                      style={submittedtestingdetails && (x.beforeWash === '' || x.beforeWash === null || x.beforeWash === undefined || x.beforeWash === 0) ? inputstyles : {}}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.afterWash} onChange={e => handleStabilityOnChange(e, index)}
                                                      style={submittedtestingdetails && (x.afterWash === '' || x.afterWash === null || x.afterWash === undefined || x.afterWash === 0) ? inputstyles : {}}
                                                    />
                                                  </td>

                                                  <td>
                                                    {x.ChangeIn.toFixed(2)}
                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 2 ? <span> {x.minResult + "  " + "%" + "  " + "to" + "  " + x.maxResult + "  " + "%"} </span>
                                                        : x.resultType === 3 ? <span> {x.gradeValue} ------- {x.gradeOptionID}</span>
                                                          : <span> {x.minResult + "  " + "to" + "  " + x.maxResult}</span>
                                                      }
                                                    </>

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>

                                          ))

                                        }
                                      </tbody>
                                    </table>}

                                    {fabric.testName === "SPIRALITY" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>


                                          <th style={{ width: "20%" }}>

                                            <tr style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                              <th>
                                                Details
                                              </th>
                                              <th>
                                                {
                                                  getSpiUOMLabel.map((x, index) => (

                                                    <Reactselect className="basic-single" name="IsProductionStatusID"
                                                      options={UOMValues}
                                                      classNamePrefix="washcount"
                                                      isClearable={true}
                                                      onChange={e => handleSPIUOMOnChange(e, index, "SPIRALITY")}
                                                      //value={GetResult}
                                                      value={UOMValues.filter(function (option) {
                                                        return option.value === x.value;
                                                      })}
                                                    ></Reactselect>
                                                  )
                                                  )
                                                }

                                              </th>
                                            </tr>

                                          </th>
                                          <th>Before Wash </th>
                                          <th>BW Deviation </th>
                                          <th>Change In</th>
                                          <th>After Wash</th>
                                          <th>AW Deviation </th>
                                          <th>Change In</th>
                                          <th>AllowedTol</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getSpiralityInfo != undefined && getSpiralityInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.beforeWash} onChange={e => handleSpiralityWashOnChange(e, index, "BeforeWash")}
                                                      style={submittedtestingdetails && (x.beforeWash === '' || x.beforeWash === null || x.beforeWash === undefined || x.beforeWash === 0) ? inputstyles : {}}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.BWDeviation} onChange={e => handleSpiralityOnChange(e, index, "BeforeWash Deviation")} />
                                                  </td>
                                                  <td>
                                                    {x.beforeWashChangeIn + '%'}
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.afterWash} onChange={e => handleSpiralityWashOnChange(e, index, "AfterWash")}
                                                      style={submittedtestingdetails && (x.afterWash === '' || x.afterWash === null || x.afterWash === undefined || x.afterWash === 0) ? inputstyles : {}}
                                                    />
                                                  </td>
                                                  <td>
                                                    <input type="number" className='form-control' value={x.AWDeviation} onChange={e => handleSpiralityOnChange(e, index, "AfterWash Deviation")} />
                                                  </td>
                                                  <td>
                                                    {x.afterWashChangeIn + '%'}
                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 2 ? <span> {x.minResult + "  " + "%" + "  " + "to" + "  " + x.maxResult + "  " + "%"} </span>
                                                        : x.resultType === 3 ? <span> {x.gradeValue} ------- {x.gradeOptionID}</span>
                                                          : <span> {x.minResult + "  " + "to" + "  " + x.maxResult}</span>
                                                      }
                                                    </>

                                                  </td>
                                                  {/* <td>
                                                {

                                                  (x.resultType === 1) || (x.resultType === 2) ? <input type="number" className='form-control' value={x.foundGSM} onChange={e => handleSpiralityOnChange(e, index)} />
                                                    : x.resultType === 4 ? <input type="text" className='form-control' value={x.foundGSM} onChange={e => handleSpiralityOnChange(e, index)} />
                                                      : x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleSpiralityOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                      ></Reactselect>

                                                        : ""

                                                }

                                              </td> */}
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}
                                    {fabric.testName === "APPEARANCE AFTER WASH" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details </th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getAppearanceInfo != undefined && getAppearanceInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {<span>  {x.gradeValue}</span>

                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleAfterWashOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                        styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}

                                                      ></Reactselect>



                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>

                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}
                                    <br />
                                    {fabric.testName === "SPIRALITY" && <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                      <div className="panel-group accordion" id="acc_Spiralitywash_Detailinfo">
                                        <div className="panel panel-default">
                                          <div className="panel-heading">
                                            <h4 className="panel-title">
                                              <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Spiralitywash_Detailinfo" href="#collapse_acc_Spiralitywash_Detailinfo">
                                                Detailed Washing Information
                                              </a>
                                            </h4>
                                          </div>
                                          <div id="collapse_acc_Spiralitywash_Detailinfo" className="panel-collapse collapse">
                                            <div className="panel-body border-red">
                                              <div class="col-md-12" style={{ overflowX: 'auto', maxWidth: '1700px' }}>
                                                <div className="row" >
                                                  {getSpiralDetailedInformaion && getSpiralDetailedInformaion.map((info, index) => (
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                      <thead>
                                                        <tr>
                                                          <th rowspan="3" className="fixed-width" style={{ width: '30px' }}>#</th>
                                                          <th rowspan="3" className="fixed-width-th" style={{ width: '120px' }}>Details</th>
                                                          {info.ArrayActionheaders && info.ArrayActionheaders.map((Act, Actindex) => {
                                                            return (
                                                              <th colspan="3" > <button className='btn btn-xs btn-success' type='button' onClick={() => addSpiralSizeHeader(index, Actindex + 1, info)}>
                                                                <i className='fa fa-plus'></i></button>&nbsp;
                                                                {<button className='btn btn-xs btn-danger' type='button' onClick={() => RemoveSpiralHeader(index, Actindex, info)}><i className='fa fa-trash'></i></button>} </th>
                                                            )
                                                          })}
                                                        </tr>
                                                        <tr>
                                                          {info.ArrayAwheaders && info.ArrayAwheaders.map((head, awindex) => {
                                                            return (<>
                                                              <th style={{ width: '120px' }}> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                isClearable={true}
                                                                options={WashCountValues}
                                                                onChange={e => handleSpiralwashTypeChange(e, index, awindex, WashCountValues.find(option => option.value === parseInt(e.value)))}
                                                                onBlur={e => handleSpiralDuplicateDetailWash(e, index, awindex)}
                                                                value={WashCountValues.filter(function (option) {
                                                                  return option.value === head.Awvalue;
                                                                })}
                                                                isDisabled={false}
                                                                classNamePrefix="washcount"
                                                              ></Reactselect></th>
                                                              <th style={{ width: '90px' }}>{head.Name1}</th>
                                                              <th style={{ width: '90px' }}>{head.Name2}</th>
                                                            </>)

                                                          })}
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          info.Gradingpoints !== undefined && info.Gradingpoints.map((x, smindex) => (
                                                            <>
                                                              <tr>
                                                                <td>{smindex + 1}</td>
                                                                <td> {x.fieldName}</td>
                                                                {x.GradingAwchangeIn.map((chil, chilindex) => (
                                                                  <>
                                                                    <td> <input type="number" max='10' className='form-control' value={chil.AwchangeIN} onChange={e => handleSpiralDetailwashChange(e, index, smindex, chilindex, x, 'AWB')} /></td>
                                                                    <td> <input type="number" max='10' className='form-control' value={chil.Deviation} onChange={e => handleSpiralDetailwashChange(e, index, smindex, chilindex, x, 'Dev')} /></td>
                                                                    <td>{(chil.ChangeIn === undefined || chil.ChangeIn === '') ? '%' : `${chil.ChangeIn} %`}</td>
                                                                  </>
                                                                ))
                                                                }

                                                              </tr>
                                                            </>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  ))
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>}

                                    {fabric.testName === "APPEARANCE AFTER WASH" && <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                      <div className="panel-group accordion" id="acc_Apperencewash_Detailinfo">
                                        <div className="panel panel-default">
                                          <div className="panel-heading">
                                            <h4 className="panel-title">
                                              <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Apperencewash_Detailinfo" href="#collapse_acc_Apperencewash_Detailinfo">
                                                Detailed Washing Information
                                              </a>
                                            </h4>
                                          </div>
                                          <div id="collapse_acc_Apperencewash_Detailinfo" className="panel-collapse collapse">
                                            <div className="panel-body border-red">
                                              <div class="col-md-12" style={{ overflowX: 'auto', maxWidth: '1700px' }}>
                                                <div className="row" >
                                                  <div className="tablerow">
                                                    {getApperenceDetailedInformaion !== undefined && getApperenceDetailedInformaion.map((info, index) => (
                                                      <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                        <thead>
                                                          <tr>
                                                            <th rowspan="2" className="fixed-width">#</th>
                                                            <th rowspan="2" className="fixed-width-th">Details</th>
                                                            {info.ArrayActionheaders && info.ArrayActionheaders.map((Act, Actindex) => {
                                                              return (
                                                                <th colspan="1"> <button className='btn btn-xs btn-success' type='button' onClick={() => addApperenceSizeHeader(index, Actindex + 1, info)}>
                                                                  <i className='fa fa-plus'></i></button>&nbsp;
                                                                  {<button className='btn btn-xs btn-danger' type='button' onClick={() => RemoveApperenceHeader(index, Actindex)}><i className='fa fa-trash'></i></button>} </th>
                                                              )
                                                            })}
                                                          </tr>
                                                          <tr>
                                                            {info.ArrayAwheaders && info.ArrayAwheaders.map((head, headerindex) => {
                                                              return (
                                                                <>

                                                                  <th style={{ width: '90px' }}> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                    options={WashCountValues}
                                                                    onChange={e => handleAppWashTypeChange(e, head, index, headerindex, WashCountValues.find(option => option.value === parseInt(e.value)))}
                                                                    onBlur={e => handleAppDuplicateDetailWash(e, head, index, headerindex)}
                                                                    value={WashCountValues.filter(function (option) {
                                                                      return option.value === head.Awvalue;
                                                                    })}
                                                                    isClearable={true}
                                                                    classNamePrefix="washcount"
                                                                    is
                                                                  ></Reactselect></th>
                                                                  {/* <th>{head.Name}</th> */}
                                                                </>
                                                              )
                                                            })}
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {
                                                            info.Gradingpoints !== undefined && info.Gradingpoints.map((x, Smindex) => (
                                                              <>
                                                                <tr>
                                                                  <td>{Smindex + 1}</td>
                                                                  <td> {x.fieldName}</td>
                                                                  {x.GradingAwchangeIn.map((chil, chilindex) => (
                                                                    <>

                                                                      <td>
                                                                        <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                          options={getGradeValue}
                                                                          classNamePrefix="brandGarmentDetails"
                                                                          isClearable={true}
                                                                          onChange={e => handleDimAppDetailwashChange(e, index, Smindex, chilindex)}
                                                                          //value={GetResult}
                                                                          value={getGradeValue.filter(function (option) {
                                                                            return option.value === chil.AwchangeIN;
                                                                          })}
                                                                        ></Reactselect>
                                                                      </td>
                                                                      {/* <td> <input type="number" maxLength='10' className='form-control' value={chil.AwchangeIN} onChange={e => handleDimAppDetailwashChange(e, index, Smindex, chilindex)} /></td> */}
                                                                    </>
                                                                  ))
                                                                  }

                                                                </tr>
                                                              </>
                                                            ))}
                                                        </tbody>
                                                      </table>
                                                    ))
                                                    }
                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>}
                                    {fabric.testName === "DIMENSIONAL STABILTY TO WASHING" && <div className="row" style={{ padding: '14px 14px 14px 14px' }}>
                                      <div className="panel-group accordion" id="acc_Dimensionalwash_Detailinfo">
                                        <div className="panel panel-default">
                                          <div className="panel-heading">
                                            <h4 className="panel-title">
                                              <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Dimensionalwash_Detailinfo" href="#collapse_acc_Dimensionalwash_Detailinfo">
                                                Detailed Washing Information
                                              </a>
                                            </h4>
                                          </div>
                                          <div id="collapse_acc_Dimensionalwash_Detailinfo" className="panel-collapse collapse">
                                            <div className="panel-body border-red">
                                              <div class="col-md-12" style={{ overflowX: 'auto', maxWidth: '1700px' }}>
                                                <div className="row" >
                                                  {getDetailedInformaion !== undefined && getDetailedInformaion.map((info, index) => (
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                                      <thead>
                                                        <tr>
                                                          <th rowspan="2" className="fixed-width" style={{ width: '30px' }}>#</th>
                                                          <th rowspan="2" className="fixed-width-th" style={{ width: '120px' }}>Details</th>
                                                          {info.ArrayActionheaders && info.ArrayActionheaders.map((Act, Actindex) => {
                                                            return (
                                                              <th colspan="2" > <button className='btn btn-xs btn-success' type='button' onClick={() => addSizeHeader(index, Actindex + 1, info)}>
                                                                <i className='fa fa-plus'></i></button>&nbsp;
                                                                {<button className='btn btn-xs btn-danger' type='button' onClick={() => RemoveHeader(index, Actindex, info)}><i className='fa fa-trash'></i></button>} </th>
                                                            )
                                                          })}
                                                        </tr>
                                                        <tr>
                                                          {info.ArrayAwheaders && info.ArrayAwheaders.map((head, awindex) => {
                                                            return (<>
                                                              <th style={{ width: '120px' }}> <Reactselect className="basic-single " name="IsProductionStatusID"
                                                                options={WashCountValues}
                                                                onChange={e => handleDetailWashTypeChange(e, index, awindex, WashCountValues.find(option => option.value === parseInt(e.value)))}
                                                                onBlur={e => handleDuplicateDetailWash(e, index, awindex)}
                                                                value={WashCountValues.filter(function (option) {
                                                                  return option.value === head.Awvalue;
                                                                })}
                                                                isDisabled={head.Awvalue !== null && WashCountValues.Awvalue < head.Awvalue}
                                                                isClearable={true}
                                                                classNamePrefix="washcount"
                                                              ></Reactselect></th>
                                                              <th style={{ width: '90px' }}>{head.Name}</th></>)
                                                          })}
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {
                                                          info.Gradingpoints !== undefined && info.Gradingpoints.map((x, smindex) => (
                                                            <>
                                                              <tr>
                                                                <td>{smindex + 1}</td>
                                                                <td> {x.fieldName}</td>
                                                                {x.GradingAwchangeIn.map((chil, chilindex) => (
                                                                  <>
                                                                    <td> <input type="number" max='10' className='form-control' value={chil.AwchangeIN} onChange={e => handleDimDetailwashChange(e, index, smindex, chilindex, x)} /></td>
                                                                    <td>{(chil.ChangeIn === undefined || chil.ChangeIn === '') ? '%' : `${chil.ChangeIn} %`}</td>
                                                                  </>
                                                                ))
                                                                }

                                                              </tr>
                                                            </>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  ))
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
                : <tr><td colSpan="8" className='norecordfound'><span>No Records Found Please Check Masters</span></td></tr>
            }
          </form>
        </div >
        <br />
        <br />

        {
          getLabComments.map((x, index) => (
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="">Lab Comments</label>
                  <br />

                  <Reactselect className="basic-single " name="IsProductionStatusID"
                    options={labResult}
                    isClearable={true}
                    onChange={e => handleLabResultOnchange(e, index, "labcomment")}
                    //value={GetResult}
                    styles={submittedlabcomments && x.value === 0 ? styles : ''}
                    value={labResult.filter(function (option) {
                      return option.value === x.value;
                    })}
                  ></Reactselect>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="">Remarks</label>
                  <br />
                  <textarea name="" id=""
                    style={submittedlabremark && (x.value === 2 && x.Remarks === "") ? textareaStyles : {}}
                    onChange={e => handleLabResultOnchange(e, index, "Remarks")} value={x.Remarks} cols="150" rows="10"></textarea>
                </div>
              </div>
            </>
          ))
        }
      </div >
      <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
        <div className="fixTableHeadTnadetail">
          <Modal.Header closeButton>
            <Modal.Title>TEST SELECTION POPUP</Modal.Title>
          </Modal.Header>
          {/* , overflowY: "auto" */}
          <Modal.Body className="customdialogbody">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="row">
                    {
                      gettestselect.map((fabric, index) => (
                        <div className="col-lg-6">
                          <li
                            className="listmodal"
                          >
                            <input
                              className="hidecheckbox"
                              type="checkbox"
                              name="IsShowAW"
                              checked={fabric.isChecked}
                              onChange={e => handleCheckboxChange(e, fabric.testName)}
                            />
                            &nbsp;<label htmlFor="IsShowAW">{fabric.testName}</label>
                          </li>

                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-info' onClick={handleClose}>Save</button>
          </Modal.Footer>
        </div>
      </Modal>
      {loader}
    </div >
  )
}

export default GarmentTest