import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { paginationChanges, loadTNAPurchaseOrderList, LoadTaskWiseBuyer, loadBrandThemeStoryEndCustomer, loadSupplierList } from "../../actions/tna";
import ReactDatatable from "../Common/ReactDatatable";
import Reactselect from 'react-select';
import { UpdateSupplierRefence } from "../../actions/route";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import styleService from "../../services/Style/styleService";

const TNAPurchaseOrder = (props) => {
  const dispatch = useDispatch();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [getIsSearchSubmitted, setIsSearchSubmitted] = useState(false);
  //reducer
  const isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
  
  const data = useSelector((state) => state.tna.TNAPurchaseOrderList);
 ;
  let getSeasonList = useSelector((state) => state.tna.drpSeasonList);

  const getSupplierList = useSelector((state) => state.tna.drpSupplierList);

  const reducerSeasonID = useSelector((state) => state.season.lastSeasonID);
  const reducerBrandID = useSelector((state) => state.tna.lastBrandID);
  const reducerSupplierID = useSelector((state) => state.tna.lastSupplierID);


  // const reducerState = useSelector((state) => state);
  // const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
  const [getBuyerList, setBuyerList] = useState([]);
  const BuyerBrandDetails = useSelector((state) => state.auth.UserMenuList.item6);
  //let getBrandList;
  // getBrandList = getBrandList.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId));
  // getBrandList = getBrandList.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
  //   o.value === brandId && o.buyerID === buyerId && (isAllInfo === 1 || isMapped === 1)));
  
  let getBrandList = useSelector((state) => state.tna.drpBrandList);

  useEffect(() => {
    setBuyerList(getBrandList.filter(o => BuyerBrandDetails.some(({ brandId }) => o.value === brandId)));
     //setBuyerList(getBrandList);
    }, [getBrandList])


  let LastBrandID = 0;
  let LastSeasonID = 0;
  let LastSupplierID = 0;
  let purchaseOrderID = 0;
  if (props.SeasonID > 0 && props.BrandID > 0 && props.SupplierID > 0) {

    LastBrandID = props.BrandID;
    LastSeasonID = props.SeasonID;
    LastSupplierID = props.SupplierID;
    purchaseOrderID = props.PurchaseOrderID;
  } else {
    LastBrandID = reducerBrandID;
    LastSeasonID = reducerSeasonID;
    LastSupplierID = reducerSupplierID;
  }

  const [brandID, setBrandID] = useState(LastBrandID);
  const [seasonID, setSeasonID] = useState(LastSeasonID);
  const [supplierID, setSupplierID] = useState(LastSupplierID);

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
  }

  useEffect(() => {
    if (!isLoadingTNA) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [isLoadingTNA])

  useEffect(() => {

  }, [])


  const COLUMNS = [

    {
      Header: "Received Date",
      accessor: "recievedOnStr",
      disableSortBy: false,
      disableFilters: false,
      //  Cell: ({ value }) => {
      //     return format(new Date(value), "dd/MM/yyyy");
      //   }
    },
    {
      Header: "Style Name",
      accessor: "styleName",
      disableSortBy: false,
      disableFilters: false
    },
    {
      Header: "Style No",
      accessor: "styleNo",
      disableSortBy: false,
      disableFilters: false
    },
    {
      Header: "PO/ID No",
      accessor: "idpono",
      disableSortBy: false,
      disableFilters: false
    },
    {
      Header: "Ref No",
      accessor: "focusPONO",
      disableSortBy: false,
      disableFilters: false
    },
  ];

  // If check production or sampling

  const activeMenus = useSelector((state) => state.auth.activeMenuList);
  let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
  let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
  // End
  // End

  useEffect(() => {
    if (props.BrandID === 0 && props.PurchaseOrderID === 0 && props.SeasonID === 0 && props.SupplierID === 0) {
      setBrandID(0);
      setSupplierID(0);
      setSeasonID(0);
    }
    showLoader();
    //dispatch(retrieveSeason(0));
    //TNA Operation 7
    //dispatch(loadBrandThemeStoryEndCustomer(7, 0));
    dispatch(LoadTaskWiseBuyer(2, IsProduction));
    dispatch(LoadTaskWiseBuyer(3, IsProduction));
    dispatch(LoadTaskWiseBuyer(4, IsProduction));
    //dispatch(loadSupplierList());
    hideLoader();
  }, []);



  useEffect(() => {
    dispatch(loadTNAPurchaseOrderList({ brandId: brandID, seasonId: seasonID, supplierId: supplierID, IsProduction: IsProduction, purchaseOrderId: purchaseOrderID }));

  }, [brandID, seasonID, supplierID]);

  const handleChange = (e, pageName) => {
    if (pageName === "Brand") {
      if (e !== null) {
        setBrandID(e.value);
      } else {
        setBrandID(0);
      }
    } else if (pageName === "Season") {
      if (e !== null) {
        setSeasonID(e.value);
      } else {
        setSeasonID(0);
      }
    } else if (pageName === "Supplier") {
      if (e !== null) {
        setSupplierID(e.value);
        dispatch(UpdateSupplierRefence(e.value))
      } else {
        setSupplierID(0);
        dispatch(UpdateSupplierRefence(0))
      }
    }
  }

  // const SearchPurchaseOrderStyle = (event, supplierID, seasonID, brandID) => {

  //   setIsSearchSubmitted(true);
  //   if (supplierID > 0 && seasonID > 0 && brandID > 0) {
  //     dispatch(loadTNAPurchaseOrderList({ brandId: brandID, seasonId: seasonID, supplierId: supplierID, IsProduction: IsProduction, purchaseOrderId: purchaseOrderID }));
  //   }
  //   else {
  //     Nodify('Warning!', 'warning', 'Please Choose all the mandatory(*) fields.');
  //   }
  // }


  return (
    <Fragment>
      {/* <ReactNotification /> */}
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget-body">
            <div className="row">
              <div className="col-sm-3">
                <div className='form-group'>
                  <label> Buyer/Brand<span className="text-danger">*</span></label>
                  <span className='input-icon icon-right'>
                    <Reactselect className="basic-single" name="BrandID"
                      id={"BrandID"}
                      isDisabled={purchaseOrderID > 0 ? true : false}
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      //value={getBuyerList}
                      value={getBuyerList.filter(function (option) {
                        return option.value === brandID;
                      })}
                      options={getBuyerList}
                      onChange={event => handleChange(event, 'Brand')}
                      styles={brandID === 0 ? styles : ''}
                    ></Reactselect>
                  </span>
                </div>
              </div>
              <div className="col-sm-3">
                <div className='form-group'>
                  <label> Season<span className="text-danger">*</span></label>
                  <span className='input-icon icon-right'>
                    <Reactselect className="basic-single" name="SeasonID"
                      id={"SeasonID"}
                      isDisabled={purchaseOrderID > 0 ? true : false}
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      value={getSeasonList.filter(function (option) {
                        return option.value === seasonID;
                      })}
                      options={getSeasonList}
                      onChange={event => handleChange(event, 'Season')}
                      styles={seasonID === 0 ? styles : ''}
                    ></Reactselect>
                  </span>
                </div>
              </div>
              <div className="col-sm-3">
                <div className='form-group'>
                  <label> Supplier<span className="text-danger">*</span></label>
                  <span className='input-icon icon-right'>
                    <Reactselect className="basic-single" name="SupplierID"
                      id={"SupplierID"}
                      isDisabled={purchaseOrderID > 0 ? true : false}
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      value={getSupplierList.filter(function (option) {
                        return option.value === supplierID;
                      })}
                      options={getSupplierList}
                      onChange={event => handleChange(event, 'Supplier')}
                      styles={supplierID === 0 ? styles : ''}
                    ></Reactselect>
                  </span>
                </div>
              </div>
              {/* <div className="col-sm-2">
                <div className='form-group'>
                  <label></label>
                  <br />
                  <br />
                  <span className='input-icon icon-right' >
                    <button className="btn btn-xs btn-danger" type="button" onClick={event => { SearchPurchaseOrderStyle(event, supplierID, seasonID, brandID) }}><i className="fa fa-search"></i>&nbsp; Search</button>
                  </span>
                </div>
              </div> */}
              <div className="col-sm-2">
                <div className='form-group'>
                  <label> Total Style Count</label>
                  <span className='input-icon icon-right'>
                    {data.length}
                    {/* <label
                      className="form-control"
                      value={data.length}
                      type="label"
                      readOnly={true}

                    ></label> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ReactDatatable data={data} columns={COLUMNS} pageAction={"TNAPurchaseOrder"} />
        </div>
      </div>
      {loader}
    </Fragment>
  );

}

export default React.memo(TNAPurchaseOrder);