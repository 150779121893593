import axios from "axios";
import authHeader from "../auth-header";

class ProductContextSpecificationService {
   

    GetProductContextSpecificationList () {
        return axios.get(window.$APIBaseURL + "Master/GetProductContSpecification", {
            headers: {
                authorization: authHeader()
            }
        });
    }



    InsertUpdateProductContextSpecification (Params) {
        debugger
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateProductContSpecification  ',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


}
export default new ProductContextSpecificationService();