import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from "react-notifications-component";
import Nodify from "../../../Common/ReactNotification";
import ProductAdditionalInfoService from "../../../../services/Master/ProductAdditionalInfoService"; // Replace with the correct service for ProductAdditionalInfo
import { useSelector } from "react-redux";

const AddProductAdditionalInfo = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const activeMenu = menulist?.filter((i) => i.menuName === "ProductAdditionalInfo");

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getProductAdditionalInfo, setProductAdditionalInfo] = useState([{ ProductAdditionalInfoID: 0, ProductAdditionalInfoName: "" }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");

    useEffect(() => {
        ProductAdditionalInfoService.GetProductAdditionalInfo().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const handleAddFields = (index) => {
        const values = [...getProductAdditionalInfo];
        if (values[index].ProductAdditionalInfoName.trim() !== "") {
            values.push({ ProductAdditionalInfoID: 0, ProductAdditionalInfoName: "" });
            setProductAdditionalInfo(values);
            setSubmitted(false);
        } else {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {
        const values = [...getProductAdditionalInfo];
        values[index].ProductAdditionalInfoName = event.target.value.trim() !== "" ? event.target.value : "";
        setProductAdditionalInfo(values);
    };

    const handleRemoveFields = (index) => {
        const values = getProductAdditionalInfo.filter((_, i) => i !== index);
        setProductAdditionalInfo(values);
    };

    const ResetOperation = (action) => {
        if (action === "Back") {
            props.history.push("/ProductAdditionalInfoList");
        } else {
            setProductAdditionalInfo([{ ProductAdditionalInfoID: 0, ProductAdditionalInfoName: "" }]);
            setSubmitted(false);
        }
    };

    const CheckDuplicate = (index) => {
        const values = [...getProductAdditionalInfo];
        const inputValue = values[index].ProductAdditionalInfoName.trim().toLowerCase();

        if (
            ExistingList.some(
                (existing) => existing.additionalInfoName.trim().toLowerCase() === inputValue
            )
        ) {
            values[index].ProductAdditionalInfoName = "";
            setProductAdditionalInfo(values);
            Nodify("Warning!", "warning", "This Additional Info Name already exists.");
            return false;
        }

        if (
            getProductAdditionalInfo.some(
                (item, i) => i !== index && item.ProductAdditionalInfoName.trim().toLowerCase() === inputValue
            )
        ) {
            values[index].ProductAdditionalInfoName = "";
            setProductAdditionalInfo(values);
            Nodify("Warning!", "warning", "This Additional Info Name is already in the list.");
            return false;
        }

        return true;
    };

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ProductAdditionalInfoList",
            state: { message: Func },
        });
    };

    const SaveProductAdditionalInfoList = (e) => {
        e.preventDefault();
        setButtonLoader(true);

        if (getProductAdditionalInfo.some((item) => item.ProductAdditionalInfoName.trim() === "")) {
            setSubmitted(true);
            setButtonLoader(false);
            Nodify("Warning!", "warning", "Please fill all mandatory(*) fields.");
            return;
        }

        const InputCommonMaster = {
            ProductAdditionalInfoInformation: getProductAdditionalInfo.map(item => ({
                Id: item.ProductAdditionalInfoID,
                Name: item.ProductAdditionalInfoName,
            })),
            Createdby: currentUser.employeeinformationID,
        };

        ProductAdditionalInfoService.InsertUpdateProductAdditionalInfo(InputCommonMaster)
            .then((res) => {
            let Func = "Add";

                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Additional Info added successfully.");
                    PageRedirect(Func);
                    
                    // props.history.push("/ProductAdditionalInfoList");
                } else if (res.data.outputResult === "-1") {
                    Nodify("Warning!", "warning", "This Additional Info Name already exists.");
                } else {
                    Nodify("Error!", "danger", "An error occurred. Please try again.");
                }
                setButtonLoader(false);
            })
            .catch(() => {
                Nodify("Error!", "danger", "Failed to save. Please check your connection and try again.");
                setButtonLoader(false);
            });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Additional Info </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {getProductAdditionalInfo.map((inputField, index) => (
                                        <Fragment key={`${inputField.Id}_${index}`}>
                                            <div className="row row_left10">
                                                <div className="col-sm-3 divder_style">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Additional Info Name"
                                                            maxLength="50"
                                                            value={inputField.ProductAdditionalInfoName}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                            onBlur={() => CheckDuplicate(index)}
                                                            style={{
                                                                border:
                                                                    submitted && !inputField.ProductAdditionalInfoName
                                                                        ? "1px solid red"
                                                                        : "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 paddingstatic">
                                                    {getProductAdditionalInfo.length > 1 && (
                                                        <div className="col-lg-1_own col-xs-12">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                title="Delete Additional Info Name"
                                                                onClick={() => handleRemoveFields(index)}
                                                            >
                                                                <i className="fa fa-trash-o"></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {index === getProductAdditionalInfo.length - 1 && (
                                                        <div className="col-lg-1_own col-xs-12">
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                title="Add Additional Info Name"
                                                                onClick={() => handleAddFields(index)}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Fragment>
                                    ))}
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span
                                                className="btn btn-primary"
                                                onClick={() => ResetOperation("Back")}
                                            >
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={ResetOperation}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                disabled={buttonLoader}
                                                onClick={SaveProductAdditionalInfoList}
                                            >
                                                <i className="fa fa-check right"></i>&nbsp;{ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProductAdditionalInfo;
