import React, { useState, useEffect, useMemo, useRef } from 'react';
import { TableHeader, Pagination, Search, PageCount } from "../Datatable";
import { useDispatch } from 'react-redux';
import { LoadCourierDetails } from '../../actions/courier';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { useSelector } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import SweetAlertPopup from '../Common/SweetAlertPopup';
import { CourierActionId } from "../Common/PageActionNumber";
import SearchRetain from "../Common/SearchRetain";
import Nodify from "../Common/ReactNotification";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import { Modal, Button, ModalBody } from "react-bootstrap";
import { InsertUpdateCourierDetails, ViewCourierStatus } from "../../actions/courier";
import { Row, Col } from 'react-bootstrap';
//import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
const CourierList = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let isLoadingCourier = useSelector((state) => state.courierReducer.isLoadingCourier);
    let CourierStateList = useSelector((state) => state.courierReducer.CourierList);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getAWPNumberSearch, setAWPNumberSearch] = useState("");
    const [getAgencySearch, setAgencySearch] = useState("");
    const [getFromAddressSearch, setFromAddressSearch] = useState("");
    const [getToAddressSearch, setToAddressSearch] = useState("");
    const [getStatusSearch, setStatusSearch] = useState("");
    const [getID, setID] = useState(0);
    const [getPlaceholder] = useState("Track ID");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const dispatch = useDispatch();
    const [Courierpopup, setCourierpopup] = useState(false);
    const [getres, setres] = useState(0);
    const [getIsView, setIsView] = useState(true);
    const [getCourierStatus, setCourierStatus] = useState([]);
    const [getCourierAvailability, setCourierAvailability] = useState(false);
    const [getCourierAgency, setCourierCourierAgency] = useState(false);
    const [getCourierEvents, setCourierEvents] = useState([]);
    const [getCourierInfo, setCourierInfo] = useState([])
    const [getstate_CourierID, setstate_CourierID] = useState(0);
    const [getHeaderName, addHeaderName] = useState("Add");
    const [getBtnText, setBtnText] = useState("Save");
    var CourierSearchDate = new Date();
    const CurrentUser = useSelector((state) => state.auth.user);
    const [getCourierSearchDateRange, setCourierSearchDateRange] = useState([
        {
            startDate: CourierSearchDate.setDate(CourierSearchDate.getDate() - 30),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "Courier");
    }

    //const [getMailInfo, SetMailInfo] = useState([{CourierMailId :0, DeliveryStatus:'', DeliveryStatusID: [], CubeEmp:'', CubeEmpID: [] ,CourierId : 0, AddtionalEmail:''}]);
    //End Enable Menu
    const pageRedirect = (CourierParams, redirect, page) => {
        debugger
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect == "Add") {
            props.history.push('/AddCourierTracker');
        }
        else if (redirect === 'View') {
            CourierParams.action = "View";
            props.history.push(`/AddCourierTracker`, { params: CourierParams });
        }
        else if (redirect === 'Edit') {
           CourierParams.action = "Edit";
            dispatch(LoadCourierDetails()).then((res) => {
                if (res.item3 !== undefined) {
                    // Ensure that courierFileUploadInformation is initialized as an array
                  if (!CourierParams.courierFileUploadInformation) {
                     CourierParams.courierFileUploadInformation = [];
                   }

                    let arrcourierFileUploadInformation = res.item3.filter(x => x.courierId === CourierParams.id);
                
                    arrcourierFileUploadInformation.forEach(arrcourier => {
                        // Add a new property FileType with the value of fileType
                        arrcourier.FileType = arrcourier.fileType;
                        arrcourier.FileName = arrcourier.fileName;
                        arrcourier.FilePath = arrcourier.filePath;
                        // Delete the old property fileType
                        delete arrcourier.fileType;
                        delete arrcourier.fileName;
                        delete arrcourier.filePath;

                        // Push the modified object to CourierParams.courierFileUploadInformation
                        CourierParams.courierFileUploadInformation.push(arrcourier);
                    });
                }

                if (res.item4 !== undefined) {
                       
                    if (!CourierParams.courierMailInformation) {
                        CourierParams.courierMailInformation = [];
                      }
                      
                      let arrcourierMailInformation = res.item4.filter(x => x.courierId === CourierParams.id);
                      arrcourierMailInformation.forEach(arrcourierMail => {
                        // Add a new property FileType with the value of fileType
                        arrcourierMail.CourierId = arrcourierMail.courierId;
                        arrcourierMail.CourierMailId = arrcourierMail.courierMailId;
                        arrcourierMail.DeliveryStatusID = arrcourierMail.courierDeliveryID;
                        arrcourierMail.CubeEmpID = arrcourierMail.cubeEmployee;
                        arrcourierMail.AddtionalEmail = arrcourierMail.addtionalEmail;
                        //arrcourierMail.FilePath = arrcourierMail.filePath;
                        // Delete the old property fileType
                        delete arrcourierMail.courierId;
                        delete arrcourierMail.courierMailId;
                        delete arrcourierMail.courierDeliveryID;
                        delete arrcourierMail.cubeEmployee;
                        delete arrcourierMail.addtionalEmail;

                        // Push the modified object to CourierParams.courierFileUploadInformation
                        CourierParams.courierMailInformation.push(arrcourierMail);
                    });

                }

                 
                 debugger;
                props.history.push(`/AddCourierTracker`, { params: CourierParams });
                

            });

        }
        else if (redirect === 'CheckStatus') {
            dispatch(ViewCourierStatus(CourierParams.awpNumber)).then((res) => {
                if (res.data !== undefined) {
                    CourierParams.status = res.data.shipments[0].status.status;
                    if (CourierParams.status === "delivered") {
                        CourierParams.Operation = 2;
                        dispatch(InsertUpdateCourierDetails(CourierParams)).then((res) => {
                            // if (res.data.outputResult === "2") {
                            //     dispatch(LoadCourierDetails()).then((res) => {

                            //     });
                            // }

                        });
                    }
                }
            });
        }
    };
    const DeleteCourier = (data) => {
        setID({ showPopupDelete: true, Params: data });
    }
    const Delcallback = () => {
        setID({ showPopupDelete: false, Params: [] });
    }
    function SetCourier(awp) {
        debugger;
        setIsView(true);
        addHeaderName("View");
        setres(1);
        let restrictedAgencyName = awp.agencyName.replace(/\s/g, ""); // avoid space
        if(restrictedAgencyName ===  "DHL-Courier")
        {
            setCourierCourierAgency(false);
             dispatch(ViewCourierStatus(awp.awpNumber)).then((res) => {
             if (res.data !== undefined) {
                setCourierInfo(awp);
                setCourierStatus(res.data.shipments[0]);
                setCourierEvents(res.data.shipments[0].events);
                debugger;
                if(awp.status === '' || awp.status !== 'delivered')
                { 
                awp.Operation = 2;
                
                awp.status = res.data.shipments[0].status.statusCode;
                if(!awp.CourierFileUploadInformation) { awp.CourierFileUploadInformation = []; }
                if(!awp.CourierMailInformation) { awp.CourierMailInformation = []; }
                awp.CourierFileUploadInformation.push({
                        CourierFileId: 0,
                        FileType: '',
                        FileName: '',
                        FilePath: ''
                    });
                awp.CourierMailInformation.push({
                               CourierMailId:0,
                               CourierId:0,
                               CourierDeliveryID: '',
                               CubeEmployee: '',
                               AddtionalEmail:''
                   });

                  dispatch(InsertUpdateCourierDetails(awp));
                }
                // if(res.data.shipments[0].status.statusCode !== 'delivered')
                // {
                //    
                //     awp.Operation = 2;
                //     awp.status = res.data.shipments[0].status.statusCode;
                //     dispatch(InsertUpdateCourierDetails(awp));
                // }
                getCourierStatus.id === '' ? setCourierAvailability(false) : setCourierAvailability(true);
                setres(0);
            }
            else {
                setCourierStatus([]);
                //setIsView(false);
                setCourierAvailability(false);


            }
        });
        }
        else{ 
            
            setCourierStatus([]);
            //setIsView(false);
            setCourierAvailability(false);
            setCourierCourierAgency(true);
            //Nodify('Warning!', 'warning', 'Only for DHL Agency');
        }

    }

    useEffect(() => {
        dispatch(LoadCourierDetails());
        // dispatch(LoadCourierDetails()).then((res) => {

        // });
    }, [])

    useEffect(() => {
        if (!isLoadingCourier) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingCourier]);
    useEffect(() => {
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Style details updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Style details added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }

    }, [dispatch]);
    const
        headers = [
            {
                name: "#",
                field: "id",
                sortable: false
            },
            {
                name: "Created Date",
                field: "createdDate",
                sortable: true
            },
            {
                name: "Entry No",
                field: "createdDate",
                sortable: true
            },
            {
                name: "Sent Date",
                field: "sendDate",
                sortable: true
            },
            {
                name: "Sender",
                field: "fromAddress",
                sortable: true
            },
            {
                name: "Receiver",
                field: "toAddress",
                sortable: true
            },
            {
                name: "Agency",
                field: "agencyName",
                sortable: true
            },
            {
                name: "AWB Number",
                field: "awpNumber",
                sortable: true
            },
            // {
            //     name: "Comments",
            //     field: "comment",
            //     sortable: true
            // },
            {
                name: "Check Delivery Status",
                field: "status",
                sortable: true
            },
            {
                name:"Delivery Date",
                field:"deliverydate",
                sortable:true
            },
            {
                name: "Action",
                field: "",
                sortable: false,
                display: activeMenu && activeMenu[0].isEdit === 0 &&
                    activeMenu[0].isDelete === 0 &&
                    activeMenu[0].isView === 0 ? 'none' : ''
            },
        ];

    const CourierListData = useMemo(() => {

       debugger;
        let CourierData = CourierStateList;
        if (getAWPNumberSearch) {
            CourierData = CourierData.filter(courier =>
                courier.awpNumber.toLowerCase().includes(getAWPNumberSearch.toLowerCase())
            )
        }
        if (getAgencySearch) {
            CourierData = CourierData.filter(courier =>
                courier.agencyName.toLowerCase().includes(getAgencySearch.toLowerCase())
            )
        }
        if (getFromAddressSearch) {
            CourierData = CourierData.filter(courier =>
                courier.fromAddress.toLowerCase().includes(getFromAddressSearch.toLowerCase())
            )
        }
        if (getToAddressSearch) {
            CourierData = CourierData.filter(courier =>
                courier.toAddress.toLowerCase().includes(getToAddressSearch.toLowerCase())
            )
        }
        if (getStatusSearch) {
            CourierData = CourierData.filter(courier =>
                courier.status.toLowerCase().includes(getStatusSearch.toLowerCase())

                // CourierData = CourierData.filter(courier =>
                //     courier.comment.toLowerCase().includes(getStatusSearch.toLowerCase())



            )
        }
    
        // if (sorting.field) {
        //     const reversed = sorting.order === "asc" ? 1 : -1;
        //     CourierData = CourierData.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        // }

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            CourierData = CourierData.sort((a, b) => {
                const valueA = a[sorting.field] || '';  // Fallback to empty string if null/undefined
                const valueB = b[sorting.field] || '';  // Fallback to empty string if null/undefined
                return reversed * valueA.localeCompare(valueB);
            });
        }
        const StartDate = moment(getCourierSearchDateRange[0].startDate).startOf('day').toDate();
        const EndDate = moment(getCourierSearchDateRange[0].endDate).endOf('day').toDate();

        CourierData = CourierData.filter(comment => {
            const createdDate = moment(comment.createdDate).toDate();
            return createdDate >= StartDate && createdDate <= EndDate;
        });
        setTotalItems(CourierData.length);
        let records = CourierData.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        return CourierData.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [CourierStateList,
        getAWPNumberSearch,
        getAgencySearch,
        getFromAddressSearch,
        getToAddressSearch,
        getStatusSearch,
        sorting,
        currentPage,
        getCourierSearchDateRange,
        ITEMS_PER_PAGE,]);

    const clearDateSelect = () => {
        var defaultStartDate = new Date();
        const emptychartDateRange = [
            {
                
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 30),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setCourierSearchDateRange(emptychartDateRange);
    }
    const handleDateSelect = (event, isShow) => {
        let SearchDate = [...getCourierSearchDateRange];
        SearchDate[0].isShow = isShow;
        setCourierSearchDateRange(SearchDate);
    }

    const [isExpanded, setIsExpanded] = useState(false); // State to toggle expand/collapse

    const toggleExpandCollapse = () => {
        setIsExpanded(!isExpanded); // Toggle the expand/collapse state
    };


    const handleCourierinformation = (awp, view, getPlaceholder) => {
        setCourierpopup(true);
       
        // Assuming SetCourier is a function that sets the state or performs an action with the props
        //let getCourier = SetCourier(awp);
        SetCourier(awp);
        setIsExpanded(false);
        //setCourierInfo(awp);
        // Adjust this line if necessary
        //console.log(awp, view, getPlaceholder); // For debugging or additional processing
    };

    const datePicker = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePicker.current && !datePicker.current.contains(event.target)) {
                setCourierSearchDateRange(prev => [{ ...prev[0], isShow: false }]);
            }

        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className='widget'>
                    <div className="widget-header">
                        {/* <span className="widget-caption">Covering Letter List & Courier Tracking Page</span> */}
                        <span className="widget-caption">Courier Tracking Page</span>
                    </div>
                    <div className="widget-body">
                        <div className="dataTables_wrapper form-inline no-footer">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="widget">
                                        <div className="widget-body no-padding">
                                            <div className="widget-main ">
                                                <div className="panel-group accordion" id="accordion">
                                                    <div className="panel panel-default">
                                                        <div className="panel-heading ">
                                                            <h4 className="panel-title">
                                                                <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                    Search Filter (Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span> )
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div id="collapseOne" className="panel-collapse collapse in">
                                                            <div className="panel-body border-red">
                                                                <div className="row">
                                                                    <div className="col-md-12">

                                                                        <div className="col-md-2">
                                                                            <label>Sender</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setFromAddressSearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="Sender" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label>Receiver</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setToAddressSearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="Receiver" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label>AWB Number</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setAWPNumberSearch(value);
                                                                                    }
                                                                                }
                                                                                    className="form-control StyleList"
                                                                                    placeholder="AWB Number" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <label>Agency</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setAgencySearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="Agency" props={props} />
                                                                            </span>
                                                                        </div>
                                                                        {/* <div className="col-md-2">
                                                                        <label>Status</label>
                                                                            <span className="input-icon icon-right">
                                                                                <Search onSearch={
                                                                                    value => {
                                                                                        setStatusSearch(value);
                                                                                    }
                                                                                }
                                                                                    placeholder="Status" props={props} />
                                                                            </span>
                                                                        </div> */}
                                                                        <div className='col-md-2' ref={datePicker}>
                                                                            <label>Created Date</label>
                                                                            <span className='input-icon icon-right mb-5'>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        onClick={event => handleDateSelect(event, !getCourierSearchDateRange[0].isShow)}
                                                                                        value={(getCourierSearchDateRange[0].startDate === null ? '' : moment(getCourierSearchDateRange[0].startDate).format('DD/MM/YYYY') + ' To ') + ((getCourierSearchDateRange[0].endDate === null || !moment(getCourierSearchDateRange[0].endDate).isValid()) ? '' : moment(getCourierSearchDateRange[0].endDate).format('DD/MM/YYYY'))}
                                                                                        type="text"
                                                                                        className={'form-control'}
                                                                                        placeholder="Select Date"
                                                                                        style={{ width: '265px' }}
                                                                                    />
                                                                                    <i className="fa fa-times" onClick={clearDateSelect}></i>
                                                                                </span>
                                                                                <span className='input-icon icon-right  mb-5'>
                                                                                    <br />
                                                                                    {getCourierSearchDateRange[0].isShow && <DateRangePicker
                                                                                        onChange={item => setCourierSearchDateRange([item.selection])}
                                                                                        showSelectionPreview={true}
                                                                                        moveRangeOnFirstSelection={false}
                                                                                        months={2}
                                                                                        ranges={getCourierSearchDateRange}
                                                                                        showDateDisplay={false}
                                                                                        direction="vertical"
                                                                                        className={'ChartDateRangePicker'}
                                                                                        style={{ width: '265px' }}
                                                                                    />}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="row margin-top-20">
                                                                    <div className="col-md-12">
                                                                       
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    &nbsp;


                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="table-toolbar pull-right">
                                &nbsp;
                                <span
                                    style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? "" : 'none' }}
                                    className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)}>
                                    <i className="fa fa-plus"></i>&nbsp;Add
                                </span>
                            </div>

                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                <TableHeader headers={headers} onSorting={
                                    (field, order) => setSorting({ field, order })
                                } />
                                <tbody>
                                    {
                                        CourierListData.map((awp, index) => (
                                            <>
                                                <tr key={awp.id}>
                                                    <td>{index + 1}</td> {/* Display the index incremented by 1 */}
                                                    <td>{moment(awp.createdDate).format('DD/MM/YYYY')}</td>
                                                    <td>{awp.id}</td>
                                                    {/* <td>{moment(awp.sendDate).format('DD/MM/YYYY hh:mm A')}</td> */}
                                                    <td>{moment(awp.sendDate).format('DD/MM/YYYY')}</td>
                                                    <td>{awp.fromAddress}</td>
                                                    <td>{awp.toAddress}</td>
                                                    <td>{awp.agencyName}</td>
                                                    <td>{awp.awpNumber}</td>
                                                    
                                                    {/* <td>{awp.comment}</td> */}
                                                    {/* <td>
                  {awp.comment === '' || awp.comment === undefined || awp.comment === null ? "-" : awp.comment}
                </td> */}

                                                    <td>

                                                        <span

                                                            title='Check Courier Status'
                                                            className="btn btn-primary btn-xs edit"
                                                            style={{ display: activeMenu && activeMenu[0].isView === 1 ? "" : 'none' }}
                                                            onClick={() => handleCourierinformation(awp, 'View', getPlaceholder)}
                                                        >
                                                            <i className="fa fa-check-square-o"></i>
                                                        </span>
                                                        &nbsp;
                                                        <span className={
                                                            awp.status.toLowerCase() === 'in-transit' ? 'text-warning' :
                                                                awp.status.toLowerCase() === 'transit' ? 'text-info' :
                                                                //awp.status.toLowerCase() === '12' ? 'text-info' :
                                                                //awp.status.toLowerCase() === '24' ? 'text-info' :
                                                                    awp.status.toLowerCase() === 'delivered' ? 'text-success' : 'text-danger'
                                                        }>
                                                            <b>{awp.status}</b>
                                                        </span>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {awp.status.toLowerCase() === 'delivered' &&  awp.currentStatusDate 
                                                            ? moment(awp.currentStatusDate).format('DD/MM/YYYY hh:mm A') 
                                                            : '-'}
                                                    </td>
                                                    <td style={{
                                                        display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 && activeMenu[0].isView === 0 ? 'none' : ''
                                                    }}>


                                                        <span
                                                            title='View courier'
                                                            className="btn btn-secondary btn-xs edit"
                                                            style={{ display: activeMenu && activeMenu[0].isView === 1 ? "" : 'none' }}
                                                        //onClick={() => pageRedirect(awp, 'CheckStatus', getPlaceholder)}
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </span> &nbsp;
                                                        <span
                                                            title='Edit courier'
                                                            style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? "" : 'none' }}
                                                            className="btn btn-success btn-xs edit"
                                                            onClick={() => pageRedirect(awp, 'Edit', getPlaceholder)}
                                                        >
                                                            <i className="fa fa-edit"></i>
                                                        </span>&nbsp;

                                                        <span
                                                            title='Delete courier'
                                                            style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? "" : 'none' }}
                                                            className="btn btn-danger btn-xs edit"
                                                            onClick={() => DeleteCourier(awp)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </span>


                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    }


                                    {
                                        totalItems === 0 ?
                                            <tr>
                                                <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>
                                            </tr> : ''
                                    }

                                </tbody>
                            </table>

                            <PageCount onPageCount={
                                value => {
                                    setITEMS_PER_PAGE(value);
                                    setCurrentPage(1);
                                }
                            } />

                            <Pagination total={totalItems}
                                itemsPerPage={
                                    parseInt(ITEMS_PER_PAGE)
                                }
                                currentPage={currentPage}
                                onPageChange={
                                    page => setCurrentPage(page)
                                } />
                            <Modal
                                show={Courierpopup}
                                size="lg"
                                backdrop="static"
                                keyboard={false}
                                onHide={() => setCourierpopup(false)} // Make sure the modal can be closed
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {getCourierStatus.length !== 0 && <span>Courier Tracking Details for AWB No: {getCourierStatus.id} - Current Status:  <span className='Eventfont-bold'>{getCourierStatus.status.statusCode}</span></span>}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className='margin-top-20 margin-bottom-20'>
                                        {/* <Col md={1}></Col> <Col md={10}>Sent: {getCourierInfo.fromAddress - getCourierInfo.createdDate}</Col>  */}
                                        <Col md={1}></Col>
                                        <Col md={10}>
                                            {getCourierStatus.length !== 0 && <span className='font-bold'>Sent <span style={{ marginLeft: "42px" }}> :  </span> <span style={{ marginLeft: "7px" }}>{getCourierInfo.fromAddress ? getCourierInfo.fromAddress : "N/A"} on {getCourierInfo.createdDate ? moment(new Date(getCourierInfo.createdDate)).format("DD/MM/YYYY HH:MM:A") : "N/A"}</span></span>}
                                        </Col>
                                    </Row>
                                    <Row className='margin-top-20 margin-bottom-20'>
                                        <Col md={1}></Col> {getCourierStatus.length !== 0 && <Col md={10}> <span className='font-bold'>Delivered <span style={{ marginLeft: "10px" }}> :  </span> {getCourierStatus.status.statusCode === "delivered" && moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}  DHL ({getCourierStatus.status.statusCode})</span></Col>}

                                    </Row>
                                    <Row className='margin-top-20 margin-bottom-20'>
                                        <Col md={1}></Col>
                                        {getCourierStatus.length !== 0 && <Col md={10}>  <span className='font-bold'>Piece ID <span style={{ marginLeft: "20px" }}> :  </span> {getCourierStatus.length !== 0 && <span style={{ marginLeft: "7px" }}>
                                            {getCourierStatus.details.pieceIds}</span>} </span></Col>}
                                    </Row>
                                    {getres === 0 && getIsView &&
                                        <Row className='margin-top-20 margin-bottom-20'>
                                            <Col md={1}></Col>
                                            <Col md={10}>
                                                {/* <div className='CourierContainerOne'>
                                                    <span className='CourierContainerOneText'>Tracking Code: {getCourierStatus.id}</span><br />
                                                    {getCourierStatus.length != 0 && <span className='CourierContainerOneText'>Status:&nbsp;<span className='font-bold'>{getCourierStatus.status.statusCode}</span>&nbsp;&nbsp;
                                                        <span className='Eventfont-bold'>{getCourierStatus.status.statusCode.toLowerCase() == "delivered" && <i className='fa-lg fa fa-check-circle'></i>}</span>
                                                        <span className='Eventfonttransit-bold'>{getCourierStatus.status.statusCode.toLowerCase() == "transit" && <i className='fa-lg fa fa-plane'></i>}</span>
                                                        <span className='Eventfontintransit-bold'>{getCourierStatus.status.statusCode.toLowerCase() == "intransit" && <i className='fa-lg fa fa-send'></i>}</span>
                                                    </span>}
                                                </div> */}
                                                <div className='CourierContainerOne'>
                                                    {getCourierStatus.length != 0 && <span className='CourierContainerOneText font-bold'><i className='fa fa-map-marker fa-lg'></i>&nbsp;{getCourierStatus.status.location.address.addressLocality}</span>}<br />
                                                    {getCourierStatus.length != 0 && <span className='CourierContainerOneText font-bold'>{moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}<br />
                                                    {/* <span className='CourierContainerOneText'>02. May 2022 05:33 Local time, ONTARIO SERVICE AREA, ON - CANADA</span> */}
                                                    <br />
                                                    <div className='CourierProcessFlow'>
                                                        {getCourierStatus.length != 0 && <span className='font-bold'>Service From: {getCourierStatus.origin.address.addressLocality}</span>}<br />
                                                        {/* <div className="block"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="block"></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="block"></div> */}
                                                        <div className="row">
                                                            <div className="col-md-12 hh-grayBox pt45 pb20">
                                                                <div className="row justify-content-between">
                                                                    <div className="order-tracking completed col-md-4">
                                                                        <span className="is-complete"></span>
                                                                        <p>{getCourierStatus.length !== 0 && <span>{getCourierStatus.events[getCourierStatus.events.length - 1].location.address.addressLocality}</span>}<br />
                                                                            <span>{getCourierStatus.length !== 0 && <span> {moment(new Date(getCourierStatus.events[getCourierStatus.events.length - 1].timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span></p>

                                                                    </div>
                                                                    <div className="order-tracking completed col-md-4">
                                                                        <span className="is-complete"></span>
                                                                        {/* <p>{getCourierStatus.length !== 0 && <span>{getCourierStatus.status.location.address.addressLocality}</span>}<br />
                                                                                <span>{getCourierStatus.length !== 0 && <span> {moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span></p> */}
                                                                    </div>
                                                                    <div className={getCourierStatus.length !== 0 && getCourierStatus.status.statusCode === "delivered" ? "order-tracking completed col-md-4" : "order-tracking  col-md-4"}>
                                                                        <span className="is-complete"></span>
                                                                        <p>{getCourierStatus.length !== 0 && <span>{getCourierStatus.destination.address.addressLocality}</span>}<br />
                                                                            <span>{getCourierStatus.length !== 0 && getCourierStatus.status.statusCode === "delivered" && <span> {moment(new Date(getCourierStatus.status.timestamp)).format("DD/MM/YYYY HH:MM:A")}</span>}</span><br />
                                                                            <span>{getCourierStatus.length !== 0 && getCourierStatus.status.statusCode === "delivered" && <span className='Eventfont-bold'> {getCourierStatus.status.statusCode}</span>}</span></p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {getCourierStatus.length != 0 && <span className='font-bold pull-right'>Service To: {getCourierStatus.destination.address.addressLocality}</span>}<br />
                                                    </div>
                                                </div>
                                                {/* <div className='CourierContainerOne'>
                                                    <span className=' CourierProcessFlow font-bold'>More Shipment Details</span><br />
                                                    <Row className='CourierProcessFlow'>
                                                        <Col md={12} className=" pieceIDBG">
                                                            <Col md={6}>
                                                                <span> Piece ID</span>
                                                            </Col>

                                                            <Col md={6}>
                                                                {getCourierStatus.length != 0 && <span>
                                                                    {getCourierStatus.details.pieceIds}</span>}
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                </div> */}
                                                <div className='CourierContainerTwo'>
                                                    {/* <div>
            <span className='CourierProcessFlow font-bold' style={{marginLeft:"515px"}}>All Shipment Updates</span><br />

            {/* Expand/Collapse Button */}
                                                    {/* <button onClick={toggleExpandCollapse} className="expand-collapse-btn">
                {isExpanded ? 'Collapse Updates' : 'Expand Updates'}
            </button> */}
                                                    {/* <button onClick={toggleExpandCollapse} className="expand-collapse-btn"  style={{marginLeft:"510px",marginTop:"-5px"}}>
        {isExpanded ? (
            <i className="fa fa-minus" style={{ fontSize: '15px' }}></i> // Minus icon
        ) : (
            <i className="fa fa-plus" style={{ fontSize: '15px' }}></i> // Plus icon
        )}
       
    </button>
</div> */}
                                                    {/* <div style={{ display: 'flex', marginLeft:"450px", justifyContent: 'center', marginBottom: '10px' }}> */}
                                                    <div>
                                                        <span className='CourierProcessFlow font-bold' style={{ marginRight: '10px' }}>
                                                            All Shipment Updates
                                                        </span>
                                                        {/* <button onClick={toggleExpandCollapse} className="expand-collapse-btn" style={{ fontSize: '15px' }}>
        {isExpanded ? (
            <i className="fa fa-minus"></i> // Minus icon
        ) : (
            <i className="fa fa-plus"></i> // Plus icon
        )}
    </button> */}

                                                        <span onClick={toggleExpandCollapse} style={{ cursor: 'pointer', fontSize: '19px', marginLeft: "499px" }}>
                                                            {isExpanded ? (
                                                                <i className="fa fa-minus"></i> // Minus icon
                                                            ) : (
                                                                <i className="fa fa-plus"></i> // Plus icon
                                                            )}
                                                        </span>

                                                    </div>


                                                    {isExpanded && ( // Only show the table when expanded
                                                        <table border="0" className='table table-bordered table-hover margin-top-20' style={{ backgroundColor: '#ddd0d0' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="1">#</th>
                                                                    <th colSpan="4">Event & Location</th>
                                                                    <th colSpan="2">Timestamp</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getCourierStatus.length !== 0 && getCourierEvents.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort by date descending
                                                                        .map((event, index, arr) => {
                                                                            const currentDate = moment(event.timestamp).format("DD/MM/YYYY");
                                                                            const previousDate = index > 0 ? moment(arr[index - 1].timestamp).format("DD/MM/YYYY") : null;
                                                                            const isNewDate = currentDate !== previousDate;

                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {/* Render date row when the date changes */}
                                                                                    {isNewDate && (
                                                                                        <tr className="date-row">
                                                                                            <td colSpan="7" style={{ backgroundColor: "#222d32", fontWeight: "bold", textAlign: "center" }}>
                                                                                                <span style={{ color: "#FFF" }}>{currentDate}</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                    <tr>
                                                                                        <td colSpan="1">
                                                                                            {/* Display descending index */}
                                                                                            {getCourierEvents.length - index}
                                                                                        </td>
                                                                                        <td colSpan="4">
                                                                                            <p className='Eventfont-bold'>{event.description}</p>
                                                                                            <span>{event.location.address.addressLocality}</span>
                                                                                        </td>
                                                                                        <td colSpan="2">
                                                                                            {/* Formatting the timestamp using moment.js */}
                                                                                            {moment(new Date(event.timestamp)).format("HH:mm A")}
                                                                                        </td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                    }
                                    { !getCourierAvailability && getIsView &&
                                    
                                        <Row className='margin-top-20 margin-bottom-20 text-center'>
                                            {
                                                 !getCourierAgency ? 
                                                 <span className='alert alert-warning '>
                                                  Sorry, your tracking attempt was not successful. Please check your tracking number.
                                                 </span> 
                                                 :
                                                 
                                                 <span className='alert alert-warning '>
                                                  Tracking information is only available for DHL-Courier.
                                                 </span> 
                                            }
                                            
                                        </Row>
                                    }
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={CourierActionId}
                        Msg={"Courier information deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}

export default CourierList;