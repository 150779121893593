import axios from "axios";
import authHeader from "../auth-header";
class TNAService {
    //  LoadRouteInformationList() {
    //     return axios.get(window.$APIBaseURL + "Route/LoadRouteInformationList", {
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });
    // }

    GetTNAPurchaseOrderList(params) {

        return axios.get(window.$APIBaseURL + "TNA/GetTNAPurchaseOrderList?BrandID=" + params.brandId + "&SeasonID=" + params.seasonId + "&SupplierID=" + params.supplierId + "&PurchaseOrderID=" + params.purchaseOrderId + "&IsProduction=" + params.IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    GetSupplierID(styleID) {
        return axios.get(window.$APIBaseURL + "TNA/LoadViewTNASupplier?StyleID=" + styleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    //FOR PDF
    GetTestingListforPDF(TestinspectionID) {
        return axios.get(window.$APIBaseURL + "TNA/getTestingPDFList?TNATestInspectionID=" + TestinspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    //FOR PDF
    GetFinalinspectionPDFList(TestinspectionID) {
        return axios.get(window.$APIBaseURL + "TNA/getFinalinspectionPDFList?TNAFinalinspectionID=" + TestinspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetInternalQAListforPDF(TestinspectionID) {
        return axios.get(window.$APIBaseURL + "TNA/getInteralQAPDFList?TNAInternalQCID=" + TestinspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetDropDownTesting(Operation) {
        return axios.get(window.$APIBaseURL + "TNA/getQCCommonResultList?Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadTNARouteList(PurchaseOrderIDList, IsProduction) {

        return axios.get(window.$APIBaseURL + "TNA/LoadTNARouteInformationList?PurchaseOrderIDList=" + PurchaseOrderIDList + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    LoadTNASupplierEmployeeList(getTNASupplierID) {

        return axios.get(window.$APIBaseURL + "TNA/LoadTNASupplierEmployeeList?TNASupplierID=" + getTNASupplierID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    LoadNewPurchaseOrderSKUList(TNAId, StyleId) {

        return axios.get(window.$APIBaseURL + "TNA/LoadNewPurchaseOrderSKUList?TNAId=" + TNAId + "&StyleId=" + StyleId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    // LoadTNAList(params) {
    //    
    //     return axios.get(window.$APIBaseURL + "TNA/GetTNAList?Operation=" + params.Operation + "&BrandId=" + params.brandId + "&SeasonId=" + params.seasonId + "&SupplierId=" + params.supplierId + "&StyleId=" + params.styleId + "&IDPoNos=" + params.idPoNos + "&IsProduction=" + params.IsProduction, {
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });

    // }
    LoadTNAList(params) {
        const queryString = `Operation=${encodeURIComponent(params.Operation)}
            &BrandId=${encodeURIComponent(params.brandId)}
            &SeasonId=${encodeURIComponent(params.seasonId)}
            &SupplierId=${encodeURIComponent(params.supplierId)}
            &StyleId=${encodeURIComponent(params.styleId)}
            &IDPoNos=${encodeURIComponent(params.idPoNos)}
            &StyleCode=${encodeURIComponent(params.styleCode)}
            &EndCustomer=${encodeURIComponent(params.EndCustomer)}
            &QuaterID=${encodeURIComponent(params.QuaterID)}
            &PoStartDate=${encodeURIComponent(params.PoStartDate)}
            &PoEndDate=${encodeURIComponent(params.PoEndDate)}
            &IsProduction=${encodeURIComponent(params.IsProduction)}
            &EmployeeInformationID=${encodeURIComponent(params.EmployeeInformationID)}`; // Fixed concatenation
    
        return axios.get(`${window.$APIBaseURL}TNA/GetTNAList?${queryString}`, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    LoadTNACommentsList(params) {

        return axios.get(window.$APIBaseURL + "TNA/GetTNAParentTaskCommentsList?TNAId=" + params.TnaId + "&TaskId=" + params.TaskId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    LoadTNAAttachmentsList(params) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNAParentTaskAttachmentList?TNAId=" + params.TnaId + "&TaskId=" + params.TaskId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    LoadTNADetailList(params) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNATaskApplyDetailsList?TNAId=" + params.TNAId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    // LoadTNAReportList(params) {
    //
    //     //return axios.get(window.$APIBaseURL + "TNA/GetTNAReport", {
    //     return axios.get(window.$APIBaseURL + "TNA/GetTNAReport?BuyerId=" + params.BuyerId + "&BrandId=" + params.BrandId + "&SeasonId=" + params.SeasonId + "&SupplierId=" + params.SupplierId + "&TaskType=" + params.TaskType + "&TaskNameId=" + params.TaskNameId + "&TaskTodoInProgressCompletedStatus=" + params.TaskTodoInProgressCompletedStatus+"&StartDate="+params.StartDate+"&EndDate="+params.EndDate, {
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });

    // }

    LoadTNAReportList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetTNAReport',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadTNATaskOwner(params) {
        return axios.get(window.$APIBaseURL + "TNA/LoadTNATaskOwnerList?TaskHolderID=" + params.TaskHolderID + "&BrandID=" + params.BrandID + "&SupplierID=" + params.SupplierID + "&UserLoginID=" + params.UserLoginID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetTNARouteInformationList(RouteInformationID, PurOrderStyleDetailIDList) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNARouteInformationList?RouteInformationID=" + RouteInformationID + "&PurOrderStyleDetailIDList=" + PurOrderStyleDetailIDList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNARouteTaskCheck(RouteInformationID, PurOrderStyleDetailIDList, TaskId) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNARouteTaskCheck?RouteInformationID=" + RouteInformationID + "&PurOrderStyleDetailIDList=" + PurOrderStyleDetailIDList + "&TaskId=" + TaskId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNACompanyHolidayList(params) {
        return axios.get(window.$APIBaseURL + "Master/GetTNACompanyBuyerSupplierHolidayList?BrandID=" + params.BrandID + "&SupplierID=" + params.SupplierID + "&CompanyBuyerSupID=" + params.CompanyBuyerSupID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    InsertUpdateRouteLabdipSubmissionApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateStrikeoffSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTNAProtoFitSmsSubmissionApproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTNAProtoFitSmsSubmissionApprovalForm',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }



    InsertTNA(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertTNA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //Testing
    InsertTestingInformation(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTestingGrid',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    // TestingTab 2 - Inspection Entry (Main Module)
    GetTestInspectionList(IsProduction, Taskname, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) {
        return axios.get(window.$APIBaseURL + "TNA/GetTestInspectionList?IsProduction=" + IsProduction + '&Taskname=' + Taskname + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate + "&ApStartDate=" + ApStartDate + "&ApEndDate=" + ApEndDate + "&ApprovedStatus=" + ApprovedStatus,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    //Approval
    InsertUpdateTestingpproval(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTestingapproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //

    TestingreportList(SubtaskId, Operation, TNAID) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNAExfacTestingList?SubTaskID=" + SubtaskId + "&Operation=" + Operation + '&TNAID=' + TNAID, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    InsertUpdateTestingreportComment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTestreportComment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTestPurchaseOrderComments(Id, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/GetTestPurchaseOrderSkuReportComments?PurchaseOrderCommentsID=" + Id + "&Operation=" + Operation,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    // End Testing
    getAllQcInfoList(Operation, TNAID, TestInspectionID) {
        return axios.get(window.$APIBaseURL + "TNA/getAllTestList?Operation=" + Operation + '&TNAID=' + TNAID + '&TestInspectionID=' + TestInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    // End Testing
    GetOrderList(TestInspectionID) {
        return axios.get(window.$APIBaseURL + "TNA/GetOrderList?TestInspectionID=" + TestInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }





    //
    UpdateTNA(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/UpdateTNA',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadTNAChildReferenceUnMappedList(Params) {

        return axios.get(window.$APIBaseURL + "TNA/GetLoadTNAChildReferenceUnMappedList?TaskName=" + Params.TaskName + "&TNAId=" + Params.TNAId + "&SubParentId=" + Params.SubParentId + "&ReferenceIdList=" + Params.ReferenceIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNAEditTaskWiseChildList(Params) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNAEditTaskWiseChildList?TNAId=" + Params.TNAId + "&TaskName=" + Params.TaskName, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSkuAndStyleDetails(Params) {
        return axios.get(window.$APIBaseURL + "TNA/GetSkuAndStyleDetails?TNAId=" + Params.TNAId + "&TaskName=" + Params.TaskName + "&MainId=" + Params.MainId + "&SubId=" + Params.SubId + "&StrikeOffIdOrTrimInfoId=" + Params.StrikeOffIdOrTrimInfoId + "&StyleId=" + Params.StyleId + "&SKUIdList=" + Params.SKUIdList + "&StyleStrikeOffGarmentSizeIdList=" + Params.StyleStrikeOffGarmentSizeIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateLabdipSubmissionApproval(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateLabdipSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateStrikeoffSubmissionApproval(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateStrikeoffSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTrimSubmissionApproval(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTrimSubmissionApproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    TaskHolderList(Tnaid) {
        return axios.get(window.$APIBaseURL + "TNA/GetTNATaskHolderList?TNAId=" + Tnaid, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    LapdipSubmissionList(Operation, LabdibFabricInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetLabdipSubmissionList?Operation=" + Operation + "&LabdibFabricInfoId=" + LabdibFabricInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    StrikeoffSubmissionList(Operation, TNAStrikeOffRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetStrikeoffSubmissionList?Operation=" + Operation + "&TNAStrikeOffRefInfoId=" + TNAStrikeOffRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    TrimSubmissionList(Operation, TNATrimRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetTrimSubmissionList?Operation=" + Operation + "&TNATrimRefInfoId=" + TNATrimRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetStrikeoffDimension(Operation, TNAStrikeOffRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetStrikeoffDimension?Operation=" + Operation + "&TNAStrikeOffRefInfoId=" + TNAStrikeOffRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetTrimDimension(Operation, TNATrimRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetTrimDimension?Operation=" + Operation + "&TNATrimRefInfoId=" + TNATrimRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }


    GetLabdipSubmissionOrderInfoList(Operation, LabdibFabricInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetLabdipSubmissionOrderInfoList?Operation=" + Operation + "&LabdibFabricInfoId=" + LabdibFabricInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetStrikeoffSubmissionOrderInfoList(Operation, TNAStrikeOffRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetStrikeoffSubmissionOrderInfoList?Operation=" + Operation + "&TNAStrikeOffRefInfoId=" + TNAStrikeOffRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetTrimSubmissionOrderInfoList(Operation, TNATrimRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetTrimSubmissionOrderInfoList?Operation=" + Operation + "&TNATrimRefInfoId=" + TNATrimRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetLabdipSubmissionSubmittedOption(Operation, ID) {
        return axios.get(window.$APIBaseURL + "TNA/GetLabdipSubmissionSubmittedOption?Operation=" + Operation + "&ID=" + ID, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    GetStrikeoffSubmissionSubmittedOption(Operation, ID) {
        return axios.get(window.$APIBaseURL + "TNA/GetStrikeoffSubmissionSubmittedOption?Operation=" + Operation + "&ID=" + ID, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    GetTrimSubmissionSubmittedOption(Operation, ID) {
        return axios.get(window.$APIBaseURL + "TNA/GetTrimSubmissionSubmittedOption?Operation=" + Operation + "&ID=" + ID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetStrikeoffColorAppComments(Operation, ID) {
        return axios.get(window.$APIBaseURL + "TNA/GetStrikeoffColorAppComments?Operation=" + Operation + "&ID=" + ID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetTrimColorAppComments(Operation, ID) {
        return axios.get(window.$APIBaseURL + "TNA/GetTrimColorAppComments?Operation=" + Operation + "&ID=" + ID, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    ProtoFitSMSSubmissionList(Operation, TNAProtoFitId) {
        return axios.get(window.$APIBaseURL + "TNA/GetProtoFitSMSSubmissionList?Operation=" + Operation + "&TNAProtoFitId=" + TNAProtoFitId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetProtoFitSMSApprovalList(Operation, TNAProtoFitId) {
        return axios.get(window.$APIBaseURL + "TNA/GetProtoFitSMSApprovalList/?Operation=" + Operation + "&TNAProtoFitId=" + TNAProtoFitId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetProtoFitSMSSubmission(Operation, TNAProtoFitId) {

        return axios.get(window.$APIBaseURL + "TNA/GetProtoFitSMSSubmission?Operation=" + Operation + "&TNAProtoFitId=" + TNAProtoFitId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    AppearenceTestList(TNASmsInfoId) {
        return axios.get(window.$APIBaseURL + "TNA/GetAppearenceTestInfo?TNASmsInfoId=" + TNASmsInfoId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetEditAppearenceTestInfo(TNASmsInfoId) {
        return axios.get(window.$APIBaseURL + "TNA/GetEditAppearenceTestInfo?TNASmsInfoId=" + TNASmsInfoId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    InsertUpdateAppearenceTest(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateAppearenceTest',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateTaskManualCloseDetails(Params) {

        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/UpdateTaskManualCloseDetails',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    StylePurchaseOrderTNAList(Params) {
        return axios.get(window.$APIBaseURL + "TNA/GetStylePurchaseOrderTNAList?StyleId=" + Params.StyleID + "&PurchaseOrderId=" + Params.PurOrdId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    ViewLabdipSubmissionApprovalList(LabdibFabricInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetViewLabdipSubmissionApprovalList?LabdibFabricInfoId=" + LabdibFabricInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    ViewStrikeoffSubmissionApprovalList(TNAStrikeOffRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetViewStrikeoffSubmissionApprovalList?TNAStrikeOffRefInfoId=" + TNAStrikeOffRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    ViewTrimSubmissionApprovalList(TNATrimRefInfoId, IsProduction) {
        return axios.get(window.$APIBaseURL + "TNA/GetViewTrimSubmissionApprovalList?TNATrimRefInfoId=" + TNATrimRefInfoId + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    ViewProtoFitSMSSubmissionApprovalList(Operation, TNAProtoFitOrSmsId) {
        return axios.get(window.$APIBaseURL + "TNA/GetViewProtoFitSMSSubmissionApprovalList?Operation=" + Operation + "&TNAProtoFitOrSmsId=" + TNAProtoFitOrSmsId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetSubChildMultiTaskManualCloseList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetSubChildMultiTaskManualCloseList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSubChildMultiTaskSubmissionApprovalList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetSubChildMultiTaskSubmissionApprovalList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    getManualApproval(ManId, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/getManualApproval?ManId=" + ManId + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    getExistingApproval(ExtId, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/getExistingApproval?ExtId=" + ExtId + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    getTNATaskReviceList(TNAId, TaskDetailId) {
        return axios.get(window.$APIBaseURL + "TNA/getTNATaskReviceList?TNATaskDetailId=" + TaskDetailId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    InsertUpdateDeleteTNATaskRevice(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateDeleteTNATaskRevice',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTNAManualCloseSubmissionList(TaskInfoId, TaskName) {
        return axios.get(window.$APIBaseURL + "TNA/getTNAManualCloseSubmissionList?TaskInfoId=" + TaskInfoId + "&TaskName=" + TaskName, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    getTNATaskRescheduleList(TNAId, TaskDetailId) {
        return axios.get(window.$APIBaseURL + "TNA/getTNATaskRescheduleList?TNATaskDetailId=" + TaskDetailId, {
            headers: {
                authorization: authHeader()
            }
        });

    }
    InsertUpdateDeleteTNATaskReschedule(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateDeleteTNATaskReschedule',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    getTNADependencyStartList(TNAId, TaskID, ChildId, IsSingleTask) {
        return axios.get(window.$APIBaseURL + "TNA/getTNADependencyStartList?TNAId=" + TNAId + "&TaskID=" + TaskID + "&CommonId=" + ChildId + "&IsSingleTask=" + IsSingleTask, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    InsertTNADependencyStartHistory(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertTNADependencyStartHistory',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertTNAMultiDependencyStartHistory(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertTNAMultiDependencyStartHistory',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateLabdipSwatchStatus(LabdibFabricInfoId, Status, SwatchCardImageName, SwatchCardIamgePath, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/UpdateSwatchStatus?ChildId=" + LabdibFabricInfoId + "&Status=" + Status
            + "&SwatchCardImageName=" + SwatchCardImageName + "&SwatchCardIamgePath=" + SwatchCardIamgePath + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetChildId(ChildId, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/GetChildId?ChildId=" + ChildId + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    UpdateStrikeoffSwatchStatus(TNAStrikeOffRefInfoId, Status, SwatchCardImageName, SwatchCardIamgePath, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/UpdateSwatchStatus?ChildId=" + TNAStrikeOffRefInfoId + "&Status=" + Status
            + "&SwatchCardImageName=" + SwatchCardImageName + "&SwatchCardIamgePath=" + SwatchCardIamgePath + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    UpdateTrimSwatchStatus(TNATrimOffRefInfoId, Status, SwatchCardImageName, SwatchCardIamgePath, Operation) {
        return axios.get(window.$APIBaseURL + "TNA/UpdateSwatchStatus?ChildId=" + TNATrimOffRefInfoId + "&Status=" + Status
            + "&SwatchCardImageName=" + SwatchCardImageName + "&SwatchCardIamgePath=" + SwatchCardIamgePath + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });

    }


    GetNewPOStyleListNotMappedTNA(Params) {
        return axios.get(window.$APIBaseURL + "TNA/GetNewPOStyleListNotMappedTNA?BrandID=" + Params.BrandID + "&BuyerID=" + Params.BuyerID + "&SeasonID=" + Params.SeasonID + "&SupplierID=" + Params.SupplierId + "&StyleID=" + Params.StyleId, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    UpdateNewPOStyleINTNA(Params) {
        return axios.get(window.$APIBaseURL + "TNA/UpdateNewPOStyleINTNA?TNAId=" + Params.TNAId + "&POStyleList=" + Params.POStyleList + "&Createdby=" + Params.Createdby, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSubChildMultExFactoryFormSKUList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetMultExFactoryFormSKUList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetTestingMultExFactorySKUList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetTestingExFactorySKUList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetCheckProgramSKUList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetCheckProgramSkuList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    //Testting module
    GetTestingMultiSKUList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/GetTNATestingQualityinfoLsit',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    UpdateExFactoryByIdList(TNAExFactoryIdList) {
        return axios.get(window.$APIBaseURL + "TNA/UpdateExFactoryByIdList?TNAExFactoryIdList=" + TNAExFactoryIdList, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadExistingPurchaseOrderSKUList(TNAId, StyleId) {

        return axios.get(window.$APIBaseURL + "TNA/LoadExistingPurchaseOrderSKUList?TNAId=" + TNAId + "&StyleId=" + StyleId, {
            headers: {
                authorization: authHeader()
            }
        });

    }

    LoadStyleBasedIDPO(StyleID) {
        return axios.get(window.$APIBaseURL + "TNA/LoadStyleBasedIDPO?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTestDetailsReportList(Operation, IsProduction, TaskDetailsName, ExfacStartDate, ExfacEndDate, TestStartDate, TestEndDate, TNAStartDate, TNAEndDate) {

        return axios.get(window.$APIBaseURL + "TNA/GetTestDetailsReportList?Operation=" + Operation + '&IsProduction=' + IsProduction + '&TaskDetailsName=' +
            TaskDetailsName + '&ExfacStartDate=' + ExfacStartDate + '&ExfacEndDate=' + ExfacEndDate + '&TestStartDate=' + TestStartDate + '&TestEndDate=' + TestEndDate +
            '&TNAStartDate=' + TNAStartDate + '&TNAEndDate=' + TNAEndDate, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetTestEntryDetailsReportList(IsProduction, Taskname, PoStartDate, PoEndDate, ApStartDate, ApEndDate, ApprovedStatus) {
        return axios.get(window.$APIBaseURL + "TNA/GetTestInspectionList?IsProduction=" + IsProduction + '&Taskname=' + Taskname + '&PoStartDate=' + PoStartDate + '&PoEndDate=' + PoEndDate + '&ApStartDate=' + ApStartDate + '&ApEndDate=' + ApEndDate + '&ApprovedStatus=' + ApprovedStatus, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetInspectionandApprovalList(Operation, Id, Taskname) {
        return axios.get(window.$APIBaseURL + "TNA/GetTestInspectionandApprovalList?Operation=" + Operation + "&PoSKUID=" + Id + "&Taskname=" + Taskname,
            {
                headers: {
                    authorization: authHeader()
                }
            });
    }
    LoadSKUNames() {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/LoadSKUNames',
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertUpdateTestreportComment(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTestreportComment',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTestPurchaseOrderSkuReportComments(PurchaseOrderCommentsID, TaskType) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/GetTestPurchaseOrderSkuReportComments?PurchaseOrderCommentsID=' + PurchaseOrderCommentsID + '&TaskType=' + TaskType,
            headers: {
                authorization: authHeader()
            }
        });
    }

    InsertTestEntryDetailsComments(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'TNA/InsertUpdateTestingapproval',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTestApprovalList1(InsepctionID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/GetTestApprovalList1?TestInspectionID=' + InsepctionID,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetTestInfoDetails(BuyerID, BrandID, TestTypeID, StyleID, TestingInspectionID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/GetTestInfoDetails?BuyerID=' + BuyerID + '&BrandID=' + BrandID + '&TestTypeID=' + TestTypeID + '&StyleID=' + StyleID + '&TestingInspectionID=' + TestingInspectionID,
            headers: {
                authorization: authHeader()
            }
        });

    }

    GetTestDetailedInfo(BuyerID, BrandID, TestTypeID, ID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/GetTestDetailedInfo?BuyerID=' + BuyerID + '&BrandID=' + BrandID + '&TestTypeID=' + TestTypeID + '&ID=' + ID,
            headers: {
                authorization: authHeader()
            }
        });

    }

    LoadGradeDetail() {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/LoadGradeDetail',
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadTestInfoExternal(Operation) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/TNAExternalTestDetails?Operation=' + Operation,
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadTestInfoDetails(TNATestInsepctionID, TNAID, TestTypeID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/TNABasedTestingInfoDetails?TNATestInsepctionID=' + TNATestInsepctionID + '&TNAID=' + TNAID + '&TestTypeID=' + TestTypeID,
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetTestReInspectionDetailList(Operation, TestInspectionID, TNAId, TasKname) {
        return axios.get(window.$APIBaseURL + "TNA/GetReInspectionDetailList?Operation=" + Operation + "&TestInspectionID=" + TestInspectionID + "&TNAId=" + TNAId + '&TaskName=' + TasKname, {
            headers: {
                authorization: authHeader()
            }
        });
    }


    GetGSMCountFabric(TestInspectionID) {
        return axios.get(window.$APIBaseURL + "TNA/GetGSMCountFabric?TestInspectionID=" + TestInspectionID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadUOMInfo(TNATestInsepctionID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/LoadUOMInfo?TNATestInsepctionID=' + TNATestInsepctionID,
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadNewStyleProgramtask(Tnaid, StyleId, Operation, TaskNameID) {
        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'TNA/LoadStyleProgramtask?TNAID=' + Tnaid + "&StyleID=" + StyleId + '&Operation=' + Operation + '&TaskNameID=' + TaskNameID,
            headers: {
                authorization: authHeader()
            }
        });
    }



}

export default new TNAService();
