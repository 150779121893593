import React, { useState, useEffect, Fragment } from "react";
import ReactNotification from "react-notifications-component";
import Nodify from "../../../Common/ReactNotification";
import ProductFabricService from "../../../../services/Master/ProductFabricService";
import { useSelector } from "react-redux";

const AddProductFabricType = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const menulist = useSelector((state) => state.auth.UserMenuList.item3);
    const activeMenu = menulist?.filter((i) => i.menuName === "ProductFabricType");

    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getProductFabricType, setProductFabricType] = useState([{ productFabricTypeID: 0, productFabricTypeName: "" }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    debugger
    useEffect(() => {
        ProductFabricService.GetProductFabricList().then((response) => {
            if (response.data) {
                setExistingList(response.data);
            }
        });
    }, []);

    const handleAddFields = (index) => {
        const values = [...getProductFabricType];
        if (values[index].productFabricTypeName.trim() !== "") {
            values.push({ productFabricTypeID: 0, productFabricTypeName: "" });
            setProductFabricType(values);
            setSubmitted(false);
        } else {
            Nodify("Warning!", "warning", "Please fill the mandatory(*) fields.");
            setSubmitted(true);
        }
    };

    const handleInputChange = (index, event) => {
        const values = [...getProductFabricType];
        values[index].productFabricTypeName = event.target.value.trim() !== "" ? event.target.value : "";
        setProductFabricType(values);
    };

    const handleRemoveFields = (index) => {
        const values = getProductFabricType.filter((_, i) => i !== index);
        setProductFabricType(values);
    };

    const ResetOperation = (action) => {
        if (action === "Back") {
            props.history.push("/ProductFabricTypeList");
        } else {
            setProductFabricType([{ productFabricTypeID: 0, productFabricTypeName: "" }]);
            setSubmitted(false);
        }
    };

    const CheckDuplicate = (index) => {
        const values = [...getProductFabricType];
        const inputValue = values[index].productFabricTypeName?.trim().toLowerCase();

        if (
            ExistingList.some(
                (existing) => existing.productFabricTypeName?.trim().toLowerCase() === inputValue
            )
        ) {
            values[index].productFabricTypeName = "";
            setProductFabricType(values);
            Nodify("Warning!", "warning", "This Fabric Type Name already exists.");
            return false;
        }

        if (
            getProductFabricType.some(
                (item, i) => i !== index && item.productFabricTypeName.trim().toLowerCase() === inputValue
            )
        ) {
            values[index].productFabricTypeName = "";
            setProductFabricType(values);
            Nodify("Warning!", "warning", "This Fabric Type Name is already in the list.");
            return false;
        }

        return true;
    };

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ProductFabricTypeList",
            state: { message: Func },
        });
    };

    const SaveProductFabricTypeList = (e) => {
        debugger
        e.preventDefault();
        setButtonLoader(true);

        if (getProductFabricType.some((item) => item.productFabricTypeName.trim() === "")) {
            setSubmitted(true);
            setButtonLoader(false);
            Nodify("Warning!", "warning", "Please fill all mandatory(*) fields.");
            return;
        }

        const InputCommonMaster = {
            ProductFabricInformation: getProductFabricType.map(item => ({
                Id: item.productFabricTypeID,
                Name: item.productFabricTypeName,
            })),
            Createdby: currentUser.employeeinformationID,
        };

        debugger
        ProductFabricService.InsertUpdateProductFabric(InputCommonMaster)
            .then((res) => {
                //SearchRetain(page);
            let Func = "Add";

                if (res.data.outputResult === "1") {
                    Nodify("Success!", "success", "Fabric Type added successfully.");
                    PageRedirect(Func);

                    //props.history.push("/ProductFabricTypeList");
                } else if (res.data.outputResult === "-1") {
                    Nodify("Warning!", "warning", "This Fabric Type Name already exists.");
                } else {
                    Nodify("Error!", "danger", "An error occurred. Please try again.");
                }
                setButtonLoader(false);
            })
            .catch(() => {
                Nodify("Error!", "danger", "Failed to save. Please check your connection and try again.");
                setButtonLoader(false);
            });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} Fabric Type </h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <form>
                                    {getProductFabricType.map((inputField, index) => (
                                        <Fragment key={`${inputField.Id}_${index}`}>
                                            <div className="row row_left10">
                                                <div className="col-sm-3 divder_style">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Fabric Type Name"
                                                            maxLength="50"
                                                            value={inputField.productFabricTypeName}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                            onBlur={() => CheckDuplicate(index)}
                                                            style={{
                                                                border:
                                                                    submitted && !inputField.productFabricTypeName
                                                                        ? "1px solid red"
                                                                        : "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 paddingstatic">
                                                    {getProductFabricType.length > 1 && (
                                                        <div className="col-lg-1_own col-xs-12">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                title="Delete Fabric Type Name"
                                                                onClick={() => handleRemoveFields(index)}
                                                            >
                                                                <i className="fa fa-trash-o"></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {index === getProductFabricType.length - 1 && (
                                                        <div className="col-lg-1_own col-xs-12">
                                                            <button
                                                                type="button"
                                                                className="btn btn-success"
                                                                title="Add Fabric Type Name"
                                                                onClick={() => handleAddFields(index)}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Fragment>
                                    ))}
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span
                                                className="btn btn-primary"
                                                onClick={() => ResetOperation("Back")}
                                            >
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={ResetOperation}>
                                                <i className="fa fa-times"></i>
                                                &nbsp;Reset
                                            </span>
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                disabled={buttonLoader}
                                                onClick={SaveProductFabricTypeList}
                                            >
                                                <i className="fa fa-check right"></i>&nbsp;{ButtonName}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProductFabricType;
