import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment from "moment";
import { LoadFinalInspectionList, LoadFlDetailsreport, UpdateCommentsreportViewAllCheckBox, UpdateCommentsreportViewCheckBox, LoadReFinalInspectionList } from "../../../../actions/finalInspection";
import Nodify from "../../../Common/ReactNotification";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import { Pagination, Search, PageCount, ReactSearch } from "../../../Datatable";
import TableHeader from '../../../Datatable/Header/commetstyle';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ReportService from "../../../../services/Report/ReportService";
import * as XLSX from 'xlsx';
import axios from "axios";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FLReportSkuComments, FLReportSkuOrderStatus, FinalinspectionID, FLApprovalComment } from "../../../Common/PageActionNumber";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";
import AddInspection from "../../../Production/Inspection_Quality Control/InspectionQC/AddInspection";
import ReInspection from "../../../Production/Inspection_Quality Control/InspectionQC/ReInspection";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { loadTNADetailList, GetSubChildMultExFactoryFormSKUList } from "../../../../actions/tna";
import StrikeoffService from "../../../../services/Style/StrikeoffService";
import AqlList from "../../../Master/AQL/AqlList";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import TNAService from "../../../../services/TNA/TNAService";
const ExcelJS = require("exceljs");


const FinalInspectionEntry = ({ props }) => {
    //)
    const [Settingpopupopen, setSettingpopupopen] = useState(false)
    const [getinpsectionID, setinpsectionID] = useState()
    const [settings, setSettings] = useState([
        { isSelected: true, Name: 'Factory Report Confirmarion' },
        { isSelected: true, Name: 'Report PDF - Primary pages.' },
        { isSelected: true, Name: 'Report PDF - All pages.' },
    ]);
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [getSeasonList, setSeasonList] = useState();
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const comments = [];
    const [getheaderName, setheaderName] = useState("");
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [getImagename, setImagename] = useState();
    const [isOpenimg, setIsOpenimg] = useState(false);
    const [showmodal, setShowmodal] = useState(false);
    const [selectstatus, setselectstatus] = useState({ value: 3, label: "Minor" });
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    //const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [selectedOption, setSelectedOptions] = useState({ value: 1, label: 'Yet to Start' })
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [Submitted, setSubmitted] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [getOrdStatusID, setOrdStatusID] = useState({ showPopupDelete: false, Params: {} });
    const [getInspectionInfo, setInspectionInfo] = useState({});
    const [getIDSingleTask, setIDSingleTask] = useState({ showPopupDelete: false, Params: {} });
    const [getIDfinalEntry, setIDfinalEntry] = useState({ showPopupDelete: false, Params: {} });
    const [getQARemark, setQARemark] = useState({ ReceivedDate: '', });
    const [getIDcomment, setIDcomment] = useState({ showPopupDelete: false, Params: {} });
    const [checkboxStates, setCheckboxStates] = useState({
        CreatedDate: true,
        EntryNo: true,
        InspectionType: true,
        ReInspectionDoneAgainst: true,
        Buyer: true,
        Brand: true,
        Season: true,
        Supplier: true,
        Factory: true,
        CustomerShipmentRefNo: true,
        IDNo: true,
        StyleName: true,
        StyleNo: true,
        SKUName: true,
        InspectionQuantity: true,
        InspectionResult: true,
        CreatedBy: true,
        ApprovalResult: true,
        TypeInterOrExtern: true
    });


    const [getHideID, setHideID] = useState({
        Po: true,
        season: true,
        Quarter: true,
        brand: true,
        customer: true,
        supplier: true,
        idNo: true,
        styleName: true,
        styleNo: true,
        skuName: true,
        skuImg: true,
        size: true,
        orderQty: true,
        exFac: true,
        orderStatus: true,
        currentStatus: true,
        cubeEmp: true,
        buyerEmp: true,
        stsCreated: true,
        cmtCreated: true,
        skuID: true,
        category: true,

    });
    const [EditedCommentIndex, setEditedCommentIndex] = useState('');
    const [checked, setchecked] = useState('0');
    const [table, settable] = useState('0');
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(true);
    const [seasoncheck, setseasoncheck] = useState(true)
    const [Quartercheck, setQuartercheck] = useState(true)
    // const datepickerRef = useRef();
    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [skuqtylist, setskuqtylist] = useState([]);
    const [getskuid, setskuid] = useState({ skuID: 0, qrty: 0 });
    // FOR COMMENTS
    const [Commentpopup, setCommentpopup] = useState(false);
    const [getComment, setComment] = useState([])
    //const [getHeaderName, setHeaderName] = useState("");
    const [getpurchaseId, setPurchaseId] = useState("0");
    const [getSupplierID, setSupplierID] = useState("0")
    const [getindex, setindex] = useState(0);
    const [getExfacindex, setExfacindex] = useState(0);
    const [getFIRemark, setFIRemark] = useState([])
    const [getExFactoryList, setExFactoryList] = useState([])
    const [getApprovalList, setApprovalList] = useState([])
    const [Ecomment, setFIRemarks] = useState();
    const [Approvallist, setExfacList] = useState([]);
    const [getnewfinalReportlist, setnewfinalReportlist] = useState([]);
    const [getnewheaderdata, setnewheaderdata] = useState([]);
    //End Comments
    const [getFiles, setFiles] = useState([])
    //For OrderStatus
    const [selectedOptionStatus, setSelectedOptionsStatus] = useState({ value: 0, label: 'Select Order Status' })
    const [selectedExfactory, setSelectedExfactory] = useState()
    const [getSelectedApproval, setSelectedApproval] = useState(0)
    const [getExfactoryModal, setExfactoryModal] = useState(false);
    const [getorderStatus, setorderStatus] = useState([]);
    const [getordindex, setOrdindex] = useState(0);
    //End OrdStatus

    const EmptyInputFields = [];

    const [inputFields, setInputFields] = useState(EmptyInputFields);

    const emptyFields = [
        {
            TNAFinalinsMeasurementMainId: 0,
            MeasurementCheckedPeices: '',
            StyleMeasurementID: 0,
            MeasurementSpec: '',
            SkuName: '',
            Category: [{
                StyleMeasurementCategoryID: 0,
                MeasurementCategoryID: "0",
                CategoryTypeName: '',
                CategoryIndexName: '',
                SkuName: '',
                CurrentSize: '',
                SizeHeaderNames: [],
                StyleMeasurementID: 0,
                CategoryRowNo: 0,
                Remark: '',
                Point: [{
                    TNAFinalinsMeasurementPointsId: 0,
                    MeasurementPoints: 0,
                    MeasurementGranding: '',
                    TolerancePlus: '',
                    ToleranceMinus: '',
                    GradingSizes: '',
                    GradingSizesId: '',
                    GradingSizesValue: '',
                    arrGradingSizes: [],
                    CategoryIndexName: '',
                    SkuName: '',
                    SizeValues: [],
                    StyleMeasurementID: 0,
                    CategoryTypeName: '',

                }]
            }],
        }
    ];

    const datePickerRefApp = useRef(null);
    const datePickerRefIns = useRef(null);
    const datePickerPo = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRefIns.current && !datePickerRefIns.current.contains(event.target)) {
                setStateDate(prev => [{ ...prev[0], isShow: false }]);
            }
            if (datePickerRefApp.current && !datePickerRefApp.current.contains(event.target)) {
                setStateApDate(prev => [{ ...prev[0], isShow: false }]);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    function handleChangeQARemarks(e, FieldName, result) {
        debugger
        let inputText = '';
        const values = { ...getQARemark }
        if (FieldName === 'ReceivedDate') {
            if (e !== null) {
                values.ReceivedDate = e;
            }
            else {
                values.ReceivedDate = null;
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.Comments = inputText;
        }
        setQARemark(values);
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="MM/DD/YYYY"
            />
        )
    }
    const [Measurementdata, setMeasurementdata] = useState(emptyFields);
    const values = [...Measurementdata];

    const Input = { ...inputFields };

    const [inspectionstatus, setinspectionstatus] = useState();


    const [getMessureValue, setMessureValue] = useState([]);
    const [isStyleMeasurementempty, setisStyleMeasurementempty] = useState(false);

    // PO/season/Quarter
    //const [getHideID, setHideID] = useState({ Po: true, season: true, Quarter: true });
    const Values = { ...getComment }
    const OrdStatus = { ...showmodal }
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End

    //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let InternalComments;
    let info;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) {
        info = IsProduction === 1 ? 'InternalFinalInspection' : (IsProduction === 2 ? 'InternalFinalInspection' : undefined);
        activeMenu = menulist.filter(i => i.menuName === info);

        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'InspectionEntry') { InternalComments = activeMenuSub[index]; }
                    //InternalComments = activeMenuSub[index];
                }
            }
        }

    }
    // End Enable Menu



    let InspectionandApproval = [
        {
            value: 1,
            label: 'Inspection Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'Inspection Progressing',
            selected: false,

        },
        {
            value: 3,
            label: 'Inspection Pass',
            selected: false,

        },
        {
            value: 4,
            label: 'Inspection Fail',
            selected: false,

        },
        {
            value: 5,
            label: 'New Inspection',
            selected: false,

        },
        , {
            value: 6,
            label: 'Re Inspection',
            selected: false,

        }, {
            value: 7,
            label: 'Re Inspection Pending',
            selected: false,

        }, {
            value: 8,
            label: 'Inspection Under Approval',
            selected: false,

        },
        {
            value: 9,
            label: 'Inspection Approved',
            selected: false,

        }
        ,
        {
            value: 10,
            label: 'Inspection Commercially Approved',
            selected: false,

        }
        ,
        {
            value: 11,
            label: 'Inspection Rejected',
            selected: false,

        }
        ,
        {
            value: 12,
            label: 'Balance for New Inspection',
            selected: false,

        }
        ,
        {
            value: 13,
            label: 'Balance for Re Inspection',
            selected: false,

        }
    ]





    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultApStartDate = new Date();
    // const [stateApDate, setStateApDate] = useState([
    //     {

    //         startDate: null,//defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
    //         endDate: new Date(''),
    //         key: 'selection',
    //         isShow: false
    //     }
    // ]);
    const [stateApDate, setStateApDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const [ShowMsrtabl, setShowMsrtabl] = useState(false);



    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = useSelector((state) => state.auth);

    let IsProductionStatus = [
        {
            value: 1,
            label: 'Sampling',
            selected: true,

        },
        {
            value: 2,
            label: 'Production',
            selected: false,

        }
    ]


    async function delApprovalCallback(value) {

        if (value) {
            let StartDate = null;
            let EndDate = null;

            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            var defaultAppStartDate = new Date();
            defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
            if (moment(defaultAppStartDate).isValid()) {
                StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }

            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction, PoStartDate: StartDate,
                PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
            }

            FinalinspectionService.GetFIApprovalHistory(getheaderName[0].FinalInspectionID).then(async (response) => {
                if (response.data) {
                    setFIRemark(response.data)
                }
                hideLoader();
            }).catch(() => { hideLoader(); });
            await FinalinspectionService.LoadGetFLApproval().then((res) => {
                if (res.data) {
                    res.data.map((x) => {
                        Approvallist.push({
                            value: x.value,
                            label: x.label
                        })
                    })
                    setApprovalList(Approvallist);
                }
                hideLoader();
            })
            setCommentpopup(false)
            dispatch(LoadFinalInspectionList(params));
        }
        else {
            setIDcomment({ showPopupDelete: false, Params: [] });
        }
    }


    const delCallbackfinalins = (value) => {

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }

        if (value) {
            //dispatch(LoadFlDetailsreport(params));
            dispatch(LoadFinalInspectionList(params));
        } else {
            setIDfinalEntry({ showPopupDelete: false, Params: [] });
        }
    }

    async function delCallback(value) {

        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartExDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndExDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        if (value) {

            await FinalinspectionService.GetFLPurchaseOrderSkuReportComments(getpurchaseId, 1).then(async (response) => {
                if (response.data) {
                    setFIRemark(response.data.flReportCommentDetails)
                    //setFiles(response.data.flReportfileupload)
                }
                if (response.data.flReportCommentDetails.length === 0) {
                    setCommentpopup(false)
                }
            }).catch(() => { });

            dispatch(LoadFlDetailsreport(params));
        } else {
            setID({ showPopupDelete: false, Params: [] });
        }
    }


    const handleSaveApproval = (e, comment, FinalInspectionID) => {
        debugger
        const values = { ...getQARemark }
        if (values.ReceivedDate === '' || getQARemark.ReceivedDate === undefined || comment === undefined || comment === '' || getSelectedApproval === undefined || getSelectedApproval.value === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {
            // // var Data = [];
            let ReceivedDate = getQARemark.ReceivedDate;
            if (ReceivedDate) {
                ReceivedDate.setMinutes(ReceivedDate.getMinutes() + 370);
            }
            var CommentInformationData = [];
            CommentInformationData.push({
                FLApprovalID: 0,
                ApprovalRemarks: comment,
                ApprovalID: getSelectedApproval,
                ReceivedDate: ReceivedDate
                //IsLatest : 1
            });

            var data = { CreatedBy: currentUser.employeeinformationID, FinalInspectionID: parseInt(FinalInspectionID), ApprovalListInformation: CommentInformationData };

            FinalinspectionService.InsertUpdateApproval(data).then((res) => {
                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', "Comment Added for " + FinalInspectionID + " Successfully");
                    setCommentpopup(false);
                    setFIRemarks('');
                    let StartDate = null;
                    let EndDate = null;

                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                    }

                    let StartApDate = null;
                    let EndApDate = null;
                    var defaultAppStartDate = new Date();
                    defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
                    if (moment(defaultAppStartDate).isValid()) {
                        StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateApDate[0].endDate).isValid()) {
                        EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }

                    const currentDate = new Date();
                    const formattedDate = moment(currentDate).format('MM/DD/YYYY');
                    let params = {
                        IsProduction: IsProduction, PoStartDate: StartDate,
                        PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
                    }
                    let Paramsone = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 3 }
                    setSelectedApproval("");
                    dispatch(LoadFinalInspectionList(params));
                    dispatch(LoadReFinalInspectionList(Paramsone));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

        //setEditedCommentIndex('');
    };
    //  Grid Data
    const toDataURL = (url) => {

        const promise = new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.onloadend = function () {
                    resolve({ base64Url: reader.result });
                };
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        });

        return promise;
    };

    // const [dataJson, setData] = useState([]);
    // useEffect(() => {
    //     fetch("https://dummyjson.com/products")
    //         .then((res) => res.json())
    //         .then(async (dataJson) => {
    //             console.log(dataJson);
    //             setData(dataJson);
    //         })
    //         .then((json) => console.log(json));
    // }, []);
    //
    //Export Excel

    let ImageShow = [
        {
            Id: 1,
            url: 'https://i.dummyjson.com/data/products/8/thumbnail.jpg',
            value: 'Balck',
            Position: 'Front'
        },
        {
            Id: 1,
            url: 'https://i.dummyjson.com/data/products/9/thumbnail.jpg',
            value: 'Balck',
            Position: 'Back'
        }
    ]





    let JDImageShow = [
        {
            Id: 1,
            Url: 'https://i.dummyjson.com/data/products/8/thumbnail.jpg',
            value: 'Balck',
            Position: 'Front'


        },
        {
            Id: 1,
            Url: 'https://i.dummyjson.com/data/products/8/thumbnail.jpg',
            value: 'Balck',
            Position: 'Front'


        },
        {
            Id: 1,
            Url: 'https://i.dummyjson.com/data/products/8/thumbnail.jpg',
            value: 'Balck',
            Position: 'Front'


        }
    ]

    let DefectList = [
        {
            Name: 'Defect Level',
            value: 'Major',
            value1: 'Minor',
        },
        {
            Name: 'Foot Patrol',
            value: '1.5',
            value1: '2.5',
        },
        {
            Name: 'Nicholas Deakins',
            value: '1.5',
            value1: '2.5',
        }
        ,
        {
            Name: 'One True Saxon',
            value: '1.5',
            value1: '2.5',
        }
        ,
        {
            Name: 'Size? Essentials',
            value: '1.5',
            value1: '2.5',
        }
        ,
        {
            Name: 'Brasher',
            value: '1.5',
            value1: '2.5',
        }
        ,
        {
            Name: 'Berghaus',
            value: '1.5',
            value1: '2.5',
        }
        // ,
        // {
        //     Name: 'North Ridge',
        //     value: '1.5',
        //     value1: '2.5',
        // }
        // ,
        // {
        //     Name: 'OEX',
        //     value: '1.5',
        //     value1: '2.5',
        // }
        ,
        {
            Name: 'Align',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Duffer',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Fila',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Mckenzie',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Peter Storm',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'One Earth',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Pink Soda',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Sonneti',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Supply and Demand Womens',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'Technicals',
            value: '2.5',
            value1: '4',
        }
        ,
        {
            Name: 'SUR',
            value: '2.5',
            value1: '4',
        }
        // ,
        // {
        //     Name: 'The Edge',
        //     value: '2.5',
        //     value1: '4',
        // }
        // ,
        // {
        //     Name: 'Free Spirit ',
        //     value: '2.5',
        //     value1: '4',
        // }
        // ,
        // {
        //     Name: 'Royal Scot',
        //     value: '2.5',
        //     value1: '4',
        // }
        // ,
        // {
        //     Name: 'Westlake',
        //     value: '2.5',
        //     value1: '4',
        // }
        ,
        {
            Name: 'Nanny State',
            value: '4',
            value1: '6.5',
        }
        ,
        {
            Name: 'Supply and Demand Mens',
            value: '4',
            value1: '6.5',
        }
    ]

    let AQLevels = [
        {
            one: '151 to 280',
            two: '32',
            three: '1',
            four: '1',
            five: '2',
            six: '5',
        },
        {
            one: '281 to 500',
            two: '50',
            three: '1',
            four: '2',
            five: '3',
            six: '7',
        },
        {
            one: '501 to 1200',
            two: '80',
            three: '2',
            four: '3',
            five: '5',
            six: '10',
        },
        {
            one: '1201 to 3200',
            two: '125',
            three: '3',
            four: '5',
            five: '7',
            six: '14',
        },
        {
            one: '3201 to 10000',
            two: '200',
            three: '5',
            four: '7',
            five: '10',
            six: '21',
        },
        {
            one: '10001 to 35000',
            two: '315',
            three: '7',
            four: '10',
            five: '14',
            six: '21',
        },
        {
            one: '35001 to 150000',
            two: '500',
            three: '10',
            four: '14',
            five: '21',
            six: '21',
        },
        {
            one: '150001 to 500000',
            two: '800',
            three: '14',
            four: '21',
            five: '21',
            six: '21',
        },
    ]

    var borderStyles = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
    };

    var borderStyleslr = {
        left: { style: "thin" },
        right: { style: "thin" }
    };

    var borderStyleslrb = {
        left: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
    };

    var borderStylesb = {
        bottom: { style: "thin" },
    };

    var borderStylesr = {
        right: { style: "thin" }
    };

    var borderStylesl = {
        left: { style: "thin" }
    };

    let headerColor = '000042';
    let fontName = 'Helvetica';
    let fontSize = 11;
    let fontColor = '3333ff';
    let fontHeaderColor = 'ebeff5';

    const exportExcelFileJD = (data, ImageShow, measurementdata, skudetails, Styleid) => {

        let URL = window.$APIBaseURL + "Images";
        let fileName = 'JDExcelFormatFile';
        const workbook = new ExcelJS.Workbook();
        //const sheetNames = ["TEST GRID","INSPECTION REPORT", "STD PICS", "DEFECTIVE PICS", "MEASUREMENT LOG"];
        const sheetNames = ["SAMPLING PLAN", "AQL PAPERWORK", "PHOTOS", "MEASUREMENT"];
        //const sheetNames = ["Sampling plan"];

        sheetNames.forEach(sheetName => {
            const sheet = workbook.addWorksheet(sheetName);
            // I believe this needs to be set to show in LibreOffice.
            sheet.state = 'visible';
            if (sheetName === 'SAMPLING PLAN') {

                sheet.getColumn('A').width = 16;
                sheet.getColumn('B').width = 10.14;
                sheet.getColumn('C').width = 8.43;
                sheet.getColumn('D').width = 8.43;

                let logo = window.$APIBaseURL + 'Images/JD_logo.jpg';
                let logo1 = window.$APIBaseURL + 'Images/JD_logo1.jpg';
                let result; let result1;
                let array = [{ novalue: '' }]

                array.map(async x => {
                    result = await toDataURL(logo);

                    const image1 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'jpg',
                    });

                    result1 = await toDataURL(logo1);

                    const image2 = workbook.addImage({
                        base64: result1.base64Url,
                        extension: 'jpg',
                    });

                    sheet.addImage(image1, {
                        tl: { col: 0, row: 0 },
                        ext: { width: 110, height: 80 },

                    });

                    sheet.addImage(image2, {
                        tl: { col: 1, row: 0 },
                        ext: { width: 250, height: 80 },

                    });


                })

                //End First Row

                //// Second Row
                // sheet.getCell('A5').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "000042" },
                // };

                sheet.mergeCells('B5:D5');
                sheet.getCell('B5').value = 'Sampling Plan';
                sheet.getCell('B5').font = {
                    name: fontName,
                    color: { argb: '#0d0d0d' },
                    size: fontSize,
                    bold: true,

                };

                sheet.getCell('B5').alignment = { horizontal: 'center' };
                //End Second Row
                sheet.getCell('A6').value = '1) Select the Brand';
                sheet.getCell('A7').value = '2) Note the Major/Minor defect levels';

                let Start = 'A';
                let End = 'B';
                let SecondStart = 'C';
                let ThirdStart = 'D';
                for (let index = 0; index < DefectList.length; index++) {
                    Start += index + 9;
                    End += index + 9;
                    SecondStart += index + 9;
                    ThirdStart += index + 9;
                    let borderDesign;
                    if (index == 0) // First row Only BorderStyle
                    { borderDesign = borderStyles; }
                    else if (index == (DefectList.length - 1)) // Last row Only BorderStyle
                    { borderDesign = borderStyleslrb; }
                    else { borderDesign = borderStyleslr; }//In between row BorderStyle

                    // Backround Color
                    if (DefectList[index].value1 == '2.5') {
                        sheet.getCell(SecondStart).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "c9c6c5" },
                        };
                        sheet.getCell(ThirdStart).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "c9c6c5" },
                        };

                    }
                    else if (DefectList[index].value1 == '4') {
                        sheet.getCell(SecondStart).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "bab6b5" },
                        };
                        sheet.getCell(ThirdStart).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "bab6b5" },
                        };

                    }
                    else if (DefectList[index].value1 == '6.5') {

                        sheet.getCell(SecondStart).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "aba8a7" },
                        };
                        sheet.getCell(ThirdStart).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "aba8a7" },
                        };
                    }
                    sheet.mergeCells(Start, End);
                    sheet.getCell(Start).value = DefectList[index].Name
                    sheet.getCell(Start).border = borderDesign;
                    sheet.getCell(SecondStart).value = DefectList[index].value
                    sheet.getCell(SecondStart).border = borderDesign;
                    sheet.getCell(ThirdStart).value = DefectList[index].value1
                    sheet.getCell(ThirdStart).border = borderDesign;

                    Start = 'A';
                    End = 'B';
                    SecondStart = 'C';
                    ThirdStart = 'D';
                }

                sheet.getCell('A30').value = '3) Complete 1 AQL per PO number';
                sheet.getCell('A31').value = '4) Determine the Batch/lot size (The amount of product passed through final inspection and ready for dispatch)';
                sheet.getCell('A32').value = '5) Go to the next column to see the sample number to select randomly from cartons (max 5 pieces per carton)';
                sheet.getCell('A33').value = '6) Go across again to the relevant Brand major/minor defect levels to see the amount of defects allowed in the sample size.';

                sheet.mergeCells('C35:E35');
                sheet.getCell('C35').value = 'Acceptable Quality Levels';
                sheet.getCell('C35').border = borderStyles;


                sheet.getCell('A36').value = 'Lot/Batch size';
                sheet.getCell('A36').border = borderStyles;
                //sheet.getCell('A39').width = 120;


                sheet.getCell('B36').value = 'sample size';
                sheet.getCell('B36').border = borderStyles;
                //sheet.getCell('B39').width = 120;

                sheet.getCell('C36').value = '1.5';
                sheet.getCell('C36').border = borderStyles;
                sheet.getCell('D36').value = '2.5';
                sheet.getCell('D36').border = borderStyles;
                sheet.getCell('E36').value = '4';
                sheet.getCell('E36').border = borderStyles;
                sheet.getCell('F36').value = '6.5';
                sheet.getCell('F36').border = borderStyles;

                sheet.mergeCells('A37:F37');
                sheet.getCell('A37').value = 'Maximum number of defects allowed';
                sheet.getCell('A37').border = borderStyles;
                sheet.getCell('A37').alignment = { horizontal: 'right' };

                let AQStart = 'A';
                let AQEnd = 'B';
                let AQSecondStart = 'B';
                let AQSecondEnd = 'D';
                let AQThirdStart = 'C';
                let AQFourStart = 'D';
                let AQFiveStart = 'E';
                let AQSixStart = 'F';
                let Startposition = 38;
                for (let index = 0; index < AQLevels.length; index++) {
                    ;
                    AQStart += index + Startposition;
                    //AQEnd += index + Startposition;
                    AQSecondStart += index + Startposition;
                    // AQSecondEnd += index + Startposition;
                    AQThirdStart += index + Startposition;
                    AQFourStart += index + Startposition;
                    AQFiveStart += index + Startposition;
                    AQSixStart += index + Startposition;

                    //sheet.mergeCells(AQStart, AQEnd);
                    sheet.getCell(AQStart).value = AQLevels[index].one
                    sheet.getCell(AQStart).border = borderStyles

                    //sheet.mergeCells(AQSecondStart, AQSecondEnd);
                    sheet.getCell(AQSecondStart).value = AQLevels[index].two
                    sheet.getCell(AQSecondStart).border = borderStyles


                    sheet.getCell(AQThirdStart).value = AQLevels[index].three
                    sheet.getCell(AQThirdStart).border = borderStyles

                    sheet.getCell(AQFourStart).value = AQLevels[index].four
                    sheet.getCell(AQFourStart).border = borderStyles

                    sheet.getCell(AQFiveStart).value = AQLevels[index].five
                    sheet.getCell(AQFiveStart).border = borderStyles

                    sheet.getCell(AQSixStart).value = AQLevels[index].six
                    sheet.getCell(AQSixStart).border = borderStyles

                    AQStart = 'A';
                    AQEnd = 'B';
                    AQSecondStart = 'B';
                    AQSecondEnd = 'D';
                    AQThirdStart = 'C';
                    AQFourStart = 'D';
                    AQFiveStart = 'E';
                    AQSixStart = 'F';

                }

                sheet.getCell('A47').value = 'NB; 0 critical defects allowed';
                sheet.getCell('A49').value = 'For example; 2000pcs of Sonneti ready for inspection would be; Defect level 2.5 major/4 Minor';
                sheet.getCell('A50').value = 'Lot/batch size 1201 to 3200 so a sample size of 125 and 5 major/7 minor defects allowed.';
                sheet.getCell('A52').value = 'NB; Not Applicable for Hard goods';





            }

            else if (sheetName === 'AQL PAPERWORK') {

                // sheet.getCell('A1').width=520;
                // sheet.getCell('C1').width=120;
                // sheet.getCell('E1').width=120;
                // sheet.getCell('K1').width=120;

                sheet.getColumn('A').width = 40;
                sheet.getColumn('C').width = 50;
                sheet.getColumn('E').width = 40;
                sheet.getColumn('K').width = 40;




                // const columnsToResize = ['A','C', 'E', 'K'];
                // columnsToResize.forEach((columnKey) => {
                //   worksheet.columns[worksheet.getColumnKey(columnKey)].width = 45;
                // });




                let DefectclasiificationArr1 = [
                    {
                        FabricDying: 'Main body fabric/panel fabric/linings',
                        PrintingEmbFlockTransfer: 'Process carried out directly on to the garment/panels',
                        CuttingBundlingProductionprep: 'operations pre sewing/garment construction'
                    },
                    {
                        FabricDying: 'Fabric hole (missed stitch)',
                        PrintingEmbFlockTransfer: 'Missing print',
                        CuttingBundlingProductionprep: 'Lining cut too short'
                    },
                    {
                        FabricDying: 'Raw fabric edges showing',
                        PrintingEmbFlockTransfer: 'Unravelling embroidery thread',
                        CuttingBundlingProductionprep: 'Lining cut too tight'
                    },
                    {
                        FabricDying: 'Incorrect fabric quality',
                        PrintingEmbFlockTransfer: 'Air holes',
                        CuttingBundlingProductionprep: 'Facings cut too large/small'
                    },
                    {
                        FabricDying: 'Seam slippage',
                        PrintingEmbFlockTransfer: 'Mis-registration',
                        CuttingBundlingProductionprep: 'Garment cut unbalanced '
                    },
                    {
                        FabricDying: 'Snagging',
                        PrintingEmbFlockTransfer: 'Mis-aligned/positioned',
                        CuttingBundlingProductionprep: 'Knitted fabric not pre-relaxed'
                    },
                    {
                        FabricDying: 'Pilling',
                        PrintingEmbFlockTransfer: 'Poor/uneven coverage',
                        CuttingBundlingProductionprep: 'Wrong size zip - all size '
                    },
                    {
                        FabricDying: 'Incorrect fabric colour',
                        PrintingEmbFlockTransfer: 'Colour strike through',
                        CuttingBundlingProductionprep: 'Incorrect size label - all order/size'
                    },
                    {
                        FabricDying: 'Skewing/Spirality',
                        PrintingEmbFlockTransfer: 'Poor stitch density/coverage',
                        CuttingBundlingProductionprep: 'Missing component - all order/size'
                    },
                    {
                        FabricDying: 'Patchy/uneven dye',
                        PrintingEmbFlockTransfer: 'Too shiny/matt',
                        CuttingBundlingProductionprep: 'Uneven hem/panel cutting'
                    },
                    {
                        FabricDying: 'Fabric flaws',
                        PrintingEmbFlockTransfer: 'Incorrect colour matching',
                        CuttingBundlingProductionprep: 'Unfused components'
                    }
                    ,
                    {
                        FabricDying: 'Barre (shaded stripes)',
                        PrintingEmbFlockTransfer: 'Cracking print',
                        CuttingBundlingProductionprep: 'Shading panel to panel'
                    },
                    {
                        FabricDying: 'Shading within a panel',
                        PrintingEmbFlockTransfer: 'Colour migration through print',
                        CuttingBundlingProductionprep: 'Fabric cut off grain '
                    },
                    {
                        FabricDying: 'Fly (contrast rogue fibres)',
                        PrintingEmbFlockTransfer: 'Bowing print',
                        CuttingBundlingProductionprep: ''
                    },
                    {
                        FabricDying: 'Needle line',
                        PrintingEmbFlockTransfer: 'Incorrect size print/embroidery',
                        CuttingBundlingProductionprep: ''
                    },
                    {
                        FabricDying: 'Low fabric stretch/recovery',
                        PrintingEmbFlockTransfer: 'pressure marks/glaze',
                        CuttingBundlingProductionprep: ''
                    },
                    {
                        FabricDying: 'Shade variation hood to body',
                        PrintingEmbFlockTransfer: 'Fiber dust at print',
                        CuttingBundlingProductionprep: ''
                    }


                ]

                let DefectclasiificationArr2 = [
                    {
                        SewingConstruction: 'operations on the production line',
                        Trims: 'An item attached to the garment',
                        PackingpressingFinishing: 'Processes after garment construction'
                    },
                    {
                        SewingConstruction: 'Incorrect label - isolated',
                        Trims: 'Rough/sharp edges',
                        PackingpressingFinishing: 'Foreign objects'
                    },
                    {
                        SewingConstruction: 'Insecure/incorrectly attached labels',
                        Trims: 'Insecure attachment',
                        PackingpressingFinishing: 'Incorrect carton quality/size'
                    },
                    {
                        SewingConstruction: 'Belt/button loops insecure',
                        Trims: 'Broken',
                        PackingpressingFinishing: 'Missing packing list'
                    },
                    {
                        SewingConstruction: 'Loose buttons',
                        Trims: 'incorrect/missing carelabel',
                        PackingpressingFinishing: 'Wrong style/colour in bag or carton'
                    },
                    {
                        SewingConstruction: 'Button holes too large/small',
                        Trims: 'incorrect/missing trim',
                        PackingpressingFinishing: 'Wrong size in bag or carton'
                    },
                    {
                        SewingConstruction: 'Unravelling buttonholes',
                        Trims: 'incorrect colour',
                        PackingpressingFinishing: 'Missing barcode sticker'
                    },
                    {
                        SewingConstruction: 'Neck stretch too small (stitch tension)',
                        Trims: 'poor/uneven coverage',
                        PackingpressingFinishing: 'Incorrect barcode sticker'
                    },
                    {
                        SewingConstruction: 'Poor/wrong sleeve insertion',
                        Trims: 'Colour transfer',
                        PackingpressingFinishing: 'Glazing'
                    },
                    {
                        SewingConstruction: 'Thread ends caught inside seam tape',
                        Trims: 'poor colour match',
                        PackingpressingFinishing: 'Shadow through garment'
                    },
                    {
                        SewingConstruction: 'Channels/ creasing on seam tape',
                        Trims: 'Examples',
                        PackingpressingFinishing: 'Odour/smell'
                    },
                    {
                        SewingConstruction: 'Seam tape missing from seam/stitching',
                        Trims: 'Buttons/poppers',
                        PackingpressingFinishing: 'Mildew'
                    },
                    {
                        SewingConstruction: 'Wavy or incorrect topstitching',
                        Trims: 'Zips',
                        PackingpressingFinishing: 'Incorrect/missing swing ticket'
                    },
                    {
                        SewingConstruction: 'Misaligned/unbalanced components',
                        Trims: 'Toggles',
                        PackingpressingFinishing: 'Incorrect folding'
                    },
                    {
                        SewingConstruction: 'Slanted plackets/seams',
                        Trims: 'Woven/printed labels',
                        PackingpressingFinishing: 'Missing tissue/wax paper (prints)'
                    },
                    {
                        SewingConstruction: 'Wavy seams',
                        Trims: 'care labels',
                        PackingpressingFinishing: 'Incorrect poly bag'
                    },
                    {
                        SewingConstruction: 'Seam grinning',
                        Trims: 'Drawcords',
                        PackingpressingFinishing: 'overpressing'
                    },
                    {
                        SewingConstruction: 'Pocket corners not reinforced',
                        Trims: 'Eyelets',
                        PackingpressingFinishing: 'Untrimmed threads'
                    },
                    {
                        SewingConstruction: 'Incorrect stitch tension/density',
                        Trims: 'Sequins',
                        PackingpressingFinishing: 'Loose threads/fibres on surface'
                    },
                    {
                        SewingConstruction: 'Broken seam',
                        Trims: 'Beads',
                        PackingpressingFinishing: 'Handling stain'
                    },
                    {
                        SewingConstruction: 'Unbalanced stitch tension',
                        Trims: 'Fur',
                        PackingpressingFinishing: 'Stain'
                    },
                    {
                        SewingConstruction: 'insecure bootlace/capsule ends',
                        Trims: 'velcro',
                        PackingpressingFinishing: 'Pen mark'
                    },
                    {
                        SewingConstruction: 'Open seam ta hem',
                        Trims: '',
                        PackingpressingFinishing: ''
                    },
                    {
                        SewingConstruction: 'Neck shape off',
                        Trims: '',
                        PackingpressingFinishing: ''
                    },
                    {
                        SewingConstruction: 'Skip stitch',
                        Trims: '',
                        PackingpressingFinishing: ''
                    },
                    {
                        SewingConstruction: 'Joint stith at pocket',
                        Trims: '',
                        PackingpressingFinishing: ''
                    }
                ]


                let logo = window.$APIBaseURL + 'Images/JD_logo.jpg';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'jpg',
                    });

                    sheet.addImage(imageId2, 'A1:A7');
                })
                sheet.mergeCells('E3:K3');
                sheet.getCell('E3').value = 'Maximum number of defects allowed';
                sheet.getCell('E3').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,

                };
                sheet.getCell('E3').border = borderStyles;

                sheet.getCell('C4').value = 'Lot or Batch size';
                sheet.getCell('C4').border = borderStyles;
                sheet.getCell('C4').font = { bold: true };
                sheet.getCell('D4').value = 'sample size';
                sheet.getCell('D4').border = borderStyles;
                sheet.getCell('D4').font = { bold: true };
                sheet.getCell('E4').value = 'Major';
                sheet.getCell('E4').border = borderStyles;
                sheet.getCell('E4').font = { bold: true };
                sheet.mergeCells('F4:H4');
                sheet.getCell('F4').value = 'Minor';
                sheet.getCell('F4').border = borderStyles;
                sheet.getCell('F4').font = { bold: true };
                sheet.mergeCells('I4:K4');
                sheet.getCell('I4').value = 'Critical';
                sheet.getCell('I4').border = borderStyles;
                sheet.getCell('I4').font = { bold: true };


                sheet.mergeCells('E7:K7');
                sheet.getCell('E7').value = 'Number of defects found';
                sheet.getCell('E7').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,

                };
                sheet.getCell('E7').border = borderStyles;

                sheet.getCell('A8').value = 'DATE CHECKED:' + data.item1[0].dateChecked;
                //  sheet.getCell('A8').font = { bold: true };
                sheet.getCell('E8').value = 'Major';
                sheet.getCell('E8').border = borderStyles;
                sheet.getCell('E8').font = { bold: true };
                sheet.mergeCells('F8:H8');
                sheet.getCell('F8').value = 'Minor';
                sheet.getCell('F8').font = { bold: true };
                sheet.getCell('F8').border = borderStyles;

                sheet.mergeCells('I8:K8');
                sheet.getCell('I8').value = 'Critical';
                sheet.getCell('I8').font = { bold: true };
                sheet.getCell('I8').border = borderStyles;

                sheet.getCell('A9').value = 'DELIVERY DATE:';
                //  sheet.getCell('A9').font = { bold: true };
                sheet.getCell('C9').value = 'Quantity checked:';
                sheet.getCell('C9').border = borderStyles;
                sheet.getCell('C9').font = { bold: true };

                sheet.mergeCells('F5:H5');
                sheet.mergeCells('I5:K5');

                sheet.getCell('C5').border = borderStyles;
                sheet.getCell('D5').border = borderStyles;
                sheet.getCell('E5').border = borderStyles;
                sheet.getCell('F5').border = borderStyles;
                sheet.getCell('I5').border = borderStyles;

                sheet.mergeCells('F9:H9');
                sheet.mergeCells('I9:K9');

                sheet.getCell('D9').border = borderStyles;
                sheet.getCell('E9').border = borderStyles;
                sheet.getCell('F9').border = borderStyles;
                sheet.getCell('I9').border = borderStyles;

                sheet.getCell('A10').value = 'PO:' + data.item1[0].idnos;
                sheet.getCell('A11').value = 'STYLE NAME:' + data.item1[0].styleName;
                sheet.getCell('A12').value = 'STYLE NUMBER:' + data.item1[0].styleCode;
                sheet.getCell('A13').value = 'SUPPLIER:CUBE FASHIONS';
                sheet.getCell('A14').value = 'FACTORY:' + data.item1[0].supplierName;
                sheet.getCell('A15').value = 'Checked by:' + data.item1[0].checkedBy;

                sheet.getCell('C10').value = 'Carton numbers checked';
                sheet.getCell('C10').font = { bold: true };
                sheet.mergeCells('D10:E10');
                sheet.getCell('C10').border = borderStyles;
                sheet.getCell('D10').border = borderStyles;

                sheet.getCell('C12').value = 'Order Quantity';
                sheet.getCell('C13').value = 'Shipped Quantity';
                sheet.getCell('C12').border = borderStyles;
                sheet.getCell('C13').border = borderStyles;
                sheet.getCell('D12').border = borderStyles;
                sheet.getCell('D13').border = borderStyles;
                sheet.getCell('C12').font = { bold: true };
                sheet.getCell('C13').font = { bold: true };

                sheet.mergeCells('F12:G12');
                sheet.mergeCells('F13:G13');
                sheet.mergeCells('F14:G14');
                sheet.mergeCells('F15:G15');

                sheet.mergeCells('I12:J12');
                sheet.mergeCells('I13:J13');
                sheet.mergeCells('I14:J14');
                sheet.mergeCells('I15:J15');

                sheet.getCell('F12').value = 'Carton size';
                sheet.getCell('F15').value = 'Carton weight';
                sheet.getCell('H12').value = 'JD1';
                sheet.getCell('H13').value = 'JD2';
                sheet.getCell('H14').value = 'JD3';
                sheet.getCell('H15').value = '5kg - 20Kg';
                sheet.getCell('I12').value = '370x400x600mm';
                sheet.getCell('I13').value = '300x400x200mm';
                sheet.getCell('I14').value = '350x500x300mm';

                sheet.getCell('F12').border = borderStyles;
                sheet.getCell('F15').border = borderStyles;
                sheet.getCell('H12').border = borderStyles;
                sheet.getCell('H13').border = borderStyles;
                sheet.getCell('H14').border = borderStyles;
                sheet.getCell('H15').border = borderStyles;
                sheet.getCell('I12').border = borderStyles;
                sheet.getCell('I13').border = borderStyles;
                sheet.getCell('I14').border = borderStyles;
                sheet.getCell('F13').border = borderStyles;
                sheet.getCell('F14').border = borderStyles;
                sheet.getCell('I15').border = borderStyles;

                sheet.getCell('K12').border = borderStyles;
                sheet.getCell('K13').border = borderStyles;
                sheet.getCell('K14').border = borderStyles;
                sheet.getCell('K15').border = borderStyles;



                sheet.getCell('A17').value = 'DEFECT CLASSIFICATION';
                sheet.getCell('A17').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                sheet.getCell('A19').value = 'Fabric/Dying';
                sheet.getCell('A19').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                sheet.getCell('C19').value = 'Printing/Emb (Flock&Transfer)';
                sheet.getCell('C19').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                sheet.getCell('E19').value = 'Cutting (Bundling/Production prep)';
                sheet.getCell('E19').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                let Start = 'A';
                let SecondStart = 'C';
                let ThirdStart = 'E';
                let Empty1 = 'B';
                let Empty2 = 'D';
                let Empty3 = 'F';

                for (let index = 0; index < DefectclasiificationArr1.length; index++) {
                    Start += index + 20;
                    SecondStart += index + 20;
                    ThirdStart += index + 20;
                    Empty1 += index + 20;
                    Empty2 += index + 20;
                    Empty3 += index + 20;

                    sheet.getCell(Start).value = DefectclasiificationArr1[index].FabricDying
                    sheet.getCell(Start).border = borderStyles;
                    sheet.getCell(SecondStart).value = DefectclasiificationArr1[index].PrintingEmbFlockTransfer
                    sheet.getCell(SecondStart).border = borderStyles;
                    sheet.getCell(ThirdStart).value = DefectclasiificationArr1[index].CuttingBundlingProductionprep
                    sheet.getCell(ThirdStart).border = borderStyles;
                    sheet.getCell(Empty1).border = borderStyles;
                    sheet.getCell(Empty2).border = borderStyles;
                    sheet.getCell(Empty3).border = borderStyles;

                    Start = 'A';
                    SecondStart = 'C';
                    ThirdStart = 'E';
                    Empty1 = 'B';
                    Empty2 = 'D';
                    Empty3 = 'F';
                }

                sheet.getCell('A38').value = 'Sewing/Construction';
                sheet.getCell('A38').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                sheet.getCell('C38').value = 'Trims';
                sheet.getCell('C38').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                sheet.getCell('E38').value = 'Packing/pressing (Finishing)';
                sheet.getCell('E38').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 11,
                    bold: true,
                    underline: true
                };

                for (let index = 0; index < DefectclasiificationArr2.length; index++) {
                    Start += index + 39;
                    SecondStart += index + 39;
                    ThirdStart += index + 39;
                    Empty1 += index + 39;
                    Empty2 += index + 39;
                    Empty3 += index + 39;

                    sheet.getCell(Start).value = DefectclasiificationArr2[index].SewingConstruction
                    sheet.getCell(Start).border = borderStyles;
                    sheet.getCell(SecondStart).value = DefectclasiificationArr2[index].Trims
                    sheet.getCell(SecondStart).border = borderStyles;
                    sheet.getCell(ThirdStart).value = DefectclasiificationArr2[index].PackingpressingFinishing
                    sheet.getCell(ThirdStart).border = borderStyles;
                    sheet.getCell(Empty1).border = borderStyles;
                    sheet.getCell(Empty2).border = borderStyles;
                    sheet.getCell(Empty3).border = borderStyles;
                    Empty1 = 'B';
                    Empty2 = 'D';
                    Empty3 = 'F';


                    Start = 'A';
                    SecondStart = 'C';
                    ThirdStart = 'E';
                }


                let Jdlogo2 = window.$APIBaseURL + 'Images/JD_logo2.jpg';
                let Jdlogo3 = window.$APIBaseURL + 'Images/JD_logo3.jpg';
                let Jdlogo4 = window.$APIBaseURL + 'Images/JD_logo4.jpg';
                let logo2result; let logo3result; let logo4result;
                let emptyarray = [{ novalue: '' }]

                emptyarray.map(async x => {
                    logo2result = await toDataURL(Jdlogo2);

                    const image1 = workbook.addImage({
                        base64: logo2result.base64Url,
                        extension: 'jpg',
                        width: '200px'
                    });

                    logo3result = await toDataURL(Jdlogo3);

                    const image2 = workbook.addImage({
                        base64: logo3result.base64Url,
                        extension: 'jpg',
                    });

                    logo4result = await toDataURL(Jdlogo4);

                    const image3 = workbook.addImage({
                        base64: logo4result.base64Url,
                        extension: 'jpg',
                    });


                    sheet.addImage(image1, 'H19:I20');
                    sheet.addImage(image2, 'H21:I62');
                    sheet.addImage(image3, 'K21:L38');
                })

                sheet.getCell('K40').value = 'Critical';
                sheet.getCell('K41').value = 'Major';
                sheet.getCell('K42').value = 'Major/ Minor (depending on pos)';
                sheet.getCell('K43').value = 'Minor';

                sheet.getCell('L40').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ff0000" },
                };
                sheet.getCell('L41').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFA500" },
                };
                sheet.getCell('L42').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFF00" },
                };
                sheet.getCell('L43').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ffffe0" },
                };


                sheet.getCell('D5').value = data.item1[0].samplingSize;
                sheet.getCell('E5').value = data.item2.length > 0 ? data.item2[0].minor : '';
                sheet.getCell('F5').value = data.item2.length > 0 ? data.item2[0].major : '';
                sheet.getCell('I5').value = data.item2.length > 0 ? data.item2[0].critical : '';
                sheet.getCell('D9').value = data.item1[0].samplingSize;
                sheet.getCell('E9').value = data.item2.length > 0 ? data.item2[1].minor : '';
                sheet.getCell('F9').value = data.item2.length > 0 ? data.item2[1].major : '';
                sheet.getCell('I9').value = data.item2.length > 0 ? data.item2[1].critical : '';
                sheet.getCell('D10').value = data.item1[0].cortonNosChecked;
                sheet.getCell('D12').value = data.item3[0].orderQty;
                sheet.getCell('D13').value = data.item3[0].shippedQty;
                //  sheet.getCell('K12').value = data.item1[0].SamplingSize;
                //  sheet.getCell('K15').value = data.item1[0].SamplingSize;


            }

            else if (sheetName === 'PHOTOS') {

                var borderStyle = {
                    top: { style: "thick" },
                    left: { style: "thick" },
                    bottom: { style: "thick" },
                    right: { style: "thick" }
                };

                sheet.getCell('B2').value = 'Critical';
                sheet.mergeCells('B2:N2');
                sheet.getCell('B2:N2').border = borderStylesb;
                sheet.getCell('B20').value = 'Major';
                sheet.mergeCells('B20:N20');
                sheet.getCell('B20:N20').border = borderStylesb;
                sheet.getCell('B38').value = 'Minor';
                sheet.mergeCells('B38:N38');
                sheet.getCell('B38:N38').border = borderStylesb;
                //sheet.getCell('B3:C3').border = borderStyles;

                sheet.getCell('B2').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "ff0000" },
                };
                sheet.getCell('B20').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFA500" },
                };
                sheet.getCell('B38').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFF00" },
                };

                // SKU Image
                let ColumnNumber = 3;
                let RowNumber = 0;
                let ColumnNumberBack = 0;
                let indexCritical = 0;
                let indexMajor = 0;
                let indexMinor = 0;
                let index = 0;
                let lableRowNwmber = 0;
                let CellRow;


                data.item4.map(async (product) => {

                    if (product.base64String !== null && product.base64String !== '') {

                        const splitted = URL + product?.url.split(".");
                        const extName = splitted[splitted.length - 1];

                        if (product?.defectType == 'Critical') {
                            RowNumber = 3;
                            indexCritical++;
                            index = indexCritical;
                            lableRowNwmber = '18';
                        }
                        else if (product?.defectType == 'Major') {
                            RowNumber = 21;
                            indexMajor++;
                            index = indexMajor;
                            if (index === 1)
                                lableRowNwmber = '35';
                        }
                        else if (product?.defectType == 'Minor') {
                            RowNumber = 41;
                            indexMinor++;
                            index = indexMinor;
                            lableRowNwmber = '55';
                        }

                        if (index === 1) { ColumnNumber = 0; ColumnNumber = index + 2; }
                        else { ColumnNumber = ColumnNumber + 4; }


                        const imageId = workbook.addImage({
                            base64: product?.base64String,
                            extension: extName,
                        });

                        sheet.addImage(imageId, {
                            tl: { col: ColumnNumber, row: RowNumber },
                            ext: { width: 180, height: 220 },

                        });

                        if (index === 1) { CellRow = 'D' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 2) { CellRow = 'H' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 3) { CellRow = 'L' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 4) { CellRow = 'P' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 5) { CellRow = 'T' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 6) { CellRow = 'X' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 7) { CellRow = 'AB' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 8) { CellRow = 'AF' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 9) { CellRow = 'AJ' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }
                        else if (index === 10) { CellRow = 'AN' + lableRowNwmber; sheet.getCell(CellRow).value = product?.value; }

                        sheet.getCell(CellRow).font = {
                            name: 'Helvetica',
                            color: { argb: '3268ba' },
                            //family: 2,
                            size: 10,
                            italic: true
                        };


                    }






                })





            }

            else if (sheetName === 'MEASUREMENT') {

                sheet.getRow(1).height = 70;
                sheet.mergeCells('A1:A2');
                let logo = window.$APIBaseURL + 'Images/JD_logo.jpg';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'jpg',
                    });

                    sheet.addImage(imageId2, 'A1:A1');
                })
                sheet.mergeCells('B1:O1');
                sheet.getCell('B1').value = 'AQL -Size chart/Measurements';
                sheet.getCell('B1').font = {
                    name: 'Calibri',
                    // color: { argb: 'ebeff5' },
                    size: 22,
                    //bold: true,

                };

                sheet.getCell('A2').value = 'DATE CHECKED:' + data.item1[0].dateChecked;
                sheet.getCell('A2').font = { bold: true };

                sheet.getCell('A3').value = 'PO:' + data.item1[0].idnos;
                sheet.getCell('A3').font = { bold: true };

                sheet.getCell('A4').value = 'STYLE NAME:' + data.item1[0].styleName;
                sheet.getCell('A4').font = { bold: true };

                sheet.getCell('A5').value = 'STYLE NUMBER:' + data.item1[0].styleCode;
                sheet.getCell('A5').font = { bold: true };

                sheet.mergeCells('B2:C2');
                sheet.getCell('B2').value = 'SUPPLIER:';
                sheet.getCell('B2').font = { bold: true };

                sheet.mergeCells('B3:C3');
                sheet.getCell('B3').value = 'FACTORY:';
                sheet.getCell('B3').font = { bold: true };

                sheet.mergeCells('B4:C4');
                sheet.getCell('B4').value = 'Checked by:';
                sheet.getCell('B4').font = { bold: true };

                sheet.mergeCells('D2:K2');
                sheet.mergeCells('D3:K3');
                sheet.mergeCells('D4:K4');
                sheet.getCell('D2').value = 'CUBE FASHIONS';
                sheet.getCell('D3').value = data.item1[0].supplierName;
                sheet.getCell('D4').value = data.item1[0].checkedBy;


                let Mainheaders = [];

                StrikeoffService.LoadGroupSizeDetailsList(Styleid).then((response) => {
                    if (response.data) {
                        let maincount = 1;
                        Mainheaders = response.data;
                        Mainheaders.map(mh => {
                            // if (maincount <= 3) {

                            if (maincount === 1) {

                                sheet.getCell("A6").value = "Sku-Category-Points";
                                sheet.getCell('A6').font = { bold: true };
                                sheet.getCell('B6').value = mh.label + "-Req";
                                sheet.getCell('B6').font = { bold: true };
                                // sheet.getCell('E3').border = borderStyles;
                                //  sheet.getCell('E3').border = borderStyles;
                                for (let i = 67; i <= 76; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "6").value = mh.label + "-" + (i - 66);
                                    sheet.getCell(String.fromCharCode(i) + "6").font = { bold: true };
                                    //  sheet.getCell(String.fromCharCode(i) + "6").border = borderStyles;
                                }
                                maincount = maincount + 1;


                                let start = 7;
                                let val = 66;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                sheet.getCell('A' + start).value = Pointitem.SkuName + "-" + Pointitem.CategoryTypeName + "-" + Pointitem.MeasurementPoints;
                                                // sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 6).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 66;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 2) {
                                sheet.getCell('M6').value = mh.label + "-Req";
                                sheet.getCell('M6').font = { bold: true };
                                // sheet.getCell('E3').border = borderStyles;
                                for (let i = 78; i <= 87; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "6").value = mh.label + "-" + (i - 77);
                                    sheet.getCell(String.fromCharCode(i) + "6").font = { bold: true };
                                    // sheet.getCell(String.fromCharCode(i) + "6").border = borderStyles;
                                }
                                maincount = maincount + 1;

                                let start = 7;
                                let val = 77;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                //  sheet.getCell('A' + start).value = Pointitem.MeasurementPoints;
                                                //  sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 6).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 77;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })


                            }
                            else if (maincount === 3) {
                                sheet.getCell('X6').value = mh.label + "-Req";
                                sheet.getCell('X6').font = { bold: true };
                                //  sheet.getCell('X6').border = borderStyles;
                                for (let i = 89; i <= 90; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "6").value = mh.label + "-" + (i - 88);
                                    sheet.getCell(String.fromCharCode(i) + "6").font = { bold: true };
                                    // sheet.getCell(String.fromCharCode(i) + "6").border = borderStyles;

                                }
                                sheet.getCell("AA6").value = mh.label + "-3"; sheet.getCell('AA6').font = { bold: true };
                                sheet.getCell("AB6").value = mh.label + "-4"; sheet.getCell('AB6').font = { bold: true };
                                sheet.getCell("AC6").value = mh.label + "-5"; sheet.getCell('AC6').font = { bold: true };
                                sheet.getCell("AD6").value = mh.label + "-6"; sheet.getCell('AD6').font = { bold: true };
                                sheet.getCell("AE6").value = mh.label + "-7"; sheet.getCell('AE6').font = { bold: true };
                                sheet.getCell("AF6").value = mh.label + "-8"; sheet.getCell('AF6').font = { bold: true };
                                sheet.getCell("AG6").value = mh.label + "-9"; sheet.getCell('AG6').font = { bold: true };
                                sheet.getCell("AH6").value = mh.label + "-10"; sheet.getCell('AH6').font = { bold: true };
                                maincount = maincount + 1;


                                let start = 7; let additionalstart = 6;
                                let val = 88;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                // sheet.getCell('A' + start).value = Pointitem.MeasurementPoints;
                                                //  sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {

                                                    if (i <= 3) {
                                                        let headername = sheet.getCell(String.fromCharCode(val) + 6).value;
                                                        sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                        // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    }
                                                    else {
                                                        // i = i + 61;
                                                        let headername = sheet.getCell("A" + String.fromCharCode(i + 61) + 6).value;
                                                        sheet.getCell("A" + String.fromCharCode(i + 61) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    }
                                                    val = val + 1;
                                                    //additionalstart = 6;
                                                }

                                                val = 88;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 4) {
                                sheet.getCell("A27").value = "Sku-Category-Points";
                                sheet.getCell('A27').font = { bold: true };
                                // sheet.getCell('A27').border = borderStyles;
                                sheet.getCell('B27').value = mh.label + "-Req";
                                sheet.getCell('B27').font = { bold: true };
                                //  sheet.getCell('B27').border = borderStyles;
                                for (let i = 67; i <= 76; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "27").value = mh.label + "-" + (i - 66);
                                    sheet.getCell(String.fromCharCode(i) + "27").font = { bold: true };
                                    //  sheet.getCell(String.fromCharCode(i) + "27").border = borderStyles;
                                }
                                maincount = maincount + 1;

                                let start = 28;
                                let val = 66;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                sheet.getCell('A' + start).value = Pointitem.SkuName + "-" + Pointitem.CategoryTypeName + "-" + Pointitem.MeasurementPoints;
                                                // sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 27).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 66;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 5) {
                                sheet.getCell('M27').value = mh.label + "-Req";
                                sheet.getCell('M27').font = { bold: true };
                                //  sheet.getCell('M27').border = borderStyles;
                                for (let i = 78; i <= 87; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "27").value = mh.label + "-" + (i - 77);
                                    sheet.getCell(String.fromCharCode(i) + "27").font = { bold: true };
                                    //  sheet.getCell(String.fromCharCode(i) + "27").border = borderStyles;
                                }
                                maincount = maincount + 1;

                                let start = 28;
                                let val = 77;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                //  sheet.getCell('A' + start).value = Pointitem.MeasurementPoints;
                                                //  sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 27).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 77;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 6) {
                                sheet.getCell('X27').value = mh.label + "-Req";
                                sheet.getCell('X27').font = { bold: true };
                                //   sheet.getCell('E3').border = borderStyles;
                                for (let i = 89; i <= 90; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "27").value = mh.label + "-" + (i - 88);
                                    sheet.getCell(String.fromCharCode(i) + "27").font = { bold: true };
                                    // sheet.getCell(String.fromCharCode(i) + "27").border = borderStyles;

                                }
                                sheet.getCell("AA27").value = mh.label + "-3"; sheet.getCell('AA27').font = { bold: true };
                                sheet.getCell("AB27").value = mh.label + "-4"; sheet.getCell('AB27').font = { bold: true };
                                sheet.getCell("AC27").value = mh.label + "-5"; sheet.getCell('AC27').font = { bold: true };
                                sheet.getCell("AD27").value = mh.label + "-6"; sheet.getCell('AD27').font = { bold: true };
                                sheet.getCell("AE27").value = mh.label + "-7"; sheet.getCell('AE27').font = { bold: true };
                                sheet.getCell("AF27").value = mh.label + "-8"; sheet.getCell('AF27').font = { bold: true };
                                sheet.getCell("AG27").value = mh.label + "-9"; sheet.getCell('AG27').font = { bold: true };
                                sheet.getCell("AH27").value = mh.label + "-10"; sheet.getCell('AH76').font = { bold: true };
                                maincount = maincount + 1;

                                let start = 28; let additionalstart = 27;
                                let val = 88;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                //  sheet.getCell('A' + start).value = Pointitem.MeasurementPoints;
                                                //  sheet.getCell('A' + start).border = borderStyles;



                                                for (let i = 1; i <= 11; i++) {

                                                    if (i <= 3) {
                                                        let headername = sheet.getCell(String.fromCharCode(val) + 27).value;
                                                        sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                        // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    }
                                                    else {
                                                        let AA = sheet.getCell("AA" + additionalstart).value;
                                                        sheet.getCell("AA" + start).value = Pointitem.arrGradingSizes[0][AA] !== '0' ? Pointitem.arrGradingSizes[0][AA] : '';
                                                        additionalstart = additionalstart + 1;
                                                    }
                                                    val = val + 1;
                                                    additionalstart = 6;
                                                }
                                                val = 88;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 7) {
                                sheet.getCell("A47").value = "Sku-Category-Points";
                                sheet.getCell('A47').font = { bold: true };
                                // sheet.getCell('A27').border = borderStyles;
                                sheet.getCell('B47').value = mh.label + "-Req";
                                sheet.getCell('B47').font = { bold: true };
                                //  sheet.getCell('B27').border = borderStyles;
                                for (let i = 67; i <= 76; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "47").value = mh.label + "-" + (i - 66);
                                    sheet.getCell(String.fromCharCode(i) + "47").font = { bold: true };
                                    //  sheet.getCell(String.fromCharCode(i) + "27").border = borderStyles;
                                }
                                maincount = maincount + 1;

                                let start = 48;
                                let val = 66;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                sheet.getCell('A' + start).value = Pointitem.SkuName + "-" + Pointitem.CategoryTypeName + "-" + Pointitem.MeasurementPoints;
                                                // sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 47).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 66;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 8) {
                                sheet.getCell('M47').value = mh.label + "-Req";
                                sheet.getCell('M47').font = { bold: true };
                                //  sheet.getCell('M27').border = borderStyles;
                                for (let i = 78; i <= 87; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "47").value = mh.label + "-" + (i - 77);
                                    sheet.getCell(String.fromCharCode(i) + "47").font = { bold: true };
                                    //  sheet.getCell(String.fromCharCode(i) + "27").border = borderStyles;
                                }
                                maincount = maincount + 1;

                                let start = 48;
                                let val = 77;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                //  sheet.getCell('A' + start).value = Pointitem.MeasurementPoints;
                                                //  sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 47).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 77;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            else if (maincount === 9) {
                                sheet.getCell('X47').value = mh.label + "-Req";
                                sheet.getCell('X47').font = { bold: true };
                                //   sheet.getCell('E3').border = borderStyles;
                                for (let i = 89; i <= 90; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "47").value = mh.label + "-" + (i - 88);
                                    sheet.getCell(String.fromCharCode(i) + "47").font = { bold: true };
                                    // sheet.getCell(String.fromCharCode(i) + "27").border = borderStyles;

                                }
                                sheet.getCell("AA47").value = mh.label + "-3"; sheet.getCell('AA47').font = { bold: true };
                                sheet.getCell("AB47").value = mh.label + "-4"; sheet.getCell('AB47').font = { bold: true };
                                sheet.getCell("AC47").value = mh.label + "-5"; sheet.getCell('AC47').font = { bold: true };
                                sheet.getCell("AD47").value = mh.label + "-6"; sheet.getCell('AD47').font = { bold: true };
                                sheet.getCell("AE47").value = mh.label + "-7"; sheet.getCell('AE47').font = { bold: true };
                                sheet.getCell("AF47").value = mh.label + "-8"; sheet.getCell('AF47').font = { bold: true };
                                sheet.getCell("AG47").value = mh.label + "-9"; sheet.getCell('AG47').font = { bold: true };
                                sheet.getCell("AH47").value = mh.label + "-10"; sheet.getCell('AH47').font = { bold: true };
                                maincount = maincount + 1;

                                let start = 48; let additionalstart = 47;
                                let val = 88;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                //  sheet.getCell('A' + start).value = Pointitem.MeasurementPoints;
                                                //  sheet.getCell('A' + start).border = borderStyles;



                                                for (let i = 1; i <= 11; i++) {

                                                    if (i <= 3) {
                                                        let headername = sheet.getCell(String.fromCharCode(val) + 47).value;
                                                        sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                        // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    }
                                                    else {
                                                        let AA = sheet.getCell("AA" + additionalstart).value;
                                                        sheet.getCell("AA" + start).value = Pointitem.arrGradingSizes[0][AA] !== '0' ? Pointitem.arrGradingSizes[0][AA] : '';
                                                        additionalstart = additionalstart + 1;
                                                    }
                                                    val = val + 1;
                                                    additionalstart = 6;
                                                }
                                                val = 88;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }

                            else if (maincount === 10) {

                                sheet.getCell("A67").value = "Sku-Category-Points";
                                sheet.getCell('A67').font = { bold: true };
                                sheet.getCell('B67').value = mh.label + "-Req";
                                sheet.getCell('B67').font = { bold: true };
                                // sheet.getCell('E3').border = borderStyles;
                                //  sheet.getCell('E3').border = borderStyles;
                                for (let i = 67; i <= 76; i++) {

                                    sheet.getCell(String.fromCharCode(i) + "67").value = mh.label + "-" + (i - 66);
                                    sheet.getCell(String.fromCharCode(i) + "67").font = { bold: true };
                                    //  sheet.getCell(String.fromCharCode(i) + "6").border = borderStyles;
                                }
                                maincount = maincount + 1;


                                let start = 68;
                                let val = 66;
                                skudetails.map(comment => {
                                    measurementdata.map((item, subindex) => {
                                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                sheet.getCell('A' + start).value = Pointitem.SkuName + "-" + Pointitem.CategoryTypeName + "-" + Pointitem.MeasurementPoints;
                                                // sheet.getCell('A' + start).border = borderStyles;

                                                for (let i = 1; i <= 11; i++) {
                                                    let headername = sheet.getCell(String.fromCharCode(val) + 67).value;

                                                    sheet.getCell(String.fromCharCode(val) + start).value = Pointitem.arrGradingSizes[0][headername] !== '0' ? Pointitem.arrGradingSizes[0][headername] : '';
                                                    // sheet.getCell(String.fromCharCode(val) + start).border = borderStyles;
                                                    val = val + 1;
                                                }
                                                val = 66;
                                                start = start + 1;
                                            })

                                        })
                                    })

                                })
                            }
                            //  }



                        })




                    }
                })

                setTimeout(() => {
                    workbook.xlsx.writeBuffer().then(function (data) {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = fileName;
                        anchor.click();
                        window.URL.revokeObjectURL(url);
                    });
                    hideLoader();
                }, 6000);
            }

        });

    };
    const handleSettingChange = (e, index) => {
        const { name, checked } = e.target;

        setSettings((prevSettings) => {
            const updatedSettings = [...prevSettings]; // Create a shallow copy of the settings array
            updatedSettings[index] = {
                ...updatedSettings[index], // Create a copy of the specific object at the index
                [name]: checked, // Update the specific field (e.g., 'basicInfo', 'photos', etc.) with the new value
            };
            return updatedSettings; // Return the updated array
        });
    };

    const generatePDF = async () => {
        debugger
        const res = await TNAService.GetFinalinspectionPDFList(getinpsectionID);
        let MainList = [];
        // Push testingPDFMainList into MainList if it exists
        if (res.data.finalIPDFMainList) {
            MainList.push(res.data.finalIPDFMainList);
        }
        // Declare variables for lists to be passed to generatePDF1
        let FinalISubDetailList = [];

        // Map over testingPDFResultList if it exists
        if (res.data.finalIListResultSubDetails) {
            FinalISubDetailList = res.data.finalIListResultSubDetails.map((x) => ({
                Details: x.details,
                ResultName: x.resultName,
                FindingsRemarks: x.findingsRemarks
            }));
        }
        const groupedData = res.data.finalIListOrderSummaryTable.reduce((acc, item) => {
            const existingSku = acc.find((sku) => sku.name === item.skuName);
            const row = {
                styleNo: item.styleName,
                endCustomer: item.endCustomer,
                poIDNo: item.idno,
                refNo: "", // Placeholder for reference
                poQty: Number(item.poQty),
                insQty: Number(item.offeredQty),
            };

            if (existingSku) {
                existingSku.rows.push(row);
            } else {
                acc.push({
                    name: item.skuName,
                    rows: [row],
                });
            }
            return acc;
        }, []);
        const groupedmainData = {
            "Strike off - Artworks": res.data.finalIListAllMainDetailsTable.filter(item => item.name === "Strike off - Artworks"),
            "Trims": res.data.finalIListAllMainDetailsTable.filter(item => item.name === "Trims"),
            "Workmanship": res.data.finalIListAllMainDetailsTable.filter(item => item.name === "Workmanship"),
        };
        generatePDF1(res.data, MainList, FinalISubDetailList, groupedData, groupedmainData);
    }
    const addHeaderDetails = (doc, pageWidth, yPosition, result, approvalResult) => {
        const labCommentsLabel = "O/A Result:";
        const approvalResultLabel = "App. Result:";

        // Define colors
        const greenColor = "#28a745";
        const redColor = "#dc3545";
        const yellowColor = "#ffc107";

        // Determine colors based on labComments and approvalResult values
        const labCommentsColor = result === "Pass" ? greenColor : redColor;
        const approvalResultColor = approvalResult === "Approved" ? greenColor : (approvalResult === "Rejected" ? redColor : yellowColor);

        // Positioning settings for horizontal layout
        const startX = pageWidth - 350; // Adjust as needed for alignment on the right
        const spacing = 10; // Spacing between label and value
        yPosition -= 42;
        // Add "Lab Comments" label and value with dynamic color, positioned horizontally
        doc.setFont("helvetica", "bold");
        doc.setFontSize(8);
        let xPosition = startX;
        doc.text(labCommentsLabel, xPosition, yPosition);
        xPosition += doc.getTextWidth(labCommentsLabel) + spacing;
        doc.setTextColor(labCommentsColor); // Set color based on labComments value
        doc.text(result, xPosition, yPosition);

        // Add space between "Lab Comments" and "App. Result"
        xPosition += doc.getTextWidth(result) + spacing * 2;

        // Add "App. Result" label and value with dynamic color
        doc.setTextColor(0); // Reset to black for label
        doc.text(approvalResultLabel, xPosition, yPosition);
        xPosition += doc.getTextWidth(approvalResultLabel) + spacing;
        doc.setTextColor(approvalResultColor); // Set color based on approvalResult value
        doc.text(approvalResult, xPosition, yPosition);

        // Reset text color to black for subsequent content
        doc.setTextColor(0);
    };

    const removeFooter = (doc, footerY, pageWidth) => {
        debugger
        //const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFillColor(255, 255, 255); // Set fill color to white
        doc.rect(0, footerY - 10, pageWidth, 50, 'F'); // Draw white rectangle over the footer area
    };

    const addLabCommentsAndApprovalResult = (doc, margin, yPosition, result, MainList) => {
        // Define colors to match the style
        const greenColor = "#28a745";
        const redColor = "#dc3545";
        const lightGreenColor = "#d4edda";
        const whiteColor = "#FFFFFF";
        const yellowColor = "#ffc107";
        // Adjusted box dimensions
        const boxWidthLabel = 300; // Width for "Lab Comments:" and "Approval Result:" label box
        const boxWidthValue = 80;  // Width for value box (e.g., "Satisfied" or "Not Satisfied")
        const boxHeight = 20;

        // Lab Comments Section
        const labCommentsLabel = "Overall Result:";
        const labCommentsValue = result || "";

        // Determine color based on "Satisfied" or "Not Satisfied"
        const labCommentsColor = labCommentsValue === "Pass" ? greenColor : redColor;

        // Draw "Lab Comments" label box with green fill
        doc.setFillColor(labCommentsColor);
        doc.rect(margin, yPosition, boxWidthLabel, boxHeight, 'F'); // Green background for label
        doc.setTextColor(whiteColor);
        doc.setFont("helvetica", "bold");
        doc.text(labCommentsLabel, margin + 5, yPosition + 15);

        // Draw bordered box for lab comments value (e.g., "Satisfied" or "Not Satisfied")
        doc.setDrawColor(labCommentsColor); // Set border color based on value
        doc.rect(margin + boxWidthLabel, yPosition, boxWidthValue, boxHeight); // Border only, no fill
        doc.setTextColor(labCommentsColor); // Text color based on value
        doc.text(labCommentsValue, margin + boxWidthLabel + 10, yPosition + 15);

        doc.setTextColor(0); // Black
        doc.setDrawColor(0, 0, 0); // Black draw color
        yPosition += boxHeight + 10;

        // yPosition = +10;
        // Approval Result Section
        const approvalResultLabel = "Approval Result:";
        const approvalResultValue = MainList.finalinspectionReviewID || "Pending";
        let approvalResultColor;
        if (approvalResultValue === "Approved") approvalResultColor = greenColor;
        else if (approvalResultValue === "Rejected") approvalResultColor = redColor;
        else if (approvalResultValue === "Approved With Comments") approvalResultColor = yellowColor;
        else approvalResultColor = lightGreenColor; // Def

        // Draw "Approval Result" label box with green fill
        doc.setFillColor(approvalResultColor);
        doc.rect(margin, yPosition, boxWidthLabel, boxHeight, 'F'); // Green background for label
        doc.setTextColor(whiteColor);
        doc.text(approvalResultLabel, margin + 5, yPosition + 15);

        // Draw bordered box for approval result value (e.g., "Pending")
        doc.setDrawColor(approvalResultColor); // Dynamic border color based on value
        doc.rect(margin + boxWidthLabel, yPosition, 130, boxHeight); // Border only, no fill
        doc.setTextColor(approvalResultColor); // Dynamic text color for value
        doc.text(approvalResultValue, margin + boxWidthLabel + 10, yPosition + 15);

        // Reset color for future text
        doc.setTextColor(0); // Black
        doc.setDrawColor(0, 0, 0); // Black draw color
    };


    const generateStrikeOffDetails = (doc, margin, pageWidth, yPosition, data, headerText) => {
        debugger
        yPosition = +120;
        // Add section header
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.setFillColor(230, 230, 230); // Light gray background
        doc.rect(margin, yPosition, pageWidth - 2 * margin, 20, "F");
        doc.text(`${headerText}`, margin + 5, yPosition + 14);
        doc.text("Result:", pageWidth - 165, yPosition + 14);
        doc.setFontSize(10);
        const finalResult = data[0].finalResult; // Assuming all entries share the same `finalResult`
        const resultColor = finalResult === "OK" ? [0, 153, 0] : finalResult === "Not OK" ? [255, 0, 0] : [0, 0, 139];
        doc.setTextColor(...resultColor);
        doc.text(finalResult, pageWidth - 115, yPosition + 14);
        doc.setTextColor(0, 0, 0); // Reset text color
        yPosition += 30;

        data.forEach((item, index) => {
            // Alternate row colors
            const rowColor = index % 2 === 0 ? [240, 240, 240] : [255, 255, 255];
            doc.setFillColor(...rowColor);

            // Draw background rectangle
            doc.rect(margin, yPosition, pageWidth - 2 * margin, 30, "F");

            // Render serial number
            doc.setFont("helvetica", "normal");
            doc.setFontSize(9);
            doc.text(`${index + 1}`, margin + 5, yPosition + 18);

            // Render details
            doc.text(item.details, margin + 30, yPosition + 18);

            // Render result icon
            const resultColor = item.result === "OK" ? [0, 153, 0] : item.result === "Not OK" ? [255, 0, 0] : [0, 0, 139];
            doc.setTextColor(...resultColor);
            doc.text(item.result, pageWidth - 105, yPosition + 18);

            // Add camera icon beside the result
            doc.addImage('assets/img/emptyImage.jpg', "PNG", pageWidth - 130, yPosition + 5, 15, 15);

            doc.setTextColor(0, 0, 0); // Reset text color
            yPosition += 35;
        });

        return yPosition + 20; // Add spacing after the section
    };

    const getImageAsBase64 = async (backendEndpoint) => {
        try {
            const response = await fetch(backendEndpoint);
            if (!response.ok) console.warn("Failed to fetch base64 image.");

            const base64String = await response.text(); // Get the Base64 string
            return base64String;
        } catch (error) {
            console.error("Error:", error);
            return '';
        }
    };

    const generatePDF1 = async (res, MainList, FinalISubDetailList, groupedData, groupedmainData) => {
        debugger
        const left = 40;
        const top = 27;
        const imgWidth = 50;
        const imgHeight = 40;
        const margin = 40;
        const pageHeight = 820; // Usable page height in points for A4
        const footerHeight = 100; // Space reserved for footer
        const doc = new jsPDF('p', 'pt', 'a4'); // Portrait, points, A4 size
        const pageWidth = doc.internal.pageSize.getWidth();
        let currentHeight = margin;
        const logoX = margin;
        const cubeFashionsX = logoX + imgWidth + 10;
        const reportTextX = margin;

        const addNewPage = () => {
            doc.addPage();
            currentHeight = margin;  // Reset current height for the new page
            addHeader();  // Add header on new page
            addHeaderDetails(doc, pageWidth, currentHeight + 20, res.finalIOverallResult[0].resultStatus, MainList[0][0].finalinspectionReviewID); // Add lab comments and approval result header
        };
        // Define Colors for Result Types
        const resultColors = {
            "OK": [0, 153, 0], // Green
            "Not OK": [255, 0, 0], // Red
            "Not Applicable": [0, 0, 139], // Gray
            "Beyond AQL": [255, 204, 0], // Yellow
        };
        // Conditional footer to add on each page
        const addFooter = (pageNum, totalPages) => {
            debugger
            const leftMargin = 40;
            const footerY = pageHeight - footerHeight + 20;
            // Draw footer line
            doc.setLineWidth(0.5);
            doc.line(leftMargin, footerY - 10, pageWidth - leftMargin, footerY - 10);

            // Footer content
            const reportPreparedY = footerY - 30;
            doc.setFont("helvetica", "bold");
            doc.setFontSize(8);
            doc.text("Report Prepared:", leftMargin, reportPreparedY);
            doc.text("Report Approved:", pageWidth - 150, reportPreparedY);

            if (pageNum === totalPages) {
                removeFooter(doc, footerY, pageWidth);
                doc.text("Cube Fashions (P) Ltd", leftMargin, footerY);
                doc.setFont("helvetica", "normal");
                doc.text("20/4, Jai Nagar, Maniyakaram Palayam Road, Rakkiya Palayam Cross, Kangayam Road, Tirupur, INDIA - 641606", leftMargin, footerY + 20);
                doc.text("Tel: 0091 421 4355958, Fax: 0091 421 4355671, Email: info@cubefashions.com", leftMargin, footerY + 35);

            } else {
                doc.setFont("helvetica", "bold");
                doc.text("Generated on Cube Fashions (P) Ltd", leftMargin, footerY + 20);
            }

            // Page number
            doc.setFont("helvetica", "normal");
            doc.setFontSize(8);
            doc.text(`Page ${pageNum} of ${totalPages}`, pageWidth - 70, footerY + 20, { align: 'right' });
        };
        const addHeader = () => {
            doc.addImage('assets/img/MainMenu/ICube_logo.PNG', "PNG", left, top, imgWidth, imgHeight);
            doc.setFontSize(14);
            doc.setFont("helvetica", "bold");
            doc.text("CUBE FASHIONS", cubeFashionsX, 50,);
            doc.setFontSize(10); // Smaller text for the subtitle
            doc.text(`FINAL INSPECTION REPORT`, reportTextX + 350, 70,);
            doc.line(margin, 80, pageWidth - margin, 80); // Line below title

            // Add No, Date, and Page Number Section
            currentHeight = 95;
            doc.setFontSize(8); // Smaller text for No, Date, and Page Number
            doc.setFont("helvetica", "normal");
            const now = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000);
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = now.getFullYear();
            const dateIST = `${day}/${month}/${year}`;
            doc.text(`Report No: ${getinpsectionID} / ${dateIST}`, margin, currentHeight);
            // Dynamic Page Number
            currentHeight += 10;
            doc.line(margin, currentHeight, pageWidth - margin, currentHeight); // Line after No/Date/Page
            currentHeight += 10;
        };

        // Add a new page when content exceeds the current page height
        const checkAndAddNewPage = (heightToAdd) => {
            if (currentHeight + heightToAdd > pageHeight - footerHeight) {
                debugger
                addNewPage(); // Add new page
                currentHeight = margin + 50;  // Adjust after the header
            }
        };

        // Add initial header
        addHeader();
        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        doc.setFillColor(230, 230, 230); // Light gray background
        doc.rect(margin - 5, currentHeight + 1, pageWidth - margin * 2 + 10, 25, "F");
        doc.text("Inspection Details", margin, currentHeight + 6 + 12);
        // Add the header details
        const lineSpacing = 14; // Reduced line space
        let yPosition = currentHeight + 20;
        checkAndAddNewPage(120); // Ensure enough space
        yPosition += 20;
        for (const section of settings) {
            if (section.isSelected && section.Name === 'Factory Report Confirmarion') {
                doc.setFontSize(8);
                doc.setFont("helvetica", "normal");
                doc.text(`Inspection Standard / Level: ${MainList[0][0]?.inspectionStandardName} - ${MainList[0][0]?.inspectionLevelName}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`AQL Level:  CRI-0, ${MainList[0][0]?.aqlLevelName}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`Buyer Info: ${MainList[0][0]?.buyerName + ' - ' + MainList[0][0]?.brandName + ' - ' + MainList[0][0]?.seasonName}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`Supplier: ${MainList[0][0]?.supplierName}`, margin, yPosition);

                yPosition += lineSpacing;
                doc.text(`Factory - Location: ${MainList[0][0]?.addressTypeName}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`End Customer: ${MainList[0][0]?.endCustomer}`, margin, yPosition);

                yPosition += lineSpacing;
                doc.text(`Style / No: ${MainList[0][0]?.styelName}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`PO/ID No: ${MainList[0][0]?.idpono}`, margin, yPosition);

                yPosition += lineSpacing;
                doc.text(`Ref No: ${MainList[0][0]?.styelName}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`Sku(s) Name: ${MainList[0][0]?.skuName}`, margin, yPosition);

                yPosition += lineSpacing;
                doc.text(`PO / Inspection Qty: ${MainList[0][0]?.totalPOQty}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`Shipment Ref No: ${MainList[0][0]?.customerShipmentRefNo}`, margin, yPosition);
                yPosition += lineSpacing;
                doc.text(`Inspector Name: ${MainList[0][0]?.inspectorConclusion}`, margin, yPosition);
                yPosition += lineSpacing;

                //yPosition += 10;
                // Display product image to the right
                const imageX = pageWidth - 160;  // Adjusted further left
                const staticImagePath = `${window.$APIBaseURL + 'Images' + MainList[0][0].styleLogoPath}`;
                const backendEndpoint = `${window.$APIBaseURL}users/convert-image-to-base64?imageUrl=${encodeURIComponent(staticImagePath)}`;
                const Res = await getImageAsBase64(backendEndpoint);
                doc.addImage(Res, 'JPEG', imageX, 160, 120, 150);//left, top, imgWidth, imgHeigh
                checkAndAddNewPage(40);
                doc.line(40, yPosition, pageWidth - 40, yPosition); // Line below details

                // Header
                yPosition += 10
                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                doc.setFillColor(230, 230, 230); // Light gray background
                doc.rect(margin - 5, yPosition - 5, pageWidth - margin * 2 + 10, 25, "F");
                doc.text("Inspection Summary & Result", margin, yPosition + 12);
                yPosition += 30;

                // Inspection Summary
                doc.setFont("helvetica", "normal");
                doc.setFontSize(8);

                doc.text(`Inspection Sample Size: ${res.finalIMainListSub[0]?.samplingSize}`, margin, yPosition);
                doc.text(`Qty % Available while Inspection: ${res.finalIMainListSub[0]?.quantityAvailableInspection}`, pageWidth - 325, yPosition); // Adjusted position to be closer to the center

                yPosition += lineSpacing + 5;
                doc.text(`Sample Inspected Pieces: ${res.finalIMainListSub[0]?.sampleInspectedPieces}`, margin, yPosition);
                doc.text(`Measured Pieces: ${res.finalIMainListSub[0]?.measuredPieces}`, pageWidth - 325, yPosition); // Reduced the offset to move left

                // AQL Defects Section
                yPosition += 30;
                doc.setFont("helvetica", "bold");
                doc.text("AQL Defects:", margin, yPosition);
                yPosition += 10;

                const defectData = [
                    { label: "CRI", value: res.finalIOverallResult[0].critical, color: [255, 0, 0] }, // Red for Critical
                    { label: "MAJ", value: res.finalIOverallResult[0].major, color: [255, 204, 0] }, // Yellow for Major
                    { label: "MIN", value: res.finalIOverallResult[0].minor, color: [0, 112, 192] }, // Blue for Minor
                    {
                        label: res.finalIOverallResult[0].resultStatus === "Pass" ? "   Pass" : "    Fail",
                        value: res.finalIOverallResult[0].resultStatus === "Pass" ? "Pass" : "Fail",
                        color: res.finalIOverallResult[0].resultStatus === "Pass" ? [0, 153, 0] : [255, 0, 0], // Green for Pass, Red for Fail
                    },
                ];
                const rectWidth = 60; // Width of each rectangle
                const rectHeight = 20; // Height of each rectangle
                const spacing = 10; // Space between rectangles
                const totalRectWidth = defectData.length * rectWidth + (defectData.length - 1) * spacing;

                // Calculate starting x position to center the rectangles
                const startX = (pageWidth - totalRectWidth) / 2;
                // Render the rectangles dynamically centered
                defectData.forEach((item, index) => {
                    const x = startX + index * (rectWidth + spacing);
                    doc.setFillColor(...item.color); // Set background color dynamically
                    doc.rect(x, yPosition, rectWidth, rectHeight, "F"); // Draw the box
                    doc.setTextColor(255, 255, 255); // White text for labels
                    doc.text(item.value, x + rectWidth / 2, yPosition + rectHeight / 2 + 4, { align: "center" }); // Center the text in the rectangle
                });
                yPosition += 50;



                // Define two columns
                const leftColumnX = margin;
                const rightColumnX = doc.internal.pageSize.getWidth() / 2 + margin / 2;

                // Split the details into two equal parts
                const halfLength = Math.ceil(FinalISubDetailList.length / 2);
                const leftColumnData = FinalISubDetailList.slice(0, halfLength);
                const rightColumnData = FinalISubDetailList.slice(halfLength);

                // Function to render a single column
                const renderColumn = (data, x, startY) => {
                    let y = startY;
                    data.filter(x => x.Details !== 'Inspector Conclusion').forEach((item) => {
                        // Render item.details in black
                        doc.setFont("helvetica", "bold");
                        doc.setFontSize(8);
                        doc.setTextColor(0, 0, 0); // Black color for details
                        doc.text(item.Details, x, y);

                        // Render item.resultName in dynamic color
                        const resultColor = resultColors[item.ResultName] || [0, 0, 0]; // Default black
                        doc.setFont("helvetica", "normal");
                        doc.setTextColor(...resultColor); // Dynamic color for resultName
                        doc.text(item.ResultName, x + 180, y);

                        // Move to the next row
                        y += 15;
                    });
                };
                // Render the left column
                renderColumn(leftColumnData, leftColumnX, yPosition);
                // Render the right column
                renderColumn(rightColumnData, rightColumnX, yPosition);
                // Return updated height
                yPosition = yPosition + Math.max(leftColumnData.length, rightColumnData.length) * 15;
                doc.setTextColor(0, 0, 0); // Black color for details


                yPosition += 20;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                doc.setFillColor(230, 230, 230); // Light gray background
                doc.rect(margin - 5, yPosition - 5, pageWidth - margin * 2 + 10, 25, "F");
                doc.text("Inspector's conclusion:", margin, yPosition + 12);
                yPosition += 40;
                addLabCommentsAndApprovalResult(doc, margin, yPosition, res.finalIOverallResult[0].resultStatus, MainList[0][0]);

                addNewPage();
                //const ColorValue = res.finalIOverallResult[0].resultStatus === "Fail" ? [255, 0, 0] : [0, 153, 0];
                yPosition = + 120;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setFillColor(230, 230, 230); // Light gray
                doc.rect(margin - 5, yPosition - 10, pageWidth - margin * 2 + 10, 25, "F");
                doc.text("Defects", margin, yPosition + 7);
                doc.text("Result:", pageWidth - 150, yPosition + 7);
                doc.setFontSize(10);
                if (res.finalIOverallResult[0].resultStatus === 'Fail') {
                    doc.setTextColor(255, 0, 0);
                } else {
                    doc.setTextColor(0, 153, 0);
                }
                // doc.setTextColor(ColorValue); // Red for Fail, Green for Pass
                doc.text(res.finalIOverallResult[0]?.resultStatus, pageWidth - 90, yPosition + 7);
                doc.setTextColor(0, 0, 0); // Black color for details
                yPosition += 30;

                //Inspection headers
                doc.setFont("helvetica", "normal");
                doc.setFontSize(10);

                doc.text(`Inspection Standard: ${MainList[0][0]?.inspectionStandardName}`, margin, yPosition);
                doc.text(`Sample Size: ${res.finalIMainListSub[0]?.samplingSize}`, pageWidth - 200, yPosition);
                yPosition += 15;

                doc.text(`Inspection Level: ${MainList[0][0]?.inspectionLevelName}`, margin, yPosition);
                yPosition += 30;

                // AQL TABLE
                const colors = {
                    CRITICAL: [255, 0, 0], // Red
                    MAJOR: [255, 204, 0],  // Yellow
                    MINOR: [0, 112, 192],  // Blue
                };

                const updatedDefectDetails = res.finalIListDefectaqlDetails.map((item) => ({
                    ...item,
                    color: colors[item.label] || [0, 0, 0], // Default to black if no match found
                }));
                const categoryWidth = 150; // Width of each category box
                updatedDefectDetails.forEach((category, index) => {
                    const x = margin + index * (categoryWidth + 20);
                    doc.setFillColor(...category.color);
                    doc.rect(x, yPosition, categoryWidth, 60, "F");
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(8);
                    doc.setTextColor(255, 255, 255); // White text for header
                    doc.text(category.label, x + 10, yPosition + 28);

                    doc.setTextColor(0, 0, 0); // Black text for values
                    doc.setFontSize(9);
                    doc.text(`AQL: ${category.aql}`, x + 70, yPosition + 15);
                    doc.text(`Accept: ${category.accept}`, x + 70, yPosition + 30);
                    doc.text(`Found: ${category.found}`, x + 70, yPosition + 45);
                });
                yPosition += 80;

                // Defect Table:
                // Initialize totals
                let criTotal = 0;
                let majTotal = 0;
                let minTotal = 0;

                // Calculate totals for CRI, MAJ, and MIN
                res.finalIListDefectAqlTable.forEach((row) => {
                    criTotal += parseFloat(row.cri || 0); // Add CRI, default to 0 if undefined
                    majTotal += parseFloat(row.maj || 0); // Add MAJ, default to 0 if undefined
                    minTotal += parseFloat(row.min || 0); // Add MIN, default to 0 if undefined
                });
                const tableHeaders = ["#", "Found Defects", "Focus Area", "CRI", "MAJ", "MIN"];
                const tableRows = res.finalIListDefectAqlTable.map((row, index) => [
                    index + 1, // Row number
                    row.foundDefects || "", // Found Defects
                    row.focusArea || "", // Focus Area
                    row.cri || "", // CRI
                    row.maj || "", // MAJ
                    row.min || "", // MIN
                ]);

                // Add the table using autoTable
                doc.autoTable({
                    startY: yPosition,
                    head: [tableHeaders],
                    body: tableRows,
                    theme: "grid", // Use grid theme for borders
                    styles: {
                        font: "helvetica",
                        fontSize: 9,
                        halign: "center", // Center align the text
                        valign: "middle", // Vertically align to the middle
                    },
                    headStyles: {
                        fillColor: [230, 230, 230], // Light gray background for headers
                        textColor: [0, 0, 0], // Black text
                        fontStyle: "bold",
                    },
                    columnStyles: {
                        0: { cellWidth: 50 }, // Column 1: Row numbers
                        1: { cellWidth: 200 }, // Column 2: Found Defects
                        2: { cellWidth: 100 }, // Column 3: Focus Area
                        3: { cellWidth: 50 }, // Column 4: CRI
                        4: { cellWidth: 50 }, // Column 5: MAJ
                        5: { cellWidth: 50 }, // Column 6: MIN
                    },
                });
                // Add Total Row
                const totalRowY = doc.lastAutoTable.finalY + 13; // Position below the table
                const totalRowHeight = 20; // Height of the total row
                const totalRowWidth = pageWidth - margin * 2; // Total row width

                // Draw border for the total row
                doc.setLineWidth(0.5);
                doc.rect(margin - 5, totalRowY, totalRowWidth - 5, totalRowHeight, "S"); // Add rectangle (border)

                // Add text inside the total row
                doc.setFont("helvetica", "bold");
                doc.text("Found Defects Total", margin, totalRowY + 14); // Label
                doc.text(String(criTotal || 0), margin + 370, totalRowY + 14); // CRI Total
                doc.text(String(majTotal || 0), margin + 420, totalRowY + 14); // MAJ Total
                doc.text(String(minTotal || 0), margin + 470, totalRowY + 14); // MIN Total

                yPosition += 180;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(9);
                doc.setFillColor(230, 230, 230);
                doc.rect(margin - 5, yPosition, pageWidth - margin * 2 + 10, 20, "F");
                doc.text("Findings / Remarks", margin, yPosition + 14);
                doc.setFont("helvetica", "normal");
                // Automatically wrap the QC remarks text within the available page width
                const maxTextWidth = pageWidth - 2 * margin;  // Account for both margins
                const wrappedText = doc.splitTextToSize(res.finalIListDefectAqlTable[0]?.defectFindingRemarks, maxTextWidth);  // Wrap the text
                doc.setTextColor(0, 0, 0);
                doc.text(wrappedText, margin, yPosition + 35);

                // Next Defect functionality
                addNewPage();
                yPosition = + 120;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setFillColor(230, 230, 230); // Light gray
                doc.rect(margin - 5, yPosition - 10, pageWidth - margin * 2 + 10, 25, "F");
                doc.text("Inspector's Remarks", margin, yPosition + 7);
                doc.text("Result:", pageWidth - 150, yPosition + 7);
                doc.setFontSize(10);
                if (res.finalIOverallResult[0].resultStatus === 'Fail') {
                    doc.setTextColor(255, 0, 0);
                } else {
                    doc.setTextColor(0, 153, 0);
                }
                // doc.setTextColor(ColorValue); // Red for Fail, Green for Pass
                doc.text(res.finalIOverallResult[0].resultStatus, pageWidth - 90, yPosition + 7);
                doc.setTextColor(0, 0, 0); // Black color for details
                yPosition += 30;

                // Function to wrap text within a given width
                const wrapText = (doc, text, maxWidth) => {
                    const words = text.split(" ");
                    let line = "";
                    const lines = [];

                    words.forEach((word) => {
                        const testLine = line + word + " ";
                        const testWidth = doc.getTextWidth(testLine);
                        if (testWidth > maxWidth && line !== "") {
                            lines.push(line.trim());
                            line = word + " ";
                        } else {
                            line = testLine;
                        }
                    });
                    lines.push(line.trim());
                    return lines;
                };

                // Starting position
                FinalISubDetailList.forEach((item, index) => {
                    // Determine background color for alternating rows
                    const rowColor = index % 2 === 0 ? [240, 240, 240] : [255, 255, 255];
                    doc.setFillColor(...rowColor);

                    const rowHeight = 20; // Fixed base row height
                    const rowPadding = 5; // Padding for text alignment
                    const maxQtyWidth = 250; // Max width for qtyinfo
                    const maxRemarksWidth = 200; // Max width for remarksinfo

                    // Wrap text for qtyinfo and remarksinfo
                    const qtyinfoLines = wrapText(doc, item.Details, maxQtyWidth);
                    const remarksinfoLines = wrapText(doc, item.FindingsRemarks, maxRemarksWidth);

                    // Calculate dynamic row height based on the number of lines
                    const dynamicRowHeight = Math.max(qtyinfoLines.length, remarksinfoLines.length) * 12 + rowPadding * 2;

                    // Draw background rectangle for the row
                    doc.rect(margin, yPosition, pageWidth - 2 * margin, dynamicRowHeight, 'F');

                    // Render qtyinfo
                    qtyinfoLines.forEach((lineText, lineIndex) => {
                        const yTextPos = yPosition + rowPadding + lineIndex * 12 + 10;
                        doc.setFont("helvetica", "normal");
                        doc.setFontSize(9);
                        doc.setTextColor(0, 0, 0); // Black text
                        doc.text(lineText, margin + 5, yTextPos);
                    });

                    // Render remarksinfo
                    remarksinfoLines.forEach((lineText, lineIndex) => {
                        const yTextPos = yPosition + rowPadding + lineIndex * 12 + 10;
                        doc.setFont("helvetica", "normal");
                        doc.setFontSize(9);
                        doc.setTextColor(0, 0, 0); // Black text
                        doc.text(lineText, margin + 300, yTextPos); // Adjust X position for remarksinfo
                    });

                    // Update yPosition for the next row
                    yPosition += dynamicRowHeight;
                });

                yPosition += 40;
                // // Confirmation Section Headers
                // const confirmationHeaders = [
                //     ["Inspector Name", "Designation", "Signature"],
                //     ["Factory's confirmation:"],
                // ];

                // // Inspector Confirmation
                // doc.autoTable({
                //     startY: yPosition,
                //     body: [["", "", ""]], // Empty row for input fields
                //     head: [confirmationHeaders[0]],
                //     theme: "grid",
                //     styles: {
                //         font: "helvetica",
                //         fontSize: 9,
                //         halign: "center",
                //         valign: "middle",
                //     },
                //     headStyles: {
                //         fillColor: [255, 255, 255], // No background for header
                //         textColor: [0, 0, 0], // Black text
                //         fontStyle: "bold",
                //     },
                //     columnStyles: {
                //         0: { cellWidth: 150 },
                //         1: { cellWidth: 150 },
                //         2: { cellWidth: 150 },
                //     },
                // });
                // yPosition += 30;
                // // Factory Confirmation Section
                // const factoryConfirmationText =
                //     `We hereby confirm that the final inspection report ${getinpsectionID}, conducted on ${MainList[0][0].reportDate}, has been received and thoroughly reviewed. The information in the report is acknowledged and brought to our full attention.
                //      By signing below, we formally accept the final inspection report in its entirety and acknowledge the findings as accurate.`;

                // // Add Factory's Confirmation Text
                // doc.setFont("helvetica", "normal");
                // doc.setFontSize(9);
                // doc.text(factoryConfirmationText, margin, currentHeight, { maxWidth: pageWidth - margin * 2 });
                // yPosition += 30;
                // // Factory Confirmation Table
                // doc.autoTable({
                //     startY: currentHeight,
                //     body: [["", "", ""]], // Empty row for input fields
                //     head: [confirmationHeaders[0]],
                //     theme: "grid",
                //     styles: {
                //         font: "helvetica",
                //         fontSize: 9,
                //         halign: "center",
                //         valign: "middle",
                //     },
                //     headStyles: {
                //         fillColor: [255, 255, 255], // No background for header
                //         textColor: [0, 0, 0], // Black text
                //         fontStyle: "bold",
                //     },
                //     columnStyles: {
                //         0: { cellWidth: 150 },
                //         1: { cellWidth: 150 },
                //         2: { cellWidth: 150 },
                //     },
                // });
                const tableWidth = pageWidth - 2 * margin;
                const rowHeight = 30; // Height of each row

                // Inspector's Details Section
                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                doc.setFillColor(230, 230, 230); // Light gray background
                doc.rect(margin, yPosition + 7, tableWidth, 20, "F"); // Header background
                doc.text("Inspector Name", margin + 5, yPosition + 20);
                doc.text("Designation", margin + tableWidth / 3, yPosition + 20);
                doc.text("Signature", margin + (2 * tableWidth) / 3, yPosition + 20);

                // Draw empty fields
                yPosition += rowHeight;
                doc.rect(margin, yPosition, tableWidth / 3, rowHeight, "S"); // Inspector Name field
                doc.rect(margin + tableWidth / 3, yPosition, tableWidth / 3, rowHeight, "S"); // Designation field
                doc.rect(margin + (2 * tableWidth) / 3, yPosition, tableWidth / 3, rowHeight, "S"); // Signature field

                yPosition += rowHeight + 10; // Add spacing after this section

                // Factory's Confirmation Section
                const confirmationHeaderHeight = 20;
                doc.setFont("helvetica", "bold");
                doc.setFillColor(230, 230, 230); // Light gray background
                doc.rect(margin, yPosition, tableWidth, confirmationHeaderHeight, "F"); // Header background
                doc.text("Factory's confirmation:", margin + 5, yPosition + confirmationHeaderHeight - 5);

                yPosition += confirmationHeaderHeight;

                // Factory's confirmation content
                const confirmationText = `We hereby confirm that the final inspection report ${getinpsectionID}, conducted on ${MainList[0][0].reportDate}, has been received and thoroughly reviewed. The information in the report is acknowledged and brought to our full attention.
                By signing below, we formally accept the final inspection report in its entirety and acknowledge the findings as accurate.`;
                const maxLineWidth = tableWidth - 20; // Leave padding on both sides
                const paragraphs = confirmationText.split("\n"); // Split by newlines for multiple paragraphs
                doc.setFont("helvetica", "normal");
                doc.setFontSize(9);
                yPosition += confirmationHeaderHeight;
                paragraphs.forEach((paragraph) => {
                    const wrappedLines = doc.splitTextToSize(paragraph, maxLineWidth);

                    // Add each wrapped line
                    wrappedLines.forEach((line) => {
                        doc.text(line, margin + 10, yPosition);
                        yPosition += 12; // Line height
                    });

                    yPosition += 10; // Add extra spacing between paragraphs
                });
                // Factory's Details Section
                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                doc.setFillColor(230, 230, 230); // Light gray background
                doc.rect(margin, yPosition + 7, tableWidth, 20, "F"); // Header background
                doc.text("Factory Person Name", margin + 5, yPosition + 20);
                doc.text("Designation", margin + tableWidth / 3, yPosition + 20);
                doc.text("Signature", margin + (2 * tableWidth) / 3, yPosition + 20);

                // Draw empty fields
                yPosition += rowHeight;
                doc.rect(margin, yPosition, tableWidth / 3, rowHeight, "S"); // Factory Person Name field
                doc.rect(margin + tableWidth / 3, yPosition, tableWidth / 3, rowHeight, "S"); // Designation field
                doc.rect(margin + (2 * tableWidth) / 3, yPosition, tableWidth / 3, rowHeight, "S"); // Signature field

                // Final adjustment
                yPosition += rowHeight;

            } else if (section.isSelected && section.Name === 'Report PDF - Primary pages.') {
                addNewPage();
                yPosition = + 120;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setFillColor(230, 230, 230); // Light gray
                doc.rect(margin - 5, yPosition - 10, pageWidth - margin * 2 + 10, 25, "F");
                doc.text("Quantity", margin, yPosition + 7);
                doc.text("Result:", pageWidth - 150, yPosition + 7);
                doc.setFontSize(10);
                if (res.finalIMainListSub[0].result === 'OK') {
                    doc.setTextColor(0, 153, 0);
                } else if (res.finalIMainListSub[0].result === 'Not Ok') {
                    doc.setTextColor(255, 0, 0);
                }
                else {
                    doc.setTextColor(0, 0, 139)
                }
                doc.text(res.finalIMainListSub[0]?.result, pageWidth - 90, yPosition + 7);
                doc.setTextColor(0, 0, 0); // Black color for details
                yPosition += 30;


                // Inspection Summary
                doc.setFont("helvetica", "normal");
                doc.setFontSize(8);

                doc.text(`Total PO Qty: ${res.finalIMainListSub[0]?.totalPOQty}`, margin, yPosition);
                doc.text(`No. of the Inspected Carton Box(es): ${res.finalIMainListSub[0]?.inspectedCartonBox}`, pageWidth - 325, yPosition); // Adjusted position to be closer to the center

                yPosition += lineSpacing + 5;
                doc.text(`Inspected Qty: ${res.finalIMainListSub[0]?.totalOffQty}`, margin, yPosition);
                doc.text(`Qty % Available for Inspection: ${res.finalIMainListSub[0]?.quantityAvailableInspection}`, pageWidth - 325, yPosition); // Reduced the offset to move left

                yPosition += lineSpacing + 5;
                doc.text(`PO vs Inspection Qty: ${res.finalIMainListSub[0]?.offeredpercent}`, margin, yPosition);
                doc.text(`Total Carton Boxes: ${res.finalIMainListSub[0]?.totalCartonBox}`, pageWidth - 325, yPosition); // Reduced the offset to move left

                yPosition += 30;
                // Order Summary
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setFillColor(230, 230, 230); // Light gray
                doc.rect(margin, yPosition, pageWidth - 2 * margin, 20, "F");
                doc.text("Order Summary", margin + 5, yPosition + 14);
                yPosition += 30;

                let grandTotalPOQty = 0;
                let grandTotalInsQty = 0;

                // Iterate through SKUs
                groupedData.forEach((sku, skuIndex) => {
                    // SKU Name Header
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(10);
                    doc.text(`Sku Name: ${sku.name}`, margin, yPosition);
                    yPosition += 20;

                    // Table Header and Data
                    const tableHeaders = ["#", "Style No.", "End Customer", "PO/ID No.", "Ref No.", "PO Qty", "Ins. Qty", "%"];
                    const tableRows = sku.rows.map((row, rowIndex) => {
                        const percentage = ((row.insQty / row.poQty) * 100).toFixed(2) + "%";
                        return [
                            rowIndex + 1, // Row Number
                            row.styleNo,
                            row.endCustomer,
                            row.poIDNo,
                            row.refNo,
                            row.poQty,
                            row.insQty,
                            percentage,
                        ];
                    });

                    // Add Subtotal Row
                    const subtotalPOQty = sku.rows.reduce((sum, row) => sum + row.poQty, 0);
                    const subtotalInsQty = sku.rows.reduce((sum, row) => sum + row.insQty, 0);
                    const subtotalPercentage = ((subtotalInsQty / subtotalPOQty) * 100).toFixed(2) + "%";
                    tableRows.push(["", "", "", "", "Sub Total", subtotalPOQty, subtotalInsQty, subtotalPercentage]);

                    // Update Grand Totals
                    grandTotalPOQty += subtotalPOQty;
                    grandTotalInsQty += subtotalInsQty;

                    // Render Table using autoTable
                    doc.autoTable({
                        startY: yPosition,
                        head: [tableHeaders],
                        body: tableRows,
                        theme: "grid",
                        styles: {
                            font: "helvetica",
                            fontSize: 9,
                            halign: "center", // Center align text
                        },
                        headStyles: {
                            fillColor: [230, 230, 230], // Light gray background for headers
                            textColor: [0, 0, 0], // Black text
                            fontStyle: "bold",
                        },
                        columnStyles: {
                            0: { cellWidth: 20 }, // Column 1: Row Number
                            1: { cellWidth: 80 }, // Column 2: Style No.
                            2: { cellWidth: 100 }, // Column 3: End Customer
                            3: { cellWidth: 80 }, // Column 4: PO/ID No.
                            4: { cellWidth: 70 }, // Column 5: Ref No.
                            5: { cellWidth: 50 }, // Column 6: PO Qty
                            6: { cellWidth: 50 }, // Column 7: Ins. Qty
                            7: { cellWidth: 50 }, // Column 8: %
                        },
                    });

                    yPosition = doc.lastAutoTable.finalY + 20; // Update yPosition after table
                });

                // Add Grand Total Row
                const grandTotalPercentage = ((grandTotalInsQty / grandTotalPOQty) * 100).toFixed(2) + "%";
                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                doc.text("Grand Total", margin + 300, yPosition);
                doc.text(String(grandTotalPOQty), margin + 360, yPosition);
                doc.text(String(grandTotalInsQty), margin + 400, yPosition);
                doc.text(grandTotalPercentage, margin + 450, yPosition);



                // Add Grand Total Row
                doc.setFont("helvetica", "bold");
                doc.text("Grand Total", margin + 300, yPosition);
                doc.text(String(grandTotalPOQty), margin + 360, yPosition);
                doc.text(String(grandTotalInsQty), margin + 400, yPosition);
                doc.text(((grandTotalInsQty / grandTotalPOQty) * 100).toFixed(2) + "%", margin + 450, yPosition);

                yPosition += 30; // Return updated Y position

                addNewPage();
                yPosition = + 120;
                // Add section header
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setFillColor(230, 230, 230); // Light gray background
                doc.rect(margin, yPosition, pageWidth - 2 * margin, 20, "F");
                doc.text(`Defects Photos - Found Defects: ${res.finalIListDefectImages.length}`, margin + 5, yPosition + 14);
                yPosition += 30;

                // Constants for layout
                const imagesPerRow = 3;
                const pageWidthWithoutMargins = pageWidth - margin * 2;
                const imageSpacing = 15; // Reduced spacing for compact layout
                const imageWidth = (pageWidthWithoutMargins - imageSpacing * (imagesPerRow - 1)) / imagesPerRow;
                const imageHeight = imageWidth * 0.75; // Adjusted height proportionally
                const labelHeight = 15; // Height for the defect label
                const textSpacing = 10; // Spacing for defect name text
                const rowSpacing = 20; // Additional space between rows

                // Initial positions
                let imageX = margin;
                let imageY = yPosition + 20; // Position images below the section title

                res.finalIListDefectImages.forEach((defect, index) => {
                    // Check if image exceeds the page height
                    if (imageY + imageHeight + labelHeight + rowSpacing > pageHeight - footerHeight) {
                        addNewPage(); // Add new page
                        imageX = margin; // Reset X position
                        imageY = margin + 100; // Reset Y position for new page
                    }

                    // Add the image (if available)
                    if (defect.images) {
                        const imgPath = `${window.$APIBaseURL + 'Images/' + defect.images}`;
                        doc.addImage(imgPath, 'JPEG', imageX, imageY, imageWidth, imageHeight);
                    }

                    // Add Defect Name below the image
                    doc.setFont("helvetica", "normal");
                    doc.setFontSize(9);
                    doc.text(
                        defect.defectName,
                        imageX + imageWidth / 2 - doc.getTextWidth(defect.defectName,) / 2, // Center-align text under the image
                        imageY + imageHeight + textSpacing
                    );

                    // Add Defect Label (CRI, MAJ, MIN) below the name
                    const labelColor =
                        defect.result === "CRI" ? [255, 0, 0] : defect.result === "MAJ" ? [255, 204, 0] : [0, 112, 192];
                    doc.setFillColor(...labelColor);
                    doc.rect(
                        imageX + imageWidth / 2 - 20, // Center-align the label
                        imageY + imageHeight + textSpacing + 10,
                        40,
                        labelHeight,
                        "F"
                    );
                    doc.setTextColor(255, 255, 255); // White text for the label
                    doc.text(
                        defect.result,
                        imageX + imageWidth / 2 - doc.getTextWidth(defect.result) / 2,
                        imageY + imageHeight + textSpacing + 22
                    );
                    doc.setTextColor(0, 0, 0); // Reset text color

                    // Move X position to place the next image in the same row
                    imageX += imageWidth + imageSpacing;

                    // Move to the next row after `imagesPerRow` images
                    if ((index + 1) % imagesPerRow === 0) {
                        imageX = margin; // Reset X for the next row
                        imageY += imageHeight + labelHeight + textSpacing + rowSpacing; // Add extra space between rows
                    }
                });

                // Update yPosition for the next section
                yPosition = imageY + 50; // Space below the last row



            } else {
                addNewPage();
                yPosition = + 120;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(12);
                doc.setFillColor(230, 230, 230); // Light gray
                doc.rect(margin - 5, yPosition - 10, pageWidth - margin * 2 + 10, 25, "F");
                doc.text("Confirmation of Available Information", margin, yPosition + 7);
                doc.text("Result:", pageWidth - 150, yPosition + 7);
                doc.setFontSize(10);
                if (res.finalIListConfimAvilinfoTable[0].finalResult === 'OK') {
                    doc.setTextColor(0, 153, 0);
                } else if (res.finalIListConfimAvilinfoTable[0].finalResult === 'Not Ok') {
                    doc.setTextColor(255, 0, 0);
                } else {
                    doc.setTextColor(0, 0, 139)
                }

                // doc.setTextColor(ColorValue); // Red for Fail, Green for Pass
                doc.text(res.finalIListConfimAvilinfoTable[0].finalResult, pageWidth - 90, yPosition + 7);
                doc.setTextColor(0, 0, 0); // Black color for details
                yPosition += 30;

                res.finalIListConfimAvilinfoTable.forEach((item, index) => {
                    // Determine background color for alternating rows
                    const rowColor = index % 2 === 0 ? [240, 240, 240] : [255, 255, 255];
                    doc.setFillColor(...rowColor);

                    const rowHeight = 30; // Set a fixed row height, equal to yPosition increment

                    // Draw background rectangle for the row
                    doc.rect(margin, yPosition, pageWidth - 2 * margin, rowHeight, 'F');

                    // Render the row number
                    doc.setFont("helvetica", "normal");
                    doc.setFontSize(9);
                    doc.setTextColor(0, 0, 0);
                    doc.text(`${index + 1}`, margin + 5, yPosition + 14);

                    // Render Details (qtyinfo)
                    doc.text(item.details, margin + 30, yPosition + 14);

                    // Render ResultName in corresponding color
                    const resultColor = resultColors[item.result] || [0, 0, 0]; // Default black if resultName is undefined
                    doc.setTextColor(...resultColor);
                    doc.text(item.result, pageWidth - 140, yPosition + 14);


                    // Reset text color to black
                    doc.setTextColor(0, 0, 0);

                    // Update yPosition for the next row
                    yPosition += rowHeight; // Directly use rowHeight as the increment value
                });


                // Generate sections dynamically
                Object.entries(groupedmainData).forEach(([header, items]) => {
                    addNewPage();
                    yPosition = generateStrikeOffDetails(doc, margin, pageWidth, yPosition, items, header);
                });
            }
        }
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            addFooter(i, totalPages);
        }


        doc.save(`FINAL INSPECTION REPORT.pdf`);
    }


    const exportExcelFile = (data, fileName, ImageShow, measurementdata, skudetails) => {
        // showLoader();

        const workbook = new ExcelJS.Workbook();

        //const sheetNames = ["TEST GRID","INSPECTION REPORT", "STD PICS", "DEFECTIVE PICS", "MEASUREMENT LOG"];
        const sheetNames = ["SHIPMENT", "STANDARD PICS", "DEFECTIVE PICS", "MEASUREMENT LOG", "Style CAD", "T&C"];

        sheetNames.forEach(sheetName => {
            const sheet = workbook.addWorksheet(sheetName);
            // I believe this needs to be set to show in LibreOffice.
            sheet.state = 'visible';

            const twoMinutes = 1 * 60 * 1000; // 2 minutes in milliseconds
            let URL = window.$APIBaseURL + "Images";



            if (sheetName === 'SHIPMENT') {

                sheet.getColumn('A').width = 23;
                sheet.getColumn('B').width = 9;
                sheet.getColumn('C').width = 22;
                sheet.getColumn('D').width = 20.71;
                sheet.getColumn('E').width = 18.29;
                sheet.getColumn('F').width = 20.29;
                sheet.getColumn('G').width = 17.86;
                sheet.getRow(1).height = 110;



                //sheet.getRow(1).height = 70;
                // sheet.mergeCells('A1:C1');
                // sheet.mergeCells('D1:L1');

                let logo1 = window.$APIBaseURL + 'Images/Focus.png';
                let logo2 = window.$APIBaseURL + 'Images/LogoCommon.png';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo1);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'png',
                    });

                    // sheet.addImage(imageId2, 'A1:B1');
                    sheet.addImage(imageId2, {
                        tl: { col: 0, row: 0 },
                        ext: { width: 163, height: 133 },
                    });

                })

                array.map(async x => {
                    result = await toDataURL(logo2);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'png',
                    });

                    //   sheet.addImage(imageId2, 'C1:G1');


                    sheet.addImage(imageId2, {
                        tl: { col: 1, row: 0 },
                        ext: { width: 743, height: 134 },
                    });
                })


                // sheet.getCell('A1').border = borderStyles;
                // sheet.getCell('B1:G1').border = borderStyles;



                // // //End First Row

                //// Second Row

                sheet.getCell('A2:G2').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.mergeCells('A2:G2');
                sheet.getRow(2).height = 5;
                //End Second Row

                // Third Row
                sheet.getCell('A3').value = 'Delivery (ITS#):';
                sheet.getCell('A3').alignment = { horizontal: 'left' };
                sheet.mergeCells('B3:C3');
                sheet.getCell('C3').value = data.item1[0].customerShipmentRefNo;
                sheet.getCell('C3').alignment = { horizontal: 'left' };
                sheet.getCell('A3').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C3').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A3').border = borderStyles;
                sheet.getCell('B3:C3').border = borderStyles;

                sheet.getCell('D3').value = 'Customers:';
                sheet.getCell('D3').alignment = { horizontal: 'left' };

                sheet.mergeCells('E3:G3');
                sheet.getCell('E3').value = data.item1[0].customers;
                sheet.getCell('E3').alignment = { horizontal: 'left' };
                sheet.getCell('D3').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E3').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D3').border = borderStyles;
                sheet.getCell('E3:G3').border = borderStyles;
                // Third Row End

                // Four Row
                sheet.getCell('A4').value = 'Supplier/Agent:';
                sheet.getCell('A4').alignment = { horizontal: 'left' };
                sheet.mergeCells('B4:C4');
                sheet.getCell('C4').value = 'CUBE FASHIONS';
                sheet.getCell('C4').alignment = { horizontal: 'left' };
                sheet.getCell('A4').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C4').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A4').border = borderStyles;
                sheet.getCell('B4:C4').border = borderStyles;

                sheet.getCell('D4').value = 'ID numbers:';
                sheet.getCell('D4').alignment = { horizontal: 'left' };

                sheet.mergeCells('E4:G4');
                sheet.getCell('E4').value = data.item1[0].idnos;
                sheet.getCell('E4').alignment = { horizontal: 'left' };
                sheet.getCell('D4').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E4').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D4').border = borderStyles;
                sheet.getCell('E4:G4').border = borderStyles;
                // Four Row End

                // Five Row
                sheet.getCell('A5').value = 'Factory:';
                sheet.getCell('A5').alignment = { horizontal: 'left' };
                sheet.mergeCells('B5:C5');
                sheet.getCell('C5').value = data.item1[0].supplierName;
                sheet.getCell('C5').alignment = { horizontal: 'left' };
                sheet.getCell('A5').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C5').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A5').border = borderStyles;
                sheet.getCell('B5:C5').border = borderStyles;

                sheet.getCell('D5').value = 'Po numbers:';
                sheet.getCell('D5').alignment = { horizontal: 'left' };

                sheet.mergeCells('E5:G5');
                sheet.getCell('E5').value = data.item1[0].ponos;
                sheet.getCell('E5').alignment = { horizontal: 'left' };
                sheet.getCell('D5').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E5').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('D5').border = borderStyles;
                sheet.getCell('E5:G5').border = borderStyles;
                // Five End
                // Six
                sheet.mergeCells('A6:G6');
                sheet.getRow(6).height = 5;
                // Six End
                // Seven Row
                sheet.getCell('A7').value = 'QC Inspector:';
                sheet.getCell('A7').alignment = { horizontal: 'left' };
                sheet.mergeCells('B7:C7');
                sheet.getCell('C7').value = data.item1[0].qcInspector;
                sheet.getCell('C7').alignment = { horizontal: 'left' };
                sheet.getCell('A7').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C7').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A7').border = borderStyles;
                sheet.getCell('B7:C7').border = borderStyles;

                sheet.getCell('D7').value = 'Inspection Date:';
                sheet.getCell('D7').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E7').value = data.item1[0].inspectionDate;
                sheet.getCell('E7').alignment = { horizontal: 'left' };
                sheet.getCell('D7').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E7').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D7').border = borderStyles;
                sheet.getCell('E7').border = borderStyles;

                sheet.getCell('F7').value = 'Location COO:';
                sheet.getCell('F7').alignment = { horizontal: 'left' };

                sheet.getCell('G7').value = 'TIRUPUR/ INDIA';
                sheet.getCell('G7').alignment = { horizontal: 'left' };
                sheet.getCell('F7').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G7').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F7').border = borderStyles;
                sheet.getCell('G7').border = borderStyles;
                // Seven End
                // eight
                sheet.mergeCells('A8:G8');
                sheet.getRow(8).height = 5;
                // eight End

                //// nine Row

                sheet.getCell('A9:C9').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A9').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A9').value = 'Inspected style';
                sheet.getCell('A9').alignment = { horizontal: 'center' };

                sheet.getCell('D9:E9').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('D9').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('D9').value = 'Shipment quantities';
                sheet.getCell('D9').alignment = { horizontal: 'center' };

                sheet.getCell('F9:G9').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('F9').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };
                sheet.getCell('F9').value = 'Shipping marks/ cartons';
                sheet.getCell('F9').alignment = { horizontal: 'center' };

                sheet.mergeCells('A9:C9');
                sheet.mergeCells('D9:E9');
                sheet.mergeCells('F9:G9');
                //End nine Row

                // Ten

                sheet.getCell('A10').value = 'Style code:';
                sheet.getCell('A10').alignment = { horizontal: 'left' };
                sheet.mergeCells('B10:C10');
                sheet.getCell('C10').value = data.item1[0].styleCode;
                sheet.getCell('C10').alignment = { horizontal: 'left' };
                sheet.getCell('A10').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C10').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('D10').value = 'QTY on order:';
                sheet.getCell('D10').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E10').value = data.item1[0].qtyonOrder;
                sheet.getCell('E10').alignment = { horizontal: 'left' };
                sheet.getCell('D10').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E10').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('A10').border = borderStyles;
                sheet.getCell('B10:C10').border = borderStyles;
                sheet.getCell('D10').border = borderStyles;
                sheet.getCell('E10').border = borderStyles;

                sheet.getCell('F10').value = 'Carton markings:';
                sheet.getCell('F10').alignment = { horizontal: 'left' };

                sheet.getCell('G10').value = data.item1[0].cartonMarkings;
                sheet.getCell('G10').alignment = { horizontal: 'left' };
                sheet.getCell('F10').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G10').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F10').border = borderStyles;
                sheet.getCell('G10').border = borderStyles;



                // End Ten
                // 11

                sheet.getCell('A11').value = 'Style name:';
                sheet.getCell('A11').alignment = { horizontal: 'left' };
                sheet.mergeCells('B11:C11');
                sheet.getCell('C11').value = data.item1[0].styleName;
                sheet.getCell('C11').alignment = { horizontal: 'left' };
                sheet.getCell('A11').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C11').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('D11').value = 'QTY to be shipped:';
                sheet.getCell('D11').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E11').value = data.item1[0].qtytobeShipped;
                sheet.getCell('E11').alignment = { horizontal: 'left' };
                sheet.getCell('D11').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E11').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('A11').border = borderStyles;
                sheet.getCell('B11:C11').border = borderStyles;
                sheet.getCell('E13').border = borderStyles;
                sheet.getCell('D13').border = borderStyles;

                sheet.getCell('F11').value = 'Gross weight (kg):';
                sheet.getCell('F11').alignment = { horizontal: 'left' };

                sheet.getCell('G11').value = data.item1[0].grossWeight;
                sheet.getCell('G11').alignment = { horizontal: 'left' };
                sheet.getCell('F11').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G11').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F11').border = borderStyles;
                sheet.getCell('G11').border = borderStyles;

                // End 11

                // 12

                sheet.getCell('A12').value = 'Description:';
                sheet.getCell('A12').alignment = { horizontal: 'left' };
                sheet.mergeCells('B12:C12');
                sheet.getCell('C12').value = data.item1[0].categoryName;
                sheet.getCell('C12').alignment = { horizontal: 'left' };
                sheet.getCell('A12').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C12').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A12').border = borderStyles;
                sheet.getCell('B12:C12').border = borderStyles;

                sheet.getCell('D12').value = 'Difference in %';
                sheet.getCell('D12').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E12').value = data.item1[0].differenceinPercentage;
                sheet.getCell('E12').alignment = { horizontal: 'left' };
                sheet.getCell('D12').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E12').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D12').border = borderStyles;
                sheet.getCell('E12').border = borderStyles;

                sheet.getCell('F12').value = 'Measurements (cm)';
                sheet.getCell('F12').alignment = { horizontal: 'left' };

                sheet.getCell('G12').value = data.item1[0].measurements;
                sheet.getCell('G12').alignment = { horizontal: 'left' };
                sheet.getCell('F12').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G12').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('F12').border = borderStyles;
                sheet.getCell('G12').border = borderStyles;

                // End 12

                // 13

                sheet.getCell('A13').value = 'Colour:';
                sheet.getCell('A13').alignment = { horizontal: 'left' };
                sheet.mergeCells('B13:C13');
                sheet.getCell('C13').value = data.item1[0].colour;
                sheet.getCell('C13').alignment = { horizontal: 'left' };
                sheet.getCell('A13').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C13').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A13').border = borderStyles;
                sheet.getCell('B13:C13').border = borderStyles;

                sheet.getCell('D13').value = 'Ex-factory date:';
                sheet.getCell('D13').alignment = { horizontal: 'left' };



                // sheet.mergeCells('E7:E7');
                sheet.getCell('E13').value = data.item1[0].exFacDate;
                sheet.getCell('E13').alignment = { horizontal: 'left' };
                sheet.getCell('D13').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E13').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('D13').border = borderStyles;
                sheet.getCell('E13').border = borderStyles;

                sheet.getCell('F13').value = 'Barcode stickers? ';
                sheet.getCell('F13').alignment = { horizontal: 'left' };

                sheet.getCell('G13').value = data.item1[0].barcodeStickers;
                sheet.getCell('G13').alignment = { horizontal: 'left' };
                sheet.getCell('F13').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G13').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F13').border = borderStyles;
                sheet.getCell('G13').border = borderStyles;

                // End 13

                // 14

                sheet.getCell('A14').value = 'Size range:';
                sheet.getCell('A14').alignment = { horizontal: 'left' };
                sheet.mergeCells('B14:C14');
                sheet.getCell('C14').value = data.item1[0].sizeName;
                sheet.getCell('C14').alignment = { horizontal: 'left' };
                sheet.getCell('A14').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C14').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A14').border = borderStyles;
                sheet.getCell('B14:C14').border = borderStyles;

                sheet.getCell('D14').value = 'Ex-factory (ITS 2)';
                sheet.getCell('D14').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E14').value = data.item1[0].exFactITS2;
                sheet.getCell('E14').alignment = { horizontal: 'left' };
                sheet.getCell('D14').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E14').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('D14').border = borderStyles;
                sheet.getCell('E14').border = borderStyles;

                sheet.getCell('F14').value = 'Mixed cartons marked?';
                sheet.getCell('F14').alignment = { horizontal: 'left' };

                sheet.getCell('G14').value = data.item1[0].mixedcartonsmarked;
                sheet.getCell('G14').alignment = { horizontal: 'left' };
                sheet.getCell('F14').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G14').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F14').border = borderStyles;
                sheet.getCell('G14').border = borderStyles;

                // End 14
                // 15

                sheet.mergeCells('A15:G15');
                sheet.getRow(15).height = 5;

                // End 15

                //16

                sheet.getCell('A16:E16').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A16').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A16').value = 'INSPECTOR S CHECK LIST * Tick the correct box';
                sheet.getCell('A16').alignment = { horizontal: 'center' };

                sheet.getCell('F16:F16').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('F16').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('F16').value = 'Yes';
                sheet.getCell('F16').alignment = { horizontal: 'center' };

                sheet.getCell('G16:G16').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('G16').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };
                sheet.getCell('G16').value = 'No';
                sheet.getCell('G16').alignment = { horizontal: 'center' };

                sheet.mergeCells('A16:E16');
                //sheet.mergeCells('F9:E9');
                //sheet.mergeCells('F9:G9');

                // End 16




                // sheet.mergeCells('A13:H13');
                // sheet.getCell('A13').value = 'INSPECTORS CHECK LIST * Tick the correct box';
                // sheet.getCell('A13:H13').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "3268ba" },
                // };
                // sheet.getCell('A13').font = {
                //     name: 'Helvetica',
                //     color: { argb: 'ebeff5' },
                //     //family: 2,
                //     size: 12,
                //     // italic: true
                // };

                // sheet.mergeCells('I13:J13');
                // sheet.getCell('I13').value = 'YES';
                // sheet.getCell('I13:J13').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "3268ba" },
                // };
                // sheet.getCell('I13').font = {
                //     name: 'Helvetica',
                //     color: { argb: 'ebeff5' },
                //     //family: 2,
                //     size: 12,
                //     // italic: true
                // };

                // sheet.mergeCells('K13:L13');
                // sheet.getCell('K13').value = 'NO';
                // sheet.getCell('K13:L13').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "3268ba" },
                // };
                // sheet.getCell('K13').font = {
                //     name: 'Helvetica',
                //     color: { argb: 'ebeff5' },
                //     //family: 2,
                //     size: 12,
                //     // italic: true
                // };


                // // End 13th Row
                // // 14 Row

                // 15

                let LabelForeColor = '85420c';
                let ValueForeColor = '12191a';




                let Start1 = 'A';
                let End1 = 'E';
                let SecondStart1 = 'F';
                //let SecondEnd1 = 'F';
                let ThirdStart1 = 'G';
                //let ThirdEnd1 = 'G';

                for (let index = 0; index < data.item2.length; index++) {
                    Start1 += index + 17;
                    End1 += index + 17;
                    SecondStart1 += index + 17;
                    // SecondEnd1 += index + 17;
                    ThirdStart1 += index + 17;
                    // ThirdEnd1 += index + 17;

                    let borderDesign;
                    // if (index == 0) // First row Only BorderStyle
                    // { borderDesign = borderStyles; }
                    // else if (index == (data.item3.length - 1)) // Last row Only BorderStyle
                    // { borderDesign = borderStyleslrb; }
                    // else { borderDesign = borderStyleslr; }//In between row BorderStyle

                    // UnWanted Code Client needs to be a empty row
                    if (Start1 === 'A24') { sheet.mergeCells(Start1, ThirdStart1); sheet.getRow(24).height = 5; }
                    else {
                        sheet.mergeCells(Start1, End1);
                        sheet.getCell(Start1, End1).border = borderStyles;

                        //sheet.mergeCells(SecondStart1, SecondEnd1);
                        //sheet.mergeCells(ThirdStart1, ThirdEnd1);

                        sheet.getCell(Start1).value = data.item2[index].value
                        // sheet.getCell(Start).border = borderStyles;
                        sheet.getCell(SecondStart1).value = data.item2[index].result === 'Yes' ? '✔' : data.item2[index].result === 'N/A' ? 'n/a' : '';
                        // sheet.getCell(SecondStart).border = borderStyles;
                        sheet.getCell(ThirdStart1).value = data.item2[index].result === 'No' ? '✖' : '';
                        // sheet.getCell(ThirdStart).border = borderStyles;

                        // sheet.getCell(FourthStart).border = borderStyles;

                        sheet.getCell(SecondStart1).alignment = { horizontal: 'center' };
                        sheet.getCell(SecondStart1).border = borderStyles;
                        sheet.getCell(ThirdStart1).alignment = { horizontal: 'center' };
                        sheet.getCell(ThirdStart1).border = borderStyles;
                    }



                    Start1 = 'A';
                    End1 = 'E';
                    SecondStart1 = 'F';
                    //SecondEnd1 = 'F';
                    ThirdStart1 = 'G';
                    //ThirdEnd1 = 'G';

                }

                // End 15th Row
                // 28

                sheet.mergeCells('A28:G28');
                sheet.getRow(28).height = 5;

                // End 28
                // 29

                sheet.getCell('A29:G29').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A29').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A29').value = 'AQL SAMPLES';
                sheet.mergeCells('A29:G29');
                sheet.getCell('A29').alignment = { horizontal: 'center' };


                // End 29

                // 30

                sheet.getCell('A30').value = 'Inspection sample size (as per AQL 2.5:';
                sheet.getCell('A30').alignment = { horizontal: 'left' };
                sheet.mergeCells('A30:C30');
                sheet.getCell('D30').value = data.item1[0].samplingSize;
                sheet.getCell('D30').alignment = { horizontal: 'center' };
                sheet.mergeCells('D30:G30');
                sheet.getCell('A30').font = {
                    name: fontName,
                    size: fontSize,
                    bold: true,
                };
                sheet.getCell('D30').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A30:C30').border = borderStyles;
                sheet.getCell('D30:G30').border = borderStyles;

                // End 30

                // 31

                sheet.getCell('A31').value = 'Measurement inspection. Measured pieces:';
                sheet.getCell('A31').alignment = { horizontal: 'left' };
                sheet.mergeCells('A31:C31');
                sheet.getCell('D31').value = data.item1[0].measurementCheckedPeices;
                sheet.getCell('D31').alignment = { horizontal: 'center' };
                sheet.mergeCells('D31:G31');
                sheet.getCell('A31').font = {
                    name: fontName,
                    size: fontSize,
                    bold: true,
                };
                sheet.getCell('D31').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A31:C31').border = borderStyles;
                sheet.getCell('D31:G31').border = borderStyles;

                // End 31

                // 32

                sheet.getCell('A32').value = 'Inspected carton numbers:';
                sheet.getCell('A32').alignment = { horizontal: 'left' };
                sheet.mergeCells('A32:C32');
                sheet.getCell('D32').value = data.item1[0].inspectedCartonBox;
                sheet.getCell('D32').alignment = { horizontal: 'center' };
                sheet.mergeCells('D32:G32');
                sheet.getCell('A32').font = {
                    name: fontName,
                    size: fontSize,
                    bold: true,
                };
                sheet.getCell('D32').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A32:C32').border = borderStyles;
                sheet.getCell('D32:G32').border = borderStyles;

                // End 30
                // 33
                sheet.mergeCells('A33:G33');
                sheet.getRow(33).height = 5;

                // End 33
                // 34
                sheet.getCell('A34').value = 'Defects found in inspection (free text)';
                sheet.mergeCells('A34:D34');
                sheet.getCell('A34:D34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('E34').value = 'Critical';

                sheet.getCell('E34').alignment = { horizontal: 'center' };

                sheet.getCell('E34:E34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('E34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('F34').value = 'Major';

                sheet.getCell('F34').alignment = { horizontal: 'center' };

                sheet.getCell('F34:F34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('F34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('G34').value = 'Minor';

                sheet.getCell('G34').alignment = { horizontal: 'center' };

                sheet.getCell('G34:G34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('G34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };





                let Start = 'A';
                let End = 'D';
                let SecondStart = 'E';
                let SecondEnd = 'E';
                let ThirdStart = 'F';
                let ThirdEnd = 'F';
                let FourthStart = 'G';
                let FourthEnd = 'G';
                for (let index = 0; index < data.item3.length; index++) {
                    Start += index + 35;
                    End += index + 35;
                    SecondStart += index + 35;
                    // SecondEnd += index + 35;
                    ThirdStart += index + 35;
                    //ThirdEnd += index + 35;
                    FourthStart += index + 35;
                    // FourthEnd += index + 35;
                    // let borderDesign;
                    // if (index == 0) // First row Only BorderStyle
                    // { borderDesign = borderStyles; }
                    // else if (index == (data.item3.length - 1)) // Last row Only BorderStyle
                    // { borderDesign = borderStyleslrb; }
                    // else { borderDesign = borderStyleslr; }//In between row BorderStyle



                    sheet.mergeCells(Start, End);
                    sheet.getCell(Start, End).border = borderStyles;

                    //sheet.mergeCells(SecondStart, SecondEnd);
                    //sheet.mergeCells(ThirdStart, ThirdEnd);
                    //sheet.mergeCells(FourthStart, FourthEnd);
                    sheet.getCell(Start).value = data.item3[index].defectName
                    sheet.getCell(Start).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };
                    sheet.getCell(SecondStart).value = data.item3[index].crirical
                    sheet.getCell(SecondStart).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };

                    sheet.getCell(SecondStart).border = borderStyles;
                    sheet.getCell(ThirdStart).value = data.item3[index].major
                    sheet.getCell(ThirdStart).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };
                    sheet.getCell(ThirdStart).border = borderStyles;
                    sheet.getCell(FourthStart).value = data.item3[index].minor
                    sheet.getCell(FourthStart).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };
                    sheet.getCell(FourthStart).border = borderStyles;
                    sheet.getCell(SecondStart).alignment = { horizontal: 'right' };
                    sheet.getCell(ThirdStart).alignment = { horizontal: 'right' };
                    sheet.getCell(FourthStart).alignment = { horizontal: 'right' };


                    Start = 'A';
                    End = 'D';
                    SecondStart = 'E';
                    SecondEnd = 'E';
                    ThirdStart = 'F';
                    ThirdEnd = 'F';
                    FourthStart = 'G';
                    FourthEnd = 'G';
                }

                //     let A = 'A';
                //     let D = 'D';
                //    for (let index = 1; index < 13; index++) {
                //    
                //     let index1 = index + 34;
                //     A = A + index1;
                //     D = D + index1;
                //     sheet.getCell(A, D).border = borderStyles;
                //    }

                // 48

                sheet.mergeCells('A48:D48');
                //sheet.mergeCells('G48:H48');
                //sheet.mergeCells('I48:J48');
                //sheet.mergeCells('K48:L48');

                sheet.getCell('A48').value = 'Total no. of defectives';
                sheet.getCell('A48').font = {
                    name: fontName,
                    size: fontSize,
                    italic: true,
                };
                sheet.getCell('A48:D48').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: 'd1cfcb' },
                };

                sheet.getCell('A48:D48').border = borderStyles;

                let TotMin = 0;
                let TotMaj = 0;
                let TotCrit = 0;

                data.item3.map(x => {
                    TotMin = TotMin + x.minor;
                    TotMaj = TotMaj + x.major;
                    TotCrit = TotCrit + x.crirical;

                })

                sheet.getCell('E48').value = TotCrit;
                sheet.getCell('F48').value = TotMaj;
                sheet.getCell('G48').value = TotMin;

                sheet.getCell('E48').alignment = { horizontal: 'right' };
                sheet.getCell('E48').border = borderStyles;

                sheet.getCell('F48').alignment = { horizontal: 'right' };
                sheet.getCell('F48').border = borderStyles;
                sheet.getCell('G48').alignment = { horizontal: 'right' };
                sheet.getCell('G48').border = borderStyles;

                // End 48th Row

                // 49

                sheet.mergeCells('A49:D49');
                //sheet.mergeCells('G48:H48');
                //sheet.mergeCells('I48:J48');
                //sheet.mergeCells('K48:L48');

                sheet.getCell('A49').value = 'Fill in the acceptable number of faults : ZERO for critical; AQL 2.5 for major faults; AQL 4.0 for minor faults';
                //   sheet.getCell('A49').font = {
                //     name: fontName,
                //     size: fontSize,
                //     italic: true,
                //  };

                // End 49th Row

                // 50

                sheet.mergeCells('A50:G50');
                sheet.getRow(50).height = 5;

                //  End 50

                // 51

                sheet.getCell('A51:C51').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A51').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A51').value = 'Inspection Conclusion';
                sheet.getCell('A51').alignment = { horizontal: 'center' };

                sheet.getCell('D51:E51').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('D51').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('D51').value = 'Conformity (Delete the unnecessary)';
                sheet.getCell('D51').alignment = { horizontal: 'center' };

                sheet.getCell('F51:G51').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('F51').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };
                sheet.getCell('F51').value = 'Note';
                sheet.getCell('F51').alignment = { horizontal: 'center' };

                sheet.mergeCells('A51:C51');
                sheet.mergeCells('D51:E51');
                sheet.mergeCells('F51:G51');


                // End 51



                let Start2 = 'A';
                let End2 = 'C';
                let SecondStart2 = 'D';
                let SecondEnd2 = 'E';
                let ThirdStart2 = 'F';
                let ThirdEnd2 = 'G';

                for (let index = 0; index < data.item4.length; index++) {
                    Start2 += index + 52;
                    End2 += index + 52;
                    SecondStart2 += index + 52;
                    SecondEnd2 += index + 52;
                    ThirdStart2 += index + 52;
                    ThirdEnd2 += index + 52;

                    //let borderDesign;
                    // if (index == 0) // First row Only BorderStyle
                    // { borderDesign = borderStyles; }
                    // else if (index == (data.item3.length - 1)) // Last row Only BorderStyle
                    // { borderDesign = borderStyleslrb; }
                    // else { borderDesign = borderStyleslr; }//In between row BorderStyle


                    sheet.mergeCells(Start2, End2);
                    sheet.getCell(Start2, End2).border = borderStyles;
                    sheet.mergeCells(SecondStart2, SecondEnd2);
                    sheet.getCell(SecondStart2, SecondEnd2).border = borderStyles;
                    sheet.mergeCells(ThirdStart2, ThirdEnd2);
                    sheet.getCell(ThirdStart2, ThirdEnd2).border = borderStyles;

                    sheet.getCell(Start2).value = data.item4[index].value
                    // sheet.getCell(Start).border = borderStyles;
                    sheet.getCell(SecondStart2).value = data.item4[index].result;
                    sheet.getCell(SecondStart2).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };

                    // sheet.getCell(SecondStart).border = borderStyles;
                    sheet.getCell(ThirdStart2).value = data.item4[index].remark;
                    // sheet.getCell(ThirdStart).border = borderStyles;

                    // sheet.getCell(FourthStart).border = borderStyles;

                    sheet.getCell(SecondStart2).alignment = { horizontal: 'center' };
                    sheet.getCell(ThirdStart2).alignment = { horizontal: 'center' };

                    Start2 = 'A';
                    End2 = 'C';
                    SecondStart2 = 'D';
                    SecondEnd2 = 'E';
                    ThirdStart2 = 'F';
                    ThirdEnd2 = 'G';


                }

                // End 51th Row
                // 59
                sheet.mergeCells('A59:G59');
                sheet.getRow(59).height = 5;

                // End 59

                //  60th Row
                sheet.getRow(60).height = 40;
                sheet.mergeCells('A60:G60');
                sheet.getRow(60).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                sheet.getCell('A60:G60').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "d1cfcb" },
                };
                sheet.getCell('A60').value = 'This report must be sent to the Focus QC Team (QC@focus-brands.com) and the relevant Major account manager no later than 2 days prior ex-fty date.Focus reserves the right to issue a  fine of $100 for each ';
                sheet.getCell('A60').border = borderStyles;
                // END 60th Row






            }

            else if (sheetName === 'STANDARD PICS') {




                //                 // Set a right border for the entire column H
                //   const columnH = sheet.getColumn('P');
                //   columnH.eachCell((cell) => {
                //     cell.border = {
                //       right: { style: 'thin', color: { argb: 'FF000000' } }, // Right border
                //     };
                //   });
                //                 //sheet.getRow(1).height= 30;
                //sheet.getColumn('G').width = 17.86;
                sheet.getRow(1).height = 22.50;
                sheet.getRow(1).height = 24;

                sheet.mergeCells('A1:N1');
                sheet.getCell('A1').value = 'PRODUCT APPEARANCE AND PACKAGING: STANDARD PICTURES';
                sheet.getCell('A1').font = {
                    name: fontName,
                    color: { argb: fontHeaderColor },
                    size: fontSize,

                };
                sheet.getCell('A1:N1').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('A1').alignment = { horizontal: 'center' };

                data.item5.map(async (product) => {


                    sheet.mergeCells('A2:C2');
                    sheet.getCell('A2').value = 'ID:' + product.idnos;
                    sheet.getCell('A2').font = {
                        name: fontName,
                        color: { argb: fontColor },
                        size: fontSize,

                    };
                    sheet.mergeCells('D2:H2');
                    sheet.getCell('D2').value = 'Product Code:' + product.styleCode;
                    sheet.getCell('D2').font = {
                        name: fontName,
                        color: { argb: fontColor },
                        size: fontSize,

                    };
                    sheet.mergeCells('I2:N2');
                    sheet.getCell('I2').value = 'Name & Color:' + product.styleName + '&' + product.colour;
                    sheet.getCell('I2').font = {
                        name: fontName,
                        color: { argb: fontColor },
                        size: fontSize,

                    };

                })





                // SKU Image
                let rowNumber = 0;
                let rowNumberBack = 0;
                let FrontLabel = 0;
                let FirstCellsLabel;
                let mergeCells;
                let staticLabel;

                data.item6.map(async (product, index) => {

                    //const result = await toDataURL(URL + product?.url);

                    if (product?.position === 'Front') {

                        const splitted = URL + product?.url.split(".");
                        const extName = splitted[splitted.length - 1];
                        if (product?.base64String !== null) {
                            const imageId = workbook.addImage({
                                // base64: result.base64Url,
                                base64: product?.base64String,
                                extension: extName,
                            });

                            if (index === 0) { rowNumber = index + 5; }
                            else { rowNumber = rowNumber + 20; }

                            sheet.addImage(imageId, {
                                tl: { col: 2, row: rowNumber },
                                ext: { width: 350, height: 280 },
                                //editAs : "absolute",
                            });
                        }

                        FrontLabel = FrontLabel + 22;
                        FirstCellsLabel = 'B' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'H' + FrontLabel;

                    }
                    else {
                        if (index === 1) { rowNumberBack = index + 4; }
                        else { rowNumberBack = rowNumberBack + 20; }

                        const splitted1 = URL + product?.url.split(".");
                        const extName1 = splitted1[splitted1.length - 1];

                        if (product?.base64String !== null) {
                            const imageId1 = workbook.addImage({
                                base64: product?.base64String,
                                extension: extName1,
                            });
                            sheet.addImage(imageId1, {
                                tl: { col: 8.5, row: rowNumberBack },
                                ext: { width: 350, height: 280 },
                            });

                        }
                        FrontLabel = FrontLabel;
                        FirstCellsLabel = 'I' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'N' + FrontLabel;

                    }


                    MargeCellGrid(sheet, mergeCells, FirstCellsLabel, product?.value, fontName, fontColor, fontSize, headerColor, fontHeaderColor);
                    sheet.getCell(FirstCellsLabel).alignment = { horizontal: 'center' };
                    if (product?.type !== null && product?.type !== '') {
                        let addRow = FrontLabel + 2;
                        FirstCellsLabel = 'A' + addRow;
                        mergeCells = FirstCellsLabel + ':' + 'N' + addRow;
                        if (product?.type === "1") {
                            staticLabel = 'PRODUCT LABELLING';

                        }
                        else if (product?.type === "2") {
                            staticLabel = 'PRODUCT OUTER PACKAGING AND SHIPPING MARKS';

                        }
                        else if (product?.type === "3") {
                            staticLabel = '';
                        }

                        if (staticLabel !== '') {
                            sheet.getCell(FirstCellsLabel).value = staticLabel;

                            MargeCellGridHeader(sheet, mergeCells, FirstCellsLabel, staticLabel, fontName, fontHeaderColor, fontSize, headerColor);
                            sheet.getCell(FirstCellsLabel).alignment = { horizontal: 'center' };
                        }
                    }

                })


                for (let index = 0; index < FrontLabel; index++) {
                    const element = 'H' + index;
                    const element1 = 'B' + index;
                    const element2 = 'N' + index;
                    sheet.getCell(element).border = borderStylesr;
                    sheet.getCell(element1).border = borderStylesl;
                    sheet.getCell(element2).border = borderStylesr;

                }

                // sheet.getCell('H2').border = borderStylesr;
                // sheet.getCell('H3').border = borderStylesr;
                // sheet.getCell('H4').border = borderStylesr;
                // sheet.getCell('H5').border = borderStylesr;




            }
            else if (sheetName === 'DEFECTIVE PICS') {
                sheet.getRow(1).height = 30;
                sheet.mergeCells('A1:N1');
                sheet.getCell('A1').value = 'DEFECTS FOUND IN AQL INSPECTION (recorded as found at the moment of inspection)';


                sheet.getCell('A1').font = {
                    name: fontName,
                    color: { argb: fontHeaderColor },
                    size: fontSize,

                };
                sheet.getCell('A1:N1').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('A1').alignment = { horizontal: 'center' };

                // SKU Image
                let rowNumber = 0;
                let rowNumberBack = 0;
                let FrontLabel = 0;
                let FirstCellsLabel;
                let mergeCells;
                let staticLabel;
                data.item7.map(async (product, index) => {
                    //const result = await toDataURL(URL + product?.url);

                    if (product?.position === 'Front') {
                        const splitted = URL + product?.url.split(".");
                        const extName = splitted[splitted.length - 1];
                        if (product?.base64String !== null) {
                            const imageId = workbook.addImage({
                                base64: product?.base64String,
                                extension: extName,
                            });

                            if (index === 0) { rowNumber = index + 3; }
                            else { rowNumber = rowNumber + 20; }

                            sheet.addImage(imageId, {
                                tl: { col: 2, row: rowNumber },
                                ext: { width: 240, height: 280 },
                                //editAs : "absolute",
                            });
                        }


                        FrontLabel = FrontLabel + 20;
                        FirstCellsLabel = 'B' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'G' + FrontLabel;


                    }
                    else {
                        if (index === 1) { rowNumberBack = index + 2; }
                        else { rowNumberBack = rowNumberBack + 20; }

                        const splitted1 = URL + product?.url.split(".");
                        const extName1 = splitted1[splitted1.length - 1];
                        if (product?.base64String !== null) {
                            const imageId1 = workbook.addImage({
                                base64: product?.base64String,
                                extension: extName1,
                            });

                            sheet.addImage(imageId1, {
                                tl: { col: 8, row: rowNumberBack },
                                ext: { width: 240, height: 280 },
                                //editAs : "absolute",
                            });
                        }

                        FrontLabel = FrontLabel;
                        FirstCellsLabel = 'H' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'M' + FrontLabel;
                    }
                    index = index + 1;

                    MargeCellGrid(sheet, mergeCells, FirstCellsLabel, 'Defective ' + index + ' : ' + product?.value + ' ( ' + product?.defectType + ' )', fontName, fontColor, fontSize, headerColor, fontHeaderColor);
                    //  MargeCellGrid(sheet, mergeCells, FirstCellsLabel, index  +' ) '+  product?.value, + '( ' + product?.defectType + ' )',fontName,fontColor,fontSize,headerColor,fontHeaderColor);
                    sheet.getCell(FirstCellsLabel).alignment = { horizontal: 'center' };
                })

                for (let index = 0; index < FrontLabel; index++) {
                    const element = 'G' + index;
                    const element1 = 'B' + index;
                    const element2 = 'M' + index;
                    sheet.getCell(element).border = borderStylesr;
                    sheet.getCell(element1).border = borderStylesl;
                    sheet.getCell(element2).border = borderStylesr;

                }



            }
            else if (sheetName === 'MEASUREMENT LOG') {
                const columns = [
                    { header: 'Sku Name', key: 'SkuName' },
                    { header: 'Category Type Name', key: 'CategoryTypeName' },
                    { header: 'Measurement', key: 'MeasurementPoints' },
                    { header: 'Tolerance', key: 'Tolerance' }];

                sheet.addRow([]);
                skudetails.map(comment => {

                    measurementdata.map((item, subindex) => {

                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {

                            Catitem.SizeHeaderNames.filter(x => x.SkuName === comment.SkuName).map((size, i) => {

                                let sizename = size.HeaderName.split('-');
                                let newsize = '';
                                if (sizename.includes('Req')) {
                                    newsize = 'Size:' + sizename[0];
                                }
                                else {
                                    newsize = size.HeaderName;
                                }
                                if (columns.filter(c => c.header === newsize).length === 0) {
                                    columns.push(
                                        { header: newsize, key: size.HeaderName })
                                }

                            })

                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                let row = [];
                                row.push(Pointitem.SkuName)
                                row.push(Pointitem.CategoryTypeName)
                                row.push(Pointitem.MeasurementPoints)
                                row.push(Pointitem.TolerancePlus)


                                Catitem.SizeHeaderNames.filter(x => x.SkuName === comment.SkuName && x.CategoryRowNo === Catitem.CategoryRowNo).map((sizeval, i) => {
                                    row.push(Pointitem.arrGradingSizes[0][sizeval.HeaderName] !== '0' ? Pointitem.arrGradingSizes[0][sizeval.HeaderName] : '')
                                })
                                sheet.addRow(row);

                            })
                        })
                    })

                    // promise.then(() => {
                    //     workbook.xlsx.writeBuffer().then(function (dataJson) {
                    //         const blob = new Blob([dataJson], {
                    //             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    //         });
                    //         const url = window.URL.createObjectURL(blob);
                    //         const anchor = document.createElement("a");
                    //         anchor.href = url;
                    //         anchor.download = fileName;
                    //         anchor.click();
                    //         window.URL.revokeObjectURL(url);
                    //     });
                    // });

                    // return true;

                })

                sheet.columns = columns;



                sheet.spliceRows(1, 0, []);

                // sheet.addRow()
                sheet.getRow(1).height = 70;
                sheet.mergeCells('A1:B1');
                sheet.mergeCells('C1:Z1');

                let logo = window.$APIBaseURL + 'Images/FI_excelimageFocusformat.jpg';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'jpg',
                    });

                    sheet.addImage(imageId2, 'A1:B1');
                })

                // const splitted = product?.logo.split(".");
                //  const extName = splitted[splitted.length - 1];



                sheet.getCell('C1').value = 'PRE-SHIPMENT MEASUREMENT AUDIT SHEET';
                sheet.getCell('C1').font = {
                    name: 'Helvetica',
                    // color: { argb: 'ebeff5' },
                    //family: 2,
                    size: 12,
                    // italic: true
                    bold: true

                };
                sheet.getCell('C1').alignment = { horizontal: 'center' };

                sheet.getCell('A2').font = { bold: true };
                sheet.getCell('B2').font = { bold: true };
                sheet.getCell('C2').font = { bold: true };
                sheet.getCell('D2').font = { bold: true };


            }

            else if (sheetName === 'Style CAD') {



            }
            else if (sheetName === 'T&C') {
                sheet.mergeCells('A1:N1');
                sheet.getCell('A1').value = 'TERMS AND CONDITIONS';
                sheet.getCell('A1').font = {
                    name: fontName,
                    color: { argb: fontHeaderColor },
                    //family: 2,
                    size: fontSize,
                    // italic: true
                    bold: true

                };
                sheet.getCell('A1:N1').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('A1').alignment = { horizontal: 'center' };

                // sheet.getCell('A8').alignment = { horizontal: 'center' };
                //sheet.getCell('A8').value = "@EU legislation in effect requires that products imported from non-member states are supported by a technical pack, which is kept for 10 years following the product release on the market. Shipment inspection report is inseparable part of the Technical pack";


                let logo = window.$APIBaseURL + 'Images/T&C.jpg';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'jpg',
                    });

                    sheet.addImage(imageId2, 'A3:N38');
                })

                setTimeout(() => {
                    workbook.xlsx.writeBuffer().then(function (data) {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = fileName;
                        anchor.click();
                        window.URL.revokeObjectURL(url);
                    });
                    hideLoader();

                }, 7000);
            }

        });
    };



    const exportExcelFileSte = (data, fileName, ImageShow, measurementdata, skudetails) => {
        // showLoader();

        const workbook = new ExcelJS.Workbook();

        //const sheetNames = ["TEST GRID","INSPECTION REPORT", "STD PICS", "DEFECTIVE PICS", "MEASUREMENT LOG"];
        const sheetNames = ["SHIPMENT", "STANDARD PICS", "DEFECTIVE PICS", "MEASUREMENT LOG", "Style CAD"];

        sheetNames.forEach(sheetName => {
            const sheet = workbook.addWorksheet(sheetName);
            // I believe this needs to be set to show in LibreOffice.
            sheet.state = 'visible';

            const twoMinutes = 1 * 60 * 1000; // 2 minutes in milliseconds
            let URL = window.$APIBaseURL + "Images";

            let headerColor = '000042';
            let fontName = 'Helvetica';
            let fontSize = 11;
            let fontColor = '3333ff';
            let fontHeaderColor = 'ebeff5';

            if (sheetName === 'SHIPMENT') {

                sheet.getColumn('A').width = 23;
                sheet.getColumn('B').width = 9;
                sheet.getColumn('C').width = 22;
                sheet.getColumn('D').width = 20.71;
                sheet.getColumn('E').width = 18.29;
                sheet.getColumn('F').width = 20.29;
                sheet.getColumn('G').width = 17.86;
                sheet.getRow(1).height = 110;



                //sheet.getRow(1).height = 70;
                // sheet.mergeCells('A1:C1');
                // sheet.mergeCells('D1:L1');

                let logo1 = window.$APIBaseURL + 'Images/Steiff.png';
                let logo2 = window.$APIBaseURL + 'Images/LogoCommon.png';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo1);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'png',
                    });

                    // sheet.addImage(imageId2, 'A1:B1');
                    sheet.addImage(imageId2, {
                        tl: { col: 0, row: 0 },
                        ext: { width: 163, height: 73 },
                    });

                })

                array.map(async x => {
                    result = await toDataURL(logo2);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'png',
                    });

                    //   sheet.addImage(imageId2, 'C1:G1');


                    sheet.addImage(imageId2, {
                        tl: { col: 1, row: 0 },
                        ext: { width: 743, height: 134 },
                    });
                })


                // sheet.getCell('A1').border = borderStyles;
                // sheet.getCell('B1:G1').border = borderStyles;



                // // //End First Row

                //// Second Row

                sheet.getCell('A2:G2').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.mergeCells('A2:G2');
                sheet.getRow(2).height = 5;
                //End Second Row

                // Third Row
                sheet.getCell('A3').value = 'Delivery (ITS#):';
                sheet.getCell('A3').alignment = { horizontal: 'left' };
                sheet.mergeCells('B3:C3');
                sheet.getCell('C3').value = data.item1[0].customerShipmentRefNo;
                sheet.getCell('C3').alignment = { horizontal: 'left' };
                sheet.getCell('A3').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C3').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A3').border = borderStyles;
                sheet.getCell('B3:C3').border = borderStyles;

                sheet.getCell('D3').value = 'Customers:';
                sheet.getCell('D3').alignment = { horizontal: 'left' };

                sheet.mergeCells('E3:G3');
                sheet.getCell('E3').value = data.item1[0].customers;
                sheet.getCell('E3').alignment = { horizontal: 'left' };
                sheet.getCell('D3').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E3').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D3').border = borderStyles;
                sheet.getCell('E3:G3').border = borderStyles;
                // Third Row End

                // Four Row
                sheet.getCell('A4').value = 'Supplier/Agent:';
                sheet.getCell('A4').alignment = { horizontal: 'left' };
                sheet.mergeCells('B4:C4');
                sheet.getCell('C4').value = 'CUBE FASHIONS';
                sheet.getCell('C4').alignment = { horizontal: 'left' };
                sheet.getCell('A4').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C4').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A4').border = borderStyles;
                sheet.getCell('B4:C4').border = borderStyles;

                sheet.getCell('D4').value = 'ID numbers:';
                sheet.getCell('D4').alignment = { horizontal: 'left' };

                sheet.mergeCells('E4:G4');
                sheet.getCell('E4').value = data.item1[0].idnos;
                sheet.getCell('E4').alignment = { horizontal: 'left' };
                sheet.getCell('D4').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E4').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D4').border = borderStyles;
                sheet.getCell('E4:G4').border = borderStyles;
                // Four Row End

                // Five Row
                sheet.getCell('A5').value = 'Factory:';
                sheet.getCell('A5').alignment = { horizontal: 'left' };
                sheet.mergeCells('B5:C5');
                sheet.getCell('C5').value = data.item1[0].supplierName;
                sheet.getCell('C5').alignment = { horizontal: 'left' };
                sheet.getCell('A5').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C5').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A5').border = borderStyles;
                sheet.getCell('B5:C5').border = borderStyles;

                sheet.getCell('D5').value = 'Po numbers:';
                sheet.getCell('D5').alignment = { horizontal: 'left' };

                sheet.mergeCells('E5:G5');
                sheet.getCell('E5').value = data.item1[0].ponos;
                sheet.getCell('E5').alignment = { horizontal: 'left' };
                sheet.getCell('D5').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E5').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('D5').border = borderStyles;
                sheet.getCell('E5:G5').border = borderStyles;
                // Five End
                // Six
                sheet.mergeCells('A6:G6');
                sheet.getRow(6).height = 5;
                // Six End
                // Seven Row
                sheet.getCell('A7').value = 'QC Inspector:';
                sheet.getCell('A7').alignment = { horizontal: 'left' };
                sheet.mergeCells('B7:C7');
                sheet.getCell('C7').value = data.item1[0].qcInspector;
                sheet.getCell('C7').alignment = { horizontal: 'left' };
                sheet.getCell('A7').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C7').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A7').border = borderStyles;
                sheet.getCell('B7:C7').border = borderStyles;

                sheet.getCell('D7').value = 'Inspection Date:';
                sheet.getCell('D7').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E7').value = data.item1[0].inspectionDate;
                sheet.getCell('E7').alignment = { horizontal: 'left' };
                sheet.getCell('D7').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E7').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D7').border = borderStyles;
                sheet.getCell('E7').border = borderStyles;

                sheet.getCell('F7').value = 'Location COO:';
                sheet.getCell('F7').alignment = { horizontal: 'left' };

                sheet.getCell('G7').value = 'TIRUPUR/ INDIA';
                sheet.getCell('G7').alignment = { horizontal: 'left' };
                sheet.getCell('F7').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G7').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F7').border = borderStyles;
                sheet.getCell('G7').border = borderStyles;
                // Seven End
                // eight
                sheet.mergeCells('A8:G8');
                sheet.getRow(8).height = 5;
                // eight End

                //// nine Row

                sheet.getCell('A9:C9').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A9').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A9').value = 'Inspected style';
                sheet.getCell('A9').alignment = { horizontal: 'center' };

                sheet.getCell('D9:E9').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('D9').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('D9').value = 'Shipment quantities';
                sheet.getCell('D9').alignment = { horizontal: 'center' };

                sheet.getCell('F9:G9').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('F9').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };
                sheet.getCell('F9').value = 'Shipping marks/ cartons';
                sheet.getCell('F9').alignment = { horizontal: 'center' };

                sheet.mergeCells('A9:C9');
                sheet.mergeCells('D9:E9');
                sheet.mergeCells('F9:G9');
                //End nine Row

                // Ten

                sheet.getCell('A10').value = 'Style code:';
                sheet.getCell('A10').alignment = { horizontal: 'left' };
                sheet.mergeCells('B10:C10');
                sheet.getCell('C10').value = data.item1[0].styleCode;
                sheet.getCell('C10').alignment = { horizontal: 'left' };
                sheet.getCell('A10').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C10').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('D10').value = 'QTY on order:';
                sheet.getCell('D10').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E10').value = data.item1[0].qtyonOrder;
                sheet.getCell('E10').alignment = { horizontal: 'left' };
                sheet.getCell('D10').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E10').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('A10').border = borderStyles;
                sheet.getCell('B10:C10').border = borderStyles;
                sheet.getCell('D10').border = borderStyles;
                sheet.getCell('E10').border = borderStyles;

                sheet.getCell('F10').value = 'Carton markings:';
                sheet.getCell('F10').alignment = { horizontal: 'left' };

                sheet.getCell('G10').value = data.item1[0].cartonMarkings;
                sheet.getCell('G10').alignment = { horizontal: 'left' };
                sheet.getCell('F10').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G10').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F10').border = borderStyles;
                sheet.getCell('G10').border = borderStyles;



                // End Ten
                // 11

                sheet.getCell('A11').value = 'Style name:';
                sheet.getCell('A11').alignment = { horizontal: 'left' };
                sheet.mergeCells('B11:C11');
                sheet.getCell('C11').value = data.item1[0].styleName;
                sheet.getCell('C11').alignment = { horizontal: 'left' };
                sheet.getCell('A11').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C11').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('D11').value = 'QTY to be shipped:';
                sheet.getCell('D11').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E11').value = data.item1[0].qtytobeShipped;
                sheet.getCell('E11').alignment = { horizontal: 'left' };
                sheet.getCell('D11').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E11').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('A11').border = borderStyles;
                sheet.getCell('B11:C11').border = borderStyles;
                sheet.getCell('E13').border = borderStyles;
                sheet.getCell('D13').border = borderStyles;

                sheet.getCell('F11').value = 'Gross weight (kg):';
                sheet.getCell('F11').alignment = { horizontal: 'left' };

                sheet.getCell('G11').value = data.item1[0].grossWeight;
                sheet.getCell('G11').alignment = { horizontal: 'left' };
                sheet.getCell('F11').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G11').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F11').border = borderStyles;
                sheet.getCell('G11').border = borderStyles;

                // End 11

                // 12

                sheet.getCell('A12').value = 'Description:';
                sheet.getCell('A12').alignment = { horizontal: 'left' };
                sheet.mergeCells('B12:C12');
                sheet.getCell('C12').value = data.item1[0].categoryName;
                sheet.getCell('C12').alignment = { horizontal: 'left' };
                sheet.getCell('A12').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C12').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A12').border = borderStyles;
                sheet.getCell('B12:C12').border = borderStyles;

                sheet.getCell('D12').value = 'Difference in %';
                sheet.getCell('D12').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E12').value = data.item1[0].differenceinPercentage;
                sheet.getCell('E12').alignment = { horizontal: 'left' };
                sheet.getCell('D12').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E12').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };
                sheet.getCell('D12').border = borderStyles;
                sheet.getCell('E12').border = borderStyles;

                sheet.getCell('F12').value = 'Measurements (cm)';
                sheet.getCell('F12').alignment = { horizontal: 'left' };

                sheet.getCell('G12').value = data.item1[0].measurements;
                sheet.getCell('G12').alignment = { horizontal: 'left' };
                sheet.getCell('F12').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G12').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('F12').border = borderStyles;
                sheet.getCell('G12').border = borderStyles;

                // End 12

                // 13

                sheet.getCell('A13').value = 'Colour:';
                sheet.getCell('A13').alignment = { horizontal: 'left' };
                sheet.mergeCells('B13:C13');
                sheet.getCell('C13').value = data.item1[0].colour;
                sheet.getCell('C13').alignment = { horizontal: 'left' };
                sheet.getCell('A13').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C13').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A13').border = borderStyles;
                sheet.getCell('B13:C13').border = borderStyles;

                sheet.getCell('D13').value = 'Ex-factory date:';
                sheet.getCell('D13').alignment = { horizontal: 'left' };



                // sheet.mergeCells('E7:E7');
                sheet.getCell('E13').value = data.item1[0].exFacDate;
                sheet.getCell('E13').alignment = { horizontal: 'left' };
                sheet.getCell('D13').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E13').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('D13').border = borderStyles;
                sheet.getCell('E13').border = borderStyles;

                sheet.getCell('F13').value = 'Barcode stickers? ';
                sheet.getCell('F13').alignment = { horizontal: 'left' };

                sheet.getCell('G13').value = data.item1[0].barcodeStickers;
                sheet.getCell('G13').alignment = { horizontal: 'left' };
                sheet.getCell('F13').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G13').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F13').border = borderStyles;
                sheet.getCell('G13').border = borderStyles;

                // End 13

                // 14

                sheet.getCell('A14').value = 'Size range:';
                sheet.getCell('A14').alignment = { horizontal: 'left' };
                sheet.mergeCells('B14:C14');
                sheet.getCell('C14').value = data.item1[0].sizeName;
                sheet.getCell('C14').alignment = { horizontal: 'left' };
                sheet.getCell('A14').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('C14').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A14').border = borderStyles;
                sheet.getCell('B14:C14').border = borderStyles;

                sheet.getCell('D14').value = 'Ex-factory (ITS 2)';
                sheet.getCell('D14').alignment = { horizontal: 'left' };

                // sheet.mergeCells('E7:E7');
                sheet.getCell('E14').value = data.item1[0].exFactITS2;
                sheet.getCell('E14').alignment = { horizontal: 'left' };
                sheet.getCell('D14').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('E14').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },
                };

                sheet.getCell('D14').border = borderStyles;
                sheet.getCell('E14').border = borderStyles;

                sheet.getCell('F14').value = 'Mixed cartons marked?';
                sheet.getCell('F14').alignment = { horizontal: 'left' };

                sheet.getCell('G14').value = data.item1[0].mixedcartonsmarked;
                sheet.getCell('G14').alignment = { horizontal: 'left' };
                sheet.getCell('F14').font = {
                    name: fontName,
                    size: fontSize,
                };
                sheet.getCell('G14').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('F14').border = borderStyles;
                sheet.getCell('G14').border = borderStyles;

                // End 14
                // 15

                sheet.mergeCells('A15:G15');
                sheet.getRow(15).height = 5;

                // End 15

                //16

                sheet.getCell('A16:E16').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A16').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A16').value = 'INSPECTOR S CHECK LIST * Tick the correct box';
                sheet.getCell('A16').alignment = { horizontal: 'center' };

                sheet.getCell('F16:F16').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('F16').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('F16').value = 'Yes';
                sheet.getCell('F16').alignment = { horizontal: 'center' };

                sheet.getCell('G16:G16').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('G16').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };
                sheet.getCell('G16').value = 'No';
                sheet.getCell('G16').alignment = { horizontal: 'center' };

                sheet.mergeCells('A16:E16');
                //sheet.mergeCells('F9:E9');
                //sheet.mergeCells('F9:G9');

                // End 16




                // sheet.mergeCells('A13:H13');
                // sheet.getCell('A13').value = 'INSPECTORS CHECK LIST * Tick the correct box';
                // sheet.getCell('A13:H13').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "3268ba" },
                // };
                // sheet.getCell('A13').font = {
                //     name: 'Helvetica',
                //     color: { argb: 'ebeff5' },
                //     //family: 2,
                //     size: 12,
                //     // italic: true
                // };

                // sheet.mergeCells('I13:J13');
                // sheet.getCell('I13').value = 'YES';
                // sheet.getCell('I13:J13').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "3268ba" },
                // };
                // sheet.getCell('I13').font = {
                //     name: 'Helvetica',
                //     color: { argb: 'ebeff5' },
                //     //family: 2,
                //     size: 12,
                //     // italic: true
                // };

                // sheet.mergeCells('K13:L13');
                // sheet.getCell('K13').value = 'NO';
                // sheet.getCell('K13:L13').fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: { argb: "3268ba" },
                // };
                // sheet.getCell('K13').font = {
                //     name: 'Helvetica',
                //     color: { argb: 'ebeff5' },
                //     //family: 2,
                //     size: 12,
                //     // italic: true
                // };


                // // End 13th Row
                // // 14 Row

                // 15

                let LabelForeColor = '85420c';
                let ValueForeColor = '12191a';




                let Start1 = 'A';
                let End1 = 'E';
                let SecondStart1 = 'F';
                //let SecondEnd1 = 'F';
                let ThirdStart1 = 'G';
                //let ThirdEnd1 = 'G';

                for (let index = 0; index < data.item2.length; index++) {
                    Start1 += index + 17;
                    End1 += index + 17;
                    SecondStart1 += index + 17;
                    // SecondEnd1 += index + 17;
                    ThirdStart1 += index + 17;
                    // ThirdEnd1 += index + 17;

                    let borderDesign;
                    // if (index == 0) // First row Only BorderStyle
                    // { borderDesign = borderStyles; }
                    // else if (index == (data.item3.length - 1)) // Last row Only BorderStyle
                    // { borderDesign = borderStyleslrb; }
                    // else { borderDesign = borderStyleslr; }//In between row BorderStyle

                    // UnWanted Code Client needs to be a empty row
                    if (Start1 === 'A24') { sheet.mergeCells(Start1, ThirdStart1); sheet.getRow(24).height = 5; }
                    else {
                        sheet.mergeCells(Start1, End1);
                        sheet.getCell(Start1, End1).border = borderStyles;

                        //sheet.mergeCells(SecondStart1, SecondEnd1);
                        //sheet.mergeCells(ThirdStart1, ThirdEnd1);

                        sheet.getCell(Start1).value = data.item2[index].value
                        // sheet.getCell(Start).border = borderStyles;
                        sheet.getCell(SecondStart1).value = data.item2[index].result === 'Yes' ? '✔' : data.item2[index].result === 'N/A' ? 'n/a' : '';
                        // sheet.getCell(SecondStart).border = borderStyles;
                        sheet.getCell(ThirdStart1).value = data.item2[index].result === 'No' ? '✖' : '';
                        // sheet.getCell(ThirdStart).border = borderStyles;

                        // sheet.getCell(FourthStart).border = borderStyles;

                        sheet.getCell(SecondStart1).alignment = { horizontal: 'center' };
                        sheet.getCell(SecondStart1).border = borderStyles;
                        sheet.getCell(ThirdStart1).alignment = { horizontal: 'center' };
                        sheet.getCell(ThirdStart1).border = borderStyles;
                    }



                    Start1 = 'A';
                    End1 = 'E';
                    SecondStart1 = 'F';
                    //SecondEnd1 = 'F';
                    ThirdStart1 = 'G';
                    //ThirdEnd1 = 'G';

                }

                // End 15th Row
                // 28

                sheet.mergeCells('A28:G28');
                sheet.getRow(28).height = 5;

                // End 28
                // 29

                sheet.getCell('A29:G29').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A29').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A29').value = 'AQL SAMPLES';
                sheet.mergeCells('A29:G29');
                sheet.getCell('A29').alignment = { horizontal: 'center' };


                // End 29

                // 30

                sheet.getCell('A30').value = 'Inspection sample size (as per AQL 2.5:';
                sheet.getCell('A30').alignment = { horizontal: 'left' };
                sheet.mergeCells('A30:C30');
                sheet.getCell('D30').value = data.item1[0].samplingSize;
                sheet.getCell('D30').alignment = { horizontal: 'center' };
                sheet.mergeCells('D30:G30');
                sheet.getCell('A30').font = {
                    name: fontName,
                    size: fontSize,
                    bold: true,
                };
                sheet.getCell('D30').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };
                sheet.getCell('A30:C30').border = borderStyles;
                sheet.getCell('D30:G30').border = borderStyles;

                // End 30

                // 31

                sheet.getCell('A31').value = 'Measurement inspection. Measured pieces:';
                sheet.getCell('A31').alignment = { horizontal: 'left' };
                sheet.mergeCells('A31:C31');
                sheet.getCell('D31').value = data.item1[0].measurementCheckedPeices;
                sheet.getCell('D31').alignment = { horizontal: 'center' };
                sheet.mergeCells('D31:G31');
                sheet.getCell('A31').font = {
                    name: fontName,
                    size: fontSize,
                    bold: true,
                };
                sheet.getCell('D31').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A31:C31').border = borderStyles;
                sheet.getCell('D31:G31').border = borderStyles;

                // End 31

                // 32

                sheet.getCell('A32').value = 'Inspected carton numbers:';
                sheet.getCell('A32').alignment = { horizontal: 'left' };
                sheet.mergeCells('A32:C32');
                sheet.getCell('D32').value = data.item1[0].inspectedCartonBox;
                sheet.getCell('D32').alignment = { horizontal: 'center' };
                sheet.mergeCells('D32:G32');
                sheet.getCell('A32').font = {
                    name: fontName,
                    size: fontSize,
                    bold: true,
                };
                sheet.getCell('D32').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontColor },

                };

                sheet.getCell('A32:C32').border = borderStyles;
                sheet.getCell('D32:G32').border = borderStyles;

                // End 30
                // 33
                sheet.mergeCells('A33:G33');
                sheet.getRow(33).height = 5;

                // End 33
                // 34
                sheet.getCell('A34').value = 'Defects found in inspection (free text)';
                sheet.mergeCells('A34:D34');
                sheet.getCell('A34:D34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('E34').value = 'Critical';

                sheet.getCell('E34').alignment = { horizontal: 'center' };

                sheet.getCell('E34:E34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('E34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('F34').value = 'Major';

                sheet.getCell('F34').alignment = { horizontal: 'center' };

                sheet.getCell('F34:F34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('F34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('G34').value = 'Minor';

                sheet.getCell('G34').alignment = { horizontal: 'center' };

                sheet.getCell('G34:G34').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('G34').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };





                let Start = 'A';
                let End = 'D';
                let SecondStart = 'E';
                let SecondEnd = 'E';
                let ThirdStart = 'F';
                let ThirdEnd = 'F';
                let FourthStart = 'G';
                let FourthEnd = 'G';
                for (let index = 0; index < data.item3.length; index++) {
                    Start += index + 35;
                    End += index + 35;
                    SecondStart += index + 35;
                    // SecondEnd += index + 35;
                    ThirdStart += index + 35;
                    //ThirdEnd += index + 35;
                    FourthStart += index + 35;
                    // FourthEnd += index + 35;
                    // let borderDesign;
                    // if (index == 0) // First row Only BorderStyle
                    // { borderDesign = borderStyles; }
                    // else if (index == (data.item3.length - 1)) // Last row Only BorderStyle
                    // { borderDesign = borderStyleslrb; }
                    // else { borderDesign = borderStyleslr; }//In between row BorderStyle



                    sheet.mergeCells(Start, End);
                    sheet.getCell(Start, End).border = borderStyles;

                    //sheet.mergeCells(SecondStart, SecondEnd);
                    //sheet.mergeCells(ThirdStart, ThirdEnd);
                    //sheet.mergeCells(FourthStart, FourthEnd);
                    sheet.getCell(Start).value = data.item3[index].defectName
                    sheet.getCell(Start).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };
                    sheet.getCell(SecondStart).value = data.item3[index].crirical
                    sheet.getCell(SecondStart).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };

                    sheet.getCell(SecondStart).border = borderStyles;
                    sheet.getCell(ThirdStart).value = data.item3[index].major
                    sheet.getCell(ThirdStart).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };
                    sheet.getCell(ThirdStart).border = borderStyles;
                    sheet.getCell(FourthStart).value = data.item3[index].minor
                    sheet.getCell(FourthStart).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };
                    sheet.getCell(FourthStart).border = borderStyles;
                    sheet.getCell(SecondStart).alignment = { horizontal: 'right' };
                    sheet.getCell(ThirdStart).alignment = { horizontal: 'right' };
                    sheet.getCell(FourthStart).alignment = { horizontal: 'right' };


                    Start = 'A';
                    End = 'D';
                    SecondStart = 'E';
                    SecondEnd = 'E';
                    ThirdStart = 'F';
                    ThirdEnd = 'F';
                    FourthStart = 'G';
                    FourthEnd = 'G';
                }

                //     let A = 'A';
                //     let D = 'D';
                //    for (let index = 1; index < 13; index++) {
                //    
                //     let index1 = index + 34;
                //     A = A + index1;
                //     D = D + index1;
                //     sheet.getCell(A, D).border = borderStyles;
                //    }

                // 48

                sheet.mergeCells('A48:D48');
                //sheet.mergeCells('G48:H48');
                //sheet.mergeCells('I48:J48');
                //sheet.mergeCells('K48:L48');

                sheet.getCell('A48').value = 'Total no. of defectives';
                sheet.getCell('A48').font = {
                    name: fontName,
                    size: fontSize,
                    italic: true,
                };
                sheet.getCell('A48:D48').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: 'd1cfcb' },
                };

                sheet.getCell('A48:D48').border = borderStyles;

                let TotMin = 0;
                let TotMaj = 0;
                let TotCrit = 0;

                data.item3.map(x => {
                    TotMin = TotMin + x.minor;
                    TotMaj = TotMaj + x.major;
                    TotCrit = TotCrit + x.crirical;

                })

                sheet.getCell('E48').value = TotCrit;
                sheet.getCell('F48').value = TotMaj;
                sheet.getCell('G48').value = TotMin;

                sheet.getCell('E48').alignment = { horizontal: 'right' };
                sheet.getCell('E48').border = borderStyles;

                sheet.getCell('F48').alignment = { horizontal: 'right' };
                sheet.getCell('F48').border = borderStyles;
                sheet.getCell('G48').alignment = { horizontal: 'right' };
                sheet.getCell('G48').border = borderStyles;

                // End 48th Row

                // 49

                sheet.mergeCells('A49:D49');
                //sheet.mergeCells('G48:H48');
                //sheet.mergeCells('I48:J48');
                //sheet.mergeCells('K48:L48');

                sheet.getCell('A49').value = 'Fill in the acceptable number of faults : ZERO for critical; AQL 2.5 for major faults; AQL 4.0 for minor faults';
                //   sheet.getCell('A49').font = {
                //     name: fontName,
                //     size: fontSize,
                //     italic: true,
                //  };

                // End 49th Row

                // 50

                sheet.mergeCells('A50:G50');
                sheet.getRow(50).height = 5;

                //  End 50

                // 51

                sheet.getCell('A51:C51').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };

                sheet.getCell('A51').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };


                sheet.getCell('A51').value = 'Inspection Conclusion';
                sheet.getCell('A51').alignment = { horizontal: 'center' };

                sheet.getCell('D51:E51').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('D51').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };

                sheet.getCell('D51').value = 'Conformity (Delete the unnecessary)';
                sheet.getCell('D51').alignment = { horizontal: 'center' };

                sheet.getCell('F51:G51').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('F51').font = {
                    name: fontName,
                    size: fontSize,
                    color: { argb: fontHeaderColor },

                };
                sheet.getCell('F51').value = 'Note';
                sheet.getCell('F51').alignment = { horizontal: 'center' };

                sheet.mergeCells('A51:C51');
                sheet.mergeCells('D51:E51');
                sheet.mergeCells('F51:G51');


                // End 51



                let Start2 = 'A';
                let End2 = 'C';
                let SecondStart2 = 'D';
                let SecondEnd2 = 'E';
                let ThirdStart2 = 'F';
                let ThirdEnd2 = 'G';

                for (let index = 0; index < data.item4.length; index++) {
                    Start2 += index + 52;
                    End2 += index + 52;
                    SecondStart2 += index + 52;
                    SecondEnd2 += index + 52;
                    ThirdStart2 += index + 52;
                    ThirdEnd2 += index + 52;

                    //let borderDesign;
                    // if (index == 0) // First row Only BorderStyle
                    // { borderDesign = borderStyles; }
                    // else if (index == (data.item3.length - 1)) // Last row Only BorderStyle
                    // { borderDesign = borderStyleslrb; }
                    // else { borderDesign = borderStyleslr; }//In between row BorderStyle


                    sheet.mergeCells(Start2, End2);
                    sheet.getCell(Start2, End2).border = borderStyles;
                    sheet.mergeCells(SecondStart2, SecondEnd2);
                    sheet.getCell(SecondStart2, SecondEnd2).border = borderStyles;
                    sheet.mergeCells(ThirdStart2, ThirdEnd2);
                    sheet.getCell(ThirdStart2, ThirdEnd2).border = borderStyles;

                    sheet.getCell(Start2).value = data.item4[index].value
                    // sheet.getCell(Start).border = borderStyles;
                    sheet.getCell(SecondStart2).value = data.item4[index].result;
                    sheet.getCell(SecondStart2).font = {
                        name: fontName,
                        size: fontSize,
                        color: { argb: fontColor },

                    };

                    // sheet.getCell(SecondStart).border = borderStyles;
                    sheet.getCell(ThirdStart2).value = data.item4[index].remark;
                    // sheet.getCell(ThirdStart).border = borderStyles;

                    // sheet.getCell(FourthStart).border = borderStyles;

                    sheet.getCell(SecondStart2).alignment = { horizontal: 'center' };
                    sheet.getCell(ThirdStart2).alignment = { horizontal: 'center' };

                    Start2 = 'A';
                    End2 = 'C';
                    SecondStart2 = 'D';
                    SecondEnd2 = 'E';
                    ThirdStart2 = 'F';
                    ThirdEnd2 = 'G';


                }

                // End 51th Row
                // 59
                sheet.mergeCells('A59:G59');
                sheet.getRow(59).height = 5;

                // End 59

                //  60th Row
                sheet.getRow(60).height = 40;
                sheet.mergeCells('A60:G60');
                sheet.getRow(60).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                sheet.getCell('A60:G60').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "d1cfcb" },
                };
                //sheet.getCell('A60').value = 'This report must be sent to the Focus QC Team (QC@focus-brands.com) and the relevant Major account manager no later than 2 days prior ex-fty date.Focus reserves the right to issue a  fine of $100 for each ';
                sheet.getCell('A60').border = borderStyles;
                // END 60th Row






            }

            else if (sheetName === 'STANDARD PICS') {




                //                 // Set a right border for the entire column H
                //   const columnH = sheet.getColumn('P');
                //   columnH.eachCell((cell) => {
                //     cell.border = {
                //       right: { style: 'thin', color: { argb: 'FF000000' } }, // Right border
                //     };
                //   });
                //                 //sheet.getRow(1).height= 30;
                //sheet.getColumn('G').width = 17.86;
                sheet.getRow(1).height = 22.50;
                sheet.getRow(1).height = 24;

                sheet.mergeCells('A1:N1');
                sheet.getCell('A1').value = 'PRODUCT APPEARANCE AND PACKAGING: STANDARD PICTURES';
                sheet.getCell('A1').font = {
                    name: fontName,
                    color: { argb: fontHeaderColor },
                    size: fontSize,

                };
                sheet.getCell('A1:N1').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('A1').alignment = { horizontal: 'center' };

                data.item5.map(async (product) => {


                    sheet.mergeCells('A2:C2');
                    sheet.getCell('A2').value = 'ID:' + product.idnos;
                    sheet.getCell('A2').font = {
                        name: fontName,
                        color: { argb: fontColor },
                        size: fontSize,

                    };
                    sheet.mergeCells('D2:H2');
                    sheet.getCell('D2').value = 'Product Code:' + product.styleCode;
                    sheet.getCell('D2').font = {
                        name: fontName,
                        color: { argb: fontColor },
                        size: fontSize,

                    };
                    sheet.mergeCells('I2:N2');
                    sheet.getCell('I2').value = 'Name & Color:' + product.styleName + '&' + product.colour;
                    sheet.getCell('I2').font = {
                        name: fontName,
                        color: { argb: fontColor },
                        size: fontSize,

                    };

                })





                // SKU Image
                let rowNumber = 0;
                let rowNumberBack = 0;
                let FrontLabel = 0;
                let FirstCellsLabel;
                let mergeCells;
                let staticLabel;

                data.item6.map(async (product, index) => {

                    //const result = await toDataURL(URL + product?.url);

                    if (product?.position === 'Front') {

                        const splitted = URL + product?.url.split(".");
                        const extName = splitted[splitted.length - 1];
                        if (product?.base64String !== null) {
                            const imageId = workbook.addImage({
                                // base64: result.base64Url,
                                base64: product?.base64String,
                                extension: extName,
                            });

                            if (index === 0) { rowNumber = index + 5; }
                            else { rowNumber = rowNumber + 20; }

                            sheet.addImage(imageId, {
                                tl: { col: 2, row: rowNumber },
                                ext: { width: 350, height: 280 },
                                //editAs : "absolute",
                            });
                        }

                        FrontLabel = FrontLabel + 22;
                        FirstCellsLabel = 'B' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'H' + FrontLabel;

                    }
                    else {
                        if (index === 1) { rowNumberBack = index + 4; }
                        else { rowNumberBack = rowNumberBack + 20; }

                        const splitted1 = URL + product?.url.split(".");
                        const extName1 = splitted1[splitted1.length - 1];

                        if (product?.base64String !== null) {
                            const imageId1 = workbook.addImage({
                                base64: product?.base64String,
                                extension: extName1,
                            });
                            sheet.addImage(imageId1, {
                                tl: { col: 8.5, row: rowNumberBack },
                                ext: { width: 350, height: 280 },
                            });

                        }
                        FrontLabel = FrontLabel;
                        FirstCellsLabel = 'I' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'N' + FrontLabel;

                    }


                    MargeCellGrid(sheet, mergeCells, FirstCellsLabel, product?.value, fontName, fontColor, fontSize, headerColor, fontHeaderColor);
                    sheet.getCell(FirstCellsLabel).alignment = { horizontal: 'center' };
                    if (product?.type !== null && product?.type !== '') {
                        let addRow = FrontLabel + 2;
                        FirstCellsLabel = 'A' + addRow;
                        mergeCells = FirstCellsLabel + ':' + 'N' + addRow;
                        if (product?.type === "1") {
                            staticLabel = 'PRODUCT LABELLING';

                        }
                        else if (product?.type === "2") {
                            staticLabel = 'PRODUCT OUTER PACKAGING AND SHIPPING MARKS';

                        }
                        else if (product?.type === "3") {
                            staticLabel = '';
                        }

                        if (staticLabel !== '') {
                            sheet.getCell(FirstCellsLabel).value = staticLabel;

                            MargeCellGridHeader(sheet, mergeCells, FirstCellsLabel, staticLabel, fontName, fontHeaderColor, fontSize, headerColor);
                            sheet.getCell(FirstCellsLabel).alignment = { horizontal: 'center' };
                        }
                    }

                })


                for (let index = 0; index < FrontLabel; index++) {
                    const element = 'H' + index;
                    const element1 = 'B' + index;
                    const element2 = 'N' + index;
                    sheet.getCell(element).border = borderStylesr;
                    sheet.getCell(element1).border = borderStylesl;
                    sheet.getCell(element2).border = borderStylesr;

                }

                // sheet.getCell('H2').border = borderStylesr;
                // sheet.getCell('H3').border = borderStylesr;
                // sheet.getCell('H4').border = borderStylesr;
                // sheet.getCell('H5').border = borderStylesr;




            }
            else if (sheetName === 'DEFECTIVE PICS') {
                sheet.getRow(1).height = 30;
                sheet.mergeCells('A1:N1');
                sheet.getCell('A1').value = 'DEFECTS FOUND IN AQL INSPECTION (recorded as found at the moment of inspection)';


                sheet.getCell('A1').font = {
                    name: fontName,
                    color: { argb: fontHeaderColor },
                    size: fontSize,

                };
                sheet.getCell('A1:N1').fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: headerColor },
                };
                sheet.getCell('A1').alignment = { horizontal: 'center' };

                // SKU Image
                let rowNumber = 0;
                let rowNumberBack = 0;
                let FrontLabel = 0;
                let FirstCellsLabel;
                let mergeCells;
                let staticLabel;
                data.item7.map(async (product, index) => {
                    //const result = await toDataURL(URL + product?.url);

                    if (product?.position === 'Front') {
                        const splitted = URL + product?.url.split(".");
                        const extName = splitted[splitted.length - 1];
                        if (product?.base64String !== null) {
                            const imageId = workbook.addImage({
                                base64: product?.base64String,
                                extension: extName,
                            });

                            if (index === 0) { rowNumber = index + 3; }
                            else { rowNumber = rowNumber + 20; }

                            sheet.addImage(imageId, {
                                tl: { col: 2, row: rowNumber },
                                ext: { width: 240, height: 280 },
                                //editAs : "absolute",
                            });
                        }


                        FrontLabel = FrontLabel + 20;
                        FirstCellsLabel = 'B' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'G' + FrontLabel;


                    }
                    else {
                        if (index === 1) { rowNumberBack = index + 2; }
                        else { rowNumberBack = rowNumberBack + 20; }

                        const splitted1 = URL + product?.url.split(".");
                        const extName1 = splitted1[splitted1.length - 1];
                        if (product?.base64String !== null) {
                            const imageId1 = workbook.addImage({
                                base64: product?.base64String,
                                extension: extName1,
                            });

                            sheet.addImage(imageId1, {
                                tl: { col: 8, row: rowNumberBack },
                                ext: { width: 240, height: 280 },
                                //editAs : "absolute",
                            });
                        }

                        FrontLabel = FrontLabel;
                        FirstCellsLabel = 'H' + FrontLabel;
                        mergeCells = FirstCellsLabel + ':' + 'M' + FrontLabel;
                    }
                    index = index + 1;

                    MargeCellGrid(sheet, mergeCells, FirstCellsLabel, 'Defective ' + index + ' : ' + product?.value + ' ( ' + product?.defectType + ' )', fontName, fontColor, fontSize, headerColor, fontHeaderColor);
                    //  MargeCellGrid(sheet, mergeCells, FirstCellsLabel, index  +' ) '+  product?.value, + '( ' + product?.defectType + ' )',fontName,fontColor,fontSize,headerColor,fontHeaderColor);
                    sheet.getCell(FirstCellsLabel).alignment = { horizontal: 'center' };
                })

                for (let index = 0; index < FrontLabel; index++) {
                    const element = 'G' + index;
                    const element1 = 'B' + index;
                    const element2 = 'M' + index;
                    sheet.getCell(element).border = borderStylesr;
                    sheet.getCell(element1).border = borderStylesl;
                    sheet.getCell(element2).border = borderStylesr;

                }



            }
            else if (sheetName === 'MEASUREMENT LOG') {
                const columns = [
                    { header: 'Sku Name', key: 'SkuName' },
                    { header: 'Category Type Name', key: 'CategoryTypeName' },
                    { header: 'Measurement', key: 'MeasurementPoints' },
                    { header: 'Tolerance', key: 'Tolerance' }];

                sheet.addRow([]);
                skudetails.map(comment => {

                    measurementdata.map((item, subindex) => {

                        item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {

                            Catitem.SizeHeaderNames.filter(x => x.SkuName === comment.SkuName).map((size, i) => {

                                let sizename = size.HeaderName.split('-');
                                let newsize = '';
                                if (sizename.includes('Req')) {
                                    newsize = 'Size:' + sizename[0];
                                }
                                else {
                                    newsize = size.HeaderName;
                                }
                                if (columns.filter(c => c.header === newsize).length === 0) {
                                    columns.push(
                                        { header: newsize, key: size.HeaderName })
                                }

                            })

                            Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                let row = [];
                                row.push(Pointitem.SkuName)
                                row.push(Pointitem.CategoryTypeName)
                                row.push(Pointitem.MeasurementPoints)
                                row.push(Pointitem.TolerancePlus)


                                Catitem.SizeHeaderNames.filter(x => x.SkuName === comment.SkuName && x.CategoryRowNo === Catitem.CategoryRowNo).map((sizeval, i) => {
                                    row.push(Pointitem.arrGradingSizes[0][sizeval.HeaderName] !== '0' ? Pointitem.arrGradingSizes[0][sizeval.HeaderName] : '')
                                })
                                sheet.addRow(row);

                            })
                        })
                    })

                    // promise.then(() => {
                    //     workbook.xlsx.writeBuffer().then(function (dataJson) {
                    //         const blob = new Blob([dataJson], {
                    //             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    //         });
                    //         const url = window.URL.createObjectURL(blob);
                    //         const anchor = document.createElement("a");
                    //         anchor.href = url;
                    //         anchor.download = fileName;
                    //         anchor.click();
                    //         window.URL.revokeObjectURL(url);
                    //     });
                    // });

                    // return true;

                })

                sheet.columns = columns;



                sheet.spliceRows(1, 0, []);

                // sheet.addRow()
                sheet.getRow(1).height = 70;
                sheet.mergeCells('A1:B1');
                sheet.mergeCells('C1:Z1');

                let logo = window.$APIBaseURL + 'Images/FI_excelimageFocusformat.jpg';

                // const result = toDataURL(logo);
                let result;
                let array = [{ novalue: '' }]
                array.map(async x => {
                    result = await toDataURL(logo);

                    const imageId2 = workbook.addImage({
                        base64: result.base64Url,
                        extension: 'jpg',
                    });

                    sheet.addImage(imageId2, 'A1:B1');
                })

                // const splitted = product?.logo.split(".");
                //  const extName = splitted[splitted.length - 1];



                sheet.getCell('C1').value = 'PRE-SHIPMENT MEASUREMENT AUDIT SHEET';
                sheet.getCell('C1').font = {
                    name: 'Helvetica',
                    // color: { argb: 'ebeff5' },
                    //family: 2,
                    size: 12,
                    // italic: true
                    bold: true

                };
                sheet.getCell('C1').alignment = { horizontal: 'center' };

                sheet.getCell('A2').font = { bold: true };
                sheet.getCell('B2').font = { bold: true };
                sheet.getCell('C2').font = { bold: true };
                sheet.getCell('D2').font = { bold: true };


            }
            else if (sheetName === 'Style CAD') {
                sheet.mergeCells('A1:N1');
                sheet.getCell('A1').value = 'STYLING WORKSHEET';
                sheet.getCell('A1').font = {
                    name: fontName,
                    color: { argb: fontColor },
                    size: fontSize,
                    bold: true

                };

                sheet.getCell('A1').alignment = { horizontal: 'center' };



                setTimeout(() => {
                    workbook.xlsx.writeBuffer().then(function (data) {
                        const blob = new Blob([data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement("a");
                        anchor.href = url;
                        anchor.download = fileName;
                        anchor.click();
                        window.URL.revokeObjectURL(url);
                    });
                    hideLoader();

                }, 7000);
            }

        });

    };

    //End Export

    useEffect(() => {


        //////////// multi Filter

        const buyer = localStorage.getItem("Buyer1")
        const season = localStorage.getItem("Season1")
        const brand = localStorage.getItem("Brand1")
        const quarter = localStorage.getItem("Quarter1")
        const supplier = localStorage.getItem("Supplier1")
        const poidno = localStorage.getItem("ID/NO1")
        const endCustomer = localStorage.getItem("EndCustomer1")
        const category = localStorage.getItem("Category1")
        const stylename = localStorage.getItem("StyleName1")
        const styleno = localStorage.getItem("StyleNo1")
        const skuname = localStorage.getItem("SKUName1")
        const factory = localStorage.getItem("Factory1")
        const ProductionStatus = localStorage.getItem("IsProductionStatus1")
        const orderStatus = localStorage.getItem("OrderStatus1")

        if (skuname !== null && skuname !== "") {
            if (skuname) {
                setSkuSearch(skuname);
            }
            else {
                localStorage.removeItem('SKUName1');
                setSkuSearch("");
            }
        }
        if (buyer !== null && buyer !== "") {
            if (buyer) {
                setBuyerSearch(buyer)
            }
            else {
                localStorage.removeItem('Buyer1');
                setBuyerSearch("");
            }
        }
        if (season !== null && season !== "") {

            if (season) {
                setSeasonSearch(season)
            }
            else {
                localStorage.removeItem('Season1');
                setSeasonSearch("");
            }
        }
        if (brand !== null && brand !== "") {

            if (brand) {
                setBrandSearch(brand)
            }
            else {
                localStorage.removeItem('Brand1');
                setBrandSearch("");
            }
        }
        if (quarter !== null && quarter !== "") {

            if (quarter) {
                setQuarterSearch(quarter)
            }
            else {
                localStorage.removeItem('Quarter1');
                setQuarterSearch("");
            }
        }
        if (supplier !== null && supplier !== "") {

            if (supplier) {
                setsupplierSearch(supplier)
            }
            else {
                localStorage.removeItem('Supplier1');
                setsupplierSearch("");
            }
        }
        if (poidno !== null && poidno !== "") {

            if (poidno) {
                setIdPoSearch(poidno)
            }
            else {
                localStorage.removeItem('ID/NO1');
                setIdPoSearch("");
            }
        }
        if (endCustomer !== null && endCustomer !== "") {

            if (endCustomer) {
                setEndCustomerSearch(endCustomer)
            }
            else {
                localStorage.removeItem('EndCustomer1');
                setEndCustomerSearch("");
            }
        }
        if (category !== null && category !== "") {

            if (category) {
                setCategorySearch(category)
            }
            else {
                localStorage.removeItem('Category1');
                setCategorySearch("");
            }
        }
        if (stylename !== null && stylename !== "") {

            if (stylename) {
                setStyleNameSearch(stylename)
            }
            else {
                localStorage.removeItem('StyleName1');
                setStyleNameSearch("");
            }
        }
        if (styleno !== null && styleno !== "") {

            if (styleno) {
                setStyleNoSearch(styleno)
            }
            else {
                localStorage.removeItem('StyleNo1');
                setStyleNoSearch("");
            }
        }
        if (factory !== null && factory !== "") {

            if (factory) {
                setFactorySearch(factory)
            }
            else {
                localStorage.removeItem('Factory1');
                setFactorySearch("");
            }
        }
        if (orderStatus !== null && orderStatus !== "") {

            if (orderStatus) {
                setOrderStatusSearch(orderStatus)
            }
            else {
                localStorage.removeItem('OrderStatus1');
                setOrderStatusSearch("");
            }
        }
        if (ProductionStatus !== null && ProductionStatus !== "") {

            if (ProductionStatus) {
                setIsProductionStatussearch(ProductionStatus)
            }
            else {
                localStorage.removeItem('IsProductionStatus1');
                setIsProductionStatussearch("");
            }
        }


        ///////////////////////////

        if (props.location.state !== null) {

            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            if (moment(stateApDate[0].startDate).isValid()) {
                StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }

            dispatch(LoadFinalInspectionList(params));
        }
    }, [dispatch]);

    const FinalInspectionList = reducerState.fldetails.FinalInspectionList !== undefined ? reducerState.fldetails.FinalInspectionList : reducerState.fldetails.FinalInspectionList;
    const isLoadingFlDetailsReport = reducerState.fldetails.isLoadingFlDetailsReport;
    // loader

    useEffect(() => {

        if (!isLoadingFlDetailsReport) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingFlDetailsReport]);


    const handleChanges = (value) => {
        setSelectedOptions(value);
        // setComment(Values)
    };


    // User Location
    const [getIsShowGeoLocModal, setgetIsShowGeoLocModal] = useState(false);
    const [getCurrentLocation, setCurrentLocation] = useState({
        Latitude: '',
        Longitude: ''
    });

    function RemoveOrderStatus(Commentitem) {

        let Param = {
            Operation: FLReportSkuOrderStatus,
            Id: Commentitem.statusId,
        };
        setOrdStatusID({ showPopupDelete: true, Params: Param });
    }

    const handleOnSelect = (event, FieldName, purchaseOrderSkuID) => {
        let isChecked = false;
        let indexes = FinalInspectionList.findIndex(obj => obj.purchaseOrderSkuID === purchaseOrderSkuID)
        if (event.target.checked) {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));
    }


    const GetStatus = (PurchaseOrderSkuID, Feildname) => {
        showLoader();
        setPurchaseId('');
        setOrdindex(0);
        setShowmodal(true);
        setPurchaseId(PurchaseOrderSkuID);
        let isChecked = false;
        let indexes = FinalInspectionList.findIndex(obj => obj.purchaseOrderSkuID === PurchaseOrderSkuID)
        setOrdindex(indexes)
        let ordStatus = { ...selectedOptionStatus }

        if (Feildname === 'OrderStatus') {
            isChecked = true;
        } else {
            setIsAllSelected(false);
        }
        let params = {
            isChecked: isChecked, index: indexes
        };
        dispatch(UpdateCommentsreportViewCheckBox(params));

        FinalinspectionService.GetFLPurchaseOrderSkuReportComments(PurchaseOrderSkuID, 2).then((response) => {
            if (response.data) {
                setorderStatus(response.data.flReportStatus)
                ordStatus.value = 0;
                ordStatus.label = '-Select Order Status-';
                setSelectedOptionsStatus(ordStatus)
            }
            else {
                setSelectedOptionsStatus(ordStatus)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });

    }



    const GetComments = async (comment, feild, status) => {

        showLoader();
        let Approvallist = [];
        let ReceivedDate = '';
        let Approval = [{ FinalInspectionID: comment.finalInspectionID, InsCreatedDate: comment.insCreatedDate }]
        setheaderName(Approval);
        setQARemark(ReceivedDate)
        setinspectionstatus(status);
        setCommentpopup(true);

        await FinalinspectionService.GetFIApprovalHistory(comment.finalInspectionID).then(async (response) => {
            if (response.data) {
                setFIRemark(response.data)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });
        await FinalinspectionService.LoadGetFLApproval().then((res) => {
            if (res.data) {
                res.data.map((x) => {
                    Approvallist.push({
                        value: x.value,
                        label: x.label
                    })
                })
                setApprovalList(Approvallist);
            }
            hideLoader();
        })

    }


    // const StatusClose = (Feildname) => {
    //     let isChecked = false;
    //     if (Feildname === 'ordStatus') {
    //         isChecked = false;
    //     } else {
    //         setIsAllSelected(false);
    //     }
    //     let params = {
    //         isChecked: isChecked, index: getordindex
    //     };
    //     dispatch(UpdateCommentsreportViewCheckBox(params));
    //     setShowmodal(false);
    //     setSubmitted(false);
    // }
    let Size = '120px';
    const tableHeader = [
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize: '2%',
            left: 0,

        },
        checkboxStates.CreatedDate && (
            {
                name: "Created Date",
                field: "insCreatedDate",
                sortable: true,
                widthsize: Size,
                left: 0,

            }
        )
        ,
        checkboxStates.EntryNo && ({
            name: "Entry No",
            field: "finalInspectionID",
            sortable: true,
            widthsize: Size,
            // left: "120px",

        }),
        // checkboxStates.InspectionType && ({
        //     name: "Inspection Type",
        //     field: "inspectionType",
        //     sortable: true,

        //     widthsize: "5%",
        //     left: "240px",

        // })
        // ,
        // checkboxStates.TypeInterOrExtern && ({
        //     name: "Internal / External",
        //     field: "typeInterOrExtern",
        //     sortable: true,
        //     widthsize: "5%",
        //     left: "120px",

        // })

        // , checkboxStates.ReInspectionDoneAgainst && ({
        //     name: "Re Inspection Done Against",
        //     field: "",
        //     sortable: true,
        //     width: "10%",
        //     widthsize: "6%",
        //     //position: "sticky",
        //     left: "360px",
        //     //background: "#222d32",
        // })
        // , checkboxStates.Buyer && ({
        //     name: "Buyer",
        //     field: "buyerName",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "4%",
        //     //position: "sticky",
        //     left: "600px",
        //     //background: "#222d32",
        // })
        // , checkboxStates.Brand && ({
        //     name: "Brand",
        //     field: "brandName",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "4%",
        //     //position: "sticky",
        //     left: "480px",
        //     //background: "#222d32",
        // })
        // , checkboxStates.Season && ({
        //     name: "Season",
        //     field: "seasonName",
        //     sortable: true,
        //     //width: "5%",
        //     widthsize: "3%",
        //     //position: "sticky",
        //     left: "600px",
        //     //background: "#222d32",
        // })
        , checkboxStates.Supplier && ({
            name: "Supplier / Factory",
            field: "supplierName",
            sortable: true,
            //width: "5%",
            widthsize: Size,
            //position: "sticky",
            // left: "736px",
            //background: "#222d32",
        })
        // , checkboxStates.Factory && ({
        //     name: "Factory",
        //     field: "factory",
        //     sortable: true,
        //     //width: "4%",
        //     widthsize: "4%",
        //     // position: "sticky",
        //     left: "858px",
        //     //background: "#222d32",
        // })
        , checkboxStates.CustomerShipmentRefNo && ({
            name: "Ship.Ref No",
            field: "customerShipmentRefNo",
            sortable: true,
            //width: "5%",
            widthsize: Size,
        })
        , checkboxStates.StyleName && ({
            name: "Style Name",
            field: "styleName",
            sortable: true,
            widthsize: Size,
            //width: "7%",
        })
        , checkboxStates.SKUName && ({
            name: "SKU Name",
            field: "skuName",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.IDNo && ({
            name: "PO/ID No",
            field: "idpono",
            sortable: true,
            widthsize: Size,
            //width: "4%",
        })
        // , checkboxStates.IDNo && ({
        //     name: "FO PO No",
        //     field: "foidpono",
        //     sortable: true,
        //     widthsize: "120px",
        //     //width: "4%",
        // })

        , checkboxStates.StyleNo && ({
            name: "Order Qty",
            field: "styleNo",
            sortable: true,
            widthsize: Size,
            // width: "7%",
        })

        , checkboxStates.InspectionQuantity && ({
            name: "Ins.Qty",
            field: "inspectionQty",
            sortable: true,
            widthsize: Size,
            // width: "5%",
        })
        , checkboxStates.InspectionResult && ({
            name: "Ins.Result",
            field: "inspectionQty",
            sortable: true,
            widthsize: Size,
            // width: "4%",
        })
        // , checkboxStates.CreatedBy && ({
        //     name: "Created By",
        //     field: "createdBy",
        //     sortable: true,
        //     widthsize: "120px",
        //     // width: "2%",
        // })

        , checkboxStates.ApprovalResult && ({
            name: "App.Result",
            field: "approvalResult",
            sortable: true,
            widthsize: Size,
            // width: "2%",
        })
        // , checkboxStates.ApprovalResult && ({
        //     name: "Inspection Status",
        //     field: "approvalResult",
        //     sortable: true,
        //     widthsize: "120px",
        //     // width: "2%",
        // })

        ,
        {
            name: "Action",
            field: "Action",
            className: "text-center ActionWidth fixed-column-header-Route",
            //fixed- column - header - Route
            sortable: false,
            width: "120px",
            widthsize: Size,
            display: InternalComments && InternalComments.isView === 0 && InternalComments.isAdd === 0 && InternalComments.isEdit === 0 &&
                InternalComments.isDelete === 0 ? 'none' : ''
            //left: "736px",
        }
    ];

    let orderStatus = [
        {
            value: 1,
            label: 'Yet to Start',
            selected: false,

        },
        {
            value: 2,
            label: 'In-Progress',
            selected: false,

        },
        {
            value: 3,
            label: 'Completed',
            selected: false,

        },
        {
            value: 4,
            label: 'Hold',
            selected: false,

        },
        {
            value: 5,
            label: 'Cancel',
            selected: false,

        }
    ]

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };








    const handleAllOnSelect = (event, FieldName, page, total) => {

        let Pagecount = parseInt(page);
        if (event.target.checked) {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = true;
                })
                setIsAllSelected(true);
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(true));

        } else {
            for (let i = 0; i < Pagecount; i++) {
                commentsData.map(x => {
                    x.isSelected = false;
                })
                setIsAllSelected(false);
            }
            dispatch(UpdateCommentsreportViewAllCheckBox(false));
        }
    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateDate[0].isShow = isShow;
        getStateApDate[0].isShow = false;
        setStateApDate(getStateApDate);
        setStateDate(getStateDate);
    }
    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateApDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);
        setStateApDate(getStateApDate);
    }

    // const handleInsDateSelect = (event, isShow) => {

    //     let getStateDate = [...stateDate];
    //     let getStateInsDate = [...stateInsDate];
    //     getStateInsDate[0].isShow = isShow;
    //     getStateDate[0].isShow = false;
    //     setStateDate(getStateDate);
    //     setStateInsDate(getStateInsDate);
    // }

    // const handleTNADateSelect = (event, isShow) => {

    //     let getStateDate = [...stateDate];
    //     let getStateTNADate = [...stateTNADate];
    //     getStateTNADate[0].isShow = isShow;
    //     getStateDate[0].isShow = false;
    //     setStateDate(getStateDate);
    //     setStateTNADate(getStateTNADate);
    // }





    function DateExfaconchange(item) {
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('')
        setStateApDate(stateDate)
        stateApDate[0].startDate = item[0].startDate;
        stateApDate[0].endDate = item[0].endDate
        setStateApDate(stateApDate)

        let StartDate = null;
        let EndDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction, PoStartDate: StartDate,
            PoEndDate: formattedDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0
        }
        dispatch(LoadFinalInspectionList(params));
    }

    function DateRangechange(item) {

        stateApDate[0].startDate = null;
        stateApDate[0].endDate = new Date('');
        setStateDate(stateApDate)
        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(stateDate)
        const updatedStateDate = [
            {
                ...stateDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateDate(updatedStateDate);

        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }
        let StartApDate = null;
        let EndApDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate,
            ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
        }
        dispatch(LoadFinalInspectionList(params));
    }

    // function DateInsfaconchange(item) {


    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //         StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //         EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }


    // }

    // function DateTNAchange(item) {



    //     let StartDate = null;
    //     let EndDate = null;
    //     if (moment(stateDate[0].startDate).isValid()) {
    //         StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
    //     }
    //     if (moment(stateDate[0].endDate).isValid()) {
    //         EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
    //     }



    // }




    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);

        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }


        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }
        dispatch(LoadFinalInspectionList(params));
    }
    const clearExDateSelect = () => {
        // var defaultApStartDate = new Date();
        // const emptyStateExDate = ([
        //     {
        //         startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
        //         endDate: new Date(),
        //         key: 'selection',
        //         isShow: false
        //     }
        // ]);
        //setStateApDate(emptyStateExDate);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(NewemptyStateExDate[0].startDate).isValid()) {
            StartApDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(NewemptyStateExDate[0].endDate).isValid()) {
            EndApDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }
        dispatch(LoadFinalInspectionList(params));
    }

    // const clearInsDateSelect = () => {
    //     const emptyStateDate = [
    //         {
    //             startDate: null,
    //             endDate: new Date(""),
    //             key: 'selection',
    //             isShow: false
    //         }];
    //     setStateInsDate(emptyStateDate);
    // }

    // const clearTNADateSelect = () => {
    //     const emptyStateDate = [
    //         {
    //             startDate: null,
    //             endDate: new Date(""),
    //             key: 'selection',
    //             isShow: false
    //         }];
    //     setStateTNADate(emptyStateDate);
    // }
    FinalInspectionList.map((x) => {
        if (x.quarterName === null || x.quarterName === undefined) {
            x.quarterName = ''
        }
        if (x.categoryName === null || x.categoryName === undefined) {
            x.categoryName = ''
        }
    })

    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];


    const commentsData = useMemo(() => {
        //let computedComments = FinalInspectionList;

        let computedComments = FinalInspectionList.filter(o =>
            BuyerBrandDetails.some(({ brandId, isAllInfo, isMapped }) =>
                o.brandID === brandId && isAllInfo === 1 && isMapped === 0
            )
        );

        let drpvalue = orderStatus;

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.factory.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment => {
                comment.customerName == null ? comment.customerName = "" : comment.customerName = comment.customerName;
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase());
            }
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        // if (getBuyerEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.buyerEmployee.toLowerCase().includes(getBuyerEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }
        // if (getCubeEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.cubeEmployee.toLowerCase().includes(getCubeEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }

        if (getOrderStatussearch) {
            // if (getOrderStatussearch.value === 1) {
            //     computedComments = computedComments.filter(comment =>
            //         comment.orderStatus === null || comment.orderStatus === "" || comment.orderStatus === undefined
            //         // || parseInt(comment.orderStatus) === getOrderStatussearch.value
            //     );
            // }

            if (getOrderStatussearch.value === 1 || getOrderStatussearch.value === 2 || getOrderStatussearch.value === 7) {
                computedComments = computedComments.filter((comment) => {
                    return comment.approvalResult === null;
                })
            }
            else if (getOrderStatussearch.value === 3) {
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionType === 'Pass';
                })
            }
            else if (getOrderStatussearch.value === 4) {
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionType === 'Fail';
                })
            }
            else if (getOrderStatussearch.value === 6) {
                computedComments = computedComments.filter(comment => (comment.inspectionType === "Re Inspection"));
            }
            else if (getOrderStatussearch.value === 5) {
                computedComments = computedComments.filter(comment => (comment.inspectionType === "New Inspection"));
            }
            else if (getOrderStatussearch.value === 8) {
                computedComments = computedComments.filter((comment) => {
                    return comment.approvalResult !== 'Approved';
                })
            }
            else if (getOrderStatussearch.value === 9) {
                computedComments = computedComments.filter(comment => (comment.approvalResult === "Approved"));
            }
            else if (getOrderStatussearch.value === 10) {
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionType === 'Inspection Commercially Approved';
                })
            }
            else if (getOrderStatussearch.value === 11) {
                computedComments = computedComments.filter(comment => (comment.approvalResult === "Rejected"));
            }
            else if (getOrderStatussearch.value === 12) {
                computedComments = computedComments.filter((comment) => {
                    return comment.balQtyInspection > 0;
                })
            }
            else if (getOrderStatussearch.value === 13) {
                computedComments = computedComments.filter((comment) => {
                    return comment.balQtyReInspection > 0;
                })
            }
            else {
                computedComments = computedComments.filter(comment =>
                    parseInt(comment.orderStatus) === getOrderStatussearch.value
                );
            }


            setTotalItems(drpvalue && drpvalue.length);
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );



            setTotalItems(computedComments.length);
        }

        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.foidpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        if (stateDate[0].startDate && stateDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.insCreatedDate, format)) >= stateDate[0].startDate && new Date(moment(comment.insCreatedDate, format)) <= stateDate[0].endDate
            )
        }

        setTotalItems(computedComments.length);
        // // let Exformat = "DD/MM/YYYY";
        // // let StartExDate = null;
        // // let EndExDate = null;
        // // if (moment(stateApDate[0].startDate).isValid()) {
        // //     StartExDate = new Date(moment(stateApDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        // //     //StartDate.setMinutes(StartDate.getMinutes() + 370);
        // // }
        // // if (moment(stateApDate[0].endDate).isValid()) {
        // //     EndExDate = new Date(moment(stateApDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        // //     //EndDate.setMinutes(EndDate.getMinutes() + 370);
        // // }

        // // if (stateApDate[0].startDate && stateApDate[0].endDate) {
        // //     computedComments = computedComments.filter(comment =>
        // //         new Date(moment(comment.exFacDate, Exformat)) >= stateApDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateApDate[0].endDate
        // //     )
        // // }
        // // setTotalItems(computedComments.length);

        //sorting table
        if (sorting.field === "typeInterOrExtern") {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field] - (b[sorting.field])));
        }
        else if (sorting.field) {
            computedComments.map((x) => {
                x.finalInspectionID = x.finalInspectionID.toString();
            });

            const reversed = sorting.order === "asc" ? 1 : -1;

            computedComments = computedComments.sort((a, b) => {
                const fieldA = a[sorting.field] || '';
                const fieldB = b[sorting.field] || '';
                return reversed * fieldA.localeCompare(fieldB);
            });
        }




        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            FinalInspectionList,
            currentPage,
            // search,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateApDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);



    const handleChangeRemarks = (index, e, FieldName) => {
        //  setSubmitted(false);
        let inputText = '';
        if (FieldName === "Remarks") {

        }
        else if (FieldName === "ReceivedDate") {

        }
        // setRemarksList(values);
        // remarkslist(values);// call back to parent

    }

    const resetFinalIns = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setIsProductionStatussearch('');

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const emptyStateExDate = ([
            {

                startDate: null,//defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(''),
                key: 'selection',
                isShow: false
            }
        ]);

        var defaultInspectionStartDate = new Date();
        const emptyStateInsDate = ([
            {

                startDate: defaultInspectionStartDate.setDate(defaultInspectionStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        var defaultTNAStartDate = new Date();
        const emptyStateTNADate = ([
            {

                startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        setStateDate(emptyStateDate);
        setStateApDate(emptyStateExDate);

        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction,
            PoStartDate: StartDate, PoEndDate: EndDate,
            ApStartDate: StartApDate, ApEndDate: formattedDate,
            ApprovedStatus: 0
        }
        dispatch(LoadFinalInspectionList(params));
        //EmptyCheck();
    }
    const handleChangeOrdStatus = (value) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);
        setOrderStatusSearch(value);
        //setIsProductionStatussearch(value);
    }
    const handleclick = () => {

        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);

    }
    const onFocus = (e, field) => {
        var val = e.target.value;
        e.target.value = '';
        e.target.value = val;
    }

    const ViewFile = (event, filename) => { // event.preventDefault();

        // let arr;
        // arr = filename.split(',')
        // for (let sku of arr) {
        //
        // }
        setIsOpenimg(true);
        setImagename(filename);
    }
    function RemoveComments(Commentitem) {

        let Param = {
            Operation: FLReportSkuComments,
            Id: Commentitem.commentsID,
        };
        setID({ showPopupDelete: true, Params: Param });
    }

    const filevals = [...getFiles]
    const AppendFiles = async e => {

        const file = e.target.files[0];
        if (file !== null && file !== '' && file !== undefined) {
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', "application/vnd.ms-excel",
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            if (!validImageTypes.includes(fileType)) {
                Nodify('Warning!', 'warning', 'Invalid file format selected');
                $('#FileUpload').val("");
            }
            else {
                const formData = new FormData();
                formData.append("FormFile", file);
                formData.append("FileName", file.name);
                formData.append("Page", "ReportStatus");
                try {
                    const res = await axios.post(window.$APIBaseURL + "api/file", formData);
                    let Index = FinalInspectionList.findIndex(obj => obj.purchaseOrderSkuID === getpurchaseId.toString())
                    filevals.push({
                        reportManualFileId: 0,
                        fileName: res.data + "_" + file.name,
                        filePath: "Images/Report/" + file.name,
                        fileType: file.type,
                        reportIndexName: Index.toString(),
                        reportFileIndexName: getFiles.length.toString(),
                        IsDeleted: 0
                    })
                    setFiles(filevals);
                    // let Split = getFiles[0].FileName.split("_");
                    // getFiles[0].FileName = Split[1];
                    $('#FileUpload').val("");
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }

    const RemoveImages = async (index, files, ReportManualFileId) => {

        if (ReportManualFileId !== 0) {
            getFiles[index].IsDeleted = 1;
        } else {
            filevals.splice(index, 1);
        }
        setFiles(filevals);
    }

    const handleFullClose = (Feildname, comment) => {

        // if (getSelectedApproval) {
        //     getSelectedApproval.value = 0;
        //     getSelectedApproval.label = '';
        //     setSelectedApproval(getSelectedApproval);
        // }
        // let isChecked = false;
        // if (Feildname === 'reportcmt') {
        //     isChecked = false;
        // } else {
        //     setIsAllSelected(false);
        // }
        // let params = {
        //     isChecked: isChecked, index: getindex
        // };
        // if (comment === undefined || comment === '') {
        //     setSubmitted(false);
        // }
        // if (getFiles.reportManualFileId === 0) {
        //     setFiles([]);
        // }
        // dispatch(UpdateCommentsreportViewCheckBox(params));
        setCommentpopup(false);
        setSubmitted(false)
        setSelectedApproval('')
        setFIRemark([]);
        setFIRemarks('');
        setSelectedApproval("");

    }
    const handleChangeComment = (e) => {
        setSubmitted(false);
        let inputtext = "";
        if (e.target.value.trim() && e.target.value.trim() !== '') {
            inputtext = e.target.value;
            setSubmitted(false);
        }
        else {
            //setSubmitted(true);
        }
        setFIRemarks(inputtext);

    }
    function handleCmtEXFactoryChanges(value) {
        debugger
        setSelectedApproval(value)
    }

    const handleChangeIsProductionStatus = (value) => {
        ;
        if (value === null) {
            value = { label: "Sampling", selected: true, value: 1 }
        }

        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartExDate = null;
        let EndExDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartExDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndExDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ExfacStartDate: StartExDate, ExfacEndDate: EndExDate }
        dispatch(LoadFlDetailsreport(params));
    }
    function handleChange(e, filterName) {

        let inputText = '';
        if (filterName === 'Buyer1' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            localStorage.setItem('Buyer1', inputText)
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleNo1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('StyleNo1', inputText)
            setStyleNoSearch(inputText);
        }
        else if (filterName === 'StyleName1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('StyleName1', inputText)
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Season1', inputText)
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Brand1', inputText)
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('ID/NO1', inputText)
            setIdPoSearch(inputText);
        }
        else if (filterName === 'Category1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Category1', inputText)
            setCategorySearch(inputText);
        }
        else if (filterName === 'Supplier1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Supplier1', inputText)
            setsupplierSearch(inputText);
        }
        else if (filterName === 'EndCustomer1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('EndCustomer1', inputText)
            setEndCustomerSearch(inputText);
        }
        else if (filterName === 'OrderStatus1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('OrderStatus1', inputText)
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('SKUName1', inputText)
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Factory1', inputText)
            setFactorySearch(inputText);
        }
        else if (filterName === 'IsProductionStatus1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
                // let params = { Operation: 1 ,IsProduction: inputText}
                // dispatch(LoadFlDetailsreport(params));
            }
            localStorage.setItem('IsProductionStatus1', inputText)
            setIsProductionStatussearch(inputText);
        }


        else if (filterName === 'Quarter1') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            localStorage.setItem('Quarter1', inputText)
            setQuarterSearch(inputText);
        }
    };



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = {
        thumbnails: {
            showThumbnails: false,
        }
    }

    // const GetSKUandQty = (PurchaseOrderSkuID,) => {
    //     ;
    //     ReportService.GetPurchaseOrderSizeQty(PurchaseOrderSkuID).then((response) => {
    //         ;
    //         if (response.data) {
    //             setskuqtylist(response.data)
    //         }

    //     }).catch(() => { });
    //     setsizeqtypopup(true);

    // }
    const GetSKUandQty = (finalInspectionID) => {
        //setskuid('');
        FinalinspectionService.GetPurchaseOrderSKUQty(finalInspectionID).then((response) => {
            if (response.data) {

                setskuqtylist(response.data)
            }
        }).catch(() => { });
        setsizeqtypopup(true);
        //setskuid({ skuID: PurchaseOrderSkuID, qrty: orderQty })
    }
    const Drag = (props) => {
        return <Draggable handle=".modal-title"><ModalDialog {...props} /></Draggable>
    }

    const handleCloseExFactoryFormPopup = () => {
        Input.IsExFactoryForm = 0;
        setInputFields(Input);
    };


    const handleFinalInspecViewOpen = (comment, Isfinal, TNAExFactoryId) => {
        Input.Finalview = Isfinal;
        // Input.Finalview = 0
        setInputFields(Input);
        let balanceqty = 0;
        FinalinspectionService.GetTNAExfacReportList(TNAExFactoryId).then((res) => {
            let uniquevalues = [...new Map(res.data.map(item =>
                [item['skuName'], item])).values()];
            res.data.map((inspc) => {
                if (balanceqty === 0) {
                    balanceqty = parseInt(inspc.poQty) - parseInt(inspc.inspectedQty);
                    inspc.balanceQuantity = balanceqty;
                }
                else {
                    inspc.balanceQuantity = parseInt(balanceqty) - parseInt(inspc.inspectedQty);
                    balanceqty = inspc.balanceQuantity;
                }

            })

            setnewfinalReportlist(res.data)
            setnewheaderdata(uniquevalues)
            hideLoader();
        })
        getInspectionInfo.TaskStatus = 2;
        setInspectionInfo(getInspectionInfo);


    }

    const handleFinalInspecViewclose = () => {
        Input.Finalview = 0;
        setInputFields(Input);
    };








    const handleExFactoryFormOpen = (comment, IsExFactoryFormPop, Taskid) => {

        // let Operation = 1
        // let Dependency = false;
        // if (values[index].SubTaskList[subIndex].ChildTaskList[childIndex].intDependency === 0) {

        //     if (values[index].SelectedDependency) {
        //         let arrstatus = [];
        //         arrstatus = values[index].SelectedDependency.split(',');
        //         if (arrstatus.length > 0) {
        //             arrstatus.forEach(element => {

        //                 let val = [];
        //                 val = element.split('-');
        //                 let valIndex = values[parseInt(val[0]) - 1].StatusID
        //                 if (valIndex !== 3) {
        //                     Dependency = true;
        //                 }
        //             });
        //         }
        //         if (Dependency) {
        //             Nodify('Warning!', 'warning', "Please close dependency task");
        //         }
        //         else {

        //         }
        //     }
        // }

        // if (!Dependency) {

        //     let params = {
        //         Operation: values[index].SubTaskList[subIndex].ChildTaskList[childIndex].StatusID !== 3 ? 1 : 2,
        //         BuyerId: getTNABuyerID, BrandId: getTNABrandID, SeasonId: getTNASeasonID,
        //         SupplierId: getTNASupplierID, TaskId: TaskID, StyleId: props.location.state.params.styleId,
        //         SkuId: 0, TaskOwnerIdList: TaskOwnerIdList, TaskHolderId: TaskHolderId,
        //         tnaExFactoryId: TNAExFactoryId, status: Status, selectedDependency: SelectedDependency, NewID: SubId
        //     };
        //     setAddInspectionListParam(params);



        let params = {
            Operation: 2,
            BuyerId: comment.buyerID, BrandId: comment.brandID, SeasonId: comment.seasonID,
            SupplierId: comment.supplierID, TaskId: Taskid, StyleId: comment.styleID,
            SkuId: 0, TaskOwnerIdList: '', TaskHolderId: 0,
            tnaExFactoryId: 0, status: 1, selectedDependency: '', NewID: 0,
            Isproduction: IsProduction
        };
        setAddInspectionListParam(params);


        Input.IsExFactoryForm = IsExFactoryFormPop;
        Input.strTaskName = "Inspection";
        Input.StatusID = 1;
        setInputFields(Input);

        getInspectionInfo.TaskStatus = IsExFactoryFormPop;
        setInspectionInfo(getInspectionInfo);


    };

    const [getFinalInspectionPageView, setFinalInspectionPageView] = useState('AddInspection');
    const [getAddInspectionListParam, setAddInspectionListParam] = useState([]);
    const [getIsAddFinalInspection, setIsAddFinalInspection] = useState(false);
    const [getfinalinspectionMulti, setfinalinspectionMulti] = useState([]);
    const [commonSubmitApprovalParams, setCommonSubmitApprovalParams] = useState({ SelectedDependency: '', TaskID: 0, IsSingleTask: true, hasAllDependencyStart: 0 });
    const pageRedirectForFinalInspection = (Page) => {
        setFinalInspectionPageView(Page);
    }

    function FinalInspectionCallback(value) {
        ;

        setfinalinspectionMulti(value)
    }

    const AddExFactoryForm = () => {

        let Multiselected = [];
        const Values = [...getfinalinspectionMulti]
        let isvalid = true;
        isvalid = !!getfinalinspectionMulti.find(x => x.isSelected === 1)
        if (isvalid) {
            Multiselected = Values.concat(getfinalinspectionMulti.filter(x => x.isSelected === 1))
            setIsAddFinalInspection(true);
        } else {
            Nodify('Warning!', 'warning', 'Please fill atleast one checkbox');
            return false;
        }

        //values[index].SubTaskList[subIndex].ChildTaskList[childIndex].IsOpenInspectionConfirmationType = 1;

    };
    const handleCloseInspectionConfirmationPopup = (index, subIndex, childIndex) => {
        setIsAddFinalInspection(false);
    };

    const handleFinalInspecView = async (comment, Action, inpectionpage) => {

        let params = {
            Operation: 2,
            BuyerId: comment.buyerID,
            BrandId: comment.brandID, SeasonId: comment.seasonID,
            SupplierId: comment.supplierID,
            TaskId: comment.taskDetailsID,
            StyleId: comment.styleID,
            SkuId: 0,
            TaskOwnerIdList: '',
            TaskHolderId: 0,
            tnaExFactoryId: comment.tnaExFactoryId,
            status: 0,
            selectedDependency: '',
            //NewID is subID
            NewID: comment.skuSubID,
            Isproduction: IsProduction
        };
        setAddInspectionListParam(params);
        /// dispatch(GetSubChildMultExFactoryFormSKUList(params));
        let AlterSkudetailsList = [];
        let NewArr = [];
        await TNAService.GetSubChildMultExFactoryFormSKUList(params).then((res) => {
            if (res.data) {
                AlterSkudetailsList = res.data
            }
        })
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        let sizeandqtyfordisplay = "";
        let arrGradingSizesValue = comment.skuID.split(",").map(value => value.trim());
        // NewArr = AlterSkudetailsList.filter(x => x.skuID === parseInt(comment.skuID))
        NewArr = AlterSkudetailsList.filter(x => arrGradingSizesValue.includes(x.skuID.toString()));
        NewArr.map((Purchaseorder) => {
            Purchaseorder.isSelected = 1;
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        getInspectionInfo.multiselecteddatas = NewArr;
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = { tnaid: comment.tnaid };;
        getInspectionInfo.type = comment.typeInterOrExtern;
        getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay.toString();
        getInspectionInfo.TNAInspectionID = comment.finalInspectionID;
        getInspectionInfo.Isproduction = IsProduction
        getInspectionInfo.page = inpectionpage;
        getInspectionInfo.ParentTask = "ProductionFLDetails";
        getInspectionInfo.Mode = comment.inspectionType === 'Re Inspection' ? 'ReInspection' : "InspectionView";
        getInspectionInfo.NewDetails = comment;
        getInspectionInfo.Action = Action;
        getInspectionInfo.TaskStatus = 2;
        getInspectionInfo.styleID = comment.styleID;
        getInspectionInfo.PageName = 'Inspection Entry';
        getInspectionInfo.IsAddorEditReinspection = comment.inspectionType === 'Re Inspection' ? Action : '';
        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    };

    //PopUP
    function handleDeletefinalinsPopup(TNAInspectionID) {

        let commentparams = {
            Operation: 105,
            Id: TNAInspectionID,
        }
        setIDcomment({ showPopupDelete: true, Params: commentparams });
    }

    //Main Grid List
    function handleDeletefinalinspection(type, TNAInspectionID, Action) {
        let PackingParams = {
            Operation: 104,
            Id: TNAInspectionID,
        }
        setIDfinalEntry({ showPopupDelete: true, Params: PackingParams });
    }

    const handleFinalInspecViewDetails = (type, TNAInspectionID, comment) => {

        // let sizeandqtyfordisplay = "";
        // let NewArr = [];
        // NewArr = getfinalinspectionMulti.filter(x => x.isSelected === 1)
        // NewArr.map((Purchaseorder) => {
        //     if (sizeandqtyfordisplay === '') {
        //         sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
        //     } else {
        //         sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
        //     }
        // })
        getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = { tnaid: comment.tnaid };;
        getInspectionInfo.type = type;
        //getInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay
        getInspectionInfo.TNAInspectionID = TNAInspectionID;
        getInspectionInfo.Isproduction = IsProduction
        getInspectionInfo.page = getFinalInspectionPageView;
        getInspectionInfo.ParentTask = "ProductionFLDetails";
        getInspectionInfo.NewDetails = comment;
        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    };












    const CreateFinalInspection = (type, comment) => { // type=1=> internal, 2=> external

        getInspectionInfo.multiselecteddatas = getfinalinspectionMulti;
        getInspectionInfo.quantityinfo = getAddInspectionListParam
        getInspectionInfo.details = { tnaid: comment.tnaid };
        getInspectionInfo.type = type;
        getInspectionInfo.Isproduction = IsProduction;
        getInspectionInfo.page = getFinalInspectionPageView;
        getInspectionInfo.ParentTask = "ProductionFLDetails";
        getInspectionInfo.NewDetails = comment;
        props.history.push(`/CreateFinalInspection`, { params: getInspectionInfo });
    }


    function ExporttoExcel(TNAInspectionID, TNAId, Styleid, buyerName) {

        if (buyerName === 'Focus International') {
            ExporttoExcelFocus(TNAInspectionID, TNAId, Styleid)
        }
        else if (buyerName === 'Steiff') {
            ExporttoExcelSte(TNAInspectionID, TNAId, Styleid)
        }
        else if (buyerName === 'JD Sports') {
            ExporttoExcelJD(TNAInspectionID, TNAId, Styleid)
        }


    }
    const handlepdffunction = (comment) => {
        setinpsectionID(comment.finalInspectionID)
        setSettingpopupopen(true);
    }
    async function ExporttoExcelFocus(TNAInspectionID, TNAId, Styleid) {
        showLoader();
        let skudetails = [];
        await FinalinspectionService.GetTNAFinalinspecMainInfo(TNAInspectionID, TNAId).then((res) => {

            //load measurement data
            if (res.data.finalInsMeasurementMainList.length > 0) {
                values[0].StyleMeasurementID = res.data.finalInsMeasurementMainList[0].styleMeasurementID;
                values[0].MeasurementSpec = res.data.finalInsMeasurementMainList[0].measurementSpec;
                values[0].TNAFinalinsMeasurementMainId = res.data.finalInsMeasurementMainList[0].tnaFinalinsMeasurementMainId;
                values[0].MeasurementCheckedPeices = res.data.finalInsMeasurementMainList[0].measurementCheckedPeices;
                values[0].Category = [];


                if (res.data.finalInsMeasurementCategoryList.length > 0) {
                    res.data.finalInsMeasurementCategoryList.map((category, catIndex) => {

                        skudetails.push({
                            SkuName: category.skuName
                        })

                        values[0].Category.push({
                            TNAFinalinsMeasurementCategoryId: category.tnaFinalinsMeasurementCategoryId,
                            StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                            MeasurementCategoryID: category.measurementCategoryID,
                            StyleMeasurementID: category.styleMeasurementID,
                            CategoryTypeName: category.categoryTypeName,
                            CategoryIndexName: category.categoryIndexName,
                            SizeHeaderNames: category.sizeHeaderNames,
                            SkuName: category.skuName,
                            CurrentSize: '',
                            Remark: category.remark,
                            Point: []
                        });
                        // setcurrentSizeheaders(Sizeheadernames);

                        let pointList = res.data.finalInsMeasurementPointList.filter(x => x.categoryIndexName === category.categoryIndexName && x.skuName === category.skuName);
                        pointList.map((point, pointIndex) => {
                            //Revise
                            // point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                            values[0].Category[catIndex].Point.push({
                                TNAFinalinsMeasurementPointsId: point.tnaFinalinsMeasurementPointsId,
                                StyleMeasurementID: point.styleMeasurementID,
                                MeasurementPoints: point.measurePointName,
                                MeasurementGranding: point.measurementGranding,
                                TolerancePlus: point.tolerancePlus,
                                ToleranceMinus: point.toleranceMinus,
                                GradingSizes: point.gradingSizes,
                                GradingSizesValue: point.gradingSizesValue,
                                arrGradingSizes: [],
                                CategoryIndexName: point.categoryIndexName,
                                SkuName: point.skuName,
                                CategoryTypeName: category.categoryTypeName,

                            });
                            let arrGradingSizes = point.gradingSizes.split(",");
                            let arrGradingSizesValue = point.gradingSizesValue.split(",");
                            let arrcount = 0;

                            arrGradingSizes.forEach((val, valIndex) => {
                                if (arrcount === 0) {
                                    arrcount++;
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                        [val]: arrGradingSizesValue[valIndex],

                                    });
                                }
                                else {
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] =
                                        arrGradingSizesValue[valIndex];


                                }
                            });

                        });
                    });
                    setCurrentSize(values[0].Category, Styleid);

                }
            }
            else {
                setisStyleMeasurementempty(true);
            }
            setMessureValue(skudetails)


        })


        await FinalinspectionService.GetFinalInspectionExportFocusformatList(TNAInspectionID).then((res) => {
            if (res.data) {
                let fileName = 'FocusInternationalFinalInspectionReport_' + TNAInspectionID + '.xlsx';
                exportExcelFile(res.data, fileName, ImageShow, values, skudetails);

            }

        })
    }

    async function ExporttoExcelSte(TNAInspectionID, TNAId, Styleid) {
        showLoader();
        let skudetails = [];
        await FinalinspectionService.GetTNAFinalinspecMainInfo(TNAInspectionID, TNAId).then((res) => {

            //load measurement data
            if (res.data.finalInsMeasurementMainList.length > 0) {
                values[0].StyleMeasurementID = res.data.finalInsMeasurementMainList[0].styleMeasurementID;
                values[0].MeasurementSpec = res.data.finalInsMeasurementMainList[0].measurementSpec;
                values[0].TNAFinalinsMeasurementMainId = res.data.finalInsMeasurementMainList[0].tnaFinalinsMeasurementMainId;
                values[0].MeasurementCheckedPeices = res.data.finalInsMeasurementMainList[0].measurementCheckedPeices;
                values[0].Category = [];


                if (res.data.finalInsMeasurementCategoryList.length > 0) {
                    res.data.finalInsMeasurementCategoryList.map((category, catIndex) => {

                        skudetails.push({
                            SkuName: category.skuName
                        })

                        values[0].Category.push({
                            TNAFinalinsMeasurementCategoryId: category.tnaFinalinsMeasurementCategoryId,
                            StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                            MeasurementCategoryID: category.measurementCategoryID,
                            StyleMeasurementID: category.styleMeasurementID,
                            CategoryTypeName: category.categoryTypeName,
                            CategoryIndexName: category.categoryIndexName,
                            SizeHeaderNames: category.sizeHeaderNames,
                            SkuName: category.skuName,
                            CurrentSize: '',
                            Remark: category.remark,
                            Point: []
                        });
                        // setcurrentSizeheaders(Sizeheadernames);

                        let pointList = res.data.finalInsMeasurementPointList.filter(x => x.categoryIndexName === category.categoryIndexName && x.skuName === category.skuName);
                        pointList.map((point, pointIndex) => {
                            //Revise
                            // point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                            values[0].Category[catIndex].Point.push({
                                TNAFinalinsMeasurementPointsId: point.tnaFinalinsMeasurementPointsId,
                                StyleMeasurementID: point.styleMeasurementID,
                                MeasurementPoints: point.measurePointName,
                                MeasurementGranding: point.measurementGranding,
                                TolerancePlus: point.tolerancePlus,
                                ToleranceMinus: point.toleranceMinus,
                                GradingSizes: point.gradingSizes,
                                GradingSizesValue: point.gradingSizesValue,
                                arrGradingSizes: [],
                                CategoryIndexName: point.categoryIndexName,
                                SkuName: point.skuName,
                                CategoryTypeName: category.categoryTypeName,

                            });
                            let arrGradingSizes = point.gradingSizes.split(",");
                            let arrGradingSizesValue = point.gradingSizesValue.split(",");
                            let arrcount = 0;

                            arrGradingSizes.forEach((val, valIndex) => {
                                if (arrcount === 0) {
                                    arrcount++;
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                        [val]: arrGradingSizesValue[valIndex],

                                    });
                                }
                                else {
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] =
                                        arrGradingSizesValue[valIndex];


                                }
                            });

                        });
                    });
                    setCurrentSize(values[0].Category, Styleid);

                }
            }
            else {
                setisStyleMeasurementempty(true);
            }
            setMessureValue(skudetails)


        })


        await FinalinspectionService.GetFinalInspectionExportFocusformatList(TNAInspectionID).then((res) => {
            if (res.data) {
                let fileName = 'StriffFinalInspectionReport_' + TNAInspectionID + '.xlsx';
                exportExcelFileSte(res.data, fileName, ImageShow, values, skudetails);

            }

        })



    }

    async function ExporttoExcelJD(TNAInspectionID, TNAId, Styleid) {

        showLoader();

        let skudetails = [];
        await FinalinspectionService.GetTNAFinalinspecMainInfo(TNAInspectionID, TNAId).then((res) => {

            //load measurement data
            if (res.data.finalInsMeasurementMainList.length > 0) {
                values[0].StyleMeasurementID = res.data.finalInsMeasurementMainList[0].styleMeasurementID;
                values[0].MeasurementSpec = res.data.finalInsMeasurementMainList[0].measurementSpec;
                values[0].TNAFinalinsMeasurementMainId = res.data.finalInsMeasurementMainList[0].tnaFinalinsMeasurementMainId;
                values[0].MeasurementCheckedPeices = res.data.finalInsMeasurementMainList[0].measurementCheckedPeices;
                values[0].Category = [];


                if (res.data.finalInsMeasurementCategoryList.length > 0) {
                    res.data.finalInsMeasurementCategoryList.map((category, catIndex) => {

                        if (skudetails.filter(x => x.SkuName === category.skuName).length === 0) {
                            skudetails.push({
                                SkuName: category.skuName
                            })
                        }

                        values[0].Category.push({
                            TNAFinalinsMeasurementCategoryId: category.tnaFinalinsMeasurementCategoryId,
                            StyleMeasurementCategoryID: category.styleMeasurementCategoryID,
                            MeasurementCategoryID: category.measurementCategoryID,
                            StyleMeasurementID: category.styleMeasurementID,
                            CategoryTypeName: category.categoryTypeName,
                            CategoryIndexName: category.categoryIndexName,
                            SizeHeaderNames: category.sizeHeaderNames,
                            SkuName: category.skuName,
                            CurrentSize: '',
                            Remark: category.remark,
                            Point: []
                        });
                        // setcurrentSizeheaders(Sizeheadernames);

                        let pointList = res.data.finalInsMeasurementPointList.filter(x => x.categoryIndexName === category.categoryIndexName && x.skuName === category.skuName);
                        pointList.map((point, pointIndex) => {
                            //Revise
                            // point.styleMeasurementCategoryPointsID = MeasurementList.measurementOpreation === 1 ? 0 : point.styleMeasurementCategoryPointsID;
                            values[0].Category[catIndex].Point.push({
                                TNAFinalinsMeasurementPointsId: point.tnaFinalinsMeasurementPointsId,
                                StyleMeasurementID: point.styleMeasurementID,
                                MeasurementPoints: point.measurePointName,
                                MeasurementGranding: point.measurementGranding,
                                TolerancePlus: point.tolerancePlus,
                                ToleranceMinus: point.toleranceMinus,
                                GradingSizes: point.gradingSizes,
                                GradingSizesValue: point.gradingSizesValue,
                                arrGradingSizes: [],
                                CategoryIndexName: point.categoryIndexName,
                                SkuName: point.skuName,
                                CategoryTypeName: category.categoryTypeName,

                            });
                            let arrGradingSizes = point.gradingSizes.split(",");
                            let arrGradingSizesValue = point.gradingSizesValue.split(",");
                            let arrcount = 0;

                            arrGradingSizes.forEach((val, valIndex) => {
                                if (arrcount === 0) {
                                    arrcount++;
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes.push({
                                        [val]: arrGradingSizesValue[valIndex],

                                    });
                                }
                                else {
                                    values[0].Category[catIndex].Point[pointIndex].arrGradingSizes[0][val] =
                                        arrGradingSizesValue[valIndex];


                                }
                            });

                        });
                    });
                    setCurrentSize(values[0].Category, Styleid);

                }
            }
            else {
                setisStyleMeasurementempty(true);
            }
            setMessureValue(skudetails)


        })


        await FinalinspectionService.GetFinalInspectionExportJDformatList(TNAInspectionID).then((res) => {
            if (res.data) {

                exportExcelFileJD(res.data, ImageShow, values, skudetails, Styleid);

            }

        })



    }


    function setCurrentSize(CategoryList, Styleid) {

        StrikeoffService.LoadGroupSizeDetailsList(Styleid).then((response) => {
            if (response.data) {
                // setGradingSize(response.data);
                var allsizes = response.data;
                let CategoryRowNo = 1;



                CategoryList.map((c, cindex) => {
                    let Sizeheadernames = [];
                    let splitheadername = c.SizeHeaderNames.split(',')

                    splitheadername.map(s => {
                        let size = s.split('-');
                        Sizeheadernames.push({
                            SizeName: size[0],
                            HeaderName: s,
                            SkuName: c.SkuName,
                            CategoryRowNo: CategoryRowNo
                        })
                    })
                    values[0].Category[cindex].SizeHeaderNames = Sizeheadernames;
                    values[0].Category[cindex].CategoryRowNo = CategoryRowNo;
                    CategoryRowNo++;
                })
                values[0].Category = CategoryList;
                setMeasurementdata(values);
                //  setShowMsrtabl(true);



            }
        }).catch(() => { });



    }

    const columnss = [

        {
            title: "Created Date",
            field: "insCreatedDate",
        }
        ,
        {
            title: "Entry No",
            field: "finalInspectionID",
        },

        {
            title: "Supplier / Factory",
            field: "supplierName",
        }

        , {
            title: "Ship.Ref No",
            field: "customerShipmentRefNo",
        }
        , {
            title: "Style Name",
            field: "styleName",
        }
        , {
            title: "SKU Name",
            field: "skuName",
        }
        , {
            title: "PO/ID No",
            field: "idpono",

        }
        , {
            title: "Order Qty",
            field: "styleNo",

        }

        , {
            title: "Ins.Qty",
            field: "inspectionQty",
        }
        , {
            title: "Ins.Result",
            field: "inspectionResult",
        }

        , {
            title: "App.Result",
            field: "approvalResult",
        }
    ]
    let PdfValues = [];

    const DownloadPDF = () => {

        commentsData.map(comment => {
            PdfValues.push({
                insCreatedDate: comment.insCreatedDate,
                finalInspectionID: comment.finalInspectionID,
                supplierName: comment.supplierName + ' / ' + comment.factory,
                customerShipmentRefNo: comment.customerShipmentRefNo === '' ? '-' : comment.customerShipmentRefNo,
                styleName: comment.styleName,
                skuName: comment.skuName,
                idpono: comment.idpono,
                styleNo: comment.styleNo,
                inspectionQty: comment.inspectionQty,
                inspectionResult: comment.inspectionResult,
                approvalResult: comment.approvalResult === null ? '-' : comment.approvalResult
            })

        })
        const doc = new jsPDF()
        doc.text("Finalinspection Entries", 20, 10)
        doc.autoTable({
            theme: "grid",
            columns: columnss.map(col => ({ ...col, dataKey: col.field })),
            body: PdfValues
        })
        doc.save('FinalinspectionEntries.pdf')



    }

    return (
        <div className="page-body" style={{ background: "none" }}>
            {/* <ReactNotification /> */}
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div className="widget">
                        {/* <div className="widget-header" style={{ boxShadow: "none", padding: "0px" }}> */}
                        {/* <span className="widget-caption" style={{ padding: "15px 0px 0px" }}>Final Inspection Entry List (91 Days)
                                &nbsp;

                            </span> */}
                        {/* {commentsData.length !== 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button-final"
                                    className="fa fa-file-excel-o  btn btn-darkorange excelcomt"
                                    table={"table-to-xls"}
                                    filename={"TNADetailsReportList"}
                                    sheet="TNADetailsReport"
                                    buttonText="  Export to Excel"

                                />
                            )} */}
                        {/* </div> */}
                        <div className="widget-body" style={{ boxShadow: "none", padding: "0px" }}>
                            <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                        <div className="widget">
                                            <div className="widget-body no-padding">
                                                <div className="widget-main ">
                                                    <div className="panel-group accordion" id="accordion1">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading ">
                                                                <h4 className="panel-title">
                                                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne1">
                                                                        Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                    </a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapseOne1" className="panel-collapse collapse in">
                                                                <div className="panel-body border-red">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                            <div className="col-md-2">
                                                                                <label>Buyer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Buyer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Buyer'}
                                                                                        onChange={e => handleChange(e, 'Buyer1')}
                                                                                        value={getBuyersearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                                <label>Season</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Season'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Season'}
                                                                                        onChange={e => handleChange(e, 'Season1')}
                                                                                        value={getSeasonsearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Brand</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Brand'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Brand'}
                                                                                        onChange={e => handleChange(e, 'Brand1')}
                                                                                        value={getBrandsearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Quarter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Quarter'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Quarter'}
                                                                                        onChange={e => handleChange(e, 'Quarter1')}
                                                                                        value={getQuartersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Supplier</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Supplier'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Supplier'}
                                                                                        onChange={e => handleChange(e, 'Supplier1')}
                                                                                        value={getSuppliersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>PO/ID No.</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'PO/ID No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'PO/ID No'}
                                                                                        onChange={e => handleChange(e, 'ID/NO1')}
                                                                                        value={getIdPosearch}
                                                                                    />

                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    &nbsp;


                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-2">
                                                                                <label>End Customer</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'End Customer'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'End Customer'}
                                                                                        onChange={e => handleChange(e, 'EndCustomer1')}
                                                                                        value={getEndCustomersearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Category</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Category'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Category'}
                                                                                        onChange={e => handleChange(e, 'Category1')}
                                                                                        value={getCategorysearch}
                                                                                    />

                                                                                </span>
                                                                            </div>


                                                                            <div className="col-md-2">
                                                                                <label>Style Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Style Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style Name'}
                                                                                        onChange={e => handleChange(e, 'StyleName1')}
                                                                                        value={getStyleNamesearch}
                                                                                    />
                                                                                </span>

                                                                            </div>

                                                                            <div className="col-md-2">
                                                                                <label>Style No</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className=" StyleList form-control"
                                                                                        title={'Style No'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Style No'}
                                                                                        onChange={e => handleChange(e, 'StyleNo1')}
                                                                                        value={getStyleNosearch}
                                                                                    />

                                                                                </span>



                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>SKU Name</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'SKU Name'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'SKU Name'}
                                                                                        onChange={e => handleChange(e, 'SKUName1')}
                                                                                        value={getSkuSearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label>Factory</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="StyleList form-control"
                                                                                        title={'Factory'}
                                                                                        maxlength={"50"}
                                                                                        placeholder={'Factory'}
                                                                                        onChange={e => handleChange(e, 'Factory1')}
                                                                                        value={getFactorySearch}
                                                                                    />
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;

                                                                    <div className="row">
                                                                        <div className="col-md-12">


                                                                            <div className="col-md-2">
                                                                                <label>Inspection and Approval Filter</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={InspectionandApproval}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                    //value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>



                                                                            <div className="col-md-2" ref={datePickerRefIns}>
                                                                                <label>Inspection Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                        type="text"
                                                                                        className={'form-control styledatapickerwidth100'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times stylemleft" onClick={clearDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateDate([item.selection])}
                                                                                            onChange={item => DateRangechange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2" ref={datePickerRefApp}>
                                                                                <label>Approval Date</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleExDateSelect(event, !stateApDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapickerwidth100'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times stylemleft" onClick={clearExDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateApDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateApDate([item.selection])}
                                                                                            onChange={item => DateExfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateApDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <div className="col-md-1 margin-top-30">
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-md-3">
                                                                                <label>Status</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={orderStatus}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                        value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-3">
                                                                                <label>Inspection Date Filter</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateInsDate[0].startDate === null ? '' : moment(stateInsDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateInsDate[0].endDate === null || !moment(stateInsDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateInsDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleInsDateSelect(event, !stateInsDate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearInsDateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateInsDate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            //onChange={item => setStateApDate([item.selection])}
                                                                                            onChange={item => DateInsfaconchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateInsDate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>

                                                                            <div className="col-md-3">
                                                                                <label>TNA Date Range</label>
                                                                                <span className="input-icon icon-right">
                                                                                    <input
                                                                                        value={(stateTNADate[0].startDate === null ? '' : moment(stateTNADate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateTNADate[0].endDate === null || !moment(stateTNADate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateTNADate[0].endDate).format('DD/MM/YYYY'))}
                                                                                        onClick={event => handleTNADateSelect(event, !stateTNADate[0].isShow)}
                                                                                        //onBlur={event => handleDateBlur()}
                                                                                        type="text"
                                                                                        className={'form-control styledatapicker'}
                                                                                        placeholder="Select Date"

                                                                                    />
                                                                                    <i className="fa fa-times style" onClick={clearTNADateSelect} ></i>
                                                                                </span>


                                                                                <span className='input-icon icon-right mb-5'>
                                                                                    <br />
                                                                                    {stateTNADate[0].isShow &&
                                                                                        <DateRangePicker
                                                                                            // onChange={item => setStateApDate([item.selection])}
                                                                                            onChange={item => DateTNAchange([item.selection])}
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            ranges={stateTNADate}
                                                                                            showDateDisplay={false}
                                                                                            direction="vertical"
                                                                                            className={'StyleDateRangePicker'}

                                                                                        />

                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="col-md-1 margin-top-30" style={{ marginLeft: "250px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    {/* <div className="col-md-3">
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="col-md-1 margin-top-30" style={{ marginLeft: "250px" }}>
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                      </div> */}
                                                                </div>

                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                        &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                        <div className="fixTableHeadTnadetail">
                            <Modal.Header closeButton>
                                <Modal.Title>Show Hide Header</Modal.Title>
                            </Modal.Header>
                            {/* , overflowY: "auto" */}
                            <Modal.Body className="customdialogbody">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="row">
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(1)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.insCreatedDate}
                                                        disabled
                                                    />
                                                    &nbsp;<label htmlFor="podate">PO Date</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(21)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="podate"
                                                        checked={getHideID.finalInspectionID}
                                                    />
                                                    &nbsp;<label htmlFor="skuid">SKU ID</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(2)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="season"
                                                        checked={getHideID.season}
                                                    />
                                                    &nbsp;<label htmlFor="season">Season</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(3)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.Quarter}
                                                    />
                                                    &nbsp;<label htmlFor="quarter">Quarter</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(4)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.brand}
                                                    />
                                                    &nbsp;<label htmlFor="brand">Brand</label>
                                                </li>
                                            </div> */}
                                                {/* <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onClick={() => CheckBoxHandler(5)}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="quarter"
                                                        checked={getHideID.customer}
                                                    />
                                                    &nbsp;<label htmlFor="customer">Customer</label>
                                                </li>
                                            </div> */}
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CreatedDate"
                                                            checked={checkboxStates.CreatedDate}

                                                        />
                                                        &nbsp;<label htmlFor="CreatedDate">Created Date </label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="EntryNo"
                                                            checked={checkboxStates.EntryNo}
                                                        />
                                                        &nbsp;<label htmlFor="EntryNo">Entry No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="TypeInterOrExtern"
                                                            checked={checkboxStates.TypeInterOrExtern}
                                                        />
                                                        &nbsp;<label htmlFor="TypeInterOrExtern">Internal / External</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionType"
                                                            checked={checkboxStates.InspectionType}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionType">Inspection Type</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="ReInspectionDoneAgainst"
                                                            checked={checkboxStates.ReInspectionDoneAgainst}
                                                        />
                                                        &nbsp;<label htmlFor="ReInspectionDoneAgainst">Re Inspection Done</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Brand"
                                                            checked={checkboxStates.Brand}
                                                        />
                                                        &nbsp;<label htmlFor="Brand">Brand</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Season"
                                                            checked={checkboxStates.Season}
                                                        />
                                                        &nbsp;<label htmlFor="Season">Season</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Supplier"
                                                            checked={checkboxStates.Supplier}
                                                        />
                                                        &nbsp;<label htmlFor="Supplier">Supplier</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="Factory"
                                                            checked={checkboxStates.Factory}
                                                        />
                                                        &nbsp;<label htmlFor="Factory">Factory</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CustomerShipmentRefNo"
                                                            checked={checkboxStates.CustomerShipmentRefNo} />
                                                        &nbsp;<label htmlFor="CustomerShipmentRefNo">Customer Shipment Ref No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="IDNo"
                                                            checked={checkboxStates.IDNo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="IDNo">PO/ID No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="StyleName"
                                                            checked={checkboxStates.StyleName}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="StyleName">Style Name</label>
                                                    </li>
                                                </div>


                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="StyleNo"
                                                            checked={checkboxStates.StyleNo}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="StyleNo">Style No</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="SKUName"
                                                            checked={checkboxStates.SKUName}
                                                        />
                                                        &nbsp;
                                                        <label htmlFor="SKUName">SKU Name</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionQuantity"
                                                            checked={checkboxStates.InspectionQuantity}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionQuantity">Inspection Quantity</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="InspectionResult"
                                                            checked={checkboxStates.InspectionResult}
                                                        />
                                                        &nbsp;<label htmlFor="InspectionResult">Inspection Result</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="CreatedBy"
                                                            checked={checkboxStates.CreatedBy}
                                                        />
                                                        &nbsp;<label htmlFor="CreatedBy">Created By</label>
                                                    </li>
                                                </div>
                                                <div className="col-lg-4">
                                                    <li
                                                        className="listmodal"
                                                        onChange={handleCheckboxChange}>
                                                        <input
                                                            className="hidecheckbox"
                                                            type="checkbox"
                                                            name="ApprovalResult"
                                                            checked={checkboxStates.ApprovalResult}
                                                        />
                                                        &nbsp;<label htmlFor="ApprovalResult">Approval Result</label>
                                                    </li>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-xs btn-danger" onClick={handleClose}><i className="fa fa-times"></i>&nbsp;Close</button>
                            </Modal.Footer>
                        </div>
                    </Modal>
                    <div className="row">
                        <div className="col-lg-11">
                        </div>

                        {commentsData.length !== 0 && (
                            <span>
                                <span className="btn btn-warning pdficon" title="Export to PDF" columns={columnss}
                                    data={PdfValues}
                                    onClick={() => DownloadPDF()}
                                >
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                </span>
                                &nbsp;
                                <ReactHTMLTableToExcel
                                    id="test-table-s-button-final"
                                    className="fa fa-file-excel-o  btn btn-success excelcomt"
                                    table={"table-to-xlss"}
                                    filename={"InspectionEntryList"}
                                    sheet="InspectionEntry"
                                    buttonText=""
                                />
                            </span>
                        )}&nbsp;
                        <button style={{ marginTop: '8px', marginBottom: '7px' }} title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>
                        {/* <label style={{ marginTop: '7px', marginLeft: "34px" }} title={isAllSelected ? "Un Select All" : "Select All"}>

                            <input type="checkbox" name="IsAllSelected"
                                onChange={event => (handleAllOnSelect(event, 'IsAllSelected', ITEMS_PER_PAGE, totalItems))}
                                value={isAllSelected ? true : false}
                                checked={isAllSelected ? true : false}
                                className="selectallcheckbox"
                            />
                        </label> */}

                        <div className="col-md-12">
                            <div style={{ overflow: "auto" }}>
                                <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "125vm" }}>
                                    <TableHeader headers={tableHeader}
                                        onSorting={
                                            (field, order) => setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {
                                            commentsData.map((comment, i) => (
                                                <Fragment key={i}>
                                                    <tr key={comment.finalInspectionID}>
                                                        <td style={{ width: '25px' }}>{i + 1}</td>
                                                        {
                                                            checkboxStates.CreatedDate && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    // className="stickyone "
                                                                    title={comment.insCreatedDate}>
                                                                    {comment.insCreatedDate}

                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.EntryNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={`${comment.typeInterOrExtern === 1 ? 'IN' : 'EX'} - ${comment.inspectionType === 'Re Inspection' ? 'Re' : 'New'} - ${comment.finalInspectionID}`}>
                                                                    {comment.typeInterOrExtern === 1 ? 'IN' : 'EX'} - {comment.inspectionType === 'Re Inspection' ? 'Re' : 'New'} - {comment.finalInspectionID}
                                                                </td>
                                                            )
                                                        }




                                                        {/* {
                                                        checkboxStates.InspectionType && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionType}>
                                                                {comment.inspectionType}
                                                            </td>
                                                        )

                                                    } */}
                                                        {/* {
                                                        checkboxStates.TypeInterOrExtern && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.typeInterOrExtern}>

                                                            </td>
                                                        )
                                                    } */}


                                                        {/* {
                                                        checkboxStates.ReInspectionDoneAgainst && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.reInspectionAgainst}>
                                                                {comment.reInspectionAgainst === 0 || comment.reInspectionAgainst === null ? '-' : comment.reInspectionAgainst}
                                                            </td>
                                                        )

                                                    } */}
                                                        {/* {
                                                        checkboxStates.Buyer && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.buyerName}>
                                                                {comment.buyerName}
                                                            </td>
                                                        )

                                                    }
                                                    {
                                                        checkboxStates.Brand && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.brandName}>
                                                                {comment.brandName}
                                                            </td>
                                                        )

                                                    }
                                                    {
                                                        checkboxStates.Season && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.seasonName}>
                                                                {comment.seasonName}
                                                            </td>
                                                        )
                                                    } */}

                                                        {
                                                            checkboxStates.Supplier && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "

                                                                    title={`${comment.supplierName} - ${comment.factory}`}>

                                                                    {comment.supplierName} / {comment.factory}
                                                                </td>
                                                            )
                                                        }

                                                        {/* {
                                                        checkboxStates.Factory && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                className="stickytwo "
                                                                title={'Factory'}>

                                                                {comment.factory}
                                                            </td>
                                                        )
                                                    } */}

                                                        {
                                                            checkboxStates.CustomerShipmentRefNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'CustomerShipment Ref'}>
                                                                    {/* {"-"} */}
                                                                    {comment.customerShipmentRefNo}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.StyleName && (
                                                                <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.SKUName && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.skuName}>
                                                                    {comment.skuName}
                                                                    <span>
                                                                        <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }} onClick={() => GetSKUandQty(comment.finalInspectionID)}
                                                                        >
                                                                            <i className="fa fa-eye"></i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.IDNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {/* {"-"} */}
                                                                    {comment.idpono}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            // checkboxStates.IDNo && (
                                                            //     <td
                                                            //         style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                            //         //className="stickytwo "
                                                            //         title={'FO PO ID No'}>
                                                            //         {/* {"-"} */}
                                                            //         {comment.foidpono === '0' ? '-' : comment.foidpono}
                                                            //     </td>
                                                            // )
                                                        }


                                                        {/*
                                                    {
                                                        checkboxStates.StyleNo && (
                                                            <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                        )
                                                    } */}

                                                        {
                                                            checkboxStates.InspectionQuantity && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionQty}>
                                                                    {"-"}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionQuantity && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionQty}>
                                                                    {comment.inspectionQty}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionResult}>
                                                                    <b className={comment.inspectionResult === 'Pass' ? 'success' : comment.inspectionResult === 'Fail' ? 'danger' : comment.inspectionResult === 'Saved' || comment.inspectionResult === 'Saved Without Defect' ? 'blue' : 'warning'}>
                                                                        {comment.inspectionResult}
                                                                    </b>
                                                                </td>
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.CreatedBy && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.createdBy}>
                                                                {comment.createdBy}
                                                            </td>
                                                        )
                                                    } */}
                                                        {
                                                            checkboxStates.ApprovalResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.approvalResult}>
                                                                    <span className={comment.approvalResult === 'Approved' ? 'success' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                                </td >
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.ApprovalResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.approvalResult}>
                                                                <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                            </td >
                                                        )
                                                    } */}

                                                        {< td className="text-center fixed-column-header-CommentReport" style={{
                                                            width: "9%", display: InternalComments && InternalComments.isView === 0 && InternalComments.isAdd === 0 && InternalComments.isEdit === 0 &&
                                                                InternalComments.isDelete === 0 ? 'none' : ''
                                                        }}>
                                                            {/* <span style={{ padding: '2px' }}>
                                                            <span className="btn" title="Approved" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments')}
                                                                style={{
                                                                    background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                    color: 'white',
                                                                    padding: '3px 6px',
                                                                    borderRadius: '1px',
                                                                    border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                }}
                                                            >
                                                                <i className="fa fa-check"></i>
                                                            </span>
                                                        </span> */}

                                                            < span >



                                                                <button type="button"
                                                                    className={(comment.inspectionResult === 'Saved' || comment.inspectionResult === 'Saved Without Defect' || comment.inspectionResult === 'Draft') && comment.approvalResult === null ? "DPNone" : comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                    style={{ display: InternalComments && InternalComments.isAdd === 0 ? 'none' : '' }}
                                                                    // className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                    onClick={() => GetComments(comment, 'Comments', comment.approvalResult)}>
                                                                    <i className="fa fa-check"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                    style={{ display: InternalComments && InternalComments.isView === 0 ? 'none' : '' }}
                                                                    onClick={() => handleFinalInspecView(comment, 'View', '')}>
                                                                    <i className="fa fa-eye "></i>
                                                                </button>
                                                                &nbsp;

                                                                <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-success" : "DPNone"} title='Edit FinalList'
                                                                    style={{ display: InternalComments && InternalComments.isEdit === 0 ? 'none' : '' }}
                                                                    onClick={() => handleFinalInspecView(comment, 'Edit', 'EditInspection')}>
                                                                    <i className="fa fa-edit"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-danger" : "DPNone"} title='Delete FinalList'
                                                                    style={{ display: InternalComments && InternalComments.isDelete === 0 ? 'none' : '' }}
                                                                    onClick={() => handleDeletefinalinspection(comment.inspectionType, comment.finalInspectionID, 'delete')}
                                                                >
                                                                    <i className="fa fa-trash-o"></i>
                                                                </button>
                                                                &nbsp;
                                                                {(comment.buyerName === 'Focus International' || comment.buyerName === 'Steiff' || comment.buyerName === 'JD Sports') &&

                                                                    <button type="button" className="btn btn-success" title='Export to Excel'
                                                                        style={{ display: InternalComments && InternalComments.isView === 0 ? 'none' : '' }}
                                                                        onClick={() => ExporttoExcel(comment.finalInspectionID, comment.tnaid, comment.styleID, comment.buyerName)}
                                                                    >
                                                                        <i class="fa fa-file-excel-o"></i>

                                                                    </button>
                                                                }
                                                                &nbsp;
                                                                <button className="btn btn-xs btn-magenta" title="PDF" onClick={(e) => handlepdffunction(comment)}><i className="fa fa-file-pdf-o"></i></button>&nbsp;
                                                                {/* &nbsp;
                                                            <button type="button" className="btn btn-success" title='Export to Excel JD'
                                                                onClick={() => ExporttoExcelJD(comment.finalInspectionID, comment.tnaid, comment.styleID)}
                                                            >
                                                                <i class="fa fa-file-excel-o"></i>

                                                            </button>
                                                            &nbsp;
                                                            <button type="button" className="btn btn-success" title='Export to Excel Sterif'
                                                                onClick={() => ExporttoExcelSte(comment.finalInspectionID, comment.tnaid, comment.styleID)}
                                                            >
                                                                <i class="fa fa-file-excel-o"></i>

                                                            </button> */}
                                                                {/* <a href='D:\TFS_Source\ICube_RApi\Dev-v01\Downloads\FinalInsExport\FocusInternationalFinalInspectionReport_609.xlsx'></a> */}



                                                            </span >



                                                        </td>}
                                                    </tr>
                                                </Fragment>
                                            ))

                                        }

                                        {
                                            Settingpopupopen === true ?

                                                <Modal show={Settingpopupopen === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setSettingpopupopen(false)}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Report PDF
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body style={{ overflow: "auto", height: "25vh" }} align="center">
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={2} style={{ width: "350px" }}>DetailName</th>
                                                                    {/* <th style={{ width: "160px" }}></th> */}
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    settings.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.Name}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                            <input type="checkbox" name="isSelected"
                                                                                                onChange={event => (handleSettingChange(event, index))}
                                                                                                value={item.isSelected}
                                                                                                checked={item.isSelected}
                                                                                                className="clearOpacity textdesign" />
                                                                                            <span className="text"></span>
                                                                                        </label>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className='btn btn-success' onClick={() => {
                                                            setTimeout(() => {
                                                                generatePDF();
                                                            }, 500);  // Delay of 500ms
                                                        }}>Generate PDF</button>
                                                    </Modal.Footer>

                                                </Modal> : ''
                                        }
                                        {
                                            sizeqtypopup === true ?

                                                <Modal show={sizeqtypopup === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setsizeqtypopup(false)}>
                                                    {/* <Modal.Header closeButton>
                                                      <Modal.Title>Size and Qty </Modal.Title>

                                                  </Modal.Header> */}

                                                    <Modal.Header closeButton>
                                                        <Modal.Title>SKU & Qty
                                                        </Modal.Title>


                                                    </Modal.Header>
                                                    <div className="row" style={{ padding: "0px 15px 0px 20px" }}>

                                                        {/* <div className="col-lg-4"> <h5>SKU ID : {getskuid.skuID}</h5></div>
                                                        <div className="col-lg-6">

                                                        </div>
                                                        <div className="col-lg-2">
                                                            <h5>Total : {getskuid.qrty}</h5>
                                                        </div>*/}

                                                    </div>

                                                    <Modal.Body style={{ overflow: "auto", height: "50vh" }} align="center">
                                                        <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "350px" }}>PO-SKU ID</th>
                                                                    <th style={{ width: "350px" }}>SKU</th>
                                                                    <th style={{ width: "160px" }}>Qty</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    skuqtylist.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.purchaseOrderSkuID}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.skuName}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="input-icon icon-right">
                                                                                            {item.offeredQty}
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <button className="btn btn-xs btn-danger" onClick={() => setsizeqtypopup(false)}><i className="fa fa-times"></i>&nbsp;Close</button>
                                                    </Modal.Footer>

                                                </Modal> : ''
                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }

                                    </tbody >

                                    <table data-role="table" data-mode="columntoggle" id="table-to-xlss"
                                        className="table table-striped table-bordered table-hover dataTable no-footer"
                                        style={{ width: "200%", display: 'none' }}>
                                        <thead>
                                            {
                                                <div style={{ display: 'inline-block' }}>
                                                    {
                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                            <>
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                                < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            </>


                                                            :

                                                            (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                                <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                                :

                                                                (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                                    < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                        ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                            moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''

                                                    }
                                                </div>
                                            }
                                            <tr>
                                                {checkboxStates.CreatedDate === true ? <th style={{ width: '3%' }}> Created Date </th> : false}
                                                {checkboxStates.EntryNo === true ? <th style={{ width: '3%' }}> Entry No </th> : false}
                                                {checkboxStates.InspectionType === true ? <th style={{ width: '5%' }}> Inspection Type</th> : false}
                                                {checkboxStates.TypeInterOrExtern === true ? <th style={{ width: '3%' }}> Interal/External </th> : false}
                                                {checkboxStates.ReInspectionDoneAgainst === true ? <th style={{ width: '6%' }}> Re Inspection Done Against</th> : false}
                                                {checkboxStates.Brand === true ? <th style={{ width: '4%' }}> Brand</th> : false}
                                                {checkboxStates.Season === true ? <th style={{ width: '3%' }}> Season</th> : false}
                                                {checkboxStates.Supplier === true ? <th style={{ width: '3%' }}> Supplier </th> : false}
                                                {checkboxStates.Factory === true ? <th style={{ width: '4%' }}> Factory</th> : false}
                                                {checkboxStates.CustomerShipmentRefNo === true ? <th style={{ width: '6%' }}>Customer Shipment Ref No</th> : false}
                                                {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}> PO/ID No</th> : false}
                                                {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}> FOPO No</th> : false}
                                                {checkboxStates.StyleName === true ? <th style={{ width: '7%' }}> Style Name</th> : false}
                                                {checkboxStates.StyleNo === true ? <th style={{ width: '5%' }}>Style No</th> : false}
                                                {checkboxStates.SKUName === true ? <th style={{ width: '3% ' }}> SKU Name</th> : false}
                                                {checkboxStates.InspectionQuantity === true ? <th style={{ width: '4%' }}> Inspection Quantity</th> : false}
                                                {checkboxStates.InspectionResult === true ? <th style={{ width: '3%' }}>Inspection Result</th> : false}
                                                {checkboxStates.CreatedBy === true ? <th style={{ width: '4%' }}>Created By</th> : false}
                                                {checkboxStates.ApprovalResult === true ? <th style={{ width: '4%' }}> Approval Result</th> : false}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commentsData.map(comment => (
                                                    <tr key={comment.finalInspectionID}>

                                                        {
                                                            checkboxStates.CreatedDate && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    // className="stickyone "
                                                                    title={comment.insCreatedDate}>
                                                                    {comment.insCreatedDate}

                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.EntryNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.finalInspectionID}>
                                                                    {comment.finalInspectionID}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.InspectionType && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionType}>
                                                                    {comment.inspectionType}
                                                                </td>
                                                            )

                                                        }
                                                        {
                                                            checkboxStates.TypeInterOrExtern && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.typeInterOrExtern}>
                                                                    {comment.typeInterOrExtern === 1 ? 'Internal' : 'External'}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.ReInspectionDoneAgainst && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.reInspectionAgainst}>
                                                                    {(comment.reInspectionAgainst === 0) ? '-' : comment.reInspectionAgainst}
                                                                </td>
                                                            )

                                                        }
                                                        {
                                                            checkboxStates.Brand && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.brandName}>
                                                                    {comment.brandName}
                                                                </td>
                                                            )

                                                        }
                                                        {
                                                            checkboxStates.Season && (

                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.seasonName}>
                                                                    {comment.seasonName}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.Supplier && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.supplierName}>
                                                                    {comment.supplierName}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.Factory && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'Factory'}>
                                                                    {comment.factory}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.CustomerShipmentRefNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'CustomerShipment Ref'}>
                                                                    {comment.customerShipmentRefNo}
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.IDNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {comment.idpono}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.IDNo && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={'PO/ID No'}>
                                                                    {comment.foidpono}
                                                                </td>
                                                            )
                                                        }



                                                        {
                                                            checkboxStates.StyleName && (
                                                                <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.StyleNo && (
                                                                <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.SKUName && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.skuName}>
                                                                    {comment.skuName}
                                                                    <span>
                                                                        <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }} onClick={() => GetSKUandQty(comment.finalInspectionID)}
                                                                        >
                                                                            <i className="fa fa-eye"></i>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            )
                                                        }

                                                        {
                                                            checkboxStates.InspectionQuantity && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionQty}>
                                                                    {comment.inspectionQty}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.InspectionResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.inspectionResult}>
                                                                    {comment.inspectionResult}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.CreatedBy && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    //className="stickytwo "
                                                                    title={comment.createdBy}>
                                                                    {comment.createdBy}
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            checkboxStates.ApprovalResult && (
                                                                <td
                                                                    style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                    title={comment.approvalResult}>
                                                                    <span className={comment.approvalResult === 'Approved' ? 'green' : comment.approvalResult === 'Commercially Approved' ? 'text-warning' : 'red'}>
                                                                        <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b>
                                                                    </span>
                                                                </td >
                                                            )
                                                        }
                                                        {/* {
                                                        checkboxStates.ApprovalResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.approvalResult}>
                                                                <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                            </td >
                                                        )
                                                    } */}

                                                        {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%", display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}>
                                                            {/* <span style={{ padding: '2px' }}>
                                                            <span className="btn" title="Approved" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments')}
                                                                style={{
                                                                    background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                    color: 'white',
                                                                    padding: '3px 6px',
                                                                    borderRadius: '1px',
                                                                    border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                }}
                                                            >
                                                                <i className="fa fa-check"></i>
                                                            </span>
                                                        </span> */}
                                                        </td>}
                                                    </tr>
                                                ))

                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }

                                        </tbody>
                                    </table>
                                </table >
                                {
                                    ShowMsrtabl === true ?
                                        getMessureValue.map((comment, index) => (
                                            <div className="row">
                                                <div>


                                                    {comment.SkuName}


                                                    <div id={index} className="" >
                                                        <div className="">
                                                            {
                                                                isStyleMeasurementempty === false ?
                                                                    Measurementdata.map((item, subindex) => (

                                                                        // item.SkuName === comment.SkuName &&
                                                                        <Fragment key={`${item}~${subindex}`}>
                                                                            <div id="" className="panel-body border-red">
                                                                                <table className="table table-bordered" id="MesurementTbl">

                                                                                    <tbody>

                                                                                        {
                                                                                            item.Category.filter(c => c.SkuName === comment.SkuName).map((Catitem, catIndex) => {
                                                                                                return (
                                                                                                    <>

                                                                                                        <tr>
                                                                                                            <td>

                                                                                                                <span className="BuyerPaddingLeft">
                                                                                                                    Sku Name / Category :  {
                                                                                                                        Catitem.SkuName
                                                                                                                    }
                                                                                                                    /
                                                                                                                    {
                                                                                                                        Catitem.CategoryTypeName
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                        <>
                                                                                                            {

                                                                                                                <tr>
                                                                                                                    <td colSpan="150">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-12">
                                                                                                                                <div className="dataTables_wrapper no-footer" style={{ width: '99%', overflowX: "auto" }}>
                                                                                                                                    {
                                                                                                                                        Catitem.Point.length > 0 ?
                                                                                                                                            <>

                                                                                                                                                {

                                                                                                                                                    <table className="table table-bordered" >
                                                                                                                                                        <thead className="text-center">

                                                                                                                                                            <tr>

                                                                                                                                                                <th style={{ width: "23%" }} className="word_warp_padding text-center ">
                                                                                                                                                                    Points
                                                                                                                                                                </th>

                                                                                                                                                                <th className="text-center ">Tol +</th>

                                                                                                                                                                {/* <th className="text-center ">Req</th> */}
                                                                                                                                                                {
                                                                                                                                                                    Catitem.SizeHeaderNames.filter(x => x.SkuName === comment.SkuName).map((size, i) => {
                                                                                                                                                                        return (
                                                                                                                                                                            // <th className="text-center ">{size.label}</th>
                                                                                                                                                                            // Size === size.label ? <span className="text-danger">*</span> : ''
                                                                                                                                                                            <th className="text-center ">
                                                                                                                                                                                <span>{size.HeaderName}
                                                                                                                                                                                </span>
                                                                                                                                                                            </th>
                                                                                                                                                                        )

                                                                                                                                                                    })
                                                                                                                                                                }


                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            {


                                                                                                                                                                Catitem.Point.filter(p => p.SkuName === comment.SkuName).map((Pointitem, pointIndex) => {
                                                                                                                                                                    // Catitem.Point.map((Pointitem, pointIndex) => {
                                                                                                                                                                    return (
                                                                                                                                                                        <>

                                                                                                                                                                            <tr key={pointIndex}>

                                                                                                                                                                                <td className='' >
                                                                                                                                                                                    {Pointitem.MeasurementPoints}
                                                                                                                                                                                </td>

                                                                                                                                                                                <td className=''>
                                                                                                                                                                                    {Pointitem.TolerancePlus}
                                                                                                                                                                                </td>



                                                                                                                                                                                {
                                                                                                                                                                                    Catitem.SizeHeaderNames.filter(x => x.SkuName === comment.SkuName &&
                                                                                                                                                                                        x.CategoryRowNo === Catitem.CategoryRowNo).map((sizeval, i) => {
                                                                                                                                                                                            return (
                                                                                                                                                                                                <td>
                                                                                                                                                                                                    {
                                                                                                                                                                                                        Pointitem.arrGradingSizes[0][sizeval.HeaderName] !== '0' ? Pointitem.arrGradingSizes[0][sizeval.HeaderName] : ''
                                                                                                                                                                                                    }
                                                                                                                                                                                                </td>

                                                                                                                                                                                            );
                                                                                                                                                                                        })
                                                                                                                                                                                }


                                                                                                                                                                            </tr>
                                                                                                                                                                        </>

                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            }
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                {

                                                                                                                                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                                                                                                                                        <thead className="text-center">
                                                                                                                                                            <tr>
                                                                                                                                                                <th className="word_warp_padding text-center " >Points</th>
                                                                                                                                                                <th className="text-center ">Grading</th>
                                                                                                                                                                <th className="text-center ">Tol +</th>
                                                                                                                                                                <th className="text-center ">Tol -</th>

                                                                                                                                                                <th className="text-center ">Remarks</th>
                                                                                                                                                                <th className="text-center Actionbutton fixed-column-header">Action</th>
                                                                                                                                                            </tr>
                                                                                                                                                        </thead>
                                                                                                                                                        <tbody>
                                                                                                                                                            <tr><td colSpan="6" className='norecordfound'><span>No Records Found</span></td></tr>
                                                                                                                                                        </tbody>
                                                                                                                                                    </table>


                                                                                                                                                }

                                                                                                                                            </>
                                                                                                                                    }

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            }
                                                                                                        </>
                                                                                                    </>
                                                                                                )

                                                                                            })
                                                                                        }

                                                                                    </tbody>
                                                                                </table>



                                                                            </div>
                                                                        </Fragment>
                                                                    ))
                                                                    : <div className='form-group' style={{ marginLeft: "18px" }}>
                                                                        <br />
                                                                        <span className="widget-header"
                                                                            style={{ color: 'red', fontSize: '14px' }}>*No Measurement Data Found</span>

                                                                    </div>
                                                            }

                                                        </div >



                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                        : ''
                                }

                            </div >
                            {
                                Commentpopup === true ?
                                    // style={{ height: "25%" }}
                                    <Modal show={Commentpopup === true}
                                        // dialogAs={Drag} 
                                        size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullClose('reportcmt', Ecomment)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Final Inspection Approval
                                                {/* &nbsp;( Entry No : {getheaderName[0].FinalInspectionID + ' - ' + 'Date : ' + getheaderName[0].InsCreatedDate}) */}

                                            </Modal.Title>
                                            {
                                                getFIRemark.length >= 1 ?
                                                    <button style={{ marginLeft: "30rem" }} type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                        onClick={() => handleDeletefinalinsPopup(getheaderName[0].FinalInspectionID)}
                                                    >
                                                        <i className="fa fa-trash-o"></i>
                                                    </button>
                                                    : ''
                                            }
                                        </Modal.Header>
                                        {
                                            inspectionstatus === null &&
                                            <>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Inspection Report No & Date</th>
                                                            <th>Inspection Approval Date<span className="text-danger">*</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input type="text" name="ItemName"
                                                                    id="ItemName"
                                                                    value={getheaderName[0].FinalInspectionID + ' - ' + getheaderName[0].InsCreatedDate}
                                                                    maxLength="25"
                                                                    className={
                                                                        'form-control'
                                                                    }
                                                                    autoFocus
                                                                    placeholder="Enter Item Name"
                                                                />
                                                            </td>
                                                            <td>
                                                                <span className="input-icon icon-right"
                                                                    style={{ border: Submitted && !getQARemark.ReceivedDate ? '1px solid red' : '' }}>
                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "ReceivedDate"
                                                                        }
                                                                        selected={
                                                                            getQARemark.ReceivedDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeQARemarks(event, "ReceivedDate", 1)
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={getQARemark.ReceivedDate === null ? false : true}
                                                                    />

                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" style={{ marginLeft: "14px", width: "97%" }}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th>Approval Date<span className="text-danger">*</span></th> */}
                                                            {/* <th>Result<span className="text-danger">*</span></th> */}
                                                            <th>Approval Remark<span className="text-danger">*</span></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {/* <td >
                                                                <Reactselect
                                                                    options={getApprovalList}
                                                                    value={getSelectedApproval}
                                                                    onChange={handleCmtEXFactoryChanges}
                                                                    styles={Submitted && getSelectedApproval === undefined || getSelectedApproval === null ? styles : ''}

                                                                />
                                                            </td> */}
                                                            <td colSpan='2'>
                                                                <textarea
                                                                    rows="4"
                                                                    placeholder="Enter comments here..."
                                                                    autoFocus onFocus={onFocus} autoComplete="off"
                                                                    maxLength="500" name="EnterComment"
                                                                    id={"EnterComment"}
                                                                    value={Ecomment}
                                                                    onChange={event => handleChangeComment(event, "reportComment")}
                                                                    style={{ width: "100%", border: Submitted && (Ecomment === undefined || Ecomment === '') ? '1px solid red' : '' }}>
                                                                </textarea>
                                                            </td>

                                                        </tr >

                                                    </tbody >
                                                </table >
                                                <span><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Approval Result<span className="text-danger">*</span></label></span>
                                                <br />

                                                <div>
                                                    <div className="col-lg-12">
                                                        <div className="col-sm-4">
                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getSelectedApproval === 1 ? '#53a93f' : '', color: getSelectedApproval === 1 ? 'white' : '#444444' }}
                                                                onClick={event => { handleCmtEXFactoryChanges(1) }}>Approved</button>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getSelectedApproval === 2 ? '#f4b400' : '', color: getSelectedApproval === 2 ? 'white' : '#444444' }}
                                                                onClick={event => { handleCmtEXFactoryChanges(2) }}>Commercially Approved</button>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getSelectedApproval === 3 ? '#d73d32' : '', color: getSelectedApproval === 3 ? 'white' : '#444444' }}
                                                                onClick={event => { handleCmtEXFactoryChanges(3) }}>Rejected</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                <thead>
                                                    <tr>

                                                        <th>Approval Date</th>
                                                        <th>Results</th>
                                                        <th>Approval Remark</th>
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        getFIRemark.length === 0 ?
                                                            <tr>
                                                                <td colSpan="4" className='norecordfound'><span>No Records Found</span></td>
                                                            </tr> :
                                                            getFIRemark.map((Commentitem) => {
                                                                return (

                                                                    <tr>
                                                                        <td>
                                                                            {Commentitem.createdDate}
                                                                        </td>
                                                                        <td>
                                                                            {Commentitem.approvalName}
                                                                        </td>

                                                                        <td>
                                                                            {Commentitem.approvalRemarks}
                                                                        </td>
                                                                        <td>
                                                                            {Commentitem.createdBy}
                                                                        </td>

                                                                    </tr>

                                                                );
                                                            })
                                                    }
                                                </tbody>
                                            </table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                inspectionstatus === null &&

                                                <Button variant="success" onClick={(event) => handleSaveApproval(event, Ecomment, getheaderName[0].FinalInspectionID)}>
                                                    Save
                                                </Button>
                                            }

                                        </Modal.Footer>
                                    </Modal >
                                    : ''
                            }



                        </div >


                    </div >

                </div >

                <PageCount onPageCount={
                    value => {
                        setITEMS_PER_PAGE(value);
                        setCurrentPage(1);
                    }
                } />
                <Pagination total={totalItems}
                    itemsPerPage={
                        parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                        page => setCurrentPage(page)
                    } />
                {/* {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={delCallback}
                        showpopup={true}
                        pageActionId={FLReportSkuComments}
                        Msg={"Final Inspection details deleted successfully"} /> : null
                } */}
                {/* {
                    getIDSingleTask.showPopupDelete ? <SweetAlertPopup data={
                        getIDSingleTask.Params
                    }
                        deleteCallback={delCallbackfinalins}
                        showpopup={true}
                        pageActionId={FinalinspectionID}
                        Msg={"Final Inspection deleted successfully"} /> : null
                } */}
                {
                    getIDfinalEntry.showPopupDelete ? <SweetAlertPopup data={
                        getIDfinalEntry.Params
                    }
                        deleteCallback={delCallbackfinalins}
                        showpopup={true}
                        pageActionId={FinalinspectionID}
                        Msg={"Final Inspection deleted successfully"} /> : null
                }
                {
                    getIDcomment.showPopupDelete ? <SweetAlertPopup data={
                        getIDcomment.Params
                    }
                        deleteCallback={delApprovalCallback}
                        showpopup={true}
                        pageActionId={FLApprovalComment}
                        Msg={"Final Inspection Approval comment deleted successfully"} /> : null
                }

            </div >
            {loader}



        </div >
    )
}

export default FinalInspectionEntry
function MargeCellGrid(sheet, mergeCells, FirstCellsLabel, product, fontName, fontColor, fontSize, headerColor, fontHeaderColor) {
    sheet.mergeCells(mergeCells);
    sheet.getCell(FirstCellsLabel).value = product;
    sheet.getCell(FirstCellsLabel).font = {
        name: fontName,
        color: { argb: fontHeaderColor },
        //family: 2,
        size: fontSize,
        italic: true
    };

    sheet.getCell(mergeCells).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: headerColor },
    };
}

function MargeCellGridHeader(sheet, mergeCells, FirstCellsLabel, product, fontName, fontHeaderColor, fontSize, headerColor) {
    sheet.mergeCells(mergeCells);
    sheet.getCell(FirstCellsLabel).value = product;


    sheet.getCell(FirstCellsLabel).font = {
        name: fontName,
        color: { argb: fontHeaderColor },
        //family: 2,
        size: fontSize,
        italic: true
    };

    sheet.getCell(mergeCells).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: headerColor },
    };
}

