import {
    PRODUCTFABRICTYPE_LOADING,
    RETRIEVE_PRODUCTFABRICTYPE,
    DELETE_PRODUCTFABRICTYPE,
} from "../actions/types";


const initialState = {
    isLoadingProductFabricType: true,
    ProductfabricTypeList: [],
};

const ProductfabricTypeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PRODUCTFABRICTYPE_LOADING:
            return {
                ...state,
                isLoadingProductFabricType: payload
            };
        case RETRIEVE_PRODUCTFABRICTYPE:
            return { ...state, ProductfabricTypeList: payload };
        case DELETE_PRODUCTFABRICTYPE:
            const results = state.ProductfabricTypeList.filter(c => c.fabricDetailsID !== payload.fabricDetailsID);
            return {
                ...state,
                ProductfabricTypeList: results
            };

        default:
            return state;
    }
};
export default ProductfabricTypeReducer;