
import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, NavLink } from 'react-router-dom';
// import $ from "jquery";
import CommonService from "../../services/Common/CommonService";
import { logout, activeMenus } from "../../actions/auth";
import { loadMenu } from "../../actions/common";
import { useState } from "react";
import { usePath } from "hookrouter";
import Lightbox from "react-image-lightbox";
import { useHistory } from "react-router-dom";


const TopMenu = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const activeMenu = useSelector((state) => state.auth.activeMenuList);
    const dispatch = useDispatch();
    const [ParentMenuList, setParentMenuList] = useState([]);
    const path = usePath();
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    let menuList = useSelector((state) => state.auth.UserMenuList);
  
    useEffect(() => {
        setParentMenuList(menuList);
        try {
            if (path === '/') {
                history.push("/Dashboard");
                window.location.reload();
            }
            let childMenu = menuList.item2.filter(d => d.subUrlPath !== null && d.subUrlPath !== "");
            let activeInfo = childMenu.filter(d => d.subUrlPath.includes(path));
            if (activeInfo.length === 0) {

                for (let index = 0; index < menuList.item3.length; index++) {
                    if (menuList.item3[index].menuDisplayName === 'General Lab Dip') {
                        menuList.item3[index].menuDisplayName = 'Lab Dip';
                    }
                    else if (menuList.item3[index].menuDisplayName === 'General Strike Off List') {
                        menuList.item3[index].menuDisplayName = 'Strike Off';
                    }
                    else if (menuList.item3[index].menuDisplayName === 'General Trim List') {
                        menuList.item3[index].menuDisplayName = 'Trim';
                    }
                    else if (menuList.item3[index].menuDisplayName === 'Order Lab Dip') {
                        menuList.item3[index].menuDisplayName = 'Lab Dip';
                    }
                    else if (menuList.item3[index].menuDisplayName === 'Order Strike Off List') {
                        menuList.item3[index].menuDisplayName = 'Strike Off';
                    }
                    else if (menuList.item3[index].menuDisplayName === 'Order Trim List') {
                        menuList.item3[index].menuDisplayName = 'Trim';
                    }

                }

                let subChildMenu = menuList.item3.filter(d => d.subUrlPath !== null && d.subUrlPath !== "");

                activeInfo = subChildMenu.filter(d => d.subUrlPath.includes(path));
            }

            if (activeInfo[0].menuType === 2) {


                let menuInfo = menuList.item1.filter(d => d.menuID === activeInfo[0].mainMenuID);
                const ActiveMenus =
                {
                    ParentName: menuInfo[0].menuName,
                    ChildName: activeInfo[0].menuName,
                    SubChildName: '',
                    ParentDisplayName: menuInfo[0].menuDisplayName,
                    ChildDisplayName: activeInfo[0].menuDisplayName,
                    SubChildDisplayName: '',
                    ChildURLPath: activeInfo[0].urlPath,
                    SubChildURLPath: '',
                    IsProduction: menuInfo[0].menuDisplayName === 'Sampling' ? 1 : 2
                }
                dispatch(activeMenus(ActiveMenus));
            } else if (activeInfo[0].menuType === 3) {

                let childInfo = menuList.item2.filter(d => d.menuID === activeInfo[0].mainMenuID);
                let menuInfo = menuList.item1.filter(d => d.menuID === childInfo[0].mainMenuID);
                const ActiveMenus =
                {
                    ParentName: menuInfo[0].menuName,
                    ChildName: childInfo[0].menuName,
                    SubChildName: activeInfo[0].menuName,
                    ParentDisplayName: menuInfo[0].menuDisplayName,
                    ChildDisplayName: childInfo[0].menuDisplayName,
                    SubChildDisplayName: activeInfo[0].menuDisplayName,
                    ChildURLPath: childInfo[0].urlPath,
                    SubChildURLPath: activeInfo[0].urlPath,
                    IsProduction: menuInfo[0].menuDisplayName === 'Sampling' ? 1 : 2
                }
                // Stable page while reload the page
                let IsProduction = JSON.parse(localStorage.getItem("IsProduction"));
                if (IsProduction !== null && IsProduction !== undefined) {
                    if (IsProduction === 2 && ActiveMenus.ParentDisplayName === 'Sampling') {
                        ActiveMenus.IsProduction = IsProduction;
                        ActiveMenus.ParentDisplayName = "Production";
                        ActiveMenus.ParentName = "Production";
                        if(ActiveMenus.SubChildName!='GeneralTNAReport')
                            {
                                ActiveMenus.SubChildName = ActiveMenus.IsProduction === 2 ? `${'Production' + activeInfo[0].menuName}` : activeInfo[0].menuName;
                            }
                        if(IsProduction === 2 && ActiveMenus.SubChildName==='ProductionTrimList')
                            {
                                ActiveMenus.SubChildName = 'ProductionOrderTrimList';
                            }
                    }
                }
                dispatch(activeMenus(ActiveMenus));
            }


        } catch { }
        //  }
        // })
        // .catch(e => {
        //     console.log(e);
        // });
    }, []);

    const logOut = () => {
        dispatch(logout());
    };


    // const bodyClickHandler = (e) => {
    //
    //     if (e.target.className === "menu-text-childItem") {
    //         e.childNodes[0].classList.toggle('open');
    //     }
    //     // if (e.target.className === "menu-text-childItem") {
    //     //     e.nextElementSibling.classList.toggle('open');
    //     // }

    //     return false;
    // };

    const MenuItemIcon = (props) => {
        let menuclassName = "menu-text";
        if (activeMenu.ParentName === props.parentMenu || activeMenu.ParentName === 'Report' || activeMenu.ParentName === 'Master' || activeMenu.ParentName === 'General') {
            if (activeMenu.SubChildName === props.childMenu) {
                menuclassName = "menu-text active";
            } else if (activeMenu.SubChildName === props.subChildMenu) {
                menuclassName = "menu-text active";
            }
        }

        return (
            <NavLink to={props.href}>
                {/* <i className={props.iconattr}></i> */}
                <span className={menuclassName}>{props.text}</span>
            </NavLink>
        );
    };

    // const MenuItem = (props) => {
    //     let menuclassName = "menu-text";
    //     if (activeMenu.ParentName === "Dashboard") {
    //         menuclassName = "menu-text active";
    //     }

    //     return (
    //         activeMenu.ParentName === "Dashboard" &&
    //         <NavLink to="/Dashboard">
    //             <span className={menuclassName}>{props.text}</span>
    //         </NavLink>
    //     );
    // };

    const ParentMenuIcon = (props) => {
        let menuclassName = "menu-text";
        if (activeMenu.ParentName === props.menuName) {
            menuclassName = "menu-text active";
        }
        let parentItem = {};
        if (props.menuName === "Dashboard") {
            parentItem = {
                menuName: props.text,
                menuDisplayName: props.menuName,
                urlPath: props.urlPath,
            };
        }

        return (
            props.menuName === "Dashboard" ?
                <NavLink to="/Dashboard">
                    <span className={menuclassName} onClick={() => ParentMenuClick(parentItem, false)} >{props.text}</span>
                </NavLink> :
                <a className="menu-text" onClick={props.onMouseOver} >
                    <span className={menuclassName}>{props.text}</span>
                </a>
        );
    };

    const ParentMenu = (props) => {

        return (
            <a className="menu-dropdown" onClick={props.onMouseOver}>
                <span className="menu-text">{props.text}</span>
                {/* <i className="fa fa-angle-right menu-icon"></i> */}
            </a>
        );
    }

    const ParentMenuClick = (MenuInfo, IsExist) => {

        if (!IsExist) {
            const ActiveMenus =
            {
                ParentName: MenuInfo.menuName,
                ChildName: MenuInfo.menuName,
                SubChildName: '',
                ParentDisplayName: MenuInfo.menuDisplayName,
                ChildDisplayName: MenuInfo.menuDisplayName,
                SubChildDisplayName: '',
                ChildURLPath: MenuInfo.urlPath,
                SubChildURLPath: '',
                IsProduction: MenuInfo.menuDisplayName === 'Sampling' ? 1 : 2
            }
            dispatch(activeMenus(ActiveMenus));
        } else {
            const ActiveMenus =
            {
                ParentName: "Dashboard",
                ChildName: "Dashboard",
                SubChildName: '',
                ParentDisplayName: "Dashboard",
                ChildDisplayName: "Dashboard",
                SubChildDisplayName: '',
                ChildURLPath: "/Dashboard",
                SubChildURLPath: '',
                IsProduction: 0
            }
            dispatch(activeMenus(ActiveMenus));
            history.push("/Dashboard");
        }
    }
    const SubChildClick = (MenuInfo, ChildInfo, SubChildInfo) => {
       
        const ActiveMenus =
        {
            ParentName: MenuInfo.menuName,
            ChildName: ChildInfo.menuName,
            SubChildName: SubChildInfo.menuName,
            ParentDisplayName: MenuInfo.menuDisplayName,
            ChildDisplayName: ChildInfo.menuDisplayName,
            SubChildDisplayName: SubChildInfo.menuDisplayName,
            ChildURLPath: ChildInfo.urlPath,
            SubChildURLPath: SubChildInfo.urlPath,
            IsProduction: MenuInfo.menuDisplayName === 'Sampling' ? 1 : 2
        }
        dispatch(activeMenus(ActiveMenus));
        localStorage.setItem('IsProduction', ActiveMenus.IsProduction)

        // Clear the local storage when the back button is clicked
        localStorage.removeItem("InspectionID");
        localStorage.removeItem("TestingInspectionID");
        localStorage.removeItem("QCInspectionID");
        localStorage.removeItem("Search");
        localStorage.removeItem("value");
        localStorage.removeItem("Ischecked");
        localStorage.removeItem("CompanySubbuyID");
        localStorage.removeItem('Path')

    }

    //let none = "none";
    //let noneBlock = "block";

    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    return (


        <header class="navbar navbar-default navbar-static-top">
            <div class="container-fluid">
                <div class="navbar-header">

                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" onClick={() => ParentMenuClick(null, true)} ><img src="assets/img/MainMenu/logo-icube.png" alt="" /></a>

                </div>
                <div className="navbar-header pull-right">



                    <ul class="profile_photo_name">
                        <li>
                            <div class="avathore_phot">
                                <img
                                    src={
                                        currentUser.empProfilePicPath === ""
                                            ? "assets/img/emptyImage.jpg"
                                            : window.$APIBaseURL +
                                            "Images" +
                                            currentUser.empProfilePicPath
                                    }
                                    height={30}
                                    width={30}
                                    alt="Logo"
                                    title="view profile picture"
                                    onClick={() =>
                                        setIsOpen(
                                            currentUser.empProfilePicPath === ""
                                                ? "assets/img/emptyImage.jpg"
                                                : window.$APIBaseURL +
                                                "Images" +
                                                currentUser.empProfilePicPath
                                        )
                                    }
                                />
                            </div>
                            <td >
                                <p className="cuts_text" title={currentUser.empFirstName + "" + currentUser.empLastName}> {currentUser.empFirstName +
                                    "" +
                                    currentUser.empLastName}</p>
                            </td>

                        </li>
                        <li>
                            <a id="btn-setting" className="logout_icon" title="Log Out" href="/Login" style={{ fontSize: "30px", color: "red" }}
                                onClick={logOut}
                            >
                                <i class="icon glyphicon glyphicon glyphicon-off"></i></a>
                        </li>
                    </ul>

                </div>
                <div>
                    {isOpen && (
                        <Lightbox
                            mainSrc={isOpen}
                            alt="bg image"
                            onCloseRequest={() => setIsOpen(false)}
                        />
                    )}
                </div>
                <div class="navbar-collapse collapse">
                    <ul class="nav navbar-nav ">
                        {
                            ParentMenuList.item1 && (ParentMenuList.item1).filter(x => x.isList === 1).map((parentItem, parentIndex) => (
                                <>
                                    {parentItem.menuName === 'Logout' || parentItem.menuName === "Mobile" ?
                                        <>

                                        </>
                                        :
                                        <li class="dropdown menu-large topMenuActive" >
                                            {
                                                parentItem.menuName === 'Dashboard' ?
                                                    <a>
                                                        <ParentMenuIcon urlPath={parentItem.urlPath} text={" " + parentItem.menuDisplayName} menuName={parentItem.menuName} />
                                                    </a>
                                                    :
                                                    <>
                                                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">

                                                            <ParentMenuIcon text={" " + parentItem.menuDisplayName} menuName={parentItem.menuName} iconattr={parentItem.menuIcon} /> <b class="caret"></b></a>

                                                        <ul class="dropdown-menu megamenu row">
                                                            {
                                                                parentItem.menuDisplayName === "Sampling" ?
                                                                    <>
                                                                        {ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SamplingGeneral" && x.isList === 1).map((childItem, childIndex) => (
                                                                            <li class="col-sm-2">
                                                                                <ul>
                                                                                    <>
                                                                                        <li>
                                                                                            <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                            <ul className="menu-text-childItem-ul">
                                                                                                {ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (


                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>

                                                                                                        <MenuItemIcon
                                                                                                            href={subChildItem.urlPath}
                                                                                                            dbID={subChildItem.menuID}
                                                                                                            childMenu={childItem.menuName}
                                                                                                            subChildMenu={subChildItem.menuName}
                                                                                                            text={" " + subChildItem.menuDisplayName}
                                                                                                            iconattr={subChildItem.menuIcon}
                                                                                                            parentMenu={parentItem.menuDisplayName}
                                                                                                        />


                                                                                                    </li>

                                                                                                ))
                                                                                                }
                                                                                            </ul>
                                                                                        </li>

                                                                                    </>

                                                                                </ul>
                                                                            </li>
                                                                        ))
                                                                        }
                                                                        {

                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SamplingOrder" && x.isList === 1).map((childItem, childIndex) => (

                                                                                <li class="col-sm-2">
                                                                                    <ul>

                                                                                        <>
                                                                                            <li>
                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                    {
                                                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                            </li>
                                                                                                        ))
                                                                                                    }

                                                                                                </ul>
                                                                                            </li>

                                                                                        </>

                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                        {

                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SamplingComponent" && x.isList === 1).map((childItem, childIndex) => (
                                                                                <li class="col-sm-2">
                                                                                    <ul>

                                                                                        <>
                                                                                            <li>
                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                    {
                                                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon}
                                                                                                                    parentMenu={parentItem.menuDisplayName} />


                                                                                                            </li>

                                                                                                        ))
                                                                                                    }
                                                                                                </ul>
                                                                                            </li>
                                                                                        </>

                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                        {

                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SamplingSample" && x.isList === 1).map((childItem, childIndex) => (

                                                                                <li class="col-sm-2">
                                                                                    <ul>

                                                                                        <>
                                                                                            <li>
                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                    {
                                                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                            </li>
                                                                                                        ))
                                                                                                    }

                                                                                                </ul>
                                                                                            </li>

                                                                                        </>

                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                        }

                                                                        {

                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SamplingTesting" && x.isList === 1).map((childItem, childIndex) => (

                                                                                <li class="col-sm-2">
                                                                                    <ul>

                                                                                        <>
                                                                                            <li>
                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                    {
                                                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                            </li>
                                                                                                        ))
                                                                                                    }

                                                                                                </ul>
                                                                                            </li>

                                                                                        </>

                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                        }

                                                                        {

                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SamplingInspectionQualityControl" && x.isList === 1).map((childItem, childIndex) => (

                                                                                <li class="col-sm-2">
                                                                                    <ul>

                                                                                        <>
                                                                                            <li>
                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                    {
                                                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                            </li>
                                                                                                        ))
                                                                                                    }

                                                                                                </ul>
                                                                                            </li>

                                                                                        </>

                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    : parentItem.menuDisplayName === "Production" ?
                                                                        <>
                                                                            {ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ProductionGeneral" && x.isList === 1).map((childItem, childIndex) => (
                                                                                <li class="col-sm-2">
                                                                                    <ul>

                                                                                        <>

                                                                                            <li>
                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                    {ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (


                                                                                                        <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>

                                                                                                            <MenuItemIcon href={subChildItem.urlPath}
                                                                                                                dbID={subChildItem.menuID}
                                                                                                                childMenu={childItem.menuName}
                                                                                                                subChildMenu={subChildItem.menuName}
                                                                                                                text={" " + subChildItem.menuDisplayName}
                                                                                                                iconattr={subChildItem.menuIcon}
                                                                                                                parentMenu={parentItem.menuDisplayName}
                                                                                                            />


                                                                                                        </li>

                                                                                                    ))
                                                                                                    }
                                                                                                </ul>
                                                                                            </li>

                                                                                        </>

                                                                                    </ul>
                                                                                </li>
                                                                            ))
                                                                            }
                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ProductionOrder" && x.isList === 1).map((childItem, childIndex) => (

                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>
                                                                                                            ))
                                                                                                        }

                                                                                                    </ul>
                                                                                                </li>

                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ProductionComponent" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ProductionSamples" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }

                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ProductionTesting" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }

                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ProductionProduction" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }

                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "InspectionQualityControl" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon
                                                                                                                        href={subChildItem.urlPath}
                                                                                                                        dbID={subChildItem.menuID}
                                                                                                                        childMenu={childItem.menuName}
                                                                                                                        subChildMenu={subChildItem.menuName}
                                                                                                                        text={" " + subChildItem.menuDisplayName}
                                                                                                                        iconattr={subChildItem.menuIcon}
                                                                                                                        parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }

                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "Shipment" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }

                                                                            {

                                                                                ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "Payment" && x.isList === 1).map((childItem, childIndex) => (
                                                                                    <li class="col-sm-2">
                                                                                        <ul>

                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />


                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    </li>
                                                                                ))
                                                                            }


                                                                        </>
                                                                        : parentItem.menuDisplayName === "Master" ?
                                                                            <>
                                                                           {ParentMenuList.item2 && ParentMenuList.item2.filter( (x) => x.mainMenuID === parentItem.menuID &&     (x.menuName === "CompanyInformation" || x.menuName === "BuyerInformation" || x.menuName === "SupplierInformation") &&  x.isList === 1).length > 0 && (
                                                                                <li class="col-sm-2">
                                                                                    {ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "CompanyInformation" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <ul>
                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" style={{ display: !childItem.list ? 'Block' : 'None' }} onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>

                                                                                        </ul>
                                                                                    ))
                                                                                    }
                                                                                    {ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "BuyerInformation" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <ul>
                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                </li>
                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>
                                                                                        </ul>
                                                                                    ))
                                                                                    }
                                                                                    {ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "SupplierInformation" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <ul>
                                                                                            <>
                                                                                                <li>
                                                                                                    <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                    <ul className="menu-text-childItem-ul">
                                                                                                        {
                                                                                                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                </li>

                                                                                                            ))
                                                                                                        }
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </>
                                                                                        </ul>
                                                                                    ))
                                                                                    }
                                                                                </li>
                                                                                
                                                                            ) }
                                                                            
                                                                            
                                                                            
                                                                            
                                                                            {  
                                                                                  ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "General" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                        <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                    </li>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>
                                                                                          ))
                                                                                        }
                                                                                
                                                                                     { ParentMenuList.item2 && ParentMenuList.item2.filter( (x) => x.mainMenuID === parentItem.menuID &&     (x.menuName === "PantoneInformation" || x.menuName === "TaskInfo") &&  x.isList === 1).length > 0 && (
                                                                                    <li class="col-sm-2">
                                                                                        {ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "PantoneInformation" && x.isList === 1).map((childItem, childIndex) => (
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                        <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                    </li>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>
                                                                                            </ul>
                                                                                           ))
                                                                                        
                                                                                        }


                                                                                        {

                                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "TaskInfo" && x.isList === 1).map((childItem, childIndex) => (

                                                                                                <ul>

                                                                                                    <>
                                                                                                        <li>
                                                                                                            <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                            <ul className="menu-text-childItem-ul">
                                                                                                                {
                                                                                                                    ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                        <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                            <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                        </li>

                                                                                                                    ))
                                                                                                                }
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    </>

                                                                                                </ul>

                                                                                            ))
                                                                                        }
                                                                                    </li>
                                                                                  )}
                                                                                 
                                                                                  {  ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "Component" && x.isList === 1).map((childItem, childIndex) => (

                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                        <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                    </li>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                                {

                                                                                    ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "AddtionalComponent" && x.isList === 1).map((childItem, childIndex) => (

                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                        <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                    </li>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>
                                                                                    ))
                                                                                }

                                                                                {

                                                                                    ParentMenuList.item2 && (ParentMenuList.item2).filter((x, index) => x.mainMenuID === parentItem.menuID && x.menuName === "FinalInspection" && x.isList === 1).map((childItem, childIndex) => (


                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (
                                                                                                                    <Fragment>
                                                                                                                        {subChildIndex < 8 &&
                                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                            </li>
                                                                                                                        }
                                                                                                                    </Fragment>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>

                                                                                    ))
                                                                                }
                                                                                {

                                                                                    ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "AdditionalFinalInspection" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (
                                                                                                                    <Fragment>
                                                                                                                        {
                                                                                                                            //subChildIndex > 7 &&
                                                                                                                            <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                            </li>
                                                                                                                        }
                                                                                                                    </Fragment>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                                {

                                                                                    ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "MasterTesting" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                        <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                    </li>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                                {

                                                                                    ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "CustomReport" && x.isList === 1).map((childItem, childIndex) => (
                                                                                        <li class="col-sm-2">
                                                                                            <ul>

                                                                                                <>
                                                                                                    <li>
                                                                                                        <span className="menu-text-childItem" > {childItem.menuDisplayName}</span>
                                                                                                        <ul className="menu-text-childItem-ul">
                                                                                                            {
                                                                                                                ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                    <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                        <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                    </li>

                                                                                                                ))
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                </>

                                                                                            </ul>
                                                                                        </li>
                                                                                    ))
                                                                                }

{

ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "MasterProduct" && x.isList === 1).map((childItem, childIndex) => (
    <li class="col-sm-2">
        <ul>

            <>
                <li>
                    <span className="menu-text-childItem" > {childItem.menuDisplayName}</span>
                    <ul className="menu-text-childItem-ul">
                        {
                            ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                <li className="menu-text-childItem-li" onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                    <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                </li>

                            ))
                        }
                    </ul>
                </li>
            </>

        </ul>
    </li>
))
}
                                                                                <div class="clear"></div>

                                                                            </>
                                                                            : parentItem.menuDisplayName === "General" ?
                                                                                <>
                                                                                    {

                                                                                        ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "CourierInformation" && x.isList === 1).map((childItem, childIndex) => (

                                                                                            <li class="col-sm-2">
                                                                                                <ul>

                                                                                                    <>
                                                                                                        <li>
                                                                                                            <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                            <ul className="menu-text-childItem-ul">
                                                                                                                {
                                                                                                                    ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                        <li className="menu-text-childItem-li" style={{ display: !childItem.list ? 'Block' : 'None' }} onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                            <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                        </li>

                                                                                                                    ))
                                                                                                                }
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    </>

                                                                                                </ul>
                                                                                            </li>
                                                                                        ))
                                                                                    }

                                                                                </>
                                                                                : parentItem.menuDisplayName === "Report" ?
                                                                                    <>
                                                                                        {

                                                                                            ParentMenuList.item2 && (ParentMenuList.item2).filter(x => x.mainMenuID === parentItem.menuID && x.menuName === "ReportInformation" && x.isList === 1).map((childItem, childIndex) => (

                                                                                                <li class="col-sm-2">
                                                                                                    <ul>

                                                                                                        <>
                                                                                                            <li>
                                                                                                                <span className="menu-text-childItem"> {childItem.menuDisplayName}</span>
                                                                                                                <ul className="menu-text-childItem-ul">
                                                                                                                    {
                                                                                                                        ParentMenuList.item3 && (ParentMenuList.item3).filter(y => y.mainMenuID === childItem.menuID && y.isList === 1).map((subChildItem, subChildIndex) => (

                                                                                                                            <li className="menu-text-childItem-li" style={{ display: !childItem.list ? 'Block' : 'None' }} onClick={() => SubChildClick(parentItem, childItem, subChildItem)}>
                                                                                                                                <MenuItemIcon href={subChildItem.urlPath} dbID={subChildItem.menuID} childMenu={childItem.menuName} subChildMenu={subChildItem.menuName} text={" " + subChildItem.menuDisplayName} iconattr={subChildItem.menuIcon} parentMenu={parentItem.menuDisplayName} />

                                                                                                                            </li>

                                                                                                                        ))
                                                                                                                    }
                                                                                                                </ul>
                                                                                                            </li>
                                                                                                        </>

                                                                                                    </ul>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <></>


                                                            }




                                                        </ul>
                                                    </>

                                            }





                                        </li>
                                    }
                                </>

                            ))
                        }

                    </ul>
                </div>
                {/* <!-- /.navbar-collapse --> */}
            </div>
            {/* <!-- /.container --> */}







        </header >

        //changesd

    );
};
export default TopMenu;