import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../../Common/ReactNotification"
import UserService from "../../../../services/Master/UserPermissionService";
import validator from 'validator'
import SearchRetain from "../../../Common/SearchRetain";
import Reactselect from 'react-select';
import Select from "react-validation/build/select";
import { retrieveUsername, loadExistingMenuBuyerBrandDetails, GetUserPermissionList } from "../../../../actions/UserPermission";
import { useDispatch, useSelector } from "react-redux";
import { TableHeader } from "../../../Datatable";




const UserRightsRegister = (props) => {
    //reducer
    const { user: currentUser } = useSelector((state) => state.auth);
    const userList = useSelector((state) => state.userpermission.userList);
    let oldUserPermissionList = useSelector((state) => state.userpermission.editUserPermissionList);
    const reducerState = useSelector((state) => state);
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];


    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getRole, setRole] = useState([{ Id: 0, Name: "", }]);
    const [ExistingList, setExistingList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [HeaderName, setHeaderName] = useState("Add");
    // const [oldEditUserPermissionList, setEditOldUserPermissionList] = useState([]);
    const [getMenuInfo, setMenuInfo] = useState({

        Operation: 1, EmpMenuPermissionId: 0, EmployeeInformationId: 0,
        MenuDetails: [],
        MenuDetailsHeader: [{ showButton: 0, addButton: 0, editButton: 0, viewButton: 0, deleteButton: 0, quickButton: 0 }],
        ExtMenuDetails: [], ExtMenuDetailsHeader: [], Createdby: currentUser.employeeinformationID,
        MenuBuyerBrandDetails: [],
        MenuBuyerBrandDetailsHeader: [{ isAllInfo: false, isMapped: false }]
    });
    const UserRightsValue = { ...getMenuInfo };
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red', innerHeight: '100px', outerHeight: '100px' }),
    }

    //declaration
    const dispatch = useDispatch();




    // const existingMenuBuyerBrandDetails = useSelector((state) => state.userpermission.existingMenuBuyerBrandDetails);


    //Header Name Declaration
    const headers = [
        { name: "Menu Name", field: "menuName", sortable: true, widthsize: "10%" },
        { name: "Action", field: "", sortable: false },
    ];

    //status
    const [tabStatus, setTabStatus] = useState(1);
    //Tabs
    const tabClick = (tab) => {
        setTabStatus(tab);
    }

    useEffect(() => {

        if (props.location.state.params.employeeInformationId) {
            dispatch(GetUserPermissionList(parseInt(props.location.state.params.empMenuPermissionId))).then(response => {
                //   UserService.GetUserPermissionList(parseInt(props.location.state.params.empMenuPermissionId)).then((response) => {
                if (response) {

                    let res = response.item2;
                    // let buyerBrandPermissionList = response.item3;
                    debugger;
                    let buyerBrandPermissionList = response.item3.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
                        o.buyerId === buyerId && isAllInfo === 1 && isMapped === 0));
                       let computedCommentsBuyerDetails = response.item3.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
                       isAllInfo === 0 && isMapped === 1 
                       //&& o.cubeEmployeeIdList != undefined
                        && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
                       debugger;
                        if (computedCommentsBuyerDetails.length > 0) {
                            buyerBrandPermissionList = [...buyerBrandPermissionList, ...computedCommentsBuyerDetails];
                    }
                    
                    setHeaderName('Edit');
                    setButtonName('Update');
                    dispatch(retrieveUsername(2));
                    //For header
                    UserRightsValue.MenuDetails = [];
                    UserRightsValue.MenuBuyerBrandDetails = [];
                    //UserRightsValue.MenuDetailsHeader = [];
                    let outputReswithoutlastrow = [];
                    //let outputRes = [];

                    if (res.length > 0) {
                        for (var i = 0; i < res.length; i++) {
                            outputReswithoutlastrow.push({
                                validateMenu: res[i].validateMenu, userType: res[i].userType, subParentUserType: res[i].subParentUserType,
                                menuDisplayName: res[i].menuDisplayName, roleId: res[i].roleId, roleMenuPermissionId: res[i].empMenuPermissionDetailId,
                                menuId: res[i].menuId, addButton: res[i].addButton, editButton: res[i].editButton, deleteButton: res[i].deleteButton,
                                viewButton: res[i].viewButton, showButton: res[i].showButton, quickButton: res[i].quickButton, menuChk: res[i].menuChk,
                                isParentMenu: res[i].isParentMenu
                            });
                        }
                    }

                    UserRightsValue.Operation = 2;
                    UserRightsValue.EmpMenuPermissionId = props.location.state.params.empMenuPermissionId;
                    UserRightsValue.EmployeeInformationId = props.location.state.params.employeeInformationId;
                    debugger;
                    UserRightsValue.MenuBuyerBrandDetails = buyerBrandPermissionList;
                    // UserRightsValue.getEditBuyerBrandPermissionList = buyerBrandPermissionList;


                    // UserRightsValue.MenuDetailsHeader = outputRes; // Header data
                    //UserRightsValue.ExtMenuDetailsHeader = outputRes; // Header data
                    UserRightsValue.MenuDetails = outputReswithoutlastrow;

                    UserRightsValue.ExtMenuDetails = outputReswithoutlastrow;
                    //

                    let isShow = !!UserRightsValue.MenuDetails.find(d => d.showButton === 0);
                    if (!isShow) {
                        UserRightsValue.MenuDetailsHeader[0].showButton = 1;
                    }
                    let isAdd = !!UserRightsValue.MenuDetails.find(d => d.addButton === 0);
                    if (!isAdd) {
                        UserRightsValue.MenuDetailsHeader[0].addButton = 1;
                    }
                    let isEdit = !!UserRightsValue.MenuDetails.find(d => d.editButton === 0);
                    if (!isEdit) {
                        UserRightsValue.MenuDetailsHeader[0].editButton = 1;
                    }
                    let isView = !!UserRightsValue.MenuDetails.find(d => d.viewButton === 0);
                    if (!isView) {
                        UserRightsValue.MenuDetailsHeader[0].viewButton = 1;
                    }
                    let isDelete = !!UserRightsValue.MenuDetails.find(d => d.deleteButton === 0);
                    if (!isDelete) {
                        UserRightsValue.MenuDetailsHeader[0].deleteButton = 1;
                    }
                    let isQuick = !!UserRightsValue.MenuDetails.find(d => d.quickButton === 0);
                    if (!isQuick) {
                        UserRightsValue.MenuDetailsHeader[0].quickButton = 1;
                    }

                    // MenuBuyerBrandDetails
                    let isAllInfo = !!!UserRightsValue.MenuBuyerBrandDetails.find(d => d.isAllInfo === false);
                    if (isAllInfo) {
                        UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = true;
                    }
                    let isMapped = !!!UserRightsValue.MenuBuyerBrandDetails.find(d => d.isMapped === false);
                    if (isMapped) {
                        UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = true;
                    }

                    setSubmitted(false);
                    setMenuInfo(UserRightsValue);
                    // setEditOldUserPermissionList(oldUserPermissionList);
                }

            }).catch(e => {
            });
        }
        else {

            let buyerBrandPermissionDetails = props.location.state.buyerBrandPermissionDetails;
            UserRightsValue.MenuBuyerBrandDetails = [];
            debugger;
            if (buyerBrandPermissionDetails) {

                let computedComments = buyerBrandPermissionDetails.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
                    o.buyerId === buyerId && isAllInfo === 1 && isMapped === 0));
                   let computedCommentsBuyerDetails = buyerBrandPermissionDetails.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
                   isAllInfo === 0 && isMapped === 1 
                   //&& o.cubeEmployeeIdList != undefined
                    && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
                   debugger;
                    if (computedCommentsBuyerDetails.length > 0) {
                    computedComments = [...computedComments, ...computedCommentsBuyerDetails];
                }
                UserRightsValue.MenuBuyerBrandDetails = computedComments;
            }

            setMenuInfo(UserRightsValue);
            dispatch(retrieveUsername(1));
        }

    }, [props.location.state.params.employeeInformationId]);




    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/UserRightsList');
        } else {
            window.location.reload();

        }
    }


    const handleOnSelectRecords = (event, index, FieldName) => {
        debugger;
        if (FieldName === 'menuChk') {
          
            UserRightsValue.MenuDetails[index].menuChk = 0;
            UserRightsValue.MenuDetails[index].showButton = 0;
            UserRightsValue.MenuDetails[index].addButton = 0;
            UserRightsValue.MenuDetails[index].editButton = 0;
            UserRightsValue.MenuDetails[index].viewButton = 0;
            UserRightsValue.MenuDetails[index].deleteButton = 0;
            UserRightsValue.MenuDetails[index].quickButton = 0;
            if (event.target.checked) {
                UserRightsValue.MenuDetails[index].menuChk = 1;
                UserRightsValue.MenuDetails[index].showButton = 1;
                UserRightsValue.MenuDetails[index].addButton = 1;
                UserRightsValue.MenuDetails[index].editButton = 1;
                UserRightsValue.MenuDetails[index].viewButton = 1;
                UserRightsValue.MenuDetails[index].deleteButton = 1;
                UserRightsValue.MenuDetails[index].quickButton = 1;

                let isShow = !!UserRightsValue.MenuDetails.find(d => d.showButton === 0);
                if (!isShow) {
                    UserRightsValue.MenuDetailsHeader[0].showButton = 1;
                }
                let isAdd = !!UserRightsValue.MenuDetails.find(d => d.addButton === 0);
                if (!isAdd) {
                    UserRightsValue.MenuDetailsHeader[0].addButton = 1;
                }
                let isEdit = !!UserRightsValue.MenuDetails.find(d => d.editButton === 0);
                if (!isEdit) {
                    UserRightsValue.MenuDetailsHeader[0].editButton = 1;
                }
                let isView = !!UserRightsValue.MenuDetails.find(d => d.viewButton === 0);
                if (!isView) {
                    UserRightsValue.MenuDetailsHeader[0].viewButton = 1;
                }
                let isDelete = !!UserRightsValue.MenuDetails.find(d => d.deleteButton === 0);
                if (!isDelete) {
                    UserRightsValue.MenuDetailsHeader[0].deleteButton = 1;
                }
            } else {

                UserRightsValue.MenuDetailsHeader[0].showButton = 0;
                UserRightsValue.MenuDetailsHeader[0].addButton = 0;
                UserRightsValue.MenuDetailsHeader[0].editButton = 0;
                UserRightsValue.MenuDetailsHeader[0].viewButton = 0;
                UserRightsValue.MenuDetailsHeader[0].deleteButton = 0;
                UserRightsValue.MenuDetailsHeader[0].quickButton = 0;
            }
        }
        else {
            UserRightsValue.MenuDetails[index][FieldName] = event.target.checked ? 1 : 0;
            if (event.target.checked) {

                let hasNotChecked = !!UserRightsValue.MenuDetails.find(d => d[FieldName] === 0);

                if (!hasNotChecked) {
                    UserRightsValue.MenuDetailsHeader[0][FieldName] = 1;
                }

                if (UserRightsValue.MenuDetails[index].showButton === 1 &&
                    UserRightsValue.MenuDetails[index].addButton === 1 &&
                    UserRightsValue.MenuDetails[index].editButton === 1 &&
                    UserRightsValue.MenuDetails[index].viewButton === 1 &&
                    UserRightsValue.MenuDetails[index].deleteButton === 1 &&
                    UserRightsValue.MenuDetails[index].quickButton === 1) {
                    UserRightsValue.MenuDetails[index].menuChk = 1;
                }
            } else {
                UserRightsValue.MenuDetailsHeader[0][FieldName] = 0;
                UserRightsValue.MenuDetails[index].menuChk = 0;
            }


        }
        // BindChildParent(UserRightsValue.MenuDetails, index, event);
        setMenuInfo(UserRightsValue);

    };



    const handleOnSetasdefault = (event) => {
        debugger;
        if (event.target.checked) {
            UserService.GetMenuList(parseInt(UserRightsValue.EmployeeInformationId), 1).then((response) => {
                if (response.data) {
                    let res = response.data;
                    UserRightsValue.MenuDetails = [];
                    //UserRightsValue.MenuDetailsHeader = [];
                    //For header
                    let outputReswithoutlastrow = [];
                    //let outputRes = [];
                    // if (res.length > 0) {
                    //     let totalCou = res.length - 1;
                    //     for (var i = 0; i < res.length; i++) {
                    //         if (i === totalCou) {
                    //             outputRes.push({ roleId: res[i].roleId, roleMenuPermissionId: res[i].empMenuPermissionDetailId, menuId: res[i].menuId, addButton: res[i].addButton, editButton: res[i].editButton, deleteButton: res[i].deleteButton, viewButton: res[i].viewButton, showButton: res[i].showButton })
                    //         }
                    //         else {
                    //             outputReswithoutlastrow.push({ menuDisplayName: res[i].menuDisplayName, roleId: res[i].roleId, roleMenuPermissionId: res[i].empMenuPermissionDetailId, menuId: res[i].menuId, addButton: res[i].addButton, editButton: res[i].editButton, deleteButton: res[i].deleteButton, viewButton: res[i].viewButton, showButton: res[i].showButton, menuChk: res[i].menuChk, isParentMenu: res[i].isParentMenu });
                    //         }
                    //     }
                    // }


                    if (res.length > 0) {
                        for (var i = 0; i < res.length; i++) {
                            outputReswithoutlastrow.push({ validateMenu: res[i].validateMenu, userType: res[i].userType, subParentUserType: res[i].subParentUserType, menuDisplayName: res[i].menuDisplayName, roleId: res[i].roleId, roleMenuPermissionId: res[i].empMenuPermissionDetailId, menuId: res[i].menuId, addButton: res[i].addButton, editButton: res[i].editButton, deleteButton: res[i].deleteButton, viewButton: res[i].viewButton, showButton: res[i].showButton, quickButton: res[i].quickButton, menuChk: res[i].menuChk, isParentMenu: res[i].isParentMenu });

                        }
                    }

                    UserRightsValue.MenuDetails = outputReswithoutlastrow;
                    //Declaration
                    UserRightsValue.MenuDetailsHeader[0].showButton = 0;
                    UserRightsValue.MenuDetailsHeader[0].addButton = 0;
                    UserRightsValue.MenuDetailsHeader[0].editButton = 0;
                    UserRightsValue.MenuDetailsHeader[0].viewButton = 0;
                    UserRightsValue.MenuDetailsHeader[0].deleteButton = 0;
                    UserRightsValue.MenuDetailsHeader[0].quickButton = 0;
                    //

                    let isShow = !!UserRightsValue.MenuDetails.find(d => d.showButton === 0);
                    if (!isShow) {
                        UserRightsValue.MenuDetailsHeader[0].showButton = 1;
                    }
                    let isAdd = !!UserRightsValue.MenuDetails.find(d => d.addButton === 0);
                    if (!isAdd) {
                        UserRightsValue.MenuDetailsHeader[0].addButton = 1;
                    }
                    let isEdit = !!UserRightsValue.MenuDetails.find(d => d.editButton === 0);
                    if (!isEdit) {
                        UserRightsValue.MenuDetailsHeader[0].editButton = 1;
                    }
                    let isView = !!UserRightsValue.MenuDetails.find(d => d.viewButton === 0);
                    if (!isView) {
                        UserRightsValue.MenuDetailsHeader[0].viewButton = 1;
                    }
                    let isDelete = !!UserRightsValue.MenuDetails.find(d => d.deleteButton === 0);
                    if (!isDelete) {
                        UserRightsValue.MenuDetailsHeader[0].deleteButton = 1;
                    }
                    let isQuick = !!UserRightsValue.MenuDetails.find(d => d.quickButton === 0);
                    if (!isQuick) {
                        UserRightsValue.MenuDetailsHeader[0].quickButton = 1;
                    }
                    setSubmitted(false);
                    setMenuInfo(UserRightsValue);
                    //

                }
            });
        }

        else {

            UserRightsValue.MenuDetails = UserRightsValue.ExtMenuDetails;
            //Declaration
            UserRightsValue.MenuDetailsHeader[0].showButton = 0;
            UserRightsValue.MenuDetailsHeader[0].addButton = 0;
            UserRightsValue.MenuDetailsHeader[0].editButton = 0;
            UserRightsValue.MenuDetailsHeader[0].viewButton = 0;
            UserRightsValue.MenuDetailsHeader[0].deleteButton = 0;
            UserRightsValue.MenuDetailsHeader[0].quickButton = 0;
            //

            let isShow = !!UserRightsValue.MenuDetails.find(d => d.showButton === 0);
            if (!isShow) {
                UserRightsValue.MenuDetailsHeader[0].showButton = 1;
            }
            let isAdd = !!UserRightsValue.MenuDetails.find(d => d.addButton === 0);
            if (!isAdd) {
                UserRightsValue.MenuDetailsHeader[0].addButton = 1;
            }
            let isEdit = !!UserRightsValue.MenuDetails.find(d => d.editButton === 0);
            if (!isEdit) {
                UserRightsValue.MenuDetailsHeader[0].editButton = 1;
            }
            let isView = !!UserRightsValue.MenuDetails.find(d => d.viewButton === 0);
            if (!isView) {
                UserRightsValue.MenuDetailsHeader[0].viewButton = 1;
            }
            let isDelete = !!UserRightsValue.MenuDetails.find(d => d.deleteButton === 0);
            if (!isDelete) {
                UserRightsValue.MenuDetailsHeader[0].deleteButton = 1;
            }
            let isQuick = !!UserRightsValue.MenuDetails.find(d => d.quickButton === 0);
            if (!isQuick) {
                UserRightsValue.MenuDetailsHeader[0].quickButton = 1;
            }
            setSubmitted(false);
            setMenuInfo(UserRightsValue);
        }
    }

    const handleOnSelectRecordsHeader = (event, FieldName) => {
        debugger;
        for (var i = 0; i < UserRightsValue.MenuDetails.length; i++) {
            if (event.target.checked) {
                UserRightsValue.MenuDetails[i][FieldName] = 1;
                if (UserRightsValue.MenuDetails[i].showButton === 1 &&
                    UserRightsValue.MenuDetails[i].addButton === 1 &&
                    UserRightsValue.MenuDetails[i].editButton === 1 &&
                    UserRightsValue.MenuDetails[i].viewButton === 1 &&
                    UserRightsValue.MenuDetails[i].deleteButton === 1 &&
                    UserRightsValue.MenuDetails[i].quickButton === 0) {
                    UserRightsValue.MenuDetails[i].menuChk = 1;
                }
            }
            else {
                if (UserRightsValue.MenuDetails[i].validateMenu !== 'Dashboard') { UserRightsValue.MenuDetails[i][FieldName] = 0; }

                UserRightsValue.MenuDetails[i].menuChk = 0;
            }
        }
        //For header
        for (var k = 0; k < UserRightsValue.MenuDetailsHeader.length; k++) {
            if (event.target.checked) { UserRightsValue.MenuDetailsHeader[k][FieldName] = 1; }
            else {
                // if (UserRightsValue.MenuDetails[k].validateMenu !== 'Dashboard')
                // { UserRightsValue.MenuDetailsHeader[k][FieldName] = 0; }
                // else { UserRightsValue.MenuDetailsHeader[k][FieldName] = 1; }
                UserRightsValue.MenuDetailsHeader[k][FieldName] = 0;

            }
        }
        setMenuInfo(UserRightsValue);
    };

    const SaveRoleList = (e) => {
        debugger;
        setButtonLoader(true);
        e.preventDefault();
        if (UserRightsValue.EmployeeInformationId === 0) {
            setButtonLoader(false);
            setSubmitted(true);
            ValidationPopup("Please select username.");
        }
        else {

            BindChildParent(UserRightsValue.MenuDetails);
            UserRightsValue.MenuBuyerBrandDetails = BindMenuBuyerBrandDetails();
            //For Header
            //UserRightsValue.MenuDetails.push({ roleId: UserRightsValue.MenuDetailsHeader[0].roleId, roleMenuPermissionId: UserRightsValue.MenuDetailsHeader[0].roleMenuPermissionId, menuId: UserRightsValue.MenuDetailsHeader[0].menuId, addButton: UserRightsValue.MenuDetailsHeader[0].addButton, editButton: UserRightsValue.MenuDetailsHeader[0].editButton, deleteButton: UserRightsValue.MenuDetailsHeader[0].deleteButton, viewButton: UserRightsValue.MenuDetailsHeader[0].viewButton, showButton: UserRightsValue.MenuDetailsHeader[0].showButton, menuChk: UserRightsValue.MenuDetailsHeader[0].menuChk });
            UserService.InsertUpdateUserRightsGrid(UserRightsValue).then(res => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "-2") {
                    setButtonLoader(false);
                    ValidationPopup("This Role is already exist.");
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    ValidationPopup("Error Occured!");
                }
            });
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/UserRightsList", state: { message: Func } });
    }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const handleInputChangeUser = (event, FieldName) => {
        if (FieldName === "userName") {
            if (event !== null) {
                if (event.id !== 0) {
                    UserService.GetMenuList(parseInt(event.id), 0).then((response) => {
                        if (response.data) {
                            let res = response.data;
                            UserRightsValue.MenuDetails = [];
                            let outputReswithoutlastrow = [];
                            if (res.length > 0) {
                                for (var i = 0; i < res.length; i++) {
                                    outputReswithoutlastrow.push({ validateMenu: res[i].validateMenu, userType: res[i].userType, subParentUserType: res[i].subParentUserType, menuDisplayName: res[i].menuDisplayName, roleId: res[i].roleId, roleMenuPermissionId: res[i].roleMenuPermissionId, menuId: res[i].menuId, addButton: res[i].addButton, editButton: res[i].editButton, deleteButton: res[i].deleteButton, viewButton: res[i].viewButton, showButton: res[i].showButton, quickButton: res[i].quickButton, menuChk: res[i].menuChk, isParentMenu: res[i].isParentMenu });
                                }
                            }
                            UserRightsValue.EmployeeInformationId = event.id;
                            // UserRightsValue.MenuDetailsHeader = outputRes; // Header data
                            UserRightsValue.MenuDetails = outputReswithoutlastrow;
                            let isShow = !!UserRightsValue.MenuDetails.find(d => d.showButton === 0);
                            if (!isShow) {
                                UserRightsValue.MenuDetailsHeader[0].showButton = 1;
                            }
                            let isAdd = !!UserRightsValue.MenuDetails.find(d => d.addButton === 0);
                            if (!isAdd) {
                                UserRightsValue.MenuDetailsHeader[0].addButton = 1;
                            }
                            let isEdit = !!UserRightsValue.MenuDetails.find(d => d.editButton === 0);
                            if (!isEdit) {
                                UserRightsValue.MenuDetailsHeader[0].editButton = 1;
                            }
                            let isView = !!UserRightsValue.MenuDetails.find(d => d.viewButton === 0);
                            if (!isView) {
                                UserRightsValue.MenuDetailsHeader[0].viewButton = 1;
                            }
                            let isDelete = !!UserRightsValue.MenuDetails.find(d => d.deleteButton === 0);
                            if (!isDelete) {
                                UserRightsValue.MenuDetailsHeader[0].deleteButton = 1;
                            }
                            let isQuick = !!UserRightsValue.MenuDetails.find(d => d.quickButton === 0);
                            if (!isQuick) {
                                UserRightsValue.MenuDetailsHeader[0].quickButton = 1;
                            }
                            //
                            setSubmitted(false);
                            setMenuInfo(UserRightsValue);
                        }
                    });
                }
            }
            else {
                UserRightsValue.EmployeeInformationId = 0;
                //UserRightsValue.EmployeeInformationId = 0;
                //UserRightsValue.MenuDetailsHeader = []; // Header data
                UserRightsValue.MenuDetails = [];
                setMenuInfo(UserRightsValue);
            }
        } else if (FieldName === "existingName") {
            if (event) {
                dispatch(loadExistingMenuBuyerBrandDetails(parseInt(event.value))).then(data => {
                    if (data) {
                        let prevMenuBuyerBrandList = (oldUserPermissionList && oldUserPermissionList.length > 0 ? oldUserPermissionList : UserRightsValue.MenuBuyerBrandDetails);

                        let newExistingMenu = prevMenuBuyerBrandList && prevMenuBuyerBrandList.map((item) => {
                            let existingData = data && data.filter(d => d.brandId === item.brandId);
                            if (existingData && existingData.length > 0) {
                                item.isAllInfo = existingData[0].isAllInfo;
                                item.isMapped = existingData[0].isMapped;
                            } else {
                                item.isAllInfo = false;
                                item.isMapped = false;
                            }
                            return item;
                        });

                        UserRightsValue.MenuBuyerBrandDetails = [];
                        UserRightsValue.MenuBuyerBrandDetails = newExistingMenu;

                        let isAllInfo = !!!newExistingMenu.find(d => d.isAllInfo === false || d.isAllInfo === 0 || d.isAllInfo === undefined);
                        if (isAllInfo) {
                            UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = true;
                        } else {
                            UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = false;
                        }
                        let isMapped = !!!newExistingMenu.find(d => d.isMapped === false || d.isMapped === 0 || d.isMapped === undefined);
                        if (isMapped) {
                            UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = true;
                        } else {
                            UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = false;
                        }
                        setMenuInfo(UserRightsValue);
                    }

                }).catch(e => {
                });
            } else {

                if (props.location.state.params.employeeInformationId) {
                    dispatch(loadExistingMenuBuyerBrandDetails(parseInt(props.location.state.params.empMenuPermissionId))).then(data => {
                        if (data) {
                            let prevMenuBuyerBrandList = oldUserPermissionList;
                            let newExistingMenu = prevMenuBuyerBrandList && prevMenuBuyerBrandList.map((item) => {
                                let existingData = data && data.filter(d => d.brandId === item.brandId);
                                if (existingData) {
                                    item.isAllInfo = existingData[0].isAllInfo;
                                    item.isMapped = existingData[0].isMapped;
                                } else {
                                    item.isAllInfo = false;
                                    item.isMapped = false;
                                }
                                return item;
                            });

                            UserRightsValue.MenuBuyerBrandDetails = [];
                            UserRightsValue.MenuBuyerBrandDetails = newExistingMenu;

                            let isAllInfo = !!!newExistingMenu.find(d => d.isAllInfo === false || d.isAllInfo === 0 || d.isAllInfo === undefined);
                            if (isAllInfo) {
                                UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = true;
                            } else {
                                UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = false;
                            }
                            let isMapped = !!!newExistingMenu.find(d => d.isMapped === false || d.isMapped === 0 || d.isMapped === undefined);
                            if (isMapped) {
                                UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = true;
                            } else {
                                UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = false;
                            }
                            setMenuInfo(UserRightsValue);
                        }

                    }).catch(e => {

                    });

                    // UserRightsValue.MenuBuyerBrandDetails = [];
                    // if (oldEditUserPermissionList) {
                    //     UserRightsValue.MenuBuyerBrandDetails = oldEditUserPermissionList;

                    //     let isAllInfo = !!!UserRightsValue.MenuBuyerBrandDetails.find(d => d.isAllInfo === false || d.isAllInfo === 0 || d.isAllInfo === undefined);
                    //     if (isAllInfo) {
                    //         UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = true;
                    //     } else {
                    //         UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = false;
                    //     }
                    //     let isMapped = !!!UserRightsValue.MenuBuyerBrandDetails.find(d => d.isMapped === false || d.isMapped === 0 || d.isMapped === undefined);
                    //     if (isMapped) {
                    //         UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = true;
                    //     } else {
                    //         UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = false;
                    //     }
                    //     setMenuInfo(UserRightsValue);
                    // }
                } else {
                    let buyerBrandPermissionDetails = UserRightsValue.MenuBuyerBrandDetails;
                    UserRightsValue.MenuBuyerBrandDetails = [];
                    if (buyerBrandPermissionDetails) {
                        let newExistingMenu = buyerBrandPermissionDetails && buyerBrandPermissionDetails.map((item) => {
                            item.isAllInfo = false;
                            item.isMapped = false;
                            return item;
                        });
                        UserRightsValue.MenuBuyerBrandDetails = newExistingMenu;
                    }

                    UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = false;
                    UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = false;

                    setMenuInfo(UserRightsValue);

                }
            }
        }


    }


    /////////////////////// ***** Buyer / Brand ***** //////////////////////////////
    const handleOnSelectBrandPrmHeader = (event, FieldName) => {
        UserRightsValue.MenuBuyerBrandDetailsHeader[0][FieldName] = event.target.checked ? true : false;
        if (FieldName === "isAllInfo") {
            UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = false;
        } else {
            UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = false;
        }

        if (event.target.checked) {
            UserRightsValue.MenuBuyerBrandDetails && UserRightsValue.MenuBuyerBrandDetails.map((inputField, i) => {
                UserRightsValue.MenuBuyerBrandDetails[i][FieldName] = true;
                if (FieldName === "isAllInfo") {
                    UserRightsValue.MenuBuyerBrandDetails[i].isMapped = false;
                } else {
                    UserRightsValue.MenuBuyerBrandDetails[i].isAllInfo = false;
                }
            });

        } else {
            UserRightsValue.MenuBuyerBrandDetails && UserRightsValue.MenuBuyerBrandDetails.map((inputField, i) => {
                UserRightsValue.MenuBuyerBrandDetails[i][FieldName] = false;
                if (FieldName === "isAllInfo") {
                    UserRightsValue.MenuBuyerBrandDetails[i].isMapped = false;
                } else {
                    UserRightsValue.MenuBuyerBrandDetails[i].isAllInfo = false;
                }
            });

        }

        setMenuInfo(UserRightsValue);
    }

    const handleOnSelectBrandPrm = (event, index, FieldName) => {

        UserRightsValue.MenuBuyerBrandDetails[index][FieldName] = event.target.checked ? true : false;
        if (event.target.checked) {
            let hasNotChecked = !!UserRightsValue.MenuBuyerBrandDetails.find(d => d[FieldName] === false);
            if (!hasNotChecked) {
                UserRightsValue.MenuBuyerBrandDetailsHeader[0][FieldName] = true;
            }
            if (FieldName === "isAllInfo") {
                UserRightsValue.MenuBuyerBrandDetails[index].isMapped = false;
                UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped = false;
            } else {
                UserRightsValue.MenuBuyerBrandDetails[index].isAllInfo = false;
                UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo = false;
            }

        } else {
            UserRightsValue.MenuBuyerBrandDetailsHeader[0][FieldName] = false;
        }
        setMenuInfo(UserRightsValue);
    };

    function BindMenuBuyerBrandDetails() {
        let MenuBuyerBrandDetails = [];

        UserRightsValue.MenuBuyerBrandDetails &&
            UserRightsValue.MenuBuyerBrandDetails.map((inputField, i) => {
                MenuBuyerBrandDetails.push({
                    EmpBuyerMenuPermissionBrandDetailId: inputField.empMenuPermissionBuyerBrandDetailId,
                    BuyerId: inputField.buyerId,
                    BrandId: inputField.brandId,
                    IsAllInfo: inputField.isAllInfo,
                    IsMapped: inputField.isMapped,
                });
            });

        return MenuBuyerBrandDetails;
    }

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{HeaderName} User Permission</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>
                                    &nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>
                                    &nbsp;Reset
                                </span>
                                {UserRightsValue.MenuDetails.length > 0 &&
                                    <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveRoleList}>
                                        <i className="fa fa-check right"></i>&nbsp;
                                        {ButtonName}
                                    </button>
                                }
                            </div>
                        </div>


                        <div className="widget-body">
                            <div className="row">
                                {/* <form> */}
                                <div className="row row_left10">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="Username" >User(Email / Type / Role) <span className="text-danger">*</span></label>
                                            <span className='input-icon icon-right'>
                                                <Reactselect name="userName"
                                                    id='userName'
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    // isMulti
                                                    value={userList.find(obj => obj.value === UserRightsValue.EmployeeInformationId)} // set selected value
                                                    options={userList}
                                                    // value={getFabricList.filter(option => option.value === (getMaxidFabric !== 0 ? getMaxidFabric : inputField.ArrayFabricDetail))}
                                                    //value={inputField.ArrayFabricDetail}
                                                    // menuPosition="unset"
                                                    onChange={event => handleInputChangeUser(event, "userName")}
                                                    menuPortalTarget={document.body}
                                                //styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }, submitted && UserRightsValue.EmployeeInformationId === 0 ? styles : ''}
                                                // styles={}
                                                //isDisabled={props.location.state.params.action === 'Edit' ? true : false}

                                                ></Reactselect>

                                                {/* <b>Set AS Defaulf</b> */}

                                            </span>

                                        </div>

                                    </div>
                                    {props.location.state.params.action === 'Edit' &&
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label style={{ marginTop: '30px' }}>
                                                    <input type="checkbox" name="Setasdefault"
                                                        //value={UserRightsValue.MenuDetailsHeader[0].editButton}
                                                        onChange={event => (handleOnSetasdefault(event))}
                                                        //checked={UserRightsValue.MenuDetailsHeader[0].editButton === 1 ? true : false}
                                                        className="clearOpacity" />
                                                    <span className="text"></span>
                                                </label>
                                                <span className='Chkbox'>Set as default</span>
                                            </div>

                                        </div>
                                    }
                                </div>
                                <div className="col-md-12">

                                    <div className="tabbable">
                                        <ul className="nav nav-tabs nav-justified" id="myTab">
                                            <li className="active" onClick={() => tabClick(1)}>
                                                <a data-toggle="tab" href="#permissionTab">
                                                    <h5>Permission</h5>
                                                </a>
                                            </li>
                                            <li className="" onClick={() => tabClick(2)} >
                                                <a data-toggle="tab" href="#buyerAndBrandsTab">
                                                    <h5>Buyer and Brands</h5>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="permissionTab" className="tab-pane in active" style={{ width: "100%" }}>




                                                <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>
                                                    {
                                                        UserRightsValue.MenuDetails.length > 0 && UserRightsValue.EmployeeInformationId !== 0 ?
                                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                                <thead>

                                                                    <tr>
                                                                        {/* <th style={{ width: "210px" }} className="fixed-column-header-first-tna"> #</th> */}
                                                                        <th style={{ width: "300px" }}>Menu</th>
                                                                        <th style={{ width: "120px" }}>
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="ShowButtonHeader"
                                                                                    value={UserRightsValue.MenuDetailsHeader[0].showButton}
                                                                                    onChange={event => (handleOnSelectRecordsHeader(event, 'showButton'))}
                                                                                    checked={UserRightsValue.MenuDetailsHeader[0].showButton === 1 ? true : false}
                                                                                    className="clearOpacity" title="Show Menu" />
                                                                                <span className="text">List</span>
                                                                            </label>

                                                                        </th>
                                                                        <th style={{ width: "40px" }}>
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="AddButton"
                                                                                    value={UserRightsValue.MenuDetailsHeader[0].addButton}
                                                                                    onChange={event => (handleOnSelectRecordsHeader(event, 'addButton'))}
                                                                                    checked={UserRightsValue.MenuDetailsHeader[0].addButton === 1 ? true : false}
                                                                                    className="clearOpacity" title="Add" />
                                                                                <span className="text">Add</span>
                                                                            </label></th>
                                                                        <th style={{ width: "40px" }}>
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="EditButton"
                                                                                    value={UserRightsValue.MenuDetailsHeader[0].editButton}
                                                                                    onChange={event => (handleOnSelectRecordsHeader(event, 'editButton'))}
                                                                                    checked={UserRightsValue.MenuDetailsHeader[0].editButton === 1 ? true : false}
                                                                                    className="clearOpacity" title="Edit" />
                                                                                <span className="text">Edit</span>
                                                                            </label></th>
                                                                        <th style={{ width: "40px" }}>
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="ViewButton"
                                                                                    value={UserRightsValue.MenuDetailsHeader[0].viewButton}
                                                                                    onChange={event => (handleOnSelectRecordsHeader(event, 'viewButton'))}
                                                                                    checked={UserRightsValue.MenuDetailsHeader[0].viewButton === 1 ? true : false}
                                                                                    className="clearOpacity" title="View" />
                                                                                <span className="text">View</span>
                                                                            </label></th>
                                                                        <th style={{ width: "40px" }}>
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="DeleteButton"
                                                                                    value={UserRightsValue.MenuDetailsHeader[0].deleteButton}
                                                                                    onChange={event => (handleOnSelectRecordsHeader(event, 'deleteButton'))}
                                                                                    checked={UserRightsValue.MenuDetailsHeader[0].deleteButton === 1 ? true : false}
                                                                                    className="clearOpacity" title="Delete" />
                                                                                <span className="text">Delete</span>
                                                                            </label></th>
                                                                        <th style={{ width: "1040px" }}>
                                                                            <label style={{ marginTop: '8px' }}>
                                                                                <input type="checkbox" name="QuickButton"
                                                                                    value={UserRightsValue.MenuDetailsHeader[0].quickButton}
                                                                                    onChange={event => (handleOnSelectRecordsHeader(event, 'quickButton'))}
                                                                                    checked={UserRightsValue.MenuDetailsHeader[0].quickButton === 1 ? true : false}
                                                                                    className="clearOpacity textdesign" title="Quick Master" />
                                                                                <span className="text">Quick Master</span>

                                                                            </label></th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {UserRightsValue.MenuDetails && (UserRightsValue.MenuDetails).map((inputField, index) => (
                                                                        <Fragment key={`${inputField}~${index}`}>

                                                                            <tr class="parent" data-child={"parent_" + index}>

                                                                                {
                                                                                    inputField.isParentMenu === 1 ?



                                                                                        // <td colSpan='6' style={{ backgroundColor: "#222d32" }}>
                                                                                        //     <span style={{ color: "#FFF" }} className='input-icon icon-right'>
                                                                                        //         {inputField.menuDisplayName}
                                                                                        //     </span>
                                                                                        // </td>
                                                                                        inputField.menuDisplayName === 'Dashboard' ?
                                                                                            <>

                                                                                                <td colSpan='1' style={{ backgroundColor: "#ffffff" }}>
                                                                                                    <span style={{ color: "#333333" }} className='input-icon icon-right text'>
                                                                                                        {inputField.menuDisplayName}
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td colSpan='6' style={{ backgroundColor: "#ffffff" }}>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label>
                                                                                                            <input type="checkbox" name="ShowButton"
                                                                                                                value={inputField.showButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'ShowButton'))}
                                                                                                                checked={inputField.showButton === 1 ? true : false}
                                                                                                                className="clearOpacity textdesign"
                                                                                                                disabled
                                                                                                            />
                                                                                                            <span title={inputField.menuDisplayName} className="text"></span>
                                                                                                        </label>

                                                                                                    </div>

                                                                                                </td>
                                                                                            </>

                                                                                            :

                                                                                            <td colSpan='7' style={{ backgroundColor: "#222d32" }}>
                                                                                                <span style={{ color: "#FFF" }} className='input-icon icon-right text'>
                                                                                                    {inputField.menuDisplayName}
                                                                                                </span>
                                                                                            </td>




                                                                                        : (inputField.isParentMenu === 0 || inputField.isParentMenu === 4) ?
                                                                                            <td>
                                                                                                {/* <span className='input-icon icon-right'>
                                                                                    <b>{inputField.menuDisplayName}</b>
                                                                                </span> */}
                                                                                                <div>
                                                                                                    <label style={{ marginTop: '8px', marginLeft: inputField.isParentMenu === 4 ? '25px' : '1px' }}>
                                                                                                        <input type="checkbox" name="MenuChk" title={inputField.menuDisplayName}
                                                                                                            value={inputField.menuChk}
                                                                                                            onChange={event => (handleOnSelectRecords(event, index, 'menuChk'))}
                                                                                                            checked={inputField.menuChk === 1 ? true : false}
                                                                                                            className="clearOpacity" />
                                                                                                        <span className="text">{inputField.menuDisplayName}</span>
                                                                                                    </label>

                                                                                                </div>
                                                                                            </td>
                                                                                            : ''



                                                                                }
                                                                                {
                                                                                    (inputField.isParentMenu === 0 || inputField.isParentMenu === 4) &&
                                                                                    <>
                                                                                        {inputField.validateMenu === 'RouteExport' ||
                                                                                            inputField.validateMenu === 'TNAActivate' ||
                                                                                            inputField.validateMenu === 'TNACloseComplate' ||
                                                                                            inputField.validateMenu === 'TNAComment' ||
                                                                                            inputField.validateMenu === 'TNAAttachment' ||
                                                                                            inputField.validateMenu === 'TNAstartstop' ||
                                                                                            inputField.validateMenu === 'TNAReportActivate' ||
                                                                                            inputField.validateMenu === 'TNAReportCloseComplate' ||
                                                                                            inputField.validateMenu === 'TNAReportComment' ||
                                                                                            inputField.validateMenu === 'TNAReportAttachment' ||
                                                                                            inputField.validateMenu === 'TNAReportComment' ||
                                                                                            inputField.validateMenu === 'TestingComments' ||
                                                                                            inputField.ValidateMenu === 'InternalComments'||
                                                                                            inputField.ValidateMenu === 'FIComments'
                                                                                            
                                                                                            ?
                                                                                            <>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td >
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="addButton"
                                                                                                                value={inputField.addButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'addButton'))}
                                                                                                                checked={inputField.addButton === 1 ? true : false}
                                                                                                                className="clearOpacity" />
                                                                                                            <span title="Add" className="text"></span>
                                                                                                        </label>

                                                                                                    </div>

                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                    </div>
                                                                                                </td>

                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="showButton"
                                                                                                                value={inputField.showButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'showButton'))}
                                                                                                                checked={inputField.showButton === 1 ? true : false}
                                                                                                                className="clearOpacity" />
                                                                                                            <span title="Show" className="text"></span>
                                                                                                        </label>

                                                                                                    </div>

                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="addButton"
                                                                                                                value={inputField.addButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'addButton'))}
                                                                                                                checked={inputField.addButton === 1 ? true : false}
                                                                                                                className="clearOpacity" />
                                                                                                            <span title="Add" className="text"></span>
                                                                                                        </label>

                                                                                                    </div>

                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="editButton"
                                                                                                                value={inputField.editButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'editButton'))}
                                                                                                                checked={inputField.editButton === 1 ? true : false}
                                                                                                                className="clearOpacity" />
                                                                                                            <span title="Edit" className="text"></span>
                                                                                                        </label>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="viewButton"
                                                                                                                value={inputField.viewButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'viewButton'))}
                                                                                                                checked={inputField.viewButton === 1 ? true : false}
                                                                                                                className="clearOpacity" />
                                                                                                            <span title="View" className="text"></span>
                                                                                                        </label>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        <label style={{ marginTop: '8px' }}>
                                                                                                            <input type="checkbox" name="deleteButton"
                                                                                                                value={inputField.deleteButton}
                                                                                                                onChange={event => (handleOnSelectRecords(event, index, 'deleteButton'))}
                                                                                                                checked={inputField.deleteButton === 1 ? true : false}
                                                                                                                className="clearOpacity" />
                                                                                                            <span title="Delete" className="text"></span>
                                                                                                        </label>

                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div style={{ width: "80px" }}>
                                                                                                        {/* {inputField.ValidateMenu} */}
                                                                                                        {(inputField.validateMenu === 'Season' || inputField.validateMenu === 'Branch' || inputField.validateMenu === 'City' || inputField.validateMenu === 'Gender' || inputField.validateMenu === 'Pantone' || inputField.validateMenu === 'GroupType'
                                                                                                            || inputField.validateMenu === 'Category' || inputField.validateMenu === 'MeasurementPoint' || inputField.validateMenu === 'FabricType' || inputField.validateMenu === 'Role' ||
                                                                                                            inputField.validateMenu === 'City' || inputField.validateMenu === 'FabricPart' || inputField.validateMenu === 'Swatch' || inputField.validateMenu === 'Department' ||
                                                                                                            inputField.validateMenu === 'GroupSize' || inputField.validateMenu === 'ProcessType' || inputField.validateMenu === "Size"
                                                                                                            || inputField.validateMenu === 'PantoneType') &&
                                                                                                            <label style={{ marginTop: '8px' }}>
                                                                                                                <input type="checkbox" name="quickButton"
                                                                                                                    value={inputField.quickButton}
                                                                                                                    onChange={event => (handleOnSelectRecords(event, index, 'quickButton'))}
                                                                                                                    checked={inputField.quickButton === 1 ? true : false}
                                                                                                                    className="clearOpacity textdesign" />
                                                                                                                <span title={"Quick Master - " + inputField.menuDisplayName} className="text"></span>
                                                                                                            </label>
                                                                                                        }


                                                                                                    </div>
                                                                                                </td>

                                                                                            </>

                                                                                        }


                                                                                    </>
                                                                                }

                                                                            </tr>
                                                                        </Fragment>
                                                                    ))
                                                                    }

                                                                </tbody>
                                                            </table>
                                                            : UserRightsValue.EmployeeInformationId === 0 ? ''
                                                                : <div style={{ textAlign: 'center', padding: '40px' }} className="col-md-12"><span className="red"><b>&nbsp;Properly Not Mapped For Menu[Kindly review role and user creation pages]</b></span></div>

                                                    }
                                                </div>
                                            </div>
                                            <div id="buyerAndBrandsTab" className="tab-pane" style={{ width: "100%" }}>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="Username" > Copied existing user information(Email / Type / Role) </label>
                                                    <span className='input-icon icon-right'>
                                                        <Reactselect name="existingName"
                                                            id='existingName'
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            // value={userList.find(obj => obj.value === UserRightsValue.EmployeeInformationId)} // set selected value
                                                            options={props.location.state.existUser && props.location.state.existUser}
                                                            onChange={event => handleInputChangeUser(event, "existingName")}
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
                                                        ></Reactselect>


                                                    </span>

                                                </div>
                                                <div className="col-md-12" style={{ overflowX: "auto", minHeight: '100px' }}>

                                                    {

                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" >
                                                            <thead>
                                                                <tr><th colSpan='4'>Sampling </th></tr>
                                                                <tr>
                                                                    <th style={{ width: "300px" }}>Buyer</th>
                                                                    <th style={{ width: "300px" }}>Brand</th>

                                                                    <th style={{ width: "40px" }}>
                                                                        <label style={{ marginTop: '8px' }}>
                                                                            <input type="checkbox" name="isAllInfo"
                                                                                value={UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo}
                                                                                onChange={event => (handleOnSelectBrandPrmHeader(event, 'isAllInfo'))}
                                                                                checked={UserRightsValue.MenuBuyerBrandDetailsHeader[0].isAllInfo}
                                                                                className="clearOpacity" title="All Info " />
                                                                            <span className="text">All Info</span>
                                                                        </label></th>
                                                                    <th style={{ width: "40px" }}>
                                                                        <label style={{ marginTop: '8px' }}>
                                                                            <input type="checkbox" name="isMapped"
                                                                                value={UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped}
                                                                                onChange={event => (handleOnSelectBrandPrmHeader(event, 'isMapped'))}
                                                                                checked={UserRightsValue.MenuBuyerBrandDetailsHeader[0].isMapped}
                                                                                className="clearOpacity" title="Mapped" />
                                                                            <span className="text">Mapped</span>
                                                                        </label></th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {UserRightsValue.MenuBuyerBrandDetails && (UserRightsValue.MenuBuyerBrandDetails).map((inputField, index) => (
                                                                    <Fragment key={`${inputField}~${index}`}>

                                                                        <tr>

                                                                            <td>
                                                                                <span>
                                                                                    {inputField.buyerName}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span>
                                                                                    {inputField.brandName}
                                                                                </span>
                                                                            </td>

                                                                            <td>
                                                                                <div style={{ width: "80px" }}>
                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                        <input type="checkbox" name="isAllInfo"
                                                                                            value={inputField.isAllInfo}
                                                                                            onChange={event => (handleOnSelectBrandPrm(event, index, 'isAllInfo'))}
                                                                                            checked={inputField.isAllInfo}
                                                                                            className="clearOpacity" />

                                                                                        <span className="text"></span>
                                                                                    </label>

                                                                                </div>

                                                                            </td>
                                                                            <td>
                                                                                <div style={{ width: "80px" }}>
                                                                                    <label style={{ marginTop: '8px' }}>
                                                                                        <input type="checkbox" name="isMapped"
                                                                                            value={inputField.isMapped}
                                                                                            onChange={event => (handleOnSelectBrandPrm(event, index, 'isMapped'))}
                                                                                            checked={inputField.isMapped}
                                                                                            className="clearOpacity" />
                                                                                        <span className="text"></span>
                                                                                    </label>

                                                                                </div>

                                                                            </td>


                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        &nbsp;
                                        <div className="col-sm-12">
                                            <div className="buttons-preview" align="right">
                                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                    <i className="fa fa-arrow-circle-left"></i>
                                                    &nbsp;Back
                                                </span>
                                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                    <i className="fa fa-times"></i>
                                                    &nbsp;Reset
                                                </span>
                                                {UserRightsValue.MenuDetails.length > 0 &&
                                                    <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveRoleList}>
                                                        <i className="fa fa-check right"></i>&nbsp;
                                                        {ButtonName}
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* </form> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserRightsRegister;

// function BindChildParent(UserRightsValue) {
//     let ParentList;
//     let childParentList;
//     let isShowGenLab, isShowGenStrike, isShowGenTrim;
//     let isShowStyle, isShowOrdLab, isShowOrdStrike, isShowOrdTrim, isShowPurchase, isShowRoute, isShowTNA, isShowTNAReport;
//     let isShowStrikeMaster, isShowTrimMaster;
//     let isShowCompany, isShowSupplier, isShowBuyerList, isShowBuyerInfo;
//     let isShowGroup, isShowCategory, isShowStrikeOffType, isShowTrimType, isShowMeasurement, isShowGroupFType, isShowGroupFPart;
//     let isShowSwatch, isShowRole, isShowPermission, isShowDepartment, isShowSize, isShowGroupSize, isShowProcess;
//     let isShowTaskType, isShowTask;



//     for (var index = 0; index < UserRightsValue.length; index++) {

//         //General(sampling)
//         if (UserRightsValue[index].menuDisplayName === 'General Lab Dip' || UserRightsValue[index].menuDisplayName === 'General Strike Off List' || UserRightsValue[index].menuDisplayName === 'General Trim List') {
//             if (!isShowGenLab && !isShowGenStrike && !isShowGenTrim) {
//                 isShowGenLab = UserRightsValue.find(m => m.menuDisplayName === 'General Lab Dip' && m.showButton === 1);
//                 isShowGenStrike = UserRightsValue.find(m => m.menuDisplayName === 'General Strike Off List' && m.showButton === 1);
//                 isShowGenTrim = UserRightsValue.find(m => m.menuDisplayName === 'General Trim List' && m.showButton === 1);

//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Sampling' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'General' && d.userType === 2 && d.subParentUserType === 1);
//                 if (isShowGenLab || isShowGenStrike || isShowGenTrim) {
//                     // alert(0);
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//                 // else {
//                 //     if (childParentList.length > 0) { childParentList[0].showButton = 0 }
//                 //     if (ParentList.length > 0) { ParentList[0].showButton = 0 }
//                 // }
//             }
//         }
//         //Order(sampling)
//         else if (UserRightsValue[index].menuDisplayName === 'Style' || UserRightsValue[index].menuDisplayName === 'Order Lab Dip' || UserRightsValue[index].menuDisplayName === 'Order Strike Off List' || UserRightsValue[index].menuDisplayName === 'Order Trim List' || UserRightsValue[index].menuDisplayName === 'Purchase Order' || UserRightsValue[index].menuDisplayName === 'Route' || UserRightsValue[index].menuDisplayName === 'TNA' || UserRightsValue[index].menuDisplayName === 'TNA Report') {
//             if (!isShowStyle && !isShowOrdLab && !isShowOrdStrike && !isShowOrdTrim && !isShowPurchase && !isShowRoute && !isShowTNA && !isShowTNAReport) {
//                 // alert(1);
//                 isShowStyle = UserRightsValue.find(m => m.menuDisplayName === 'Style' && m.showButton === 1);
//                 isShowOrdLab = UserRightsValue.find(m => m.menuDisplayName === 'Order Strike Off List' && m.showButton === 1);
//                 isShowOrdStrike = UserRightsValue.find(m => m.menuDisplayName === 'Order Trim List' && m.showButton === 1);
//                 isShowOrdTrim = UserRightsValue.find(m => m.menuDisplayName === 'Order Trim List' && m.showButton === 1);
//                 isShowPurchase = UserRightsValue.find(m => m.menuDisplayName === 'Purchase Order' && m.showButton === 1);
//                 isShowRoute = UserRightsValue.find(m => m.menuDisplayName === 'Route' && m.showButton === 1);
//                 isShowTNA = UserRightsValue.find(m => m.menuDisplayName === 'TNA' && m.showButton === 1);
//                 isShowTNAReport = UserRightsValue.find(m => m.menuDisplayName === 'TNA Report' && m.showButton === 1);

//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Sampling' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Order' && d.userType === 2 && d.subParentUserType === 1);
//                 if (isShowStyle || isShowOrdLab || isShowOrdStrike || isShowOrdTrim || isShowPurchase || isShowRoute || isShowTNA || isShowTNAReport) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//                 // else {
//                 //     if (childParentList.length > 0) { childParentList[0].showButton = 0 }
//                 //     if (ParentList.length > 0) { ParentList[0].showButton = 0 }
//                 // }
//             }

//         }
//         //Component(sampling)
//         else if (UserRightsValue[index].menuDisplayName === 'Strike off Master' || UserRightsValue[index].menuDisplayName === 'Trim Master') {
//             if (!isShowStrikeMaster && !isShowTrimMaster) {
//                 isShowStrikeMaster = UserRightsValue.find(m => m.menuDisplayName === 'Strike off Master' && m.showButton === 1);
//                 isShowTrimMaster = UserRightsValue.find(m => m.menuDisplayName === 'Trim Master' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Sampling' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Component' && d.userType === 2 && d.subParentUserType === 1);

//                 if (isShowStrikeMaster || isShowTrimMaster) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }
//         //Company Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Company') {
//             if (!isShowCompany) {
//                 isShowCompany = UserRightsValue.find(m => m.menuDisplayName === 'Company' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Company Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowCompany) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Buyer Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Buyer List' || UserRightsValue[index].menuDisplayName === 'Buyer Info') {
//             if (!isShowBuyerList && !isShowBuyerInfo) {
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Buyer List' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Buyer Info' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Buyer Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowBuyerList || isShowBuyerInfo) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Supplier(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Supplier') {
//             if (!isShowSupplier) {
//                 isShowSupplier = UserRightsValue.find(m => m.menuDisplayName === 'Supplier' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Supplier Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowSupplier) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }


//         //General(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Branch' || UserRightsValue[index].menuDisplayName === 'City' || UserRightsValue[index].menuDisplayName === 'Season' || UserRightsValue[index].menuDisplayName === 'Gender' || UserRightsValue[index].menuDisplayName === 'Currency') {
//             if (!isShowBuyerList && !isShowBuyerInfo) {
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Buyer List' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Buyer Info' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Buyer Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowBuyerList || isShowBuyerInfo) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Pantone Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Pantone' || UserRightsValue[index].menuDisplayName === 'Pantone Type') {
//             if (!isShowBuyerList && !isShowBuyerInfo) {
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Buyer List' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Buyer Info' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Buyer Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowBuyerList || isShowBuyerInfo) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Component(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Group Type' || UserRightsValue[index].menuDisplayName === 'Category' || UserRightsValue[index].menuDisplayName === 'Strike Off Type' || UserRightsValue[index].menuDisplayName === 'Trim Type' || UserRightsValue[index].menuDisplayName === 'Measurement Point' || UserRightsValue[index].menuDisplayName === 'Fabric Type' || UserRightsValue[index].menuDisplayName === 'Fabric Part') {
//             if (!isShowGroup && !isShowCategory && !isShowStrikeOffType && !isShowTrimType && !isShowMeasurement && !isShowGroupFType && !isShowGroupFPart) {

//                 isShowGroup = UserRightsValue.find(m => m.menuDisplayName === 'Group Type' && m.showButton === 1);
//                 isShowCategory = UserRightsValue.find(m => m.menuDisplayName === 'Category' && m.showButton === 1);
//                 isShowStrikeOffType = UserRightsValue.find(m => m.menuDisplayName === 'Strike Off Type' && m.showButton === 1);
//                 isShowTrimType = UserRightsValue.find(m => m.menuDisplayName === 'Trim Type' && m.showButton === 1);
//                 isShowMeasurement = UserRightsValue.find(m => m.menuDisplayName === 'Measurement Point' && m.showButton === 1);
//                 isShowGroupFType = UserRightsValue.find(m => m.menuDisplayName === 'Fabric Type' && m.showButton === 1);
//                 isShowGroupFPart = UserRightsValue.find(m => m.menuDisplayName === 'Fabric Part' && m.showButton === 1);

//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Component' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowGroup || isShowCategory || isShowStrikeOffType || isShowTrimType || isShowMeasurement || isShowGroupFType || isShowGroupFPart) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Component1(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Swatch' || UserRightsValue[index].menuDisplayName === 'Role' || UserRightsValue[index].menuDisplayName === 'User Permission' || UserRightsValue[index].menuDisplayName === 'Department' || UserRightsValue[index].menuDisplayName === 'Size' || UserRightsValue[index].menuDisplayName === 'Group Size' || UserRightsValue[index].menuDisplayName === 'Process Type') {
//             if (!isShowSwatch && !isShowRole && !isShowPermission && !isShowDepartment && !isShowSize && !isShowGroupSize && !isShowProcess) {

//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Swatch' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Role' && m.showButton === 1);
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'User Permission' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Department' && m.showButton === 1);
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Size' && m.showButton === 1);
//                 isShowBuyerInfo = UserRightsValue.find(m => m.menuDisplayName === 'Group Size' && m.showButton === 1);
//                 isShowBuyerList = UserRightsValue.find(m => m.menuDisplayName === 'Process Type' && m.showButton === 1);


//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Component 1' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowSwatch || isShowRole || isShowPermission || isShowDepartment || isShowSize || isShowGroupSize || isShowProcess) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//         //Task Info(Master)
//         else if (UserRightsValue[index].menuDisplayName === 'Task Type' || UserRightsValue[index].menuDisplayName === 'Task') {
//             if (!isShowTaskType && !isShowTask) {
//                 isShowTaskType = UserRightsValue.find(m => m.menuDisplayName === 'Task Type' && m.showButton === 1);
//                 isShowTask = UserRightsValue.find(m => m.menuDisplayName === 'Task' && m.showButton === 1);
//                 ParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Master' && d.subParentUserType === 77);
//                 childParentList = UserRightsValue.filter(d => d.menuDisplayName === 'Task Info' && d.userType === 2 && d.subParentUserType === 3);
//                 if (isShowTaskType || isShowTask) {
//                     if (childParentList.length > 0) { childParentList[0].showButton = 1 }
//                     if (ParentList.length > 0) { ParentList[0].showButton = 1 }
//                 }
//             }
//         }

//     }



// }

function BindChildParent(UserRightsValue) {
    let ParentList;
    let childParentList;
    let isShowGenLab, isShowGenStrike, isShowGenTrim, isShowGeneralTNAReport, isShowGenLabProduction, isShowGenStrikeProduction, isShowGenTrimProduction;
    let isShowStyle, isShowOrdLab, isShowOrdStrike, isShowOrdTrim, isShowPurchase, isShowRoute, isShowTNA, isShowTNAReport;
    let isShowStyleProduction, isShowOrdLabProduction, isShowOrdStrikeProduction, isShowOrdTrimProduction, isShowPurchaseProduction, isShowRouteProduction, isShowTNAProduction, isShowTNAReportProduction;
    let isShowStrikeMaster, isShowTrimMaster,isShowProductionStrikeMaster, isShowProductionTrimMaster;
    let isShowSamples,isShowProductionSamples, isShowTesting,isShowDailyProductionEntry,isShowInternalQAAudit,isShowFinalInspection,isShowShipmentBooking,isShowInvoice,isShowPayments;
    let isShowCompany, isShowSupplier, isShowBuyerList, isShowBuyerInfo;
    let isShowGroup, isShowCategory, isShowStrikeOffType, isShowTrimType, isShowMeasurement, isShowGroupFType, isShowGroupFPart, isShowYarnType, isShowAgency, isShowQACommentPoint;
    let isShowSwatch, isShowRole, isShowPermission, isShowDepartment, isShowSize, isShowGroupSize, isShowProcess;
    let isShowBranch, isShowCity,isShowState, isShowSeason, isShowGender, isShowCurrency;
    let isShowTaskType, isShowTask;
    let isShowFIDefecteDetails,isShowFIWorkmanshipDetails,isShowFIWorkmanshipTemplate,isShowFIPackageItem,isShowFIPackageTemplate,isShowFIImageName,isShowFIAvailableInfoItem,isShowFIAvailableInfoTemplate,
        isShowFICsrDetails,isShowFICsrTemplate,isShowFICsrDropdown,isShowFIWarehouse,isShowFIBCWarehouse,isShowFIAQL,isShowFITestingItem,isShowFITestingTemplate,isShowFIAQLInspection,isShowFIAQLTolerance;
        let isShowTestName, isShowTestItem,isShowTestField,isShowTestStandard,isShowTestMapping,isShowTestAllignment,isShowTestColoreDepth,isShowTestBrandWiseMaster;
    let isShowReportModule, isShowReportTemplate,isShowColumnInformation;
    let isShowCommentsReport, isShowTNADetailsReport,isShowScheduleReport;
    let isShowCourier;
    let isGeneral, isMaster, isGeneralInfo,isGeneralProduction,isReport;

     debugger;
    for (var index = 0; index < UserRightsValue.length; index++) {

        //General(sampling)
        if (UserRightsValue[index].validateMenu === 'LabDip' || UserRightsValue[index].validateMenu === 'StrikeOffList' || UserRightsValue[index].validateMenu === 'TrimList') {
            if (!isShowGenLab && !isShowGenStrike && !isShowGenTrim) {
                isShowGenLab = UserRightsValue.find(m => m.validateMenu === 'LabDip' && m.showButton === 1);
                isShowGenStrike = UserRightsValue.find(m => m.validateMenu === 'StrikeOffList' && m.showButton === 1);
                isShowGenTrim = UserRightsValue.find(m => m.validateMenu === 'TrimList' && m.showButton === 1);
                isShowGeneralTNAReport = UserRightsValue.find(m => m.validateMenu === 'GeneralTNAReport' && m.showButton === 1);

                // ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingGeneral' && d.userType === 2 && d.subParentUserType === 1);
                if (isShowGenLab || isShowGenStrike || isShowGenTrim || isShowGeneralTNAReport) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isGeneral = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }
        //Order(sampling)
        else if (UserRightsValue[index].validateMenu === 'Style' || UserRightsValue[index].validateMenu === 'OrderLabDip' || UserRightsValue[index].validateMenu === 'OrderStrikeOff' || UserRightsValue[index].validateMenu === 'OrderTrim' || UserRightsValue[index].validateMenu === 'PurchaseOrder' || UserRightsValue[index].validateMenu === 'Route' || UserRightsValue[index].validateMenu === 'TNA' || UserRightsValue[index].validateMenu === 'TNAReport') {
            if (!isShowStyle && !isShowOrdLab && !isShowOrdStrike && !isShowOrdTrim && !isShowPurchase && !isShowRoute && !isShowTNA && !isShowTNAReport) {
                // alert(1);
                debugger;
                isShowStyle = UserRightsValue.find(m => m.validateMenu === 'Style' && m.showButton === 1);
                isShowOrdLab = UserRightsValue.find(m => m.validateMenu === 'OrderLabDip' && m.showButton === 1);
                isShowOrdStrike = UserRightsValue.find(m => m.validateMenu === 'OrderStrikeOff' && m.showButton === 1);
                isShowOrdTrim = UserRightsValue.find(m => m.validateMenu === 'OrderTrim' && m.showButton === 1);
                isShowPurchase = UserRightsValue.find(m => m.validateMenu === 'PurchaseOrder' && m.showButton === 1);
                isShowRoute = UserRightsValue.find(m => m.validateMenu === 'Route' && m.showButton === 1);
                isShowTNA = UserRightsValue.find(m => m.validateMenu === 'TNA' && m.showButton === 1);
                isShowTNAReport = UserRightsValue.find(m => m.validateMenu === 'TNAReport' && m.showButton === 1);

                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingOrder' && d.userType === 2 && d.subParentUserType === 1);
                if (isShowStyle || isShowOrdLab || isShowOrdStrike || isShowOrdTrim || isShowPurchase || isShowRoute || isShowTNA || isShowTNAReport) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isGeneral = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }

        }
        //Component(sampling)
        else if (UserRightsValue[index].validateMenu === 'StrikeoffMaster' || UserRightsValue[index].validateMenu === 'TrimMaster') {
            if (!isShowStrikeMaster && !isShowTrimMaster) {
                isShowStrikeMaster = UserRightsValue.find(m => m.validateMenu === 'StrikeoffMaster' && m.showButton === 1);
                isShowTrimMaster = UserRightsValue.find(m => m.validateMenu === 'TrimMaster' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingComponent' && d.userType === 2 && d.subParentUserType === 1);

                if (isShowStrikeMaster || isShowTrimMaster) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isGeneral = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Component(Production)
        else if (UserRightsValue[index].validateMenu === 'ProductionStrikeoffMaster' || UserRightsValue[index].validateMenu === 'ProductionTrimMaster') {
            if (!isShowProductionStrikeMaster && !isShowProductionTrimMaster) {
                debugger;
                isShowProductionStrikeMaster = UserRightsValue.find(m => m.validateMenu === 'ProductionStrikeoffMaster' && m.showButton === 1);
                isShowProductionTrimMaster = UserRightsValue.find(m => m.validateMenu === 'ProductionTrimMaster' && m.showButton === 1);
                ParentList = UserRightsValue.filter(d => d.validateMenu === 'Production' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionComponent' && d.userType === 2 && d.subParentUserType === 2);

                if (isShowProductionStrikeMaster || isShowProductionTrimMaster) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isGeneralProduction = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }


        

        //Order(Production)
        else if (UserRightsValue[index].validateMenu === 'ProductionStyle' || UserRightsValue[index].validateMenu === 'ProductionOrderLabDip' || UserRightsValue[index].validateMenu === 'ProductionOrderStrikeOff' || UserRightsValue[index].validateMenu === 'ProductionOrderTrim' || UserRightsValue[index].validateMenu === 'ProductionPurchaseOrder' || UserRightsValue[index].validateMenu === 'ProductionRoute' || UserRightsValue[index].validateMenu === 'ProductionTNA' || UserRightsValue[index].validateMenu === 'ProductionTNAReport') {
            if (!isShowStyleProduction && !isShowOrdLabProduction && !isShowOrdStrikeProduction && !isShowOrdTrimProduction && !isShowPurchaseProduction && !isShowRouteProduction && !isShowTNAProduction && !isShowTNAReportProduction) {
                // alert(1);
                isShowStyleProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionStyle' && m.showButton === 1);
                isShowOrdLabProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionOrderLabDip' && m.showButton === 1);
                isShowOrdStrikeProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionOrderStrikeOff' && m.showButton === 1);
                isShowOrdTrimProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionOrderTrim' && m.showButton === 1);
                isShowPurchaseProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionPurchaseOrder' && m.showButton === 1);
                isShowRouteProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionRoute' && m.showButton === 1);
                isShowTNAProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionTNA' && m.showButton === 1);
                isShowTNAReportProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionTNAReport' && m.showButton === 1);

                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionOrder' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowStyleProduction || isShowOrdLabProduction || isShowOrdStrikeProduction || isShowOrdTrimProduction || isShowPurchaseProduction || isShowRouteProduction || isShowTNAProduction || isShowTNAReportProduction) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isGeneralProduction = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //isGeneralProduction = false;
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }

        }
      //Samples (Sampling)
        else if ( UserRightsValue[index].validateMenu === 'Samples') {
            if (!isShowSamples) {
                
                isShowSamples = UserRightsValue.find(m => m.validateMenu === 'Samples' && m.showButton === 1);
               // if(isShowSamples){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);}
               
               childParentList = UserRightsValue.filter(d => d.validateMenu === 'SamplingSample' && d.userType === 2 && d.subParentUserType === 1);
               
                if (isShowSamples ) 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                         //isGeneralProduction = true;
                         isGeneral = true;
                    }
                    else 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                     
                    }
                    
                   
            }
        }

         //Samples (Production)
         else if ( UserRightsValue[index].validateMenu === 'ProductionSample') {
            if (!isShowProductionSamples) {
                
                isShowProductionSamples = UserRightsValue.find(m => m.validateMenu === 'ProductionSample' && m.showButton === 1);
               // if(isShowSamples){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);}
               
               
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowProductionSamples ) 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                         isGeneralProduction = true;
                    }
                    else 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                     
                    }
                    
                   
            }
        }

        //General(Production)
        if (UserRightsValue[index].validateMenu === 'ProductionLabDip' || UserRightsValue[index].validateMenu === 'ProductionStrikeOffList' || UserRightsValue[index].validateMenu === 'ProductionTrimList') {
            if (!isShowGenLabProduction && !isShowGenStrikeProduction && !isShowGenTrimProduction) {
                isShowGenLabProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionLabDip' && m.showButton === 1);
                isShowGenStrikeProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionStrikeOffList' && m.showButton === 1);
                isShowGenTrimProduction = UserRightsValue.find(m => m.validateMenu === 'ProductionTrimList' && m.showButton === 1);

                // ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionGeneral' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowGenLabProduction || isShowGenStrikeProduction || isShowGenTrimProduction) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    //isGeneral = true;
                    isGeneralProduction = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Production
        else if ( UserRightsValue[index].validateMenu === 'Testing') {
            if (!isShowTesting) {
                 isShowTesting = UserRightsValue.find(m => m.validateMenu === 'Testing' && m.showButton === 1);
                //if(isShowTesting){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionTesting' && d.userType === 2 && d.subParentUserType === 2);}
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionTesting' && d.userType === 2 && d.subParentUserType === 2);
                if ( isShowTesting ) 
                {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    
                    isGeneralProduction = true;
                }
                else 
                {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    
                }
            }
        }

       

        else if ( UserRightsValue[index].validateMenu === 'DailyProductionEntry') {
           
            if (!isShowDailyProductionEntry) {
                
                isShowDailyProductionEntry = UserRightsValue.find(m => m.validateMenu === 'DailyProductionEntry' && m.showButton === 1);
               // if(isShowSamples){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);}

                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionProduction' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowDailyProductionEntry ) 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                         isGeneralProduction = true;
                    }
                    else 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                     
                    }
                    
                   
            }
        }

        else if ( UserRightsValue[index].validateMenu === 'InternalQAAudit' || UserRightsValue[index].validateMenu === 'InternalFinalInspection') {
           debugger;
            if (!isShowInternalQAAudit && !isShowFinalInspection) {
                
                isShowInternalQAAudit = UserRightsValue.find(m => m.validateMenu === 'InternalQAAudit' && m.showButton === 1);
                isShowFinalInspection = UserRightsValue.find(m => m.validateMenu === 'InternalFinalInspection' && m.showButton === 1);
               // if(isShowSamples){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);}

                childParentList = UserRightsValue.filter(d => d.validateMenu === 'InspectionQualityControl' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowInternalQAAudit || isShowFinalInspection) 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                         isGeneralProduction = true;
                    }
                    else 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                     
                    }
                    
                   
            }
        }

        else if ( UserRightsValue[index].validateMenu === 'ShipmentBooking' || UserRightsValue[index].validateMenu === 'Invoice') {
           
            if (!isShowShipmentBooking && !isShowInvoice) {
                
                isShowShipmentBooking = UserRightsValue.find(m => m.validateMenu === 'ShipmentBooking' && m.showButton === 1);
                isShowInvoice = UserRightsValue.find(m => m.validateMenu === 'Invoice' && m.showButton === 1);
               // if(isShowSamples){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);}

                childParentList = UserRightsValue.filter(d => d.validateMenu === 'Shipment' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowShipmentBooking || isShowInvoice) 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                         isGeneralProduction = true;
                    }
                    else 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                     
                    }
                    
                   
            }
        }


        else if ( UserRightsValue[index].validateMenu === 'Payments') {
           
            if (!isShowPayments) {
                
                isShowPayments = UserRightsValue.find(m => m.validateMenu === 'Payments' && m.showButton === 1);
               // if(isShowSamples){ childParentList = UserRightsValue.filter(d => d.validateMenu === 'ProductionSamples' && d.userType === 2 && d.subParentUserType === 2);}

                childParentList = UserRightsValue.filter(d => d.validateMenu === 'Payment' && d.userType === 2 && d.subParentUserType === 2);
                if (isShowPayments ) 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                         isGeneralProduction = true;
                    }
                    else 
                    {
                        if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                     
                    }
                    
                   
            }
        }

        

        // End Production
        //Company Info(Master)
        else if (UserRightsValue[index].validateMenu === 'Company') {
            if (!isShowCompany) {

                isShowCompany = UserRightsValue.find(m => m.validateMenu === 'Company' && m.showButton === 1);
                // ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'CompanyInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowCompany) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Buyer Info(Master)
        else if (UserRightsValue[index].validateMenu === 'BuyerList' || UserRightsValue[index].validateMenu === 'BuyerInfo') {
            if (!isShowBuyerList && !isShowBuyerInfo) {
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'BuyerList' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'BuyerInfo' && m.showButton === 1);
                // ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'BuyerInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBuyerList || isShowBuyerInfo) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Supplier(Master)
        else if (UserRightsValue[index].validateMenu === 'Supplier') {
            if (!isShowSupplier) {
                isShowSupplier = UserRightsValue.find(m => m.validateMenu === 'Supplier' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'SupplierInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowSupplier) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }


        //General(Master)
        else if (UserRightsValue[index].validateMenu === 'Branch' || UserRightsValue[index].validateMenu === 'City' || UserRightsValue[index].validateMenu === 'State/Country' || UserRightsValue[index].validateMenu === 'Season' || UserRightsValue[index].validateMenu === 'Gender' || UserRightsValue[index].validateMenu === 'Currency') {
            if (!isShowBranch && !isShowCity && !isShowSeason && !isShowGender && !isShowCurrency) {
                isShowBranch = UserRightsValue.find(m => m.validateMenu === 'Branch' && m.showButton === 1);
                isShowCity = UserRightsValue.find(m => m.validateMenu === 'City' && m.showButton === 1);
                isShowState = UserRightsValue.find(m => m.validateMenu === 'State/Country' && m.showButton === 1);
                isShowSeason = UserRightsValue.find(m => m.validateMenu === 'Season' && m.showButton === 1);
                isShowGender = UserRightsValue.find(m => m.validateMenu === 'Gender' && m.showButton === 1);
                isShowCurrency = UserRightsValue.find(m => m.validateMenu === 'Currency' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'General' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBranch || isShowCity || isShowState || isShowSeason || isShowGender || isShowCurrency) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    // if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Pantone Info(Master)
        else if (UserRightsValue[index].validateMenu === 'Pantone' || UserRightsValue[index].validateMenu === 'PantoneType') {
            if (!isShowBuyerList && !isShowBuyerInfo) {
                isShowBuyerList = UserRightsValue.find(m => m.validateMenu === 'Pantone' && m.showButton === 1);
                isShowBuyerInfo = UserRightsValue.find(m => m.validateMenu === 'PantoneType' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'PantoneInformation' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowBuyerList || isShowBuyerInfo) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Component(Master)
        else if (UserRightsValue[index].validateMenu === 'GroupType' || UserRightsValue[index].validateMenu === 'Category' || UserRightsValue[index].validateMenu === 'StrikeOffType' || UserRightsValue[index].validateMenu === 'TrimType' || UserRightsValue[index].validateMenu === 'MeasurementPoint' || UserRightsValue[index].validateMenu === 'FabricType' || UserRightsValue[index].validateMenu === 'FabricPart'
            || UserRightsValue[index].validateMenu === 'YarnType' || UserRightsValue[index].validateMenu === 'Agency') {
            if (!isShowGroup && !isShowCategory && !isShowStrikeOffType && !isShowTrimType && !isShowMeasurement && !isShowGroupFType && !isShowGroupFPart && !isShowYarnType && !isShowAgency) {

                isShowGroup = UserRightsValue.find(m => m.validateMenu === 'GroupType' && m.showButton === 1);
                isShowCategory = UserRightsValue.find(m => m.validateMenu === 'Category' && m.showButton === 1);
                isShowStrikeOffType = UserRightsValue.find(m => m.validateMenu === 'StrikeOffType' && m.showButton === 1);
                isShowTrimType = UserRightsValue.find(m => m.validateMenu === 'TrimType' && m.showButton === 1);
                isShowMeasurement = UserRightsValue.find(m => m.validateMenu === 'MeasurementPoint' && m.showButton === 1);
                isShowGroupFType = UserRightsValue.find(m => m.validateMenu === 'FabricType' && m.showButton === 1);
                isShowGroupFPart = UserRightsValue.find(m => m.validateMenu === 'FabricPart' && m.showButton === 1);
                isShowYarnType = UserRightsValue.find(m => m.validateMenu === 'YarnType' && m.showButton === 1);
                isShowAgency = UserRightsValue.find(m => m.validateMenu === 'Agency' && m.showButton === 1);
                isShowQACommentPoint = UserRightsValue.find(m => m.validateMenu === 'QACommentPoint' && m.showButton === 1);

                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'Component' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowGroup || isShowCategory || isShowStrikeOffType || isShowTrimType || isShowMeasurement || isShowGroupFType || isShowGroupFPart || isShowYarnType || isShowAgency || isShowQACommentPoint) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Component1(Master)
        else if (UserRightsValue[index].validateMenu === 'Swatch' || UserRightsValue[index].validateMenu === 'Role' || UserRightsValue[index].validateMenu === 'UserPermission' || UserRightsValue[index].validateMenu === 'Department' || UserRightsValue[index].validateMenu === 'Size' || UserRightsValue[index].validateMenu === 'GroupSize' || UserRightsValue[index].validateMenu === 'ProcessType') {
            if (!isShowSwatch && !isShowRole && !isShowPermission && !isShowDepartment && !isShowSize && !isShowGroupSize && !isShowProcess) {

                isShowSwatch = UserRightsValue.find(m => m.validateMenu === 'Swatch' && m.showButton === 1);
                isShowRole = UserRightsValue.find(m => m.validateMenu === 'Role' && m.showButton === 1);
                isShowPermission = UserRightsValue.find(m => m.validateMenu === 'UserPermission' && m.showButton === 1);
                isShowDepartment = UserRightsValue.find(m => m.validateMenu === 'Department' && m.showButton === 1);
                isShowSize = UserRightsValue.find(m => m.validateMenu === 'Size' && m.showButton === 1);
                isShowGroupSize = UserRightsValue.find(m => m.validateMenu === 'GroupSize' && m.showButton === 1);
                isShowProcess = UserRightsValue.find(m => m.validateMenu === 'ProcessType' && m.showButton === 1);


                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'AddtionalComponent' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowSwatch || isShowRole || isShowPermission || isShowDepartment || isShowSize || isShowGroupSize || isShowProcess) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Task Info(Master)
        else if (UserRightsValue[index].validateMenu === 'TaskType' || UserRightsValue[index].validateMenu === 'Task') {
            if (!isShowTaskType && !isShowTask) {
                isShowTaskType = UserRightsValue.find(m => m.validateMenu === 'TaskType' && m.showButton === 1);
                isShowTask = UserRightsValue.find(m => m.validateMenu === 'Task' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'TaskInfo' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowTaskType || isShowTask) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Final Inspection(Master)
        else if (UserRightsValue[index].validateMenu === 'Defects' || UserRightsValue[index].validateMenu === 'Workmanship' 
            || UserRightsValue[index].validateMenu === 'WorkmanshipGroup' || UserRightsValue[index].validateMenu === 'Package' 
            || UserRightsValue[index].validateMenu === 'PackageGroup' || UserRightsValue[index].validateMenu === 'Item' 
            || UserRightsValue[index].validateMenu === 'Template' ||  UserRightsValue[index].validateMenu ===  'PackageImageList') {
            if (!isShowFIDefecteDetails && !isShowFIWorkmanshipDetails && !isShowFIWorkmanshipTemplate && !isShowFIPackageItem 
                && !isShowFIPackageTemplate && !isShowFIImageName && !isShowFIAvailableInfoItem && !isShowFIAvailableInfoTemplate) {

                    isShowFIDefecteDetails = UserRightsValue.find(m => m.validateMenu === 'Defects' && m.showButton === 1);
                    isShowFIWorkmanshipDetails = UserRightsValue.find(m => m.validateMenu === 'Workmanship' && m.showButton === 1);
                    isShowFIWorkmanshipTemplate = UserRightsValue.find(m => m.validateMenu === 'WorkmanshipGroup' && m.showButton === 1);
                    isShowFIPackageItem = UserRightsValue.find(m => m.validateMenu === 'Package' && m.showButton === 1);
                    isShowFIPackageTemplate = UserRightsValue.find(m => m.validateMenu === 'PackageGroup' && m.showButton === 1);
                    isShowFIAvailableInfoItem = UserRightsValue.find(m => m.validateMenu === 'Item' && m.showButton === 1);
                    isShowFIAvailableInfoTemplate = UserRightsValue.find(m => m.validateMenu === 'Template' && m.showButton === 1);
                    isShowFIImageName = UserRightsValue.find(m => m.validateMenu === 'PackageImageList' && m.showButton === 1);
                
                debugger;
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'FinalInspection' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowFIDefecteDetails || isShowFIWorkmanshipDetails || isShowFIWorkmanshipTemplate 
                     || isShowFIPackageItem || isShowFIPackageTemplate || isShowFIAvailableInfoItem
                    || isShowFIAvailableInfoTemplate || isShowFIImageName ) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Final Inspection 1 (Master)
        else if (UserRightsValue[index].validateMenu === 'CSRDetails' || UserRightsValue[index].validateMenu === 'CSRTemplate' 
            || UserRightsValue[index].validateMenu === 'CSRDropdown'   || UserRightsValue[index].validateMenu === 'Warehouse' 
            || UserRightsValue[index].validateMenu === 'BuyerCustomerWarehouse' || UserRightsValue[index].validateMenu === 'AQL' 
            || UserRightsValue[index].validateMenu === 'TestingItem' || UserRightsValue[index].validateMenu === 'TestingTemplate' 
            || UserRightsValue[index].validateMenu === 'AQLInspection' ||  UserRightsValue[index].validateMenu === 'AQLTolerance') {
            if (    !isShowFICsrDetails && !isShowFICsrTemplate  && !isShowFICsrDropdown
                 && !isShowFIWarehouse && !isShowFIBCWarehouse && !isShowFIAQL 
                 && !isShowFITestingItem && !isShowFITestingTemplate 
                 && !isShowFIAQLInspection && !isShowFIAQLTolerance) {

                isShowFICsrDetails = UserRightsValue.find(m => m.validateMenu === 'CSRDetails' && m.showButton === 1);
                isShowFICsrTemplate = UserRightsValue.find(m => m.validateMenu === 'CSRTemplate' && m.showButton === 1);
                isShowFICsrDropdown = UserRightsValue.find(m => m.validateMenu === 'CSRDropdown' && m.showButton === 1);
                isShowFIWarehouse = UserRightsValue.find(m => m.validateMenu === 'Warehouse' && m.showButton === 1);
                isShowFIBCWarehouse = UserRightsValue.find(m => m.validateMenu === 'BuyerCustomerWarehouse' && m.showButton === 1);
                isShowFIAQL = UserRightsValue.find(m => m.validateMenu === 'AQL' && m.showButton === 1);
                isShowFITestingItem = UserRightsValue.find(m => m.validateMenu === 'TestingItem' && m.showButton === 1);
                isShowFITestingTemplate = UserRightsValue.find(m => m.validateMenu === 'TestingTemplate' && m.showButton === 1);
                isShowFIAQLInspection = UserRightsValue.find(m => m.validateMenu === 'AQLInspection' && m.showButton === 1);
                isShowFIAQLTolerance = UserRightsValue.find(m => m.validateMenu === 'AQLTolerance' && m.showButton === 1);
                debugger;
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'AdditionalFinalInspection' && d.userType === 2 && d.subParentUserType === 3);
                if ( isShowFICsrDetails || isShowFICsrTemplate || isShowFICsrDropdown
                     || isShowFIWarehouse || isShowFIBCWarehouse || isShowFIAQL 
                     || isShowFITestingItem || isShowFITestingTemplate || isShowFIAQLInspection 
                     || isShowFIAQLTolerance ) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

        //Testing (Master)
        else if (UserRightsValue[index].validateMenu === 'TestName' || UserRightsValue[index].validateMenu === 'TestItem' || UserRightsValue[index].validateMenu === 'TestField'
             || UserRightsValue[index].validateMenu === 'TestStandard' || UserRightsValue[index].validateMenu === 'Mapping' || UserRightsValue[index].validateMenu === 'Alignment'
              || UserRightsValue[index].validateMenu === 'ColorDepth'|| UserRightsValue[index].validateMenu === 'BrandWiseStandard') 
              {
            if (!isShowTestName && !isShowTestItem && !isShowTestField && !isShowTestStandard && !isShowTestMapping 
                && !isShowTestMapping && !isShowTestAllignment && !isShowTestColoreDepth && !isShowTestBrandWiseMaster) {

                isShowTestName = UserRightsValue.find(m => m.validateMenu === 'TestName' && m.showButton === 1);
                isShowTestItem = UserRightsValue.find(m => m.validateMenu === 'TestItem' && m.showButton === 1);
                isShowTestField = UserRightsValue.find(m => m.validateMenu === 'TestField' && m.showButton === 1);
                isShowTestStandard = UserRightsValue.find(m => m.validateMenu === 'TestStandard' && m.showButton === 1);
                isShowTestMapping = UserRightsValue.find(m => m.validateMenu === 'Mapping' && m.showButton === 1);
                isShowTestAllignment = UserRightsValue.find(m => m.validateMenu === 'Alignment' && m.showButton === 1);
                isShowTestColoreDepth = UserRightsValue.find(m => m.validateMenu === 'ColorDepth' && m.showButton === 1);
                isShowTestBrandWiseMaster = UserRightsValue.find(m => m.validateMenu === 'BrandWiseStandard' && m.showButton === 1);


                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'MasterTesting' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowTestName || isShowTestItem || isShowTestField || isShowTestStandard || isShowTestMapping || isShowTestAllignment 
                    || isShowTestColoreDepth || isShowTestBrandWiseMaster ) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }

           //Custom Report(Master)
           else if (UserRightsValue[index].validateMenu === 'ReportModulecategory' || UserRightsValue[index].validateMenu === 'ReportTemplate' || UserRightsValue[index].validateMenu === 'ColumnInformation') {
            if (!isShowReportModule && !isShowReportTemplate && !isShowColumnInformation) {
                isShowReportModule = UserRightsValue.find(m => m.validateMenu === 'ReportModulecategory' && m.showButton === 1);
                isShowReportTemplate = UserRightsValue.find(m => m.validateMenu === 'ReportTemplate' && m.showButton === 1);
                isShowColumnInformation = UserRightsValue.find(m => m.validateMenu === 'ColumnInformation' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'CustomReport' && d.userType === 2 && d.subParentUserType === 3);
                if (isShowReportModule || isShowReportTemplate || isShowColumnInformation) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isMaster = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }
        
        
        //courier info(general)
        else if (UserRightsValue[index].validateMenu === 'Courier') {
            if (!isShowCourier) {
                isShowCourier = UserRightsValue.find(m => m.validateMenu === 'Courier' && m.showButton === 1);
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'CourierInformation' && d.userType === 2 && d.subParentUserType === 102);
                if (isShowCourier) {
                    if (childParentList.length > 0) {
                        childParentList[0].showButton = 1
                    }
                    isGeneralInfo = true;
                }
                else {
                    if (childParentList.length > 0) {
                        childParentList[0].showButton = 0
                    }
                }
            }
        }

         //Common Report Information(Report)
         else if (UserRightsValue[index].validateMenu === 'CommentsReport' || UserRightsValue[index].validateMenu === 'TNADetailsReport' || UserRightsValue[index].validateMenu === 'ScheduleReport') {
            if (!isShowCommentsReport && !isShowTNADetailsReport && !isShowScheduleReport) {
                isShowCommentsReport = UserRightsValue.find(m => m.validateMenu === 'CommentsReport' && m.showButton === 1);
                isShowTNADetailsReport = UserRightsValue.find(m => m.validateMenu === 'TNADetailsReport' && m.showButton === 1);
                isShowScheduleReport = UserRightsValue.find(m => m.validateMenu === 'ScheduleReport' && m.showButton === 1);
                //ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
                debugger;
                childParentList = UserRightsValue.filter(d => d.validateMenu === 'ReportInformation' && d.userType === 2 && d.subParentUserType === 118);
                if (isShowCommentsReport || isShowTNADetailsReport || isShowScheduleReport) {
                    if (childParentList.length > 0) { childParentList[0].showButton = 1 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 1 }
                    isReport = true;
                }
                else {
                    if (childParentList.length > 0) { childParentList[0].showButton = 0 }
                    //if (ParentList.length > 0) { ParentList[0].showButton = 0 }
                }
            }
        }
    }

    ParentList = UserRightsValue.filter(d => d.validateMenu === 'Master' && d.subParentUserType === 77);
    if (ParentList && ParentList.length > 0) {
        ParentList[0].showButton = ParentList.length > 0 && isMaster ? 1 : 0
    }
    ParentList = UserRightsValue.filter(d => d.validateMenu === 'Sampling' && d.subParentUserType === 77);
    if (ParentList && ParentList.length > 0) {
        ParentList[0].showButton = ParentList.length > 0 && isGeneral ? 1 : 0
    }

    ParentList = UserRightsValue.filter(d => d.validateMenu === 'Production' && d.subParentUserType === 77);
    if (ParentList && ParentList.length > 0) {
        ParentList[0].showButton = ParentList.length > 0 && isGeneralProduction ? 1 : 0
    }
    ParentList = UserRightsValue.filter(d => d.validateMenu === 'General' && d.subParentUserType === 77);
    if (ParentList && ParentList.length > 0) {
        ParentList[0].showButton = ParentList.length > 0 && isGeneralInfo ? 1 : 0
    }
    ParentList = UserRightsValue.filter(d => d.validateMenu === 'Report' && d.subParentUserType === 77);
    if (ParentList && ParentList.length > 0) {
        ParentList[0].showButton = ParentList.length > 0 && isReport ? 1 : 0
    }

}



