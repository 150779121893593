import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTNARouteList, getRouteInfoList, setTNADrpRouteID, loadTNATaskOwner, getTNACompanyHolidayList, insertTNA, setTNATaskInputFields, loadTNASupplierEmployeeList } from "../../actions/tna";
import Reactselect from 'react-select';
import { Modal, Button } from "react-bootstrap";
import taskService from "../../services/Master/TaskService";
import TnaService from "../../services/TNA/TNAService";
import Nodify from "../Common/ReactNotification";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { retrieveTask, loadTaskList, loadTaskHolderList } from "../../actions/task";
import { loadTaskTypeList } from "../../actions/taskType";
import { loadLeaveDaysList } from "../../actions/leaveDay";
import { loadRouteDependencyList } from "../../actions/route";
import DatePicker from "react-datepicker";
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from "moment";


import StyleService from "../../services/Style/styleService";
import { loadCubeBuyerSupplierEmp,loadCubeEmp } from "../../actions/common";
import StyleList from "../Style/StyleList";

const TNARoute = (props) => {
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;
    // End

    //reducer
    const getRouteList = useSelector((state) => state.tna.drpTNARouteList);
    const AllRouteInfo = useSelector((state) => state.tna.TNAAllRouteInfo);
    const getTaskTypeList = useSelector((state) => state.taskType.drpTaskTypeMapList);
    const { user: currentUser } = useSelector((state) => state.auth);
    const getLeaveDaysList = useSelector((state) => state.leaveDay.drpLeaveDaysList);
    const ExistingList = useSelector((state) => state.task.taskList);
    let AllTaskList = useSelector((state) => state.task.drpAllTaskList);
    if (IsProduction === 1) {
        AllTaskList = AllTaskList.filter(x => x.taskOrderTypeName === "Sample" || x.taskOrderTypeName === "Sample & Production")
    }
    else if (IsProduction === 2) {
        AllTaskList = AllTaskList.filter(x => x.taskOrderTypeName === "Production" || x.taskOrderTypeName === "Sample & Production")
    }
    const TaskList = AllTaskList;
    const getDependencyList = useSelector((state) => state.route.routeDependencyList);
    const getRouteID = useSelector((state) => state.tna.drpTNARouteID);
    const taskHolderList = useSelector((state) => state.task.drpTaskHolderList);
    const drpTNATaskOwnerList = useSelector((state) => state.tna.drpTNATaskOwnerList);
    const getCompanyHolidayList = useSelector((state) => state.tna.TNACompanyHolidayList);
    const getTNABrandID = useSelector((state) => state.tna.TNABrandID);
    const getTNASeasonID = useSelector((state) => state.tna.TNASeasonID);
    const getTNASupplierID = useSelector((state) => state.tna.TNASupplierID);
    const getTNATaskInputFields = useSelector((state) => state.tna.TNATaskInputFields);
    const SupplierID = useSelector((state) => state.route.SupplierID);
   
    const isLoadingTNA = useSelector((state) => state.tna.isLoadingTNA);
    const TNAMultiTask = useSelector((state) => state.tna.TNAMultiTask);
    const SupplierEmployeeList = useSelector((state) => state.tna.drpSupplierEmployeeList);
    const [getIsSearchSubmitted, setIsSearchSubmitted] = useState(false);
    const [RouteID, setRouteID] = useState(0);
    const [getIsAutoExfactory, setIsAutoExfactory] = useState(false);
    const [getAutoExfactorytext, setAutoExfactorytext] = useState({Id:2, Name:''});
    
    const [RouteIDForEdit, setRouteIDForEdit] = useState("");

    //declaration
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),
    }

    const PurchaseOrderIDList = props?.PurchaseOrderIDList;
    //State



    const [MainFields, setMainFields] = useState([{
        Operation: 1,
        TNAID: 0,
        SeasonID: 0,
        BrandID: 0,
        SupplierID: 0,
        RouteInformationID: 0,
        PurOrderStyleDetailIDList: '',
        TNARouteApplyDetails: [],
        TNADependencyDetails: [],
        TNAAdditionalTaskDetails: [],
        CreatedBy: currentUser.employeeinformationID,
        AssignAutocalculation:0,
        

        BuyerID: getTNABrandID,
             
        arrBuyerEmployeeID: [],
        arrCubeEmployeeID: [],
        arrSupplierEmployeeID: [],

        BuyerEmployeeIdList: '',
        CubeEmployeeIdList: '',
        SupplierEmployeeIdList: '',
    }]);

    const EmptyInputFields = (getTNATaskInputFields.length === 0) ? [] : getTNATaskInputFields;

    const [inputFields, setInputFields] = useState(EmptyInputFields);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getTaskHolderList, setTaskHolderList] = useState([]);
    const [getAllRouteInfoList, setAllRouteInfoList] = useState([]);
    const [getHeaderName, addHeaderName] = useState("Edit");
    const [isCalculationLoad, setCalculationLoad] = useState(false);
    const [isWarningPopup, setIsWarningPopup] = useState({ isPopupOpen: false, Msg: [] });
    const values = [...inputFields];
    const MainValues = [...MainFields];

    let CubeList = useSelector((state) => state.common.CubeList);
    //  let SupList = useSelector((state) => state.common.SupList);
        const [SupList, setSupList] = useState([]);
        
     const [CubeEmployeeID, setCubeEmployeeID] = useState(0);

    const [getEmployeeList, setEmployeeList] = useState([]);

   const [getCubeEmployeeList, setCubeEmployeeList] = useState([]);
      const [getSupplierEmployeeList, setSupplierEmployeeList] = useState([]);
    
      
      
          const [getStyleList, setStyleList] = useState(MainValues);
          
          let styleValue = getStyleList;

          useEffect(() => {

            debugger
            if (getStyleList[0].BuyerID) {
                StyleService.LoadBuyerEmployee(getStyleList[0].BuyerID,2)
                    .then((response) => {
                        if (response.data) {
                            
                            setEmployeeList(response.data);
                            console.log(response.data);
                        }
                    })
                    .catch((error) => {
                        
                        console.error("Error loading buyer employee list:", error);
                    });
            }
            dispatch(loadCubeEmp(getTNABrandID));

            dispatch(loadCubeBuyerSupplierEmp(3))
            .then((data) => {
             
              // Filter the data based on CheckCubeBuyerSupplierTNA
              const filteredData = data.filter(
                (x) => x.checkCubeBuyerSupplierTNA === SupplierID
              );
          
              console.log("Filtered Supplier Employee Data:", filteredData);
          
              if (filteredData && filteredData.length > 0) {
                // Map filteredData to dropdown options
                const dropdownOptions = filteredData.map((item) => ({
                  value: item.value,
                  label: item.label.replace(/\s*\(.*?\)/g, ""),
                }));
          
                // Set the dropdown options
                setSupList(dropdownOptions);
              } else {
                console.warn("No supplier employee data found for the given supplierID.");
              }
            })
            .catch((error) => {
              console.error("Error fetching Supplier Employee data:", error);
            });
          
        }, [getStyleList[0]?.BuyerID]);
        
        
                 
                





    useEffect(() => {
        if (!isLoadingTNA) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingTNA])

    useEffect(() => {
        if (getTNATaskInputFields.length === 0) {
            dispatch(getRouteInfoList(getRouteID, PurchaseOrderIDList));
        }
    }, [getRouteID]);

    useEffect(() => {
        if (values.length > 0) {
            if (isCalculationLoad === true) {
                commonCalculation(false, false);
                setCalculationLoad(false);
            }
        }
    }, [isCalculationLoad === true]);

    useMemo(() => {
        dispatch(setTNATaskInputFields(inputFields));

    }, [inputFields]);

    useMemo(() => {
        if (getRouteList.length > 0) {
            let checkOldRouteID = getRouteList.filter(d => d.value === getRouteID);
            if (checkOldRouteID.length === 0) {
                setInputFields([]);
            }
        }

    }, [getRouteList]
    );

    useMemo(
        () => {

            let Optionvalue = [];
            taskHolderList && taskHolderList.map(element => {
                let LabelColor = '';
                if (element.label === 'Buyer - -') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Buyer - Cube') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Buyer - Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Buyer - Cube,Supplier') {
                    LabelColor = <><span style={{ color: "#0b0992" }}><b>Buyer</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - -') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Cube - Buyer') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Cube - Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Cube - Buyer,Supplier') {
                    LabelColor = <><span style={{ color: "#00a65a" }}><b>Cube</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - -') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span></>
                }
                else if (element.label === 'Supplier - Buyer') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span></>
                }
                else if (element.label === 'Supplier - Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }
                else if (element.label === 'Supplier - Buyer,Cube') {
                    LabelColor = <><span style={{ color: "#e27607" }}><b>Supplier</b></span> - <span style={{ color: "#0b0992" }}><b>Buyer</b></span>, <span style={{ color: "#00a65a" }}><b>Cube</b></span></>
                }

                Optionvalue.push({
                    value: element.value,
                    label: LabelColor,
                    subLabel: element.label,
                })
            });

            setTaskHolderList(Optionvalue);

        },
        [taskHolderList]
    );



    useMemo(
        () => {
            if (getTNATaskInputFields.length === 0 && getRouteID !== 0) {
               
                let routeInfo = AllRouteInfo.routeInfo;
                let dependentInfo = AllRouteInfo.dependentInfo;
                let additionalInfo = AllRouteInfo.additionalInfo;
                let routeInformation = [];
                if (AllRouteInfo !== undefined) {
                    if (routeInfo !== undefined) {
                        if (routeInfo.length > 0) {
                            //setInputFields([]);
                            routeInfo.map((route, i) => {
                                let RouteInfoId = 0;
                                let RouteId = 0;
                                let employee1 = "";
                                let employee2 = "";
                                let employee3 = "";

                                RouteInfoId = route.routeInformationID;
                                RouteId = route.routeID;
                                if (route && route.taskOwnerName) {
                                    let TaskHolderName = route.taskOwnerName.split('-');
                                    if (TaskHolderName && TaskHolderName[0]) {
                                        employee1 = TaskHolderName[0].trim();

                                        if (TaskHolderName && TaskHolderName[1]) {
                                            let FollowerName = TaskHolderName[1].split(',');
                                            if (FollowerName && FollowerName[0]) {
                                                employee2 = FollowerName[0].trim();

                                                if (FollowerName && FollowerName[1]) {
                                                    employee3 = FollowerName[1].trim();
                                                } else {
                                                    employee3 = "";
                                                }
                                            } else {
                                                employee2 = "";
                                                employee3 = "";
                                            }

                                        } else {
                                            employee2 = "";
                                            employee3 = "";
                                        }
                                    }
                                }

                                //check supplier id in supplier employeeList
                                let taskHolderIDList = route.taskHolderIDList;
                                let followerIDList1 = route.followerIDList1;
                                let followerIDList2 = "";
                                if (employee1 === "Supplier" || employee2 === "Supplier" || employee3 === "Supplier") {

                                    if (route.followerIDList2) {
                                        if (SupplierEmployeeList && SupplierEmployeeList.length > 0) {
                                            let Supplier = SupplierEmployeeList.filter(item => (route.followerIDList2.split(',').includes(item.id.toString())));
                                            if (Supplier && Supplier.length > 0) {
                                                followerIDList2 = Supplier.map(function (item) {
                                                    return item['id'] && item['id'].toString();
                                                });
                                                followerIDList2 = followerIDList2 && followerIDList2.toString();
                                            }
                                        }

                                    }
                                } else {
                                    followerIDList2 = route.followerIDList2;
                                }


                                let taskHolderOwnerIdList = "";
                                if (employee1 !== "Supplier" && employee2 !== "Supplier" && employee3 !== "Supplier") {
                                    taskHolderOwnerIdList = route.taskOwner;
                                } else {
                                    if (taskHolderIDList) {
                                        taskHolderOwnerIdList = taskHolderIDList;
                                    }
                                    if (followerIDList1) {
                                        if (!taskHolderOwnerIdList) {
                                            taskHolderOwnerIdList = followerIDList1;
                                        } else {
                                            taskHolderOwnerIdList = taskHolderOwnerIdList + "," + followerIDList1;
                                        }
                                    }
                                    if (followerIDList2) {
                                        if (!taskHolderOwnerIdList) {
                                            taskHolderOwnerIdList = followerIDList2;
                                        } else {
                                            taskHolderOwnerIdList = taskHolderOwnerIdList + "," + followerIDList2;
                                        }
                                    }
                                }

                                let StartDate = new Date();
                                let EndDate = new Date();

                                routeInformation.push({
                                    RouteInformationID: RouteInfoId,
                                    TNARouteApplyID: RouteId,
                                    TaskType: route.taskType,
                                    TaskName: route.taskNameID,
                                    strTaskName: route.taskName,
                                    EndDate: EndDate,
                                    StartDate: StartDate,
                                    PreStartDate: StartDate,
                                    PreEndDate: EndDate,
                                    Duration: route.duration,
                                    arrDependency: [],
                                    TaskHolderList: '',
                                    TaskHolderOrFollower: route.taskHolderOrFollower,
                                    TaskHolderOwnerTxtList: route.taskOwnerNames,
                                    TaskHolderOwnerIdList: taskHolderOwnerIdList,
                                    TaskHolderOrFollowerTxt: route.taskOwnerName && route.taskOwnerName.replace(" - -", ""),
                                    titleEmployee1: employee1,
                                    titleEmployee2: employee2,
                                    titleEmployee3: employee3,
                                    LeaveDays: route.leaveDays,
                                    LeaveDaysTxt: route.leaveDayName,
                                    AdditionalFollowup: additionalInfo.filter(x => x.indexName === route.indexName && x.isTaskSelected === 1).length,
                                    IsFollowupExist: additionalInfo.filter(x => x.indexName === route.indexName).length,
                                    DependencyCount: dependentInfo.filter(x => x.indexName === route.indexName && x.dependencyID !== 0).length,
                                    IndexName: route.indexName,
                                    // PreferenceId: route.preferenceId,
                                    IsPopupOpen: 0,
                                    IsDependencyOpen: 0,
                                    IsTHLeaveDaysOpen: 0,
                                    IsSelectAll: route.isSelectAll,
                                    FollowupDetails: [],
                                    SelectedDependency: route.selectedDependency,
                                    TaskHolderBuyer: taskHolderIDList,
                                    arrTaskHolderBuyer: [],
                                    TaskHolderBuyerTxt: '',
                                    FollowerCube: followerIDList1,
                                    arrFollowerCube: [],
                                    FollowerCubeTxt: '',
                                    FollowerSupplier: followerIDList2,
                                    arrFollowerSupplier: [],
                                    FollowerSupplierTxt: '',
                                    IsChildStatus: route.isChildStatus,
                                    EndDateChange: 0,
                                    TaskHolderIDList: taskHolderIDList,
                                    FollowerIDList1: followerIDList1,
                                    FollowerIDList2: followerIDList2,
                                    IsTHFirstTime: 0,
                                    TotalHoliday: '',
                                    DetailTypeID: route.detailTypeID


                                });
                            });
                        }

                        if (dependentInfo.length > 0) {
                            dependentInfo.map((element, i) => {
                                let RouteInfoId = 0;
                                let DependencyId = 0;
                                RouteInfoId = element.routeInformationID;
                                DependencyId = element.routeDependencyID;

                                routeInformation[parseInt(element.indexName)].arrDependency.push({
                                    TNADependencyID: DependencyId,
                                    DependentTaskName: element.dependentTaskName,
                                    TNATaskName: element.routeTaskName,
                                    DependencyID: element.dependencyID,
                                    LogDays: element.logDays,
                                    IndexName: element.indexName
                                })
                            })
                        }

                        if (additionalInfo.length > 0) {
                            routeInformation.map((add, j) => {
                                additionalInfo.filter(x => x.indexName === add.IndexName).map((element, i) => {
                                    let RouteInfoId = 0;
                                    let AddtaskId = 0;

                                    RouteInfoId = element.routeInformationID;
                                    AddtaskId = element.routeAddTaskID;

                                    routeInformation[j].FollowupDetails.push({
                                        RouteInformationID: RouteInfoId,
                                        TNAAddTaskID: AddtaskId,
                                        AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                        AddtionalTaskName: element.addtionalTaskName,
                                        IsTaskSelected: element.isTaskSelected,
                                        IndexName: element.indexName
                                    })
                                });

                            })
                        }
                        setInputFields(routeInformation);
                        if (routeInformation.length > 0) {
                            setCalculationLoad(true);

                        }

                    } else {
                        setInputFields(routeInformation);
                    }
                } else {
                    setInputFields(routeInformation);
                }
            }

        },
        [AllRouteInfo]
    );

    useEffect(() => {
        dispatch(loadTNARouteList(PurchaseOrderIDList, IsProduction));
        dispatch(loadTaskTypeList(2));
        dispatch(loadLeaveDaysList());
        dispatch(retrieveTask(0));
        dispatch(loadTaskList(0));
        dispatch(loadRouteDependencyList());
        dispatch(loadTaskHolderList());
        dispatch(getTNACompanyHolidayList(getTNABrandID, getTNASupplierID, currentUser.companyBuyerSupID));
        dispatch(loadTNASupplierEmployeeList(getTNASupplierID));

    }, []);

    const warningPopupClose = () => {
        setIsWarningPopup({ isPopupOpen: false, Msg: [] });
    }

    const handleAddFields = (index) => {
        let count = index;
        count++;
        let EmptyList = {};
        EmptyList =
        {
            RouteInformationID: 0,
            TNARouteApplyID: 0,
            TaskType: 0,
            TaskName: 0,
            strTaskName: '',
            Duration: '',
            EndDate: new Date(),
            StartDate: new Date(),
            PreStartDate: new Date(),
            PreEndDate: new Date(),
            arrDependency: [],
            DependencyCount: 0,
            AdditionalFollowup: 0,
            IsFollowupExist: 0,
            IndexName: '',
            IsPopupOpen: 0,
            IsDependencyOpen: 0,
            IsSelectAll: 1,
            SelectedDependency: '',
            FollowupDetails: [],
            IsChildStatus: true,
            EndDateChange: 0,
            TaskHolderList: '',// TaskHolder Details And LeaveDays
            TaskHolderOrFollower: 0,
            TaskHolderOwnerTxtList: '',
            TaskHolderIDList: '',
            FollowerIDList1: '',
            FollowerIDList2: '',
            TaskHolderBuyer: '',
            FollowerCube: '',
            FollowerSupplier: '',
            TaskHolderOwnerIdList: '',
            titleEmployee1: '',
            titleEmployee2: '',
            titleEmployee3: '',
            LeaveDays: 1,
            IsTHFirstTime: 0,
            LeaveDaysTxt: 'None',
            IsTHLeaveDaysOpen: 0,
            arrTaskHolderBuyer: [],
            arrFollowerCube: [],
            arrFollowerSupplier: [],
            TaskHolderBuyerTxt: '',
            FollowerCubeTxt: '',
            FollowerSupplierTxt: '',
        }
        values.splice(count, 0, EmptyList);

        values.map((Item, i) => {
            //for (let i = 0; i < values.length; i++) {
            let TaskDependency = [];

            values.map((Item2) => {
                // for (let j = 0; j < values.length; j++) {
                if (Item.arrDependency.length > 0) {

                    let AlreadyExist = Item.arrDependency.filter(x => x.DependentTaskName === Item2.TaskName);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }

                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item2.TaskName,
                            TNATaskName: Item2.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (i).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item2.TaskName,
                            TNATaskName: Item2.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (i).toString()
                        }
                    )
                }
            });
            Item.arrDependency = [];

            Item.arrDependency = TaskDependency;

            let DependencyShow = '';
            Item.arrDependency.map((Item3, k) => {
                // for (let k = 0; k < values[i].arrDependency.length; k++) {
                if (i !== k) {
                    let DependentID = Item3.DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (k + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            });

            Item.SelectedDependency = DependencyShow;
            Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
            //Item.PreferenceId = i + 1;

            values[i] = Item;

        });

        // if (values.length > 0) {
        //     for (var z = 0; z < values.length; z++) {
        //         values[z].PreferenceId = z + 1;
        //     }
        // }

        setInputFields(values);
        setSubmitted(false);
    };


    const handleRemoveFields = index => {
        values.splice(index, 1);
        values.map((Item, j) => {
            //for (let j = 0; j < values.length; j++) {
            Item.arrDependency.splice(index, 1);

            Item.SelectedDependency = '';
            let DependencyShow = '';
            Item.arrDependency.map((Item2, i) => {
                //for (let i = 0; i < values[j].arrDependency.length; i++) {
                if (j !== i) {
                    let DependentID = Item2.DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : "EE")
                        }
                    }
                }
            });
            Item.SelectedDependency = DependencyShow;
            Item.DependencyCount = Item.arrDependency.filter(x => x.DependencyID !== 0).length;
            values[j] = Item;
        });


        setInputFields(values);
        commonCalculation(false, false);
        DisabeSelectedTaskName();
    };

    const handleChange = (e, index, FieldName) => {

       ;
        setSubmitted(false);
        let inputText = '';
        if (FieldName === "IsSelectAll") {
            if (e.target.checked) {
                values[index].FollowupDetails.map((Item, i) => {
                    //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 1;
                });

                values[index][FieldName] = 1;
            } else {
                values[index].FollowupDetails.map((Item, i) => {
                    //for (var i = 0; i < values[index].FollowupDetails.length; i++) {
                    values[index].FollowupDetails[i].IsTaskSelected = 0;
                });
                values[index][FieldName] = 0;

            }

            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
            values[index].IsFollowupExist = values[index].FollowupDetails.length;

        }
        else if (FieldName === "TaskType") {
            if (e != null) {
                inputText = parseInt(e.value);
                values[index].TaskName = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];
                var SelectedIndex = getTaskTypeList.findIndex(x => x.value === e.value);
                getTaskTypeList[SelectedIndex].isdisabled = true;
            }
            
            else {
                inputText = 0;
                values[index].TaskName = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = "";
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index].FollowupDetails = [];

            }
            values[index][FieldName] = inputText;
            DisabeSelectedTaskName();

        }
        else if (FieldName === "AutoExfactory") 
        {
         ;
            if (e != null) {
               
               // Iterate through each element in the getDependencyList array
getDependencyList.forEach((element, index) => {
    // Set the IsAutoExfactory property of each element to 0
    getDependencyList[index].IsAutoExfactory = 0;
});
setAutoExfactorytext('');

// Check if the event target is checked
if (e.target.checked) {
    // Set the IsAutoExfactory property of the specific element at the given index to 1
    getDependencyList[index].IsAutoExfactory = 1;
    setAutoExfactorytext({Id:getDependencyList[index].value,Name:getDependencyList[index].label});
}
                
                // inputText = parseInt(e.value);
                // values[index].TaskName = 0;
                // values[index].TaskHolderOrFollower = 0;
                // values[index].TaskHolderOrFollowerTxt = '';
                // values[index].LeaveDays = 0;
                // values[index].LeaveDaysTxt = "";
                // values[index].AdditionalFollowup = 0;
                // values[index].IsFollowupExist = 0;
                // values[index].strTaskName = '';
                // values[index].FollowupDetails = [];
                // var SelectedIndex = getTaskTypeList.findIndex(x => x.value === e.value);
                // getTaskTypeList[SelectedIndex].isdisabled = true;
            }
        }
        else if (FieldName === "TaskName") 
        {
            if (e != null) {
                inputText = parseInt(e.value);
                let routeInfo = AllRouteInfo.routeInfo;
                let additionalInfo = AllRouteInfo.additionalInfo;
                const existingTask = routeInfo.filter(d => d.taskNameID === inputText)[0];

                let Task_Value = TaskList.filter(x => x.value === e.value)[0];

                values[index].strTaskName = Task_Value.label;
                values[index].TaskType = Task_Value.taskTypeID;

                if (existingTask) {
                    let existingEmployee1 = "";
                    let existingEmployee2 = "";
                    let existingEmployee3 = "";

                    if (existingTask.taskOwnerName) {
                        let TaskHolderName = existingTask.taskOwnerName.split('-');
                        if (TaskHolderName[0]) {
                            existingEmployee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1]) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0]) {
                                    existingEmployee2 = FollowerName[0].trim();

                                    if (FollowerName[1]) {
                                        existingEmployee3 = FollowerName[1].trim();
                                    } else {
                                        existingEmployee3 = "";
                                    }
                                } else {
                                    existingEmployee2 = "";
                                    existingEmployee3 = "";
                                }

                            } else {
                                existingEmployee2 = "";
                                existingEmployee3 = "";
                            }
                        }
                    }

                    let taskHolderIDList = existingTask.taskHolderIDList;
                    let followerIDList1 = existingTask.followerIDList1;
                    let followerIDList2 = "";
                    if (existingEmployee1 === "Supplier" || existingEmployee2 === "Supplier" || existingEmployee3 === "Supplier") {
                        if (existingTask.followerIDList2) {
                            if (SupplierEmployeeList && SupplierEmployeeList.length > 0) {
                                let Supplier = SupplierEmployeeList.filter(item => (existingTask.followerIDList2.includes(item.id.toString())));
                                if (Supplier && Supplier.length > 0) {
                                    followerIDList2 = Supplier.map(function (item) {
                                        return item['id'];
                                    });
                                    followerIDList2 = followerIDList2 && followerIDList2.toString();
                                }
                            }
                        }
                    } else {
                        followerIDList2 = existingTask.followerIDList2;
                    }

                    let taskHolderOwnerIdList = "";
                    if (existingEmployee1 !== "Supplier" && existingEmployee2 !== "Supplier" && existingEmployee3 !== "Supplier") {
                        taskHolderOwnerIdList = existingTask.taskOwner;
                    } else {
                        if (taskHolderIDList !== "") {
                            taskHolderOwnerIdList = taskHolderIDList;
                        }
                        if (followerIDList1 !== "") {
                            if (taskHolderOwnerIdList === "") {
                                taskHolderOwnerIdList = followerIDList1;
                            } else {
                                taskHolderOwnerIdList = taskHolderOwnerIdList + "," + followerIDList1;
                            }
                        }
                        if (followerIDList2 !== "") {
                            if (taskHolderOwnerIdList === "") {
                                taskHolderOwnerIdList = followerIDList2;
                            } else {
                                taskHolderOwnerIdList = taskHolderOwnerIdList + "," + followerIDList2;
                            }
                        }
                    }
                    values[index][FieldName] = inputText;
                    let StartDate = new Date();
                    let EndDate = new Date();

                    values[index].EndDate = EndDate;
                    values[index].StartDate = StartDate;
                    values[index].PreStartDate = StartDate;
                    values[index].PreEndDate = EndDate;
                    values[index].Duration = existingTask.duration && existingTask.duration.toString();
                    values[index].TaskHolderOrFollower = existingTask.taskHolderOrFollower;
                    values[index].TaskHolderOwnerTxtList = existingTask.taskOwnerNames;
                    values[index].TaskHolderOrFollowerTxt = existingTask.taskOwnerName && existingTask.taskOwnerName.replace(" - -", "");
                    values[index].TaskHolderOwnerIdList = taskHolderOwnerIdList;
                    values[index].TaskHolderIDList = taskHolderIDList;
                    values[index].FollowerIDList1 = followerIDList1;
                    values[index].FollowerIDList2 = followerIDList2;
                    values[index].TaskHolderBuyer = taskHolderIDList;
                    values[index].FollowerCube = followerIDList1;
                    values[index].FollowerSupplier = followerIDList2;
                    values[index].titleEmployee1 = existingEmployee1;
                    values[index].titleEmployee2 = existingEmployee2;
                    values[index].titleEmployee3 = existingEmployee3;
                    values[index].LeaveDays = existingTask.leaveDays;
                    values[index].LeaveDaysTxt = existingTask.leaveDayName;
                    values[index].AdditionalFollowup = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.isTaskSelected === 1).length;
                    values[index].IsFollowupExist = additionalInfo.filter(x => x.indexName === existingTask.indexName).length;
                    values[index].DependencyCount = additionalInfo.filter(x => x.indexName === existingTask.indexName && x.dependencyID !== 0).length;
                    // values[index].PreferenceId = existingTask.preferenceId;
                    values[index].IsSelectAll = existingTask.isSelectAll;
                    values[index].SelectedDependency = existingTask.dependency;

                    values[index].arrFollowerCube = [];
                    values[index].arrFollowerSupplier = [];
                    values[index].arrTaskHolderBuyer = [];

                    setInputFields(values);
                    hideLoader();
                } else {

                    var RespectiveList = ExistingList.filter(x => x.taskDetailsID === e.value);
                    let employee1 = "";
                    let employee2 = "";
                    let employee3 = "";

                    if (RespectiveList[0].taskHolderName !== null && RespectiveList[0].taskHolderName !== undefined) {
                        let TaskHolderName = RespectiveList[0].taskHolderName.split('-');
                        if (TaskHolderName[0] !== undefined) {
                            employee1 = TaskHolderName[0].trim();

                            if (TaskHolderName[1] !== undefined) {
                                let FollowerName = TaskHolderName[1].split(',');
                                if (FollowerName[0] !== undefined) {
                                    employee2 = FollowerName[0].trim();

                                    if (FollowerName[1] !== undefined) {
                                        employee3 = FollowerName[1].trim();
                                    } else {
                                        employee3 = "";
                                    }
                                } else {
                                    employee2 = "";
                                    employee3 = "";
                                }

                            } else {
                                employee2 = "";
                                employee3 = "";
                            }
                        }
                    }


                    values[index].TaskHolderOrFollower = RespectiveList[0].taskHolderOrFollower;
                    values[index].TaskHolderOrFollowerTxt = RespectiveList[0].taskHolderName && RespectiveList[0].taskHolderName.replace(" - -", "");
                    values[index].LeaveDays = RespectiveList[0].leaveDays;
                    values[index].LeaveDaysTxt = RespectiveList[0].leaveDayName;
                    values[index].AdditionalFollowup = RespectiveList[0].additionalFollowup;
                    values[index].IsFollowupExist = RespectiveList[0].additionalFollowup;
                    values[index].titleEmployee1 = employee1;
                    values[index].titleEmployee2 = employee2;
                    values[index].titleEmployee3 = employee3;

                    values[index].TaskHolderOwnerTxtList = "";
                    values[index].TaskHolderOwnerIdList = "";
                    values[index].TaskHolderIDList = "";
                    values[index].FollowerIDList1 = "";
                    values[index].FollowerIDList2 = "";
                    values[index].TaskHolderBuyer = "";
                    values[index].FollowerCube = "";
                    values[index].FollowerSupplier = "";
                    values[index].arrFollowerCube = [];
                    values[index].arrFollowerSupplier = [];
                    values[index].arrTaskHolderBuyer = [];

                }

                TnaService.GetTNARouteTaskCheck(getRouteID, PurchaseOrderIDList, Task_Value.value).then((response) => {

                    values[index].IsChildStatus = response.data;
                });

                taskService.GetAddtionalTaskDetailsList(0, Task_Value.taskInformationID).then((response) => {
                    if (response.data) {
                        values[index].FollowupDetails = [];
                        if (response.data.length !== 0) {
                            if (response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).length > 0) {
                                response.data.filter(x => x.indexName.toString() === Task_Value.indexName.toString()).map(element => {
                                    values[index].FollowupDetails.push({
                                        TNAAddTaskID: 0,
                                        AdditonalTaskDetailsID: element.additonalTaskDetailsID,
                                        AddtionalTaskName: element.addtionalTaskName,
                                        IsTaskSelected: 1,
                                        IndexName: index.toString()
                                    });
                                });
                            }
                            else {
                                values[index].AdditionalFollowup = 0;
                                values[index].IsFollowupExist = 0;
                                values[index].FollowupDetails = [];
                            }
                        }
                        else {
                            values[index].AdditionalFollowup = 0;
                            values[index].IsFollowupExist = 0;
                            values[index].FollowupDetails = [];
                        }
                        values[index][FieldName] = inputText;

                        values.map((Item, j) => {
                            // for (var j = 0; j < values.length; j++) {
                            let MSG = '';
                            if (index !== j) {
                                if (values[index].TaskType !== 0) { // for avoid empty validation
                                    if (Item.TaskType === values[index].TaskType &&
                                        Item.TaskName === values[index].TaskName) {
                                        if (FieldName === "TaskName") {
                                            values[index].TaskName = 0;
                                            MSG = "This task name is already exist.";
                                        }
                                        values[j] = Item;
                                        setInputFields(values);
                                        Nodify('Warning!', 'warning', MSG);
                                        return false;
                                    }
                                }
                            }
                            values[j] = Item;
                        });
                        setInputFields(values);
                    }
                });
            }
            else {
                inputText = 0;
                values[index].TaskType = 0;
                values[index].TaskHolderOrFollower = 0;
                values[index].TaskHolderOrFollowerTxt = '';
                values[index].LeaveDays = 0;
                values[index].LeaveDaysTxt = '';
                values[index].AdditionalFollowup = 0;
                values[index].IsFollowupExist = 0;
                values[index].strTaskName = '';
                values[index][FieldName] = inputText;
                values[index].FollowupDetails = [];

                values.map((Item, i) => {
                    //for (let i = 0; i < values.length; i++) {
                    Item.arrDependency.splice(index, 1);
                    values[i] = Item;
                });
                setInputFields(values);

            }

            DisabeSelectedTaskName();

        }
        else if (FieldName === "TaskHolderOrFollower" || FieldName === "LeaveDays") {
            if (e != null) {
                inputText = parseInt(e.value);
                if (FieldName === "TaskHolderOrFollower") {
                    // setTaskHolderID(inputText);

                    values[index].TaskHolderOrFollowerTxt = e.subLabel && e.subLabel.replace(" - -", "");
                    let TaskHolderName = e.subLabel.split('-');
                    if (TaskHolderName[0] !== undefined) {
                        values[index].titleEmployee1 = TaskHolderName[0].trim();

                        if (TaskHolderName[1] !== undefined) {
                            let FollowerName = TaskHolderName[1].split(',');
                            if (FollowerName[0] !== undefined) {
                                values[index].titleEmployee2 = FollowerName[0].trim();

                                if (FollowerName[1] !== undefined) {
                                    values[index].titleEmployee3 = FollowerName[1].trim();
                                } else {
                                    values[index].titleEmployee3 = "";
                                }
                            } else {
                                values[index].titleEmployee2 = "";
                                values[index].titleEmployee3 = "";
                            }

                        } else {
                            values[index].titleEmployee2 = "";
                            values[index].titleEmployee3 = "";
                        }
                        if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                            dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, 0));
                        } else {
                            dispatch(loadTNATaskOwner(inputText, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID));
                        }

                    } else {
                        values[index].titleEmployee1 = "";
                        values[index].titleEmployee2 = "";
                        values[index].titleEmployee3 = "";
                    }


                } else {
                    values[index].LeaveDaysTxt = e.label;
                }
            }
            else {
                inputText = 0;
                if (FieldName === "TaskHolderOrFollower") {
                    values[index].TaskHolderOrFollowerTxt = '';
                    values[index].titleEmployee1 = "";
                    values[index].titleEmployee2 = "";
                    values[index].titleEmployee3 = "";
                }
                values[index].LeaveDaysTxt = "";
            }
            values[index][FieldName] = inputText;
        }
        else if (FieldName === "Duration") {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
            inputText = checkNumber[0];
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index][e.target.name] = inputText;
            if (values[index].EndDateChange === 1) {
                values[index].EndDateChange = 2;
            }

        }
        else if (FieldName === "EndDate") {
            values[index].PreEndDate = values[index].EndDate;
            values[index].EndDate = e;
            if (values[index].EndDateChange === 0) {
                values[index].EndDateChange = 1;
            }

        }
        else if (FieldName === "TaskHolderBuyer" || FieldName === "FollowerCube" || FieldName === "FollowerSupplier") {
            let strValue = '';
            let strTxt = '';
            e.map((val) => {
                if (strValue === '') { strValue = val.value.toString(); strTxt = val.label.toString(); }
                else { strValue = strValue + "," + val.value; strTxt = strTxt + ", " + val.label.toString(); }
            });
            values[index]["arr" + FieldName] = e;
            values[index][FieldName] = strValue;
            values[index][FieldName + "Txt"] = strTxt;
        }
        else if (FieldName === "BuyerEmployeeIdList" || FieldName === "CubeEmployeeIdList" || FieldName === "SupplierEmployeeIdList" )
        {
            
            if (FieldName === 'BuyerEmployeeIdList') {
               
                    let strValue = '';
                    for (let size of e) {
                        if (strValue === '') { strValue = size.value.toString() }
                        else { strValue = strValue + "," + size.value }
                    }
                    styleValue['arrBuyerEmployeeID'] = e;
                    styleValue[FieldName] = strValue;
               
            }
            else if (FieldName === 'CubeEmployeeIdList' ) {
                if(e!==null)
                    {
                        setCubeEmployeeID(e.length)
                        let strValue = '';
                        for (let size of e) {
                            if (strValue === '') { strValue = size.value.toString() }
                            else { strValue = strValue + "," + size.value }
                        }
                        styleValue['arrCubeEmployeeID'] = e;
                        styleValue[FieldName] = strValue;
                    }
                else{
                    setCubeEmployeeID(0);
                }
           
            }
            else if (FieldName === 'SupplierEmployeeIdList') {
                let strValue = '';
                for (let size of e) {
                    if (strValue === '') { strValue = size.value.toString() }
                    else { strValue = strValue + "," + size.value }
                }
                styleValue['arrSupplierEmployeeID'] = e;
                styleValue[FieldName] = strValue;
            }
            setStyleList(styleValue);
        }
        else {
            if (e.target?.value.trim() !== '') {
                inputText = e.target?.value;
            }
            values[index][e.target?.name] = inputText;
        }

        if (FieldName !== "TaskName") {
            setInputFields(values);
            if (FieldName === "EndDate") {
                commonCalculation(false, true);
            }
            // } else if (FieldName === "Duration") {
            //     commonCalculation(true);
            // }
            // if (FieldName === "Duration") {
            //     commonCalculation(index);
            // }
        }
    }

    const DisabeSelectedTaskName = () => {
        TaskList.map((data, i) => {
            TaskList[i].isdisabled = false;
        })

        values.map((data, i) => {
            var SelectedIndex = TaskList.findIndex(x => x.value === data.TaskName);
            if (SelectedIndex !== -1) {
                TaskList[SelectedIndex].isdisabled = true;
            }
        })
    }

    const CheckDuplicate = (index, Field) => {

        if (Field === "Duration") {
            commonCalculation(true, false);
        }

        for (var j = 0; j < values.length; j++) {
            let MSG = '';
            if (index !== j) {
                if (values[index].TaskType !== 0 && values[index].TaskName !== 0) { // for avoid empty validation
                    if (values[j].TaskType === values[index].TaskType &&
                        values[j].TaskName === values[index].TaskName) {
                        if (Field === "TaskName") {
                            values[index].TaskName = 0;
                            MSG = "This task name is already exist.";
                        }
                        else if (Field === "TaskType") {
                            values[index].TaskType = 0;
                            MSG = "This task type is already exist.";
                        }
                        setInputFields(values);
                        Nodify('Warning!', 'warning', MSG);
                        return false;
                    }
                }
            }
        }
    }

    const handleFollowup = index => {
        if (values[index].TaskName === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].IsFollowupExist === 0) {
                Nodify('Warning!', 'warning', "Additional task doesn't exist for this task.");
            }
            else {
                values[index].IsPopupOpen = 1;
                setInputFields(values);
            }
        }
    };

    const handleClose = (index) => {
        values[index].IsPopupOpen = 0;
        setInputFields(values);
    };

    const handleDependency = (index, DependencyTask) => {
       ;
        if (values[index].TaskName === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            values[index].IsDependencyOpen = 1;

            let TaskDependency = [];
            values.map((Item, i) => {
                //for (let i = 0; i < values.length; i++) {
                if (values[index].arrDependency.length > 0) {
                    let AlreadyExist = values[index].arrDependency.filter(x => x.DependentTaskName === Item.TaskName);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskName,
                            TNATaskName: Item.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (index).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskName,
                            TNATaskName: Item.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (index).toString()
                        }
                    )
                }
            });
            values[index].arrDependency = [];
            values[index].arrDependency = TaskDependency;
            setInputFields(values);
        }
    };

    const handleTaskHolderLeaveDays = (index, isTHFirstTime) => {
        if (values[index].TaskID === 0) {
            Nodify('Warning!', 'warning', 'Please select the task name.');
        }
        else {
            if (values[index].titleEmployee1 !== "Cube" && values[index].titleEmployee2 !== "Cube" && values[index].titleEmployee3 !== "Cube") {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, 0));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, 0))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                    });
            } else {
                // dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, currentUser.employeeinformationID));
                dispatch(loadTNATaskOwner(values[index].TaskHolderOrFollower, getTNABrandID, getTNASupplierID, currentUser.employeeinformationID))
                    .then(data => {
                        if (data != null) {
                            setTNATaskOwner(data, index, isTHFirstTime);
                        }
                        values[index].IsTHLeaveDaysOpen = 1;
                        setInputFields(values);
                    });
            }
        }
    };

    function setTNATaskOwner(data, index, isTHFirstTime) {
        if (data && data.buyerEmployeeList && data.buyerEmployeeList.length > 0) {

            let taskHolderBuyer = values[index].TaskHolderIDList && data.buyerEmployeeList.filter(item => (values[index].TaskHolderIDList.split(',').includes(item.value.toString())));
            if (taskHolderBuyer && taskHolderBuyer.length > 0) {

                if (values[index].arrTaskHolderBuyer.length === 0 && isTHFirstTime === 0) {
                    values[index].arrTaskHolderBuyer = [...taskHolderBuyer];
                } else {
                    const isData = !!values[index].arrTaskHolderBuyer.filter(d => d.value === taskHolderBuyer.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrTaskHolderBuyer = [...values[index].arrTaskHolderBuyer, ...taskHolderBuyer];
                    }
                }

            }
        }
        if (data && data.cubeEmployeeList) {
            let followerCube = values[index].FollowerIDList1 && data.cubeEmployeeList.filter(item => (values[index].FollowerIDList1.split(',').includes(item.value.toString())));
            if (followerCube && followerCube.length > 0) {
                if (values[index].arrFollowerCube.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerCube = [...followerCube];
                } else {
                    const isData = !!values[index].arrFollowerCube.filter(d => d.value === followerCube.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerCube = [...followerCube];
                    }
                }
            }
        }

        if (data && data.supplierEmployeeList) {
            let followerSupplier = values[index].FollowerIDList2 && data.supplierEmployeeList.filter(item => (values[index].FollowerIDList2.split(',').includes(item.value.toString())));
            if (followerSupplier && followerSupplier.length > 0) {
                if (values[index].arrFollowerSupplier.length === 0 && isTHFirstTime === 0) {
                    values[index].arrFollowerSupplier = [...followerSupplier];
                } else {
                    const isData = !!values[index].arrFollowerSupplier.filter(d => d.value === followerSupplier.value);
                    if (isData === false && isTHFirstTime === 0) {
                        values[index].arrFollowerSupplier = [...followerSupplier];
                    }
                }
            }
        }

        if (isTHFirstTime === 0) {
            if (values[index].arrTaskHolderBuyer.length > 0) {
                let names = values[index].arrTaskHolderBuyer.map(x => x.label).join(",");
                values[index].TaskHolderBuyerTxt = names;
            }
            if (values[index].arrFollowerCube.length > 0) {
                let names = values[index].arrFollowerCube.map(x => x.label).join(",");
                values[index].FollowerCubeTxt = names;
            }
            if (values[index].arrFollowerSupplier.length > 0) {
                let names = values[index].arrFollowerSupplier.map(x => x.label).join(",");
                values[index].FollowerSupplierTxt = names;
            }
        }
        values[index].IsTHFirstTime = 1;
    }


    const handleTHLeaveDaysClose = (index) => {
        let TxtList = "";
        let IdList = "";
        if (values[index].TaskHolderBuyerTxt !== '') {
            TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
        }
        if (values[index].FollowerCubeTxt !== '') {
            TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        if (values[index].FollowerSupplierTxt !== '') {
            TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        }

        values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;
        values[index].IsTHLeaveDaysOpen = 0;
        setInputFields(values);
        commonCalculation(false, false);
    }

    const handleTHLeaveDaysSave = (index) => {

        let Taskhoder = BindTaskHoldername(values, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            setInputFields(values);
            commonCalculation(false, false);
        }

    }

    const handleTHLeaveDaysSaveNext = (index) => {

        let Taskhoder = BindTaskHoldername(values, index);
        if (Taskhoder === "") {
            Nodify('Warning!', 'warning', 'Please select task holder name');
        } else {
            values[index].IsTHLeaveDaysOpen = 0;
            // commonCalculation(false, false);
            // save Next instance

            index = index + 1;
            let totalValue = values.length - 1;

            if (totalValue >= index) {
                handleTaskHolderLeaveDays(index, values[index].IsTHFirstTime)
                //end Next

            }
            else {
                values[index - 1].IsTHLeaveDaysOpen = 0;
                setInputFields(values);
                commonCalculation(false, false);
            }

        }
    }

    const handleDependencyClose = (index) => {
        values[index].IsDependencyOpen = 0;
        setInputFields(values);
        commonCalculation(false, false);
    };

    const handleIsAutoExfactory = (index) => {
       ;
        let Openclose = index === 1 ? true:false;
        setIsAutoExfactory(Openclose);
        //values[index].IsAutoExfactory = 1;
        //setInputFields(values);
        //commonCalculation(false, false);
    };

    // const handleIsAutoExfactoryClose = (index) => {
    //    ;
    //     setIsAutoExfactory(false);
    //     //values[index].IsAutoExfactory = 0;
    //     //setInputFields(values);
    //     //commonCalculation(false, false);
    // };

    

    const  handleDependencyCloseSaveNext = (index) => {
        //values[index].IsDependencyOpen = 0;
       ;
        values[index].IsDependencyOpen = 0;
        // save Next instance
         index = index + 1;
        let totalValue = values.length - 1;
        if (totalValue >= index) {
            values[index].IsDependencyOpen = 1;

            let TaskDependency = [];
            values.map((Item, i) => {
                //for (let i = 0; i < values.length; i++) {
                if (values[index].arrDependency.length > 0) {
                    let AlreadyExist = values[index].arrDependency.filter(x => x.DependentTaskName === Item.TaskName);
                    let DependencyID = 0;
                    let LogDays = '';
                    if (AlreadyExist.length === 0) {
                        DependencyID = 0;
                        LogDays = '';
                    }
                    else {
                        DependencyID = AlreadyExist[0].DependencyID;
                        LogDays = AlreadyExist[0].LogDays;
                    }
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskName,
                            TNATaskName: Item.strTaskName,
                            DependencyID: DependencyID,
                            LogDays: LogDays,
                            IndexName: (index).toString()
                        }
                    )
                }
                else {
                    TaskDependency.push(
                        {
                            TNADependencyID: 0,
                            DependentTaskName: Item.TaskName,
                            TNATaskName: Item.strTaskName,
                            DependencyID: 0,
                            LogDays: '',
                            IndexName: (index).toString()
                        }
                    )
                }
            });
            values[index].arrDependency = [];
            values[index].arrDependency = TaskDependency;

            //setDepandencTaskName(values[index].strTaskName);
            //setDepandencyrowno(index  + 1);
        }
        else { values[index - 1].IsDependencyOpen = 0; }
        setInputFields(values);
    };

    const handleAddTaskChange = (e, index, childIndex, FieldName) => {

        if (e.target.checked) {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 1;
            let IsSelected = 1;
            let UnselectedRecords = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 0);
            if (UnselectedRecords.length > 0) {
                IsSelected = 0;
            }
            values[index].IsSelectAll = IsSelected;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        } else {
            values[index].FollowupDetails[childIndex].IsTaskSelected = 0;
            values[index].IsSelectAll = 0;
            values[index].AdditionalFollowup = values[index].FollowupDetails.filter(x => x.IsTaskSelected === 1).length;
        }
        setInputFields(values);

    }

    const handleChildChange = (e, index, childIndex, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        var CheckchildAlreadymapped = false;
        if (FieldName === "DependentTaskName" || FieldName === "DependencyID") {
            if (e != null) {
                let SourceDep = values[childIndex].SelectedDependency;

                if (SourceDep !== '') {
                    //check source already mapped with child
                    CheckchildAlreadymapped = SourceDep.includes(index + 1);
                }
                if (CheckchildAlreadymapped === true) {
                    Nodify('Warning!', 'warning', 'Depandency already mapped.');
                    return false;
                }
                else {
                    inputText = parseInt(e.value);
                }

            }
            else {
                inputText = 0;
                if (FieldName === "DependencyID") {
                    values[index].arrDependency[childIndex].LogDays = '';
                }
            }
            values[index].arrDependency[childIndex][FieldName] = inputText;
        }
        else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
                var checkNumber = inputText.replace(/\D/g, '').match('^[0-9]*$');
                inputText = checkNumber[0];
            }
            if (parseInt(inputText) === 0) {
                inputText = '';
            }
            values[index].arrDependency[childIndex][e.target.name] = inputText;
        }
        if (FieldName === "DependencyID") {
            values[index].SelectedDependency = '';
            let DependencyShow = '';
            for (let i = 0; i < values[index].arrDependency.length; i++) {
                if (index !== i) {
                    let DependentID = values[index].arrDependency[i].DependencyID;
                    if (DependentID !== 0) {
                        if (DependencyShow === '') {
                            DependencyShow = (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                        else {
                            DependencyShow = DependencyShow + ',' + (i + 1) + '-' + (DependentID === 1 ? "ES" : DependentID === 2 ? "SS" : DependentID === 3 ? "SE" : DependentID === 4 ? "EE" : "")
                        }
                    }
                }
            }
            values[index].SelectedDependency = DependencyShow;
        }
        values[index].DependencyCount = values[index].arrDependency.filter(x => x.DependencyID !== 0).length;

        setInputFields(values);
    }

    // const PageRedirect = (Func) => {
    //     props.location.state = { message: Func };
    //     props.history.push({  pathname: "/TNAList", state: { message: Func } });
    // }

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', Msg);
        return false;
    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.ResetOperation(e);
        } else {
            window.location.reload();
        }
    }

    const handleRouteChange = (e, FieldName) => {
        if (FieldName === "Route") {
            getDependencyList.forEach((element, index) => {
                // Set the IsAutoExfactory property of each element to 0
                getDependencyList[index].IsAutoExfactory = 0;
            });
            getDependencyList[1].IsAutoExfactory = 1; // set by default
            dispatch(setTNATaskInputFields([]));
            if (e !== null) {
                setRouteIDForEdit(e);
                setRouteID(e.value);
                dispatch(setTNADrpRouteID(e.value));
            } else {
                setRouteIDForEdit([]);
                setRouteID(0);
                dispatch(setTNADrpRouteID(0));
                setInputFields([]);
                setIsSearchSubmitted(false);
                setAutoExfactorytext('');
                //setAutoExfactorytext({Id:2,Name:''});
            }
        }
    }

    // const SearchWithRoute = (ID) => {
    //     dispatch(setTNATaskInputFields([]));
    //     setIsSearchSubmitted(true);
    //     if (RouteID > 0) {
    //         dispatch(setTNADrpRouteID(RouteID));
    //     }
    //     else {
    //         Nodify('Warning!', 'warning', "Please fill all the mandatory(*) fields.");
    //         dispatch(setTNADrpRouteID(0));
    //         setInputFields([]);
    //     }
    // }



    const filterOption = (option, inputValue) => {
        let trimValue = inputValue.trim();
        if (trimValue === "") {
            return option;
        }

        return option.data.subLabel.toLowerCase().includes(trimValue.toLowerCase());
    };


    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                menuPosition="fixed"
                placeholder="DD/MM/YYYY"
            />
        )
    }


    const SaveTNA = (e) => {
        
        setButtonLoader(true);
        e.preventDefault();
        let IsValid = true;
        let TaskNameList = "";
        MainValues[0].SeasonID = getTNASeasonID;
        MainValues[0].BrandID = getTNABrandID;
        MainValues[0].SupplierID = getTNASupplierID;
        MainValues[0].RouteInformationID = getRouteID;
        MainValues[0].PurOrderStyleDetailIDList = PurchaseOrderIDList.toString();

        MainValues[0].TNARouteApplyDetails = [];
        MainValues[0].TNADependencyDetails = [];
        MainValues[0].TNAAdditionalTaskDetails = [];
        MainValues[0].AssignAutocalculation = getAutoExfactorytext.Id;
        

        MainValues[0].BuyerEmployeeIdList = styleValue.BuyerEmployeeIdList;
        MainValues[0].CubeEmployeeIdList = styleValue.CubeEmployeeIdList;
        MainValues[0].SupplierEmployeeIdList = styleValue.SupplierEmployeeIdList;
       ;

        if (getRouteID === 0 ||CubeEmployeeID===0) {
            IsValid = false;
            setButtonLoader(false);
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
            setSubmitted(true);
            IsValid = false;
            return false;
        }
        else {
            inputFields.map((Info, i) => {

                let IsSingleTask = Info.DetailTypeID === 1 ? true : !!!TNAMultiTask.find(element => element.toLowerCase() === Info.strTaskName.toLowerCase());

                if (IsSingleTask === false) {
                    if (Info.IsChildStatus === false) {
                        if (TaskNameList === "") {
                            TaskNameList = Info.strTaskName;
                        } else {
                            TaskNameList = TaskNameList + ", " + Info.strTaskName;
                        }
                    }
                }
                let titleEmployee1 = Info.titleEmployee1;
                let TaskHolderOwners = []
                if (titleEmployee1 === "Buyer") {
                    TaskHolderOwners = Info.TaskHolderBuyer;
                } else if (titleEmployee1 === "Cube") {
                    TaskHolderOwners = Info.FollowerCube;
                } else if (titleEmployee1 === "Supplier") {
                    TaskHolderOwners = Info.FollowerSupplier;
                }

                if (Info.TaskName === 0 || Info.TaskType === 0 || (!Info.Duration) || (!TaskHolderOwners)) {
                    setButtonLoader(false);
                    if (IsValid === true) {
                        Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
                        IsValid = false;
                    }
                    setSubmitted(true);
                    return false;
                }
                else {
                    if (!TaskNameList) {
                        MainValues[0].TNARouteApplyDetails.push({
                            TNARouteApplyID: 0,
                            TaskType: Info.TaskType,
                            TaskNameID: Info.TaskName,
                            Duration: Info.Duration && Info.Duration.toString(),
                            PlanStartDate: convertUTCDateToLocalDate(new Date(Info.StartDate)),
                            EndDate: convertUTCDateToLocalDate(new Date(Info.EndDate)),
                            Dependency: Info.SelectedDependency,
                            TaskOwner: Info.TaskHolderOwnerIdList,
                            LeaveDays: Info.LeaveDays,
                            RouteApplyStatus: 0,
                            TaskHolderID: Info.TaskHolderOrFollower,
                            TaskHolderIDList: Info.TaskHolderBuyer && Info.TaskHolderBuyer.toString(),
                            FollowerIDList1: Info.FollowerCube && Info.FollowerCube.toString(),
                            FollowerIDList2: Info.FollowerSupplier && Info.FollowerSupplier.toString(),
                            IndexName: i.toString(),
                            SingleOrMultiTask: IsSingleTask ? 1 : 2,
                            ActualEndDate: ''

                        })
                        if (Info.arrDependency) {
                            Info.arrDependency.map((child, j) => {
                                if (child.TNATaskName !== '') {
                                    child.IndexName = i.toString();
                                    MainValues[0].TNADependencyDetails.push(child);
                                }
                            });
                        }

                        if (Info.FollowupDetails) {
                            Info.FollowupDetails.map((child, k) => {
                                child.IndexName = i.toString();
                                MainValues[0].TNAAdditionalTaskDetails.push(child);
                            });
                        }
                    }
                }
            });

            if (IsValid) {
                if (TaskNameList === "") {
                    dispatch(insertTNA(MainValues[0]))
                        .then(data => {
                            if (data.outputResult === "1") {
                                props.CallBackTNAAdd('Add');
                            } else if (data.outputResult === "-1") {

                                Nodify('Warning!', 'warning', 'TNA name already Exist');
                            }
                            setButtonLoader(false);
                        })
                        .catch(e => {
                            console.log(e);
                            setButtonLoader(false);
                        });
                } else {
                    //setIsWarningPopup({ isPopupOpen: true, Msg: 'Can you add sub task or delete (' + TaskNameList + ") task list" });
                    setIsWarningPopup({ isPopupOpen: true, Msg: TaskNameList });
                    // Nodify('Warning!', 'warning', 'Can you add sub task or delete ' + TaskNameList + " task list");
                    setButtonLoader(false);
                }

            }
        }
    }


    function convertUTCDateToLocalDate(date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date;
    }

    //    ------------- **** Calculation Begin ***** --------------------------

    //Main Calculation Function
    const commonCalculation = (isDuration, isEndDate) => {

        values.map((Item, i) => {
            let Duration = (Item.Duration !== "" && Item.Duration !== undefined) ? parseInt(Item.Duration) > 0 ? parseInt(Item.Duration) - 1 : 0 : 0;
            let dependencyCount = Item.DependencyCount;

            if (isEndDate) {
                let CheckDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                if (!isSameDay(new Date(Item.EndDate), CheckDate)) {
                    Nodify('Warning!', 'warning', 'You select the end date to leave day');
                }
            }


            if (((i === 0 || dependencyCount === 0) && isEndDate === false) || (isDuration === true && dependencyCount === 0)) {
                // Dependency Calculation Without
                let dbStartDate = new Date(Item.StartDate);
                let total = 0;
                //add Saturday,Sunday and Holiday Count
                dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                let EndDate = addDays(dbStartDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, EndDate, Item.LeaveDays, Item.titleEmployee1, i);
                for (let l = 0; l < total; l++) {
                    EndDate = addDays(EndDate, 1);
                    EndDate = checkDateBetweenInHolidayAndWE(EndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                }

                Item.StartDate = dbStartDate;
                Item.EndDate = EndDate;
                Item.PreStartDate = dbStartDate;
                Item.PreEndDate = EndDate;
                values[i] = Item;
                leavedayscountcalc(dbStartDate, EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
            }
            else if ((i === 0 || dependencyCount === 0) || (isEndDate === true && dependencyCount === 0)) {

                let dbEndDate = Item.EndDate;
                let total = 0;
                //add Saturday,Sunday and Holiday Count
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                let StartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(StartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                for (let l = 0; l < total; l++) {
                    StartDate = subDays(StartDate, 1);
                    StartDate = checkDateBetweenInHolidayAndWE(StartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }

                Item.StartDate = StartDate;
                Item.EndDate = dbEndDate;
                Item.PreStartDate = StartDate;
                Item.PreEndDate = dbEndDate;
                values[i] = Item;
                leavedayscountcalc(Item.StartDate, Item.EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
            }
            else {
                //Dependency Calculation
                let dependency = Item.SelectedDependency;

                let dbEndDate = new Date();
                let dbStartDate = new Date();
                let total = 0;

                if (dependencyCount > 1) {
                    let oldDuration = Item.Duration;
                    //------------------Multiple Dependency Calculation--------------------------------
                    let isAllowed = true;

                    //EndDate Change Duration Increase and Decrease
                    if (isEndDate === true && Item.EndDateChange !== 2) {
                        let chkStartDate = Item.StartDate;
                        let chkEndDate = Item.EndDate;
                        chkStartDate.setHours(0, 0, 0, 0);
                        chkEndDate.setHours(0, 0, 0, 0);
                        if (chkStartDate <= chkEndDate) {
                            let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                            let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                            let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1, i);
                            diffDayCount = diffDayCount - holidayTotal;
                            Item.Duration = diffDayCount;
                            Duration = diffDayCount - 1;

                        } else {
                            // Item.EndDate = Item.preEndDate;
                            isAllowed = false;
                        }
                    }

                    if (isAllowed) {
                        let dependencyMult = [];
                        let splitDepList = dependency.split(',');
                        splitDepList.map((depItem, d) => {

                            addMultDependencyDate(values, Item, depItem, oldDuration, Duration, i, dependencyMult, isEndDate);
                        });

                        const sorted = dependencyMult.slice().sort((a, b) => b.Date - a.Date);
                        let rowSorted = sorted[0];
                        dbEndDate = sorted[0].Date;
                        let lastRow = sorted.filter(d => d.depItem === rowSorted.depItem && d.Date !== dbEndDate);
                        if (lastRow.length > 0) {
                            dbStartDate = lastRow[0].Date;
                        } else {
                            dbStartDate = dbEndDate;
                        }

                        // Get Dependency and Index
                        let splitVal = lastRow[0].depItem.split('-');
                        let indexVal = splitVal[0].trim();
                        let dependencyVal = splitVal[1].trim();



                        if (Item.EndDateChange === 2) {
                            // ---Manual Calculation function------
                            let newEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            let newStartDate = subDays(newEndDate, Duration);
                            //Total Saturday,Sunday and Holiday Count
                            total = totalHolidayAndWE(newStartDate, newEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                            for (let l = 0; l < total; l++) {
                                newStartDate = subDays(newStartDate, 1);
                                newStartDate = checkDateBetweenInHolidayAndWE(newStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                            }

                            if (dependencyVal === "SS") {
                                //---- Start-Start ---- DependencyID 2------
                                //Get Dependent Map StartDate
                                let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                                let LogDate = preStartDate;
                                let Log = Item.arrDependency.filter(d => d.DependencyID === 2)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newStartDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newStartDate) {
                                    Item.StartDate = newStartDate;
                                    Item.EndDate = newEndDate;
                                } else {
                                    Item.StartDate = dbStartDate;
                                    Item.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "SE") {
                                //---- Start-End ----DependencyID 3---((preStartDate - 1) + LogDays = EndDate))-------
                                //Get Dependent Map StartDate
                                let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                                let LogDate = preStartDate;
                                let Log = Item.arrDependency.filter(d => d.DependencyID === 3)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newEndDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newEndDate) {
                                    Item.StartDate = newStartDate;
                                    Item.EndDate = newEndDate;
                                } else {
                                    Item.StartDate = dbStartDate;
                                    Item.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "ES") {
                                //Get Dependent Map StartDate ---- End-Start ----DependencyID 1------
                                let preEndDate = values[indexVal - 1].EndDate;
                                let LogDate = newStartDate;
                                let Log = Item.arrDependency.filter(d => d.DependencyID === 1)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = subDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                preEndDate.setHours(0, 0, 0, 0);
                                if (preEndDate < LogDate) {
                                    Item.StartDate = newStartDate;
                                    Item.EndDate = newEndDate;
                                } else {
                                    Item.StartDate = dbStartDate;
                                    Item.EndDate = dbEndDate;
                                }
                            } else if (dependencyVal === "EE") {
                                //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                                //Get Dependent Map StartDate
                                let preEndDate = values[indexVal - 1].EndDate;
                                let LogDate = preEndDate;
                                let Log = Item.arrDependency.filter(d => d.DependencyID === 4)[0];
                                if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                                    //LogDays Add
                                    LogDate = addDays(LogDate, parseInt(Log.LogDays));
                                }
                                LogDate.setHours(0, 0, 0, 0);
                                newEndDate.setHours(0, 0, 0, 0);
                                if (LogDate <= newEndDate) {
                                    Item.StartDate = newStartDate;
                                    Item.EndDate = newEndDate;
                                } else {
                                    Item.StartDate = dbStartDate;
                                    Item.EndDate = dbEndDate;
                                }
                            }

                        } else {
                            Item.StartDate = dbStartDate;
                            Item.EndDate = dbEndDate;

                        }

                    } else {
                        Item.EndDate = Item.PreEndDate;
                    }

                    values[i] = Item;
                    leavedayscountcalc(Item.StartDate, Item.EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
                } else if (dependencyCount === 1 && dependency.length !== 0) {
                    //-------Single  Dependent Calculation----------------
                    //Get Dependent Name and Index
                    let splitVal = dependency.split('-');
                    let indexVal = splitVal[0].trim();
                    let dependencyVal = splitVal[1].trim();

                    //EndDate Change Duration Increase and Decrease
                    if (isEndDate === true && Item.EndDateChange !== 2) {
                        let chkStartDate = Item.StartDate;
                        let chkEndDate = Item.EndDate;
                        chkStartDate.setHours(0, 0, 0, 0);
                        chkEndDate.setHours(0, 0, 0, 0);
                        if (chkStartDate <= chkEndDate) {
                            if (dependencyVal === "SS" || dependencyVal === "ES") {
                                let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                                let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                                let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1, i);
                                diffDayCount = diffDayCount - holidayTotal;
                                Item.Duration = diffDayCount;
                                Duration = diffDayCount - 1;
                            }


                        } else {
                            Item.EndDate = Item.PreEndDate;
                            Nodify('Warning!', 'warning', 'End date should not be less than start date.');
                        }
                    }

                    // //Skip First Step in SE
                    // if (dependencyVal === "SE" && isEndDate === true) {
                    //     Item.EndDateChange = 2;
                    // }

                    //Check Dependency Value
                    if (dependencyVal === "SS") {
                        //---- Start-Start ---- DependencyID 2------

                        //Get Dependent Map StartDate
                        let preStartDate = values[indexVal - 1].StartDate;
                        let LogDate = new Date();

                        // if (Item.EndDateChange === 2) {
                        //     //Manual Calculation function------(preStartDate + LogDays = StartDate))-------
                        //     dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //     dbStartDate = subDays(dbEndDate, Duration);
                        //     //Total Saturday,Sunday and Holiday Count
                        //     total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                        //     for (let l = 0; l < total; l++) {
                        //         dbStartDate = subDays(dbStartDate, 1);
                        //         dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //     }
                        //     LogDate = preStartDate;
                        //     let Log = Item.arrDependency.filter(d => d.DependencyID === 2)[0];
                        //     if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                        //         //LogDays Add
                        //         LogDate = addDays(LogDate, parseInt(Log.LogDays));
                        //         //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                        //     }
                        // }
                        LogDate.setHours(0, 0, 0, 0);
                        dbStartDate.setHours(0, 0, 0, 0);
                        // if (LogDate <= dbStartDate && Item.EndDateChange === 2) {
                        // } else {
                        //     //Auto Calculation function
                        let Log = Item.arrDependency.filter(d => d.DependencyID === 2)[0];
                        if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                            //LogDays Add
                            dbStartDate = addDays(preStartDate, parseInt(Log.LogDays));
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        } else {
                            //Calculation Saturday,Sunday and Holiday Count
                            dbStartDate = checkDateBetweenInHolidayAndWE(preStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        }

                        dbEndDate = addDays(dbStartDate, Duration);
                        //Total Saturday,Sunday and Holiday Count
                        total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                        for (let l = 0; l < total; l++) {
                            dbEndDate = addDays(dbEndDate, 1);
                            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        }
                        // }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;
                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;

                        values[i] = Item;
                        leavedayscountcalc(Item.StartDate, Item.EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
                    } else if (dependencyVal === "SE") {  //---- Start-End ----DependencyID 3---

                        let preStartDate = subDays(values[indexVal - 1].StartDate, 1);
                        let LogDate = preStartDate;
                        let Log = Item.arrDependency.filter(d => d.DependencyID === 3)[0];

                        // if (isEndDate === true && Item.EndDateChange !== 2) {
                        //     if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                        //         //LogDays Add
                        //         LogDate = addDays(LogDate, parseInt(Log.LogDays));
                        //     }
                        //     LogDate.setHours(0, 0, 0, 0);
                        //     let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                        //     newDate.setHours(0, 0, 0, 0);
                        //     if (LogDate <= newDate) {
                        //         let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                        //         let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);

                        //         let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                        //         diffDayCount = diffDayCount - holidayTotal;
                        //         Item.Duration = diffDayCount;
                        //         // Duration = diffDayCount - 1;
                        //         dbStartDate = Item.StartDate;
                        //         dbEndDate = LastDate;
                        //         // Item.Duration = Duration;
                        //     } else {
                        //         dbStartDate = Item.StartDate;
                        //         dbEndDate = Item.PreEndDate;
                        //         Item.EndDate = Item.PreEndDate;
                        //     }
                        // } else {

                        //     if (Item.EndDateChange === 2) {
                        //         //Manual Calculation function----------((preStartDate - 1) + LogDays = EndDate))-------
                        //         dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //         dbStartDate = subDays(dbEndDate, Duration);
                        //         //Total Saturday,Sunday and Holiday Count
                        //         total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                        //         for (let l = 0; l < total; l++) {
                        //             dbStartDate = subDays(dbStartDate, 1);
                        //             dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //         }

                        //         if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                        //             //LogDays Add
                        //             LogDate = addDays(LogDate, parseInt(Log.LogDays));
                        //             //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                        //         }
                        //     }

                        LogDate.setHours(0, 0, 0, 0);
                        dbEndDate.setHours(0, 0, 0, 0);
                        //     if (LogDate <= dbEndDate && Item.EndDateChange === 2) {
                        //         //Skip
                        //     } else {
                        //Auto Calculation function

                        if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                            //LogDays Add
                            dbEndDate = addDays(preStartDate, parseInt(Log.LogDays));
                            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        } else {
                            dbEndDate = checkDateBetweenInHolidayAndWE(preStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        }

                        dbStartDate = subDays(dbEndDate, Duration);
                        //Total Saturday,Sunday and Holiday Count
                        total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                        for (let l = 0; l < total; l++) {
                            dbStartDate = subDays(dbStartDate, 1);
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        }
                        //     }
                        // }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;
                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;

                        values[i] = Item;
                        leavedayscountcalc(Item.StartDate, Item.EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
                    } else if (dependencyVal === "ES") {
                        //Manual Calculation function-------- End-Start ----DependencyID 1------

                        //Get Dependent Map StartDate
                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = new Date();

                        // if (Item.EndDateChange === 2) {
                        //     // ---Manual Calculation function---------((preEndDate + 1) + LogDays = StartDate))-------
                        //     dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //     dbStartDate = subDays(dbEndDate, Duration);
                        //     //Total Saturday,Sunday and Holiday Count
                        //     total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                        //     for (let l = 0; l < total; l++) {
                        //         dbStartDate = subDays(dbStartDate, 1);
                        //         dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //     }
                        //     LogDate = dbStartDate;
                        //     let Log = Item.arrDependency.filter(d => d.DependencyID === 1)[0];
                        //     if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                        //         //LogDays Add
                        //         LogDate = subDays(LogDate, parseInt(Log.LogDays));
                        //         //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                        //     }
                        // }
                        LogDate.setHours(0, 0, 0, 0);
                        preEndDate.setHours(0, 0, 0, 0);
                        // if (preEndDate < LogDate && Item.EndDateChange === 2) {
                        // } else {
                        //     //Auto Calculation function
                        dbStartDate = addDays(preEndDate, 1);
                        let log = Item.arrDependency.filter(d => d.DependencyID === 1)[0];
                        if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                            //LogDays Add
                            dbStartDate = addDays(dbStartDate, parseInt(log.LogDays));
                        }
                        //Calculation Saturday,Sunday and Holiday Count
                        dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        dbEndDate = addDays(dbStartDate, Duration);
                        //Total Saturday,Sunday and Holiday Count
                        total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                        for (let l = 0; l < total; l++) {
                            dbEndDate = addDays(dbEndDate, 1);
                            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        }
                        // }

                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;

                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;
                        values[i] = Item;
                        leavedayscountcalc(Item.StartDate, Item.EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
                    } else if (dependencyVal === "EE") {

                        //---- End-End --------DependencyID 4-----------((preEndDate) + LogDays = EndDate))-------
                        //Get Dependent Map StartDate
                        let preEndDate = values[indexVal - 1].EndDate;
                        let LogDate = preEndDate;
                        let Log = Item.arrDependency.filter(d => d.DependencyID === 4)[0];

                        // if (isEndDate === true && Item.EndDateChange !== 2) {
                        //     if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                        //         //LogDays Add
                        //         LogDate = addDays(LogDate, parseInt(Log.LogDays));
                        //     }
                        //     LogDate.setHours(0, 0, 0, 0);
                        //     let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                        //     newDate.setHours(0, 0, 0, 0);
                        //     if (LogDate <= newDate) {
                        //         let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                        //         let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                        //         let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1);
                        //         diffDayCount = diffDayCount - holidayTotal;
                        //         Item.Duration = diffDayCount;
                        //         dbStartDate = Item.StartDate;
                        //         dbEndDate = LastDate;
                        //     } else {
                        //         dbStartDate = Item.StartDate;
                        //         dbEndDate = Item.PreEndDate;
                        //         Item.EndDate = Item.PreEndDate;
                        //     }
                        // }
                        // else {

                        //     if (Item.EndDateChange === 2) {
                        //         // ---Manual Calculation function------
                        //         dbEndDate = checkDateBetweenInHolidayAndWE(Item.EndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //         dbStartDate = subDays(dbEndDate, Duration);
                        //         //Total Saturday,Sunday and Holiday Count
                        //         total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1);
                        //         for (let l = 0; l < total; l++) {
                        //             dbStartDate = subDays(dbStartDate, 1);
                        //             dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        //         }
                        //         LogDate = preEndDate;

                        //         if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                        //             //LogDays Add
                        //             LogDate = addDays(LogDate, parseInt(Log.LogDays));
                        //             //LogDate = checkDateBetweenInHolidayAndWE(LogDate, Item.LeaveDays, "sub");
                        //         }
                        //     }
                        LogDate.setHours(0, 0, 0, 0);
                        dbEndDate.setHours(0, 0, 0, 0);
                        //     if (LogDate <= dbEndDate && Item.EndDateChange === 2) {
                        //     }
                        //     else {
                        //         //Auto Calculation function
                        if (Log && Log.LogDays !== "" && Log.LogDays !== undefined) {
                            //LogDays Add
                            dbEndDate = addDays(preEndDate, parseInt(Log.LogDays));
                            dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                        } else {
                            dbEndDate = checkDateBetweenInHolidayAndWE(preEndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        }
                        dbStartDate = subDays(dbEndDate, Duration);
                        //Total Saturday,Sunday and Holiday Count
                        total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                        for (let l = 0; l < total; l++) {
                            dbStartDate = subDays(dbStartDate, 1);
                            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                        }
                        //     }
                        // }

                        Item.EndDate = dbEndDate;
                        Item.StartDate = dbStartDate;
                        Item.PreStartDate = dbStartDate;
                        Item.PreEndDate = dbEndDate;

                        values[i] = Item;
                        leavedayscountcalc(Item.StartDate, Item.EndDate, Item.LeaveDays, Item.titleEmployee1, i, 'add')
                    }
                }
            }
        });
        setInputFields(values);
    }

    //Multiple Dependency Date add
    function addMultDependencyDate(values, Item, depItem, oldDuration, Duration, i, dependencyMult, isEndDate) {
        // Get Dependency and Index
        let splitVal = depItem.split('-');
        let indexVal = splitVal[0].trim();
        let dependencyVal = splitVal[1].trim();

        let total = 0;
        let dbEndDate = new Date();
        let dbStartDate = new Date();
        if (dependencyVal === "SS") {
            //---- Start-Start ----
            dbStartDate = values[indexVal - 1].StartDate;
            let log = Item.arrDependency.filter(d => d.DependencyID === 2)[0];
            if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                //LogDays Add
                dbStartDate = addDays(dbStartDate, parseInt(log.LogDays));
            }
            //Calculation Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
            dbEndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
            for (let l = 0; l < total; l++) {
                dbEndDate = addDays(dbEndDate, 1);
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
            }

            dependencyMult.push({ Date: dbStartDate, depItem: depItem });
            dependencyMult.push({ Date: dbEndDate, depItem: depItem });

        } else if (dependencyVal === "SE") {

            //---- Start-End ----
            dbEndDate = subDays(values[indexVal - 1].StartDate, 1);
            let LogDate = dbEndDate;
            let log = Item.arrDependency.filter(d => d.DependencyID === 3)[0];

            if (isEndDate === true && Item.EndDateChange !== 2) {
                if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                    //LogDays Add
                    LogDate = addDays(LogDate, parseInt(log.LogDays));
                }
                LogDate.setHours(0, 0, 0, 0);
                let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                newDate.setHours(0, 0, 0, 0);
                if (LogDate <= newDate) {
                    let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                    let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);

                    let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1, i);
                    diffDayCount = diffDayCount - holidayTotal;
                    Item.Duration = diffDayCount;
                    // Duration = diffDayCount - 1;
                    dbStartDate = Item.StartDate;
                    dbEndDate = LastDate;
                    // Item.Duration = Duration;
                } else {
                    dbStartDate = Item.StartDate;
                    dbEndDate = Item.PreEndDate;
                    Item.Duration = oldDuration;

                }
            } else {
                if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                    //LogDays Add
                    dbEndDate = addDays(dbEndDate, parseInt(log.LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                } else {
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }
                dbStartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                for (let l = 0; l < total; l++) {
                    dbStartDate = subDays(dbStartDate, 1);
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }
            }
            dependencyMult.push({ Date: dbStartDate, depItem: depItem });
            dependencyMult.push({ Date: dbEndDate, depItem: depItem });

        } else if (dependencyVal === "ES") {
            //---- End-Start ----
            dbStartDate = addDays(values[indexVal - 1].EndDate, 1);
            let log = Item.arrDependency.filter(d => d.DependencyID === 1)[0];
            if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                //LogDays Add
                dbStartDate = addDays(dbStartDate, parseInt(log.LogDays));
            }
            //Calculation Saturday,Sunday and Holiday Count
            dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "add");
            dbEndDate = addDays(dbStartDate, Duration);
            //Total Saturday,Sunday and Holiday Count
            total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
            for (let l = 0; l < total; l++) {
                dbEndDate = addDays(dbEndDate, 1);
                dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
            }

            dependencyMult.push({ Date: dbStartDate, depItem: depItem });
            dependencyMult.push({ Date: dbEndDate, depItem: depItem });

        } else if (dependencyVal === "EE") {
            //---- End-End ----
            dbEndDate = values[indexVal - 1].EndDate;
            let LogDate = dbEndDate;
            let log = Item.arrDependency.filter(d => d.DependencyID === 4)[0];

            if (isEndDate === true && Item.EndDateChange !== 2) {
                if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                    //LogDays Add
                    LogDate = addDays(LogDate, parseInt(log.LogDays));
                }
                LogDate.setHours(0, 0, 0, 0);
                let newDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                newDate.setHours(0, 0, 0, 0);
                if (LogDate <= newDate) {
                    let LastDate = checkDateBetweenInHolidayAndWE(new Date(Item.EndDate), Item.LeaveDays, Item.titleEmployee1, "add");
                    let diffDayCount = diffDays(new Date(Item.StartDate), LastDate);
                    let holidayTotal = totalHolidayAndWE(Item.StartDate, LastDate, Item.LeaveDays, Item.titleEmployee1, i);
                    diffDayCount = diffDayCount - holidayTotal;
                    Item.Duration = diffDayCount;
                    dbStartDate = Item.StartDate;
                    dbEndDate = LastDate;
                } else {
                    dbStartDate = Item.StartDate;
                    dbEndDate = Item.PreEndDate;
                    Item.Duration = oldDuration;
                }
            } else {
                if (log && log.LogDays !== "" && log.LogDays !== undefined) {
                    //LogDays Add
                    dbEndDate = addDays(dbEndDate, parseInt(log.LogDays));
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "add");
                } else {
                    dbEndDate = checkDateBetweenInHolidayAndWE(dbEndDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }
                dbStartDate = subDays(dbEndDate, Duration);
                //Total Saturday,Sunday and Holiday Count
                total = totalHolidayAndWE(dbStartDate, dbEndDate, Item.LeaveDays, Item.titleEmployee1, i);
                for (let l = 0; l < total; l++) {
                    dbStartDate = subDays(dbStartDate, 1);
                    dbStartDate = checkDateBetweenInHolidayAndWE(dbStartDate, Item.LeaveDays, Item.titleEmployee1, "sub");
                }
            }

            dependencyMult.push({ Date: dbStartDate, depItem: depItem });
            dependencyMult.push({ Date: dbEndDate, depItem: depItem });
        }
    }


    function isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth();
    }

    function diffDays(date, otherDate) {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function totalHolidayAndWE(startDate, endDate, leaveDays, taskHolderName, i) {
       
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        let format = "MM/DD/YYYY";
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        //  leavedayscountcalc(startDate, endDate, leaveDays, taskHolderName, i, 'add')
        // let Leavetext = '';
        // if (total !== 0) {
        //     if (leaveDays === 2) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Sunday(" + totalSunday + ") Holiday(" + totalHoliday + ")";;
        //     } else if (leaveDays === 3) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Sunday(" + totalSunday + ")";
        //     } else if (leaveDays === 4) {
        //         Leavetext = "Sunday(" + totalSunday + ")";
        //     } else if (leaveDays === 5) {
        //         Leavetext = "Holiday(" + totalHoliday + ")";
        //     } else if (leaveDays === 6) {
        //         Leavetext = "Sunday(" + totalSunday + ") Holiday(" + totalHoliday + ")";
        //     } else if (leaveDays === 7) {
        //         Leavetext = "Saturday(" + totalSaturday + ")";
        //     } else if (leaveDays === 8) {
        //         Leavetext = "Saturday(" + totalSaturday + ") Holiday(" + totalHoliday + ")";
        //     }
        //     values[i].LeaveDaysTxt = Leavetext;
        // }

        // setInputFields(values);
        return total;
    }

    function leavedayscountcalc(startDate, endDate, leaveDays, taskHolderName, i) {
       
        let format = "MM/DD/YYYY";
        let totalSaturday = 0;
        let totalSunday = 0;
        let totalHoliday = 0;
        let total = 0;
        // Saturday,Sunday and Holiday Count
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (currentDate.getDay() === 0) {
                totalSunday++;
            }
            if (currentDate.getDay() === 6) {
                totalSaturday++;
            }
            if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                var newDate = new Date(moment(currentDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
                let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= newDate && newDate <= new Date(moment(d.holidayEndDate, format))).length;
                if (HolidayCount > 0) {
                    totalHoliday++;
                }
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // Check LeaveDays base Saturday,Sunday and Holiday Count
        if (leaveDays === 2) {
            total = totalSaturday + totalSunday + totalHoliday;
        } else if (leaveDays === 3) {
            total = totalSaturday + totalSunday;
        } else if (leaveDays === 4) {
            total = totalSunday;
        } else if (leaveDays === 5) {
            total = totalHoliday;
        } else if (leaveDays === 6) {
            total = totalSunday + totalHoliday;
        } else if (leaveDays === 7) {
            total = totalSaturday;
        } else if (leaveDays === 8) {
            total = totalSaturday + totalHoliday;
        }
        let Leavetext = '';
        if (total !== 0) {
            if (leaveDays === 2) {
                Leavetext = "Sat(" + totalSaturday + ") Sun(" + totalSunday + ") Hol(" + totalHoliday + ")";;
            } else if (leaveDays === 3) {
                Leavetext = "Sat(" + totalSaturday + ") Sun(" + totalSunday + ")";
            } else if (leaveDays === 4) {
                Leavetext = "Sun(" + totalSunday + ")";
            } else if (leaveDays === 5) {
                Leavetext = "Holiday(" + totalHoliday + ")";
            } else if (leaveDays === 6) {
                Leavetext = "Sun(" + totalSunday + ") Hol(" + totalHoliday + ")";
            } else if (leaveDays === 7) {
                Leavetext = "Sat(" + totalSaturday + ")";
            } else if (leaveDays === 8) {
                Leavetext = "Sat(" + totalSaturday + ") Hol(" + totalHoliday + ")";
            }
            values[i].LeaveDaysTxt = Leavetext;
        }

        setInputFields(values);
    }

    function checkDateBetweenInHolidayAndWE(flyDate, leaveDays, taskHolderName, status) {
        let format = "MM/DD/YYYY";
        let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
        let holidayList = getCompanyHolidayList.filter(d => d.taskHolderName === taskHolderName);
        let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
        //ReCheck Date in Saturday,Sunday and Holiday Count
        if (flyDate.getDay() === 0 || flyDate.getDay() === 6 || HolidayCount > 0) {
            let i = 0;
            while (i <= 3) {
                //Weekend Date Check
                if (flyDate.getDay() === 0 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 4 || leaveDays === 6)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }
                if (flyDate.getDay() === 6 && (leaveDays === 2 || leaveDays === 3 || leaveDays === 7 || leaveDays === 8)) {
                    status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                } else {
                    i++;
                }

                if (leaveDays === 2 || leaveDays === 5 || leaveDays === 6 || leaveDays === 8) {
                    let checkDate = new Date(moment(flyDate, "DD/MM/YYYY").format("MM/DD/YYYY"));
                    let HolidayCount = holidayList.filter(d => new Date(moment(d.holidayDate, format)) <= checkDate && checkDate <= new Date(moment(d.holidayEndDate, format))).length;
                    if (HolidayCount > 0) {
                        status === "sub" ? flyDate = subDays(flyDate, 1) : flyDate = addDays(flyDate, 1);
                    } else {
                        i++;
                    }
                } else {
                    i++;
                }

                i === 3 ? i = 4 : i = 0;
            }
        }
        return flyDate;
    }

    //    ------------- **** Calculation End ***** --------------------------
    function BindTaskHoldername(values, index) {
        let TxtList = "";
        let IdList = "";
        let Taskhoder = "";
        let titleEmployee1 = values[index].titleEmployee1;
        let titleEmployee2 = values[index].titleEmployee2;
        let titleEmployee3 = values[index].titleEmployee3;

        if (values[index].TaskHolderBuyer && (titleEmployee1 === "Buyer" || titleEmployee2 === "Buyer" || titleEmployee3 === "Buyer")) {
            TxtList = values[index].TaskHolderBuyerTxt;
            IdList = values[index].TaskHolderBuyer;
            //Taskhoder = values[index].TaskHolderBuyer;
        } else {
            values[index].TaskHolderBuyer = "";
            values[index].TaskHolderBuyerTxt = "";
            values[index].arrTaskHolderBuyer = [];
        }

        if (values[index].FollowerCube && (titleEmployee1 === "Cube" || titleEmployee2 === "Cube" || titleEmployee3 === "Cube")) {
            TxtList = TxtList === "" ? values[index].FollowerCubeTxt : TxtList + ", " + values[index].FollowerCubeTxt;
            IdList = IdList === "" ? values[index].FollowerCube : IdList + "," + values[index].FollowerCube;
        }
        else {
            values[index].FollowerCube = "";
            values[index].FollowerCubeTxt = "";
            values[index].arrFollowerCube = [];
        }

        if (values[index].FollowerSupplier && (titleEmployee1 === "Supplier" || titleEmployee2 === "Supplier" || titleEmployee3 === "Supplier")) {
            TxtList = TxtList === "" ? values[index].FollowerSupplierTxt : TxtList + ", " + values[index].FollowerSupplierTxt;
            IdList = IdList === "" ? values[index].FollowerSupplier : IdList + "," + values[index].FollowerSupplier;
        } else {
            values[index].FollowerSupplier = "";
            values[index].FollowerSupplierTxt = "";
            values[index].arrFollowerSupplier = [];
        }

        values[index].TaskHolderOwnerTxtList = TxtList;
        values[index].TaskHolderOwnerIdList = IdList;

        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }
    const checkTaskHolderOwer = (index) => {
        let Taskhoder = "";
        if (values[index].titleEmployee1 === 'Cube') {
            Taskhoder = values[index].FollowerCube;
        }
        else if (values[index].titleEmployee1 === 'Buyer') {
            Taskhoder = values[index].TaskHolderBuyer;
        }
        else { Taskhoder = values[index].FollowerSupplier; }
        return Taskhoder;
    }


    return (
        <Fragment>
            <div className="widget-body">
                <div id="registration-form">
                    <form>
                        {/* <div className="col-md-12">

                            <div className="row">
                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label> Route<span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <Reactselect className="basic-single" name="RouteID"
                                                id={"RouteID"}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                value={getRouteList.filter(function (option) {
                                                    return option.value === getRouteID;
                                                })}
                                                //value={RouteIDForEdit}
                                               
                                                options={getRouteList}
                                                onChange={event => handleRouteChange(event, 'Route')}
                                                styles={getRouteID === 0 ? styles : ''}
                                            ></Reactselect>
                                        </span>
                                    </div>
                                </div>

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="CubeEmployeeIdList">Cube Employee<span className="text-danger">*</span></label>
                                        <span className='input-icon icon-right'>
                                            <Reactselect className="basic-single" name="CubeEmployeeIdList"
                                                id={
                                                    "CubeEmployeeIdList"
                                                }
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                isMulti
                                                onChange={event => handleChange(event, 0,'CubeEmployeeIdList')}
                                                options={CubeList}
                                                value={getStyleList.arrCubeEmployeeID}
                                                // value={CubeList.filter(function (option) {
                                                //     return option.value === getStyleList.BuyerEmployeeID;
                                                // })}
                                                styles={CubeEmployeeID === 0 ? styles : ''}

                                            ></Reactselect>
                                        </span>
                                    </div>
                                </div>

                                 <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="BuyerEmployeeIdList">Buyer Employee</label>
                                        <span className='input-icon icon-right'>
                                            <Reactselect className="basic-single" name="BuyerEmployeeIdList"
                                                id={
                                                    "BuyerEmployeeIdList"
                                                }
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                isMulti
                                                
                                                onChange={event => handleChange(event, 0,'BuyerEmployeeIdList')}
                                                options={getEmployeeList}
                                                value={getStyleList.arrBuyerEmployeeID}
                                                
                                               // styles={submitted && getStyleList.arrBuyerEmployeeID.length === 0 ? styles : ''}

                                            ></Reactselect>
                                        </span>
                                    </div>
                                </div> 

                            

                                <div className="col-sm-3">
                                    <div className='form-group'>
                                        <label htmlFor="SupplierEmployeeIdList">Supplier Employee</label>
                                        <span className='input-icon icon-right'>
                                            <Reactselect className="basic-single" name="SupplierEmployeeIdList"
                                                id={
                                                    "SupplierEmployeeIdList"
                                                }
                                                 classNamePrefix="SupplierEmployeeIdList"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                isMulti
                                                onChange={event => handleChange(event, 0,'SupplierEmployeeIdList')}
                                                options={SupList}
                                                value={getStyleList.arrSupplierEmployeeID}
                                            // value={SupList.filter(function (option) {
                                            //     return option.value === getStyleList.SupplierEmployeeID;
                                            // })}
                                            //styles={submitted && getStyleList.arrSupplierEmployeeID === 0 ? styles : ''}

                                            ></Reactselect>
                                        </span>
                                    </div>
                                </div>
                                <div >


                                {
                                    getRouteID !== 0 &&
                                        <div className="" 
                                        style={{
                                            // marginRight: "490px"
                                            textAlign:"right" }}
                                            >
                                            <i title={'Assiging Auto date calcaultion condition' } onClick={event => {handleIsAutoExfactory(1) }} class="menu-icon fa fa-list" style={{ marginTop: "5.5%", fontSize: "21px"  }}></i>
                                        </div>
                                }
                                </div>
                               
                            </div>

                                </div> */}

<div className="col-md-12">
    <div className="row">
        <div className="col-sm-3">
            <div className="form-group">
                <label>
                    Route<span className="text-danger">*</span>
                </label>
                <span className="input-icon icon-right">
                    <Reactselect
                        className="basic-single"
                        name="RouteID"
                        id={"RouteID"}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        value={getRouteList.filter(function (option) {
                            return option.value === getRouteID;
                        })}
                        options={getRouteList}
                        onChange={(event) => handleRouteChange(event, 'Route')}
                        styles={getRouteID === 0 ? styles : ''}
                    ></Reactselect>
                </span>
            </div>
        </div>

        <div className="col-sm-3">
            <div className="form-group">
                <label htmlFor="CubeEmployeeIdList">
                    Cube Employee<span className="text-danger">*</span>
                </label>
                <span className="input-icon icon-right">
                    <Reactselect
                        className="basic-single"
                        name="CubeEmployeeIdList"
                        id={"CubeEmployeeIdList"}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                        onChange={(event) => handleChange(event, 0, 'CubeEmployeeIdList')}
                        options={CubeList}
                        value={getStyleList.arrCubeEmployeeID}
                        styles={CubeEmployeeID === 0 ? styles : ''}
                    ></Reactselect>
                </span>
            </div>
        </div>

        <div className="col-sm-3">
            <div className="form-group">
                <label htmlFor="BuyerEmployeeIdList">Buyer Employee</label>
                <span className="input-icon icon-right">
                    <Reactselect
                        className="basic-single"
                        name="BuyerEmployeeIdList"
                        id={"BuyerEmployeeIdList"}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                        onChange={(event) => handleChange(event, 0, 'BuyerEmployeeIdList')}
                        options={getEmployeeList}
                        value={getStyleList.arrBuyerEmployeeID}
                    ></Reactselect>
                </span>
            </div>
        </div>

        <div className="col-sm-3">
            <div className="form-group">
                <label htmlFor="SupplierEmployeeIdList">Supplier Employee</label>
                <span className="input-icon icon-right">
                    <Reactselect
                        className="basic-single"
                        name="SupplierEmployeeIdList"
                        id={"SupplierEmployeeIdList"}
                        classNamePrefix="SupplierEmployeeIdList"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isSearchable={true}
                        isMulti
                        onChange={(event) => handleChange(event, 0, 'SupplierEmployeeIdList')}
                        options={SupList}
                        value={getStyleList.arrSupplierEmployeeID}
                    ></Reactselect>
                </span>
            </div>
        </div>
<div className="row">
        {getRouteID !== 0 && (
            <div
                className=""
                // style={{
                //     textAlign: "right",
                //     position: "relative", // Ensures controlled placement
                //     top: "-20px", // Adjusts icon position to avoid extra space
                // }}
                style={{
                    // marginRight: "490px"
                    textAlign:"right" }}
                
            >
                <i
                    title={'Assigning Auto date calculation condition'}
                    onClick={(event) => {
                        handleIsAutoExfactory(1);
                    }}
                    className="menu-icon fa fa-list"
                    // style={{
                    //     fontSize: "21px",
                    //     cursor: "pointer",
                    // }}
                    style={{
                        //  marginTop: "5.5%", 
                         marginRight:"30px",
                        fontSize: "21px"  }}
                ></i>
            </div>
        )}
        </div>
    </div>
</div>


                        <div className="col-md-12" style={{ overflowX: "auto" }}>
                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "110vm" }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "75px" }}> #</th>
                                        <th style={{ width: "450px" }}>Task Type<span className="text-danger">*</span></th>
                                        <th style={{ width: "800px" }}>Task<span className="text-danger">*</span></th>
                                        <th style={{ width: "100px" }}>Duration<span className="text-danger">*</span></th>
                                        <th style={{ width: "330px" }}>Start Date</th>
                                        <th style={{ width: "450px" }}>End Date</th>
                                        <th style={{ width: "320px" }}>Dependency</th>
                                        <th style={{ width: "500px" }}>Task Holder / Follower<span className="text-danger">*</span></th>
                                        <th style={{ width: "430px" }}>Leave Days</th>
                                        <th style={{ width: "255px" }} className="text-center fixed-column-header-Route">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        inputFields.map((inputField, index) => (
                                            <Fragment key={
                                                `${inputField}~${index}`
                                            }>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {/* <span title={getTaskTypeList.map((x)=>{
                                                            let stringWithoutCommas
                                                            if(x.value=== inputField.TaskType){
                                                                 stringWithoutCommas = x.label.replace(/,/g, '');
                                                                return stringWithoutCommas
                                                            }
                                                        })} className='input-icon icon-right'> */}
                                                        <span className='input-icon icon-right' title={getTaskTypeList.filter(function (option) {
                                                            return option.value === inputField.TaskType;
                                                        })[0]?.label}>

                                                            <Reactselect className="basic-single" name="TaskType"
                                                                id={
                                                                    "TaskType" + index
                                                                }
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={event => handleChange(event, index, "TaskType")}
                                                                onBlur={() => CheckDuplicate(index, 'TaskType')}
                                                                value={getTaskTypeList.filter(function (option) {
                                                                    return option.value === inputField.TaskType;
                                                                })}
                                                                options={getTaskTypeList}
                                                                menuPosition="fixed"
                                                                styles={submitted && inputField.TaskType === 0 ? styles : ''}

                                                            ></Reactselect>

                                                        </span>

                                                    </td>
                                                    <td>
                                                        <span title={inputField.strTaskName} className='input-icon icon-right'>
                                                            <Reactselect className="basic-single" name="TaskName"
                                                                id={
                                                                    "TaskName" + index
                                                                }
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={event => handleChange(event, index, "TaskName")}
                                                                onBlur={() => CheckDuplicate(index, 'TaskName')}
                                                                value={TaskList.filter(function (option) {
                                                                    return option.value === inputField.TaskName;
                                                                })}
                                                                options={inputField.TaskType === 0 ?
                                                                    TaskList : TaskList.filter(x => x.taskTypeID === inputField.TaskType)}
                                                                isOptionDisabled={(option) => option.isdisabled}
                                                                menuPosition="fixed"
                                                                styles={submitted && inputField.TaskName === 0 ? styles : ''}
                                                            ></Reactselect>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className='input-icon icon-right'>
                                                            <input type="text"
                                                                placeholder="Duration"
                                                                id={"Duration" + index}
                                                                name="Duration"
                                                                value={inputField.Duration}
                                                                onChange={event => handleChange(event, index, "Duration")}
                                                                onBlur={() => CheckDuplicate(index, 'Duration')}
                                                                maxLength="3"
                                                                className={'form-control' + (
                                                                    submitted && !inputField.Duration ? ' is-invalid' : ''
                                                                )}
                                                                style={{ border: submitted && !inputField.Duration ? '1px solid red' : '' }}
                                                            />
                                                        </span>
                                                    </td>
                                                    <td>

                                                        <span className="input-icon icon-right">
                                                            <DatePicker className="form-control"
                                                                name="StartDate"
                                                                id={
                                                                    "StartDate" + index
                                                                }
                                                                selected={
                                                                    inputField.StartDate
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                readOnly={true}

                                                            />

                                                        </span>
                                                    </td>
                                                    <td>

                                                        <span className="input-icon icon-right">
                                                            <DatePicker className="form-control"
                                                                name="EndDate"
                                                                id={
                                                                    "EndDate" + index
                                                                }
                                                                selected={
                                                                    inputField.EndDate
                                                                }
                                                                onChange={
                                                                    event => handleChange(event, index, "EndDate")
                                                                }
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="scroll"
                                                                autoComplete="off"
                                                                popperProps={{
                                                                    positionFixed: true // use this to make the popper position: fixed
                                                                }}
                                                                //menuPosition="fixed"
                                                                // minDate={inputField.HolidayDate === "" ? (new Date("01/01/" + getselectedDate)) : inputField.HolidayDate}
                                                                //maxDate={new Date("12/31/" + getselectedDate)}
                                                                customInput={<CustomInput />}
                                                            // isClearable={inputField.EndDate === '' ? false : true}
                                                            />
                                                            {/* {
                                                                inputField.TotalHoliday
                                                            } */}

                                                        </span>
                                                    </td>
                                                    <td>


                                                        {
                                                            index === 0 ? '' :
                                                                <input type="text"
                                                                    value={inputField.SelectedDependency}
                                                                    title={inputField.SelectedDependency}
                                                                    onClick={() => handleDependency(index, 1)}
                                                                    className={'form-control'}
                                                                    style={{ width: inputField.DependencyCount === 0 ? "100%" : "85%", float: "left" }}
                                                                />
                                                        }
                                                        {/* {
                                                            index !== 0 ?
                                                                inputField.DependencyCount !== 0 ?
                                                                    <span className="spanDependencyCount" onClick={() => handleDependency(index, 1)}> {inputField.DependencyCount}</span> : ''
                                                                : ''
                                                        } */}

                                                    </td>
                                                    <td>
                                                        <span
                                                            className={'form-control'} onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                            style={{ width: "100%", paddingTop: "8px", float: "left", border: submitted && !checkTaskHolderOwer(index) ? '1px solid red' : '' }}
                                                        > &nbsp;&nbsp;&nbsp;  <i className="fa fa-eye" style={{ marginRight: '4px' }} title="Task Holder /Follower"></i>
                                                            {
                                                                getTaskHolderList.filter(d => d.value === inputField.TaskHolderOrFollower).map(task => {
                                                                    return task.label;
                                                                })
                                                            }
                                                        </span>
                                                        {/* <input type="text"
                                                            value={inputField.TaskHolderOrFollowerTxt}
                                                            title={inputField.TaskHolderOrFollowerTxt}
                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                            className={'form-control'}
                                                            //style={{ width: "100%", float: "left" }}
                                                            style={{ width: "100%", float: "left", border: submitted && !inputField.TaskHolderOwnerIdList ? '1px solid red' : '' }}
                                                        /> */}


                                                    </td>
                                                    <td>
                                                        <input type="text"
                                                            value={inputField.LeaveDaysTxt}
                                                            title={inputField.LeaveDaysTxt}
                                                            onClick={() => handleTaskHolderLeaveDays(index, inputField.IsTHFirstTime)}
                                                            className={'form-control'}
                                                            style={{ width: "100%", float: "left" }}
                                                        />
                                                        {/* <Reactselect className="basic-single" name="LeaveDays"
                                                                            id={
                                                                                "LeaveDays"
                                                                            }
                                                                            isLoading={false}
                                                                            isClearable={true}
                                                                            isSearchable={true}
                                                                            onChange={event => handleChange(event, index, "LeaveDays")}
                                                                            value={getLeaveDaysList.filter(function (option) {
                                                                                return option.value === inputField.LeaveDays;
                                                                            })}
                                                                            options={getLeaveDaysList}
                                                                            styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                            menuPosition="fixed"
                                                                        ></Reactselect> */}
                                                    </td>

                                                    <td className="fixed-column-Route">

                                                       {
                                                            inputField.IsDependencyOpen === 1 &&

                                                            <Modal show={inputField.IsDependencyOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleDependencyClose(index)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                        {/* Task Name : {inputField.strTaskName} */}
                                                                        {/* Dependency - {index + 1} {inputField.strTaskName} */}
                                                                        Dependency (Selected Task - {inputField.strTaskName}, Row no - {index + 1})
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body className="dependency-overflow">
                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "50px" }}>#</th>
                                                                                <th style={{ width: "450px" }}>Task Name</th>
                                                                                <th style={{ width: "200px" }}>Dependency</th>
                                                                                <th style={{ width: "150px" }}>Log Days</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                inputField.arrDependency.map((ChildField, childIndex) => (
                                                                                    <Fragment key={
                                                                                        `${ChildField}~${childIndex}`
                                                                                    }>
                                                                                        <tr>
                                                                                            <td>{childIndex + 1}</td>
                                                                                            <td>{ChildField.TNATaskName}</td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <Reactselect className="basic-single" name="DependencyID"
                                                                                                        id={
                                                                                                            "DependencyID" + index
                                                                                                        }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "DependencyID")}
                                                                                                        value={getDependencyList.filter(function (option) {
                                                                                                            return option.value === ChildField.DependencyID;
                                                                                                        })}
                                                                                                        options={getDependencyList}
                                                                                                        isDisabled={childIndex === index ? true :
                                                                                                            ChildField.TNATaskName === '' ? true
                                                                                                                : childIndex >= index ? true : false}
                                                                                                    ></Reactselect>
                                                                                                </span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="    Log days"
                                                                                                        id={"LogDays" + index}
                                                                                                        name="LogDays"
                                                                                                        value={ChildField.LogDays}
                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                                        maxLength="3"
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                    />
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="success" onClick={() => handleDependencyClose(index)}>
                                                                        Save
                                                                    </Button>
                                                                    &nbsp;
                                                                    <Button variant="success" onClick={() => handleDependencyCloseSaveNext(index)}>
                                                                        Save & Next
                                                                    </Button>

                                                                </Modal.Footer>
                                                            </Modal>
                                                        }
                                                        {
                                                            inputField.IsTHLeaveDaysOpen === 1 &&

                                                            <Modal show={inputField.IsTHLeaveDaysOpen === 1} size="lg" backdrop="static" keyboard={false} onHide={() => handleTHLeaveDaysClose(index)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                        Leave Days / In-charges - {inputField.strTaskName}
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label> Task Holder / Follower </label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name="TaskHolderOrFollower"
                                                                                        id={"TaskHolderOrFollower"}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        onChange={event => handleChange(event, index, "TaskHolderOrFollower")}
                                                                                        value={getTaskHolderList.filter(function (option) {
                                                                                            return option.value === inputField.TaskHolderOrFollower;
                                                                                        })}
                                                                                        options={getTaskHolderList}
                                                                                        styles={submitted && inputField.TaskHolderOrFollower === 0 ? styles : ''}
                                                                                        menuPosition="fixed"
                                                                                        filterOption={filterOption}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label>Leave Days</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name="LeaveDays"
                                                                                        id={
                                                                                            "LeaveDays"
                                                                                        }
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        onChange={event => handleChange(event, index, "LeaveDays")}
                                                                                        value={getLeaveDaysList.filter(function (option) {
                                                                                            return option.value === inputField.LeaveDays;
                                                                                        })}
                                                                                        options={getLeaveDaysList}
                                                                                        styles={submitted && inputField.LeaveDays === 0 ? styles : ''}
                                                                                        menuPosition="fixed"

                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputField.titleEmployee1 && <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label htmlFor={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Task Holder - {inputField.titleEmployee1 + " Employee"} <span className="text-danger">*</span></label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                        isDisabled={false}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        isMulti
                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee1 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee1 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                        options={inputField.titleEmployee1 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee1 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                        value={inputField.titleEmployee1 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee1 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                    >  </Reactselect>

                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    {
                                                                        inputField.titleEmployee2 && <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className='form-group'>
                                                                                    <label htmlFor={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"} >Follower - {inputField.titleEmployee2 + " Employee"}</label>
                                                                                    <span className='input-icon icon-right'>
                                                                                        <Reactselect className="basic-single" name={inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                            isDisabled={false}
                                                                                            isLoading={false}
                                                                                            isClearable={true}
                                                                                            isSearchable={true}
                                                                                            isMulti
                                                                                            onChange={event => handleChange(event, index, inputField.titleEmployee2 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee2 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                            options={inputField.titleEmployee2 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee2 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                            value={inputField.titleEmployee2 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee2 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                        // options={IMPORTANCE}
                                                                                        // value={getStyleList.arrImportance}
                                                                                        //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                        >  </Reactselect>

                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {inputField.titleEmployee3 && <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className='form-group'>
                                                                                <label htmlFor="FollowerSupplier" >Follower - {inputField.titleEmployee3}Employee</label>
                                                                                <span className='input-icon icon-right'>
                                                                                    <Reactselect className="basic-single" name={inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier"}
                                                                                        isDisabled={false}
                                                                                        isLoading={false}
                                                                                        isClearable={true}
                                                                                        isSearchable={true}
                                                                                        isMulti
                                                                                        onChange={event => handleChange(event, index, inputField.titleEmployee3 === "Buyer" ? 'TaskHolderBuyer' : inputField.titleEmployee3 === "Cube" ? "FollowerCube" : "FollowerSupplier")}
                                                                                        options={inputField.titleEmployee3 === "Buyer" ? drpTNATaskOwnerList.buyerEmployeeList : inputField.titleEmployee3 === "Cube" ? drpTNATaskOwnerList.cubeEmployeeList : drpTNATaskOwnerList.supplierEmployeeList}
                                                                                        value={inputField.titleEmployee3 === "Buyer" ? inputField.arrTaskHolderBuyer : inputField.titleEmployee3 === "Cube" ? inputField.arrFollowerCube : inputField.arrFollowerSupplier}
                                                                                    // options={IMPORTANCE}
                                                                                    // value={getStyleList.arrImportance}
                                                                                    //  styles={submitted && getStyleList.arrImportance === 0 ? styles : ''}
                                                                                    >  </Reactselect>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    }



                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="success" onClick={() => handleTHLeaveDaysSave(index)}>
                                                                        Save
                                                                    </Button>

                                                                    <Button variant="success" onClick={() => handleTHLeaveDaysSaveNext(index)}>
                                                                        Save & Next
                                                                    </Button>

                                                                </Modal.Footer>
                                                            </Modal>
                                                        }

                                                        {
                                                            inputField.IsPopupOpen === 1 &&
                                                            <Modal show={inputField.IsPopupOpen === 1} size="md" dragable backdrop="static" keyboard={false} onHide={() => handleClose(index)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Additional Task</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th>Task Name</th>
                                                                                <th> <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                    <label>
                                                                                        <input type="checkbox"
                                                                                            name="IsSelectAll"
                                                                                            id={"IsSelectAll_" + index}
                                                                                            isMulti
                                                                                            onChange={event => handleChange(event, index, "IsSelectAll")}
                                                                                            checked={inputField.IsSelectAll === 1 ? true : false}
                                                                                            className="colored-blue clearOpacity" />
                                                                                        <span className="text"></span>
                                                                                    </label>
                                                                                </div></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                inputField.FollowupDetails.map((ChildField, childIndex) => (
                                                                                    <Fragment key={
                                                                                        `${inputField}~${index}`
                                                                                    }>
                                                                                        <tr>
                                                                                            <td>{childIndex + 1}</td>
                                                                                            <td>
                                                                                                <input type="text"
                                                                                                    value={ChildField.AddtionalTaskName}
                                                                                                    className={'form-control'}
                                                                                                    disabled
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="checkbox" style={{ marginTop: "0px" }}>
                                                                                                    <label>
                                                                                                        <input type="checkbox"
                                                                                                            name="IsTaskSelected"
                                                                                                            id={"IsTaskSelected_" + index + "_" + childIndex}
                                                                                                            isMulti
                                                                                                            onChange={event => handleAddTaskChange(event, index, childIndex, "IsTaskSelected")}
                                                                                                            checked={ChildField.IsTaskSelected === 1 ? true : false}
                                                                                                            className="colored-blue clearOpacity"
                                                                                                        />
                                                                                                        <span className="text"></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </Modal.Body>
                                                                <Modal.Footer>

                                                                    <Button variant="success" onClick={() => handleClose(index)}> Save </Button>

                                                                </Modal.Footer>
                                                            </Modal>
                                                        }
                                                        <button type="button" className="btn btn-xs btn-primary" title={(getHeaderName === "Edit" ? "Edit" : getHeaderName === "Export" ? "Add" : "View") + " Additional Task"}
                                                            onClick={() => handleFollowup(index)}>
                                                            <b> {inputField.AdditionalFollowup} </b>
                                                            <i className="fa fa-tasks"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-xs btn-success" title="Add Task"
                                                            onClick={() => handleAddFields(index)}>
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                        &nbsp;
                                                        {(inputFields.length !== 1 && getHeaderName !== "View") && (
                                                            <button type="button" className="btn btn-xs btn-danger" title="Delete Task"
                                                                onClick={() => handleRemoveFields(index)}>
                                                                <i className="fa fa-trash-o"></i>
                                                            </button>
                                                        )}
                                                    </td>


                                                      


                                                    
                                                </tr>
                                            </Fragment>
                                        ))
                                    }
                                    {
                                        inputFields.length === 0 ?
                                            <tr>
                                                <td colSpan="10" className='norecordfound'><span>No Records Found</span></td>
                                            </tr> : ''
                                    }

                                    {
                                        isWarningPopup.isPopupOpen === true &&

                                        <SweetAlert
                                            show={isWarningPopup.isPopupOpen === true}
                                            warning
                                            confirmBtnText="Ok"
                                            confirmBtnBsStyle="warning"
                                            cancelBtnBsStyle="primary"
                                            // title={"Delete below task list or else can you add sub task for the style"}
                                            title={<span><small>Delete below task list or else can you add sub task for this style !</small></span>}
                                            onConfirm={warningPopupClose}
                                            showCloseButton
                                            focusCancelBtn
                                            style={{ width: "50%", fontSize: 15 }}
                                        >
                                            <span> <span style={{ color: '#F8BB86' }}></span><b>{isWarningPopup.Msg}</b></span>
                                        </SweetAlert>

                                    }
                                </tbody>
                            </table>
                        </div>


                        <div>

                              {
                                                            getIsAutoExfactory   &&

                                                            <Modal show={getIsAutoExfactory} size="lg" backdrop="static" keyboard={false} onHide={() => handleIsAutoExfactory(0)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>
                                                                      Assiging Auto date calcaultion condition
                                                                        {/* Task Name : {inputField.strTaskName} */}
                                                                        {/* Dependency - {index + 1} {inputField.strTaskName} */}
                                                                        {/* Dependency (Selected Task - {inputField.strTaskName}, Row no - {index + 1}) */}
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body className="dependency-overflow">
                                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "10px" }}>#</th>
                                                                                <th style={{ width: "300px" }}>Dependency Details</th>
                                                                                <th style={{ width: "30px" }}>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                getDependencyList.map((ChildField, childIndex) => (
                                                                                    <Fragment key={
                                                                                        `${ChildField}~${childIndex}`
                                                                                    }>
                                                                                        <tr>
                                                                                            <td>{childIndex + 1}</td>
                                                                                             <td>

                                                                                               {ChildField.label} + Difference days between Previous and next PO Delivery dates
                                                                                                {/*<span className='input-icon icon-right'>
                                                                                                     <Reactselect className="basic-single" name="DependencyID"
                                                                                                        id={
                                                                                                            "DependencyID" + index
                                                                                                        }
                                                                                                        isLoading={false}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "DependencyID")}
                                                                                                        value={getDependencyList.filter(function (option) {
                                                                                                            return option.value === ChildField.DependencyID;
                                                                                                        })}
                                                                                                        options={getDependencyList}
                                                                                                        isDisabled={childIndex === index ? true :
                                                                                                            ChildField.TNATaskName === '' ? true
                                                                                                                : childIndex >= index ? true : false}
                                                                                                    ></Reactselect> 
                                                                                                </span>*/}
                                                                                            </td>
                                                                                            <td>

                                                                                            <label>
                                                                                        <input type="checkbox"
                                                                                            name="AutoExfactory"
                                                                                            id={"AutoExfactory_" + childIndex}
                                                                                            isMulti
                                                                                            onChange={event => handleChange(event, childIndex, "AutoExfactory")}
                                                                                            checked={ChildField.IsAutoExfactory === 1 ? true : false}
                                                                                            className="colored-blue clearOpacity" />
                                                                                        <span className="text"></span>
                                                                                    </label>
                                                                                                {/* <span className='input-icon icon-right'>
                                                                                                    <input type="text"
                                                                                                        placeholder="    Log days"
                                                                                                        id={"LogDays" + index}
                                                                                                        name="LogDays"
                                                                                                        value={ChildField.LogDays}
                                                                                                        onChange={event => handleChildChange(event, index, childIndex, "LogDays")}
                                                                                                        maxLength="3"
                                                                                                        className={'form-control' + (
                                                                                                            submitted && !inputField.LogDays ? ' is-invalid' : ''
                                                                                                        )}
                                                                                                        disabled={ChildField.DependencyID === 0 ? true : false}
                                                                                                    />
                                                                                                </span> */}
                                                                                            </td>
                                                                                        </tr>
                                                                                       
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                        
                                                                    </table>
                                                                   

<span>
Note : Above selected calculation will not apply for the First PO Delivery dates PO lines, because route dates will be placed on the First PO Delivery dates related PO Lines.
</span>

                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <Button variant="success" onClick={() => handleIsAutoExfactory(0)}>
                                                                        Save
                                                                    </Button>
                                                                   

                                                                </Modal.Footer>
                                                            </Modal>
                                                        }
                        </div>
                        
                    </form>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                            <span className="btn btn-primary"
                                onClick={
                                    () => ResetOperation("Back")
                                }
                            >
                                <i className="fa fa-arrow-circle-left"></i>
                                &nbsp;Back</span>
                            <span className="btn btn-danger"
                                onClick={
                                    () => ResetOperation()
                                }
                            >
                                <i className="fa fa-times"></i>
                                &nbsp;Reset</span>
                            <button type="button" disabled={buttonLoader} className="btn btn-success"
                                onClick={SaveTNA}>
                                <i className="fa fa-check right"></i>
                                &nbsp;Map</button>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </Fragment>
    );

}
export default React.memo(TNARoute);


