import axios from "axios";
import authHeader from "../auth-header";

class styleService {

    // LoadBuyer() {
    //     return axios.get(window.$APIBaseURL + "Style/LoadBuyerList", {
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });
    // }

    LoadBuyer(Operation) {
        return axios.get(window.$APIBaseURL + "Master/LoadBuyerList?Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadTaskWiseBuyer(Operation, IsProduction) {
        return axios.get(window.$APIBaseURL + "Master/LoadTaskWiseBuyerList?Operation=" + Operation + "&IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSeason(Operation, BuyerID) {
        return axios.get(window.$APIBaseURL + "Style/LoadSeasonList?Operation=" + Operation + "&BuyerID=" + BuyerID, {
            headers: {
                authorization: authHeader()

            }
        });
    }

    LoadBrandThemeStoryEndCustomer(Operation, BuyerID) {
        // Operation=1 -> Barnd
        // Operation=2-> EndCustomer
        // Operation=3 -> Story
        // Opertion=4 -> Theme
        // Opertion=5 -> All Barnd,EndCustomer,Story,Theme Against BuyerID
        // Opertion=6 -> All Barnd,EndCustomer,Story,Theme
        // Opertion=7 -> All Barnd With BuyerName
        return axios.get(window.$APIBaseURL + "Style/LoadBrandThemeStoryEndCustomerList?Operation=" + Operation + "&BuyerID=" + BuyerID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    //    LoadGroupType() {
    //         return axios.get(window.$APIBaseURL + "Style/LoadGroupTypeList", {
    //             headers: {
    //                 authorization: authHeader()
    //             }
    //         });
    //     }

    LoadGroupType() {
        return axios.get(window.$APIBaseURL + "Master/LoadGroupTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadCategory(GroupID) {
        return axios.get(window.$APIBaseURL + "Master/LoadCategoryList?GroupTypeID=" + GroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadStyleList() {
        return axios.get(window.$APIBaseURL + "Style/LoadStyleList", {
            headers: {
                authorization: authHeader()
            }
        });
    }




    LoadGender() {
        return axios.get(window.$APIBaseURL + "Style/LoadGenderSizeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSize() {
        return axios.get(window.$APIBaseURL + "Style/LoadSizeDetailsList", {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadBuyerEmployee(BuyerID,Operation) {
        //return axios.get(window.$APIBaseURL + "Master/LoadCategoryList?GroupTypeID="+GroupID, {
        return axios.get(window.$APIBaseURL + "Style/LoadEmployeeList?BuyerID=" + BuyerID + "&Operation=" + Operation, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    // LoadCompanyEmployee() {
    //     return axios.get(window.$APIBaseURL + "Style/LoadEmployeeList", {
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });
    // }

    LoadFabricDetails(GroupID) {
        return axios.get(window.$APIBaseURL + "Style/LoadFabricDetailsList?GroupID=" + GroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadFabricYarnDetailsList(GroupID) {
        return axios.get(window.$APIBaseURL + "Style/LoadFabricYarnDetailsList?GroupID=" + GroupID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleList(StyleID, IsProduction, employeeinformationID, PoStartDate, PoEndDate) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleList?StyleID=" + StyleID + "&IsProduction=" + IsProduction + "&EmployeeinformationID=" + employeeinformationID + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetStyleInspectionList(StyleID, IsProduction, TNAInspectionID,Operation) {
        debugger
        return axios.get(window.$APIBaseURL + "Style/GetStyleInspectionlist?StyleID=" + StyleID + "&IsProduction=" + IsProduction + "&TNAInspectionID=" + TNAInspectionID + "&Operation=" + Operation,{
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetStyleCopy(StyleID, IsProduction, employeeinformationID, PoStartDate, PoEndDate) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleCopy?StyleID=" + StyleID + "&IsProduction=" + IsProduction + "&EmployeeinformationID=" + employeeinformationID + "&PoStartDate=" + PoStartDate + "&PoEndDate=" + PoEndDate, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetStyleCreatedByList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleCreatedByList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    GetStyleFilterList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleFilterList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdateStyle(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateStyle',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdateCopyStyleList(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateCopyStyleList',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    InsertUpdateCopyStyle(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Style/InsertUpdateCopyStyle',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }

    // InsertUpdateFabric(Params) {
    //     return axios({
    //         method: 'post',
    //         url: window.$APIBaseURL + 'Master/InsertUpdateFabric',
    //         data: Params,
    //         headers: {
    //             authorization: authHeader()
    //         }
    //     });
    // }

    GetRemarksInfo(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleRemarkDetailsInfoList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetStyleRemarksFileUpload(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleRemarksFileUpload?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetFabricInfo(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleFabricDetailsInfoList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadMainImageList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadMainImageList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSubImgList(SkuID) {
        return axios.get(window.$APIBaseURL + "Style/LoadSubImgList?SkuID=" + SkuID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSamplereqList(Operation, StyleID, StyleSampleRequestID) {
        return axios.get(window.$APIBaseURL + "Style/GetStyleSampleRequestList?Operation=" + Operation + "&StyleID=" + StyleID + "&StyleSampleRequestID=" + StyleSampleRequestID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetSizeqtyListStylerequest(Operation, StyleID, StyleSampleRequestID) {
        return axios.get(window.$APIBaseURL + "Style/GetSizeqtyListStylerequest?Operation=" + Operation + "&StyleID=" + StyleID + "&StyleSampleRequestID=" + StyleSampleRequestID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

    LoadSampleRequestList(IsProduction) {
        return axios.get(window.$APIBaseURL + "Style/LoadSampleRequestList?IsProduction=" + IsProduction, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadProcessType() {
        return axios.get(window.$APIBaseURL + "Master/LoadProcessTypeList", {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadWashCareList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadWashCareList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }
    LoadBarcodesList(StyleID) {
        return axios.get(window.$APIBaseURL + "Style/LoadBarcodesList?StyleID=" + StyleID, {
            headers: {
                authorization: authHeader()
            }
        });
    }

}
export default new styleService();