import {
    PRODUCTADDITIONALINFO_LOADING,
    RETRIEVE_PRODUCTADDITIONALINFO,
    DELETE_PRODUCTADDITIONALINFO,
} from "./types";

import ProductAdditionalInfoService from "../services/Master/ProductAdditionalInfoService";

const ProductAdditionalInfoLoading = (isStatus) => ({
    type: PRODUCTADDITIONALINFO_LOADING,
    payload: isStatus,
});

// Action to retrieve the product additional info
export const retrieveProductAdditionalInfo = () => async (dispatch) => {
    try {
        dispatch(ProductAdditionalInfoLoading(true));
        const res = await ProductAdditionalInfoService.GetProductAdditionalInfo();
        dispatch({
            type: RETRIEVE_PRODUCTADDITIONALINFO,
            payload: res.data,
        });
        dispatch(ProductAdditionalInfoLoading(false));
    } catch (err) {
        console.error("Error fetching Product Additional Info:", err);
        dispatch(ProductAdditionalInfoLoading(false));
    }
};

// Action to delete a specific product additional info by ID
export const deleteProductAdditionalInfo = (infoID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PRODUCTADDITIONALINFO,
            payload: { infoID },
        });
    } catch (err) {
        console.error("Error deleting Product Additional Info:", err);
    }
};
