import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import { DateRangePicker } from 'react-date-range';
import Reactselect from 'react-select';
import moment from "moment";
import Nodify from "../../../Common/ReactNotification";
import { useDispatch, useSelector } from "react-redux";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { Modal, Button } from "react-bootstrap";
import { Pagination, PageCount } from "../../../Datatable";
import TableHeader from '../../../Datatable/Header/commetstyle';
import QAService from "../../../../services/InspectionQC/InspectionQCService";
import { LoaQAInspectionList } from "../../../../actions/inspectionQC";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { QAApproval, QAApprovalComment, QAInspectionId } from "../../../Common/PageActionNumber";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetSubChildMultExFactoryFormSKUList } from "../../../../actions/tna";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import TNAService from "../../../../services/TNA/TNAService";
const InternalQAAuditInspectionEntry = ({ props, Taskcallback }) => {
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getAddQCInspectionListParam, setQCAddInspectionListParam] = useState([]);
    const [getFIRemark, setFIRemark] = useState({ ReceivedDate: '', Comments: '', Reportnodate: '' })
    const [getFIRemarks, setFIRemarks] = useState([])
    const [getQARemark, setQARemark] = useState({ ReceivedDate: '', ApprovalResult: 0, Comments: '', Inspectionnodate: '' })
    const [getQARemarks, setQARemarks] = useState([])
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getSkuSearch, setSkuSearch] = useState("");
    const [getFactorySearch, setFactorySearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getQuartersearch, setQuarterSearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getSuppliersearch, setsupplierSearch] = useState("");
    const [getEndCustomersearch, setEndCustomerSearch] = useState("");
    const [getreportnosearch, setreportnoSearch] = useState("");
    const [getCategorysearch, setCategorySearch] = useState("");
    const [getStyleNamesearch, setStyleNameSearch] = useState("");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [getStyleNosearch, setStyleNoSearch] = useState("");
    const [getBuyerEmpsearch, setBuyerEmpSearch] = useState("");
    const [getCubeEmpsearch, setCubeEmpSearch] = useState("");
    const [getOrderStatussearch, setOrderStatusSearch] = useState("");
    const [getIsProductionStatussearch, setIsProductionStatussearch] = useState("");
    const [inspectionstatus, setinspectionstatus] = useState();
    const [inspection, setinspection] = useState();
    const [getQCInspectionInfo, setQCInspectionInfo] = useState({
        buyerName: ''
        , buyerID: 0
        , brandName: ''
        , brandID: 0
        , seasonName: ''
        , seasonID: 0
        , type: 0,
        TaskStatus: 0
    });
    const [getIDcomment, setIDcomment] = useState({ showPopupDelete: false, Params: {} });
    const [getIDcommentNew, setIDcommentNew] = useState({ showPopupDelete: false, Params: {} });
    const [Submitted, setSubmitted] = useState(false);
    //const [showmodal, setShowmodal] = useState({ EnableComments: 0, PurchaseOrderSkuID: 0, reportStatus: [] });
    const [Commentpopup, setCommentpopup] = useState(false);
    const [CommentpopupNew, setCommentpopupNew] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getIDQATask, setIDQATask] = useState({ showPopupDelete: false, Params: {} });
    const [Settingpopupopen, setSettingpopupopen] = useState(false)
    const [getinpsectionID, setinpsectionID] = useState()
    const [settings, setSettings] = useState([
        { isSelected: true, Name: 'Basic Information' },
        { isSelected: true, Name: 'Photos' },
        { isSelected: true, Name: 'Basic Information with measurement' },
    ]);
    const [show, setShow] = useState(false);
    const [sizeqtypopup, setsizeqtypopup] = useState(false);
    const [checkboxStates, setCheckboxStates] = useState({
        CreatedDate: true,
        EntryNo: true,
        ReportType: true,
        ReInspectionDoneAgainst: true,
        Brand: true,
        Season: true,
        Supplier: true,
        Factory: true,
        CustomerShipmentRefNo: true,
        InspectionType: true,
        IDNo: true,
        skuImg: true,
        FOIDPONo: true,
        StyleName: true,
        StyleNo: true,
        SKUName: true,
        InspectionQuantity: true,
        orderQty: true,
        InspectionResult: true,
        CreatedBy: true,
        ApprovalResult: true,
    });
    var defaultStartDate = new Date();
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);
    var defaultApStartDate = new Date();
    const [stateApDate, setStateApDate] = useState([
        {

            startDate: null, //defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
            endDate: new Date(''),
            key: 'selection',
            isShow: false
        }
    ]);
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = useSelector((state) => state.auth);

    const QAInspectionList = reducerState.inspectionQC.QAInspectionList !== undefined ? reducerState.inspectionQC.QAInspectionList : reducerState.inspectionQC.QAInspectionList;
    const isLoadingQADetailsReport = reducerState.inspectionQC.isLoadingQADetailsReport;


    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProductionLocal = JSON.parse(localStorage.getItem("IsProduction"));
    let IsProduction = activeMenus.IsProduction === 0 ? IsProductionLocal : activeMenus.IsProduction;
    // End

    // //Enable Menu
    // let activeMenu;
    // const menulist = useSelector((state) => state.common.MenuList.item3);
    // if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === 'InternalQAAudit'); }
    // // End Enable Menu

    //Enable Menu
    let activeMenu;
    let activeMenuSub;
    let InternalComments;
    let info;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    const menulistSub = useSelector((state) => state.auth.UserMenuList.item5);
    if (menulist !== undefined) {
        info = IsProduction === 1 ? 'InternalQAAudit' : (IsProduction === 2 ? 'InternalQAAudit' : undefined);
        activeMenu = menulist.filter(i => i.menuName === info);

        if (activeMenu.length > 0) {
            activeMenuSub = menulistSub.filter(i => i.mainMenuID === activeMenu[0].menuID);
            if (activeMenuSub.length > 0) {
                for (let index = 0; index < activeMenuSub.length; index++) {
                    if (activeMenuSub[index].menuName === 'InternalEntry') { InternalComments = activeMenuSub[index]; }

                }
            }
        }

    }
    // End Enable Menu


    QAInspectionList.map((x) => {
        if (x.quarterName === null || x.quarterName === undefined) {
            x.quarterName = ''
        }
    })

    useEffect(() => {
        let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(Defaultvalue);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction, Taskname: Taskcallback,
            PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate,
            ApEndDate: formattedDate, ApprovedStatus: 0
        }

        dispatch(LoaQAInspectionList(params));
    }, [dispatch]);
    // loader
    useEffect(() => {

        if (!isLoadingQADetailsReport) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingQADetailsReport]);

    let InspectionandApproval = [
        {
            value: 1,
            label: 'Report Status - Progressing (It means Draft)',
            selected: false,

        },
        {
            value: 2,
            label: 'Report Status - Completed (saved )',
            selected: false,

        },
        {
            value: 3,
            label: 'Report Under Review',
            selected: false,

        },
        {
            value: 4,
            label: 'Report Review Completed',
            selected: false,

        },
        {
            value: 5,
            label: 'Report Created By',
            selected: false,

        },
        , {
            value: 6,
            label: 'Report Reviewed By',
            selected: false,

        }, {
            value: 7,
            label: 'Report Saved, but approval pending',
            selected: false,

        }, {
            value: 8,
            label: 'Report Approval Result is Approved',
            selected: false,

        }
    ]
    let Size = '120px';
    const tableHeader = [
        { name: "#", field: "", sortable: false, widthsize: '2%', left: 0 },
        checkboxStates.CreatedDate && ({ name: "Created Date", field: "insCreatedDate", sortable: true, widthsize: Size, left: 0 }),
        checkboxStates.EntryNo && ({ name: "Entry No", field: "qcInspectionID", sortable: true, widthsize: Size, left: "120px", }),
        // checkboxStates.ReportType && ({//     name: "Report Type",//     field: "reportType",//     sortable: true,//     widthsize: "5%",//     left: "240px",// })
        // , checkboxStates.ReInspectionDoneAgainst && ({//     name: "Re Inspection Done Against",//     field: "",//     sortable: true,//     width: "10%",//     widthsize: "6%",//     //position: "sticky",//     left: "360px",//     //background: "#222d32",// })
        checkboxStates.Supplier && ({ name: "Supplier", field: "supplierName", sortable: true, widthsize: Size }),
        checkboxStates.StyleName && ({ name: "Style Name", field: "styleName", sortable: true, widthsize: Size }),
        checkboxStates.SKUName && ({ name: "SKU Name", field: "skuName", sortable: true, widthsize: Size }),
        checkboxStates.IDNo && ({ name: "PO/ID No", field: "idpono", sortable: true, widthsize: Size }),
        checkboxStates.orderQty && ({ name: "Order Qty", field: "orderQty", sortable: true, widthsize: Size }),
        checkboxStates.InspectionQuantity && ({ name: "Report Qty", field: "inspectionQty", sortable: true, widthsize: Size })
        , checkboxStates.InspectionResult && ({ name: "Report Status", field: "inspectionResult", sortable: true, widthsize: Size, })
        //, checkboxStates.Factory && ({name: "Factory",field: "factory",sortable: true, widthsize: "4%", left: "858px",})
        // , checkboxStates.IDNo && ({ //     name: "FO PO No", //     field: "foidpono", //     sortable: true, //     widthsize: "20px", //     //width: "4%", //
        // , checkboxStates.CreatedBy && ({//     name: "Created By",//     field: "createdBy",//     sortable: true,//     widthsize: "30px",//     // width: "2%",//
        // , checkboxStates.ApprovalResult && ({//     name: "Inspection Status",//     field: "approvalResult",//     sortable: true,//     widthsize: "120px",//     // width: "2%",// })
        , checkboxStates.ApprovalResult && ({ name: "Review Status", field: "reviewStatus", sortable: true, widthsize: Size })
        , {
            name: "Action", field: "Action", className: "text-center ActionWidth fixed-column-header-Route", sortable: false, widthsize: Size, display: InternalComments && InternalComments.isDelete === 0 && InternalComments.isView === 0 && InternalComments.isEdit === 0 && InternalComments.isAdd === 0 ? 'none' : ''
        }
    ];

    const tableHeaderPilot = [
        { name: "#", field: "", sortable: false, widthsize: '2%', left: 0 },
        checkboxStates.CreatedDate && ({ name: "Created Date", field: "insCreatedDate", sortable: true, widthsize: Size, left: 0 }),
        checkboxStates.EntryNo && ({ name: "Entry No", field: "qcInspectionID", sortable: true, widthsize: Size, left: "120px", }),
        // checkboxStates.ReportType && ({//     name: "Report Type",//     field: "reportType",//     sortable: true,//     widthsize: "5%",//     left: "240px",// })
        // , checkboxStates.ReInspectionDoneAgainst && ({//     name: "Re Inspection Done Against",//     field: "",//     sortable: true,//     width: "10%",//     widthsize: "6%",//     //position: "sticky",//     left: "360px",//     //background: "#222d32",// })
        checkboxStates.Supplier && ({ name: "Supplier", field: "supplierName", sortable: true, widthsize: Size }),
        checkboxStates.StyleName && ({ name: "Style Name", field: "styleName", sortable: true, widthsize: Size }),
        checkboxStates.SKUName && ({ name: "SKU Name", field: "skuName", sortable: true, widthsize: Size }),
        checkboxStates.IDNo && ({ name: "PO/ID No", field: "idpono", sortable: true, widthsize: Size }),
        checkboxStates.skuImg && ({ name: "SKU Image", field: "skuImg", sortable: true, widthsize: Size }),
        checkboxStates.orderQty && ({ name: "Order Qty", field: "orderQty", sortable: true, widthsize: Size }),
        checkboxStates.InspectionQuantity && ({ name: "Report Qty", field: "inspectionQty", sortable: true, widthsize: Size })
        , checkboxStates.InspectionResult && ({ name: "Report Status", field: "inspectionResult", sortable: true, widthsize: Size, })
        //, checkboxStates.Factory && ({name: "Factory",field: "factory",sortable: true, widthsize: "4%", left: "858px",})
        // , checkboxStates.IDNo && ({ //     name: "FO PO No", //     field: "foidpono", //     sortable: true, //     widthsize: "20px", //     //width: "4%", //
        // , checkboxStates.CreatedBy && ({//     name: "Created By",//     field: "createdBy",//     sortable: true,//     widthsize: "30px",//     // width: "2%",//
        , checkboxStates.ApprovalResult && ({ name: "Approval Status", field: "approvalResult", sortable: true, widthsize: Size })
        //, checkboxStates.InspectionResult && ({     name: "Re Ins Status",     field: "approvalResult",     sortable: true,     widthsize: Size})
        // , checkboxStates.ApprovalResult && ({ name: "Review Result", field: "reviewStatus", sortable: true, widthsize: Size})
        , {
            name: "Action", field: "Action", className: "text-center ActionWidth fixed-column-header-Route", sortable: false, widthsize: Size, display: InternalComments && InternalComments.isDelete === 0 && InternalComments.isView === 0 && InternalComments.isEdit === 0 && InternalComments.isAdd === 0 ? 'none' : ''
        }
    ];

    const datePickerreport = useRef(null);
    const datePickerRefApp = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerreport.current && !datePickerreport.current.contains(event.target)) {
                setStateDate(prev => [{ ...prev[0], isShow: false }]);
            }
            if (datePickerRefApp.current && !datePickerRefApp.current.contains(event.target)) {
                setStateApDate(prev => [{ ...prev[0], isShow: false }]);
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleclick = () => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);

    }
    const handleDateSelect = (event, isShow) => {
        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateDate[0].isShow = isShow;
        getStateApDate[0].isShow = false;
        setStateApDate(getStateApDate);
        setStateDate(getStateDate);
    }
    const handleExDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        let getStateApDate = [...stateApDate];
        getStateApDate[0].isShow = isShow;
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);
        setStateApDate(getStateApDate);
    }
    const handleChangeOrdStatus = (value) => {
        let getStateDate = [...stateDate];
        let getStateExDate = [...stateApDate];
        getStateExDate[0].isShow = false;
        getStateDate[0].isShow = false;
        setStateApDate(getStateExDate);
        setStateDate(getStateDate);
        setOrderStatusSearch(value);
        //setIsProductionStatussearch(value);
    }
    function DateRangechange(item) {
        stateApDate[0].startDate = null;
        stateApDate[0].endDate = new Date('');
        setStateApDate(stateApDate)
        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(stateDate)

        const updatedStateDate = [
            {
                ...stateDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateDate(updatedStateDate);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');

        let params = {
            IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate,
            PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
        }
        dispatch(LoaQAInspectionList(params));
    }
    const clearExDateSelect = () => {
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(NewemptyStateExDate[0].startDate).isValid()) {
            StartApDate = moment(NewemptyStateExDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(NewemptyStateExDate[0].endDate).isValid()) {
            EndApDate = moment(NewemptyStateExDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }
        // dispatch(LoaQAInspectionList(params));
    }
    function DateExfaconchange(item) {
        stateDate[0].startDate = null;
        stateDate[0].endDate = new Date('');
        setStateDate(stateDate)
        stateApDate[0].startDate = item[0].startDate;
        stateApDate[0].endDate = item[0].endDate
        setStateApDate(stateApDate)
        const updatedStateDate = [
            {
                ...stateApDate[0],
                startDate: item[0].startDate,
                endDate: item[0].endDate
            }
        ];

        setStateApDate(updatedStateDate);
        let StartDate = null;
        let EndDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: formattedDate,
            ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0
        }
        dispatch(LoaQAInspectionList(params));
    }
    const resetFinalIns = () => {
        setBuyerSearch('');
        setSkuSearch('');
        setFactorySearch('');
        setQuarterSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setsupplierSearch('');
        setIdPoSearch('');
        setsupplierSearch('');
        setEndCustomerSearch('');
        setCategorySearch('');
        setStyleNameSearch('');
        setStyleNoSearch('');
        setBuyerEmpSearch('');
        setCubeEmpSearch('');
        setOrderStatusSearch('');
        setreportnoSearch('')
        setIsProductionStatussearch('');

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const emptyStateExDate = ([
            {

                startDate: null,// defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(''),
                key: 'selection',
                isShow: false
            }
        ]);

        var defaultInspectionStartDate = new Date();
        const emptyStateInsDate = ([
            {

                startDate: defaultInspectionStartDate.setDate(defaultInspectionStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        var defaultTNAStartDate = new Date();
        const emptyStateTNADate = ([
            {

                startDate: defaultTNAStartDate.setDate(defaultTNAStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        setStateDate(emptyStateDate);
        setStateApDate(emptyStateExDate);

        let value = { label: "Sampling", selected: true, value: 1 }
        setIsProductionStatussearch(value);
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let StartApDate = null;
        let EndApDate = null;
        var defaultAppStartDate = new Date();
        defaultAppStartDate.setDate(defaultAppStartDate.getDate() - 90)
        if (moment(defaultAppStartDate).isValid()) {
            StartApDate = moment(defaultAppStartDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }

        const currentDate = new Date();
        const formattedDate = moment(currentDate).format('MM/DD/YYYY');
        let params = {
            IsProduction: IsProduction, Taskname: Taskcallback,
            PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
        }

        dispatch(LoaQAInspectionList(params));
        //EmptyCheck();
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
   
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    const commentsData = useMemo(() => {
       // let computedComments = QAInspectionList;

       

        let computedComments = QAInspectionList.filter(o => 
                   BuyerBrandDetails.some(({  brandId, isAllInfo, isMapped }) =>
                     o.brandID === brandId && isAllInfo === 1 && isMapped === 0
                   )
                 );

        let drpvalue = []//orderStatus;
        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSkuSearch) {
            computedComments = computedComments.filter(comment =>
                comment.skuName.toLowerCase().includes(getSkuSearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }

        if (getFactorySearch) {
            computedComments = computedComments.filter(comment =>
                comment.factory.toLowerCase().includes(getFactorySearch.toLowerCase()) // Need to be Change
            )
            setTotalItems(computedComments.length);
        }

        if (getStyleNamesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStyleNamesearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getStyleNosearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleNo.toLowerCase().includes(getStyleNosearch.toLowerCase())
            )
            setTotalItems(computedComments.length);
        }
        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getQuartersearch) {
            computedComments = computedComments.filter(comment =>
                comment.quarterName.toLowerCase().includes(getQuartersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getSuppliersearch) {
            computedComments = computedComments.filter(comment =>
                comment.supplierName.toLowerCase().includes(getSuppliersearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }

        if (getEndCustomersearch) {
            computedComments = computedComments.filter(comment => {
                comment.customerName == null ? comment.customerName = "" : comment.customerName = comment.customerName;
                comment.customerName.toLowerCase().includes(getEndCustomersearch.toLowerCase());
            }
            );
            setTotalItems(computedComments.length);
        }
        if (getCategorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.categoryName.toLowerCase().includes(getCategorysearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        if (getreportnosearch) {
            computedComments = computedComments.filter(comment =>
                comment.qcInspectionID.toString().includes(getreportnosearch)
            );
            setTotalItems(computedComments.length);
        }
        // if (getBuyerEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.buyerEmployee.toLowerCase().includes(getBuyerEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }
        // if (getCubeEmpsearch) {
        //     computedComments = computedComments.filter(comment =>
        //         comment.cubeEmployee.toLowerCase().includes(getCubeEmpsearch.toLowerCase())
        //     );
        //     setTotalItems(computedComments.length);
        // }

        if (getOrderStatussearch) {
            if (getOrderStatussearch.value === 1) { //draft
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionResult === "Draft";
                })
            }
            else if (getOrderStatussearch.value === 2) { //saved
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionResult === "Saved";
                })
            }
            else if (getOrderStatussearch.value === 3) { //report under review
                computedComments = computedComments.filter((comment) => {
                    return comment.reviewStatus == null || comment.reviewStatus == "";
                })
            }
            else if (getOrderStatussearch.value === 4) { //report under review
                computedComments = computedComments.filter((comment) => {
                    return comment.reviewStatus != null && comment.reviewStatus != "";
                })
            }
            else if (getOrderStatussearch.value === 7) { //report saved but approval pending
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionResult === "Saved" && (comment.approvalResult == "" || comment.approvalResult == null);
                })
            }
            else if (getOrderStatussearch.value === 8) { //report approval result is approved
                computedComments = computedComments.filter((comment) => {
                    return comment.inspectionResult === "Saved" && (comment.approvalResult != "" && comment.approvalResult != null);
                })
            }
            setTotalItems(drpvalue && drpvalue.length);
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );



            setTotalItems(computedComments.length);
        }

        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.foidpono.toLowerCase().includes(getIdPosearch.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }


        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }


        if (stateDate[0].startDate && stateDate[0].endDate) {
            computedComments = computedComments.filter(comment =>
                new Date(moment(comment.insCreatedDate, format)) >= stateDate[0].startDate && new Date(moment(comment.insCreatedDate, format)) <= stateDate[0].endDate
            )
        }

        setTotalItems(computedComments.length);
        // // let Exformat = "DD/MM/YYYY";
        // // let StartExDate = null;
        // // let EndExDate = null;
        // // if (moment(stateApDate[0].startDate).isValid()) {
        // //     StartExDate = new Date(moment(stateApDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        // //     //StartDate.setMinutes(StartDate.getMinutes() + 370);
        // // }
        // // if (moment(stateApDate[0].endDate).isValid()) {
        // //     EndExDate = new Date(moment(stateApDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        // //     //EndDate.setMinutes(EndDate.getMinutes() + 370);
        // // }

        // // if (stateApDate[0].startDate && stateApDate[0].endDate) {
        // //     computedComments = computedComments.filter(comment =>
        // //         new Date(moment(comment.exFacDate, Exformat)) >= stateApDate[0].startDate && new Date(moment(comment.exFacDate, Exformat)) <= stateApDate[0].endDate
        // //     )
        // // }
        // // setTotalItems(computedComments.length);
        //sorting table
        if (sorting.field === "typeInterOrExtern") {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field] - (b[sorting.field])));
        }
        else if (sorting.field) {
            computedComments.map((x) => {
                x.qcInspectionID = x.qcInspectionID.toString();

            })
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * (a[sorting.field].localeCompare(b[sorting.field])));

        }



        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }

        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            QAInspectionList,
            currentPage,
            // search,
            getBuyersearch,
            getSkuSearch,
            getFactorySearch,
            getSeasonsearch,
            getBrandsearch,
            getQuartersearch,
            getreportnosearch,
            getIdPosearch,
            getSuppliersearch,
            getEndCustomersearch,
            getCategorysearch,
            getStyleNamesearch,
            getStyleNosearch,
            getBuyerEmpsearch,
            getCubeEmpsearch,
            getOrderStatussearch,
            stateDate,
            stateApDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates({
            ...checkboxStates,
            [name]: checked
        });
    };

    async function handleFinalInspecView(item, Action) {

        let params = {
            Operation: 2,
            BuyerId: item.buyerID, BrandId: item.brandID, SeasonId: item.seasonID,
            SupplierId: item.supplierID, TaskId: item.taskDetailsID, StyleId: item.styleID,
            SkuId: 0, TaskOwnerIdList: item.taskOwner, TaskHolderId: 0,
            tnaExFactoryId: 0, status: 1, selectedDependency: '', NewID: 0,
            Isproduction: IsProduction, TnaId: item.tnaid,
        };
        setQCAddInspectionListParam(params)
        dispatch(GetSubChildMultExFactoryFormSKUList(params));


        let sizeandqtyfordisplay = "";
        let NewArr = [];
        const TNAAddFinalInspectionList = reducerState.tna.tnaAddfinalInspection !== undefined ? reducerState.tna.tnaAddfinalInspection : [];
        NewArr = TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID))
        NewArr.map((Purchaseorder) => {
            if (sizeandqtyfordisplay === '') {
                sizeandqtyfordisplay = Purchaseorder.purchaseOrderSkuID;
            } else {
                sizeandqtyfordisplay = sizeandqtyfordisplay + ',' + Purchaseorder.purchaseOrderSkuID
            }
        })
        TNAAddFinalInspectionList.filter(x => x.skuID === parseInt(item.skuID)).map((x) => {
            x.isSelected = 1;
        })
        getQCInspectionInfo.buyerName = item.buyerName;
        getQCInspectionInfo.buyerID = item.buyerID;
        getQCInspectionInfo.brandID = item.brandID;
        getQCInspectionInfo.brandName = item.brandName;
        getQCInspectionInfo.seasonID = item.seasonID;
        getQCInspectionInfo.seasonName = item.seasonName;
        getQCInspectionInfo.supplierId = item.supplierID;
        getQCInspectionInfo.supplierName = item.supplierName;
        getQCInspectionInfo.styleID = item.styleID;
        getQCInspectionInfo.styleName = item.styleName;
        getQCInspectionInfo.styleNo = item.styleNo
        getQCInspectionInfo.TNAQCInspectionID = item.qcInspectionID;
        getQCInspectionInfo.multiselecteddatas = TNAAddFinalInspectionList;
        getQCInspectionInfo.quantityinfo = getAddQCInspectionListParam// For Binding Selected checkbox values in quantity inforamtion
        getQCInspectionInfo.details = { tnaid: item.tnaid, supplierId: item.supplierID, purchaseOrderID: item.purchaseOrderID, idPoNos: item.idpono };
        getQCInspectionInfo.TnaID = item.tnaid;
        getQCInspectionInfo.PurchaseOrderSkuID = sizeandqtyfordisplay;//For Multiple POSKUID like(4123,4122) for Packing and PackingImage
        getQCInspectionInfo.Isproduction = IsProduction;
        getQCInspectionInfo.Action = Action;
        getQCInspectionInfo.TaskType = Taskcallback;
        getQCInspectionInfo.TaskStatus = 2;
        getQCInspectionInfo.IsAddorEditReinspection = item.reportType === 'Re Inspection' ? Action + ' ReInspection' : Action + ' Inspection';
        getQCInspectionInfo.Mode = Action === 'View' ? "ViewInspection" : 'EditInspection';
        getQCInspectionInfo.ParentTask = "QA Internal Audit";
        getQCInspectionInfo.PageName = "Inspection Entry"
        props.history.push(`/AddQCInspection`, { params: getQCInspectionInfo })
    }

    const handlepdffunction = (comment) => {
        setinpsectionID(comment.qcInspectionID)
        setSettingpopupopen(true);
    }
    function handleDeleteQAinspection(item) {
        let PackingParams = {
            Operation: 110,
            Id: item.qcInspectionID,
        }
        setIDQATask({ showPopupDelete: true, Params: PackingParams });
    }
    function delApprovalCallback1(value) {
        if (value === true) {
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            const today = new Date();
            const startDate = new Date(today);
            startDate.setDate(today.getDate() - 90);
            if (moment(startDate).isValid()) {
                StartApDate = moment(startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction, Taskname: Taskcallback,
                PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
            }

            dispatch(LoaQAInspectionList(params));
            setCommentpopupNew(false)
        } else {
            setIDcommentNew({ showPopupDelete: false, Params: [] });
        }
    }
    function delApprovalCallback(value) {
        if (value === true) {
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            const today = new Date();
            const startDate = new Date(today);
            startDate.setDate(today.getDate() - 90);
            if (moment(startDate).isValid()) {
                StartApDate = moment(startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');
            let params = {
                IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate,
                PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
            }

            dispatch(LoaQAInspectionList(params));
            setCommentpopup(false)
        } else {
            setIDcomment({ showPopupDelete: false, Params: [] });
        }
    }

    function delQaCallback(value) {
        if (value === true) {
            let Defaultvalue = { label: "Sampling", selected: true, value: 1 }
            setIsProductionStatussearch(Defaultvalue);
            let StartDate = null;
            let EndDate = null;
            if (moment(stateDate[0].startDate).isValid()) {
                StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
            }
            if (moment(stateDate[0].endDate).isValid()) {
                EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
            }

            let StartApDate = null;
            let EndApDate = null;
            const today = new Date();
            const startDate = new Date(today);
            startDate.setDate(today.getDate() - 90);
            if (moment(startDate).isValid()) {
                StartApDate = moment(startDate).format('MM/DD/YYYY');
                //StartDate.setMinutes(StartDate.getMinutes() + 370);
            }
            if (moment(stateApDate[0].endDate).isValid()) {
                EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                //EndDate.setMinutes(EndDate.getMinutes() + 370);
            }
            const currentDate = new Date();
            const formattedDate = moment(currentDate).format('MM/DD/YYYY');
            let params = { IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0 }

            dispatch(LoaQAInspectionList(params));
        } else {
            setIDQATask({ showPopupDelete: false, Params: [] });
        }
    }

    const clearDateSelect = () => {

        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);

        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        var defaultApStartDate = new Date();
        const NewemptyStateExDate = ([
            {

                startDate: defaultApStartDate.setDate(defaultApStartDate.getDate() - 90),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }
        ]);

        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }


        let StartApDate = null;
        let EndApDate = null;
        if (moment(stateApDate[0].startDate).isValid()) {
            StartApDate = moment(stateApDate[0].startDate).format('MM/DD/YYYY');
            //StartDate.setMinutes(StartDate.getMinutes() + 370);
        }
        if (moment(stateApDate[0].endDate).isValid()) {
            EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
            //EndDate.setMinutes(EndDate.getMinutes() + 370);
        }
        let params = { IsProduction: IsProduction, PoStartDate: StartDate, PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: EndApDate, ApprovedStatus: 0 }
        //dispatch(LoaQAInspectionList(params));
    }

    function handleChange(e, filterName) {
        let inputText = '';
        if (filterName === 'Buyer' && filterName !== '') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
        }
        else if (filterName === 'StyleName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleNameSearch(inputText);
        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
        }
        else if (filterName === 'ID/NO') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
        }

        else if (filterName === 'Supplier') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setsupplierSearch(inputText);
        }

        else if (filterName === 'OrderStatus') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setOrderStatusSearch(inputText);


        }
        else if (filterName === 'SKUName') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSkuSearch(inputText);
        }
        else if (filterName === 'Factory') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setFactorySearch(inputText);
        }
        else if (filterName === 'Quarter') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setQuarterSearch(inputText);
        } else if (filterName === 'ReportNo') {

            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setreportnoSearch(inputText);
        }
    };

    async function GetReview(comment) {
        setFIRemark();
        const values = { ...getFIRemark };
        setinspection(comment);
        values.Reportnodate = comment.insCreatedDate + ' - ' + comment.qcInspectionID;
        await QAService.GetQAApprovalHistory(comment.qcInspectionID).then(async (response) => {
            if (response.data) {
                setFIRemarks(response.data)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });
        setFIRemark(values);
        setCommentpopup(true);
    }

    async function GetComments(comment) {
        const values = { ...getQARemark };
        values.Inspectionnodate = comment.qcInspectionID + ' - ' + comment.insCreatedDate;
        setinspectionstatus(comment)
        await QAService.GetQAApprovalList1(comment.qcInspectionID).then(async (response) => {
            if (response.data) {
                setQARemarks(response.data)
            }
            hideLoader();
        }).catch(() => { hideLoader(); });
        setQARemark(values);
        setCommentpopupNew(true)
    }

    function handleFullCloseReveiw() {
        setCommentpopup(false);
    }

    function handleDeletefinalinsPopup(comment) {
        let commentparams = {
            Operation: 123,
            Id: comment.qcInspectionID,
        }
        setIDcomment({ showPopupDelete: true, Params: commentparams });
    }

    const CustomInput = (props) => {
        return (
            <input
                className="form-control DatePickerCalenderHoliday"
                onClick={props.onClick}
                value={props.value}
                type="text"
                readOnly={true}
                placeholder="DD/MM/YYYY"
            />
        )
    }

    const handleChangeRemarks = (e, FieldName) => {
        setSubmitted(false);
        let inputText = '';
        const values = { ...getFIRemark };
        if (FieldName === "ReceivedDate") {
            if (e !== null) {
                values.ReceivedDate = e;
            }
            else {
                values.ReceivedDate = null;
            }
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.Comments = inputText;
        }
        setFIRemark(values)

    }

    function handleSaveApproval(e, comment) {
        const values = { ...getFIRemark };
        if (values.ReceivedDate === '' || values.ReceivedDate === null || values.Comments === '') {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {

            var data = {
                CreatedBy: currentUser.employeeinformationID, QCInspectionID: comment.qcInspectionID,
                QAReviewID: 0, InspectionApprovalDate: values.ReceivedDate, ReportNoanddate: values.Reportnodate,
                TaskTypeName: Taskcallback, ApprovalRemarks: values.Comments, ReviewStatus: 1
            };

            QAService.InsertUpdateApprovalReview(data).then((res) => {

                if (res.data.outputResult === "1") {
                    Nodify('Success!', 'success', "Comment Added for " + comment.qcInspectionID + " Successfully");
                    setCommentpopup(false);
                    //setFIRemarks();
                    let StartDate = null;
                    let EndDate = null;
                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                    }

                    let StartApDate = null;
                    let EndApDate = null;
                    const today = new Date();
                    const startDate = new Date(today);
                    startDate.setDate(today.getDate() - 90);
                    if (moment(startDate).isValid()) {
                        StartApDate = moment(startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    if (moment(stateApDate[0].endDate).isValid()) {
                        EndApDate = moment(stateApDate[0].endDate).format('MM/DD/YYYY');
                        //EndDate.setMinutes(EndDate.getMinutes() + 370);
                    }
                    const currentDate = new Date();
                    const formattedDate = moment(currentDate).format('MM/DD/YYYY');
                    let params = {
                        IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate,
                        PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
                    }

                    dispatch(LoaQAInspectionList(params));
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
                //setComment(Values);

            })

        }

    }
    function handleFullCloseApproval() {
        setCommentpopupNew(false)
        setQARemark();
    }
    // const generatePDF = async () => { }
    function handleDeleteQAinsPopup(comment) {
        let commentparams = {
            Operation: 124,
            Id: comment.qcInspectionID,
        }
        setIDcommentNew({ showPopupDelete: true, Params: commentparams });
    }
    const handleSettingChange = (e, index) => {
        ;
        const { name, checked } = e.target;

        setSettings((prevSettings) => {
            const updatedSettings = [...prevSettings]; // Create a shallow copy of the settings array
            updatedSettings[index] = {
                ...updatedSettings[index], // Create a copy of the specific object at the index
                [name]: checked, // Update the specific field (e.g., 'basicInfo', 'photos', etc.) with the new value
            };
            return updatedSettings; // Return the updated array
        });
    };

    const transformData = (inputData) => {

        if (inputData.length > 0) {
            // Group data by SKU name
            const groupedBySku = inputData.reduce((acc, item) => {
                if (!acc[item.skuName]) {
                    acc[item.skuName] = [];
                }
                acc[item.skuName].push(item);
                return acc;
            }, {});

            const transformedData = Object.keys(groupedBySku).map((skuName, index) => {
                const skuData = groupedBySku[skuName];
                const result = {
                    skuName,
                    result: skuData[index].result,//groupedBySku[]
                    Category: []
                };

                const categoryMap = {};

                skuData.forEach((item) => {
                    const {
                        categoryTypeName,
                        measurePointName,
                        toleranceDisplay,
                        tnaqCinsMeasurementPointsId,
                        gradingSize,
                        gradingSizeValue
                    } = item;

                    // Create or retrieve Category
                    if (!categoryMap[categoryTypeName]) {
                        categoryMap[categoryTypeName] = {
                            CategoryName: categoryTypeName,
                            Tables: [{ TableName: "Table 1", Gradings: [] }]
                        };
                        result.Category.push(categoryMap[categoryTypeName]);
                    }

                    const currentCategory = categoryMap[categoryTypeName];

                    // Determine table index based on GradingSize suffix ("-2", "-3", etc.)
                    let tableIndex = 0; // Default to Table 1
                    const suffixMatch = gradingSize.match(/-(\d)$/);
                    if (suffixMatch) {
                        tableIndex = parseInt(suffixMatch[1]) - 1;
                    }

                    // Ensure the correct table exists in the Tables array
                    if (!currentCategory.Tables[tableIndex]) {
                        currentCategory.Tables[tableIndex] = {
                            TableName: `Table ${tableIndex + 1}`,
                            Gradings: []
                        };
                    }

                    const currentTable = currentCategory.Tables[tableIndex];

                    // Find or create grading entry in the specified table
                    let grading = currentTable.Gradings.find(
                        (g) =>
                            g.measurePointName === measurePointName &&
                            g.tnaqCinsMeasurementPointsId === tnaqCinsMeasurementPointsId
                    );

                    if (!grading) {
                        grading = {
                            measurePointName,
                            Tolerance: toleranceDisplay,
                            tnaqCinsMeasurementPointsId,
                            GradingVal: {}
                        };

                        // Populate initial required keys with values if they exist
                        const initialValues = skuData.filter(
                            (data) =>
                                data.measurePointName === measurePointName &&
                                data.tnaqCinsMeasurementPointsId === tnaqCinsMeasurementPointsId &&
                                data.gradingSize.endsWith("Req")
                        );

                        initialValues.forEach((val) => {
                            grading.GradingVal[val.gradingSize] = val.gradingSizeValue;
                        });

                        currentTable.Gradings.push(grading);
                    }

                    // Add grading size-value to GradingVal
                    grading.GradingVal[gradingSize] = gradingSizeValue;
                });

                return result;
            });

            return transformedData;
        }

        return [];
    };

    const generatePDF = async () => {

        let valid = settings.filter(x => x.isSelected === true).length
        let MainList = [];
        if (valid > 0) {
            // Fetch the data from the API
            const res = await TNAService.GetInternalQAListforPDF(getinpsectionID);
            MainList.push(res.data.testingQAPDFMainList[0]);

            let TestingCommentsList = [];
            let TestingCommentsPhotoList = [];
            let TestingCommentsMeasurmentBaseList = [];
            if (res.data.testingPDFQACommentList) {
                TestingCommentsList = res.data.testingPDFQACommentList.map((x) => ({
                    Comments: x.comments,
                    ActionTaken: x.actionTaken
                }));
            }
            if (res.data.testingPDFQACommentPhotoList) {
                TestingCommentsPhotoList = res.data.testingPDFQACommentPhotoList.map((x) => ({
                    Images: x.images,
                    Comment: x.comment,
                    CommentMainIndex: x.commentMainIndex
                }));
            }
            if (res.data.testingPDFQAMeasurementBaseList) {
                TestingCommentsMeasurmentBaseList = res.data.testingPDFQAMeasurementBaseList.map((x) => ({
                    MeasurementCheckedPeices: x.measurementCheckedPeices,
                    Filepath: x.filepath,
                    CalculationType: x.calculationType,
                    Sizes: x.sizes
                }));
            }
            const transformedData = transformData(res.data.testingPDFQAMeasurementList);
            // console.log(JSON.stringify(res.data.testingPDFQAMeasurementList, null, 2));

            generatePDF1(MainList, TestingCommentsList, TestingCommentsPhotoList, res.data.testingPDFQAStylebasicList[0], res.data.testingPDFQAStylelogoList[0], transformedData, TestingCommentsMeasurmentBaseList[0]);
        }
    }

    function calculateMeasurementPoints(data, CalculationType, sizes) {
        let totalPoints = 0; // Total measurement points
        let outOfTolerancePoints = 0; // Points out of tolerance

        // Iterate through each SKU object
        data.forEach((sku) => {
            const { skuName, Category } = sku; // Destructure `skuName` and `Category`

            Category.forEach((category) => {
                category.Tables.forEach((table) => {
                    table.Gradings.forEach((grading) => {
                        // Extract deviation keys
                        const deviationKeys = Object.keys(grading.GradingVal).filter((key) =>
                            sizes.split(',').some((size) => key.startsWith(`${size}-`)) // Split sizes string and dynamically check for size match (e.g., S-1, M-2, etc.)
                        );

                        totalPoints += deviationKeys.length; // Increment total points

                        // Parse tolerance range (e.g., "3/6")
                        const toleranceRaw = grading.Tolerance;
                        const [positiveTol, negativeTol] = toleranceRaw
                            .split("/")
                            .map((val) => parseFloat(val)); // Convert to numbers

                        if (isNaN(positiveTol) || isNaN(negativeTol)) {
                            console.warn(`Invalid tolerance for SKU: ${skuName}, Measure Point: ${grading.measurePointName}`);
                            return; // Skip invalid tolerance
                        }

                        if (CalculationType === 2) {
                            // Shared tolerance calculation
                            deviationKeys.forEach((key) => {
                                const value = parseFloat(grading.GradingVal[key]);
                                if (!isNaN(value)) {
                                    // Check if value exceeds shared tolerance range
                                    if (value > positiveTol || value < -negativeTol) {
                                        outOfTolerancePoints++;
                                    }
                                }
                            });
                        } else if (CalculationType === 1) {
                            // Size-specific tolerance calculation
                            const baseValues = {}; // This will store base values dynamically
                            sizes.split(',').forEach((size) => {
                                baseValues[size] = parseFloat(grading.GradingVal[`${size}-Req`]);
                            });

                            deviationKeys.forEach((key) => {
                                const value = parseFloat(grading.GradingVal[key]);
                                const size = key.split("-")[0]; // Extract size (e.g., "S" from "S-1")
                                const baseValue = baseValues[size];

                                if (!isNaN(value) && !isNaN(baseValue)) {
                                    const upperLimit = baseValue + positiveTol;
                                    const lowerLimit = baseValue - Math.abs(negativeTol);

                                    // Check if value is outside size-specific tolerance range
                                    if (value > upperLimit || value < lowerLimit) {
                                        outOfTolerancePoints++;
                                    }
                                }
                            });
                        }
                    });
                });
            });
        });

        return {
            totalPoints,
            outOfTolerancePoints,
        };
    }

    function getDisplayTask(Taskcallback) {
        switch (Taskcallback) {
            case 'In and Mid Line Inspection':
                return 'In and Mid Line';
            case 'Size Set Check':
                return 'Size Set Check';
            case 'Pilot Run':
                return 'Pilot Run';
            case 'PP Meeting':
                return 'PP Meeting';
            default:
                return 'Unknown Task'; // Default case if none of the above match
        }
    }

    const getImageAsBase64 = async (backendEndpoint) => {
        try {
            const response = await fetch(backendEndpoint);
            if (!response.ok) console.warn("Failed to fetch base64 image.");

            const base64String = await response.text(); // Get the Base64 string
            return base64String;
        } catch (error) {
            console.error("Error:", error);
            return '';
        }
    };

    const generatePDF1 = async (MainList, TestingCommentsList, TestingPDFPhotoList, Stylebasicdata, StyleLogoDatas, MeasurementData, MeasurementBaseinfo) => {
        const left = 40;
        const top = 27;
        const imgWidth = 50;
        const imgHeight = 40;
        const margin = 40;
        const pageHeight = 820; // Usable page height in points for A4
        const footerHeight = 90; // Space reserved for footer
        const doc = new jsPDF('p', 'pt', 'a4'); // Portrait, points, A4 size
        const pageWidth = doc.internal.pageSize.getWidth();
        let currentHeight = margin;
        const logoX = margin;
        const cubeFashionsX = logoX + imgWidth + 10;
        // Calculate points
        const { totalPoints, outOfTolerancePoints } = calculateMeasurementPoints(MeasurementData, MeasurementBaseinfo?.CalculationType, MeasurementBaseinfo?.Sizes);

        const addNewPage = () => {
            doc.addPage();
            currentHeight = margin;
            addHeader();
            // addHeaderDetails(doc, pageWidth, currentHeight + 20, MainList[0][0].labComments, MainList[0][0].approval); // Add lab comments and approval result header
        };

        const task = getDisplayTask(Taskcallback);

        const addHeader = () => {
            doc.addImage('assets/img/MainMenu/ICube_logo.PNG', "PNG", left, top, imgWidth, imgHeight);
            doc.setFontSize(14);
            doc.setFont("helvetica", "bold");
            doc.text("CUBE FASHIONS", cubeFashionsX, 50);
            doc.setFontSize(10);
            doc.text(`${task.toUpperCase()} REPORT`, margin + 320, 60);
            doc.line(margin, 80, pageWidth - margin, 80); // Line below title

            // Add No, Date, and Page Number Section
            currentHeight = 95;
            doc.setFontSize(8);
            doc.setFont("helvetica", "normal");
            const now = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000);
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const year = now.getFullYear();
            const dateIST = `${day} / ${month} / ${year}`;
            doc.text(`No: ${getinpsectionID} / ${dateIST}`, margin, currentHeight);
            // Dynamic Page Number
            currentHeight += 10;
            doc.line(margin, currentHeight, pageWidth - margin, currentHeight); // Line after No/Date/Page
            currentHeight += 10;
        };

        const removeFooter = (doc, footerY, pageWidth) => {

            doc.setFillColor(255, 255, 255);
            doc.rect(0, footerY - 10, pageWidth, 50, 'F'); // Draw white rectangle over the footer area
        };
        // Add footer to each page
        const addFooter = (pageNum, totalPages) => {
            const footerY = pageHeight - footerHeight + 20;
            const leftMargin = 40;
            doc.setLineWidth(0.5);
            doc.line(leftMargin, footerY - 10, pageWidth - leftMargin, footerY - 10);
            // Footer content
            const reportPreparedY = footerY - 30;
            doc.setFont("helvetica", "bold");
            doc.setFontSize(8);
            doc.text("Report Prepared:", leftMargin, reportPreparedY);
            doc.text("Report Reviewed:", pageWidth - 150, reportPreparedY);

            if (pageNum === totalPages) {
                removeFooter(doc, footerY, pageWidth);
                doc.text("Cube Fashions (P) Ltd", leftMargin, footerY);
                doc.setFont("helvetica", "normal");
                doc.text("20/4, Jai Nagar, Maniyakaram Palayam Road, Rakkiya Palayam Cross, Kangayam Road, Tirupur, INDIA - 641606", leftMargin, footerY + 20);
                doc.text("Tel: 0091 421 4355958, Fax: 0091 421 4355671, Email: info@cubefashions.com", leftMargin, footerY + 35);

            } else {
                doc.setFont("helvetica", "bold");
                doc.text("Generated on Cube Fashions (P) Ltd", leftMargin, footerY + 20);
            }
            // Page number
            doc.setFont("helvetica", "normal");
            doc.setFontSize(8);
            doc.text(`Page ${pageNum} of ${totalPages}`, pageWidth - 70, footerY + 20, { align: 'right' });
        };

        // Add a new page when content exceeds the current page height
        const checkAndAddNewPage = (heightToAdd) => {
            if (currentHeight + heightToAdd > pageHeight - footerHeight) {
                addNewPage();
                currentHeight = margin + 50;  // Adjust after the header
            }
        };
        // Add initial header
        addHeader();
        // Add the header details
        const lineSpacing = 14; // Reduced line space
        let yPosition = currentHeight + 20;
        checkAndAddNewPage(120); // Ensure enough space
        for (const section of settings) {
            if (section.isSelected && section.Name === 'Basic Information') {
                yPosition = margin + 60;
                doc.setFontSize(8);
                doc.setFont("helvetica", "normal");
                yPosition += lineSpacing + 5;

                doc.text(`Buyer info: ${MainList[0]?.buyerName} - ${MainList[0]?.brandName} - ${MainList[0]?.seasonName}`, margin, yPosition);
                doc.text(`Supplier: ${MainList[0]?.supplierName}`, pageWidth - 325, yPosition); // Adjusted position to be closer to the center

                yPosition += lineSpacing + 5;
                doc.text(`ID / PO No: ${MainList[0]?.idpono}`, margin, yPosition);
                doc.text(`Factory: ${MainList[0]?.addressTypeName}`, pageWidth - 325, yPosition); // Reduced the offset to move left

                yPosition += lineSpacing + 5;

                doc.text(`Style Name: ${MainList[0]?.styelName}`, margin, yPosition);
                doc.text(`Description: ${StyleLogoDatas.categoryName}`, pageWidth - 325, yPosition);

                yPosition += lineSpacing + 5;

                doc.text(`Fabric: ${Stylebasicdata.fabricDetailNames}`, margin, yPosition);
                doc.text(`Order Qty: ${MainList[0]?.totalPOQty}`, pageWidth - 325, yPosition);

                yPosition += lineSpacing + 5;
                doc.text(`Checked Area: ${MainList[0]?.checkedAreaDescription}`, margin, yPosition);
                doc.text(`Report Prod. Qty: ${MainList[0]?.totalProductQty}`, pageWidth - 325, yPosition);

                yPosition += lineSpacing + 5;
                if (MainList[0]?.qaReviewID === 'Pending') {
                    doc.setTextColor(255, 0, 0); // Red
                    doc.text('Review Status: Pending', margin, yPosition);
                } else {
                    doc.setTextColor(0, 153, 0); // Green
                    doc.text(`Review Status: ${MainList[0]?.qaReviewID}`, margin, yPosition);
                }
                // Reset text color to default (black) for subsequent text
                doc.setTextColor(0, 0, 0);
                doc.text(`SKU: ${MainList[0]?.skuName}`, pageWidth - 325, yPosition);

                // Display product image to the right
                const imageX = pageWidth - 140;  // Adjusted further left
                const staticImagePath = `${window.$APIBaseURL + 'Images' + StyleLogoDatas.styleLogoPath}`;
                const backendEndpoint = `${window.$APIBaseURL}users/convert-image-to-base64?imageUrl=${encodeURIComponent(staticImagePath)}`;
                const Res = await getImageAsBase64(backendEndpoint);
                doc.addImage(Res, 'JPEG', imageX, 120, 90, 100);
                yPosition += lineSpacing + 15;  // Add extra space before QA Comments

                //QA Comments
                doc.setFont("helvetica", "bold");
                doc.setFontSize(10);
                doc.text("#", margin, yPosition);
                doc.text("QA Comments", margin + 40, yPosition);
                yPosition += 18;
                doc.line(margin, yPosition, pageWidth - margin, yPosition);

                TestingCommentsList.forEach((item, index) => {
                    // Determine background color for alternating rows
                    const rowColor = index % 2 === 0 ? [240, 240, 240] : [255, 255, 255];
                    doc.setFillColor(...rowColor);

                    const rowPadding = 8;
                    const maxTextWidth = pageWidth - margin * 2 - 40;

                    // Manual word wrapping for comments
                    const words = item.Comments.split(" ");
                    let line = "";
                    const lines = [];

                    words.forEach((word) => {
                        const testLine = line + word + " ";
                        const testLineWidth = doc.getTextWidth(testLine);
                        if (testLineWidth > maxTextWidth && line !== "") {
                            lines.push(line.trim());
                            line = word + " ";
                        } else {
                            line = testLine;
                        }
                    });
                    lines.push(line.trim()); // Push the last line

                    // Calculate row height based on wrapped text lines
                    const rowHeight = 20 + lines.length * 12;

                    // Draw background rectangle for the row
                    doc.rect(margin, yPosition, pageWidth - 2 * margin, rowHeight, 'F');

                    // Calculate rowStartY to center the text vertically
                    const rowStartY = yPosition + rowPadding + (rowHeight - lines.length * 12) / 2;

                    // Render Serial Number
                    doc.setFont("helvetica", "normal");
                    doc.setFontSize(9);
                    doc.text(`${index + 1}`, margin + 5, rowStartY);

                    // Render Wrapped Text with Conditional Symbol on the Last Line
                    doc.setFont("helvetica", "normal");
                    lines.forEach((lineText, lineIndex) => {
                        const yTextPos = rowStartY + lineIndex * 12;
                        // Add the symbol at the end of the last line if ActionTaken is 1
                        const processedLineText = (lineIndex === lines.length - 1 && item.ActionTaken === "1")
                            ? `${lineText.trim()}`
                            : lineText.trim();
                        doc.text(`${processedLineText}`, margin + 40, yTextPos);
                    });

                    yPosition += rowHeight + 3; // Space between rows
                    // Add new page if yPosition exceeds the page height
                    if (yPosition > pageHeight - footerHeight - 50) {
                        addNewPage();
                        yPosition = margin + 40;
                    }
                });
                // Footer Section
                if (yPosition > 650) {
                    addNewPage();
                    // addFooter();
                    yPosition = margin + 100;
                } else {
                    yPosition = pageHeight - footerHeight - 70;
                }
                doc.setFont("helvetica", 'bold');
                doc.text("QC Remarks:", margin, yPosition);
                // Insert the QC remarks text below the label and wrap long text
                doc.setFont("helvetica", "normal"); // Switch to normal font for the actual remarks text
                yPosition += 15;

                // Automatically wrap the QC remarks text within the available page width
                const maxTextWidth = pageWidth - 2 * margin;  // Account for both margins
                const wrappedText = doc.splitTextToSize(MainList[0]?.qcRemarks, maxTextWidth);  // Wrap the text

                // Render the wrapped text
                doc.text(wrappedText, margin, yPosition);
                yPosition += 35;
            }
            if (section.isSelected && section.Name === 'Photos') {
                if (TestingPDFPhotoList.length > 0) {
                    addNewPage();
                    yPosition = margin + 80;
                    doc.setFont("helvetica", "bold");
                    doc.text("Photos:", margin, yPosition);
                    // Set increased image properties
                    const imagesPerRow = 3;
                    const pageWidthWithoutMargins = pageWidth - margin * 2;
                    const imageSpacing = 20;
                    const imageWidth = (pageWidthWithoutMargins - imageSpacing * (imagesPerRow - 1)) / imagesPerRow;
                    const imageHeight = imageWidth * 0.9;  // Increase height proportionally

                    // Initial position for the first image (centered)
                    let imageX = margin;
                    let imageY = yPosition + 20;  // Place images below the title

                    TestingPDFPhotoList.forEach((image, index) => {
                        if (image.Images) {
                            if (imageY + imageHeight > pageHeight - footerHeight + 100) {
                                addNewPage();
                                imageX = margin; // Reset imageX for the new page
                                imageY = margin + 100; // Reset imageY for the new page
                            }

                            const imgPath = `${window.$APIBaseURL + 'Images/' + image.Images}`;
                            doc.addImage(imgPath, 'JPEG', imageX, imageY, imageWidth, imageHeight);
                            doc.text(`${'Image: ' + (image.Comment)}( ${image.CommentMainIndex} )`, imageX, imageY + imageHeight + 10); // Add title below the image

                            // Move the X position to place the next image in the same row
                            imageX += imageWidth + imageSpacing; // Move to the next image with spacing

                            // Move to the next row after 3 images (imagesPerRow)
                            if ((index + 1) % imagesPerRow === 0) {
                                imageX = margin; // Reset X for the next row
                                imageY += imageHeight + 40; // Move Y position down for the next row, with space between rows
                            }
                        }
                    });
                    yPosition = imageY + 50;
                }
            }
            if (section.isSelected && section.Name === 'Basic Information with measurement') {
                if (MeasurementData.length > 0) {
                    addNewPage();
                    // Header
                    yPosition = margin + 80;
                    // Measurement Section Header
                    doc.setFillColor(230, 230, 230);
                    doc.rect(margin - 5, yPosition - 15, pageWidth - 2 * margin + 10, 25, "F");
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(10);
                    doc.text("Measurement", margin, yPosition);
                    doc.setTextColor(0, 0, 0); // Reset color to black
                    yPosition += 25;

                    // Measurement Details
                    doc.setFont("helvetica", "normal");
                    doc.setFontSize(8);
                    doc.text(`Measurement Checked Pieces: ${MeasurementBaseinfo?.MeasurementCheckedPeices}`, margin, yPosition);
                    doc.text(`Total Point of Measurement: ${totalPoints}`, pageWidth / 2, yPosition, { align: "center" });
                    yPosition += 15;
                    doc.text(`Measurement Points of Out of Tolerance: ${outOfTolerancePoints}`, margin, yPosition);
                    yPosition += 30;

                    MeasurementData.forEach((input) => {
                        // SKU Section Header
                        if (yPosition > 630) {
                            addNewPage();
                            yPosition = margin + 100;
                        }
                        doc.setFillColor(230, 230, 230);
                        doc.rect(margin - 5, yPosition - 15, pageWidth - 2 * margin + 10, 25, "F");
                        doc.setFont("helvetica", "bold");
                        doc.setFontSize(10);
                        doc.text(`SKU: ${input.skuName}`, margin, yPosition); // Dynamic SKU Name
                        doc.text("Result:", pageWidth - 140, yPosition);

                        if (input.result === 'OK') {
                            doc.setFillColor(204, 255, 204); // Light green
                            doc.setDrawColor(0, 153, 0); // Green
                            doc.setTextColor(0, 153, 0); // Dark green for text
                        } else if (input.result === 'NOT OK') {
                            doc.setFillColor(255, 204, 204); // Light red
                            doc.setDrawColor(255, 0, 0); // Red
                            doc.setTextColor(153, 0, 0); // Dark red for text
                        } else {
                            doc.setFillColor(204, 229, 255); // Light blue
                            doc.setDrawColor(0, 102, 204); // Blue
                            doc.setTextColor(0, 0, 153); // Dark blue for text
                        }
                        doc.rect(pageWidth - 90, yPosition - 10, 50, 15, "FD");
                        doc.text(input.result || '', pageWidth - 85, yPosition);

                        doc.setTextColor(0, 0, 0); // Reset color to black
                        yPosition += 30;
                        input.Category.forEach((category) => {
                            if (yPosition > 630) {
                                addNewPage();
                                yPosition = margin + 100;
                            }
                            doc.setFont("helvetica", "bold");
                            doc.text(`Category Type: ${category.CategoryName}`, margin, yPosition);
                            yPosition += 20; // Adjust vertical space

                            category.Tables.forEach((table) => {
                                if (yPosition > 630) {
                                    addNewPage();
                                    yPosition = margin + 100;
                                }
                                doc.setFont("helvetica", "bold");
                                // Define headers
                                const sizes = MeasurementBaseinfo.Sizes.split(","); // ["4", "6", "8", "10", "12", "1"]
                                // Dynamically build the header
                                const headers = [["#", "Points", "Tol +/-", ...sizes.flatMap((size, index) => [size, (index + 1).toString()])]];

                                // Bind table data dynamically
                                const body = table.Gradings.map((grading, index) => {
                                    const { measurePointName, Tolerance, GradingVal } = grading;

                                    // Dynamically fetch sizes
                                    const sizes = MeasurementBaseinfo.Sizes.split(","); // Split the dynamic sizes
                                    const deviations = sizes.map((size) => {
                                        const deviationKeys = Object.keys(GradingVal).filter((key) => key.startsWith(size));
                                        return deviationKeys.map((key) => GradingVal[key] || ""); // Extract deviation values dynamically
                                    });

                                    // Flatten deviations for proper table mapping
                                    const flattenedDeviations = deviations.map((deviation) => deviation[1] || ""); // Get only the value we need

                                    // Dynamically generate the row data
                                    const row = [
                                        index + 1,
                                        measurePointName,
                                        Tolerance,
                                    ];
                                    // Add the dynamically generated size columns and their corresponding deviations
                                    sizes.forEach((size, idx) => {
                                        // Add required field (e.g., S-Req, M-Req, etc.)
                                        row.push(GradingVal[`${size}-Req`] || "");
                                        // Add deviation field for the size
                                        row.push(flattenedDeviations[idx] || "");
                                    });

                                    return row;
                                });

                                const maxRowsPerPage = 18; // Fixed rows per page

                                // Function to split table data into chunks
                                const splitTableData = (data, maxRows) => {
                                    const chunks = [];
                                    for (let i = 0; i < data.length; i += maxRows) {
                                        chunks.push(data.slice(i, i + maxRows));
                                    }
                                    return chunks;
                                };
                                // Split the body data into manageable chunks
                                const tableChunks = splitTableData(body, maxRowsPerPage);

                                // Render each chunk
                                tableChunks.forEach((chunk, index) => {
                                    if (index > 0) {
                                        addNewPage(); // Add a new page for subsequent chunks
                                    }

                                    doc.autoTable({
                                        startY: index === 0 ? yPosition : 125, // Start Y position: adjust for the first page
                                        head: headers,
                                        body: chunk, // Render only the chunk
                                        theme: "grid",
                                        tableWidth: pageWidth - 2 * margin,
                                        styles: {
                                            font: "helvetica",
                                            fontSize: 8,
                                            cellPadding: 3,
                                            overflow: "linebreak",
                                        },
                                        headStyles: {
                                            fillColor: [230, 230, 230],
                                            textColor: 0,
                                            fontStyle: "bold",
                                        },
                                        didParseCell: (data) => {

                                            if (MeasurementBaseinfo.CalculationType === 2) {
                                                const { column, row, cell } = data;

                                                // Process only body rows
                                                if (row.section === "body") {
                                                    // Retrieve tolerance from the "Tol +/-" column (column 2)
                                                    const toleranceCell = row.cells[2];
                                                    const toleranceRaw = toleranceCell.raw;

                                                    // Parse the tolerance range (e.g., "3/6" => +3/-6)
                                                    const [positiveTol, negativeTol] = toleranceRaw
                                                        .split("/") // Split the "3/6" format
                                                        .map((val) => parseFloat(val)); // Convert to numbers

                                                    if (isNaN(positiveTol) || isNaN(negativeTol)) {
                                                        console.warn(`Skipping invalid tolerance for row ${row.index}, column ${column.index}`);
                                                        return; // Skip invalid tolerance
                                                    }

                                                    // Determine the current column header
                                                    const headerValue = String(data.table.columns[column.index]?.dataKey || "");

                                                    // Columns to validate for deviations: "1", "2", "3", "4", "5"
                                                    const validHeaders = data.table.columns
                                                        .filter(column => column.dataKey % 2 === 0 && column.dataKey >= 4)  // Check for even dataKey
                                                        .map(column => String(column.dataKey));  // Convert to string to match the format

                                                    // Check if this column is a deviation column
                                                    if (validHeaders.includes(headerValue)) {
                                                        const value = parseFloat(cell.raw); // Parse cell value

                                                        if (!isNaN(value)) {
                                                            if (value > positiveTol || value < -negativeTol) {
                                                                cell.styles.fillColor = [255, 255, 0]; // Yellow background
                                                                cell.styles.textColor = [255, 0, 0]; // Red text
                                                            }
                                                        }
                                                    }
                                                }
                                            } else {
                                                const { column, row, cell } = data;
                                                // Process only body rows
                                                if (row.section === "body") {
                                                    // Retrieve tolerance from the "Tol +/-" column (column 2)
                                                    const toleranceCell = row.cells[2];
                                                    const toleranceRaw = toleranceCell?.raw;

                                                    if (!toleranceRaw) {
                                                        console.warn(`Skipping: Missing tolerance in row ${row.index}`);
                                                        return;
                                                    }

                                                    // Parse the tolerance range (e.g., "3/6" => +3/-6)
                                                    const [positiveTol, negativeTol] = toleranceRaw
                                                        .split("/") // Split the "3/6" format
                                                        .map((val) => parseFloat(val)); // Convert to numbers

                                                    if (isNaN(positiveTol) || isNaN(negativeTol)) {
                                                        console.warn(`Skipping invalid tolerance: ${toleranceRaw} in row ${row.index}`);
                                                        return; // Skip invalid tolerance
                                                    }

                                                    // Determine the current column header
                                                    const headerValue = String(data.table.columns[column.index]?.dataKey || "");

                                                    // Deviation columns to validate: "1", "2", "3", "4", "5"
                                                    //const validHeaders = ["4", "6", "8", "10", "12"];
                                                    const validHeaders = data.table.columns
                                                        .filter(column => column.dataKey % 2 === 0 && column.dataKey >= 4)  // Check for even dataKey
                                                        .map(column => String(column.dataKey));  // Convert to string to match the format

                                                    if (validHeaders.includes(headerValue)) {
                                                        // Dynamically create the base column index mapping
                                                        const baseColumnIndex = validHeaders.reduce((acc, headerValue, index) => {
                                                            const baseIndex = (index * 2) + 3; // Calculate base column index dynamically
                                                            acc[headerValue] = baseIndex;
                                                            return acc;
                                                        }, {});
                                                        const baseIndex = baseColumnIndex[headerValue];

                                                        // Fetch the corresponding base value
                                                        const baseValueCell = row.cells[baseIndex];
                                                        const baseValue = parseFloat(baseValueCell?.raw);

                                                        if (isNaN(baseValue)) {
                                                            console.warn(`Skipping invalid base value in row ${row.index}, column ${baseColumnIndex}`);
                                                            return; // Skip invalid base value
                                                        }

                                                        // Get the current deviation value in this cell
                                                        const deviationValue = parseFloat(cell.raw);

                                                        if (!isNaN(deviationValue)) {
                                                            // Calculate tolerance range
                                                            const upperLimit = baseValue + positiveTol;
                                                            const lowerLimit = baseValue - negativeTol;

                                                            // Check if the deviation value falls outside the range
                                                            if (deviationValue > upperLimit || deviationValue < lowerLimit) {
                                                                cell.styles.fillColor = [255, 255, 0]; // Yellow background
                                                                cell.styles.textColor = [255, 0, 0]; // Red text
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                    });
                                });
                                // Update yPosition for next table
                                yPosition = doc.lastAutoTable.finalY + 20;
                            });
                        });
                    });
                }
            }
        };
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            addFooter(i, totalPages);
        }
        doc.save(`${task.toUpperCase()} REPORT.pdf`);
    };


    function handleSaveQAApproval(e, comment) {
        const values = { ...getQARemark }
        if (values.ReceivedDate === '' || values.Comments === '' || values.ApprovalResult === 0) {
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please enter mandatory(*) fields");
            return false;
        } else {
            // // var Data = [];
            var CommentInformationData = [];
            CommentInformationData.push({
                QAApprovalID: 0,
                ApprovalID: getQARemark.ApprovalResult,
                ApprovalRemarks: getQARemark.Comments,
                InspectionNoanddate: getQARemark.Inspectionnodate,
                InspectionApprovalDate: getQARemark.ReceivedDate
                //IsLatest : 1
            });

            var data = {
                CreatedBy: currentUser.employeeinformationID, QCInspectionID: comment.qcInspectionID,
                ApprovalListInformation: CommentInformationData, TaskNmae: Taskcallback
            };

            QAService.InsertUpdateApproval(data).then((res) => {

                if (res.data.outputResult === "1") {
                    setCommentpopupNew(false)
                    Nodify('Success!', 'success', "Comment Added for " + comment.qcInspectionID + " Successfully");
                    let StartDate = null;
                    let EndDate = null;
                    if (moment(stateDate[0].startDate).isValid()) {
                        StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
                    }
                    if (moment(stateDate[0].endDate).isValid()) {
                        EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
                    }

                    let StartApDate = null;
                    const today = new Date();
                    const startDate = new Date(today);
                    startDate.setDate(today.getDate() - 90);
                    if (moment(startDate).isValid()) {
                        StartApDate = moment(startDate).format('MM/DD/YYYY');
                        //StartDate.setMinutes(StartDate.getMinutes() + 370);
                    }
                    const currentDate = new Date();
                    const formattedDate = moment(currentDate).format('MM/DD/YYYY');
                    let params = {
                        IsProduction: IsProduction, Taskname: Taskcallback, PoStartDate: StartDate,
                        PoEndDate: EndDate, ApStartDate: StartApDate, ApEndDate: formattedDate, ApprovedStatus: 0
                    }
                    dispatch(LoaQAInspectionList(params));
                    setQARemark();
                } else if (res.data.outputResult === "-2") {
                    Nodify('Warning!', 'warning', "Error Occured");
                }
            })
        }
    }

    function handleChangeQARemarks(e, FieldName, result) {
        let inputText = '';
        const values = { ...getQARemark }
        if (FieldName === 'ReceivedDate') {
            if (e !== null) {
                values.ReceivedDate = e;
            }
            else {
                values.ReceivedDate = null;
            }
        } else if (FieldName === 'Approvalstatus') {
            values.ApprovalResult = result;
        } else {
            if (e.target.value.trim() !== '') {
                inputText = e.target.value;
            }
            values.Comments = inputText;
        }
        setQARemark(values);
    }
    return (
        <div className="page-body" style={{ background: "none" }}>
            <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div className="widget">

                        <div className="widget-body" style={{ boxShadow: "none", padding: "0px" }}>
                            <div className="widget">
                                <div id="simpledatatable_wrapper" class="dataTables_wrapper form-inline no-footer">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div className="widget">
                                                <div className="widget-body no-padding">
                                                    <div className="widget-main ">
                                                        <div className="panel-group accordion" id="accordion1">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading ">
                                                                    <h4 className="panel-title">
                                                                        <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne1">
                                                                            Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                        </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOne1" className="panel-collapse collapse in">
                                                                    <div className="panel-body border-red">
                                                                        <div className="row">
                                                                            <div className="col-md-12">

                                                                                <div className="col-md-2">
                                                                                    <label>Buyer</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Buyer'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Buyer'}
                                                                                            onChange={e => handleChange(e, 'Buyer')}
                                                                                            value={getBuyersearch}
                                                                                        />

                                                                                    </span>
                                                                                </div>


                                                                                <div className="col-md-2">
                                                                                    <label>Season</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Season'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Season'}
                                                                                            onChange={e => handleChange(e, 'Season')}
                                                                                            value={getSeasonsearch}

                                                                                        />

                                                                                    </span>



                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Brand</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Brand'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Brand'}
                                                                                            onChange={e => handleChange(e, 'Brand')}
                                                                                            value={getBrandsearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Quarter</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Quarter'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Quarter'}
                                                                                            onChange={e => handleChange(e, 'Quarter')}
                                                                                            value={getQuartersearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>Supplier</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'Supplier'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Supplier'}
                                                                                            onChange={e => handleChange(e, 'Supplier')}
                                                                                            value={getSuppliersearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>PO/ID No.</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className=" StyleList form-control"
                                                                                            title={'PO/ID No'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'PO/ID No'}
                                                                                            onChange={e => handleChange(e, 'ID/NO')}
                                                                                            value={getIdPosearch}
                                                                                        />

                                                                                    </span>

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        &nbsp;
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>Style Name</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Style Name'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Style Name'}
                                                                                            onChange={e => handleChange(e, 'StyleName')}
                                                                                            value={getStyleNamesearch}
                                                                                        />
                                                                                    </span>
                                                                                </div>

                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>SKU Name</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'SKU Name'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'SKU Name'}
                                                                                            onChange={e => handleChange(e, 'SKUName')}
                                                                                            value={getSkuSearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <label>&nbsp;</label>
                                                                                    <label>Factory</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Factory'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Factory'}
                                                                                            onChange={e => handleChange(e, 'Factory')}
                                                                                            value={getFactorySearch}
                                                                                        />
                                                                                    </span>

                                                                                </div>
                                                                                <div className="col-md-2" >
                                                                                    {/* <label>Style Name</label> */}
                                                                                    <label>&nbsp;</label>
                                                                                    <label>Report No</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="StyleList form-control"
                                                                                            title={'Report No'}
                                                                                            maxlength={"50"}
                                                                                            placeholder={'Report No'}
                                                                                            onChange={e => handleChange(e, 'ReportNo')}
                                                                                            value={getreportnosearch}
                                                                                        />
                                                                                    </span>
                                                                                </div>

                                                                                <div className="col-md-2" ref={datePickerreport}>
                                                                                    <label>Report Date</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                            onClick={event => handleDateSelect(event, !stateDate[0].isShow)}

                                                                                            type="text"
                                                                                            className={'form-control styledatapickerwidth100'}
                                                                                            placeholder="Select Date"

                                                                                        />
                                                                                        <i className="fa fa-times stylemleft" onClick={clearDateSelect} ></i>
                                                                                    </span>


                                                                                    <span className='input-icon icon-right mb-5'>
                                                                                        <br />
                                                                                        {stateDate[0].isShow &&
                                                                                            <DateRangePicker
                                                                                                // onChange={item => setStateDate([item.selection])}
                                                                                                onChange={item => DateRangechange([item.selection])}
                                                                                                showSelectionPreview={true}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                months={2}
                                                                                                ranges={stateDate}
                                                                                                showDateDisplay={false}
                                                                                                direction="vertical"
                                                                                                className={'StyleDateRangePicker'}

                                                                                            />

                                                                                        }
                                                                                    </span>
                                                                                </div>


                                                                                <div className="col-md-2">
                                                                                    <label>Report and Approval Filter</label>
                                                                                    <span className='input-icon icon-right' onClick={handleclick}>
                                                                                        <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                            options={InspectionandApproval}
                                                                                            isClearable={true}
                                                                                            onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}
                                                                                            value={getOrderStatussearch}
                                                                                        ></Reactselect>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        &nbsp;

                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="col-md-2" ref={datePickerRefApp}>
                                                                                    <label>Approval Date</label>
                                                                                    <span className="input-icon icon-right">
                                                                                        <input
                                                                                            value={(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                                                    moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                            onClick={event => handleExDateSelect(event, !stateApDate[0].isShow)}
                                                                                            //onBlur={event => handleDateBlur()}
                                                                                            type="text"
                                                                                            className={'form-control styledatapickerwidth100'}
                                                                                            placeholder="Select Date"

                                                                                        />
                                                                                        <i className="fa fa-times stylemleft" onClick={clearExDateSelect} ></i>
                                                                                    </span>


                                                                                    <span className='input-icon icon-right mb-5'>
                                                                                        <br />
                                                                                        {stateApDate[0].isShow &&
                                                                                            <DateRangePicker
                                                                                                //onChange={item => setStateApDate([item.selection])}
                                                                                                onChange={item => DateExfaconchange([item.selection])}
                                                                                                showSelectionPreview={true}
                                                                                                moveRangeOnFirstSelection={false}
                                                                                                months={2}
                                                                                                ranges={stateApDate}
                                                                                                showDateDisplay={false}
                                                                                                direction="vertical"
                                                                                                className={'StyleDateRangePicker'}

                                                                                            />

                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-md-1 margin-top-30">
                                                                                    <span className="input-icon icon-right">
                                                                                        <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetFinalIns()}
                                                                                        >
                                                                                            <i className="fa fa-close"> Reset</i>
                                                                                        </button>
                                                                                    </span>

                                                                                </div>
                                                                                {/* <div className="col-md-3">
                                                                                <label>Status</label>
                                                                                <span className='input-icon icon-right' onClick={handleclick}>
                                                                                    <Reactselect className="basic-single reportstatus" name="GenderID"
                                                                                        options={orderStatus}
                                                                                        isClearable={true}
                                                                                        onChange={e => handleChangeOrdStatus(e, 'OrderStatus')}

                                                                                        value={getOrderStatussearch}
                                                                                    ></Reactselect>
                                                                                </span>
                                                                            </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;
                                                                </div>
                                                            </div>
                                                            &nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
                    <div className="fixTableHeadTnadetail">
                        <Modal.Header closeButton>
                            <Modal.Title>Show Hide Header</Modal.Title>
                        </Modal.Header>
                        {/* , overflowY: "auto" */}
                        <Modal.Body className="customdialogbody">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="CreatedDate"
                                                        checked={checkboxStates.CreatedDate}

                                                    />
                                                    &nbsp;<label htmlFor="CreatedDate">Created Date </label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="EntryNo"
                                                        checked={checkboxStates.EntryNo}
                                                    />
                                                    &nbsp;<label htmlFor="EntryNo">Entry No</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="ReportType"
                                                        checked={checkboxStates.ReportType}
                                                    />
                                                    &nbsp;<label htmlFor="reportType">Report Type</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="ReInspectionDoneAgainst"
                                                        checked={checkboxStates.ReInspectionDoneAgainst}
                                                    />
                                                    &nbsp;<label htmlFor="ReInspectionDoneAgainst">Re Inspection Done</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="Supplier"
                                                        checked={checkboxStates.Supplier}
                                                    />
                                                    &nbsp;<label htmlFor="Supplier">Supplier</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="Factory"
                                                        checked={checkboxStates.Factory}
                                                    />
                                                    &nbsp;<label htmlFor="Factory">Factory</label>
                                                </li>
                                            </div>

                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="IDNo"
                                                        checked={checkboxStates.IDNo}
                                                    />
                                                    &nbsp;
                                                    <label htmlFor="IDNo">PO/ID No</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="StyleName"
                                                        checked={checkboxStates.StyleName}
                                                    />
                                                    &nbsp;
                                                    <label htmlFor="StyleName">Style Name</label>
                                                </li>
                                            </div>


                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="StyleNo"
                                                        checked={checkboxStates.StyleNo}
                                                    />
                                                    &nbsp;
                                                    <label htmlFor="StyleNo">Style No</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="SKUName"
                                                        checked={checkboxStates.SKUName}
                                                    />
                                                    &nbsp;
                                                    <label htmlFor="SKUName">SKU Name</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="InspectionQuantity"
                                                        checked={checkboxStates.InspectionQuantity}
                                                    />
                                                    &nbsp;<label htmlFor="InspectionQuantity">Report Quantity</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="InspectionResult"
                                                        checked={checkboxStates.InspectionResult}
                                                    />
                                                    &nbsp;<label htmlFor="InspectionResult">Report status</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="CreatedBy"
                                                        checked={checkboxStates.CreatedBy}
                                                    />
                                                    &nbsp;<label htmlFor="CreatedBy">Created By</label>
                                                </li>
                                            </div>
                                            <div className="col-lg-4">
                                                <li
                                                    className="listmodal"
                                                    onChange={handleCheckboxChange}>
                                                    <input
                                                        className="hidecheckbox"
                                                        type="checkbox"
                                                        name="ApprovalResult"
                                                        checked={checkboxStates.ApprovalResult}
                                                    />
                                                    &nbsp;<label htmlFor="ApprovalResult">Approval Result</label>
                                                </li>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-xs btn-danger" onClick={handleClose}><i className="fa fa-times"></i>&nbsp;Close</button>
                        </Modal.Footer>
                    </div>
                </Modal>
                <div className="row" style={{ marginTop: '5px' }}>
                    <div className="col-lg-11">
                    </div>
                    <button style={{ marginTop: '8px', marginBottom: '7px' }} title="Hide Header" type="button" className="btn btn-default btn-primary" onClick={handleShow}> <i className="glyphicon glyphicon-eye-close"></i></button>

                    {commentsData.length !== 0 && (
                        <ReactHTMLTableToExcel
                            id="test-table-s-button-final"
                            className="fa fa-file-excel-o  btn btn-success excelcomt"
                            table={"table-to-xlss"}
                            filename={"InspectionEntryList"}
                            sheet="InspectionEntry"
                            buttonText=""
                        />
                    )}
                    <div className="col-md-12">
                        <div style={{ overflow: "auto" }}>
                            <table data-role="table" data-mode="columntoggle" className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" style={{ width: "160vm" }}>
                                <TableHeader headers={Taskcallback === 'In and Mid Line Inspection' || Taskcallback === 'PP Meeting' ? tableHeader : Taskcallback === 'Pilot Run' || Taskcallback === 'Size Set Check' ? tableHeaderPilot : ""}
                                    onSorting={
                                        (field, order) => setSorting({ field, order })
                                    }
                                />
                                {<tbody>
                                    {

                                        commentsData.map((comment, i) => (
                                            <Fragment key={i}>
                                                <>
                                                    {Taskcallback === "In and Mid Line Inspection" || Taskcallback === 'PP Meeting' ?
                                                        <tr key={comment.qcInspectionID}>
                                                            <td style={{ width: '25px' }}>{i + 1}</td>
                                                            {
                                                                checkboxStates.CreatedDate && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        // className="stickyone "
                                                                        title={comment.insCreatedDate}>
                                                                        {comment.insCreatedDate}

                                                                    </td>
                                                                )
                                                            }

                                                            {
                                                                checkboxStates.EntryNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={`${comment.reportType} - ${comment.qcInspectionID}`}>
                                                                        {comment.reportType} - {comment.qcInspectionID}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.Supplier && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={`${comment.supplierName} - ${comment.factory}`}>
                                                                        {comment.supplierName} - {comment.factory}
                                                                    </td>
                                                                )
                                                            }


                                                            {
                                                                checkboxStates.StyleName && (
                                                                    <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.SKUName && (
                                                                    <td className="cut_textCommentsreport " title={comment.skuName}>{comment.skuName}</td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.IDNo && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={'PO/ID No'}>
                                                                        {/* {"-"} */}
                                                                        {comment.idpono}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.orderQty && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.orderQty}>
                                                                        {/* {comment.inspectionQty} */}
                                                                        {'-'}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.InspectionQuantity && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.inspectionQty}>
                                                                        {/* {comment.inspectionQty} */}
                                                                        {'-'}
                                                                    </td>
                                                                )
                                                            }
                                                            {
                                                                checkboxStates.InspectionResult && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={`${comment.inspectionResult} - ${comment.createdBy}`}>
                                                                        <b className={comment.inspectionResult === 'Saved' ? 'success' : 'warning'}>
                                                                            {comment.inspectionResult}
                                                                        </b>
                                                                        <span> - {comment.createdBy}</span>
                                                                    </td>
                                                                )
                                                            }
                                                            {Taskcallback === "In and Mid Line Inspection" || Taskcallback === "PP Meeting" ?
                                                                checkboxStates.ApprovalResult && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.reviewStatus}>
                                                                        <span className={comment.reviewStatus === '1' ? 'success' : 'red'}> <b>{comment.reviewStatus === '1' ? 'Completed' : "Pending"}</b> </span>
                                                                    </td >
                                                                )
                                                                :
                                                                checkboxStates.ApprovalResult && (
                                                                    <td
                                                                        style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                        //className="stickytwo "
                                                                        title={comment.approvalResult}>
                                                                        <span className={comment.approvalResult === 'Approved' ? 'success' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                                    </td >
                                                                )
                                                            }

                                                            {< td className="text-center fixed-column-header-CommentReport" style={{ width: "12%", display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}>
                                                                < span >
                                                                    {(Taskcallback === "In and Mid Line Inspection" || Taskcallback === "PP Meeting") ? < button type="button"
                                                                        className={(comment.inspectionResult === 'Draft') && comment.reviewStatus === null ? "DPNone" : comment.reviewStatus === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                        //className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                        onClick={() => GetReview(comment, 'Comments')}
                                                                    >
                                                                        <i className="fa fa-check"></i>
                                                                    </button> :
                                                                        < button type="button"
                                                                            className={(comment.inspectionResult === 'Draft') && comment.approvalResult === null ? "DPNone" : comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                            // className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                            onClick={() => GetComments(comment, 'Comments')}
                                                                        >
                                                                            <i className="fa fa-check"></i>
                                                                        </button>}
                                                                    &nbsp;
                                                                    <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                        onClick={() => handleFinalInspecView(comment, 'View', '')}
                                                                    >
                                                                        <i className="fa fa-eye "></i>
                                                                    </button>
                                                                    &nbsp;

                                                                    <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-success" : "DPNone"} title='Edit FinalList'
                                                                        onClick={() => handleFinalInspecView(comment, 'Edit', 'EditInspection')}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </button>
                                                                    &nbsp;
                                                                    <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-danger" : "DPNone"} title='Delete FinalList'
                                                                        onClick={() => handleDeleteQAinspection(comment)}
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button> &nbsp;
                                                                    <button className="btn btn-xs btn-magenta" title="PDF" onClick={(e) => handlepdffunction(comment)}><i className="fa fa-file-pdf-o"></i></button>&nbsp;
                                                                </span >
                                                            </td>}

                                                        </tr> : Taskcallback === 'Pilot Run' || Taskcallback === 'Size Set Check'
                                                            ? <tr key={comment.qcInspectionID}>
                                                                <td>{i + 1}</td>
                                                                {
                                                                    checkboxStates.CreatedDate && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            // className="stickyone "
                                                                            title={comment.insCreatedDate}>
                                                                            {comment.insCreatedDate}

                                                                        </td>
                                                                    )
                                                                }

                                                                {
                                                                    checkboxStates.EntryNo && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={`${comment.reportType} - ${comment.qcInspectionID}`}>
                                                                            {comment.reportType} - {comment.qcInspectionID}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.Supplier && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={`${comment.supplierName} - ${comment.factory}`}>
                                                                            {comment.supplierName} - {comment.factory}
                                                                        </td>
                                                                    )
                                                                }


                                                                {
                                                                    checkboxStates.StyleName && (
                                                                        <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.SKUName && (
                                                                        <td className="cut_textCommentsreport " title={comment.skuName}>{comment.skuName}</td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.IDNo && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={'PO/ID No'}>
                                                                            {/* {"-"} */}
                                                                            {comment.idpono}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.skuImg && (
                                                                        <td className="cut_textCommentsreport " title={comment.skuImg}>
                                                                            {/* {comment.skuImg} */}
                                                                            {"-"}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.orderQty && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.orderQty}>
                                                                            {/* {comment.inspectionQty} */}
                                                                            {'-'}
                                                                        </td>
                                                                    )
                                                                }
                                                                {
                                                                    checkboxStates.InspectionQuantity && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.inspectionQty}>
                                                                            {/* {comment.inspectionQty} */}
                                                                            {'-'}
                                                                        </td>
                                                                    )
                                                                }
                                                                {/* {
                                                   checkboxStates.Factory && (
                                                       <td
                                                           style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                           //className="stickytwo "
                                                           title={'Factory'}>
                                                           {/* {"-"}
                                                           {comment.factory}
                                                       </td>
                                                   )
                                               } */}


                                                                {/* {
                                                   checkboxStates.IDNo && (
                                                       <td
                                                           style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                           //className="stickytwo "
                                                           title={'FO PO ID No'}>
                                                           {/* {"-"}
                                                           {comment.foidpono === '0' ? '-' : comment.foidpono}
                                                       </td>
                                                   )
                                               } */}



                                                                {
                                                                    checkboxStates.InspectionResult && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={`${comment.inspectionResult} - ${comment.createdBy}`}>
                                                                            <b className={comment.inspectionResult === 'Draft' ? 'warning'
                                                                                : comment.inspectionResult === 'OK' ? 'success' :
                                                                                    comment.inspectionResult === 'OK with Comments' ? 'primary' : 'danger'
                                                                            }>
                                                                                {comment.inspectionResult}
                                                                            </b>
                                                                            <span> - {comment.createdBy}</span>
                                                                        </td>
                                                                    )
                                                                }

                                                                {/* {
                                                       checkboxStates.ApprovalResult && (
                                                           <td
                                                               style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                               //className="stickytwo "
                                                               title={comment.approvalResult}>
                                                               <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                           </td >
                                                       )
                                               } */}
                                                                {/* {
                                                   checkboxStates.CreatedBy && (
                                                       <td
                                                           style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                           //className="stickytwo "
                                                           title={comment.createdBy}>
                                                           {comment.createdBy}
                                                       </td>
                                                   )
                                               } */}

                                                                {Taskcallback === "In and Mid Line Inspection" || Taskcallback === "PP Meeting" ?
                                                                    checkboxStates.ApprovalResult && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.reviewStatus}>
                                                                            <span className={comment.reviewStatus === '1' ? 'success' : 'red'}> <b>{comment.reviewStatus === '1' ? 'Completed' : "Pending"}</b> </span>
                                                                        </td >
                                                                    )
                                                                    :
                                                                    checkboxStates.ApprovalResult && (
                                                                        <td
                                                                            style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                            //className="stickytwo "
                                                                            title={comment.approvalResult}>
                                                                            <span className={comment.approvalResult === 'Approved' ? 'success' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                                        </td >
                                                                    )
                                                                }

                                                                {/* {
                                                    checkboxStates.InspectionResult && (
                                                        <td>{'-'}</td>
                                                    )
                                                }
                                                */}

                                                                {< td className="text-center fixed-column-header-CommentReport" style={{ width: "12%", display: InternalComments && InternalComments.isDelete === 0 && InternalComments.isView === 0 && InternalComments.isEdit === 0 && InternalComments.isAdd === 0 ? 'none' : '' }}>
                                                                    < span >
                                                                        {(Taskcallback === "In and Mid Line Inspection" || Taskcallback === "PP Meeting") ? < button type="button"
                                                                            className={(comment.inspectionResult === 'Draft') && comment.reviewStatus === null ? "DPNone" : comment.reviewStatus === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                            style={{ display: InternalComments && InternalComments.isAdd === 0 ? 'none' : '' }}
                                                                            //className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                            onClick={() => GetReview(comment, 'Comments')}
                                                                        >
                                                                            <i className="fa fa-check"></i>
                                                                        </button> :
                                                                            < button type="button"
                                                                                className={(comment.inspectionResult === 'Draft') && comment.approvalResult === null ? "DPNone" : comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                                style={{ display: InternalComments && InternalComments.isAdd === 0 ? 'none' : '' }}
                                                                                // className={comment.approvalResult === null ? "btn btn-xs btn-warning" : "btn btn-xs btn-success"} title='Approval'
                                                                                onClick={() => GetComments(comment, 'Comments')}
                                                                            >
                                                                                <i className="fa fa-check"></i>
                                                                            </button>}
                                                                        &nbsp;
                                                                        <button type="button" className="btn btn-xs btn-primary" title='View FinalList'
                                                                            style={{ display: InternalComments && InternalComments.isView === 0 ? 'none' : '' }}
                                                                            onClick={() => handleFinalInspecView(comment, 'View', '')}
                                                                        >
                                                                            <i className="fa fa-eye "></i>
                                                                        </button>
                                                                        &nbsp;

                                                                        <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-success" : "DPNone"} title='Edit FinalList'
                                                                            style={{ display: InternalComments && InternalComments.isEdit === 0 ? 'none' : '' }}
                                                                            onClick={() => handleFinalInspecView(comment, 'Edit', 'EditInspection')}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        <button type="button" className={comment.approvalResult === null ? "btn btn-xs btn-danger" : "DPNone"} title='Delete FinalList'
                                                                            style={{ display: InternalComments && InternalComments.isDelete === 0 ? 'none' : '' }}
                                                                            onClick={() => handleDeleteQAinspection(comment)}
                                                                        >
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                        &nbsp;
                                                                        <button className="btn btn-xs btn-magenta" style={{ display: InternalComments && InternalComments.isView === 0 ? 'none' : '' }} title="PDF" onClick={(e) => handlepdffunction(comment)}><i className="fa fa-file-pdf-o"></i></button>&nbsp;

                                                                    </span >



                                                                </td>}


                                                            </tr> : ""
                                                    }
                                                </>
                                            </Fragment>
                                        ))

                                    }
                                    {
                                        totalItems === 0 ?
                                            <tr>
                                                <td colSpan="50" className='norecordfound'><span>No Records Found</span></td>
                                            </tr> : ''
                                    }

                                </tbody >}
                                {
                                    Settingpopupopen === true ?

                                        <Modal show={Settingpopupopen === true} size="md" dragable backdrop="static" keyboard={false} onHide={() => setSettingpopupopen(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Report PDF
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body style={{ overflow: "auto", height: "25vh" }} align="center">
                                                <table style={{ width: "550px" }} className="table table-striped table-bordered table-hover dataTable no-footer" id="AddFollowup">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={2} style={{ width: "350px" }}>DetailName</th>
                                                            {/* <th style={{ width: "160px" }}></th> */}
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {
                                                            settings.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                <span className="input-icon icon-right">
                                                                                    {item.Name}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <label style={{ marginTop: '8px' }}>
                                                                                    <input type="checkbox" name="isSelected"
                                                                                        onChange={event => (handleSettingChange(event, index))}
                                                                                        value={item.isSelected}
                                                                                        checked={item.isSelected}
                                                                                        className="clearOpacity textdesign" />
                                                                                    <span className="text"></span>
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                    </>

                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className='btn btn-success' onClick={() => {
                                                    setTimeout(() => {
                                                        generatePDF();
                                                    }, 500);  // Delay of 500ms
                                                }}>Generate PDF</button>
                                            </Modal.Footer>

                                        </Modal> : ''
                                }
                                {
                                    Commentpopup === true ?
                                        // style={{ height: "25%" }}
                                        <Modal show={Commentpopup === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullCloseReveiw('reportcmt')}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {Taskcallback + ' Report Review'}
                                                </Modal.Title>
                                                {
                                                    getFIRemarks.length >= 1 ?
                                                        <button style={{ marginLeft: "40rem" }} type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                            onClick={() => handleDeletefinalinsPopup(inspection)}
                                                        >
                                                            <i className="fa fa-trash-o"></i>
                                                        </button>
                                                        : ''
                                                }
                                            </Modal.Header>
                                            {
                                                inspection.reviewStatus === null &&

                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th>Approval Date<span className="text-danger">*</span></th> */}
                                                            <th>Report No & Date</th>
                                                            <th> Report Review Date<span className="text-danger">*</span></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td >
                                                                <input type="text" name="ItemName"
                                                                    id="ItemName"
                                                                    value={inspection.insCreatedDate + ' - ' + inspection.qcInspectionID}
                                                                    //onChange={handleChange}
                                                                    maxLength="25"
                                                                    className={
                                                                        'form-control'
                                                                    }
                                                                    //submitted && !getItem.ItemName ? ' is-invalid' : ''
                                                                    //style={{ border: submitted && getItem.ItemName === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                    placeholder="Enter Item Name"
                                                                />
                                                            </td>
                                                            <td>
                                                                <span className="input-icon icon-right"

                                                                    style={{ width: "100%", border: Submitted && (getFIRemark.ReceivedDate === null || getFIRemark.ReceivedDate === '') ? '1px solid red' : '' }}>


                                                                    <DatePicker className="form-control" name="ReceivedDate"
                                                                        id={
                                                                            "ReceivedDate"
                                                                        }
                                                                        selected={
                                                                            getFIRemark.ReceivedDate
                                                                        }
                                                                        onChange={
                                                                            event => handleChangeRemarks(event, "ReceivedDate")
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        peekNextMonth
                                                                        //showMonthDropdown
                                                                        //showYearDropdown
                                                                        dropdownMode="scroll"
                                                                        autoComplete="off"
                                                                        customInput={<CustomInput />}
                                                                        isClearable={getFIRemark.ReceivedDate === null ? false : true}
                                                                    />
                                                                </span>
                                                            </td>
                                                        </tr >
                                                        {/* <tr> */}
                                                        <td colSpan='5'>
                                                            <th>Review Remarks<span className="text-danger">*</span></th>
                                                            <textarea
                                                                cols={110}
                                                                rows="4"
                                                                placeholder="Enter comments here..."
                                                                autoFocus onFocus={''} autoComplete="off"
                                                                maxLength="1000" name="EnterComment"
                                                                id={"EnterComment"}
                                                                value={getFIRemark.Comments}
                                                                onChange={event => handleChangeRemarks(event, "Comments")}
                                                                style={{ width: "100%", border: Submitted && (getFIRemark.Comments === '') ? '1px solid red' : '' }}
                                                            >
                                                            </textarea>
                                                        </td>
                                                        {/* </tr> */}
                                                    </tbody >
                                                </table >
                                            }

                                            <Modal.Body style={{ overflow: "auto", height: "520px" }}>
                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                    <thead>
                                                        <tr>
                                                            <th>Report Date</th>
                                                            <th> Report Review Date</th>
                                                            <th>InspectionApprovalDate </th>
                                                            <th>Approval Remark</th>
                                                            <th>ApprovedBy</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            getFIRemarks.length === 0 ?
                                                                <tr>
                                                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                </tr> :
                                                                getFIRemarks.map((Commentitem) => {
                                                                    return (

                                                                        <tr>
                                                                            <td>
                                                                                {Commentitem.createdDate}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.reportNoanddate}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.inspectionApprovalDate}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.approvalRemarks}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.createdBy}
                                                                            </td>

                                                                        </tr>

                                                                    );
                                                                })

                                                        }
                                                    </tbody>
                                                </table>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                {
                                                    inspection.reviewStatus === null &&
                                                    <Button variant="success" onClick={(event) => handleSaveApproval(event, inspection)}>
                                                        Save
                                                    </Button>
                                                }
                                            </Modal.Footer>
                                        </Modal >
                                        : ''
                                }
                                {
                                    CommentpopupNew === true ?
                                        // style={{ height: "25%" }}
                                        <Modal show={CommentpopupNew === true} size="lg" dragable backdrop="static" keyboard={false} onHide={() => handleFullCloseApproval('reportcmt')}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>
                                                    {Taskcallback + ' Report Approval'}
                                                </Modal.Title>
                                                {
                                                    getQARemarks.length >= 1 ?
                                                        <button style={{ marginLeft: "50rem" }} type="button" className="btn btn-xs btn-danger" title='Delete FinalList'
                                                            onClick={() => handleDeleteQAinsPopup(inspectionstatus)}
                                                        >
                                                            <i className="fa fa-trash-o"></i>
                                                        </button>
                                                        : ''
                                                }
                                            </Modal.Header>
                                            {
                                                inspectionstatus.approvalResult === null &&
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable" style={{ marginLeft: "14px", width: "97%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Inspection Report No & Date</th>
                                                                    <th>Inspection Approval Date<span className="text-danger">*</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td >
                                                                        <input type="text" name="ItemName"
                                                                            id="ItemName"
                                                                            value={inspectionstatus.qcInspectionID + ' - ' + inspectionstatus.insCreatedDate}
                                                                            //onChange={handleChange}
                                                                            maxLength="25"
                                                                            className={
                                                                                'form-control'
                                                                            }
                                                                            //submitted && !getItem.ItemName ? ' is-invalid' : ''
                                                                            //style={{ border: submitted && getItem.ItemName === "" ? '1px solid red' : '' }}
                                                                            autoFocus
                                                                            placeholder="Enter Item Name"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <DatePicker className="form-control" name="ReceivedDate"
                                                                            id={
                                                                                "ReceivedDate"
                                                                            }
                                                                            selected={
                                                                                getQARemark.ReceivedDate
                                                                            }
                                                                            onChange={
                                                                                event => handleChangeQARemarks(event, "ReceivedDate", 1)
                                                                            }
                                                                            dateFormat="dd/MM/yyyy"
                                                                            peekNextMonth
                                                                            //showMonthDropdown
                                                                            //showYearDropdown
                                                                            dropdownMode="scroll"
                                                                            autoComplete="off"
                                                                            customInput={<CustomInput />}
                                                                            isClearable={getQARemark.ReceivedDate === null ? false : true}
                                                                        />
                                                                    </td>
                                                                </tr>

                                                            </tbody >

                                                        </table >
                                                        <span><label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Approval Result<span className="text-danger">*</span></label></span>
                                                        <br />

                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="col-lg-12">
                                                        <div className="col-sm-4">
                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getQARemark.ApprovalResult === 1 ? '#53a93f' : '', color: getQARemark.ApprovalResult === 1 ? 'white' : '#444444' }}
                                                                onClick={event => { handleChangeQARemarks(event, 'Approvalstatus', 1) }}>Approved</button>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getQARemark.ApprovalResult === 2 ? '#f4b400' : '', color: getQARemark.ApprovalResult === 2 ? 'white' : '#444444' }}
                                                                onClick={event => { handleChangeQARemarks(event, 'Approvalstatus', 2) }}>Approved With Comments</button>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <button className={"btn btn-lg btn-block"} style={{ backgroundColor: getQARemark.ApprovalResult === 3 ? '#d73d32' : '', color: getQARemark.ApprovalResult === 3 ? 'white' : '#444444' }}
                                                                onClick={event => { handleChangeQARemarks(event, 'Approvalstatus', 3) }}>Rejected</button>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div className="col-lg-12">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label htmlFor="">
                                                                    Approval Remarks<span className="text-danger">*</span>
                                                                </label>
                                                                <br />
                                                                <textarea
                                                                    cols={110}
                                                                    rows="4"
                                                                    placeholder="Enter comments here..."
                                                                    autoFocus onFocus={''} autoComplete="off"
                                                                    maxLength="1000" name="EnterComment"
                                                                    id={"EnterComment"}
                                                                    value={getQARemark.Comments}
                                                                    onChange={event => handleChangeQARemarks(event, "Comments", 1)}
                                                                    style={{ width: "100%", border: Submitted && (getQARemark.Comments === '') ? '1px solid red' : '' }}
                                                                >
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <Modal.Body style={{ overflow: "auto", height: "520px" }}>

                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="Criticaltable">
                                                    <thead>
                                                        <tr>

                                                            <th>Approval Date</th>
                                                            <th>Approval Result</th>
                                                            <th>Inspection Approval Date</th>
                                                            <th>Inspection Report No & Date</th>
                                                            <th>Approval Remark</th>
                                                            <th>Created By</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            getQARemarks.length === 0 ?
                                                                <tr>
                                                                    <td colSpan="6" className='norecordfound'><span>No Records Found</span></td>
                                                                </tr> :
                                                                getQARemarks.map((Commentitem) => {
                                                                    return (

                                                                        <tr>
                                                                            <td>
                                                                                {Commentitem.createdDate}
                                                                            </td>
                                                                            <td className={Commentitem.approvalID === 1 ? 'text-success' : Commentitem.approvalID === 2 ? 'text-warning' : 'text-danger'}>
                                                                                <b>{Commentitem.approvalID === 1 ? 'Approved' : Commentitem.approvalID === 2 ? 'Approved With Comments' : 'Recommended for Re Audit'}</b>
                                                                            </td>

                                                                            <td>
                                                                                {Commentitem.inspectionApprovalDate}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.inspectionNoanddate}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.approvalRemarks}
                                                                            </td>
                                                                            <td>
                                                                                {Commentitem.createdBy}
                                                                            </td>

                                                                        </tr>

                                                                    );
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                {
                                                    inspectionstatus.approvalResult === null &&

                                                    <Button variant="success" onClick={(event) => handleSaveQAApproval(event, inspectionstatus)}>
                                                        Save
                                                    </Button>
                                                }

                                            </Modal.Footer>
                                        </Modal >
                                        : ''
                                }
                                <table data-role="table" data-mode="columntoggle" id="table-to-xlss"
                                    className="table table-striped table-bordered table-hover dataTable no-footer"
                                    style={{ width: "200%", display: 'none' }}>
                                    <thead>
                                        {
                                            <div style={{ display: 'inline-block' }}>
                                                {
                                                    (stateDate[0].startDate !== null && stateDate[0].endDate !== null) && (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                        <>
                                                            <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                    moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>&nbsp; AND &nbsp;

                                                            < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                    moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                        </>


                                                        :

                                                        (stateDate[0].startDate !== null && stateDate[0].endDate !== null) ?
                                                            <label><b>PO Date:</b> {(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                    moment(stateDate[0].endDate).format('DD/MM/YYYY'))}</label>
                                                            :

                                                            (stateApDate[0].startDate !== null && stateApDate[0].endDate !== null) ?
                                                                < label > <b>Ex-Fac:</b> {(stateApDate[0].startDate === null ? '' : moment(stateApDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                    ((stateApDate[0].endDate === null || !moment(stateApDate[0].endDate).isValid()) ? '' :
                                                                        moment(stateApDate[0].endDate).format('DD/MM/YYYY'))}</label> : ''

                                                }
                                            </div>
                                        }
                                        <tr>
                                            {checkboxStates.CreatedDate === true ? <th style={{ width: '3%' }}> Created Date </th> : false}
                                            {checkboxStates.EntryNo === true ? <th style={{ width: '3%' }}> Entry No </th> : false}
                                            {checkboxStates.ReportType === true ? <th style={{ width: '5%' }}> Report Type</th> : false}
                                            {checkboxStates.ReInspectionDoneAgainst === true ? <th style={{ width: '6%' }}> Re Inspection Done Against</th> : false}
                                            {checkboxStates.Supplier === true ? <th style={{ width: '3%' }}> Supplier </th> : false}
                                            {checkboxStates.Factory === true ? <th style={{ width: '4%' }}> Factory</th> : false}
                                            {checkboxStates.IDNo === true ? <th style={{ width: '7%' }}> PO/ID No</th> : false}
                                            {checkboxStates.FOIDPONo === true ? <th style={{ width: '7%' }}> FOIDPO No</th> : false}
                                            {checkboxStates.StyleName === true ? <th style={{ width: '7%' }}> Style Name</th> : false}
                                            {checkboxStates.StyleNo === true ? <th style={{ width: '5%' }}>Style No</th> : false}
                                            {checkboxStates.SKUName === true ? <th style={{ width: '3% ' }}> SKU Name</th> : false}
                                            {checkboxStates.InspectionQuantity === true ? <th style={{ width: '4%' }}> Report Quantity</th> : false}
                                            {checkboxStates.InspectionResult === true ? <th style={{ width: '3%' }}>Report Status</th> : false}
                                            {checkboxStates.CreatedBy === true ? <th style={{ width: '4%' }}>Created By</th> : false}
                                            {checkboxStates.ApprovalResult === true ? <th style={{ width: '4%' }}> Review status</th> : false}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            commentsData.map(comment => (
                                                <tr key={comment.qcInspectionID}>

                                                    {
                                                        checkboxStates.CreatedDate && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                // className="stickyone "
                                                                title={comment.insCreatedDate}>
                                                                {comment.insCreatedDate}

                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.EntryNo && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.qcInspectionID}>
                                                                {comment.qcInspectionID}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.ReportType && (

                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.reportType}>
                                                                {comment.reportType}
                                                            </td>
                                                        )

                                                    }

                                                    {
                                                        checkboxStates.ReInspectionDoneAgainst && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.reInspectionAgainst}>
                                                                {(comment.reInspectionAgainst === 0) ? '-' : comment.reInspectionAgainst}
                                                            </td>
                                                        )

                                                    }

                                                    {
                                                        checkboxStates.Supplier && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.supplierName}>
                                                                {comment.supplierName}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.Factory && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'Factory'}>
                                                                {"-"}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.CustomerShipmentRefNo && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'CustomerShipment Ref'}>
                                                                {"-"}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.IDNo && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'PO/ID No'}>
                                                                {comment.idpono}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.IDNo && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={'PO/ID No'}>
                                                                {comment.foidpono}
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.StyleName && (
                                                            <td className="cut_textCommentsreport " title={comment.styleName}>{comment.styleName}</td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.StyleNo && (
                                                            <td className="cut_textCommentsreport " title={comment.styleNo}>{comment.styleNo}</td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.SKUName && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.skuName}>
                                                                {comment.skuName}
                                                                <span>
                                                                    <span className="btn btn-success" title="SKU Wise Qty" style={{ float: "right" }}
                                                                    // onClick={() => GetSKUandQty(comment.finalInspectionID)}
                                                                    >
                                                                        <i className="fa fa-eye"></i>
                                                                    </span>
                                                                </span>
                                                            </td>
                                                        )
                                                    }

                                                    {
                                                        checkboxStates.InspectionQuantity && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionQty}>
                                                                {comment.inspectionQty}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.InspectionResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.inspectionResult}>
                                                                {comment.inspectionResult}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.CreatedBy && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.createdBy}>
                                                                {comment.createdBy}
                                                            </td>
                                                        )
                                                    }
                                                    {
                                                        checkboxStates.ApprovalResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.approvalResult}>
                                                                <span className={comment.reviewStatus === '1' ? 'success' : 'red'}> <b>{comment.reviewStatus === '1' ? 'Completed' : "Pending"}</b> </span>
                                                            </td >
                                                        )
                                                    }
                                                    {/* {
                                                        checkboxStates.ApprovalResult && (
                                                            <td
                                                                style={{ zIndex: (sizeqtypopup === true || show === true) ? '0' : '' }}
                                                                //className="stickytwo "
                                                                title={comment.approvalResult}>
                                                                <span className={comment.approvalResult === 'Approved' ? 'green' : 'red'}> <b>{comment.approvalResult === null ? '-' : comment.approvalResult + ' on ' + comment.approvalDate}</b> </span>
                                                            </td >
                                                        )
                                                    } */}

                                                    {< td className="text-center fixed-column-header-CommentReport" style={{ width: "5%", display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }}>
                                                        {/* <span style={{ padding: '2px' }}>
                                                            <span className="btn" title="Approved" onClick={() => GetComments(comment.supplierID, comment.purchaseOrderSkuID, 'Comments')}
                                                                style={{
                                                                    background: comment.currentComments === '' ? '#427fed' : 'red',
                                                                    color: 'white',
                                                                    padding: '3px 6px',
                                                                    borderRadius: '1px',
                                                                    border: comment.currentComments === '' ? '#427fed' : 'red'
                                                                }}
                                                            >
                                                                <i className="fa fa-check"></i>
                                                            </span>
                                                        </span> */}
                                                    </td>}

                                                </tr>
                                            ))

                                        }
                                        {
                                            totalItems === 0 ?
                                                <tr>
                                                    <td colSpan="22" className='norecordfound'><span>No Records Found</span></td>
                                                </tr> : ''
                                        }

                                    </tbody>
                                </table>
                            </table >
                        </div >


                    </div >


                </div >
                <PageCount onPageCount={
                    value => {
                        setITEMS_PER_PAGE(value);
                        setCurrentPage(1);
                    }
                } />
                <Pagination total={totalItems}
                    itemsPerPage={
                        parseInt(ITEMS_PER_PAGE)
                    }
                    currentPage={currentPage}
                    onPageChange={
                        page => setCurrentPage(page)
                    } />
                {
                    getIDQATask.showPopupDelete ? <SweetAlertPopup data={
                        getIDQATask.Params
                    }
                        deleteCallback={delQaCallback}
                        showpopup={true}
                        pageActionId={QAInspectionId}
                        Msg={"QA Inspection deleted successfully"} /> : null
                }
                {
                    getIDcomment.showPopupDelete ? <SweetAlertPopup data={
                        getIDcomment.Params
                    }
                        deleteCallback={delApprovalCallback}
                        showpopup={true}
                        pageActionId={QAApprovalComment}
                        Msg={"QA Inspection Approval comment deleted successfully"} /> : null
                }
                {
                    getIDcommentNew.showPopupDelete ? <SweetAlertPopup data={
                        getIDcommentNew.Params
                    }
                        deleteCallback={delApprovalCallback1}
                        showpopup={true}
                        pageActionId={QAApproval}
                        Msg={"QA Inspection Approval comment deleted successfully"} /> : null
                }
            </div >
            {loader}
        </div >
    )

};
export default InternalQAAuditInspectionEntry;