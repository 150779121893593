import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from "react-notifications-component";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import ProductFabricStructure from "../../../../services/Master/ProductFabricStructure"; // Adjust service if needed
import { useSelector } from "react-redux";

const EditProductFabricStructure = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    // const [getProductFabricStructure, setProductFabricStructure] = useState({
    //     Operation: 2,
    //     ProductFabricStructureID: 0,
    //     ProductFabricStructureName: "",
    //     Createdby: currentUser.employeeinformationID,
    // });

    const [getProductFabricStructure, setProductFabricStructure] = useState([{ ProductFabricStructureID: 0, ProductFabricStructureName: "" }]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // Pre-fill form with existing data based on props
    function BindFabricStructureValue(props) {
        let fabricStructureData = {
            ProductFabricStructureID: 0,
            ProductFabricStructureName: "",
        };
        if (props.location.state !== undefined && props.location.state.params?.length !== 0) {
            if (props.location.state.params.ProductFabricStructureID !== 0) {
                fabricStructureData.ProductFabricStructureID =
                    props.location.state.params.fabricStructureID;
                fabricStructureData.ProductFabricStructureName =
                    props.location.state.params.fabricStructureName;
            }
        }
        return fabricStructureData;
    }

    useEffect(() => {
        const fabricStructureData = BindFabricStructureValue(props);
        setProductFabricStructure(fabricStructureData);
    }, [props]);

    const PageRedirect = (action) => {
        props.history.push({
            pathname: "/ProductFabricStructureList",
            state: { message: action },
        });
    };

    const ResetOperation = (action) => {
        if (action === "Back") {
            props.history.push("/ProductFabricStructureList");
        } else {
            const fabricStructureData = BindFabricStructureValue(props); // Reload the current data
            setProductFabricStructure(fabricStructureData);
            setSubmitted(false); // Reset the submitted state
        }
    };

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify("Warning!", "warning", Msg);
    };

    const handleChange = (e) => {
        const Value = { ...getProductFabricStructure };
        if (e.target.name === "ProductFabricStructureName") {
            Value[e.target.name] = e.target.value.trim() !== "" ? e.target.value : "";
            setSubmitted(Value[e.target.name] !== "");
        }
        setProductFabricStructure(Value);
    };

    const SaveFabricStructure = (e) => {
        e.preventDefault();
        setButtonLoader(true);

        if (getProductFabricStructure.ProductFabricStructureName.trim() === "") {
            setButtonLoader(false);
            Nodify("Warning!", "warning", "Fabric Structure Name is required.");
            return;
        }
console.log(getProductFabricStructure)
        const InputData = {
            ProductFabricStructureInformation:[
                {
                    Id:getProductFabricStructure.ProductFabricStructureID,
                    Name:getProductFabricStructure.ProductFabricStructureName,
                }],
             
            Createdby: currentUser.employeeinformationID,
            // ProductFabricStructureName: getProductFabricStructure.ProductFabricStructureName.trim(),
        };

        ProductFabricStructure.InsertUpdateProductFabricStructure(InputData).then((res) => {
            var page = "Remove";
            SearchRetain(page);
            if (res.data.outputResult === "1") {
                Nodify("Success!", "success", "Fabric Structure updated successfully.");
                PageRedirect("Edit");
            } else if (res.data.outputResult === "-1") {
                Nodify("Warning!", "warning", "This Fabric Structure Name already exists.");
                InputData.ProductFabricStructureName = "";
                setProductFabricStructure(InputData);
            } else {
                Nodify("Error!", "danger", "An error occurred. Please try again.");
            }
            setButtonLoader(false);
        });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit Fabric Structure</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="ProductFabricStructureName">
                                                Fabric Structure Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter Fabric Structure Name"
                                                    id="ProductFabricStructureName"
                                                    name="ProductFabricStructureName"
                                                    value={
                                                        getProductFabricStructure.ProductFabricStructureName
                                                    }
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    style={{
                                                        border:
                                                            submitted &&
                                                            !getProductFabricStructure.ProductFabricStructureName
                                                                ? "1px solid red"
                                                                : "",
                                                    }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span
                            className="btn btn-primary"
                            onClick={() => ResetOperation("Back")}
                        >
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={ResetOperation}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={SaveFabricStructure}
                            disabled={buttonLoader}
                        >
                            <i className="fa fa-check right"></i>&nbsp;Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProductFabricStructure;
