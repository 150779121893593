
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { Redirect, useLocation, NavLink } from 'react-router-dom';
import Login from "./components/Login/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import Header from "./components/Layout/Header";
import LeftMenu from "./components/Layout/LeftMenu";
import TopMenu from "./components/Layout/TopMenu";

import AddressType from "./components/Master/Branch/BranchList";
import AddAddress from "./components/Master/Branch/AddEditBranch";
import AddBranch from "./components/Master/Branch/BranchListRegister";

import BreadCrumbs from "./components/Layout/BreadCrumbs";
import Company from "./components/Master/Company/CompanyList";
import companyRegister from "./components/Master/Company/CompanyRegister";
import City from "./components/Master/City/CityList";
import CityRegister from "./components/Master/City/City";
import CityListRegister from "./components/Master/City/CityListRegister";

import BuyerInfo from "./components/Master/BuyerInformation/BuyerInfoList";
import BuyerList from "./components/Master/BuyerInformation/BuyerList";
import BuyerRegister from "./components/Master/BuyerInformation/BuyerRegister";
import BuyerInfoRegister from "./components/Master/BuyerInformation/BuyerInfoRegister";
import MapItemsBuyer from "./components/Master/BuyerInformation/BuyerInfoMultiple";
import CommonError from "./components/Master/CommonComponent/Error404";
import SupplierList from "./components/Master/Supplier/SupplierList";
import SupplierRegister from "./components/Master/Supplier/SupplierRegister";
import FactoryInfo from "./components/Master/Supplier/FactoryInfo";
import GenderRegister from "./components/Master/Gender/GenderRegister";
import GenderList from "./components/Master/Gender/GenderList";
import GenderListRegister from "./components/Master/Gender/GenderListRegister";
import SeasonRegister from "./components/Master/Season/SeasonRegister";
import SeasonList from "./components/Master/Season/SeasonList";
import SeasonListRegister from "./components/Master/Season/SeasonListRegister";
import PantoneRegister from "./components/Master/Pantone/PantoneRegister";
import PantoneList from "./components/Master/Pantone/PantoneList";
import PantoneListRegister from "./components/Master/Pantone/PantoneListRegister";
import PantoneTypeRegister from "./components/Master/PantoneType/PantoneTypeRegister";
import PantoneTypeList from "./components/Master/PantoneType/PantoneTypeList";
import PantoneTypeListRegister from "./components/Master/PantoneType/PantoneTypeListRegister";
import GroupTypeList from "./components/Master/Component/GroupType/GroupTypeList";
import GrouptType from "./components/Master/Component/GroupType/GroupTypeListRegister";
import GrouptTypeEdit from "./components/Master/Component/GroupType/GroupType";
import StrikeOffTypeList from "./components/Master/Component/StrikeOffType/StrikeOffTypeList";
import StrikeOffTypeListRegister from "./components/Master/Component/StrikeOffType/StrikeOffTypeListRegister";
import StrikeOffType from "./components/Master/Component/StrikeOffType/StrikeOffType";
import TrimTypeListRegister from "./components/Master/Component/TrimType/TrimTypeListRegister";
import TrimTypeList from "./components/Master/Component/TrimType/TrimTypeList";
import TrimType from "./components/Master/Component/TrimType/TrimType";
import MeasurementPointListRegister from "./components/Master/Component/MeasurementPoints/MeasurementPointListRegister";
import MeasurementPointList from "./components/Master/Component/MeasurementPoints/MeasurementPointList";
import MeasurementPoint from "./components/Master/Component/MeasurementPoints/MeasurementPoint";
import CategoryList from "./components/Master/Component/Category/CategoryList";
import CategoryListRegister from "./components/Master/Component/Category/CategoryListRegister";
import CategoryRegister from "./components/Master/Component/Category/CategoryRegister";
import SizeList from "./components/Master/Component/Size/SizeList";
import SizeListRegister from "./components/Master/Component/Size/SizeListRegister";
import SizeRegister from "./components/Master/Component/Size/SizeRegister";
import FabricList from "./components/Master/Component/FabricDetails/FabricList";
import FabricListRegister from "./components/Master/Component/FabricDetails/FabricListRegister";
import FabricRegister from "./components/Master/Component/FabricDetails/FabricRegister";
import SKUList from "./components/Style/SKU/SKUList";
import AddEditSKU from "./components/Style/SKU/SKU";
import ProgramList from "./components/Style/Program/ProgramList";
import AddEditProgram from "./components/Style/Program/Program";
import StyleList from "./components/Style/StyleList";
import StyleListRegister from "./components/Style/StyleListRegister";
import StrikeOffList from "./components/Style/StrikeOff/MasterStrikeOffList";
import StrikeOffListRegister from "./components/Style/StrikeOff/StrikeOffListRegister";
import PickFromMaster from "./components/Style/StrikeOff/PickFromMaster";
import TrimList from "./components/Style/Trim/MasterTrimList";
import TrimRegister from "./components/Style/Trim/MasterTrim";
import TrimPickFromMaster from "./components/Style/Trim/TrimPickFromMaster";
import CurrencyList from "./components/Master/Currency/CurrencyList"
import EditCurrency from "./components/Master/Currency/CurrencySingleAdd"
import AddCurrency from "./components/Master/Currency/CurrencyMultipleAdd"
import PurchaseOrder from "./components/PurchaseOrder/PurchaseOrder";
import PurchaseOrderList from "./components/PurchaseOrder/PurchaseOrderList";
import RouteList from "./components/Route/RouteList";
import RouteRegister from "./components/Route/RouteRegister";
import TaskList from "./components/Master/Task Info/TaskList";
import TaskAdd from "./components/Master/Task Info/TaskAdd";
import TaskEdit from "./components/Master/Task Info/TaskEdit";
import TaskTypeList from "./components/Master/Task Info/TaskTypeList";
import TaskTypeAdd from "./components/Master/Task Info/TaskTypeAdd";
import TaskTypeEdit from "./components/Master/Task Info/TaskTypeEdit";
import RouteEdit from "./components/Route/RouteEdit";
import TNAList from "./components/TNA/TNAList";
import TNARegister from "./components/TNA/TNARegister";
import TNAEdit from "./components/TNA/TNAEdit";
import TNAView from "./components/TNA/TNAView";
import TNAReport from "./components/TNA/TNAReport";
import RoleList from "./components/Master/Component/Role/RoleList";
import RoleListRegister from "./components/Master/Component/Role/RoleListRegister";
import UserRightsList from "./components/Master/Component/UserRights/UserRightsList";
import UserRightsRegister from "./components/Master/Component/UserRights/UserRightsRegister";
import UserRightsUpdate from "./components/Master/Component/UserRights/UpdateUserRights";
import UpdateRole from "./components/Master/Component/Role/UpdateRole";
import DepartmentList from "./components/Master/Component/Department/DepartmentList";
import DepartmentListRegister from "./components/Master/Component/Department/DepartmentListRegister";
import UpdateDepartment from "./components/Master/Component/Department/UpdateDepartment";

import SwatchList from "./components/Master/Component/SwatchMaster/SwatchMasterList"
import SwatchAdd from "./components/Master/Component/SwatchMaster/SwatchMasterMultipleAdd"
import SwatchEdit from "./components/Master/Component/SwatchMaster/SwatchMasterSingleAdd"
import FabricPartList from "./components/Master/Component/FabricPart/FabricPartList"
import FabricPartAdd from "./components/Master/Component/FabricPart/FabricPartAdd"
import FabricPartEdit from "./components/Master/Component/FabricPart/FabricPartEdit"
import ProcessTypeEdit from "./components/Master/ProcessType/ProcessTypeEdit";
import ProcessTypeAdd from "./components/Master/ProcessType/processTypeAdd";
import ProcessTypeList from "./components/Master/ProcessType/ProcessTypeList";

import MasterSizeList from "./components/Master/Component/MasterSize/MasterSizeList";
import MasterSizeAdd from "./components/Master/Component/MasterSize/MasterSizeAdd";
import MasterSizeEdit from "./components/Master/Component/MasterSize/MasterSizeEdit";

import SamGenLabdipList from "./components/SamplingGeneral/LabDip/List_Add_Edit/SamGenlLabdipList";
import SamGenLabdipAddEdit from "./components/SamplingGeneral/LabDip/List_Add_Edit/SamGenlLabdipAddEdit";
import SamGenlLabDipView from "./components/SamplingGeneral/LabDip/LabDipDetailsView/SamGenlLabDipView";
import SamGenLabdipAddTNA from "./components/SamplingGeneral/LabDip/LabDipDetailsView/SamGenLabdipAddTNA";

import SamGenStrikeOffList from "./components/SamplingGeneral/Strikeoff/List_Add_Edit/SamGenlStrikeOffList";
import SamGenStrikeOffAddEdit from "./components/SamplingGeneral/Strikeoff/List_Add_Edit/SamGenlStrikeOffAddEdit";
import SamGenlStrikeOffView from "./components/SamplingGeneral/Strikeoff/DetailViewTabs/SamGenlStrikeOffView";
import SamGenStrikeOffAddTNA from "./components/SamplingGeneral/Strikeoff/DetailViewTabs/SamGenStrikeOffAddTNA";

import SamGenTrimList from "./components/SamplingGeneral/Trim/List_Add_Edit/SamGenlTrimList";
import SamGenTrimAddEdit from "./components/SamplingGeneral/Trim/List_Add_Edit/SamGenlTrimAddEdit";
import SamGenlTrimView from "./components/SamplingGeneral/Trim/DetailViewTabs/SamGenlTrimView";
import SamGenTrimAddTNA from "./components/SamplingGeneral/Trim/DetailViewTabs/SamGenTrimAddTNA";

import SamGenlTNAReport from "./components/SamplingGeneral/SamGenlTNAReport";

import ProtoFitSmsList from "./components/Production/Samples/ProtoFitSmsList";






import SamOrdLabdipList from "./components/SamplingOrder/Labdip/SamOrdLabdipList";
import SamOrdLabDipView from "./components/SamplingOrder/Labdip/DetailsViewTabs/SamOrdLabDipView";


import SamOrdTrimList from "./components/SamplingOrder/Trim/SamOrdTrimList";
import SamOrdTrimView from "./components/SamplingOrder/Trim/DetailsViewTabs/SamOrdTrimView";

import SamOrdStrikeOffList from "./components/SamplingOrder/StrikeOff/SamOrdStrikeOffList";
import SamOrdStrikeOffView from "./components/SamplingOrder/StrikeOff/DetailsViewTabs/SamOrdStrikeOffView";
import CourierList from './components/CourierTracker/CourierList';
import CourierTracker from './components/CourierTracker/AddCourier';
import StyleMail from './components/Style/Email/Email';

import YarnList from "./components/Master/Component/YarnType/YarnList";
import YarnRegsiter from "./components/Master/Component/YarnType/YarnRegister";
import YarnListRegister from "./components/Master/Component/YarnType/YarnlistRegister";

import AgencyList from "./components/Master/Component/Agency/AgencyList";
import AgencyRegister from "./components/Master/Component/Agency/AgencyRegister";
import EditAgency from './components/Master/Component/Agency/EditAgency';

import QACommentPointList from "./components/Master/Component/QA/CommentPointList";
import QACommentPointRegister from "./components/Master/Component/QA/CommentPointRegister";
import QACommentPointEdit from "./components/Master/Component/QA/CommentPointEdit";

import SamOrdSamplesList from "./components/SamplingOrder/Samples/SamOrdSamplesList";
import SamOrdSamplesView from "./components/SamplingOrder/Samples/DetailsViewTabs/SamOrdSamplesView";

import SamOrdLabTestsList from "./components/SamplingOrder/LabTests/SamOrdLabTestsList";
import SamOrdLabTestsView from "./components/SamplingOrder/LabTests/DetailViewTabs/SamOrdLabTestsView";

import CommentsReport from './components/Reports/CommentStyle';
import TNADetailsReport from './components/Reports/TNADetailsReport'

import ScheduleReport from './components/Reports/ScheduleReport'
import ScheduleReportDetail from './components/Reports/ScheduleReportDetail'
import ScheduleReportDetailMail from './components/Reports/ScheduleReportDetailMail'


import InspectionQCMain from './components/Production/Inspection_Quality Control/InspectionQC/InspectionQCMain';





import StateList from "./components/Master/StateCountry/StatecountryList"
import AddState from "./components/Master/StateCountry/InsertStateCountry"
import EditState from "./components/Master/StateCountry/InsertUpdateStatecountry"

import Workmanship from './components/Master/WorkmanshipDetails/WorkmanshipList'
import AddWorkmanship from './components/Master/WorkmanshipDetails/WorkmanshipAdd'
import EditWorkmanship from "./components/Master/WorkmanshipDetails/WorkmanshipEdit"
import DefectsList from "./components/Master/Defects/DefectsList";
import DefectsAdd from "./components/Master/Defects/DefectsAdd";
import DefectEdit from "./components/Master/Defects/DefectEdit";
import ItemList from "./components/Master/ItemMaster/ItemList";
import ItemEdit from "./components/Master/ItemMaster/ItemEdit";
import ItemAdd from "./components/Master/ItemMaster/ItemAdd"
import QAAdd from "./components/Master/QA/QAAdd";
import QAEdit from "./components/Master/QA/QAEdit";
import QAList from "./components/Master/QA/QAList";

import WorkmanshipGroup from "./components/Master/Workmanshpgroup/WorkmangroupList"
import AddWorkmanshipgroup from "./components/Master/Workmanshpgroup/Addworkmangroup"
import Editworkmangroup from "./components/Master/Workmanshpgroup/Editworkmangroup";
import AddTemplate from "./components/Master/Template/AddTemplate";
import WarehouseList from "./components/Master/Warehouse/WarehouseList";
import TemplateList from "./components/Master/Template/TemplateList";
import EditTemplate from "./components/Master/Template/EditTemplate";
import AddWarehouse from "./components/Master/Warehouse/Addwarehouse";
import Editwarehouse from "./components/Master/Warehouse/Editwarehouse";
import PackageItemList from "./components/Master/packageItem/PackageItemlist";
import AddPackageItem from "./components/Master/packageItem/packageItemAdd";
import EditPackageItem from "./components/Master/packageItem/PackageitemEdit";
import CSRDetailsAdd from "./components/Master/CSR Master/CSRDetailsAdd";
import CSRDetailsList from "./components/Master/CSR Master/CSRDetailsList";
import CSRDetailsEdit from "./components/Master/CSR Master/CSRDetailsEdit";
import CSRDropdownAdd from "./components/Master/CSR Master/CSRDropdownAdd";
import CSRDropdownList from "./components/Master/CSR Master/CSRDropdownList";
import CSRDropdownEdit from "./components/Master/CSR Master/CSRDropdownEdit";
import CSRTemplateAdd from "./components/Master/CSR Master/CSRTemplateAdd";
import CSRTemplateList from "./components/Master/CSR Master/CSRTemplateList";
import CSRTemplateEdit from "./components/Master/CSR Master/CSRTemplateEdit";
import AddInspection from "./components/Production/Inspection_Quality Control/InspectionQC/AddInspection";
import CreateFinalInspection from './components/Production/Inspection_Quality Control/FinalInspection/CreateFinalInspection';
import AqlList from "./components/Master/AQL/AqlList";
import TestingReportDetailAdd from "./components/Master/AQL/TestingReportDetailAdd";
import TestingReportDetailList from "./components/Master/AQL/TestingReportDetailList";
import TestingReportDetailEdit from "./components/Master/AQL/TestingReportDetailEdit";
import TestingReportTemplateAdd from "./components/Master/AQL/TestingReportTemplateAdd";
import TestingReportTemplateList from "./components/Master/AQL/TestingReportTemplateList";
import TestingReportTemplateEdit from "./components/Master/AQL/TestingReportTemplateEdit";

import AddAQLLsit from "./components/Master/AQL/AddAQLList";
import EditAqlList from './components/Master/AQL/EditAqlList'
import BuyCusWarehouseAdd from "./components/Master/BuyerCustomerWarehouse/BuyCusWarehouseAdd";
import BuyCusWarehouseList from "./components/Master/BuyerCustomerWarehouse/BuyCusWarehouseList";
import BuyCusWarehouseEdit from "./components/Master/BuyerCustomerWarehouse/BuyCusWarehouseEdit";
import AQLFinalInspectionList from "./components/Master/AQLFinalinspection/AqlFinalInspectionlist";
import AQLInspectionAdd from "./components/Master/AQLFinalinspection/AddAqlFinalInspection";
import EditAqlFinalInspection from "./components/Master/AQLFinalinspection/EditAqlFinalInspection";

import PackageImageList from "./components/Master/PackageImage/PackageImageList";
import AddPackageimage from "./components/Master/PackageImage/AddPackageimage";
import EditPackageImage from "./components/Master/PackageImage/EditPackageImage";

import PackingTemplateList from "./components/Master/PackingTemplate/PackingTemplateList";
import AddPackingTemplate from "./components/Master/PackingTemplate/AddPackingTemplate";
import EditPackingTemplate from "./components/Master/PackingTemplate/EditPackingTemplate";
import FinalInspection from './components/Production/Inspection_Quality Control/FinalInspection/FinalInspection';
import ToleranceList from "./components/Production/Inspection_Quality Control/Tolerance/ToleranceList";
import ToleranceAdd from "./components/Production/Inspection_Quality Control/Tolerance/ToleranceAdd";
import ToleranceEdit from "./components/Production/Inspection_Quality Control/Tolerance/ToleranceEdit";
import ProtoFitViewTab from "./components/Production/Samples/DetailsViewTabs/ProtoFitViewTab";
import AddInternalQcinspection from "./components/Production/QC _internal_Inspection/AddQCinternalinspection";
import StyleQCNotes from './components/Style/QCNotes/qcnotes';
import TestNameList from "./components/Master/Testing/TestName/TestNameList";
import TestNameRegister from "./components/Master/Testing/TestName/TestNameRegister";
import TestNameEdit from "./components/Master/Testing/TestName/TestNameEdit";
import TestItemList from "./components/Master/Testing/TestItem/TestItemList";
import TestItemRegister from "./components/Master/Testing/TestItem/TestItemRegister";
import TestItemEdit from "./components/Master/Testing/TestItem/TestItemEdit";

import TestFieldList from "./components/Master/Testing/TestField/TestFieldList";
import TestFieldRegister from "./components/Master/Testing/TestField/TestFieldRegister";
import TestFieldEdit from "./components/Master/Testing/TestField/TestFieldEdit";
import TestStandardList from "./components/Master/Testing/TestStandard/TestStandardList";
import TestStandardRegister from "./components/Master/Testing/TestStandard/TestStandardRegister";
import MappingMasterList from "./components/Master/Testing/MappingMaster/MappingMasterList";
import AddMappingMaster from "./components/Master/Testing/MappingMaster/AddMappingMaster";
import TestStandardEdit from "./components/Master/Testing/TestStandard/TestStandardEdit";
import ColorDepthList from "./components/Master/Brand/Color/ColorDepthList";
import ColorDepthRegister from "./components/Master/Brand/Color/ColorDepthRegister";
import ColorDepthEdit from "./components/Master/Brand/Color/ColorDepthEdit";
import BrandWiseStandardList from "./components/Master/Testing/BrandwiseMaster/BrandWiseList";
import BrandWiseStandardRegister from "./components/Master/Testing/BrandwiseMaster/BrandwiseRegister";
import BrandStandardFabric from "./components/Master/Testing/BrandwiseMaster/BrandStandardFabric";
import BrandStandardYarn from "./components/Master/Testing/BrandwiseMaster/BrandStandardYarn";
import BrandStandardGarment from "./components/Master/Testing/BrandwiseMaster/BrandStandardGarment";
import BrandStandardStrikeOff from "./components/Master/Testing/BrandwiseMaster/BrandStandardStrikeOff";
import BrandStandardTrims from "./components/Master/Testing/BrandwiseMaster/BrandStandardTrims";
import EditiMappingMaster from "./components/Master/Testing/MappingMaster/EditiMappingMaster";
import TestingParent from './components/Production/Testing/TestingParent';
import AlignmentAdd from "./components/Master/Testing/AlignmentMaster/AlignmentAdd";
import BrandwiseEdit from "./components/Master/Testing/BrandwiseMaster/BrandwiseEdit";
import EditBrandStandardFabric from './components/Master/Testing/BrandwiseMaster/EditBrandwiseMaster/EditBrandStandardFabric';
import EditBrandStandardYarn from './components/Master/Testing/BrandwiseMaster/EditBrandwiseMaster/EditBrandStandardYarn';
import EditBrandStandardGarment from './components/Master/Testing/BrandwiseMaster/EditBrandwiseMaster/EditBrandStandardGarment';
import EditBrandStandardStrikeOff from './components/Master/Testing/BrandwiseMaster/EditBrandwiseMaster/EditBrandStandardStrikeOff';
import EditBrandStandardTrims from './components/Master/Testing/BrandwiseMaster/EditBrandwiseMaster/EditBrandStandardTrims';
import AlignmentList from "./components/Master/Testing/AlignmentMaster/AlignmentList";
import AlignmentEdit from "./components/Master/Testing/AlignmentMaster/AlignmentEdit";
import createTesting from "./components/Production/Inspection_Quality Control/Testing/createTesting";
import TestingDetails from "./components/Production/Testing/TestingDetails";
import TestEntries from "./components/Production/Testing/TestEntries";
import Retest from "./components/Production/Testing/Retest";

//import ReportModuleCategoryList from "./components/Master/CustomReport/ReportModuleCategoryList";
import ReportModuleCategoryList from "./components/Master/Component/CustomReport/ReportModuleCategory/ReportModuleCategoryList";
import ReportCategoryListRegister from "./components/Master/Component/CustomReport/ReportModuleCategory/ReportCategoryListRegister";
import EditReportModuleCategory from "./components/Master/Component/CustomReport/ReportModuleCategory/EditReportModuleCategory";
import ReportTemplateList from "./components/Master/Component/CustomReport/ReportTemplate/ReportTemplateList";
import ReportTemplateListRegister from "./components/Master/Component/CustomReport/ReportTemplate/ReportTemplateListRegister";
import EditReportTemplate from "./components/Master/Component/CustomReport/ReportTemplate/EditReportTemplate";
import ColumnInformationList from "./components/Master/Component/CustomReport/ColumnInformation/ColumnInformationList";
import ColumnInformationListRegister from "./components/Master/Component/CustomReport/ColumnInformation/ColumnInformationListRegister";
import EditColumnInformation from "./components/Master/Component/CustomReport/ColumnInformation/EditColumnInformation";

// Master  -> Product -> FabricType 
import ProductFabricTypeList from "./components/Master/Product/FabricType/ProductFabricTypeList";
import AddProductFabricType from "./components/Master/Product/FabricType/AddProductFabricType";
import EditProductFabricType from "./components/Master/Product/FabricType/EditProductFabricType";
              
// Master  -> Product -> ContextSpecification
import ProductContextSpecificationList from "./components/Master/Product/ContextSpecification/ProductContextSpecificationList";
import AddProductContextSpecification from "./components/Master/Product/ContextSpecification/AddProductContextSpecification";
import EditProductContextSpecification from "./components/Master/Product/ContextSpecification/EditProductContextSpecification";

// Master  -> Product -> FabricStructure
import ProductFabricStructureList  from "./components/Master/Product/FabricStructure/ProductFabricStructureList";
import AddProductFabricStructure from "./components/Master/Product/FabricStructure/AddProductFabricStructure";
import EditProductFabricStructure from "./components/Master/Product/FabricStructure/EditProductFabricStructure";


// Master  -> Product -> AdditionalInfo
import ProductAdditionalInfoList from "./components/Master/Product/AdditionalInfo/ProductAdditionalInfoList";
import AddProductAdditionalInfo from "./components/Master/Product/AdditionalInfo/AddProductAdditionalInfo";
import EditProductAdditionalInfo from "./components/Master/Product/AdditionalInfo/EditProductAdditionalInfo";


const App = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const path = window.location.href;
    let IsLogin = (path.includes("/login") || path.includes("/Login")) ? true : false;

    //temp solution
    if (IsLogin === false) {
        IsLogin = path === ("http://localhost:8081/") ? true : false;
    }
    if (IsLogin === false) {
        IsLogin = path === ("http://localhost:8082/") ? true : false;
    }
    if (IsLogin === false) {
        IsLogin = path === ("http://localhost:8081/Login") ? true : false;
    }
    if (IsLogin === false) {
        IsLogin = path === ("http://localhost:8082/Login") ? true : false;
    }
    if (IsLogin === false) {
        IsLogin = path === ("http://icubenew.sgssys.info/") ? true : false;
    }
    if (IsLogin === false) {
        IsLogin = path === ("http://plm.cubefashions.com/") ? true : false;
    }


    window.$APIBaseURL = "https://localhost:44311/"; // Local host
    //  window.$APIBaseURL = "http://192.168.10.149:3440/"; // Karthik
    // window.$APIBaseURL = "http://192.168.10.189/"; // Gowtham
    //    window.$APIBaseURL = "http://icubenew-api.sgssys.info/"; //New System  API
     window.$APIBaseURL = "http://apinew.cubefashions.com/"; //PLM API


    // to hide the layout when user not logged in
    let ValidLogin = false;
    let PageContentClass = false;
    if (currentUser && window.location.href.split("/").pop().toLowerCase() != "login" && window.location.href.split("/").pop() != "") {
        // if (currentUser) {
        PageContentClass = currentUser.outputResult === "0" ? 'page-content' : '';
        ValidLogin = currentUser.outputResult === "1" ? true : false;
        if (PageContentClass) { ValidLogin = false; }
    }
    useEffect(() => {
        const handlePopstate = () => {
            // Clear the local storage when the back button is clicked
            localStorage.removeItem("InspectionID");
            localStorage.removeItem("TestingInspectionID");
            localStorage.removeItem("QCInspectionID");
            localStorage.removeItem("value");
            localStorage.removeItem("Ischecked");
            localStorage.removeItem("CompanySubbuyID");
        };

        // Attach the event listener
        window.addEventListener('popstate', handlePopstate);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);
    // else {
    //     currentUser.outputResult = 0;
    //     ValidLogin = false;
    // }

    useEffect(() => {
        // If you remove this zoom size please adjust the calculation for the table width in purchase order and measurement pages.
        document.body.style.zoom = "85%";
        history.listen((location) => {
            dispatch(clearMessage()); // clear message when changing location
        });
    }, [dispatch, currentUser]);


    let Aliespath = window.location.href.split('/').pop();
    let local = localStorage
    if (local.length === 0 && Aliespath !== 'forgotpassword') {
        history.push("/login");
        //window.location.reload();
    }

    return (
        <Router history={history}>
            <div> {
                ValidLogin && (
                    <TopMenu />)
            }
                <div className="main-container container-fluid">
                    <div className="page-container">
                        {

                        }
                        <div className={PageContentClass}>
                            {
                                ValidLogin && (
                                    <BreadCrumbs></BreadCrumbs>
                                )
                            }
                            <Switch>
                                {/* {(ValidLogin && (currentUser.employeeinformationID === 38 || currentUser.employeeinformationID === 67 || currentUser.employeeinformationID === 76)) && (
                                    <Route exact path={["/", "/Company"]} component={Company} />
                                )}
                                {(ValidLogin && (currentUser.employeeinformationID !== 38 && currentUser.employeeinformationID !== 67 && currentUser.employeeinformationID !== 76)) && (
                                    <Route exact path={["/", "/StyleList"]} component={StyleList} />
                                )} */}
                                {!ValidLogin && (
                                    <Route exact path={["/", "/login"]} component={Login} />
                                )}
                                <Route exact path="/Header" component={Header} />
                                <Route exact path="/Login" component={Login} />
                                <Route exact path="/forgotpassword" component={ForgotPassword} />
                                <Route exact path="/LeftMenu" component={LeftMenu} />
                                <Route exact path="/Dashboard" component={Dashboard} />
                                <Route exact path="/Branch" component={AddressType} />
                                <Route exact path="/addBranch" component={AddBranch} />
                                <Route exact path="/editBranch" component={AddAddress} />
                                <Route exact path="/Company" component={Company} />
                                <Route exact path="/companyRegister" component={companyRegister} />
                                <Route exact path="/cityList" component={City} />
                                <Route exact path="/addCity" component={CityListRegister} />
                                <Route exact path="/cityRegister" component={CityRegister} />
                                <Route exact path="/MapBuyer" component={BuyerInfo} />
                                <Route exact path="/Buyer" component={BuyerList} />
                                <Route exact path="/BuyerRegister" component={BuyerRegister} />
                                <Route exact path="/MapBuyerInfoRegister" component={BuyerInfoRegister} />
                                <Route exact path="/MapItemsBuyer" component={MapItemsBuyer} />
                                <Route exact path="/Error404" component={CommonError} />
                                <Route exact path="/SupplierList" component={SupplierList} />
                                <Route exact path="/SupplierRegister" component={SupplierRegister} />
                                <Route exact path="/GenderList" component={GenderList} />
                                <Route exact path="/AddGender" component={GenderListRegister} />
                                <Route exact path="/EditGender" component={GenderRegister} />
                                <Route exact path="/SeasonList" component={SeasonList} />
                                <Route exact path="/AddSeason" component={SeasonListRegister} />
                                <Route exact path="/EditSeason" component={SeasonRegister} />
                                <Route exact path="/EditPantone" component={PantoneRegister} />
                                <Route exact path="/AddPantone" component={PantoneListRegister} />
                                <Route exact path="/PantoneList" component={PantoneList} />
                                <Route exact path="/EditTypePantone" component={PantoneTypeRegister} />
                                <Route exact path="/AddTypePantone" component={PantoneTypeListRegister} />
                                <Route exact path="/TypePantoneList" component={PantoneTypeList} />
                                <Route exact path="/FactoryInfo" component={FactoryInfo} />
                                <Route exact path="/AddGroupType" component={GrouptType} />
                                <Route exact path="/GroupTypeList" component={GroupTypeList} />
                                <Route exact path="/EditGroupType" component={GrouptTypeEdit} />
                                <Route exact path="/StrikeOffTypeList" component={StrikeOffTypeList} />
                                <Route exact path="/AddStrikeOffType" component={StrikeOffTypeListRegister} />
                                <Route exact path="/EdiStrikeOffType" component={StrikeOffType} />
                                <Route exact path="/AddTrimType" component={TrimTypeListRegister} />
                                <Route exact path="/TrimTypeList" component={TrimTypeList} />
                                <Route exact path="/EditTrimType" component={TrimType} />
                                <Route exact path="/AddMeasurementPoint" component={MeasurementPointListRegister} />
                                <Route exact path="/MeasurementPointList" component={MeasurementPointList} />
                                <Route exact path="/EditMeasurementPoint" component={MeasurementPoint} />
                                <Route exact path="/CategoryList" component={CategoryList} />
                                <Route exact path="/AddCategory" component={CategoryListRegister} />
                                <Route exact path="/EditCategory" component={CategoryRegister} />
                                <Route exact path="/SizeList" component={SizeList} />
                                <Route exact path="/AddSize" component={SizeListRegister} />
                                <Route exact path="/EditSize" component={SizeRegister} />
                                <Route exact path="/FabricList" component={FabricList} />
                                <Route exact path="/AddFabric" component={FabricListRegister} />
                                <Route exact path="/EditFabric" component={FabricRegister} />
                                <Route exact path="/ProgramList" component={ProgramList} />
                                <Route exact path="/AddEditProgram" component={AddEditProgram} />
                                <Route exact path="/AddEditSKU" component={AddEditSKU} />
                                <Route exact path="/SKUList" component={SKUList} />
                                <Route exact path="/StyleList" component={StyleList} />
                                <Route exact path="/StyleListRegister" component={StyleListRegister} />
                                <Route exact path="/StrikeOffList" component={StrikeOffList} />
                                <Route exact path="/AddStrikeOff" component={StrikeOffListRegister} />
                                <Route exact path="/StrikeOffPickFromMaster" component={PickFromMaster} />
                                <Route exact path="/TrimList" component={TrimList} />
                                <Route exact path="/TrimRegister" component={TrimRegister} />
                                <Route exact path="/TrimPickFromMaster" component={TrimPickFromMaster} />
                                <Route exact path="/CurrencyList" component={CurrencyList} />
                                <Route exact path="/EditCurrency" component={EditCurrency} />
                                <Route exact path="/AddCurrency" component={AddCurrency} />
                                <Route exact path="/PurchaseOrder" component={PurchaseOrder} />
                                <Route exact path="/PurchaseOrderList" component={PurchaseOrderList} />
                                <Route exact path="/RouteList" component={RouteList} />
                                <Route exact path="/RouteRegister" component={RouteRegister} />
                                <Route exact path="/ListTask" component={TaskList} />
                                <Route exact path="/SaveTask" component={TaskAdd} />
                                <Route exact path="/UpdateTask" component={TaskEdit} />
                                <Route exact path="/TaskTypeList" component={TaskTypeList} />
                                <Route exact path="/TaskTypeAdd" component={TaskTypeAdd} />
                                <Route exact path="/TaskTypeEdit" component={TaskTypeEdit} />
                                <Route exact path="/RouteEdit" component={RouteEdit} />
                                <Route exact path="/TNAList" component={TNAList} />
                                <Route exact path="/TNARegister" component={TNARegister} />
                                <Route exact path="/TNAEdit" component={TNAEdit} />
                                <Route exact path="/TNAView" component={TNAView} />
                                <Route exact path="/TNAReport" component={TNAReport} />
                                <Route exact path="/RoleList" component={RoleList} />
                                <Route exact path="/AddRole" component={RoleListRegister} />
                                <Route exact path="/UserRightsList" component={UserRightsList} />
                                <Route exact path="/UserRightsRegister" component={UserRightsRegister} />
                                <Route exact path="/UserRightsUpdate" component={UserRightsUpdate} />
                                <Route exact path="/EditRole" component={UpdateRole} />
                                <Route exact path="/DepartmentList" component={DepartmentList} />
                                <Route exact path="/AddDepartment" component={DepartmentListRegister} />
                                <Route exact path="/EditDepartment" component={UpdateDepartment} />
                                <Route exact path="/SwatchMasterList" component={SwatchList} />
                                <Route exact path="/SwatchMasterAdd" component={SwatchAdd} />
                                <Route exact path="/SwatchMasterEdit" component={SwatchEdit} />
                                <Route exact path="/FabricPartList" component={FabricPartList} />
                                <Route exact path="/FabricPartAdd" component={FabricPartAdd} />
                                <Route exact path="/FabricPartEdit" component={FabricPartEdit} />
                                <Route exact path="/ProcessTypeAdd" component={ProcessTypeAdd} />
                                <Route exact path="/ProcessTypeList" component={ProcessTypeList} />
                                <Route exact path="/ProcessTypeEdit" component={ProcessTypeEdit} />
                                <Route exact path="/MasterSizeList" component={MasterSizeList} />
                                <Route exact path="/MasterSizeEdit" component={MasterSizeEdit} />
                                <Route exact path="/MasterSizeAdd" component={MasterSizeAdd} />
                                <Route exact path="/SamGenlLabdipList" component={SamGenLabdipList} />
                                <Route exact path="/SamGenlLabdipAddEdit" component={SamGenLabdipAddEdit} />
                                <Route exact path="/SamGenlLabDipView" component={SamGenlLabDipView} />
                                <Route exact path="/SamGenlStrikeOffList" component={SamGenStrikeOffList} />
                                <Route exact path="/SamGenlStrikeOffAddEdit" component={SamGenStrikeOffAddEdit} />
                                <Route exact path="/SamGenlTrimList" component={SamGenTrimList} />
                                <Route exact path="/SamGenlTrimAddEdit" component={SamGenTrimAddEdit} />
                                <Route exact path="/SamGenlTrimView" component={SamGenlTrimView} />
                                <Route exact path="/SamGenlTrimAddTNA" component={SamGenTrimAddTNA} />
                                <Route exact path="/SamOrdLabdipList" component={SamOrdLabdipList} />
                                <Route exact path="/SamOrdLabDipView" component={SamOrdLabDipView} />
                                <Route exact path="/SamOrdTrimList" component={SamOrdTrimList} />
                                <Route exact path="/SamOrdTrimView" component={SamOrdTrimView} />
                                <Route exact path="/SamOrdStrikeOffList" component={SamOrdStrikeOffList} />
                                <Route exact path="/SamOrdStrikeOffView" component={SamOrdStrikeOffView} />
                                <Route exact path="/SamGenLabdipAddTNA" component={SamGenLabdipAddTNA} />
                                <Route exact path="/SamGenlStrikeOffView" component={SamGenlStrikeOffView} />
                                <Route exact path="/SamGenStrikeOffAddTNA" component={SamGenStrikeOffAddTNA} />
                                <Route exact path="/StylEmail" component={StyleMail} />
                                <Route exact path="/CourierList" component={CourierList} />
                                <Route exact path="/AddCourierTracker" component={CourierTracker} />
                                <Route exact path="/YarnList" component={YarnList} />
                                <Route exact path="/EditYarn" component={YarnRegsiter} />
                                <Route exact path="/AddYarn" component={YarnListRegister} />
                                <Route exact path="/AgencyList" component={AgencyList} />
                                <Route exact path="/AgencyRegister" component={AgencyRegister} />
                                <Route exact path="/EditAgency" component={EditAgency} />
                                <Route exact path="/SamGenlTNAReport" component={SamGenlTNAReport} />
                                {/* <Route exact path="/SamplesList" component={SamOrdSamplesList} />
                                <Route exact path="/SamplesView" component={SamOrdSamplesView} /> */}
                                <Route exact path="/LabTestsList" component={SamOrdLabTestsList} />
                                <Route exact path="/LabTestsView" component={SamOrdLabTestsView} />
                                <Route exact path="/CommentsReport" component={CommentsReport} />
                                <Route exact path="/TNADetailsReport" component={TNADetailsReport} />
                                <Route exact path="/ScheduleReportList" component={ScheduleReport} />
                                <Route exact path="/ScheduleReportDetail" component={ScheduleReportDetail} />
                                <Route exact path="/ScheduleReportDetailMail" component={ScheduleReportDetailMail} />
                                <Route exact path="/InternalQAAuditMain" component={InspectionQCMain} />
                                <Route exact path="/StateList" component={StateList} />
                                <Route exact path="/AddState" component={AddState} />
                                <Route exact path="/EditState" component={EditState} />
                                <Route exact path="/WorkmanshipList" component={Workmanship} />
                                <Route exact path="/AddWorkmanship" component={AddWorkmanship} />
                                <Route exact path="/EditWorkmanship" component={EditWorkmanship} />
                                <Route exact path="/DefectsList" component={DefectsList} />
                                <Route exact path="/AddDefect" component={DefectsAdd} />
                                <Route exact path="/EditDefect" component={DefectEdit} />
                                <Route exact path="/itemList" component={ItemList} />
                                <Route exact path="/editItems" component={ItemEdit} />
                                <Route exact path="/itemAdd" component={ItemAdd} />
                                <Route exact path="/QACommentAdd" component={QAAdd} />
                                <Route exact path="/QACommentEdit" component={QAEdit} />
                                <Route exact path="/QACommentList" component={QAList} />
                                <Route exact path="/WorkmanshipGroupList" component={WorkmanshipGroup} />
                                <Route exact path="/AddWorkmanshipgroup" component={AddWorkmanshipgroup} />
                                <Route exact path="/EditWorkmanshipgroup" component={Editworkmangroup} />
                                <Route exact path="/Templateadd" component={AddTemplate} />
                                <Route exact path="/Template" component={TemplateList} />
                                <Route exact path="/TemplateEdit" component={EditTemplate} />


                                <Route exact path="/WarehouseList" component={WarehouseList} />
                                <Route exact path="/AddWarehouse" component={AddWarehouse} />
                                <Route exact path="/EditWarehouse" component={Editwarehouse} />
                                <Route exact path="/Package" component={PackageItemList} />
                                <Route exact path="/Addpackageitem" component={AddPackageItem} />
                                <Route exact path="/Editpackageitem" component={EditPackageItem} />

                                <Route exact path="/CSRDetailsAdd" component={CSRDetailsAdd} />
                                <Route exact path="/CSRDetailsList" component={CSRDetailsList} />
                                <Route exact path="/CSRDetailsEdit" component={CSRDetailsEdit} />
                                <Route exact path="/CSRDrpDetailsAdd" component={CSRDropdownAdd} />
                                <Route exact path="/CSRDrpDetailsList" component={CSRDropdownList} />
                                <Route exact path="/CSRDrpDetailsEdit" component={CSRDropdownEdit} />
                                <Route exact path="/CSRTemplateAdd" component={CSRTemplateAdd} />
                                <Route exact path="/CSRTemplateEdit" component={CSRTemplateEdit} />
                                <Route exact path="/CSRTemplateList" component={CSRTemplateList} />
                                <Route exact path="/AQLList" component={AqlList} />
                                <Route exact path="/AddInspection" component={AddInspection} />
                                <Route exact path="/CreateFinalInspection" component={CreateFinalInspection} />


                                <Route exact path="/TestingItemAdd" component={TestingReportDetailAdd} />
                                <Route exact path="/TestingItemList" component={TestingReportDetailList} />
                                <Route exact path="/TestingItemEdit" component={TestingReportDetailEdit} />

                                <Route exact path="/TestingTemplateAdd" component={TestingReportTemplateAdd} />
                                <Route exact path="/TestingTemplateList" component={TestingReportTemplateList} />
                                <Route exact path="/TestingITemplateEdit" component={TestingReportTemplateEdit} />
                                <Route exact path='/AddAQLList' component={AddAQLLsit} />
                                <Route exact path="/EditAQLList" component={EditAqlList} />

                                <Route exact path="/BCWAdd" component={BuyCusWarehouseAdd} />
                                <Route exact path="/BCWList" component={BuyCusWarehouseList} />
                                <Route exact path="/BCWEdit" component={BuyCusWarehouseEdit} />
                                <Route exact path="/AQLInspectionList" component={AQLFinalInspectionList} />
                                <Route exact path="/AddAqlInspection" component={AQLInspectionAdd} />
                                <Route exact path="/EditAqlInspection" component={EditAqlFinalInspection} />

                                <Route excat path="/PackageImageList" component={PackageImageList} />
                                <Route excat path="/AddPackageimage" component={AddPackageimage} />
                                <Route exact path="/EditPackageimage" component={EditPackageImage} />
                                <Route exact path="/PackageGroup" component={PackingTemplateList} />
                                <Route exact path="/AddPackageTemplate" component={AddPackingTemplate} />
                                <Route exact path="/EditPackageTemplate" component={EditPackingTemplate} />
                                <Route exact path="/FinalInspection" component={FinalInspection} />

                                <Route exact path="/SamplesList" component={ProtoFitSmsList} />

                                <Route exact path="/SamplesView" component={ProtoFitViewTab} />

                                <Route exact path="/ToleranceList" component={ToleranceList} />
                                <Route exact path="/ToleranceAdd" component={ToleranceAdd} />
                                <Route exact path="/ToleranceEdit" component={ToleranceEdit} />
                                <Route exact path="/QACommentPointList" component={QACommentPointList} />
                                <Route exact path="/QACommentPointRegister" component={QACommentPointRegister} />
                                <Route exact path="/QACommentPointEdit" component={QACommentPointEdit} />
                                <Route exact path='/AddQCInspection' component={AddInternalQcinspection} />
                                <Route exact path="/StyleQCNotes" component={StyleQCNotes} />
                                <Route exact path="/TestNameList" component={TestNameList} />
                                <Route exact path="/TestNameRegister" component={TestNameRegister} />
                                <Route exact path="/TestNameEdit" component={TestNameEdit} />
                                <Route exact path="/TestItemList" component={TestItemList} />
                                <Route exact path="/TestItemRegister" component={TestItemRegister} />
                                <Route exact path="/TestItemEdit" component={TestItemEdit} />
                                <Route exact path="/TestFieldList" component={TestFieldList} />
                                <Route exact path="/TestFieldRegister" component={TestFieldRegister} />
                                <Route exact path="/TestFieldEdit" component={TestFieldEdit} />
                                <Route exact path="/TestStandardList" component={TestStandardList} />
                                <Route exact path="/TestStandardRegister" component={TestStandardRegister} />
                                <Route exact path="/MappingList" component={MappingMasterList} />
                                <Route exact path="/AddMapping" component={AddMappingMaster} />
                                <Route exact path="/EditMapping" component={EditiMappingMaster} />
                                <Route exact path="/AlignmentAdd" component={AlignmentAdd} />
                                <Route exact path="/AlignmentList" component={AlignmentList} />
                                <Route exact path="/AlignmentEdit" component={AlignmentEdit} />
                                <Route exact path="/TestStandardEdit" component={TestStandardEdit} />
                                <Route exact path="/ColorDepthList" component={ColorDepthList} />
                                <Route exact path="/ColorDepthRegister" component={ColorDepthRegister} />
                                <Route exact path="/ColorDepthEdit" component={ColorDepthEdit} />
                                <Route exact path="/BrandWiseStandardList" component={BrandWiseStandardList} />
                                <Route exact path="/BrandWiseStandardRegister" component={BrandWiseStandardRegister} />
                                <Route exact path="/BrandStandardFabric" component={BrandStandardFabric} />
                                <Route exact path="/BrandStandardYarn" component={BrandStandardYarn} />
                                <Route exact path="/BrandStandardGarment" component={BrandStandardGarment} />
                                <Route exact path="/BrandStandardStrikeOff" component={BrandStandardStrikeOff} />
                                <Route exact path="/BrandStandardTrims" component={BrandStandardTrims} />
                                <Route exact path="/TestingParent" component={TestingParent} />
                                <Route exact path="/BrandwiseEdit" component={BrandwiseEdit} />
                                <Route exact path="/EditBrandStandardFabric" component={EditBrandStandardFabric} />
                                <Route exact path="/EditBrandStandardYarn" component={EditBrandStandardYarn} />
                                <Route exact path="/EditBrandStandardGarment" component={EditBrandStandardGarment} />
                                <Route exact path="/EditBrandStandardStrikeOff" component={EditBrandStandardStrikeOff} />
                                <Route exact path="/EditBrandStandardTrims" component={EditBrandStandardTrims} />
                                <Route exact path="/createTesting" component={createTesting} />
                                <Route exact path="/TestingDetails" component={TestingDetails} />
                                <Route exact path="/TestEntries" component={TestEntries} />
                                <Route exact path="/Retest" component={Retest} />
                                <Route exact path="/ReportModuleCategoryList" component={ReportModuleCategoryList} />
                                <Route exact path="/AddReportModuleCategory" component={ReportCategoryListRegister} />
                                <Route exact path="/EditReportModuleCategory" component={EditReportModuleCategory} />
                                <Route exact path="/ReportTemplateList" component={ReportTemplateList} />
                                <Route exact path="/AddReportTemplate" component={ReportTemplateListRegister} />
                                <Route exact path="/EditReportTemplate" component={EditReportTemplate} />

                                <Route exact path="/ColumnInformationList" component={ColumnInformationList} />
                                <Route exact path="/AddColumnInformationList" component={ColumnInformationListRegister} />
                                <Route exact path="/EditColumnInformation" component={EditColumnInformation} />

                                <Route exact path="/ProductFabricTypeList" component={ProductFabricTypeList} />
                                <Route exact path="/addProductFabricType" component={AddProductFabricType} />
                                <Route exact path="/editProductFabricType" component={EditProductFabricType} />
                                
                                <Route exact path="/ProductContSpecList" component={ProductContextSpecificationList} />
                                <Route exact path="/addProductContSpec" component={AddProductContextSpecification} />
                                <Route exact path="/editProductContSpec" component={EditProductContextSpecification} />

                                <Route exact path="/ProductFabricStructureList" component={ProductFabricStructureList }/>
                                <Route exact path="/addProductFabricStructure" component={AddProductFabricStructure} />
                                <Route exact path="/editProductFabricStructure" component={EditProductFabricStructure} />

                                <Route exact path="/ProductAdditionalInfoList" component={ProductAdditionalInfoList} />
                                <Route exact path="/addProductAdditionalInfo" component={AddProductAdditionalInfo} />
                                <Route exact path="/editProductAdditionalInfo" component={EditProductAdditionalInfo} />
                                

                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
};

export default App;