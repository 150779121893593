import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { OrdLapdipProgramInfoId } from "../../../Common/PageActionNumber";
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Nodify from "../../../Common/ReactNotification"
import SearchRetain from "../../../Common/SearchRetain";
//import roleService from "../../../../services/Master/RoleService";
import CommonService from '../../../../services/Common/CommonService';
import { retrieveUserPermission, retrieveUsername } from "../../../../actions/UserPermission";
import { login } from './../../../../actions/auth';

const UserRightsList = (props) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("User Details");

    




    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];
    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "UserPermission"); }
    // End Enable Menu
    let comments = reducerState.userpermission.userpermissionList;
    const existUser = comments && comments.item1;
    let allPermissionList = comments && comments.item3;



    if (reducerState.userpermission.userpermissionList.item1 !== undefined) { comments = reducerState.userpermission.userpermissionList.item1; }
    let isLoadingRole = reducerState.isLoadingRole;
    const commentsData = useMemo(() => {
        let computedComments = comments;
        if(currentUser.empCompanyAdministrator  === 0)
        {
             computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
                o.compBuySupID === buyerId && isAllInfo === 1 && isMapped === 0));
               let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
               isAllInfo === 0 && isMapped === 1 
               //&& o.cubeEmployeeIdList != undefined
                && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
               debugger;
                if (computedCommentsBuyerDetails.length > 0) {
                computedComments = [...computedComments, ...computedCommentsBuyerDetails];
            }
    

        }
         
        if (search) {
            computedComments = computedComments.filter(comment => comment.userName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);

        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }

        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "User Details", field: "userName", sortable: true },
        // { name: "User Type", field: "compBuySupTableTypeName", sortable: true },
        {
            name: "Action", field: "", sortable: false, display:
                activeMenu[0].isEdit !== undefined && activeMenu[0].isEdit === 0 &&
                    activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];
    useEffect(() => {
        let EmpId = 0;
        dispatch(retrieveUserPermission(EmpId));
        const state1 = props.location.state;
        if (state1 !== undefined && state1 !== null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'User details updated successfully.';

            } else if (state1.message === 'Add') {
                Msg = 'User details added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            if (state1.message === 'Edit' || state1.message === 'Add') {
                dispatch(login(currentUser.empUserName, currentUser.empPassword, 0)).then((res) => {
                    //buyer and brand will be updated.
                });
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingRole) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingRole]);

    const pageRedirect = (dataParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            //dispatch(retrieveUsername(1));

            props.history.push('/UserRightsRegister', { params: [], buyerBrandPermissionDetails: allPermissionList, existUser: existUser });
        } else if (redirect === 'Edit') {

            //dispatch(retrieveUsername(2));
            dataParams.action = 'Edit';
            props.history.push(`/UserRightsRegister`, { params: dataParams, buyerBrandPermissionDetails: [], existUser: existUser });
        } else {
            let Params = {
                Operation: OrdLapdipProgramInfoId,
                Id: dataParams.empMenuPermissionId,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Username name is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        dispatch(retrieveUserPermission(0));
        setID({ showPopupDelete: false, Params: [] });
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">User Permission List</span>
                            </div>
                            <div className="widget-body" style={{ paddingBottom: '4%' }}>
                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu[0].isAdd !== undefined && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add User Permission">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>

                                    <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                        <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                        <tr key={comment.empMenuPermissionId}>
                                                            <td style={{width:'25px'}}>  
                                                                { i + 1}
                                                            </td>
                                                            <td>{comment.userName}</td>
                                                            {/* <td>{comment.compBuySupTableTypeName}</td> */}
                                                            <td style={{
                                                                display: activeMenu[0].isEdit !== undefined && activeMenu[0].isEdit === 0 &&
                                                                    activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 0 ? 'none' : ''
                                                                }}>
                                                                <span style={{ display: activeMenu[0].isEdit !== undefined && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Username' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-info btn-xs edit">
                                                                    <i className="fa fa-edit"></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu[0].isDelete !== undefined && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Username' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>

                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={OrdLapdipProgramInfoId}
                                    Msg={"User details deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default UserRightsList;