import {
    ROUTE_LOADING,
    RETRIEVE_ROUTE,
    DELETE_ROUTE,
    ROUTE_DEPENDENCY_DROPDOWN_LIST,
    ROUTE_TASK_OWNER_LIST,
    SUPPLIERID_ROUTE
} from "../actions/types";

const initialState = {
    isLoadingRoute: true,
    routeList: [],
    routeDependencyList: [],
    drpRouteTaskOwnerList: [],
    SupplierID:0
};

const routeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ROUTE_LOADING:
            return {
                ...state,
                isLoadingRoute: payload
            };
        case RETRIEVE_ROUTE:
            return { ...state, routeList: payload };
        case ROUTE_DEPENDENCY_DROPDOWN_LIST:
            return { ...state, routeDependencyList: payload };
        case DELETE_ROUTE:
            const results = state.routeList.filter(c => c.routeInformationID !== payload.routeInformationID);
            return {
                ...state,
                routeList: results
            };
        case ROUTE_TASK_OWNER_LIST:
            return { ...state, drpRouteTaskOwnerList: payload };
        case SUPPLIERID_ROUTE:
            return { ...state, SupplierID: payload };
        default:
            return state;
    }
};
export default routeReducer;