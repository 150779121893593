import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import BuyerService from "../../../services/Master/BuyerService"
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import AqlService from "../../../services/Master/AqlService";
import Aqlfinalinspection from "../../../services/Master/Aqlfinalinspection";
import styleService from "../../../services/Style/styleService"

const AQLInspectionAdd = (props) => {
    const [Inputfields, SetInputfields] = useState([{
        AqlInspectionID: 0,
        BrandID: 0,
        BrandName: '',
        InspectionStandard: '',
        InspectionLevelID: 0,
        InspectionLevel: '',
        MajorAQLID: 0,
        MajorAQL: '',
        MinorAQLID: 0,
        MinorAQL: '',
        IsSelected: 0
    }]);
    const [GetAllItemDetails, setAllItemDetails] = useState([{ Value: 0, label: '' }]);
    const [getMijorAqllist, setMijorAqllist] = useState([]);
    const [getMinorAqllist, setMinorAqllist] = useState([]);
    const [InspectionList, setInspectionList] = useState([]);
    const [getBuyerDetails, setBuyerDetails] = useState([]);
    const [getBrandDetails, setBrandDetails] = useState([]);
    const [getBuyerBrandDetails, setBuyerBrandDetails] = useState([]);
    const [ExistingList, setExistingList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const values = [...Inputfields]

     const reducerState = useSelector((state) => state);
     const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
     
    let BuyerListOptions = [];

    const optionsMajorAql = [

        { value: 1, label: '1' },
        { value: 2, label: '1.5' },
        { value: 3, label: '2.5' },
        { value: 4, label: '4' },
        { value: 5, label: '6.5' },
        { value: 6, label: '10' },
        { value: 7, label: '15' },
    ]

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }

    useEffect(() => {
        Aqlfinalinspection.GetAqlFinalInspection(0, 2).then((res) => {
            if (res.data.length > 0) {
                setExistingList(res.data)
            }
        })

        let InspectionLevel = [];
        AqlService.getAllAqlDpList(0).then((res) => {
            if (res.data) {
                res.data.map((x, index) => {
                    InspectionLevel.push({
                        label: x.inspectionLevel,
                        value: index + 1
                    })
                })
                setInspectionList(InspectionLevel)
                // setInspectionList([...new Map(InspectionLevel.sort((a, b) => b.value - a.value).map((item, index) =>
                //     [item['label'], item])).values()]);
            }
        })
        let BuyerDetail = [];
        BuyerService.LoadBuyerList(2).then((res) => {
            if (res.data) {
                // res.data.map(x => {
                //     BuyerDetail.push({
                //         label: x.name,
                //         value: x.id
                //     })
                // })

                BuyerListOptions = res.data;
                setBuyerDetails(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));

                //setBuyerDetails(BuyerDetail)
            }
        })

        styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((res) => {

            if (res.data) {
                setBrandDetails(res.data);
            }
        })

    }, [])

    function PageRedirect(Func) {
        props.history.push({ pathname: "/AQLInspectionList", state: { message: Func } });
    }

    function handleAddFields(event, index) {
       ;
        let isvalid = true;
        if (isvalid) {
            if (values[index].InspectionLevelID !== 0 && values[index].BrandID !== 0 && values[index].InspectionStandard !== '' &&
                values[index].MajorAQLID !== 0 && values[index].MinorAQLID !== 0) {
                values.push({
                    AqlInspectionID: 0, InspectionLevelID: 0, inspectionLevel: '', BrandID: 0, BrandName: '',
                    InspectionStandard: '', MajorAQL: '', MajorAQLID: 0, MinorAQLID: 0, MinorAQL: '',
                    IsSelected: 0
                });
                SetInputfields(values);
                setSubmitted(false);
            }
            else {
                Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                setSubmitted(true);
            }
        }
    };

    function handleRemoveFields(index) {

        values.splice(index, 1);

        SetInputfields(values);
    };

    async function GetMajorminorList(value) {
        let InspectionLevel = [];
        await AqlService.getAllAqlList(0).then((res) => {
            if (res.data) {
                res.data.filter(x => x.inspectionLevel === value).map((x, index) => {
                    InspectionLevel.push({
                        label: x.aql,
                        value: x.sNO
                    })
                })
                setMijorAqllist(InspectionLevel)
            }
        })

        await AqlService.getAllAqlList(0).then((res) => {
            if (res.data) {
                res.data.filter(x => x.inspectionLevel === value).map((x, index) => {
                    InspectionLevel.push({
                        label: x.aql,
                        value: x.sNO
                    })
                })
                setMinorAqllist(InspectionLevel)
            }
        })

    }

    function handleOnSelect(e, fields, index) {
       

        values.map(x => {
            x.IsSelected = 0
        })
        if (e.target.checked) {
            values[index].IsSelected = 1
        }
        else {
            values[index].IsSelected = 0
        }


        SetInputfields(values)
    }

    function handleInputChange(index, event, FieldName) {
       
        if (FieldName === "Brand") {
            if (event !== null) {
                values[index].BrandID = event.value;
                values[index].BrandName = event.label;
            } else {
                values[index].BrandID = 0;
                values[index].BrandName = ''
            }
        } else if (FieldName === "InspectionStandard") {
            values[index].InspectionStandard = event.target.value;
        } else if (FieldName === "InspectionLevel") {
            if (event !== null) {
                values[index].InspectionLevelID = event.value;
                values[index].InspectionLevel = event.label;
                //GetMajorminorList(event.label)
            } else {
                values[index].InspectionLevelID = 0;
                values[index].InspectionLevel = ''
            }
        }
        else if (FieldName === "MajorAQL") {
            if (event !== null) {
                values[index].MajorAQLID = event.value;
                values[index].MajorAQL = event.label;
            } else {
                values[index].MajorAQLID = 0;
                values[index].MajorAQL = ''
            }
        }
        else {
            if (event !== null) {
                values[index].MinorAQLID = event.value;
                values[index].MinorAQL = event.label;
            } else {
                values[index].MinorAQLID = 0;
                values[index].MinorAQL = ''
            }
        }
        SetInputfields(values);
    };

    function CheckDuplicate(index) {
        // for (var j = 0; j < (values.length); j++) {
        //     if (j !== index) {
        //         if (values[j].InspectionStandard.toLowerCase().trim() === values[index].InspectionStandard.toLowerCase().trim()) {
        //             values[index].InspectionStandard = '';
        //             SetInputfields(values);
        //             setSubmitted(true);
        //             //isvalid = false;
        //             Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
        //             return false;
        //         }
        //     }
        // }
        if (ExistingList.length > 0) {
            let Existing = !!ExistingList.find(x => x.brandID === values[index].BrandID
                && x.inspectionLevelID === values[index].InspectionLevelID && x.inspectionStandard.trim().toLowerCase() === values[index].InspectionStandard.trim().toLowerCase()
                && x.majorAQLID === values[index].MinorAQLID && x.minorAQLID === values[index].MinorAQLID)

            if (Existing) {
                values[index].BrandID = 0;
                values[index].BrandName = '';
                values[index].InspectionStandard = '';
                values[index].InspectionLevelID = 0;
                values[index].InspectionLevel = '';
                values[index].MajorAQLID = 0;
                values[index].MajorAQL = '';
                values[index].MinorAQLID = 0;
                values[index].MinorAQLID = 0;
                values[index].MinorAQL = ''
                SetInputfields(values);
                Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                return false;
            }
        }
        for (var j = 0; j < (values.length); j++) {
            if (j !== index) {
                if (values[j].BrandID === values[index].BrandID &&
                    values[j].InspectionLevelID === values[index].InspectionLevelID
                    && values[j].InspectionStandard.toLowerCase().trim() === values[index].InspectionStandard.toLowerCase().trim()
                    && values[j].MinorAQLID === values[index].MinorAQLID
                    && values[j].MajorAQLID === values[index].MajorAQLID) {
                    values[index].BrandID = 0;
                    values[index].BrandName = '';
                    values[index].InspectionStandard = '';
                    values[index].InspectionLevelID = 0;
                    values[index].InspectionLevel = '';
                    values[index].MajorAQLID = 0;
                    values[index].MajorAQL = '';
                    values[index].MinorAQLID = 0;
                    values[index].MinorAQLID = 0;
                    values[index].MinorAQL = ''
                    SetInputfields(values);
                    Nodify('Warning!', 'warning', 'These details are already exists, please enter differently');
                    return false;
                }

            }
        }
    }

    function handleDropdownChange(event, FieldName) {
        const buyervalues = [...GetAllItemDetails];
        let inputText = '';
        if (FieldName === "Buyer") {
            if (event !== null) {
                buyervalues[0].Value = event.value;
                buyervalues[0].label = event.label;
                inputText = event.value
            } else {
                buyervalues[0].Value = 0;
                buyervalues[0].label = ''
            }
            let respected_Brand = getBrandDetails.filter(x => (x.buyerID === parseInt(inputText)) && (x.buyerMapID === 1));
            setBuyerBrandDetails(respected_Brand);
        }
        if (ExistingList.length > 0) {
            let Existing = !!ExistingList.find(x => x.buyerID === GetAllItemDetails[0].Value)
            if (Existing) {
                buyervalues[0].Value = 0;
                buyervalues[0].label = 0;
                Nodify('Warning!', 'warning', 'Buyer Name already Exist');
            }
        }
        setAllItemDetails(buyervalues);
    };

    function ResetOperation(e) {
        if (e === "Back") {
            props.history.push('/AQLInspectionList');
        } else {
            window.location.reload();
        }
    }

    function SaveAqlFinalInspectiion(e) {
       ;
        setButtonLoader(true);
        let isvalid = true;
        if (GetAllItemDetails[0].Value === 0) {
            isvalid = false;
            setSubmitted(true);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please Select the Buyer');
        }
        if (values[0].InspectionLevelID === 0 || values[0].BrandID === 0 || values[0].InspectionStandard === '' ||
            values[0].MajorAQLID === 0 || values[0].MinorAQLID === 0) {
            isvalid = false;
            setSubmitted(true);
            setButtonLoader(false);
            Nodify('Warning!', 'warning', 'Please Atleast fill one AQL details');
        }
        else {
            for (var j = 0; j < (values.length); j++) {
                if (values[j].InspectionLevelID === 0 || values[j].BrandID === 0 || values[j].InspectionStandard === '' ||
                    values[j].MajorAQLID === 0 || values[j].MinorAQLID === 0) {
                    isvalid = false;
                    setSubmitted(true);
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', 'Please fill the mandantory(*) fields');
                }
            }
        }
        if (isvalid) {
            let AqlFinalinspec = []
            values.map((x) => {
                AqlFinalinspec.push({
                    AqlInspectionID: 0,
                    BrandID: x.BrandID,
                    BrandName: x.BrandName,
                    InspectionStandard: x.InspectionStandard,
                    InspectionLevelID: x.InspectionLevelID,
                    InspectionLevel: x.InspectionLevel,
                    MajorAQLID: x.MajorAQLID,
                    MajorAQL: x.MajorAQL,
                    MinorAQLID: x.MinorAQLID,
                    MinorAQL: x.MinorAQL,
                    IsSelected: x.IsSelected
                })
            })
            let Inputmaster = {
                Operation: 1, BuyerID: GetAllItemDetails[0].Value, BuyerName: GetAllItemDetails[0].label,
                AqlFinInspecInformation: AqlFinalinspec, CreatedBy: currentUser.employeeinformationID
            }
            Aqlfinalinspection.InsertUpdateAqlFinalInspectionGrd(Inputmaster).then((res) => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }




    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add AQL Inspection</h3>
                        </div>

                        <div className="widget-body">
                            <div className="row">


                                <div className="col-lg-3">
                                    <label>Buyer<span className="text-danger">*</span></label>
                                    <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                                        isDisabled={false}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        autocomplete='off'
                                        placeholder="Select Buyer Detail"
                                        styles={submitted && GetAllItemDetails[0].Value === 0 ? styles : ''}
                                        onChange={event => handleDropdownChange(event, "Buyer")}
                                        options={getBuyerDetails}
                                        value={getBuyerDetails.filter(function (option) {
                                            return option.value === GetAllItemDetails[0].Value;
                                        })}

                                    />
                                </div>
                            </div>
                            <div className="widget-body">
                                <div id="registration-form">
                                    <form>
                                        <div >
                                            <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "15%" }}>Brand<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Inspection Standard<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Inspection Level<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Major AQL<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Minor AQL<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Default<span className="text-danger">*</span></th>
                                                        <th style={{ width: "15%" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Inputfields.map((input, index) => (
                                                        <tr>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    placeholder="Select Brand Detail"
                                                                    styles={submitted && input.BrandID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "Brand")}
                                                                    options={getBuyerBrandDetails}
                                                                    value={getBuyerBrandDetails.filter(function (option) {
                                                                        return option.value === input.BrandID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text"
                                                                    className="form-control"
                                                                    id={"InspectionStandard"}
                                                                    name="InspectionStandard"
                                                                    placeholder="Enter InspectionStandard"
                                                                    maxLength="50"
                                                                    autoComplete="off"
                                                                    value={input.InspectionStandard}
                                                                    onChange={event => handleInputChange(index, event, "InspectionStandard")}
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    style={{ border: submitted && input.InspectionStandard === "" ? '1px solid red' : '' }}
                                                                    autoFocus
                                                                />
                                                            </td>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select InspectionLevel"
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    styles={submitted && input.InspectionLevelID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "InspectionLevel")}
                                                                    options={InspectionList}
                                                                    value={InspectionList.filter(function (option) {
                                                                        return option.value === input.InspectionLevelID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select MajorAQL"
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    styles={submitted && input.MajorAQLID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "MajorAQL")}
                                                                    options={optionsMajorAql}
                                                                    value={optionsMajorAql.filter(function (option) {
                                                                        return option.value === input.MajorAQLID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    autocomplete='off'
                                                                    placeholder="Select MinorAQL"
                                                                    onBlur={() => CheckDuplicate(index)}
                                                                    styles={submitted && input.MinorAQLID === 0 ? styles : ''}
                                                                    onChange={event => handleInputChange(index, event, "MinorAQL")}
                                                                    options={optionsMajorAql}
                                                                    value={optionsMajorAql.filter(function (option) {
                                                                        return option.value === input.MinorAQLID;
                                                                    })}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="checkbox" name="IsSelected"
                                                                    className="checkbox clearOpacityfinal"
                                                                    value={input.IsSelected}
                                                                    checked={input.IsSelected === 1 ? true : false}
                                                                    onChange={event => (handleOnSelect(event, 'IsSelected', index))}
                                                                />
                                                            </td>
                                                            <td>
                                                                {index == (Inputfields.length - 1) && <button type="button" className="btn  btn-xs  btn-success" title="Add AQLFinalInspection"
                                                                    onClick={(event) => handleAddFields(event, index)}
                                                                >
                                                                    <i className="fa fa-plus"></i>
                                                                </button>}
                                                                &nbsp;
                                                                {Inputfields.length !== 1 && (
                                                                    <button type="button" className="btn  btn-xs  btn-danger" title="Delete AQLFinalInspection"
                                                                        onClick={() => handleRemoveFields(index)}
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </button>

                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary"
                            onClick={
                                () => ResetOperation("Back")
                            }>
                            <i className="fa fa-arrow-circle-left"></i>
                            &nbsp;Back</span>
                        <span className="btn btn-danger"
                            onClick={
                                () => ResetOperation()
                            }>
                            <i className="fa fa-times"></i>
                            &nbsp;Reset</span>
                        <button type="button" className="btn btn-success" disabled={buttonLoader}
                            onClick={SaveAqlFinalInspectiion}>

                            <i className="fa fa-check right"></i>
                            &nbsp;Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AQLInspectionAdd;