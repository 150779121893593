import axios from "axios";
import authHeader from "../auth-header";

class ProductAdditionalInfoService {
   

    GetProductAdditionalInfo() {
        return axios.get(window.$APIBaseURL + "Master/GetProductAdditionalInfo", {
            headers: {
                authorization: authHeader()
            }
        });
    }



    InsertUpdateProductAdditionalInfo(Params) {
        debugger
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateProductAdditionalInfo',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


}
export default new ProductAdditionalInfoService();