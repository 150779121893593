import {
    PRODUCTFABRICTYPE_LOADING,
    RETRIEVE_PRODUCTFABRICTYPE,
    DELETE_PRODUCTFABRICTYPE,
} from "./types";

import ProductFabricService from "../services/Master/ProductFabricService";

const ProductfabricTypeLoading = (isStatus) => ({
    type: PRODUCTFABRICTYPE_LOADING,
    payload: isStatus,
});

export const deleteProductFabricType = (fabricDetailsID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PRODUCTFABRICTYPE,
            payload: fabricDetailsID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveProductFabricType = (fabricDetailsID, FabricorYarn) => async (dispatch) => {
    try {
        dispatch(ProductfabricTypeLoading(true));
        const res  = await ProductFabricService.GetProductFabricList(fabricDetailsID, FabricorYarn);
        dispatch({
            type: RETRIEVE_PRODUCTFABRICTYPE,
            payload: res.data,
        });
        dispatch(ProductfabricTypeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(ProductfabricTypeLoading(false));
    }
};