
import React, { useState, useEffect, useMemo, Fragment } from "react";
import ReactNotification from 'react-notifications-component';
import Nodify from "../../Common/ReactNotification"
import validator from 'validator'
import SearchRetain from "../../Common/SearchRetain";
import { useSelector } from "react-redux";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import SelectCombobox from 'react-select';
import StyleService from "../../../services/Style/styleService";
import Warehouseser from "../../../services/Master/WarehouseService";
const AddWarehouse = (props) => {

    const [inputfeild, setinputfeild] = useState([{ WarehouseID: 0, Warehousename: "" }]);
    const [getBuyerList, setBuyerList] = useState([]);
    const [getBuyerval, SetBuyerval] = useState();
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitteds, setSubmitteds] = useState(false);
    const [ExistingList, setExistingList] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const reducerState = useSelector((state) => state);
     const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
     let BuyerListOptions = [];

    useEffect(() => {
        StyleService.LoadBuyer(2)
            .then((response) => {
                if (response.data) {
                    //setBuyerList(response.data);
                    BuyerListOptions = response.data;
                    setBuyerList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
                }
            })
            .catch(() => { });
       
        Warehouseser.GetWarehouseList(1, 0).then((res) => {
            if (res.data) {
                setExistingList(res.data.warehouselst)
            }
        })
    }, [])
    const headers = [
        { name: <span> Warehouse Name<span className="text-danger">*</span></span>, field: "workmanItemName", sortable: false },
        { name: "Action", field: "", sortable: false, },
    ];

    let styles = {
        control: styles => ({ ...styles, border: '1px solid red' }),

    }


    const handleInputBuyerChange = (event, FieldName) => {
       
        if (FieldName === "Buyer") {
            if (event !== null) {
                SetBuyerval(event)
                setSubmitteds(false)
            } else {
                SetBuyerval('')
                setSubmitteds(true);
            }
        }
        if (event !== null) {
            let Existing = !!ExistingList.find(x => x.buyerName.trim().toLowerCase() === event.label.trim().toLowerCase())
           
            let EmptyValue = [];
            if (Existing) {
                EmptyValue.push({
                    label: "",
                    value: 0
                })
                SetBuyerval('');
                Nodify('Warning!', 'warning', "This Warehouse is already exist.");
                return false;
            }
        }

    }

    const handleInputChange = (index, event, FieldName) => {

        const values = [...inputfeild];
        let inputtext = ''
        if (FieldName === "Buyer") {
            if (event.currentTarget.value.trim() !== '') {
                inputtext = event.currentTarget.value;
            }
            values[index].Warehousename = inputtext
        }
        setinputfeild(values)
    };
    const CheckDuplicate = (index, Field) => {
       
        const values = [...inputfeild];
        for (var j = 0; j < (inputfeild.length); j++) {
            if (j !== index) {
                if (values[j].Warehousename.trim().toLowerCase() === values[index].Warehousename.trim().toLowerCase()
                ) {
                    values[index].Warehousename = "";
                    setinputfeild(values);
                    Nodify('Warning!', 'warning', 'This Warehouse Name is already exist.');
                    return false;
                }
            }
        }
    }
    const handleAddFields = (index) => {
        const values = [...inputfeild];
        if (values[index].Warehousename !== "") {
            values.push({ WarehouseID: 0, Warehousename: "" });
            setinputfeild(values);
            setSubmitted(false);
        }
        else {
            Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
            setSubmitted(true);
        }

    };

    const handleRemoveFields = index => {
        const values = [...inputfeild];
        values.splice(index, 1)
        setinputfeild(values)
    };

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/WarehouseList');
        } else {
            window.location.reload();
            // let getGroupList = GroupListing(props);
            // SetState(getGroupList)
        }
    }

    const PageRedirect = (Func) => {
        props.history.push({ pathname: "/WarehouseList", state: { message: Func } });
    }


    const SaveWorkmanGroup = (e) => {
       
        setButtonLoader(true);
        e.preventDefault();
        if (getBuyerval === undefined || getBuyerval === null && inputfeild[0].Warehousename === '') {
            setButtonLoader(false);
            setSubmitteds(true);
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
        }
        else if (getBuyerval === "") {
            setButtonLoader(false);
            setSubmitteds(true);
            Nodify('Warning!', 'warning', "Please fill the mandatory(*) fields.");
        }
        else if (inputfeild.length === 1 && inputfeild[0].Warehousename === '') {
            setButtonLoader(false);
            setSubmitted(true);
            Nodify('Warning!', 'warning', "Please fill atleast one Warehouse.");
        } else {
            for (var i = 0; i < (inputfeild.length); i++) {

                if (inputfeild[i].Warehousename === "") {
                    Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
                    setSubmitted(true);
                    setButtonLoader(false);
                    return false;
                }
            }
            let Warehouse = []
            inputfeild.map((x) => {
                Warehouse.push({
                    WarehouseID: 0,
                    Warehousename: x.Warehousename,
                    Isdeleted: 0
                })
            })
           
            let Inputmaster = { Operation: 1, CompBuySupID: getBuyerval.value, name: getBuyerval.label, WarehouseInformation: Warehouse, CreatedBy: currentUser.employeeinformationID }
            Warehouseser.InsertWarehouseGrid(Inputmaster).then((res) => {
                var page = "Remove";
                SearchRetain(page);

                let Func = 'Add';
                if (res.data.outputResult === "1") {
                    Func = 'Add';
                    PageRedirect(Func);
                } else if (res.data.outputResult === "2") {
                    Func = 'Edit';
                    PageRedirect(Func);
                }
                else if (res.data.outputResult === "0") {
                    setButtonLoader(false);
                    Nodify('Warning!', 'warning', "Error Occured!");
                }
            })
        }
    }
    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Add Warehouse </h3>
                        </div>

                        <div className="widget-body">
                            <div className="row">
                                <div className="col-lg-12 margin-top-10">
                                    <label>Buyer<span className="text-danger">*</span></label>
                                </div>
                                <div className="col-sm-3">
                                    <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                                        isDisabled={false}
                                        isLoading={false}
                                        isSearchable={true}
                                        isClearable={true}
                                        autocomplete='off'
                                        onChange={event => handleInputBuyerChange(event, "Buyer")}
                                        styles={submitteds && getBuyerval === undefined || getBuyerval === "" ? styles : ''}
                                        options={getBuyerList}
                                        // value={categoryTypeList.filter(function (option) {
                                        //     return option.value === getCategoryGp.value;
                                        // })}
                                        value={getBuyerval}
                                    />
                                </div>
                            </div>
                            <div id="registration-form">
                                <form>
                                    {
                                        <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                                            <TableHeader headers={headers} />
                                            <tbody>
                                                {inputfeild.map((inputfeilds, index) => (
                                                    <tr>
                                                        <td>
                                                            <input type="text"
                                                                className="form-control"
                                                                id={"StateName" + index}
                                                                name="StateName"
                                                                placeholder="Enter State Name"
                                                                maxLength="50"
                                                                autoComplete="off"
                                                                value={inputfeilds.Warehousename}
                                                                onChange={event => handleInputChange(index, event, "Buyer")}
                                                                onBlur={() => CheckDuplicate(index, 'Buyer')}
                                                                style={{ border: submitted && inputfeilds.Warehousename === "" || inputfeilds.Warehousename === undefined ? '1px solid red' : '' }}
                                                                autoFocus
                                                            />
                                                        </td>

                                                        <td style={{ width: "13%" }}>
                                                            {
                                                                inputfeild.length === (index + 1) && (
                                                                    <span title='Add Warehouse' onClick={() => handleAddFields(index)}
                                                                        className="btn btn-info btn-xs plus">
                                                                        <i className="fa fa-plus"></i>
                                                                    </span>
                                                                )
                                                            }

                                                            &nbsp;
                                                            {
                                                                inputfeild.length !== 1 && (
                                                                    <span title='Delete Warehouse' onClick={() => handleRemoveFields(index)}
                                                                        className="btn btn-danger btn-xs delete">
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                )
                                                            }
                                                        </td>
                                                        {/* <span> {index}</span> */}
                                                    </tr>

                                                ))

                                                }
                                            </tbody>
                                        </table>
                                    }
                                    <div className="col-sm-12">
                                        <div className="buttons-preview" align="right">
                                            <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                            </span>
                                            <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                                <i className="fa fa-times"></i>&nbsp;Reset
                                            </span>
                                            <button type="button" className="btn btn-success" disabled={buttonLoader} onClick={SaveWorkmanGroup}>
                                                <i className="fa fa-check right"></i>&nbsp;Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddWarehouse