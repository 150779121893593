import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import validator from 'validator'
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import BuyerService from "../../../services/Master/BuyerService"
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import TestReportTemp from "../../../services/Master/TestingReortTemplateService";
import TestingReportDetail from "../../../services/Master/TestingReportDetail";
import styleService from "../../../services/Style/styleService"


const TestingReportTemplateAdd = (props) => {
  const [GetItem, SetItem] = useState([{ Value: 0, label: '' }]);
  const [GetAllItemDetails, setAllItemDetails] = useState([{ Value: 0, label: '' }]);
  const [gettemp, settemp] = useState([{ Id: 0, Name: "", }]);
  const [GetBrandState, setBrandState] = useState([{ Value: 0, label: '' }]);
  const [ItemList, setItemList] = useState([]);
  const [getBuyerDetails, setBuyerDetails] = useState([]);
  const [getBrandDetails, setBrandDetails] = useState([]);
  const [getBuyerBrandDetails, setBuyerBrandDetails] = useState([]);
 ;
  const [ExistingList, setExistingList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [drpsubmitted, setdrpSubmitted] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  let BuyerListOptions = [];
   const reducerState = useSelector((state) => state);
   const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;

  const headers = [
    { name: <span>Testing Item Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: "Action", field: "", sortable: false, },
  ];
  const options = [


    { value: 1, label: 'Drop Down' },
    { value: 2, label: 'Text Box' },
  ]

  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }

  useEffect(() => {
   
    let TestingReportDetails = [];
    TestingReportDetail.getAllTestingItemList().then((res) => {
      if (res.data) {
        res.data.map(x => {
          TestingReportDetails.push({
            label: x.itemName,
            value: x.itemID
          })
        })
        setItemList(TestingReportDetails)
      }
    })

    let BuyerDetail = [];
    BuyerService.LoadBuyerList(2).then((res) => {

      if (res.data) {
        // res.data.map(x => {
        //   BuyerDetail.push({
        //     label: x.name,
        //     value: x.id
        //   })
        // })

        BuyerListOptions = res.data;
        //debugger;
        setBuyerDetails(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));


        //setBuyerDetails(BuyerDetail)
      }
    })

    styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((res) => {

      if (res.data) {
        setBrandDetails(res.data);
      }
    })

    TestReportTemp.getTestReportTempDetails(1, 0).then((res) => {
      if (res.data) {
        setExistingList(res.data);
      }
    })


  }, [])

  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/TestingTemplateList", state: { message: Func } });
  }

  const handleAddFields = (event, index) => {
    const values = [...GetItem];
    if (values[index].Value !== 0) {
      // Create a new row
      const newRow = { id: Date.now() };

      // Update the rows array
      SetItem((prevRows) => {
        // Insert the new row at the specified index
        const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

        // Increment the indices of rows following the insertion point
        const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

        return updatedRowsWithIds;
      });
      // values.push({ Value: 0, label: "" });
      // SetItem(values);
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleRemoveFields = index => {
    const values = [...GetItem];
    values.splice(index, 1);
    SetItem(values);
  };

  const handleInputChange = (index, event) => {
   ;
    const values = [...gettemp];
    let inputText = '';
    if (event.target.value.trim() !== '') {
      inputText = event.target.value;
    }
    values[index].Name = inputText;
    settemp(values);
  };

  const CheckDuplicate = (index) => {
   ;
    const values = [...gettemp];
    if (ExistingList.testRepTemp) {
      for (var i = 0; i < ExistingList.testRepTemp.length; i++) {
        if (ExistingList.testRepTemp[i].tempName.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
          values[index].Name = '';
          settemp(values);
          Nodify('Warning!', 'warning', "This Template is already exist.");
          setSubmitted(true);
          return false;
        }
      }
    }

    for (var i = 0; i < (gettemp.length); i++) {
      if (i !== index) {
        if (gettemp[i].Name.trim().toLowerCase() === gettemp[index].Name.trim().toLowerCase()) {
          values[index].Name = "";
          settemp(values);
          Nodify('Warning!', 'warning', 'This Template is already exist.');
          setSubmitted(true);
          return false;
        }
      }
    }
  }




  const handleDropdownChange = (event, FieldName) => {

    const values = [...GetAllItemDetails];
    const valuesone = [...GetBrandState]
    let inputText = '';
    if (FieldName === "Buyer") {
      if (event !== null) {
        values[0].Value = event.value;
        values[0].label = event.label;
        inputText = event.value
      } else {
        values[0].Value = 0;
        values[0].label = ''
      }
      let respected_Brand = getBrandDetails.filter(x => (x.buyerID === parseInt(inputText)) && (x.buyerMapID === 1));
      setBuyerBrandDetails(respected_Brand);
    }

    if (FieldName === "Brand") {
      if (event !== null) {
        valuesone[0].Value = event.value;
        valuesone[0].label = event.label;
        inputText = event.value
      } else {
        valuesone[0].Value = 0;
        valuesone[0].label = ''
      }
    }
    setAllItemDetails(values);
    setBrandState(valuesone)
  };



  const handleInputItemchange = (event, index, FieldName) => {

    const values = [...GetItem];
    const allitem = [...GetAllItemDetails]
    const brandid = [...GetBrandState]
    if (FieldName === "Item") {
      if (event !== null) {
        values[index].Value = event.value;
        values[index].label = event.label;
      } else {
        values[index].Value = 0;
        values[index].label = ''
      }
    }

    SetItem(values);
   ;
    if (values.length > 1)
      for (var j = 0; j < (GetItem.length); j++) {
        if (j !== index) {
          if (values[j].Value === values[index].Value) {

            values[index].Value = 0;
            SetItem(values);
            Nodify('Warning!', 'warning', 'This Item is already exist in this grid');
            setSubmitted(true);
            return false;
          }
        }
      }
    setAllItemDetails(allitem);
    setBrandState(brandid)
    SetItem(values);


    //const values = [...getTempName];


  };

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/TestingTemplateList');
    } else {
      window.location.reload();
    }
  }

  const SaveWorkmanGroup = (e) => {
   ;
    setButtonLoader(true);
    e.preventDefault();
    let Itemid1 = []
    GetItem.map(x => {
      Itemid1.push({
        CommonID: 0,
        Itemid: x.Value,
        Isdeleted: 0,

      })
    })

    for (var i = 0; i < (GetItem.length); i++) {
      if (GetItem[i].Value === 0) {
        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        setdrpSubmitted(true);
        setButtonLoader(false);
        return false;
      }
    }
    if (GetAllItemDetails[0].Value === 0 || GetBrandState[0].Value === 0 || gettemp[0].Name === '') {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
      setButtonLoader(false);
      return false;
    }

    //let finalvalues ={Operation:1,}
    let Inputmaster = { Operation: 1, TempID: gettemp[0].Id, TempName: gettemp[0].Name, BuyerID: GetAllItemDetails[0].Value, BrandID: GetBrandState[0].Value, testreptempInformation: Itemid1, CreatedBy: currentUser.employeeinformationID }
    TestReportTemp.InsertTestReportTempDetails(Inputmaster).then((res) => {
      var page = "Remove";
      SearchRetain(page);

      let Func = 'Add';
      if (res.data.outputResult === "1") {
        Func = 'Add';
        PageRedirect(Func);
      } else if (res.data.outputResult === "2") {
        Func = 'Edit';
        PageRedirect(Func);
      }
      else if (res.data.outputResult === "0") {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', "Error Occured!");
      }
    })

  }




  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Add Testing Template</h3>
            </div>

            <div className="widget-body">
              <div className="row">


                <div className="col-lg-3">
                  <label>Buyer Name<span className="text-danger">*</span></label>
                  <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                    isDisabled={false}
                    isLoading={false}
                    isSearchable={true}
                    isClearable={true}
                    autocomplete='off'
                    placeholder="Select Buyer Name"
                    styles={submitted && GetAllItemDetails[0].Value === 0 ? styles : ''}
                    onChange={event => handleDropdownChange(event, "Buyer")}
                    options={getBuyerDetails}
                    value={getBuyerDetails.filter(function (option) {
                      return option.value === GetAllItemDetails[0].Value;
                    })}

                  />
                </div>

                <div className="col-lg-3">
                  <label>Brand Name<span className="text-danger">*</span></label>
                  <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                    isDisabled={false}
                    isLoading={false}
                    isSearchable={true}
                    isClearable={true}
                    autocomplete='off'
                    placeholder="Select Brand Name"
                    styles={submitted && GetBrandState[0].Value === 0 ? styles : ''}
                    onChange={event => handleDropdownChange(event, "Brand")}
                    options={getBuyerBrandDetails}
                    value={getBuyerBrandDetails.filter(function (option) {
                      return option.value === GetBrandState[0].Value;
                    })}
                  />
                </div>
                {
                  gettemp.map((x, index) => (
                    <div className="col-lg-3">
                      <label>Template Name<span className="text-danger">*</span></label>

                      <input type="text"
                        className="form-control"
                        id={"Name_" + index}
                        name="Name"
                        value={x.Name}
                        onChange={(event) => handleInputChange(index, event)}
                        placeholder="Enter Template Name"
                        maxLength="100"
                        onBlur={() => CheckDuplicate(index)}
                        style={{ border: submitted && !x.Name ? '1px solid red' : '' }}
                      />
                    </div>
                  ))

                }


              </div>
              <div id="registration-form">
                <form>
                  {
                    <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                      <TableHeader headers={headers} />
                      <tbody>
                        {

                          GetItem.map((inputfeild, index) => (
                            <tr>
                              <td>
                                <SelectCombobox className="basic-single" name="Item" id={"Item" + index}
                                  isDisabled={false}
                                  isLoading={false}
                                  isSearchable={true}
                                  isClearable={true}
                                  autocomplete='off'
                                  placeholder="Select Testing Item Name"
                                  styles={drpsubmitted && inputfeild.Value === 0 ? styles : ''}
                                  onChange={event => handleInputItemchange(event, index, "Item")}
                                  options={ItemList}
                                  value={ItemList.filter(function (option) {
                                    return option.value === inputfeild.Value;
                                  })}
                                />
                              </td>

                              <td style={{ width: "13%" }}>
                                {/* {
                                  GetItem.length === (index + 1) && ( */}
                                <span title='Add Testing Report'
                                  onClick={(event) => handleAddFields(event, index)}
                                  className="btn btn-info btn-xs plus">
                                  <i className="fa fa-plus"></i>
                                </span>
                                {/* )
                                } */}
                                &nbsp;
                                {
                                  GetItem.length !== 1 && (
                                    <span title='Delete Testing Report'
                                      onClick={() => handleRemoveFields(index)}
                                      className="btn btn-danger btn-xs delete">
                                      <i className="fa fa-trash-o"></i>
                                    </span>
                                  )
                                }
                              </td>
                            </tr>
                          ))

                        }

                      </tbody>
                    </table>
                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>&nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" disabled={buttonLoader}
                        onClick={SaveWorkmanGroup}
                      >
                        <i className="fa fa-check right"></i>&nbsp;Save
                      </button>
                    </div>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestingReportTemplateAdd