import React, { useState, useEffect, Fragment, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestStandard } from '../../../../../actions/teststandard';
import Reactselect from 'react-select';
import { event } from 'jquery';
import TNAService from '../../../../../services/TNA/TNAService';
import AlignmentService from '../../../../../services/Master/Alignment';
import useFullPageLoader from '../../../../hooks/useFullPageLoader';
import { Modal, Button } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';

const YarnTest = ({ props, YarnDetails, labDetails, labdata, labremarkdata, Testingdetail }) => {
  const dispatch = useDispatch();
  const [getTestNames, setTestNames] = useState([]);
  const [gettestselect, settestselect] = useState([]);
  const [getYarnHeaderInfo, setYarnHeaderInfo] = useState([]);
  const [getYarnDetailInfo, setYarnDetailInfo] = useState([]);
  const [getStandardOptions, setStandardOptions] = useState([]);
  const [getGradeValue, setGradeValue] = useState([]);
  const [getOrder, setOrder] = useState([]);
  const reducerState = useSelector((state) => state);
  const [show, setShow] = useState(false);

  const [getPHInfo, setPHInfo] = useState([]);
  const [getRubbingInfo, setRubbingInfo] = useState([]);
  const [getWashingInfo, setWashingInfo] = useState([]);
  const [getAlkalineInfo, setAlkalineInfo] = useState([]);
  const [getAcidInfo, setAcidInfo] = useState([]);
  const [getWaterInfo, setWaterInfo] = useState([]);
  const inpsectionId = JSON.parse(localStorage.getItem("TestingInspectionID"));
  const [submittedlabcomments, setSubmittedlabcomments] = useState(false);
  const [submittedlabremark, setSubmittedlabremark] = useState(false);
  const [submittedtestingdetails, setSubmittedtestingdetails] = useState(false);
  const [getLabComments, setLabComments] = useState([
    {
      value: 0,
      label: "",
      Remarks: ""
    }
  ]);

  const comments = reducerState.TestStandardReducer.TestStandardList;

  const commentsOrder = reducerState.AlignmentReducer.AlignmentList.item2;
  const [loader, showLoader, hideLoader] = useFullPageLoader();



  const [checkboxStates, setCheckboxStates] = useState({
    IsShowPH: true,
    IsShowRubbing: true,
    IsShowWashing: true,
    IsShowAcid: true,
    IsShowAlkalaine: true,
    IsShowWater: true,
  });


  const inputstyles = {
    border: '1px solid red',
  };

  let labResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Satisfied</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Not Satisfied</b></span></>,


    }
  ]

  let IsResult = [
    {
      value: 1,
      label: <><span style={{ color: "green" }}><b>Pass</b></span></>,


    },
    {
      value: 2,
      label: <><span style={{ color: "Red" }}><b>Fail</b></span></>,


    }
  ]
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),
  }
  const textareaStyles = {
    border: '1px solid red',  // Red border for highlighting 
  };
  useEffect(() => {
    debugger
    //setSubmittedlabcomments(false)
    setSubmittedlabcomments(labdata)
  }, [labdata === true])
  useEffect(() => {
    debugger
    setSubmittedlabremark(labremarkdata)
  }, [labremarkdata === true])
  useEffect(() => {
    debugger
    setSubmittedtestingdetails(Testingdetail)
  }, [Testingdetail === true])
  useEffect(() => {

    let TestNameList = []
    let GradeList = [];
    let YarnInfoDetails = []
    let PHInfo = []
    let RubbingInfo = []
    let WashingInfo = []
    let WaterInfo = []
    let AlkalineInfo = []
    let AcidInfo = [];
    let alignmentData = [];


    (async () => {

      if (props.location.state.params.TaskStatus === 1) {
        if (props.location.state.params.multiselecteddatas.length > 0) {

          let StyleIDs = props.location.state.params.multiselecteddatas.filter(x => x.isSelected === 1).map((data => { return data.styleID })).join(",");
          TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 2, StyleIDs, inpsectionId).then((response) => {


            // // Function to filter and get the latest entries
            const getLatestEntriesWithChildLatestDate = () => {
              // Create an object to store the latest entries by testID and latestDate
              const latestEntries = {};

              // Iterate through the response data to store entries and their indexes
              const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

              entriesWithIndexes.forEach(({ item, index }) => {
                const { testID, latestDate, childLatestDate } = item;
                const latestDateStr = new Date(latestDate).toJSON();
                const childLatestDateStr = new Date(childLatestDate).toJSON();

                if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                  // If it's the latest date or the same date, update the latest entry
                  if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                    latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                  }

                  // Check if childLatestDate is greater or equal to the current childLatestDate
                  if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                    // If it's the child latest date or the same date, update the child latest entry
                    if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                      latestEntries[testID].childLatestDate = childLatestDateStr;
                      latestEntries[testID].entries = [];
                    }
                    latestEntries[testID].entries.push({ item, index });
                  }
                }
              });

              // Convert the object to an array of the latest entries while maintaining the original order
              const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                return [...acc, ...entry.entries];
              }, []);

              // Sort the latest entries based on their original order in the response.data array
              latestEntriesArray.sort((a, b) => a.index - b.index);

              return latestEntriesArray.map(entry => entry.item);
            };

            const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();

            setYarnHeaderInfo(latestEntriesWithChildLatestDate)

            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "PH").map((x, indexy) => {
              PHInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field == undefined ? "" : x.field.toString(),
                fieldID: x.field == undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                rowindex: x.rowIndex,
                found: "",
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: "",
                isChecked: 1

              })
            })

            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
              RubbingInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field == undefined ? "" : x.field.toString(),
                fieldID: x.field == undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                rowindex: x.rowIndex,
                found: "",
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: "",
                isChecked: 1
              })
            })

            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
              WashingInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field == undefined ? "" : x.field.toString(),
                fieldID: x.field == undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                rowindex: x.rowIndex,
                found: "",
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: "",
                isChecked: 1
              })
            })

            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
              WaterInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field == undefined ? "" : x.field.toString(),
                fieldID: x.field == undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                rowindex: x.rowIndex,
                found: "",
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: "",
                isChecked: 1
              })
            })

            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
              AlkalineInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field == undefined ? "" : x.field.toString(),
                fieldID: x.field == undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                rowindex: x.rowIndex,
                found: "",
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                foundGSM: "",
                isChecked: 1
              })
            })

            latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
              AcidInfo.push({
                id: x.id,
                testID: x.testID,
                testTypeID: x.testTypeID,
                testName: x.testName,
                group: x.group,
                fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                fabricDetails: x.fabricDetails,
                standard: x.standard,
                standardName: x.standardName,
                genderID: x.genderID,
                colorDepthID: x.colorDepthID,
                colorDepth: x.colorDepth,
                minResult: x.minResult,
                maxResult: x.maxResult,
                field: x.field == undefined ? "" : x.field.toString(),
                fieldID: x.field == undefined ? "" : x.field.toString(),
                fieldName: x.fieldName,
                resultType: x.resultType,
                resultTypeID: x.resultType,
                optionName: x.optionName == undefined ? "" : x.optionName,
                gradeValue: x.gradeValue,
                gradeOptionID: x.gradeOptionID,
                ResultID: 0,
                ResultName: "",
                gradeID: 0,
                gradeName: '',
                changein: 0,
                beforeWashChangeIn: 0,
                afterWash: "",
                afterWashChangeIn: 0,
                rowindex: x.rowIndex,
                found: "",
                foundGSM: "",
                isChecked: 1
              })
            })


            setPHInfo(PHInfo)
            setRubbingInfo(RubbingInfo)
            setWashingInfo(WashingInfo)
            setAlkalineInfo(AlkalineInfo)
            setAcidInfo(AcidInfo)
            setWaterInfo(WaterInfo)
            handleChangeinitaiload(PHInfo, RubbingInfo, WashingInfo, AlkalineInfo, AcidInfo, WaterInfo)
            // setYarnDetailInfo(YarnInfoDetails);
            //YarnDetails(YarnInfoDetails)

            hideLoader();
          }, (error => {
            hideLoader();
          }))

        }
      }
      else {


        await TNAService.LoadTestInfoDetails(props.location.state.params.TestingInspectionID, props.location.state.params.TnaID, 2).then((res) => {
          if (res.data.item2.length > 0) {

            setYarnHeaderInfo(res.data.item1)
            setOrder(res.data.item3)
            if (res.data.item2) {
              res.data.item2.filter(xy => xy.testName === "PH").map((x, indexy) => {
                PHInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: x.foundGSM,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
                RubbingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: x.foundGSM,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
                WashingInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: x.foundGSM,
                  isChecked: x.isChecked
                })
              })


              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
                WaterInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: x.foundGSM,
                  isChecked: x.isChecked
                })
              })


              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
                AlkalineInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: x.foundGSM,
                  isChecked: x.isChecked
                })
              })

              res.data.item2.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
                AcidInfo.push({
                  id: x.id,
                  testID: x.testID,
                  testTypeID: x.testTypeID,
                  testName: x.testName,
                  group: x.group,
                  fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                  fabricDetails: x.fabricDetails,
                  standard: x.standard,
                  standardName: x.standardName,
                  genderID: x.genderID,
                  colorDepthID: x.colorDepthID,
                  colorDepth: x.colorDepth,
                  minResult: x.minResult,
                  maxResult: x.maxResult,
                  field: x.field == undefined ? "" : x.field.toString(),
                  fieldID: x.field == undefined ? "" : x.field.toString(),
                  fieldName: x.fieldName,
                  resultType: x.resultType,
                  resultTypeID: x.resultType,
                  optionName: x.optionName == undefined ? "" : x.optionName,
                  gradeValue: x.gradeValue,
                  gradeOptionID: x.gradeOptionID,
                  ResultID: x.resultID,
                  ResultName: x.resultName,
                  gradeID: x.gradeID,
                  gradeName: x.gradeName,
                  changein: 0,
                  rowindex: x.rowIndex,
                  found: x.foundGSM,
                  beforeWashChangeIn: 0,
                  afterWash: "",
                  afterWashChangeIn: 0,
                  foundGSM: x.foundGSM,
                  isChecked: x.isChecked
                })
              })

              setPHInfo(PHInfo)
              setRubbingInfo(RubbingInfo)
              setWashingInfo(WashingInfo)
              setAlkalineInfo(AlkalineInfo)
              setAcidInfo(AcidInfo)
              setWaterInfo(WaterInfo)
              handleChangeinitaiload(PHInfo, RubbingInfo, WashingInfo, AlkalineInfo, AcidInfo, WaterInfo)
            }
          }
          else {
            if (props.location.state.params.multiselecteddatas.length > 0) {

              let StyleIDs = props.location.state.params.multiselecteddatas.map((data => { return data.styleID })).join(",");
              TNAService.GetTestInfoDetails(props.location.state.params.buyerID, props.location.state.params.brandID, 2, props.location.state.params.styleID, props.location.state.params.TestingInspectionID).then((response) => {


                // // Function to filter and get the latest entries


                const getLatestEntriesWithChildLatestDate = () => {
                  // Create an object to store the latest entries by testID and latestDate
                  const latestEntries = {};

                  // Iterate through the response data to store entries and their indexes
                  const entriesWithIndexes = response.data.map((item, index) => ({ item, index }));

                  entriesWithIndexes.forEach(({ item, index }) => {
                    const { testID, latestDate, childLatestDate } = item;
                    const latestDateStr = new Date(latestDate).toJSON();
                    const childLatestDateStr = new Date(childLatestDate).toJSON();

                    if (!latestEntries[testID] || latestDateStr >= latestEntries[testID].latestDate) {
                      // If it's the latest date or the same date, update the latest entry
                      if (!latestEntries[testID] || latestDateStr > latestEntries[testID].latestDate) {
                        latestEntries[testID] = { latestDate: latestDateStr, entries: [] };
                      }

                      // Check if childLatestDate is greater or equal to the current childLatestDate
                      if (!latestEntries[testID].childLatestDate || childLatestDateStr >= latestEntries[testID].childLatestDate) {
                        // If it's the child latest date or the same date, update the child latest entry
                        if (!latestEntries[testID].childLatestDate || childLatestDateStr > latestEntries[testID].childLatestDate) {
                          latestEntries[testID].childLatestDate = childLatestDateStr;
                          latestEntries[testID].entries = [];
                        }
                        latestEntries[testID].entries.push({ item, index });
                      }
                    }
                  });

                  // Convert the object to an array of the latest entries while maintaining the original order
                  const latestEntriesArray = Object.values(latestEntries).reduce((acc, entry) => {
                    return [...acc, ...entry.entries];
                  }, []);

                  // Sort the latest entries based on their original order in the response.data array
                  latestEntriesArray.sort((a, b) => a.index - b.index);

                  return latestEntriesArray.map(entry => entry.item);
                };

                const latestEntriesWithChildLatestDate = getLatestEntriesWithChildLatestDate();
                setYarnHeaderInfo(latestEntriesWithChildLatestDate)

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "PH").map((x, indexy) => {
                  PHInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    isChecked: 1

                  })
                })

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO RUBBING").map((x, indexy) => {
                  RubbingInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    isChecked: 1
                  })
                })

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WASHING").map((x, indexy) => {
                  WashingInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    isChecked: 1
                  })
                })

                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO WATER").map((x, indexy) => {
                  WaterInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE").map((x, indexy) => {
                  AlkalineInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    foundGSM: "",
                    isChecked: 1
                  })
                })
                latestEntriesWithChildLatestDate.filter(xy => xy.testName === "COLOUR FASTNESS TO PRESPIRATION - ACID").map((x, indexy) => {
                  AcidInfo.push({
                    id: x.id,
                    testID: x.testID,
                    testTypeID: x.testTypeID,
                    testName: x.testName,
                    group: x.group,
                    fabricDetailsID: x.fabricDetailID == undefined ? "" : x.fabricDetailID,
                    fabricDetails: x.fabricDetails,
                    standard: x.standard,
                    standardName: x.standardName,
                    genderID: x.genderID,
                    colorDepthID: x.colorDepthID,
                    colorDepth: x.colorDepth,
                    minResult: x.minResult,
                    maxResult: x.maxResult,
                    field: x.field == undefined ? "" : x.field.toString(),
                    fieldID: x.field == undefined ? "" : x.field.toString(),
                    fieldName: x.fieldName,
                    resultType: x.resultType,
                    resultTypeID: x.resultType,
                    optionName: x.optionName == undefined ? "" : x.optionName,
                    gradeValue: x.gradeValue,
                    gradeOptionID: x.gradeOptionID,
                    ResultID: 0,
                    ResultName: "",
                    gradeID: 0,
                    gradeName: '',
                    changein: 0,
                    beforeWashChangeIn: 0,
                    afterWash: "",
                    afterWashChangeIn: 0,
                    rowindex: x.rowIndex,
                    found: "",
                    foundGSM: "",
                    isChecked: 1
                  })
                })


                setPHInfo(PHInfo)
                setRubbingInfo(RubbingInfo)
                setWashingInfo(WashingInfo)
                setAlkalineInfo(AlkalineInfo)
                setAcidInfo(AcidInfo)
                setWaterInfo(WaterInfo)
                handleChangeinitaiload(PHInfo, RubbingInfo, WashingInfo, AlkalineInfo, AcidInfo, WaterInfo)
                // setYarnDetailInfo(YarnInfoDetails);
                //YarnDetails(YarnInfoDetails)

                hideLoader();
              }, (error => {
                hideLoader();
              }))

            }
          }
        })

        await TNAService.getAllQcInfoList(1, 0, props.location.state.params.TestingInspectionID).then((res) => {


          let newdata = [];
          if (res.data.testingMainDetails) {
            res.data.testingMainDetails.map((x) => {
              newdata.push({
                value: x.labCommentID,
                Remarks: x.labRemarks
              })

            })
            setLabComments(newdata)
            handleChangeNew(newdata)
            hideLoader();
          }
        })
      }
    })();

    TNAService.LoadGradeDetail().then((res) => {
      if (res.data) {
        res.data.map((x, index) => {
          GradeList.push({
            Id: x.id,
            value: x.value,
            label: x.label
          })
        })

      }
      setGradeValue(GradeList)
    })

    if (getYarnHeaderInfo.length > 0) {
      dispatch(retrieveTestStandard(0));
      for (var i = 0; i <= getYarnHeaderInfo.length - 1; i++) {
        if (TestNameList.length == 0) {
          let StandardOptions;
          StandardOptions = getYarnHeaderInfo.filter((data => {
            if (data.testID == getYarnHeaderInfo[i].testID) {
              return getYarnHeaderInfo[i].standard;
            }
          })).map(a => a.standard);
          TestNameList.push({
            testID: getYarnHeaderInfo[i].testID
            , ID: getYarnHeaderInfo[i].id
            , testName: getYarnHeaderInfo[i].testName
            , standard: StandardOptions.join(",")
            , isChecked: getYarnHeaderInfo[i].isChecked
          });
        }
        else if (TestNameList.length > 0) {
          let filterDuplicate = TestNameList.filter((data => {
            return data.testID == getYarnHeaderInfo[i].testID;
          }))
          if (filterDuplicate.length > 0) {

          }
          else {
            TestNameList.push({
              testID: getYarnHeaderInfo[i].testID
              , ID: getYarnHeaderInfo[i].id
              , testName: getYarnHeaderInfo[i].testName
              , standard: getYarnHeaderInfo[i].standard
              , isChecked: getYarnHeaderInfo[i].isChecked
            });
          }
        }
      }
      setTestNames(...getTestNames, TestNameList);
      settestselect(...getTestNames, TestNameList)
    }
  }, [getYarnHeaderInfo && getYarnHeaderInfo.length > 0]);


  useEffect(() => {
    if (comments && comments.item2 && comments.item2.length > 0) {
      let Options = [];
      comments.item2.forEach(element => {
        Options.push({ id: element.commentID, value: element.commentID, name: element.comment, label: element.comment });
      });
      setStandardOptions(Options);
    }
  }, [comments && comments.item2 && comments.item2.length > 0]);


  const handleChangeNew = (newdata) => {
    labDetails(newdata);
  }




  // Function to handle changes and send data to the parent
  const handleChange = () => {
    const combinedData = {
      getPHInfo
      , getRubbingInfo
      , getWashingInfo
      , getAlkalineInfo
      , getAcidInfo
    };
    YarnDetails(combinedData);
  };
  const handleChangeinitaiload = (PHInfo, RubbingInfo, WashingInfo, AlkalineInfo, AcidInfo, WaterInfo) => {
    const arry1 = { getPHInfo: PHInfo };
    const arry2 = { getRubbingInfo: RubbingInfo };
    const arry3 = { getWashingInfo: WashingInfo };
    const arry4 = { getAlkalineInfo: AlkalineInfo };
    const arry5 = { getAcidInfo: AcidInfo };
    const arry6 = { getWaterInfo: WaterInfo };
    const combinedArray = [
      arry1,
      arry2,
      arry3,
      arry4,
      arry5,]
    YarnDetails(combinedArray);
  };

  // Function to handle changes and send data to the parent
  const handleLabChange = () => {

    labDetails(getLabComments);
  };


  const handlePHOnChange = (e, index) => {
    let PHResult = [...getPHInfo]
    let inputtext = ''

    if (PHResult[index].resultType === 1) {
      if (e.target.value !== '') {
        inputtext = parseFloat(e.target.value)

      }
      else {
        inputtext = ''
      }

      if (parseInt(PHResult[index].minResult) <= inputtext && parseInt(PHResult[index].maxResult) >= inputtext) {
        PHResult[index].ResultName = "Pass"
        PHResult[index].ResultID = 1
        PHResult[index].found = inputtext;
        PHResult[index].foundGSM = inputtext;

      }
      else {
        PHResult[index].ResultName = "Fail"
        PHResult[index].ResultID = 2
        PHResult[index].found = inputtext;
        PHResult[index].foundGSM = inputtext;

      }

    }


    setPHInfo(PHResult)
    handleChange()
  };

  const handleRubbingOnChange = (e, index) => {
    let RubbingResult = [...getRubbingInfo]
    let inputtext = ''
    if (RubbingResult[index].resultType === 3) {
      if (e !== null) {
        RubbingResult[index].gradeID = e.value
        RubbingResult[index].gradeName = e.label
      }
      else {
        RubbingResult[index].gradeID = ''
        RubbingResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (RubbingResult[index].gradeOptionID >= e.value) {
        RubbingResult[index].ResultName = "Pass"
        RubbingResult[index].ResultID = 1

      } else {
        RubbingResult[index].ResultName = "Fail"
        RubbingResult[index].ResultID = 2

      }


    }
    setRubbingInfo(RubbingResult)
    handleChange()
  };


  const handleWaterOnChange = (e, index) => {
    let waterResult = [...getWaterInfo]
    let inputtext = ''

    if (waterResult[index].resultType === 3) {
      if (e !== null) {
        waterResult[index].gradeID = e.value
        waterResult[index].gradeName = e.label
      }
      else {
        waterResult[index].gradeID = ''
        waterResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (waterResult[index].gradeOptionID >= e.value) {
        waterResult[index].ResultName = "Pass"
        waterResult[index].ResultID = 1

      } else {
        waterResult[index].ResultName = "Fail"
        waterResult[index].ResultID = 2

      }


    }

    setWaterInfo(waterResult)
    handleChange()
  };

  const handleWashingOnChange = (e, index) => {
    let WashingResult = [...getWashingInfo]
    let inputtext = ''

    if (WashingResult[index].resultType === 3) {
      if (e !== null) {
        WashingResult[index].gradeID = e.value
        WashingResult[index].gradeName = e.label
      }
      else {
        WashingResult[index].gradeID = ''
        WashingResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (WashingResult[index].gradeOptionID >= e.value) {
        WashingResult[index].ResultName = "Pass"
        WashingResult[index].ResultID = 1

      } else {
        WashingResult[index].ResultName = "Fail"
        WashingResult[index].ResultID = 2

      }


    }

    setWashingInfo(WashingResult)
    handleChange()
  };

  const handleAlkalineOnChange = (e, index) => {
    let AlkalaineResult = [...getAlkalineInfo]
    let inputtext = ''

    if (AlkalaineResult[index].resultType === 3) {
      if (e !== null) {
        AlkalaineResult[index].gradeID = e.value
        AlkalaineResult[index].gradeName = e.label
      }
      else {
        AlkalaineResult[index].gradeID = ''
        AlkalaineResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (AlkalaineResult[index].gradeOptionID >= e.value) {
        AlkalaineResult[index].ResultName = "Pass"
        AlkalaineResult[index].ResultID = 1

      } else {
        AlkalaineResult[index].ResultName = "Fail"
        AlkalaineResult[index].ResultID = 2

      }


    }

    setAlkalineInfo(AlkalaineResult)
    handleChange()
  };

  const handleAcidOnChange = (e, index) => {
    let AcidResult = [...getAcidInfo]
    let inputtext = ''

    if (AcidResult[index].resultType === 3) {
      if (e !== null) {
        AcidResult[index].gradeID = e.value
        AcidResult[index].gradeName = e.label
      }
      else {
        AcidResult[index].gradeID = ''
        AcidResult[index].gradeName = ''
      }

      // Check if the required column value is within the range of the dropdown value
      if (AcidResult[index].gradeOptionID >= e.value) {
        AcidResult[index].ResultName = "Pass"
        AcidResult[index].ResultID = 1

      } else {
        AcidResult[index].ResultName = "Fail"
        AcidResult[index].ResultID = 2

      }


    }

    setAcidInfo(AcidResult)
    handleChange()
  };

  const handleResultOnchange = (value, index, fieldName) => {
    let PHResult = [...getPHInfo]
    let RubbingResult = [...getRubbingInfo]
    let WashingResult = [...getWashingInfo]
    let AlkalaineResult = [...getAlkalineInfo]
    let AcidResult = [...getAcidInfo]
    let waterInfo = [...getWaterInfo]
    if (value !== null && fieldName === "PH") {
      PHResult[index].ResultName = value.label
      PHResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO RUBBING") {
      RubbingResult[index].ResultName = value.label
      RubbingResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO WASHING") {
      WashingResult[index].ResultName = value.label
      WashingResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO WATER") {
      waterInfo[index].ResultName = value.label
      waterInfo[index].ResultID = value.value
    }

    else if (value !== null && fieldName === "COLOUR FASTNESS TO PRESPIRATION - ALKALINE") {
      AlkalaineResult[index].ResultName = value.label
      AlkalaineResult[index].ResultID = value.value
    }
    else if (value !== null && fieldName === "COLOUR FASTNESS TO PRESPIRATION - ACID") {
      AcidResult[index].ResultName = value.label
      AcidResult[index].ResultID = value.value
    }


    setPHInfo(PHResult)
    setRubbingInfo(RubbingResult)
    setWashingInfo(WashingResult)
    setAlkalineInfo(AlkalaineResult)
    setAcidInfo(AcidResult)
    setWaterInfo(waterInfo)
    handleChange()

  };


  const handleLabResultOnchange = (value, index, fieldName) => {

    let labComments = [...getLabComments]
    if (value !== null && fieldName === 'labcomment') {
      labComments[index].label = value.label
      labComments[index].value = value.value
    }
    else if (fieldName === 'Remarks') {
      labComments[index].Remarks = value.target.value
    }
    else {
      labComments[index].label = ''
      labComments[index].value = 0
    }
    setLabComments(labComments)
    handleLabChange()
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckboxChange = (event, testname) => {

    // Create a copy of the headerData array
    const updatedHeaderData = [...gettestselect];
    let PHResult = [...getPHInfo]
    let RubbingResult = [...getRubbingInfo]
    let WashingResult = [...getWashingInfo]
    let AlkalaineResult = [...getAlkalineInfo]
    let AcidResult = [...getAcidInfo]
    let waterInfo = [...getWaterInfo]

    // Find the index of the object with the matching testName
    const index = updatedHeaderData.findIndex((item) => item.testName === testname);

    if (index !== -1) {
      // Update the isChecked property of the matching object to 1 (checked)
      updatedHeaderData[index].isChecked = updatedHeaderData[index].isChecked === 1 ? 0 : 1;;

      // Reassign indices for checked items
      const checkedItems = updatedHeaderData.filter(item => item.isChecked === 1);


      // Update the detailed information arrays
      updateDetailedInfoArray(PHResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(RubbingResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(WashingResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(AlkalaineResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(AcidResult, testname, updatedHeaderData[index].isChecked);
      updateDetailedInfoArray(waterInfo, testname, updatedHeaderData[index].isChecked);
      setTestNames(checkedItems)
    }
  };


  // Function to update the isChecked property in a detailed information array
  const updateDetailedInfoArray = (detailedInfoArray, testName, isChecked) => {

    const updatedArray = [...detailedInfoArray];
    const index = updatedArray.findIndex((item) => item.testName === testName);
    if (index !== -1) {
      updatedArray.forEach((item) => {
        if (item.testName === testName) {
          item.isChecked = isChecked;
        }
      })
    }
    // You may want to set the state for the detailed information array here
    // setStateForDetailedInfoArray(updatedArray);
  };


  return (
    <div className="widget-body">
      <div id="registration-form">
        <div className="row">



          <h1>
            <span>
              {/* <input className='btn btn-info' onClick={handleShow}>Test Selection</input> */}
              <button className='btn btn-info' onClick={handleShow}>Test Selection</button>
            </span>
          </h1>

          <form>

            {
              getTestNames.length > 0 ?
                getTestNames != undefined && getTestNames.map((x, indexy) => (

                  <Fragment>

                    <div
                      style={{
                        display: x.isChecked !== 1 ? "none" : ''
                      }}
                      className="row" key={x.testID}>
                      <div className="col-md-12">
                        <div className="panel-group accordion" id="acc_Garment">
                          <div className="panel panel-default">
                            <div className="panel-heading" >
                              <h4 className="panel-title">
                                <a className="accordion-toggle collapsed clr-themegreen" data-toggle="collapse" data-parent="#acc_Quantity_Info"
                                  href={"#" + x.testID + "Accordian"}>
                                  {indexy + 1 + ". " + x.testName}
                                </a>
                              </h4>
                            </div>
                            <div id={x.testID + "Accordian"} className="panel-collapse collapse">
                              <div className="panel-body border-red">
                                <div className="col-md-12">

                                  <div className="form-group">
                                    <div className='col-md-4'>

                                      <label>Standard</label>
                                      <Reactselect className="basic-single"
                                        name={"StandardDetails" + indexy}
                                        id={"StandardDetails" + indexy}
                                        isDisabled={true}
                                        isLoading={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        isMulti
                                        menuPosition="fixed"
                                        value={getStandardOptions.filter(data => {
                                          return x.standard.split(",").includes((data.id).toString());
                                        })}
                                        options={getStandardOptions}
                                      >
                                      </Reactselect>

                                    </div>
                                  </div>


                                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                                    {
                                      x.testName == "PH" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Details</th>
                                            <th>Required </th>
                                            <th>Found</th>
                                            <th>Result</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            getPHInfo !== undefined && getPHInfo.map((x, index) => (
                                              <>
                                                {

                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{
                                                      x.fieldName === 'Static' ?
                                                        <>
                                                          <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                        </>
                                                        :
                                                        x.fieldName
                                                    }


                                                    </td>
                                                    <td>
                                                      <>
                                                        <span> {x.minResult + "  " + "to" + "  " + x.maxResult}</span>

                                                      </>

                                                    </td>
                                                    <td>
                                                      {

                                                        x.resultType === 1 ? <input type="number" className='form-control' value={x.foundGSM} onChange={e => handlePHOnChange(e, index)} onKeyPress={(e) => {
                                                          if (e.key === 'e' || e.key === 'E') {
                                                            e.preventDefault();
                                                          }
                                                        }}
                                                          style={submittedtestingdetails && (x.foundGSM === '' || x.foundGSM === null || x.foundGSM === undefined || x.foundGSM === 0) ? inputstyles : {}}
                                                        />
                                                          : ""

                                                      }

                                                    </td>
                                                    <td>


                                                      <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={IsResult}
                                                        isClearable={true}
                                                        onChange={e => handleResultOnchange(e, index, x.testName)}
                                                        //value={GetResult}
                                                        value={IsResult.filter(function (option) {
                                                          return option.value === x.ResultID;
                                                        })}
                                                      ></Reactselect>



                                                    </td>


                                                  </tr>
                                                }
                                              </>

                                            ))
                                          }
                                        </tbody>
                                      </table>
                                    }

                                    {x.testName == "COLOUR FASTNESS TO RUBBING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>


                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getRubbingInfo != undefined && getRubbingInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleRubbingOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                        styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {x.testName == "COLOUR FASTNESS TO WASHING" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getWashingInfo != undefined && getWashingInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleWashingOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                        styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>

                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}


                                    {x.testName == "COLOUR FASTNESS TO WATER" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getWaterInfo != undefined && getWaterInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleWaterOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                        styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>

                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                    {x.testName == "COLOUR FASTNESS TO PRESPIRATION - ALKALINE" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>



                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getAlkalineInfo != undefined && getAlkalineInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span>  {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleAlkalineOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                        styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}
                                                      ></Reactselect>

                                                        : ""

                                                    }
                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}


                                    {x.testName == "COLOUR FASTNESS TO PRESPIRATION - ACID" && <table className="table table-striped table-bordered table-hover dataTable no-footer">
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Details</th>
                                          <th>Required </th>
                                          <th>Found</th>
                                          <th>Result</th>



                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          getAcidInfo != undefined && getAcidInfo.map((x, index) => (
                                            <>
                                              {

                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{
                                                    x.fieldName === 'Static' ?
                                                      <>
                                                        <input type="text" value='Fabric Bit 1 test' className='form-control' />

                                                      </>
                                                      :
                                                      x.fieldName
                                                  }


                                                  </td>
                                                  <td>
                                                    <>
                                                      {x.resultType === 3 ? <span> {x.gradeValue}</span>
                                                        : ''
                                                      }
                                                    </>

                                                  </td>
                                                  <td>
                                                    {

                                                      x.resultType === 3 ? <Reactselect className="basic-single " name="IsProductionStatusID"
                                                        options={getGradeValue}
                                                        classNamePrefix="brand"
                                                        isClearable={true}
                                                        onChange={e => handleAcidOnChange(e, index)}
                                                        //value={GetResult}
                                                        value={getGradeValue.filter(function (option) {
                                                          return option.value === x.gradeID;
                                                        })}
                                                        styles={submittedtestingdetails && x.gradeID === 0 ? styles : ''}
                                                      ></Reactselect>

                                                        : ""

                                                    }

                                                  </td>
                                                  <td>


                                                    <Reactselect className="basic-single " name="IsProductionStatusID"
                                                      options={IsResult}
                                                      isClearable={true}
                                                      onChange={e => handleResultOnchange(e, index, x.testName)}
                                                      //value={GetResult}
                                                      value={IsResult.filter(function (option) {
                                                        return option.value === x.ResultID;
                                                      })}
                                                    ></Reactselect>



                                                  </td>


                                                </tr>
                                              }
                                            </>
                                          ))
                                        }
                                      </tbody>
                                    </table>}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))
                : <tr><td colSpan="8" className='norecordfound'><span>No Records Found Please Check Masters</span></td></tr>

            }

          </form>
        </div>
        <br />
        <br />
        {
          getLabComments.map((x, index) => (
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label htmlFor="">Lab Comments</label>
                  <br />

                  <Reactselect className="basic-single " name="IsProductionStatusID"
                    options={labResult}
                    isClearable={true}
                    onChange={e => handleLabResultOnchange(e, index, "labcomment")}
                    //value={GetResult}
                    styles={submittedlabcomments && x.value === 0 ? styles : ''}
                    value={labResult.filter(function (option) {
                      return option.value === x.value;
                    })}
                  ></Reactselect>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="">Remarks</label>
                  <br />
                  <textarea name="" id=""
                    style={submittedlabremark && (x.value === 2 && x.Remarks === "") ? textareaStyles : {}}
                    onChange={e => handleLabResultOnchange(e, index, "Remarks")} value={x.Remarks} cols="150" rows="10"></textarea>
                </div>
              </div>
            </>
          ))
        }

      </div>
      <Modal show={show} backdrop="static" onHide={handleClose} dialogClassName='customdialog'>
        <div className="fixTableHeadTnadetail">
          <Modal.Header closeButton>
            <Modal.Title>TEST SELECTION POPUP</Modal.Title>
          </Modal.Header>
          {/* , overflowY: "auto" */}
          <Modal.Body className="customdialogbody">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="row">
                    {
                      gettestselect != undefined && gettestselect.map((fabric, index) => (
                        <div className="col-lg-6">
                          <li
                            className="listmodal"
                          >
                            <input
                              className="hidecheckbox"
                              type="checkbox"
                              name="IsShowAW"
                              checked={fabric.isChecked}
                              onChange={e => handleCheckboxChange(e, fabric.testName)}
                            />
                            &nbsp;<label htmlFor="IsShowAW">{fabric.testName}</label>
                          </li>

                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-info' onClick={handleClose}>Save</button>
          </Modal.Footer>
        </div>
      </Modal>
      {loader}
    </div>
  )
}

export default YarnTest