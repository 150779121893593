import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { Redirect } from 'react-router-dom';
//import { useSelector } from "react-redux";
import Nodify from "../../../Common/ReactNotification";
import SwatchMasterService from "../../../../services/Master/SwatchMasterService";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { SwatchMasterActionId } from "../../../Common/PageActionNumber";
import SearchRetain from "../../../Common/SearchRetain"
import Lightbox from "react-image-lightbox"
import { useDispatch, useSelector } from "react-redux";
import { retrieveSwatch } from "../../../../actions/swatch"
import CommonService from '../../../../services/Common/CommonService';

const SwatchMasterList = (props) => {

    // Selecters
    //const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    // const { user: currentUser } = useSelector((state) => state.auth);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandSwatchMasterList, setexpandSwatchMasterList] = useState({});
    const [getID, setID] = useState(0);
    const [getPlaceholder] = useState("Buyer / Brand / Swatch Name");
    const [isOpen, setIsOpen] = useState(false)
    const [getBuyerIDForDel, setBuyerIDForDel] = useState()
    const [getBrandIDForDel, setBrandIDForDel] = useState()
    const [getExpandRow, setExpandRow] = useState();

    const dispatch = useDispatch();

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.swatch.SwatchList;
    let isLoadingSwatch = reducerState.swatch.isLoadingSwatch;

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "Swatch"); }
    // End Enable Menu

    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];


    useEffect(() => {
        // showLoader();
        // setTimeout(() => {
        //     SwatchMasterService.GetSwatchMasterList(1, 0, 0).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //             hideLoader();
        dispatch(retrieveSwatch());
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success'; //default, info, warning
            let Title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Swatch Master Information updated successfully.';
                // Type = 'default';
            } else if (state1.message === 'Add') {
                Msg = 'Swatch Master Information added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                Title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(Title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
        //         } else {
        //             hideLoader();
        //         }
        //     }).catch(() => {
        //         hideLoader();
        //     });
        // }, 500)
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingSwatch) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingSwatch]);


    const headers = [
        {
            name: "#",
            field: "",
            sortable: false
        },
        {
            name: "Buyer - Brand Name",
            field: "buyerBrandName",
            sortable: true
        }
    ];

    const childHeaders = [
        {
            name: "Swatch Name",
            sortable: false
        },
        {
            name: "Swatch Image",
            sortable: false
        },
        {
            name: "Action",
            sortable: false,
            display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];

    const SwatchMasterFiltered = useMemo(() => {
        let SwatchFilter = expandSwatchMasterList;
        if (expandSwatchMasterList.length > 0) {
            if (search) {
                SwatchFilter = SwatchFilter.filter(data =>
                    (data.swatchName.toLowerCase().includes(search.toLowerCase())));
            }
        }
        return SwatchFilter;
    }, [expandSwatchMasterList, search])


    const commentsData = useMemo(() => {
        //let computedComments = comments;
        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
           let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
           isAllInfo === 0 && isMapped === 1 
           //&& o.cubeEmployeeIdList != undefined
            && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
           debugger;
            if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }
        if (search) {
            computedComments = computedComments.filter(comment =>
                (comment.swatchName.toLowerCase().includes(search.toLowerCase())) || comment.buyerBrandName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    //Actions



    const pageRedirect = (dataParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/SwatchMasterAdd', { params: [] });
        } else if (redirect === 'Edit') {
            props.history.push(`/SwatchMasterEdit`, { params: dataParams });
        } else {
            let Params = {
                Operation: 32,
                Id: dataParams.swatchMasterID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Group type name is already mapped.";
                    let type = "warning";
                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: dataParams });
                }
            });
        }
    };

    const handleExpandRow = (buyerID, brandID, rowNumber) => {
        setExpandRow(rowNumber);
        setExpandedRows([]);
        setBuyerIDForDel(buyerID);
        setBrandIDForDel(brandID);
        SwatchMasterService.GetSwatchMasterList(2, buyerID, 0, brandID).then((response) => {
            if (response.data) {
                setexpandSwatchMasterList(response.data);
                // Expand row Start
                let currentExpandedRows = expandedRows;
                let isRowExpanded = currentExpandedRows.includes(rowNumber);
                let obj = {};
                isRowExpanded ? (obj[rowNumber] = false) : (obj[rowNumber] = true);
                setExpandState(obj);
                currentExpandedRows = [];
                const newExpandedRows = isRowExpanded ?
                    currentExpandedRows.filter(id => id !== rowNumber) :
                    currentExpandedRows.concat(rowNumber);

                setExpandedRows(newExpandedRows);
                // Expand row End
            }
        });
    }

    const Delcallback = (value) => {
        setID({ showPopupDelete: false, Params: [] });
        SwatchMasterService.GetSwatchMasterList(2, getBuyerIDForDel, 0, getBrandIDForDel).then((response) => {
            if (response.data) {
                setexpandSwatchMasterList(response.data);
                // Expand row Start
                let currentExpandedRows = expandedRows;
                let isRowExpanded = currentExpandedRows.includes(getExpandRow);
                let obj = {};
                isRowExpanded ? (obj[getExpandRow] = true) : (obj[getExpandRow] = false);
                // isRowExpanded ? (obj[getExpandRow] = false) : (obj[getExpandRow] = true);
                setExpandState(obj);
                currentExpandedRows = [];
                const newExpandedRows = isRowExpanded ?
                    currentExpandedRows.filter(id => id !== getExpandRow) :
                    currentExpandedRows.concat(getExpandRow);
                dispatch(retrieveSwatch());
            }
        });
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Swatch Information</span>
                            </div>
                            <div>
                                {
                                    isOpen && (
                                        <Lightbox
                                            mainSrc={isOpen}
                                            alt="bg image"

                                            onCloseRequest={
                                                () => setIsOpen(false)
                                            } />
                                    )
                                }
                            </div>
                            <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} >
                                        &nbsp;

                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Swatch">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>

                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map((comment,i) => (
                                                    <Fragment key={i}>
                                                        <>
                                                            <tr>
                                                                <td style={{width:'25px'}}>  
                                                                { i + 1}
                                                                </td>
                                                                <td>
                                                                    <i
                                                                        className={
                                                                            expandState[comment.rowNumber] ?
                                                                                'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                                }
                                                                        onClick={event => handleExpandRow(comment.buyerID, comment.brandID, comment.rowNumber)}
                                                                    ></i>
                                                                    <span className="BuyerPaddingLeft">
                                                                        {
                                                                            comment.buyerBrandName
                                                                        }
                                                                    </span>
                                                                </td>

                                                            </tr>
                                                            <>
                                                                {
                                                                    expandedRows.includes(comment.rowNumber) ?
                                                                        <tr>
                                                                            <td colSpan="4">
                                                                                <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                    <TableHeader headers={childHeaders}
                                                                                        onSorting={
                                                                                            (field, order) => setSorting({ field, order })
                                                                                        }

                                                                                    />
                                                                                    <tbody>
                                                                                        {
                                                                                             expandSwatchMasterList.map(swatchMasteritem => (
                                                                                                 <>
                                                                                                    <tr>

                                                                                                        <td>{swatchMasteritem.swatchName}</td>
                                                                                                        {/* <td>{swatchMasteritem.swatchFileName}</td> */}
                                                                                                        <td className="trwidth_manual">
                                                                                                            {
                                                                                                                <img src={swatchMasteritem.swatchImageFilePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + swatchMasteritem.swatchImageFilePath}
                                                                                                                    alt="Logo"
                                                                                                                    style={{ marginBottom: '5px', height: '80px', border: "1px solid #d5d5d5" }}
                                                                                                                    onClick={
                                                                                                                        () => setIsOpen(comment.swatchImageFilePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + swatchMasteritem.swatchImageFilePath)
                                                                                                                    }
                                                                                                                />
                                                                                                            }</td>
                                                                                                        <td style={{
                                                                                                                display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                                                                activeMenu[0].isDelete === 0 ? 'none' : ''
                                                                                                            }}>
                                                                                                            <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Swatch Master Detail' onClick={() => pageRedirect(swatchMasteritem, 'Edit', getPlaceholder)}
                                                                                                                className="btn btn-success btn-xs edit">
                                                                                                                <i className="fa fa-edit "></i>
                                                                                                            </span>
                                                                                                            &nbsp;
                                                                                                            <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Swatch Master Detail' onClick={() => pageRedirect(swatchMasteritem, '', getPlaceholder)}
                                                                                                                className="btn btn-danger btn-xs delete">
                                                                                                                <i className="fa fa-trash-o"></i>
                                                                                                            </span></td>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                        {
                                                                                            expandedRows.length === 0 ?
                                                                                                <tr>
                                                                                                    <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                                                                </tr> : ''
                                                                                        }

                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr> : null
                                                                }
                                                            </>
                                                        </>
                                                    </Fragment>
                                                ))
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>


                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={SwatchMasterActionId}
                                    Msg={"Swatch Master Detail deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )

}
export default SwatchMasterList;