import React, { useState, useEffect, useMemo } from "react";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, PageCount } from "../Datatable";
import ScheduleHeader from "../Datatable/Header/TableHeaderScheduleReport";
import Reactselect from 'react-select';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Nodify from "../Common/ReactNotification"
import SearchRetain from "../Common/SearchRetain";
import { Redirect } from 'react-router-dom';
import { StyleId } from "../Common/PageActionNumber";
import StyleService from "../../services/Style/styleService";
import CommonService from '../../services/Common/CommonService';
import { retrieveScheduleReportList, retrievePurchaseOrder, retrieveTNA } from "../../actions/schedulereport";
import { useDispatch, useSelector } from "react-redux";
import { loadCubeBuyerSupplierEmp } from "../../actions/common";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { DateRangePicker } from 'react-date-range';
import columnInformationService from "../../services/Master/ColumnInformationService";
/*-------------------Slider--------------------------*/
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import styleService from "../../services/Style/styleService";
import { login } from './../../actions/auth';

const ScheduleReportDetail = (props) => {

    const [ShowFilter, setShowFilter] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getPlaceholder] = useState("Buyer / Style Name / Style No / Season / Brand / Story / Theme  / Group Type");
    const [getBuyerPlaceholder] = useState("Buyer");
    const [sampleorprod, setsampleorprod] = useState('1');
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    //Start SearchFilter
    const [search, setSearch] = useState("");
    const [getBuyersearch, setBuyerSearch] = useState("");
    const [getStylesearch, setStyleSearch] = useState("");
    const [getSeasonsearch, setSeasonSearch] = useState("");
    const [getBrandsearch, setBrandSearch] = useState("");
    const [getStorysearch, setStorySearch] = useState("");
    const [getThemesearch, setThemeSearch] = useState("");
    const [getGendersearch, setGenderSearch] = useState("");
    const [getCreatedbysearch, setCreatedbySearch] = useState("");
    const [getIdPosearch, setIdPoSearch] = useState("");
    const [getimportance, setimportance] = useState("");
    const [getStyleCopyList, setStyleCopyList] = useState([]);
    const [getStyleCopyRemarkList, setStyleCopyRemarkList] = useState([]);
    const [styleId, setStyleId] = useState();
    //End SearchFilter
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getcomment, setComment] = useState();
    const [ListTile, setListTile] = useState("List");
    const [getColumnInformation, setColumnInformation] = useState([]);
    //filter list
    const [getBrandFilterlist, setBrandFilterlist] = useState();
    const [getSeasonFilterlist, setSeasonFilterList] = useState();
    const [getstyleFilterlist, setStyleFilterList] = useState();
    const [getBuyerFilterlist, setBuyerFilterList] = useState();
    const [getidpoFilterlist, setIdpoFilterList] = useState();
    const [getStoryFilterlist, setStoryFilterList] = useState();
    const [getThemeFilterlist, setThemeFilterList] = useState();
    const [getGenderFilterlist, setGenderFilterList] = useState();
    const [getEmployeeFilterlist, setEmployeeFilterList] = useState();
    const [getimportanceFilterlist, setimportanceFilterList] = useState();
    const [getStyleimage, setStyleimage] = useState();
    const [getPrint, setPrint] = useState([{
        componentRef: React.useRef(null),
        onBeforeGetContentResolve: React.useRef(null)
    }]);

    const [getPrintID, setPrintID] = useState(false);
    const [addStyleConfirmation, setAddStyleConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    const [copyConfirmation, setCopyConfirmation] = useState({ showPopupConfirm: false, Params: {} });
    // const [getPdfDownload, setPdfDownload] = useState([]);
    //Slider
    const [getisOpenSlider, setisOpenSlider] = useState(false);
    const [getSeasonList, setSeasonList] = useState();
    var defaultStartDate = new Date();
    let DateInterval = 210;
    const [stateDate, setStateDate] = useState([
        {
            startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
            endDate: new Date(),
            key: 'selection',
            isShow: false
        }
    ]);

    const [getSearchReqValues, setSearchReqValues] = useState({ getStylesearch: "", })
    const dispatch = useDispatch();

    const [print, setprint] = useState();

    let navstyle = {
        control: navstyle => ({ ...navstyle, width: "750px" }),
    }
    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    let BuyerListOptions = [] = [];
    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    // let comments = []; 
    let comments = reducerState.ScheduleReport.schedulereportList.item1 !== undefined
        ? reducerState.ScheduleReport.schedulereportList.item1
        : reducerState.ScheduleReport.schedulereportList;
    
   // const Commentcopy = reducerState.style.copyStyleList;
    //const commentsResult = reducerState.ScheduleReport.schedulereportList.item1;
    let isLoadingStyle = reducerState.ScheduleReport.isLoadingStyle;

    let ImageArray = [];

    // ;
    if (comments.length > 0) {
        
        if (comments[0].ImageArray !== undefined && !comments[0].ImageArray) { // 

            const skuImg = reducerState.ScheduleReport.schedulereportList.item2;
            for (let index = 0; index < comments.length; index++) {
                ImageArray = skuImg.filter(d => d.styleID === comments[index].styleID);
                let imgsa = ImageArray.filter(d => d.skuID !== 0 || d.skuID !== null)

                let count = 0;
                let Total = 0;
                let Index = 0;
                for (let Imgval = 0; Imgval < imgsa.length; Imgval++) {

                    if (imgsa[Imgval].front === 1) {
                        Total = imgsa.filter(d => d.skuID === imgsa[Imgval].skuID).length;
                        count = 1;
                        Index = count;
                        ImageArray[Imgval].IndexTotal = '[' + Index + ' - ' + Total + ']';
                    }
                    else {
                        // ImageArray[Imgval].Index = count
                        Index = count;
                        ImageArray[Imgval].IndexTotal = Index === 0 ? '' : '[' + Index + ' - ' + Total + ']';
                    }
                    count = count + 1;
                }

                comments[index].ImageArray = ImageArray;

            }
        }
    }
    // If check production or sampling
    const activeMenus = useSelector((state) => state.auth.activeMenuList);
    let IsProduction = activeMenus.IsProduction;

    //Enable Menu
    let activeMenu;
    // let activeMenu = { isAdd: 1, isEdit: 1, isDelete: 1, };
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === 'Style'); }
    // End Enable Menu

    // const news = localStorage.setItem("a", moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ' + moment(stateDate[0].endDate).format('DD/MM/YYYY'))
    useEffect(() => {
        let Page = localStorage.getItem("Listpage")
        if (Page) {
            setListTile(Page)
        }

        localStorage.removeItem('CreatedDate');
        const storageData = JSON.parse(localStorage.getItem("Search"));
        const storageDatas = JSON.parse(localStorage.getItem("CreatedBy"));
        const Buyer = JSON.parse(localStorage.getItem("Buyer"));
        const Style = JSON.parse(localStorage.getItem("StyleName"));
        const Season = JSON.parse(localStorage.getItem("Season"));
        const Brand = JSON.parse(localStorage.getItem("Brand"));
        const Story = JSON.parse(localStorage.getItem("Story"));
        const IdPo = JSON.parse(localStorage.getItem("IdPo"));
        const Theme = JSON.parse(localStorage.getItem("Theme"));
        const Gender = JSON.parse(localStorage.getItem("Gender"));
        const CreatedBy = JSON.parse(localStorage.getItem("CreatedBy"));
        const CreatedDate = JSON.parse(localStorage.getItem('CreatedDate'));
        const PoStartdate = localStorage.getItem("StylePodateStatedate")
        const POenddate = localStorage.getItem("StylePodateenddate")
        stateDate[0].startDate = PoStartdate === null ? new Date(stateDate[0].startDate) : new Date(PoStartdate);
        stateDate[0].endDate = POenddate === null ? new Date() : new Date(POenddate);
        setStateDate(stateDate);
        if (props.location.state && props.location.state.params.localStorage) {
            if (storageData) { // if storage is not null it will executed

                if (storageData.Page === "" && storageData.Searchvalue === "") {
                    localStorage.removeItem('Search');
                }

                if (Buyer !== null && Buyer.Page === 'Buyer') {
                    if (Buyer.Page === 'Buyer' && Buyer.Searchvalue !== '') {
                        setBuyerSearch(Buyer.Searchvalue);
                    }
                    else {
                        localStorage.removeItem('Buyer');
                        setBuyerSearch("");
                    }
                }
                if (Style !== null && Style.Page === 'StyleName') {
                    if (Style.Page === 'StyleName' && Style.Searchvalue !== '') {
                        setStyleSearch(Style.Searchvalue);
                    } else {
                        localStorage.removeItem('StyleName');
                        setStyleSearch("");
                    }
                }
                if (Season !== null && Season.Page === 'Season') {
                    if (Season.Page === 'Season' && Season.Searchvalue !== '') {
                        setSeasonSearch(Season.Searchvalue);
                    } else {
                        localStorage.removeItem('Season');
                        setSeasonSearch('');
                    }
                }
                if (Brand !== null && Brand.Page === 'Brand') {
                    if (Brand.Page === 'Brand' && Brand.Searchvalue !== '') {
                        setBrandSearch(Brand.Searchvalue);
                    } else {
                        localStorage.removeItem('Brand');
                        setBrandSearch('');
                    }

                }
                if (Story !== null && Story.Page === 'Story') {
                    if (Story.Page === 'Story' && Story.Searchvalue !== '') {
                        setStorySearch(Story.Searchvalue);
                    } else {
                        localStorage.removeItem('Story');
                        setStorySearch('');
                    }
                }
                if (IdPo !== null && IdPo.Page === 'IdPo') {
                    if (IdPo.Page === 'IdPo' && IdPo.Searchvalue !== '') {
                        setIdPoSearch(IdPo.Searchvalue);
                    } else {
                        localStorage.removeItem('IdPo');
                        setIdPoSearch('');
                    }
                }
                if (Theme !== null && Theme.Page === 'Theme') {
                    if (Theme.Page === 'Theme' && Theme.Searchvalue !== '') {
                        setThemeSearch(Theme.Searchvalue);
                    } else {
                        localStorage.removeItem('Theme');
                        setThemeSearch('');
                    }

                }
                if (Gender !== null && Gender.Page === 'Gender') {
                    if (Gender.Page === 'Gender' && Gender.Searchvalue !== '') {
                        setGenderSearch(Gender.Searchvalue);
                    }
                    else {
                        localStorage.removeItem('Gender');
                        setGenderSearch('');
                    }
                }
                if (CreatedBy !== null && CreatedBy.Page === 'CreatedBy') {
                    if (CreatedBy.Page === 'CreatedBy' && CreatedBy.Searchvalue !== '') {
                        setCreatedbySearch(CreatedBy.Searchvalue);
                    } else {
                        localStorage.removeItem('CreatedBy');
                        setCreatedbySearch('');
                    }
                }

                if (CreatedDate) {
                    if (CreatedDate.EndDate && CreatedDate.EndDate !== null && CreatedDate.Startdate && CreatedDate.Startdate !== null) {
                        //setCreatedbySearch(CreatedDate.Startdate + "To" + CreatedDate.EndDate);
                        let date = [...stateDate]
                        date[0].startDate = CreatedDate.Startdate;
                        date[0].endDate = CreatedDate.EndDate;
                        setStateDate(date);
                    }
                    else {
                        localStorage.removeItem('CreatedDate');
                        // setCreatedbySearch('');
                    }
                }
            }
            // storage is null & not null means also its executed for store filter value in local storage.
            if (Theme !== null && Theme.Page === 'Theme') {
                if (Theme.Page === 'Theme' && Theme.Searchvalue !== '') {
                    setThemeSearch(Theme.Searchvalue);
                } else {
                    localStorage.removeItem('Theme');
                    setThemeSearch('');
                }

            }
            if (Gender !== null && Gender.Page === 'Gender') {
                if (Gender.Page === 'Gender' && Gender.Searchvalue !== '') {
                    setGenderSearch(Gender.Searchvalue);
                }
                else {
                    localStorage.removeItem('Gender');
                    setGenderSearch('');
                }
            }
            if (IdPo !== null && IdPo.Page === 'IdPo') {
                if (IdPo.Page === 'IdPo' && IdPo.Searchvalue !== '') {
                    setIdPoSearch(IdPo.Searchvalue);
                } else {
                    localStorage.removeItem('IdPo');
                    setIdPoSearch('');
                }
            }
            if (Story !== null && Story.Page === 'Story') {
                if (Story.Page === 'Story' && Story.Searchvalue !== '') {
                    setStorySearch(Story.Searchvalue);
                } else {
                    localStorage.removeItem('Story');
                    setStorySearch('');
                }
            }
            if (Season !== null && Season.Page === 'Season') {
                if (Season.Page === 'Season' && Season.Searchvalue !== '') {
                    setSeasonSearch(Season.Searchvalue);
                } else {
                    localStorage.removeItem('Season');
                    setSeasonSearch('');
                }
            }
            if (Buyer !== null && Buyer.Page === 'Buyer') {
                if (Buyer.Page === 'Buyer' && Buyer.Searchvalue !== '') {
                    setBuyerSearch(Buyer.Searchvalue);
                }
                else {
                    localStorage.removeItem('Buyer');
                    setBuyerSearch("");
                }
            }
            if (Style !== null && Style.Page === 'StyleName') {
                if (Style.Page === 'StyleName' && Style.Searchvalue !== '') {
                    setStyleSearch(Style.Searchvalue);
                } else {
                    localStorage.removeItem('StyleName');
                    setStyleSearch("");
                }
            }
            if (Brand !== null && Brand.Page === 'Brand') {
                if (Brand.Page === 'Brand' && Brand.Searchvalue !== '') {
                    setBrandSearch(Brand.Searchvalue);
                } else {
                    localStorage.removeItem('Brand');
                    setBrandSearch('');
                }

            }
            if (storageDatas) {
                if (CreatedBy !== null && CreatedBy.Page === 'CreatedBy') {
                    if (CreatedBy.Page === 'CreatedBy' && CreatedBy.Searchvalue !== '') {
                        setCreatedbySearch(CreatedBy.Searchvalue);
                    } else {
                        localStorage.removeItem('CreatedBy');
                        setCreatedbySearch('');
                    }
                }
            }
            else if (CreatedDate) {
                if (CreatedDate.EndDate && CreatedDate.EndDate !== null && CreatedDate.Startdate && CreatedDate.Startdate !== null) {
                    //setCreatedbySearch(CreatedDate.Startdate + "To" + CreatedDate.EndDate);
                    let date = [...stateDate]
                    date[0].startDate = CreatedDate.Startdate;
                    date[0].endDate = CreatedDate.EndDate;
                    setStateDate(date);
                }
                else {
                    localStorage.removeItem('CreatedDate');
                    //setCreatedbySearch('');
                }
            }
        }
        else {
            localStorage.removeItem('Search');
            localStorage.removeItem('Buyer');
            localStorage.removeItem('StyleName');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('Story');
            localStorage.removeItem('IdPo');
            localStorage.removeItem('Theme');
            localStorage.removeItem('Gender');
            localStorage.removeItem('CreatedBy');
            localStorage.removeItem('CreatedDate');
            setBuyerSearch("");
            setStyleSearch("");
            setSeasonSearch('');
            setBrandSearch('');
            setStorySearch('');
            setIdPoSearch('');
            setThemeSearch('');
            setGenderSearch('');
            setCreatedbySearch('');
        }
    }, []);

    useEffect(() => {
        const fetchdata = async () => {
            await styleService.GetStyleCreatedByList(0).then((response) => {
                if (response.data) {
                    setEmployeeFilterList(response.data);
                }
            });

            const res = await columnInformationService.GetScReporttempInfoList(parseInt(props.location.state.params.reporttempID), props.location.state.params.reportTemplateIDList);
            debugger
            if (res.data) {
                // Map response data to desired format
                const columnValue = res.data.map(x => ({
                    ReportTempDetailinfoID: 0,
                    Id: x.columnInformationID,
                    TableName: x.tableName,
                    ColumnName: x.columnName,
                    DisplayName: x.displayName,
                    ModuleName: x.moduleName,
                    ModuleID: x.moduleID,
                }));
                ;
                // Update state with column information
                setColumnInformation(columnValue);
            }
        }
        fetchdata();
    }, [props])

    useEffect(() => {
        localStorage.setItem('Path', 'ScheduleReportDetail')
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }
        let params;
        debugger;
        if (props.location.state?.params.reportTemplateName === "Style") {
            params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
            dispatch(retrieveScheduleReportList(params));

        } else if (props.location.state.params.reportTemplateName === "PurchaseOrder" || props.location.state.params.reportTemplateName === "PO") {
            params = { purchaseOrderID: 0, IsProduction: 0 }
                
            //dispatch(retrieveScheduleReportList(params));
            dispatch(retrievePurchaseOrder(params.purchaseOrderID, params.IsProduction));


        } else if (props.location.state.params.reportTemplateName === "TNA") {
            // Handle the TNA case here, if needed
            params = { Operation: props.location.state.params.repCatOperation, TNAStartDate: StartDate, TNAEndDate: EndDate }
            dispatch(retrieveTNA(params));
        }




        if (IsProduction && props.location.state === null) {
            localStorage.removeItem('Search');
            localStorage.removeItem('StyleName');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('Story');
            localStorage.removeItem('IdPo');
            localStorage.removeItem('Theme');
            localStorage.removeItem('Gender');
            localStorage.removeItem('CreatedBy');
            setStyleSearch('');
            setSeasonSearch('');
            setBrandSearch('');
            setStorySearch('');
            setBuyerSearch('')
            setIdPoSearch('');
            setThemeSearch('');
            setGenderSearch('');
            setCreatedbySearch('');
            var defaultStartDate = new Date();
            const emptyStateDate = [
                {
                    startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                    endDate: new Date(),
                    key: 'selection',
                    isShow: false
                }];
            setStateDate(emptyStateDate);
        }

        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {

            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(10);
        }


        var buyerlist = [];

        comments.map(x => {
            buyerlist.push({
                label: x.buyerName,
                value: x.buyerID
            })
        })
    }, [dispatch, IsProduction]);


    useEffect(() => {
        if (!isLoadingStyle) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingStyle])

    const headers = getColumnInformation.map((x, index) => ({
        name: x.DisplayName,         // You might want to customize this based on `x` or `index`
        field: x.ColumnName,         // Same for `field` - customize as needed
        sortable: true,  // If this should be dynamic, you might adjust it
        widthsize: '20%'   // Customize if needed
    }));


    const headerss = [
        {
            name: "#",
            field: "",
            sortable: false

        },
        {
            name: "Style Name",
            field: "styleName",
            sortable: true,
            widthsize: "14%"
        }, {
            name: "Style No",
            field: "styleNo",
            sortable: true,
            widthsize: "11%"
        }, {
            name: "Season",
            field: "seasonName",
            sortable: true,
            widthsize: "9%"
        },
        {
            name: "Brand",
            field: "brandName",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "ID/PO No",
            field: "idPoNo",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Story",
            field: "stroryName",
            sortable: true,
            widthsize: "8%"
        }, {
            name: "Theme",
            field: "themeName",
            sortable: true,
            widthsize: "8%"
        },
        // {
        //     name: "CreatedDate",
        //     field: "createddate",
        //     sortable: true,
        //     widthsize: "9%"
        // },
        {
            name: "Gender",
            field: "genderSizeName",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Created Date",
            field: "createddate",
            sortable: true,
            widthsize: "8%"
        },
        {
            name: "Created By",
            field: "createdByStr",
            sortable: true,
            widthsize: "9%"
        },

    ];
    const columns = [
        {

            title: "Style Name",
            field: "styleName",

        }, {
            title: "Style No",
            field: "styleNo",

        }, {
            title: "Season",
            field: "seasonName",

        },
        {
            title: "Brand",
            field: "brandName",

        },
        {
            title: "ID/PO No",
            field: "idPoNo",
        },
        {
            title: "Story",
            field: "stroryName",

        }, {
            title: "Theme",
            field: "themeName",

        }, {
            title: "Gender",
            field: "genderSizeName",

        },
        {
            title: "Created Date",
            field: "createddate",

        },
        {
            title: "Created By",
            field: "createdByStr",

        },



    ]
    window.addEventListener('beforeunload', function () {
        // Clear localStorage
        localStorage.removeItem("Importance")
        localStorage.removeItem("CreatedBy")
        localStorage.removeItem("Gender")
        localStorage.removeItem("Theme")
        localStorage.removeItem("Story")
        localStorage.removeItem("IdPo")
        localStorage.removeItem("Brand")
        localStorage.removeItem("Season")
        localStorage.removeItem("StyleName")
        localStorage.removeItem("Buyer")
        localStorage.removeItem("StylePodateStatedate");
        localStorage.removeItem("StylePodateenddate");
    });
    //-----------------Print---------------



    const handleOnBeforeGetContent = React.useCallback(() => {

        showLoader();
        return new Promise((resolve) => {
            getPrint[0].onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setPrintID(false);
                hideLoader();
                resolve();
            }, 2000);
        });
    }, [showLoader]);

    const reactToPrintContent = React.useCallback(() => {
        setPrintID(true);

        return getPrint[0].componentRef.current;

    }, [getPrint[0].componentRef.current,]);

    React.useEffect(() => {
        if (
            typeof getPrint[0].onBeforeGetContentResolve.current === "function"
        ) {
            getPrint[0].onBeforeGetContentResolve.current();
        }

    }, [getPrint[0].onBeforeGetContentResolve.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "StyleList",
        onBeforeGetContent: handleOnBeforeGetContent,
        removeAfterPrint: true
    });
    // -----------------------------------------------------------



    //Excel
    let ExcelValues = []
    const DownloadExcel = () => {

        commentsData.map(comment => {
            ExcelValues.push({
                StyleName: comment.styleName,
                StyleNo: comment.styleNo,
                SeasonName: comment.seasonName,
                BrandName: comment.brandName,
                IDPONo: comment.idPoNo,
                StoryName: comment.stroryName,
                ThemeName: comment.themeName,
                GenderSizeName: comment.genderSizeName,
                CreatedDate: comment.createddate,
                CreatedBy: comment.createdByStr
            })
        })
        const newdata = ExcelValues.map(comment => {
            delete comment.tableData
            return comment
        })
        const worksheet = XLSX.utils.json_to_sheet(newdata)
        const Workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(Workbook, worksheet, 'StyleList')
        //Buffer
        XLSX.write(Workbook, { bookType: 'xlsx', type: 'buffer' })
        //Binary strnig
        XLSX.write(Workbook, { bookType: 'xlsx', type: 'binary' })
        //download
        XLSX.writeFile(Workbook, 'StyleList.xlsx')
    }
    //PDF
    let PdfValues = [];

    const DownloadPDF = () => {
        commentsData.map(comment => {
            PdfValues.push({
                styleName: comment.styleName,
                styleNo: comment.styleNo,
                seasonName: comment.seasonName,
                brandName: comment.brandName,
                idPoNo: comment.idPoNo,
                stroryName: comment.stroryName,
                themeName: comment.themeName,
                genderSizeName: comment.genderSizeName,
                createddate: comment.createddate,
                createdByStr: comment.createdByStr
            })

        })
        const doc = new jsPDF()
        doc.text("STYLELIST", 20, 10)
        doc.autoTable({
            theme: "grid",
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            body: PdfValues
        })
        doc.save('Stylelist.pdf')
    }
    const LoadDropdownsBasedOnFilter = (data) => {

        var buyerlist = []; var stylelist = []; var seasonlist = []; var idpolist = []; var storylist = [];
        var themelist = []; var genderlist = []; var brandlist = []; var employeelist = [];
        var importancelist = [];
        data.map(x => {
            buyerlist.push({
                label: x.buyerName,
                value: x.buyerID
            })
            seasonlist.push({
                label: x.seasonName === "" ? "-" : x.seasonName,
                value: x.seasonID
            })
            stylelist.push({
                label: x.styleName === "" ? "-" : x.styleName,
                value: x.styleID
            })
            idpolist.push({
                label: x.idPoNo === "" ? "-" : x.idPoNo,
                value: x.idPoNo
            })
            storylist.push({
                label: x.stroryName === "" ? "-" : x.stroryName,
                value: x.storyID
            })
            themelist.push({
                label: x.themeName === "" ? "-" : x.themeName,
                value: x.themeID
            })
            genderlist.push({
                label: x.genderSizeName === "" ? "-" : x.genderSizeName,
                value: x.genderID
            })
            brandlist.push({
                label: x.brandName === "" ? "-" : x.brandName,
                value: x.brandID
            })
            employeelist.push({
                label: x.createdByStr === "" ? "-" : x.createdByStr,
                value: x.createdBy
            })

            importancelist.push({
                label: x.importance === "" ? "-" : x.importance === "1" ? "Priority" : x.importance === "2" ? "Critical" : x.importance === "1,2" ? "Priority , Critical" : x.importance === "2,1" ? "Critical , Priority" : "",
                value: x.importance
            })
        })
        // setBuyerFilterList([...new Map(buyerlist.map(item =>
        //     [item['label'], item])).values()]);

        setimportanceFilterList([...new Map(importancelist.map(item =>
            [item['label'], item])).values()])


        setSeasonFilterList([...new Map(seasonlist.map(item =>
            [item['label'], item])).values()])

        setStyleFilterList([...new Map(stylelist.map(item =>
            [item['label'], item])).values()])

        setIdpoFilterList([...new Map(idpolist.map(item =>
            [item['label'], item])).values()])

        setStoryFilterList([...new Map(storylist.map(item =>
            [item['label'], item])).values()])

        setThemeFilterList([...new Map(themelist.map(item =>
            [item['label'], item])).values()])

        setGenderFilterList([...new Map(genderlist.map(item =>
            [item['label'], item])).values()])

        setBrandFilterlist([...new Map(brandlist.map(item =>
            [item['label'], item])).values()])

        setEmployeeFilterList([...new Map(employeelist.map(item =>
            [item['label'], item])).values()])


    }

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push('/ScheduleReportList');
        } else {
            window.location.reload();

        }
    }

    const commentsData = useMemo(() => {
        // let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        //     o.brandID === brandId && o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
        // let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
        //     o.brandID === brandId && isAllInfo === 0 && isMapped === 1 && o.cubeEmployeeIdList != undefined
        //     && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
       
       
        //     if (computedCommentsBuyerDetails.length > 0) {
        //     computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        // }

        let computedComments = comments;

        if (getBuyersearch) {
            computedComments = computedComments.filter(comment =>
                comment.buyerName.toLowerCase().includes(getBuyersearch.label.toLowerCase())
            )

            setTotalItems(computedComments.length);
        }

        if (getStylesearch) {
            computedComments = computedComments.filter(comment =>
                comment.styleName.toLowerCase().includes(getStylesearch.label.toLowerCase())
            )

        }

        if (getSeasonsearch) {
            computedComments = computedComments.filter(comment =>
                comment.seasonName.toLowerCase().includes(getSeasonsearch.label.toLowerCase())
            );
        }


        if (getBrandsearch) {
            computedComments = computedComments.filter(comment =>
                comment.brandName.toLowerCase().includes(getBrandsearch.label.toLowerCase())
            );
        }
        if (getStorysearch) {
            computedComments = computedComments.filter(comment =>
                comment.stroryName.toLowerCase().includes(getStorysearch.label.toLowerCase())
            );
        }
        if (getThemesearch) {
            computedComments = computedComments.filter(comment =>
                comment.themeName.toLowerCase().includes(getThemesearch.label.toLowerCase())
            );
        }

        if (getGendersearch) {
            computedComments = computedComments.filter(comment =>
                comment.genderSizeName.toLowerCase().includes(getGendersearch.label.toLowerCase())
            );
        }
        if (getCreatedbysearch) {
            
            computedComments = computedComments.filter(comment =>
                comment.createdByStr.toLowerCase().includes(getCreatedbysearch.label.toLowerCase())
            );
        }
        if (getimportance) {
            // computedComments = computedComments.filter(comment =>
            //     comment.importance.toLowerCase().includes(getimportance.label.toLowerCase())
            // );
            computedComments = computedComments.filter(comment => comment.importance === getimportance.value)
        }
        if (getIdPosearch) {
            computedComments = computedComments.filter(comment =>
                comment.idPoNo.toLowerCase().includes(getIdPosearch.label.toLowerCase())
            );
            setTotalItems(computedComments.length);
        }
        setTotalItems(computedComments.length);

        let format = "DD/MM/YYYY";
        let StartDate = null;
        let EndDate = null;
        // if (moment(stateDate[0].startDate).isValid()) {
        //     StartDate = new Date(moment(stateDate[0].startDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        //     //StartDate.setMinutes(StartDate.getMinutes() + 370);
        // }
        // if (moment(stateDate[0].endDate).isValid()) {
        //     EndDate = new Date(moment(stateDate[0].endDate, "DD/MM/YYYY").format("DD/MM/YYYY"));
        //     //EndDate.setMinutes(EndDate.getMinutes() + 370);
        // }

        // if (stateDate[0].startDate && stateDate[0].endDate) {
        //     computedComments = computedComments.filter(comment =>
        //         new Date(moment(comment.createddate, format)) >= stateDate[0].startDate && new Date(moment(comment.createddate, format)) <= stateDate[0].endDate
        //     )
        // }
        setTotalItems(computedComments.length);


        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => {
                let comparison = 0;
                if (typeof a[sorting.field] === 'string' && typeof b[sorting.field] === 'string') {
                    comparison = a[sorting.field].localeCompare(b[sorting.field]);
                } else {
                    comparison = (a[sorting.field] - b[sorting.field]);
                }
                return reversed * comparison;
            });
        }

        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
        LoadDropdownsBasedOnFilter(computedComments);
        // Current Page slice
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    },
        [
            comments,
            currentPage,
            search,
            getBuyersearch,
            getStylesearch,
            getSeasonsearch,
            getBrandsearch,
            getStorysearch,
            getThemesearch,
            getGendersearch,
            getCreatedbysearch,
            getIdPosearch,
            getimportance,
            stateDate,
            sorting,
            ITEMS_PER_PAGE,
        ]);

    const resetStyle = () => {

        localStorage.removeItem('Search');
        localStorage.removeItem('Buyer');
        localStorage.removeItem('StyleName');
        localStorage.removeItem('Season');
        localStorage.removeItem('Brand');
        localStorage.removeItem('Story');
        localStorage.removeItem('IdPo');
        localStorage.removeItem('Theme');
        localStorage.removeItem('Gender');
        localStorage.removeItem('CreatedBy');
        localStorage.removeItem('Importance')
        localStorage.removeItem("StylePodateStatedate");
        localStorage.removeItem("StylePodateenddate");
        setBuyerSearch('');
        setStyleSearch('');
        setSeasonSearch('');
        setBrandSearch('');
        setStorySearch('');
        setIdPoSearch('');
        setThemeSearch('');
        setGenderSearch('');
        setCreatedbySearch('');
        setimportance("");
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        let StartDate = null;
        let EndDate = null;
        if (moment(emptyStateDate[0].startDate).isValid()) {
            StartDate = moment(emptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(emptyStateDate[0].endDate).isValid()) {
            EndDate = moment(emptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
        var Data = { Searchvalue: '', Page: '' }
        localStorage.setItem('Search', JSON.stringify(Data));
    }

    useEffect(() => {
        if (commentsData.length > 0) {
            StyleService.LoadBuyer(2)
                .then((response) => {
                    if (response.data) {
                        BuyerListOptions = response.data;
                        var BuyerBrandInfo = BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId));
                        if (BuyerListOptions.length > 0 && BuyerBrandDetails.length > 0) {
                            setBuyerFilterList(BuyerBrandInfo.filter(o => commentsData.some(({ buyerID }) => o.id === buyerID)));
                        }
                    }
                })
                .catch(() => { });
        }
    }, [commentsData])


    const pageRedirect = (Params, redirect, page) => {

        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            ;
            dispatch(loadCubeBuyerSupplierEmp(1));
            dispatch(loadCubeBuyerSupplierEmp(3));
            let params = { tabPage: "AddStyle" };
            setAddStyleConfirmation({ showPopupConfirm: true, Params: params });

            // props.history.push('/StyleListRegister');
        }
        else if (redirect === 'Edit') {

            // dispatch(loadCubeBuyerSupplierEmp(1));
            // dispatch(loadCubeBuyerSupplierEmp(3));
            let params = { isQuickStyle: false, LastStyle: {}, }
            //dispatch(updateQuickStyleCreate(params));
            props.history.push(`/StyleListRegister`, { params: Params });
            let CreDate = {
                Startdate: stateDate[0].startDate,
                EndDate: stateDate[0].endDate
            }
            let CreatedDate = JSON.stringify(CreDate)
            //let Newocnvert = JSON.parse(CreatedDate)
            localStorage.setItem('CreatedDate', CreatedDate)
        }
        else {
            let Param = {
                Operation: StyleId,
                Id: Params.styleID,
            };

            CommonService.CheckMappedItem(Param).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Style is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    dispatch(login(currentUser.empUserName, currentUser.empPassword, 0)).then((res) => {

                    });
                    setID({ showPopupDelete: true, Params: Params });

                }
            });
        }

        // else {
        //     setID({ showPopupDelete: true, Params: Params });
        // }
    };




    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const ListType = () => {
        setListTile("Tile");
        localStorage.setItem('Listpage', 'Tile')

    }

    const TileListType = () => {
        setListTile("List");
        localStorage.setItem('Listpage', 'List')
    }


    const handleDateSelect = (event, isShow) => {

        let getStateDate = [...stateDate];
        getStateDate[0].isShow = isShow;
        setStateDate(getStateDate);
        //let Data = { Searchvalue: getStateDate, Page: 'CreatedDate' }
        // localStorage.setItem('CreatedDate', JSON.stringify(Data));
    }
    const clearDateSelect = () => {
        var defaultStartDate = new Date();
        const emptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        setStateDate(emptyStateDate);
        var defaultStartDate = new Date();
        const newemptyStateDate = [
            {
                startDate: defaultStartDate.setDate(defaultStartDate.getDate() - DateInterval),
                endDate: new Date(),
                key: 'selection',
                isShow: false
            }];
        let StartDate = null;
        let EndDate = null;
        if (moment(newemptyStateDate[0].startDate).isValid()) {
            StartDate = moment(newemptyStateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(newemptyStateDate[0].endDate).isValid()) {
            EndDate = moment(newemptyStateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
    }

    function DateRangechange(item) {
        stateDate[0].startDate = item[0].startDate;
        stateDate[0].endDate = item[0].endDate
        setStateDate(item)
        let StartDate = null;
        let EndDate = null;
        if (moment(stateDate[0].startDate).isValid()) {
            StartDate = moment(stateDate[0].startDate).format('MM/DD/YYYY');
        }
        if (moment(stateDate[0].endDate).isValid()) {
            EndDate = moment(stateDate[0].endDate).format('MM/DD/YYYY');
        }

        let params = { RouteId: 0, IsProduction: 0, employeeinformationID: currentUser.employeeinformationID, PoStartDate: StartDate, PoEndDate: EndDate }
        // if (IsProduction !== 0) {
        //     dispatch(retrieveScheduleReportList(params));
        // }
        dispatch(retrieveScheduleReportList(params));
        localStorage.setItem('StylePodateStatedate', moment(stateDate[0].startDate).format('MM/DD/YYYY'))
        localStorage.setItem('StylePodateenddate', moment(stateDate[0].endDate).format('MM/DD/YYYY'))
    }
    const handleChangeOrdStatus = (values) => {

        setCreatedbySearch(values)
        let Data = { Searchvalue: values, Page: 'CreatedBy' }
        localStorage.setItem('CreatedBy', JSON.stringify(Data));
    }

    const FilterClear = (filterName) => {
        if (filterName === 'Buyer') {
            localStorage.removeItem('Search');
            localStorage.removeItem('StyleName');
            localStorage.removeItem('Season');
            localStorage.removeItem('Brand');
            localStorage.removeItem('Story');
            localStorage.removeItem('IdPo');
            localStorage.removeItem('Theme');
            localStorage.removeItem('Gender');
            localStorage.removeItem('CreatedBy');
            setStyleSearch('');
            setSeasonSearch('');
            setBrandSearch('');
            setStorySearch('');
            setIdPoSearch('');
            setThemeSearch('');
            setGenderSearch('');
            setCreatedbySearch('');
            // var defaultStartDate = new Date();
            // const emptyStateDate = [
            //     {
            //         startDate: defaultStartDate.setDate(defaultStartDate.getDate() - 90),
            //         endDate: new Date(),
            //         key: 'selection',
            //         isShow: false
            //     }];
            // setStateDate(emptyStateDate);
        }

    }
    const handleChangeFilter = (values, filterName) => {

        if (filterName === 'Buyer') {
            setBuyerSearch(values)
            let Data = { Searchvalue: values, Page: 'Buyer' }
            localStorage.setItem('Buyer', JSON.stringify(Data));
            FilterClear(filterName);
        }
        else if (filterName === 'StyleName') {
            setStyleSearch(values)
            let Data = { Searchvalue: values, Page: 'StyleName' }
            localStorage.setItem('StyleName', JSON.stringify(Data));
        }
        else if (filterName === 'Season') {
            setSeasonSearch(values)
            let Data = { Searchvalue: values, Page: 'Season' }
            localStorage.setItem('Season', JSON.stringify(Data));
        }
        else if (filterName === "Brand") {
            setBrandSearch(values)
            let Data = { Searchvalue: values, Page: 'Brand' }
            localStorage.setItem('Brand', JSON.stringify(Data));
        }
        else if (filterName === "IdPo") {
            setIdPoSearch(values)
            let Data = { Searchvalue: values, Page: 'IdPo' }
            localStorage.setItem('IdPo', JSON.stringify(Data));
        }
        else if (filterName === "Story") {
            setStorySearch(values)
            let Data = { Searchvalue: values, Page: 'Story' }
            localStorage.setItem('Story', JSON.stringify(Data));
        }
        else if (filterName === "Theme") {
            setThemeSearch(values)
            let Data = { Searchvalue: values, Page: 'Theme' }
            localStorage.setItem('Theme', JSON.stringify(Data));
        }
        else if (filterName === "Gender") {
            setGenderSearch(values)
            let Data = { Searchvalue: values, Page: 'Gender' }
            localStorage.setItem('Gender', JSON.stringify(Data));
        }
        else if (filterName === "Importance") {
            setimportance(values)
            let Data = { Searchvalue: values, Page: 'Importance' }
            localStorage.setItem('Importance', JSON.stringify(Data));
        }
    }


    const handleclick = () => {

        let getStateDate = [...stateDate];
        getStateDate[0].isShow = false;
        setStateDate(getStateDate);
    }
    function handleChange(e, filterName) {
        let inputText = '';
        let Data;

        //var Data = { Searchvalue: inputText, Page: filterName }
        if (filterName === 'Buyer') {

            // let values = [...getBuyersearch];
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            // values= inputText;
            setBuyerSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Buyer', JSON.stringify(Data));

        }
        if (filterName === 'Style') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStyleSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Style', JSON.stringify(Data));

        }
        else if (filterName === 'Season') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setSeasonSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Season', JSON.stringify(Data));
        }
        else if (filterName === 'Brand') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setBrandSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Brand', JSON.stringify(Data));
        }
        else if (filterName === 'Story') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setStorySearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Story', JSON.stringify(Data));
        }
        else if (filterName === 'IdPo') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setIdPoSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('IdPo', JSON.stringify(Data));
        }
        else if (filterName === 'Theme') {
            if (e.target.value.trim()) {
                inputText = e.target.value;

            }
            setThemeSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Theme', JSON.stringify(Data));
        }
        else if (filterName === 'Gender') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            setGenderSearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('Gender', JSON.stringify(Data));
        }
        else if (filterName === 'CreatedDate') {
            if (e.target.value.trim()) {
                inputText = e.target.value;
            }
            //setCreatedbySearch(inputText);
            Data = { Searchvalue: inputText, Page: filterName }
            localStorage.setItem('CreatedDate', JSON.stringify(Data));
        }
        //var Data = { Searchvalue: inputText, Page: filterName }
        localStorage.setItem('Search', JSON.stringify(Data));

    };

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header">
                                <span className="widget-caption">Schedule Detail Report (90 Days)
                                </span>
                                &nbsp;
                                {ListTile !== 'List' ? '' :
                                    commentsData.length !== 0 && (
                                        <div className="table-toolbar pull-right">
                                            {/* <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span> */}

                                            <span className="btn btn-warning pdficon" title="Export to PDF" columns={columns}
                                                data={PdfValues}
                                                onClick={() => DownloadPDF()}
                                            >
                                                <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            </span>
                                            &nbsp;
                                            <span className="btn btn-success Excelicon" title="Export to Excel"
                                                columns={columns} data={ExcelValues}
                                                onClick={() => DownloadExcel()}
                                            >
                                                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                                            </span>
                                            &nbsp;
                                            <span className="btn btn-danger Print" title="Print">
                                                <i className="fa fa-print" onClick={handlePrint} />

                                                {/* <PrintDownload ref={componentRef} /> */}
                                            </span>

                                        </div>

                                    )

                                }

                                &nbsp;
                                <div>
                                    {
                                        ListTile === "List" && <span title='Tile'
                                            onClick={ListType}>
                                            <i className="menu-icon fa fa-th font_size"></i>
                                        </span>
                                    }
                                    {
                                        ListTile === "Tile" && <span title='List'
                                            onClick={TileListType}>
                                            <i className="menu-icon fa fa-list font_size"></i>
                                        </span>
                                    }
                                </div>

                            </div>
                            <div className="widget-body"
                                style={
                                    { paddingBottom: '4%' }
                                }>


                                <div id="simpledatatable_wrapper" className="dataTables_wrapper form-inline no-footer">
                                    {
                                        ShowFilter === true ?
                                            <div className="row" style={{ display: 'none' }}>
                                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                                    <div className="widget">
                                                        <div className="widget-body no-padding">
                                                            <div className="widget-main ">
                                                                <div className="panel-group accordion" id="accordion">
                                                                    <div className="panel panel-default">
                                                                        <div className="panel-heading ">
                                                                            <h4 className="panel-title">
                                                                                <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                                                                    Search Filter : Date Range <span className="rptDateRangeSpan">(Clicking on the text box will cause the date picker to appear and disappear)</span>
                                                                                </a>
                                                                            </h4>
                                                                        </div>
                                                                        <div id="collapseOne" className="panel-collapse collapse">
                                                                            <div className="panel-body border-red">
                                                                                <div className="row">
                                                                                    <div className="col-md-12">

                                                                                        <div className="col-md-2">
                                                                                            <label>Buyer</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Buyer'}
                                                                                                    placeholder={'Buyer'}
                                                                                                    value={getBuyersearch}
                                                                                                    maxlength={"50"}
                                                                                                    onChange={e => handleChange(e, 'Buyer')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Buyer"
                                                                                                    options={getBuyerFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Buyer')}
                                                                                                    value={getBuyersearch}
                                                                                                    placeholder='Select Buyer'
                                                                                                ></Reactselect>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ display: 'none' }}>
                                                                                            <label>Style Name</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Style Name'}
                                                                                                    placeholder={'Style Name'}
                                                                                                    value={getStylesearch}
                                                                                                    maxlength={"50"}
                                                                                                    onChange={e => handleChange(e, 'Style')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="StyleName"
                                                                                                    id={"StyleName"}
                                                                                                    classNamePrefix="style"
                                                                                                    options={getstyleFilterlist}
                                                                                                    isClearable={true}

                                                                                                    onChange={e => handleChangeFilter(e, 'StyleName')}
                                                                                                    value={getStylesearch}
                                                                                                    placeholder='Select Style Name'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Season</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Season'}
                                                                                                    placeholder={'Season'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getSeasonsearch}
                                                                                                    onChange={e => handleChange(e, 'Season')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Season"
                                                                                                    options={getSeasonFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Season')}
                                                                                                    value={getSeasonsearch}
                                                                                                    placeholder='Select Season'
                                                                                                ></Reactselect>

                                                                                            </span>



                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <label>Brand</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Brand'}
                                                                                                    placeholder={'Brand'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getBrandsearch}
                                                                                                    onChange={e => handleChange(e, 'Brand')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Brand"
                                                                                                    options={getBrandFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Brand')}
                                                                                                    value={getBrandsearch}
                                                                                                    placeholder='Select Brand'
                                                                                                //     .filter(function (option) {
                                                                                                //     return option.label === getcreatedby.label
                                                                                                // })}
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ display: 'none' }}>
                                                                                            <label>ID/PO No</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'IdPo'}
                                                                                                    placeholder={'ID/PO No'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getIdPosearch}
                                                                                                    onChange={e => handleChange(e, 'IdPo')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="IdPo"
                                                                                                    options={getidpoFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'IdPo')}
                                                                                                    value={getIdPosearch}
                                                                                                    placeholder='Select ID/PO No'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ display: 'none' }}>
                                                                                            <label>Story</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Story'}
                                                                                                    placeholder={'Story'}
                                                                                                    value={getStorysearch}
                                                                                                    maxlength={"50"}
                                                                                                    onChange={e => handleChange(e, 'Story')}
                                                                                                /> */}
                                                                                                <Reactselect className="basic-single" name="Story"
                                                                                                    options={getStoryFilterlist}
                                                                                                    classNamePrefix="style"
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Story')}
                                                                                                    value={getStorysearch}
                                                                                                    placeholder='Select Story'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <label>Created Date</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                <input
                                                                                                    value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                    onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                    //onBlur={event => handleDateBlur()}
                                                                                                    //onChange={e => handleChange(e, 'CreatedDate')}
                                                                                                    type="text"
                                                                                                    className={'form-control styledatapicker'}
                                                                                                    placeholder="Select Date"

                                                                                                />
                                                                                                <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                            </span>


                                                                                            <span className='input-icon icon-right mb-5'>
                                                                                                <br />
                                                                                                {stateDate[0].isShow &&
                                                                                                    <DateRangePicker
                                                                                                        //onChange={item => setStateDate([item.selection])}
                                                                                                        onChange={item => DateRangechange([item.selection])}
                                                                                                        showSelectionPreview={true}
                                                                                                        moveRangeOnFirstSelection={false}
                                                                                                        months={2}
                                                                                                        ranges={stateDate}
                                                                                                        showDateDisplay={false}
                                                                                                        direction="vertical"
                                                                                                        className={'StyleDateRangePicker'}

                                                                                                    />

                                                                                                }
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <span className="col-md-1 input-icon icon-right margin-top-30">
                                                                                                <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                                >
                                                                                                    <i className="fa fa-close"> Reset</i>
                                                                                                </button>
                                                                                            </span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div className="row">
                                                                                    <div className="col-md-12">




                                                                                    </div>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div className="row" style={{ display: 'none' }}>
                                                                                    <div className="col-md-12">
                                                                                        <div className="col-md-2">
                                                                                            <label>Theme</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                {/* <input
                                                                                                    type="text"
                                                                                                    className="form-control StyleList"
                                                                                                    title={'Theme'}
                                                                                                    placeholder={'Theme'}
                                                                                                    maxlength={"50"}
                                                                                                    value={getThemesearch}
                                                                                                    onChange={e => handleChange(e, 'Theme')}
                                                                                                /> */}
                                                                                                {/* <label>&nbsp;</label> */}
                                                                                                <Reactselect className="basic-single" name="Theme"
                                                                                                    options={getThemeFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Theme')}
                                                                                                    value={getThemesearch}
                                                                                                    placeholder='Select Theme'
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Created By</label>
                                                                                            <span className="input-icon icon-right" onClick={handleclick}>

                                                                                                <Reactselect className="basic-single" name="CreatedBy"
                                                                                                    options={getEmployeeFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeOrdStatus(e, 'CreatedBy')}
                                                                                                    value={getCreatedbysearch}
                                                                                                //     .filter(function (option) {
                                                                                                //     return option.label === getcreatedby.label
                                                                                                // })}
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Importance</label>
                                                                                            <span className="input-icon icon-right" onClick={handleclick}>

                                                                                                <Reactselect className="basic-single" name="importance"
                                                                                                    options={getimportanceFilterlist}
                                                                                                    isClearable={true}
                                                                                                    onChange={e => handleChangeFilter(e, 'Importance')}
                                                                                                    value={getimportance}
                                                                                                //     .filter(function (option) {
                                                                                                //     return option.label === getcreatedby.label
                                                                                                // })}
                                                                                                ></Reactselect>
                                                                                            </span>

                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <label>Created Date</label>
                                                                                            <span className="input-icon icon-right">
                                                                                                <input
                                                                                                    value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                        ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                            moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                    onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                    //onBlur={event => handleDateBlur()}
                                                                                                    //onChange={e => handleChange(e, 'CreatedDate')}
                                                                                                    type="text"
                                                                                                    className={'form-control styledatapicker'}
                                                                                                    placeholder="Select Date"

                                                                                                />
                                                                                                <i className="fa fa-times" onClick={clearDateSelect} ></i>
                                                                                            </span>


                                                                                            <span className='input-icon icon-right mb-5'>
                                                                                                <br />
                                                                                                {stateDate[0].isShow &&
                                                                                                    <DateRangePicker
                                                                                                        //onChange={item => setStateDate([item.selection])}
                                                                                                        onChange={item => DateRangechange([item.selection])}
                                                                                                        showSelectionPreview={true}
                                                                                                        moveRangeOnFirstSelection={false}
                                                                                                        months={2}
                                                                                                        ranges={stateDate}
                                                                                                        showDateDisplay={false}
                                                                                                        direction="vertical"
                                                                                                        className={'StyleDateRangePicker'}

                                                                                                    />

                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* <div className='col-md-3'>
                                                                                            <span className='input-icon icon-right mb-5'>
                                                                                                <span className="input-icon icon-right">
                                                                                                    <input
                                                                                                        onClick={event => handleDateSelect(event, !stateDate[0].isShow)}
                                                                                                        value={(stateDate[0].startDate === null ? '' : moment(stateDate[0].startDate).format('DD/MM/YYYY') + ' To ') +
                                                                                                            ((stateDate[0].endDate === null || !moment(stateDate[0].endDate).isValid()) ? '' :
                                                                                                                moment(stateDate[0].endDate).format('DD/MM/YYYY'))}
                                                                                                        type="text"
                                                                                                        className={'form-control'}
                                                                                                        placeholder="Select Date"
                                                                                                    />
                                                                                                    <i className="fa fa-times" onClick={clearDateSelect}></i>
                                                                                                </span>
                                                                                                <span className='input-icon icon-right mb-5'>
                                                                                                    <br />
                                                                                                    {stateDate[0].isShow &&
                                                                                                        <DateRangePicker
                                                                                                            onChange={item => setStateDate([item.selection])}
                                                                                                            showSelectionPreview={true}
                                                                                                            moveRangeOnFirstSelection={false}
                                                                                                            months={2}
                                                                                                            ranges={stateDate}
                                                                                                            showDateDisplay={false}
                                                                                                            direction="vertical"
                                                                                                            className={'StyleDateRangePicker'}
                                                                                                        />}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div> */}

                                                                                        <div className="col-md-2">
                                                                                            <span className="col-md-1 input-icon icon-right margin-top-30">
                                                                                                <button type="button" className="btn btn-danger" title="Reset" onClick={() => resetStyle()}
                                                                                                >
                                                                                                    <i className="fa fa-close"> Reset</i>
                                                                                                </button>
                                                                                            </span>

                                                                                        </div>



                                                                                    </div>
                                                                                </div>

                                                                                &nbsp;

                                                                                {/* <div className="row">
                                                                                    <div className="col-md-12">


                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    &nbsp;


                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            : ''
                                    }

                                    <div />
                                    <div className="row">
                                        <div className="table-toolbar pull-right">
                                            <span className="btn btn-danger pdficon" style={{ right: '24px' }} onClick={() => ResetOperation("Back")}>
                                                <i className="fa fa-arrow-circle-left"></i>
                                                &nbsp;Back
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        ListTile === "List" &&
                                        <div className="row">
                                            <div className="over-flow-auto-scroll">
                                                <div className="table-style-width"> {/* Adjust height as needed */}
                                                    <table className="table table-striped table-bordered table-hover dataTable no-footer ReportSchedule" id="editabledatatable">
                                                        <ScheduleHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                                                        <tbody>
                                                            {commentsData.map((comment, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    {headers.map((header, colIndex) => (
                                                                        <td key={colIndex}>
                                                                            {header.field === 'styleLogoPath' ? (
                                                                                comment[header.field] ? (
                                                                                    <img
                                                                                        src={comment[header.field]}
                                                                                        alt="Style"
                                                                                        style={{ width: '10px', height: 'auto' }} // Adjust as needed
                                                                                    />
                                                                                ) : (
                                                                                    'No Image'
                                                                                )
                                                                            ) : (
                                                                                comment[header.field] !== undefined ? comment[header.field] : '-'
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}

                                                            {totalItems === 0 ? (
                                                                <tr>
                                                                    <td colSpan={headers.length} className='norecordfound'><span>No Records Found</span></td>
                                                                </tr>
                                                            ) : ''}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                    <div>
                                        {/* --------------------Print-------------------- */}
                                        {
                                            getPrintID === true ?

                                                <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable" ref={getPrint[0].componentRef} >
                                                    <TableHeader headers={headerss}
                                                        onSorting={
                                                            (field, order) => setSorting({ field, order })
                                                        } />
                                                    <tbody>
                                                        {
                                                            commentsData.map(comment => (
                                                                <tr key={
                                                                    comment.styleID
                                                                }>

                                                                    <td>{
                                                                        comment.styleName
                                                                    }</td>
                                                                    <td>{comment.styleNo}</td>
                                                                    <td>{
                                                                        comment.seasonName
                                                                    }</td>
                                                                    <td>{
                                                                        comment.brandName
                                                                    }</td>
                                                                    <td>
                                                                        {comment.idPoNo === '' ? "-" : comment.idPoNo}
                                                                    </td>
                                                                    <td>{
                                                                        comment.stroryName === '' ? "-" : comment.stroryName
                                                                    }</td>
                                                                    <td>{
                                                                        comment.themeName === '' ? "-" : comment.themeName
                                                                    }</td>
                                                                    {/* <td>
                                                                {comment.createddate}
                                                            </td> */}
                                                                    <td>{
                                                                        comment.genderSizeName
                                                                    }</td>
                                                                    <td>{
                                                                        comment.createddate
                                                                    }</td>
                                                                    <td>{
                                                                        comment.createdByStr
                                                                    }</td>


                                                                </tr>
                                                            ))

                                                        }
                                                        {
                                                            totalItems === 0 ?
                                                                <tr>
                                                                    <td colSpan="11" className='norecordfound'><span>No Records Found</span></td>
                                                                </tr> : ''
                                                        }

                                                    </tbody>
                                                </table>
                                                : ''
                                        }
                                    </div>
                                    {
                                        ListTile === "Tile" &&
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1" >

                                            <tbody> {
                                                commentsData.map(comment => (
                                                    <div className="col-sm-3">

                                                        <div className="client_tumbline" style={{ minHeight: "310px" }}>

                                                            {/* <img className="client_logo" src={comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath} alt="Logo"
                                                                //  onClick={() => setIsOpen(comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                onClick={() => OpenSlider(comment.styleID, comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                            /> */}
                                                            < SimpleReactLightbox >
                                                                <SRLWrapper >
                                                                    {
                                                                        comment.ImageArray.map(imgInfo => (
                                                                            < img className="client_logo" style={{ display: imgInfo.skuID === 0 ? '' : 'none' }}
                                                                                src={imgInfo.imagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + imgInfo.imagePath}
                                                                                //alt={imgInfo.skuName}
                                                                                alt={!imgInfo.skuID ? 'STYLE : ' + imgInfo.skuName + " " + imgInfo.IndexTotal : 'SKU : ' + imgInfo.skuName + " " + imgInfo.IndexTotal}
                                                                            // onClick={() => setIsOpen(comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                            // comment.styleID, comment.styleLogoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.styleLogoPath)}
                                                                            // onClick={() => OpenSlider(comment.styleID, comment.styleLogoPath, comment.imagePath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.imagePath)}
                                                                            />
                                                                        ))
                                                                    }
                                                                </SRLWrapper>
                                                            </SimpleReactLightbox>

                                                            <div className="ai_button">
                                                                {comment.activeStatus === 1 && <img title="Active" alt='checked.png' src="assets/img/checked.png" />}
                                                                {comment.activeStatus === 0 && <img title="Inactive" alt='cancel.png' src="assets/img/cancel.png" />}
                                                            </div>

                                                            <table className="table_thum">
                                                                <tr className="table_rwo">
                                                                    <td className="bold_text width_10 ">Buyer Info&nbsp;:&nbsp;</td>
                                                                    <td className="bold_text width_20 cut_textInStyle" title={
                                                                        comment.buyerName + ' - ' + comment.seasonName + ' - ' + comment.brandName
                                                                    }>
                                                                        {
                                                                            comment.buyerName + ' - ' + comment.seasonName + ' - ' + comment.brandName
                                                                        } </td>
                                                                </tr>
                                                                <tr>
                                                                    <td >Style Info&nbsp;:&nbsp;</td>
                                                                    <td className="cut_textInStyle" title={comment.styleName + ' - ' + comment.styleNo}>{comment.styleName + ' - ' + comment.styleNo}
                                                                    </td>
                                                                </tr>
                                                            </table>


                                                            {/* <span title='Edit Style' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Style' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete" >
                                                                <i className="fa fa-trash-o"></i>
                                                            </span> */}

                                                            {/* <td style={{ display: activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : '' }}> */}
                                                            <span style={{ display: activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Style' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-info btn-xs edit">
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span style={{ display: activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Style' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                            &nbsp;
                                                            {
                                                                comment.importance === "1,2" ? <img type="image" src="assets/img/bell-mixed.png" title="Priority and critical" width={"10%"} alt="" /> :
                                                                    comment.importance === "2,1" ? <img type="image" src="assets/img/bell-mixed.png" title="Priority and critical" width={"10%"} alt="" /> :
                                                                        comment.importance === '1' ? <img type="image" src="assets/img/bell-yellow.png" title="Priority" width={"10%"} alt="" /> :
                                                                            comment.importance === '2' ? <img type="image" src="assets/img/bell-red.png" title="Critical" width={"10%"} alt="" /> : ""
                                                            }
                                                            {/* </td> */}


                                                        </div>
                                                    </div>


                                                ))
                                            } </tbody>
                                        </table>


                                    }
                                    <div className='norecordfound'>
                                        {
                                            totalItems === 0 && ListTile === "Tile" && <span>No Records Found</span>
                                        }



                                    </div>



                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />


                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {loader}
        </>
    )
};
export default ScheduleReportDetail;