export const BranchActionId = 1;
export const CityActionId = 2;
export const CompanyActionId = 3;
export const BuyerInfoActionId = 4;
export const GenderActionId = 5;
export const SeasonActionId = 6;
export const PantoneTypeActionId = 7;
export const PantoneActionId = 8;
export const GroupTypeId = 9;
export const MeasurementPointsId = 10;
export const strikeoffTypesId = 11;
export const TrimTypesId = 12;
export const CategoryId = 13;
export const SizeId = 14;
export const FabricId = 15;
export const MasterStrikeOffID = 16;
export const MasterTrimID = 17;
export const StyleId = 18;
export const SkuID = 19;
export const ProgramID = 20;
export const MeasurementID = 21;
export const CurrencyId = 24;
export const StyleStrikeOffId = 25;
export const StyleTrimId = 26;
export const PurchaseOrderID = 27;
export const TaskTypeActionId = 28;
export const TaskID = 29;
export const RouteActionID = 30;
export const TNAActionID = 31;
export const SwatchMasterActionId = 32;
export const RoleId = 33;
export const DepartmentId = 34;
export const FabricPartId = 35;
export const StyleSampleRequestID = 37;
export const ParentTNAList = 38;
export const ChildTNAList = 39;
export const ProcessTypeActionId = 40;
export const MasterSizeActionId = 41;
export const GenLapdipProgramInfoId = 42;
export const OrdLapdipProgramInfoId = 43;
export const GenLabdipSubmissionlId = 44;
export const GenLabdipApprovalId = 45;
export const GenLabdipRequestlId = 46;
export const GenStrikeoffInfoId = 47;
export const GenStrikeOffRequestlId = 48;
export const GenStrikeOffSubmissionlId = 49;
export const GenStrikeOffApprovalId = 50;
export const GenTrimInfoId = 51;
export const GenTrimRequestlId = 52;
export const GenTrimSubmissionlId = 53;
export const GenTrimApprovalId = 54;
export const CourierActionId = 55;
export const YarnId = 56;
export const AgencyID = 57;
export const EmailAttachment = 58;
export const TNAManualCloseParent = 59;
export const TNAManualCloseChildLibDip = 60;
export const TNAManualCloseChildStrikeOff = 61;
export const TNAManualCloseChildTrim = 62;
export const TNAManualCloseChildSms = 63;
export const TNAManualCloseChildProtoFit = 64;
export const LabdipSubmissionApprovalId = 65;
export const StrikeOffSubmissionApprovalId = 66;
export const TrimSubmissionApprovalId = 67;
export const ProtoFitSMSSubmissionApprovalId = 68;
export const AppareanceTestId = 69;
export const StateID = 70;
export const defectTypeID = 71;
export const itemID = 73;
export const commentID = 75;
export const workmanshipItemID = 72;
export const categoryID = 74;
export const templateInfoID = 76;
export const compBuySupID = 77;
export const PackageItemID = 78;
export const csrItemID = 79;
export const csrDrpItemID = 80;

export const TNAManualCloseChildExFactory = 81;

export const csrTemplateID = 82;
export const TNAManualCloseChildStrikeoffTest = 83;
export const TNAManualCloseChildTrimTest = 84;
export const ReportSkuComments = 85;
export const ReportSkuOrderStatus = 86;
export const ReportSkuExfactory = 87;
export const GenManualCloseChildLibDip = 88;
export const GenManualSubCloseChildLibDip = 89;
export const GenManualSubCloseChildStrikeoff = 90;
export const GenManualCloseAppChildStrikeoff = 91;
export const GenManualSubCloseChildTrim = 92;
export const GenManualCloseAppChildTrim = 93;

export const TestingItemID = 94;
export const TempID = 96;
export const Aqlmasterlist = 95;

export const BuyerID = 99;
export const AqlFinalInspectionList = 97;
export const PackageImageID = 98;
export const Packagetemplatelist = 100;

export const FLReportSkuComments = 101;
export const FLReportSkuOrderStatus = 102;

export const ToleranceID = 103;
export const FinalinspectionID = 104
export const FLApprovalComment = 105
export const QCCommentPoint = 106;

export const TNASamplesChild = 111;
export const QAInspectionId = 110;
export const TestName = 107;
export const TestItem = 108;
export const TestField = 109;
export const TestStandard = 112;
export const ColorDepth = 113;
export const MapID = 120;
export const QAReportSkuComments = 114;
export const BrandWiseStandard = 121;
export const QAApprovalComment = 123
export const QAApproval = 124
export const TestAlignment = 122;
export const TestingComment = 125;
export const TestEntryComment = 127;
export const TestingMainlist = 126;
export const FinalReinspectioncmt = 128;
export const QaReinspectioncmt = 129;
export const ReportmodulecategoryID=130;
export const ReporttemplateID=131;
export const ColumnInformationID=132;
export const ProductFabricType=133;
export const ProductContSpec=134;
export const ProductFabricStructure=135;
export const ProductAdditionalInfo=136;
