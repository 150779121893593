import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from "react-notifications-component";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import ProductFabricService from "../../../../services/Master/ProductFabricService";
import { useSelector } from "react-redux";

const EditProductFabricType = (props) => {
    debugger
    const { user: currentUser } = useSelector((state) => state.auth);

    const [getProductFabricType, setProductFabricType] = useState([{ productFabricTypeID: 0, productFabricTypeName: "" }]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // Pre-fill form with existing data based on props
    function BindFabricTypeValue(props) {
        let productFabricTypeID = {
            productFabricTypeID: 0,
            productFabricTypeName: "",
        };
        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.productFabricTypeID !== 0) {
                    productFabricTypeID.productFabricTypeID = props.location.state.params.productFabricTypeID;
                    productFabricTypeID.productFabricTypeName = props.location.state.params.productFabricTypeName;
                    return productFabricTypeID;
                }
            }
        }
        return productFabricTypeID;
    }

    useEffect(() => {
        const productFabricTypeID = BindFabricTypeValue(props);
        setProductFabricType(productFabricTypeID);
    }, []);

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/ProductFabricTypeList",
            state: { message: Func },
        });
    };

    const ResetOperation = (action) => {
        if (action === "Back") {
            props.history.push("/ProductFabricTypeList");
        } else {
            const productFabricTypeID = BindFabricTypeValue(props); // Reload the current data
            setProductFabricType(productFabricTypeID);
            setSubmitted(false); // Reset the submitted state
        }
    };

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify("Warning!", "warning", Msg);
    };

    const handleChange = (e) => {
        let Value = { ...getProductFabricType };
        if (e.target.name === "productFabricTypeName") {
            Value[e.target.name] = e.target.value.trim() !== "" ? e.target.value : "";
            setSubmitted(Value[e.target.name] !== "");
        }
        setProductFabricType(Value);
    };

    const SaveFabricType = (e) => {
        setButtonLoader(true);
        e.preventDefault();

        if (getProductFabricType.productFabricTypeName.trim() === "") {
            setButtonLoader(false);
            Nodify("Warning!", "warning", "Fabric Type Name is required.");
            return;
        }

        const InputData = {
            ProductFabricInformation: [
                {
                    Id: getProductFabricType.productFabricTypeID,
                    Name: getProductFabricType.productFabricTypeName,
                }
            ],
            Createdby: currentUser.employeeinformationID,
            // productFabricTypeName: getProductFabricType.productFabricTypeName.trim(),
        };

        ProductFabricService.InsertUpdateProductFabric(InputData).then((res) => {
            debugger
            var page = "Remove";
            SearchRetain(page);
            let Func = "Edit";
            if (res.data.outputResult === "1") {
                Nodify("Success!", "success", "Fabric Type updated successfully.");
                PageRedirect(Func);
            } else if (res.data.outputResult === "-1") {
                Nodify("Warning!", "warning", "This Fabric Type Name already exists.");
                InputData.productFabricTypeName = "";
                setProductFabricType(InputData);
            } else {
                Nodify("Error!", "danger", "An error occurred. Please try again.");
            }
            setButtonLoader(false);
        });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit Fabric Type</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="productFabricTypeName">
                                                Fabric Type Name<span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter Fabric Type Name"
                                                    id="productFabricTypeName"
                                                    name="productFabricTypeName"
                                                    value={getProductFabricType.productFabricTypeName}
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    style={{
                                                        border:
                                                            submitted && !getProductFabricType.productFabricTypeName
                                                                ? "1px solid red"
                                                                : "",
                                                    }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span
                            className="btn btn-primary"
                            onClick={() => ResetOperation("Back")}
                        >
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={ResetOperation}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={SaveFabricType}
                            disabled={buttonLoader}
                        >
                            <i className="fa fa-check right"></i>&nbsp;Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProductFabricType;
