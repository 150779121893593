import {
    PRODUCTCONTSPEC_LOADING,
    RETRIEVE_PRODUCTCONTSPEC,
    DELETE_PRODUCTCONTSPEC,
} from "./types";

import ProductContextSpecificationService from "../services/Master/ProductContextSpecificationService";

const ProductContSpecLoading = (isStatus) => ({
    type: PRODUCTCONTSPEC_LOADING,
    payload: isStatus,
});

// Action to retrieve the product context specification list
export const retrieveProductContSpec = () => async (dispatch) => {
    try {
        dispatch(ProductContSpecLoading(true));
        const res = await ProductContextSpecificationService.GetProductContextSpecificationList();
        dispatch({
            type: RETRIEVE_PRODUCTCONTSPEC,
            payload: res.data,
        });
        dispatch(ProductContSpecLoading(false));
    } catch (err) {
        console.error("Error fetching Product Context Specifications:", err);
        dispatch(ProductContSpecLoading(false));
    }
};

// Action to delete a specific product context specification by ID
export const deleteProductContSpec = (contSpecID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_PRODUCTCONTSPEC,
            payload: { contSpecID },
        });
    } catch (err) {
        console.error("Error deleting Product Context Specification:", err);
    }
};
