import axios from "axios";
import authHeader from "../auth-header";

class CommonService {
    DeleteRecord(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/DeleteMasterInformation?Operation=' + Params.Operation + '&Id=' + Params.Id,
            headers: {
                authorization: authHeader()
            }
        });
    }

    CheckMappedItem(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/CheckMappedItem?Operation=' + Params.Operation + '&Id=' + Params.Id,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetMenuList(EmpId) {

        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'Master/GetMenuList?EmployeeInformationId=' + EmpId,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetCubeSupList(Operation) {

        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'Master/LoadGetUspLoadCompanyBuyerSupplierEmployeeList?Operation=' + Operation,
            headers: {
                authorization: authHeader()
            }
        });
    }

    GetCubeList(BrandID) {

        return axios({
            method: 'get',
            url: window.$APIBaseURL + 'Master/LoadCompanyEmployeeListBrandwise?BrandID=' + BrandID,
            headers: {
                authorization: authHeader()
            }
        });
    }
    

    FileUpload(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'api/file' + Params,
            headers: {
                authorization: authHeader()
            }
        });
    }
    CheckMappedItemFinalinspection(Params) {
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/CheckMappedItemFinalinspection?Operation=' + Params.Operation + '&BuyerId=' + Params.BuyerId + '&BrandId=' + Params.BrandId,
            headers: {
                authorization: authHeader()
            }
        })

    }
}


export default new CommonService()