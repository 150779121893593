import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {
    retrieveProductFabricType,
    deleteProductFabricType,
} from "../../../../actions/ProductFabricType";
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import SweetAlertPopup from "../../../Common/SweetAlertPopup";
import Nodify from "../../../Common/ReactNotification";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import SearchRetain from "../../../Common/SearchRetain";
import { ProductFabricType } from "../../../Common/PageActionNumber";
import moment from "moment";

const ProductFabricTypeList = (props) => {
    const dispatch = useDispatch();

    // Redux state selectors
    const { ProductfabricTypeList, isLoadingProductFabricType } = useSelector(
        (state) => state.ProductFabricType
    );
    console.log("ProductfabricTypeList",ProductfabricTypeList)
    const { user: currentUser } = useSelector((state) => state.auth);
    const activeMenu = useSelector(
        (state) =>
            state.common.MenuList.item3?.filter((menu) => menu.menuName === "ProductFabricType")[0]
    );

    // Local states
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [getPlaceholder] = useState("Fabric Type Name");
    const [loader, showLoader, hideLoader] = useFullPageLoader();
console.log(getID)
    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Fabric Type Name", field: "fabricTypeName", sortable: true },
        { name: "Created Date", field: "createddate", sortable: true },
        {
            name: "Action",
            field: "",
            sortable: false,
            display:
                activeMenu?.isEdit === 0 && activeMenu?.isDelete === 0 ? "none" : "",
        },
    ];

    // Fetch product fabric types on mount
    useEffect(() => {
        dispatch(retrieveProductFabricType());
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success'; // default, info, warning
            let title = 'Success!';
            console.log(state1.message)
            if (state1.message === 'Edit') {
                Msg = 'Category updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Category added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    // Show or hide loader based on loading state
    useEffect(() => {
        if (isLoadingProductFabricType) {
            showLoader();
        } else {
            hideLoader();
        }
    }, [isLoadingProductFabricType]);

    // Filter and sort data
    const filteredData = useMemo(() => {
        let computedData = ProductfabricTypeList;

        if (search) {
            computedData = computedData.filter((item) =>
                item.productFabricTypeName.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedData.length);

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [ProductfabricTypeList, currentPage, search, sorting, ITEMS_PER_PAGE]);

    // Handle page redirects for add/edit actions
    const pageRedirect = (dataParams, redirect) => {
        localStorage.setItem(
            "Pagination",
            JSON.stringify({
                CurrentPage: currentPage,
                Page: getPlaceholder,
                PageItems: ITEMS_PER_PAGE,
            })
        );
        if (redirect === "Add") {
            props.history.push("/addProductFabricType", { params: [] });
        } else if (redirect === "Edit") {
            props.history.push(`/editProductFabricType`, { params: dataParams });
        } else {
            setID({ showPopupDelete: true, Params: dataParams });
        }
    };

    // Callback for delete confirmation
    const Delcallback = (value) => {
       
        setID({ showPopupDelete: false, Params: {} });
        dispatch(retrieveProductFabricType());

    };

    // Redirect to login if not authenticated
    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header">
                                <span className="widget-caption">Fabric Type List</span>
                            </div>
                            <div className="widget-body" style={{ paddingBottom: "4%" }}>
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search
                                            onSearch={(value) => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                            placeholder={getPlaceholder}
                                            props={props}
                                        />
                                    </div>
                                    <div
                                        className="table-toolbar pull-right"
                                        style={{
                                            display: activeMenu?.isAdd === 1 ? "" : "none",
                                        }}
                                    >
                                        <span
                                            className="btn btn-primary"
                                            onClick={() => pageRedirect("", "Add")}
                                            title="Add Fabric Type"
                                        >
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>
                                    <table className="table table-striped table-bordered table-hover">
                                        <TableHeader
                                            headers={headers}
                                            onSorting={(field, order) =>
                                                setSorting({ field, order })
                                            }
                                        />
                                        <tbody>
                                            {filteredData.map((item, i) => (
                                                <Fragment key={item.fabricTypeID}>
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{item.productFabricTypeName}</td>
                                                        <td>{moment(item.createddate).format('DD/MM/YYYY hh:mm A')}</td>                                                   
                                                        <td>
                                                            <span
                                                                className="btn btn-info btn-xs edit"
                                                                title="Edit Fabric Type"
                                                                onClick={() => pageRedirect(item, "Edit")}
                                                                style={{
                                                                    display: activeMenu?.isEdit === 1 ? "" : "none",
                                                                }}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </span>
                                                            &nbsp;
                                                            <span
                                                                className="btn btn-danger btn-xs delete"
                                                                title="Delete Fabric Type"
                                                                onClick={() => pageRedirect(item, "")}
                                                                style={{
                                                                    display: activeMenu?.isDelete === 1 ? "" : "none",
                                                                }}
                                                            >
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))}
                                            {totalItems === 0 && (
                                                <tr>
                                                    <td colSpan="3" className="norecordfound">
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <PageCount
                                        onPageCount={(value) => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                            </div>
                            {getID.showPopupDelete && (
                                <SweetAlertPopup
                                    data={getID.Params}
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                     pageActionId={ProductFabricType}
                                    Msg={"Fabric Type deleted successfully."}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default ProductFabricTypeList;
