import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from "react-notifications-component";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import ProductAdditionalInfoService from "../../../../services/Master/ProductAdditionalInfoService"; // Adjust service if needed
import { useSelector } from "react-redux";

const EditProductAdditionalInfo = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    // const [getProductAdditionalInfo, setProductAdditionalInfo] = useState({
    //     Operation: 2,
    //     ProductAdditionalInfoID: 0,
    //     ProductAdditionalInfoName: "",
    //     Createdby: currentUser.employeeinformationID,
    // });

    const [getProductAdditionalInfo, setProductAdditionalInfo] = useState([{ Id: 0, Name: "" }]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // Pre-fill form with existing data based on props
    function BindAdditionalInfoValue(props) {
        let additionalInfoData = {
            ProductAdditionalInfoID: 0,
            ProductAdditionalInfoName: "",
        };
        if (props.location.state !== undefined && props.location.state.params?.length !== 0) {
            if (props.location.state.params.ProductAdditionalInfoID !== 0) {
                additionalInfoData.ProductAdditionalInfoID =
                    props.location.state.params.additionalInfoID;
                additionalInfoData.ProductAdditionalInfoName =
                    props.location.state.params.additionalInfoName;
            }
        }
        return additionalInfoData;
    }

    useEffect(() => {
        const additionalInfoData = BindAdditionalInfoValue(props);
        setProductAdditionalInfo(additionalInfoData);
    }, [props]);

    const PageRedirect = (action) => {
        props.history.push({
            pathname: "/ProductAdditionalInfoList",
            state: { message: action },
        });
    };

    const ResetOperation = (action) => {
        if (action === "Back") {
            props.history.push("/ProductAdditionalInfoList");
        } else {
            const additionalInfoData = BindAdditionalInfoValue(props); // Reload the current data
            setProductAdditionalInfo(additionalInfoData);
            setSubmitted(false); // Reset the submitted state
        }
    };

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify("Warning!", "warning", Msg);
    };

    const handleChange = (e) => {
        const Value = { ...getProductAdditionalInfo };
        if (e.target.name === "ProductAdditionalInfoName") {
            Value[e.target.name] = e.target.value.trim() !== "" ? e.target.value : "";
            setSubmitted(Value[e.target.name] !== "");
        }
        setProductAdditionalInfo(Value);
    };

    const SaveAdditionalInfo = (e) => {
        e.preventDefault();
        setButtonLoader(true);

        if (getProductAdditionalInfo.ProductAdditionalInfoName.trim() === "") {
            setButtonLoader(false);
            Nodify("Warning!", "warning", "Additional Info Name is required.");
            return;
        }

        const InputData = {
            ProductAdditionalInfoInformation:[
                {
                    Id: getProductAdditionalInfo.ProductAdditionalInfoID,
                    Name: getProductAdditionalInfo.ProductAdditionalInfoName,
                }  
                ],
            Createdby: currentUser.employeeinformationID,
            // ProductAdditionalInfoName: getProductAdditionalInfo.ProductAdditionalInfoName.trim(),
        };
        
        ProductAdditionalInfoService.InsertUpdateProductAdditionalInfo(InputData).then((res) => {
            var page = "Remove";
            SearchRetain(page);
            if (res.data.outputResult === "1") {
                Nodify("Success!", "success", "Additional Info updated successfully.");
                PageRedirect("Edit");
            } else if (res.data.outputResult === "-1") {
                Nodify("Warning!", "warning", "This Additional Info Name already exists.");
                InputData.ProductAdditionalInfoName = "";
                setProductAdditionalInfo(InputData);
            } else {
                Nodify("Error!", "danger", "An error occurred. Please try again.");
            }
            setButtonLoader(false);
        });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit Additional Info</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="ProductAdditionalInfoName">
                                                Additional Info Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter Additional Info Name"
                                                    id="ProductAdditionalInfoName"
                                                    name="ProductAdditionalInfoName"
                                                    value={
                                                        getProductAdditionalInfo.ProductAdditionalInfoName
                                                    }
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    style={{
                                                        border:
                                                            submitted &&
                                                            !getProductAdditionalInfo.ProductAdditionalInfoName
                                                                ? "1px solid red"
                                                                : "",
                                                    }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span
                            className="btn btn-primary"
                            onClick={() => ResetOperation("Back")}
                        >
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={ResetOperation}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={SaveAdditionalInfo}
                            disabled={buttonLoader}
                        >
                            <i className="fa fa-check right"></i>&nbsp;Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProductAdditionalInfo;
