import {
    ROUTE_LOADING,
    RETRIEVE_ROUTE,
    DELETE_ROUTE,
    ROUTE_DEPENDENCY_DROPDOWN_LIST,
    ROUTE_TASK_OWNER_LIST,
    SUPPLIERID_ROUTE
} from "./types";

import routeService from "../services/Route/RouteService";

const routeLoading = (isStatus) => ({
    type: ROUTE_LOADING,
    payload: isStatus,
});

export const deleteRoute = (routeInformationID) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ROUTE,
            payload: routeInformationID,
        });

    } catch (err) {
        console.log(err);
    }
};

export const retrieveRoute = (RouteId,IsProduction) => async (dispatch) => {
    try {
        dispatch(routeLoading(true));
        const res = await routeService.GetRouteInformationList(RouteId,IsProduction);
        dispatch({
            type: RETRIEVE_ROUTE,
            payload: res.data,
        });
        dispatch(routeLoading(false));
    } catch (err) {
        console.log(err);
        dispatch(routeLoading(false));
    }
};

export const loadRouteDependencyList = () => async (dispatch) => {
    try {
        const res = await routeService.LoadRouteDependencyList();
        dispatch({
            type: ROUTE_DEPENDENCY_DROPDOWN_LIST,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
       
    }
};

export const loadRouteTaskOwner = (TaskHolderID, BuyerID, UserLoginID) => async (dispatch) => {
  let resData = [];
  try {
    if (TaskHolderID > 0) {
      const res = await routeService.LoadRouteTaskOwner({ TaskHolderID: TaskHolderID, BuyerID: BuyerID, UserLoginID: UserLoginID });
      dispatch({
        type: ROUTE_TASK_OWNER_LIST,
        payload: res.data,
      });
      resData = res.data;
    } else {
      dispatch({
        type: ROUTE_TASK_OWNER_LIST,
        payload: [],
      });
      resData = [];
    }
  } catch (err) {
    console.log(err);
  }
  return Promise.resolve(resData);
};

//
 export const UpdateSupplierRefence = (SupplierID) => async (dispatch) => {
  try {
    dispatch({
        type: SUPPLIERID_ROUTE,
        payload: SupplierID,
    });

} catch (err) {
    console.log(err);
}
};