import React from 'react';
import { useEffect, useMemo, useState, Fragment } from 'react';
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import { useDispatch, useSelector } from "react-redux";
import { retrieveTestField } from '../../../../actions/testfield';
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import SweetAlertPopup from '../../../Common/SweetAlertPopup';
import { TestField } from "../../../Common/PageActionNumber";
import useFullPageLoader from "../../../../hooks/useFullPageLoader";
import { retrieveBrandWiseStandard } from '../../../../actions/brandwisestandard';
import { BrandWiseStandard } from "../../../Common/PageActionNumber";
import moment from 'moment';

const BrandWiseStandardList = (props) => {
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [getPlaceholder] = useState("Brand Wise Standard");
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [totalItems, setTotalItems] = useState(0);
    const [getID, setID] = useState({ showPopupDelete: false, Params: {} });
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    //reducer
    const reducerState = useSelector((state) => state);
    const comments = reducerState.BrandWiseStandardReducer.BrandWiseStandardList.item1;
    let isLoadingBrandwiseStandard = reducerState.BrandWiseStandardReducer.isLoadingBrandwiseStandard;
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) {
        activeMenu = menulist.filter(i => i.menuName === "BrandWiseStandard");
    }

    const headers = [
        { name: "#", field: "", sortable: false },
        { name: "Buyer Name", field: "buyerName", sortable: true },
        { name: "Brand Name", field: "brandName", sortable: true },
        { name: "Internal Ref ID", field: "internalRefID", sortable: true },
        { name: "Buyer Version and Date", field: "buyerVersionAndDate", sortable: true },
        { name: "Received Date", field: "formattedReceivedOn", sortable: true },
        { name: "Effect From Date", field: "formattedEffectFromDate", sortable: true },
        { name: "Created / Modified By On Date", field: "createdBy", sortable: true },
        {
            name: "Action", field: "", sortable: false, display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },
    ];
    const dispatch = useDispatch();
    const pageRedirect = (BrandwiseParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push(`/BrandWiseStandardRegister`);
        }
        else if (redirect === 'Edit') {
            BrandwiseParams.Action = "Edit";
            props.history.push(`/BrandwiseEdit`, { params: BrandwiseParams });
        }
        else if (redirect === 'View') {
            BrandwiseParams.Action = "View";
            props.history.push(`/BrandwiseEdit`, { params: BrandwiseParams });
        } else {
            setID({ showPopupDelete: true, Params: BrandwiseParams });
        }
    };

    const Delcallback = (value) => {
        dispatch(retrieveBrandWiseStandard());
        setID({ showPopupDelete: false, Params: [] });
    }

    const commentsData = useMemo(() => {
        let computedComments = comments;
        // setTotalItems(computedComments.length || 0);
        if (computedComments != null && computedComments != undefined && computedComments.length != 0) {
            if (search) {
                computedComments = computedComments.filter(comment => comment.brandName.toLowerCase().includes(search.toLowerCase())
                    || comment.buyerName.toLowerCase().includes(search.toLowerCase())
                    // || comment.internalRefID.toLowerCase().includes(search.toLowerCase())
                    || comment.buyerName.toLowerCase().includes(search.toLowerCase()));
            }
            setTotalItems(computedComments.length);
            // Sorting comments
            // // if (sorting.field && sorting.field != "internalRefID") {
            // //     const reversed = sorting.order === "asc" ? 1 : -1;
            // //     computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
            // // }
            // // if (sorting.field == "internalRefID") {
            // //     const reversed = sorting.order === "asc" ? 1 : -1;
            // //     computedComments = computedComments.sort((a, b) => reversed * (a.internalRefID - b.internalRefID));
            // // }

            if (sorting.field) {
                const reversed = sorting.order === 'asc' ? 1 : -1;
                computedComments = computedComments.sort((a, b) => {
                    const valueA = a[sorting.field];
                    const valueB = b[sorting.field];

                    // Check if both values are numeric
                    const isNumericA = !isNaN(valueA);
                    const isNumericB = !isNaN(valueB);

                    // Check if both values are valid date-time strings using moment
                    const isDateTimeA = moment(valueA, moment.ISO_8601, true).isValid();
                    const isDateTimeB = moment(valueB, moment.ISO_8601, true).isValid();

                    if (isNumericA && isNumericB) {
                        // If both values are numeric, compare as numbers
                        return reversed * (parseFloat(valueA) - parseFloat(valueB));
                    } else if (isDateTimeA && isDateTimeB) {
                        // If both values are valid date-time strings, compare as dates
                        return reversed * (moment(valueA).valueOf() - moment(valueB).valueOf());
                    } else {
                        // If one or both values are non-numeric and non-valid date-time strings, compare as strings
                        return reversed * valueA.toString().localeCompare(valueB.toString());
                    }
                });
            }

            // Current Page slice
            let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
            if (records.length === 0 && currentPage != 1) {
                setCurrentPage(currentPage - 1);
            }
            return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        }
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE,
        totalItems
    ]);

    useEffect(() => {
        dispatch(retrieveBrandWiseStandard());
        const state1 = props.location.state;
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success';
            let title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Brand Wise Standard updated successfully.';
            } else if (state1.message === 'Add') {
                Msg = 'Brand Wise Standard added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingBrandwiseStandard) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingBrandwiseStandard]);

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="widget-header">
                    <span className="widget-caption">Brand Wise Standard list</span>
                </div>
                <div className="widget-body" style={
                    { paddingBottom: '4%' }
                }>
                    <div className="dataTables_wrapper form-inline no-footer">
                        <div className="table-toolbar pull-left">
                            <Search
                                onSearch={
                                    value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }
                                }
                                placeholder={getPlaceholder} props={props} />
                        </div>
                        <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} >
                            &nbsp;
                            <span
                                className="btn btn-primary" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}
                                title="Add Brandwise Standard" onClick={() => pageRedirect("", 'Add')}>
                                <i className="fa fa-plus"></i>&nbsp;Add
                            </span>
                        </div>
                        <table className="table table-striped table-bordered table-hover dataTable no-footer" id="editabledatatable">
                            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order })} />
                            <tbody>
                                {
                                    commentsData != undefined && commentsData.map((comment, i) => (
                                        <Fragment key={i}>
                                            <tr key={comment.id}>
                                                <td style={{ width: '25px' }}>
                                                    {i + 1}
                                                </td>
                                                <td>{comment.buyerName}</td>
                                                <td>{comment.brandName}</td>
                                                <td>{comment.internalRefID}</td>
                                                <td>{comment.buyerVersionAndDate}</td>
                                                <td>{comment.formattedReceivedOn}</td>
                                                <td>{comment.formattedEffectFromDate}</td>
                                                <td>{comment.createdBy + " On " + comment.createdDate}</td>
                                                <td style={{
                                                    display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                        activeMenu[0].isDelete === 0 ? 'none' : ''
                                                }}>
                                                    <span title='View Brand Wise Standard' className="btn btn-warning btn-xs edit"
                                                        onClick={() => pageRedirect(comment, 'View', getPlaceholder)}>
                                                        <i className='fa fa-eye'></i>
                                                    </span>&nbsp;
                                                    <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Brand Wise Standard' className="btn btn-info btn-xs edit"
                                                        onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}>
                                                        <i className='fa fa-edit'></i>
                                                    </span>&nbsp;
                                                    <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Brand Wise Standard' className="btn btn-danger btn-xs delete"
                                                        onClick={() => pageRedirect(comment, '', getPlaceholder)}>
                                                        <i className='fa fa-trash'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))
                                }
                                {
                                    totalItems === 0 ?
                                        <tr>
                                            <td colSpan="9" className='norecordfound'><span>No Records Found</span></td>
                                        </tr> : ''
                                }
                            </tbody>
                        </table>
                        <PageCount onPageCount={
                            value => {
                                setITEMS_PER_PAGE(value);
                                setCurrentPage(1);
                            }
                        } />
                        <Pagination total={totalItems}
                            itemsPerPage={
                                parseInt(ITEMS_PER_PAGE)
                            }
                            currentPage={currentPage}
                            onPageChange={
                                page => setCurrentPage(page)
                            } />
                    </div>
                </div>
                {
                    getID.showPopupDelete ? <SweetAlertPopup data={
                        getID.Params
                    }
                        deleteCallback={Delcallback}
                        showpopup={true}
                        pageActionId={BrandWiseStandard}
                        Msg={"Brand wise standard deleted successfully."} /> : null
                }
            </div>
            {loader}
        </>
    )
}
export default BrandWiseStandardList;