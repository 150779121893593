import React, { useState, useEffect, useRef } from "react";
import ReactNotification from 'react-notifications-component';
import Form from "react-validation/build/form";
import MapItem from "../CommonComponent/MapItem";
import validator from 'validator';
import Nodify from "../../Common/ReactNotification"
import SearchRetain from "../../Common/SearchRetain";
import BuyerService from "../../../services/Master/BuyerService";
import { useSelector } from "react-redux";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const BuyerInfoMultiple = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [getPONoVisible, setPONoVisible] = useState(false);
    const [getBuyerInfo, setBuyerInfo] = useState({});
    const [getChildMapItemList, setChildMapItemList] = useState({});
    const [MapItemList, setMapItemList] = useState([]);
    const [ButtonName, setButtonName] = useState("Save");
    const [submitted, setSubmitted] = useState({ BuyerID: false });
    const [getstate_BuyerInfoID, setstate_BuyerInfoID] = useState(0);
    const [getHeaderName, addHeaderName] = useState("Add");
    const [getBuyerList, setBuyerList] = useState([]);
    const [getStoryMandatory, setStoryMandatory] = useState(false);
    const [getThemeMandatory, setThemeMandatory] = useState(false);
    const [getPOFocusPONo, setPOFocusPONo] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [getNotes, setNotes] = useState("NOTE : When story or theme mandatory option checked. Need to add atleast one story or theme map items");


    const inputRef = useRef();
    let ListOperation = 1;

    const reducerState = useSelector((state) => state);
    const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
    let BuyerListOptions = [];

    function SetBuyerInfo(props) {
        let BuyerInfoValue = {
            Operation: 1,
            BuyerID: 0,
            Createdby: currentUser.employeeinformationID,
            IsStoryMandatory: 0,
            IsThemeMandatory: 0,
            IsPOFocusPONo: 0
        };

        if (props.location.state !== undefined) {
            if (props.location.state.params.length !== 0) {
                if (props.location.state.params.buyerID !== "") {
                    setstate_BuyerInfoID(props.location.state.params.buyerID);
                    setButtonName("Update");
                    ListOperation = 2;
                    BuyerInfoValue.Operation = 2;
                    BuyerInfoValue.BuyerID = props.location.state.params.buyerID;
                    BuyerInfoValue.IsStoryMandatory = props.location.state.params.isStoryMandatory;
                    if (props.location.state.params.isStoryMandatory === 1) { setStoryMandatory(true); }
                    else { setStoryMandatory(false); }
                    BuyerInfoValue.IsThemeMandatory = props.location.state.params.isThemeMandatory;
                    if (props.location.state.params.isThemeMandatory === 1) { setThemeMandatory(true); }
                    else { setThemeMandatory(false); }
                    BuyerInfoValue.IsPOFocusPONo = props.location.state.params.isPOFocusPONo ? 1 : 0;
                    if (props.location.state.params.isPOFocusPONo === true) { setPOFocusPONo(true); }
                    else { setPOFocusPONo(false); }
                    addHeaderName("Edit");
                    return BuyerInfoValue;
                }
            }
        } else {
            return BuyerInfoValue;
        }
    }

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                //SaveBuyerInfo(event);
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    useEffect(() => {
       
        let getBuyerInfo = SetBuyerInfo(props);
        setBuyerInfo(getBuyerInfo);

        BuyerService.LoadBuyerList(ListOperation)
            .then((response) => {
                if (response.data) {
                    debugger;
                    // BuyerListOptions = response.data;
                    // setBuyerList(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
                    // let hj =  BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId));
                    setBuyerList(response.data);
                    let Buyervalues = BuyerListOptions.filter(x => x.value === parseInt(props.location?.state?.params.buyerID));
                    let Isvalid = Buyervalues.filter(x => x.label === "Focus International")
                    if (Isvalid.length > 0) {
                        setPONoVisible(true);
                    } else {
                        setPONoVisible(false);
                    }
                } else {
                }
            })
            .catch(() => { });
        // AddressList
        if (props.location.state !== undefined) {
            BuyerService.GetBuyerInfoList(2, props.location.state.params.buyerID, 0).then((response) => {
                if (response.data) {
                    if (response.data.length !== 0) {
                        setMapItemList(response.data);
                    }
                    else {
                        setMapItemList([])
                    }
                }
            }).catch(() => { });
        }
    }, []);

    const handleChange = (e) => {
       
        let buyerInfoValue = getBuyerInfo;
        let inputText = '';
        if (e.target.value.trim() !== '') {
            inputText = e.target.value;
            setSubmitted(true);
        }
        let Buyervalues = getBuyerList.filter(x => x.value === parseInt(e.target.value));
        let Isvalid = Buyervalues.filter(x => x.label === "Focus International")
        if (Isvalid.length > 0) {
            setPONoVisible(true);
        } else {
            setPONoVisible(false);
        }
        if (e.target.name === "BuyerID") {
            if (submitted.Reference === true) {
                setSubmitted({ BuyerID: false });
            }
            else {
                setSubmitted({ BuyerID: false });
            }

            buyerInfoValue[e.target.name] = parseInt(inputText);
            setstate_BuyerInfoID(parseInt(inputText));
        }
        if (e.target.name === "IsStoryMandatory") {
            if (!e.target.checked) {
                buyerInfoValue[e.target.name] = 0;
                setStoryMandatory(false);
            } else {
                buyerInfoValue[e.target.name] = 1;
                setStoryMandatory(true);
            }
        }

        if (e.target.name === "IsThemeMandatory") {
            if (!e.target.checked) {
                buyerInfoValue[e.target.name] = 0;
                setThemeMandatory(false);
            } else {
                buyerInfoValue[e.target.name] = 1;
                setThemeMandatory(true);
            }
        }
        if (e.target.name === "IsPOFocusPONo") {
            if (!e.target.checked) {
                buyerInfoValue[e.target.name] = 0;
                setPOFocusPONo(false);
            } else {
                buyerInfoValue[e.target.name] = 1;
                setPOFocusPONo(true);
            }
        }
        setBuyerInfo(buyerInfoValue);
    }

    const PageRedirect = (Func) => {
        props.history.push({
            pathname: "/MapBuyer",
            state: {
                message: Func
            }
        });
    }
    const MapItemListcallback = (value) => {
        setChildMapItemList(value);
    }

    const [MapItemFields, setMapItemFields] = useState([{
        BuyerInfoID: 0,
        BuyerMapID: 0,
        MapingTextName: '',
        BuyerInfoStatus: 0,
        BuyerMapItemID: 0,
    }]);

    const ResetOperation = (e) => {
        if (e === "Back") {
            props.history.push({
                pathname: "/MapBuyer"
            });
        } else {
            //  props.history.push({
            //     pathname: "/MapItemsBuyer"
            // });
            window.location.reload();
        }
    }

    const SaveBuyerInfo = (e) => {
        setButtonLoader(true);
        let IsValid = true;
        let ValidationMsg = "";
        setSubmitted({ BuyerID: true });
        e.preventDefault();
        if (getBuyerInfo.BuyerID === 0) {
            setButtonLoader(false);
            ValidationPopup("Please fill all mandatory (*) fields.");
        }
        if (getBuyerInfo.BuyerID !== 0) {
            if (getChildMapItemList.length === undefined) {
                if (getBuyerInfo.Operation === 1) {
                    IsValid = false;
                    ValidationMsg = "Please map any one Item.";
                    setButtonLoader(false);
                    ValidationPopup(ValidationMsg);
                    return false;
                }
                else {

                    if (getBuyerInfo.IsStoryMandatory === 1) {
                        var StoryList = MapItemList.filter(x => x.buyerMapID === 3).length;
                        if (StoryList === 0) {
                            IsValid = false;
                            ValidationMsg = "Story map item is mandatory.";
                            ValidationPopup(ValidationMsg);
                            return false;
                        }
                    }
                    if (getBuyerInfo.IsThemeMandatory === 1) {
                        var ThemeList = MapItemList.filter(x => x.buyerMapID === 4).length;
                        if (ThemeList === 0) {
                            IsValid = false;
                            ValidationMsg = "Theme map item is mandatory.";
                            setButtonLoader(false);
                            ValidationPopup(ValidationMsg);
                            return false;
                        }
                    }
                    getBuyerInfo.BuyerInfo = MapItemFields;
                }
            }
            else {
                for (var index = 0; index < getChildMapItemList.length; index++) {
                    if (getChildMapItemList[index].MapingTextName !== '') {
                    }
                    else {
                        IsValid = false;
                        ValidationMsg = "Please fill all mandatory (*) fields.";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        return false;
                    }
                }
                getBuyerInfo.BuyerInfo = getChildMapItemList;
                if (getBuyerInfo.IsStoryMandatory === 1) {
                    var StoryList = getChildMapItemList.filter(x => x.BuyerMapID === 3).length;
                    if (StoryList === 0) {
                        IsValid = false;
                        ValidationMsg = "Story map item is mandatory.";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        return false;
                    }
                }
                if (getBuyerInfo.IsThemeMandatory === 1) {
                    var ThemeList = getChildMapItemList.filter(x => x.BuyerMapID === 4).length;
                    if (ThemeList === 0) {
                        IsValid = false;
                        ValidationMsg = "Theme map item is mandatory.";
                        setButtonLoader(false);
                        ValidationPopup(ValidationMsg);
                        return false;
                    }
                }
            }
            if (IsValid) {
                getBuyerInfo.IsPOFocusPONo = (getBuyerInfo.IsPOFocusPONo === 1 ? true : false);

                BuyerService.InsertUpdateBuyerInfoGrid(getBuyerInfo).then(res => {
                    var page = "Remove";
                    SearchRetain(page);
                    let Func = 'Add';
                    if (res.data.outputResult === "1") {
                        Func = 'Add';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "2" || res.data.outputResult === "-1") {
                        Func = 'Edit';
                        PageRedirect(Func);
                    } else if (res.data.outputResult === "-2") {
                        setButtonLoader(false);
                        ValidationPopup("Buyer info already exists!");
                    }
                    else if (res.data.outputResult === "0") {
                        setButtonLoader(false);
                        ValidationPopup("Error Occured!");
                    }
                });
            }
        }
        else {
            scrollToRef(inputRef);
        }
    }

    const ValidationPopup = (Msg) => {
        Nodify('Warning!', 'warning', Msg);
        setButtonLoader(false);
        return false;
    }
    return (
        <div className="page-body">
            <ReactNotification />

            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">{getHeaderName} Buyer Info</h3>
                            <div className="buttons-preview" align="right">
                                <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                                    <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                                </span>
                                <span className="btn btn-danger" onClick={() => ResetOperation()}>
                                    <i className="fa fa-times"></i>&nbsp;Reset
                                </span>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={SaveBuyerInfo}
                                    disabled={buttonLoader}
                                >
                                    <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                                </button>
                            </div>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <label htmlFor="BuyerID">
                                                        Buyer Name <span className="text-danger">*</span>
                                                    </label>
                                                    <span className="input-icon icon-right">
                                                        <select
                                                            id="BuyerID"
                                                            name="BuyerID"
                                                            className="form-select"
                                                            value={getBuyerInfo.BuyerID}
                                                            onChange={handleChange}
                                                            ref={inputRef}
                                                            disabled={getBuyerInfo.Operation === 2 ? true : false}
                                                            style={{
                                                                border:
                                                                    submitted.BuyerID && getBuyerInfo.BuyerID === 0
                                                                        ? "1px solid red"
                                                                        : "",
                                                            }}
                                                        >
                                                            <option value="0">- Select Buyer Name -</option>
                                                            {getBuyerList.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}{" "}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label htmlFor={"IsStoryMandatory"} style={{ marginTop: "27px" }}>
                                                    <input type="checkbox"
                                                        id={"IsStoryMandatory"}
                                                        name="IsStoryMandatory"
                                                        value={
                                                            getBuyerInfo.IsStoryMandatory
                                                        }
                                                        checked={getStoryMandatory}
                                                        onChange={handleChange}
                                                    />
                                                    <span htmlFor={"IsStoryMandatory"} className="text">Story ?</span>
                                                </label>
                                            </div>

                                            <div className="col-md-1">
                                                <label htmlFor={"IsThemeMandatory"} style={{ marginTop: "27px" }}>
                                                    <input type="checkbox"
                                                        id={"IsThemeMandatory"}
                                                        name="IsThemeMandatory"
                                                        value={
                                                            getBuyerInfo.IsThemeMandatory
                                                        }
                                                        checked={getThemeMandatory}
                                                        onChange={handleChange}
                                                    />
                                                    <span htmlFor={"IsThemeMandatory"} className="text">Theme ?</span>
                                                </label>
                                            </div>
                                            {getPONoVisible && <div className="col-md-1">
                                                <label htmlFor={"IsPOFocusPONo"} style={{ marginTop: "27px" }}>
                                                    <input type="checkbox"
                                                        id={"IsPOFocusPONo"}
                                                        name="IsPOFocusPONo"
                                                        value={
                                                            getBuyerInfo.IsPOFocusPONo
                                                        }
                                                        checked={getPOFocusPONo}
                                                        onChange={handleChange}
                                                    />
                                                    <span htmlFor={"IsPOFocusPONo"} className="text">PO No ?</span>
                                                </label>
                                            </div>}
                                        </div>

                                        <div className="col-md-12">
                                            <h5 className="WarningMsg">{getNotes}</h5>
                                        </div>

                                    </div>
                                    {/* <div className="row">
                                        <div class="container" style={{ width: '700px', marginLeft: '0px' }}>
                                            <div className="alert alert-info">
                                                Note : Choosing Focus International Buyer will allow you to select Focus PO No Checkbox.
                                            </div>
                                        </div>
                                    </div> */}
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="tabbable">
                        <ul className="nav nav-tabs nav-justified" id="myTab5">
                            <li className="active">
                                <a data-toggle="tab" href="#idCompanyAddress" style={{ textAlign: "left" }}>
                                    <h5>MAP ITEM(S)</h5>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div id="idCompanyHoliday" className="tab-pane" style={{ width: "100%", display: "block" }}>
                                <MapItem mapItemCallback={MapItemListcallback}
                                    MapItemList={MapItemList}
                                    BuyerInfoId={getstate_BuyerInfoID}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={() => ResetOperation()}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={SaveBuyerInfo}
                            disabled={buttonLoader}
                        >
                            <i className="fa fa-check right"></i> &nbsp;{ButtonName}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyerInfoMultiple;