import { 
    PRODUCTCONTSPEC_LOADING,
    RETRIEVE_PRODUCTCONTSPEC,
    DELETE_PRODUCTCONTSPEC,
} from "../actions/types";

const initialState = {
    isLoadingProductContSpec: true,
    ProductContSpecList: [],
};

const ProductContSpecReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PRODUCTCONTSPEC_LOADING:
            return {
                ...state,
                isLoadingProductContSpec: payload,
            };
        case RETRIEVE_PRODUCTCONTSPEC:
            return { 
                ...state, 
                ProductContSpecList: payload 
            };
        case DELETE_PRODUCTCONTSPEC:
            const updatedList = state.ProductContSpecList.filter(
                (item) => item.contSpecID !== payload.contSpecID
            );
            return {
                ...state,
                ProductContSpecList: updatedList,
            };
        default:
            return state;
    }
};

export default ProductContSpecReducer;
