import {
    PRODUCTADDITIONALINFO_LOADING,
    RETRIEVE_PRODUCTADDITIONALINFO,
    DELETE_PRODUCTADDITIONALINFO,
} from "../actions/types";

const initialState = {
    isLoadingProductAdditionalInfo: true,
    ProductAdditionalInfoList: [],
};

const ProductAdditionalInfoReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PRODUCTADDITIONALINFO_LOADING:
            return {
                ...state,
                isLoadingProductAdditionalInfo: payload,
            };
        case RETRIEVE_PRODUCTADDITIONALINFO:
            return {
                ...state,
                ProductAdditionalInfoList: payload,
            };
        case DELETE_PRODUCTADDITIONALINFO:
            const updatedList = state.ProductAdditionalInfoList.filter(
                (item) => item.infoID !== payload.infoID
            );
            return {
                ...state,
                ProductAdditionalInfoList: updatedList,
            };
        default:
            return state;
    }
};

export default ProductAdditionalInfoReducer;
