import React, { useState, useEffect, useMemo,Fragment} from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { CompanyActionId } from "../../Common/PageActionNumber";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import Lightbox from "react-image-lightbox"
import { Redirect } from 'react-router-dom';
//import { useSelector } from "react-redux";
import Nodify from "../../Common/ReactNotification";
import companyService from "../../../services/Master/Companyservice";
import { BuyerModuleId } from "../../Common/ModuleID";
import SearchRetain from "../../Common/SearchRetain"
import CommonService from '../../../services/Common/CommonService';
import { useDispatch, useSelector } from "react-redux";
import { retrieveBuyer } from "../../../actions/buyer"

const BuyerList = (props) => { // Selecters
    // const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [getID, setID] = useState(0);
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    const [isOpen, setIsOpen] = useState(false)
    const [ListTile, setListTile] = useState("List");
    //const { user: currentUser } = useSelector((state) => state.auth);
    const [getPlaceholder] = useState("Buyer Name / Reference Name");

    const dispatch = useDispatch();

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "BuyerList"); }
    // End Enable Menu

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.buyer.buyerList;
    let isLoadingBuyer = reducerState.buyer.isLoadingBuyer;

    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    useEffect(() => {
        let params = {
            companyid: 0, tabletype: 3
        }
        dispatch(retrieveBuyer(params));
        // showLoader();
        // setTimeout(() => {
        //      companyService.getAllCompanyBuyerSupList(BuyerModuleId).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //             //hideLoader();
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success'; //default, info, warning
            let Title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Buyer Information updated successfully.';
                // Type = 'default';
            } else if (state1.message === 'Add') {
                Msg = 'Buyer Information added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                Title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(Title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
        //         } else {
        //             //hideLoader();
        //         }
        //     }).catch(() => {
        //        // hideLoader();
        //     }).finally(() => {
        //         hideLoader();
        //     });
        // }, 500)
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingBuyer) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingBuyer]);


    const headers = [
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize:'25px',
        },
        {
            name: "Logo",
            field: "",
            sortable: false,
            widthsize: "7%"
        },
        {
            name: "Buyer Name",
            field: "name",
            sortable: true,
            widthsize: "28%"
        },
        {
            name: " Reference Name",
            field: "referenceName",
            sortable: true,
            widthsize: "28%"
        },
        {
            name: "Status",
            field: "statusName",
            sortable: true,
            widthsize: "28%"
        },
        {
            name: "Action ",
            field: "",
            sortable: false,
            widthsize: "9%",
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 &&
                activeMenu[0].isView === 0 ? 'none' : ''
        },
    ];

    const commentsData = useMemo(() => {
        //let computedComments = comments;

        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.companyBuyerSupID === buyerId && isAllInfo === 1 && isMapped === 0));
           let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
           isAllInfo === 0 && isMapped === 1 
           //&& o.cubeEmployeeIdList != undefined
            && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
           debugger;
            if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }

        if (search) {
            computedComments = computedComments.filter(comment => comment.name.toLowerCase().includes(search.toLowerCase()) || comment.referenceName.toLowerCase().includes(search.toLowerCase()));
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    // Actions
    const pageRedirect = (buyerParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/BuyerRegister', { params: { action: "Add" } });
        }
        else if (redirect === 'Edit') {
            buyerParams.action = "Edit";
            props.history.push(`/BuyerRegister`, { params: buyerParams });
        }
        else if (redirect === 'View') {
            buyerParams.action = "View";
            props.history.push(`/BuyerRegister`, { params: buyerParams });
        }
        else {
            let Params = {
                Operation: 3,
                Id: buyerParams.companyBuyerSupID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";
                    let message = "This Buyer is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: buyerParams });
                }
            });
        }
    };


    const ListType = () => {
        setListTile("Tile");
    }

    const TileListType = () => {
        setListTile("List");
    }

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    const Delcallback = (value) => {
        // if (value === true) {
        //     companyService.getAllCompanyBuyerSupList(BuyerModuleId).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //         }
        //     });
        // }
        setID({ showPopupDelete: false, Params: [] });
    };

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Buyer List Information</span>
                                {
                                    ListTile === "List" && <span title='Tile'
                                        onClick={ListType}>
                                        <i className="menu-icon fa fa-th font_size"></i>
                                    </span>
                                }
                                {
                                    ListTile === "Tile" && <span title='List'
                                        onClick={TileListType}>
                                        <i className="menu-icon fa fa-list font_size"></i>
                                    </span>
                                } </div>
                            <div> {
                                isOpen && (
                                    <Lightbox
                                        mainSrc={isOpen}
                                        alt="bg image"

                                        onCloseRequest={
                                            () => setIsOpen(false)
                                        } />
                                )
                            } </div>
                            <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;
                                        {/* <a href="/BuyerRegister" className="btn btn-primary">
                                             <i className="fa fa-plus"></i>
                                            <span>
                                                Add
                                        </span>
                                        </a> */}
                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Buyer">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>

                                    {
                                        ListTile === "List" &&
                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                            <TableHeader headers={headers}
                                                onSorting={
                                                    (field, order) => setSorting({ field, order })
                                                } />
                                            <tbody>
                                                {
                                                    commentsData.map((comment,i) => (
                                                      <Fragment key={i}>
                                                        <tr>
                                                            <td>
                                                                {i+1}
                                                            </td>
                                                            <td className="trwidth_manual">
                                                                {
                                                                    < img className="width_manual"
                                                                        src={comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath}
                                                                        alt="Logo"

                                                                        onClick={
                                                                            () => setIsOpen(comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath)
                                                                        } />
                                                                }</td>

                                                            <td>{
                                                                comment.name
                                                            }</td>
                                                            <td>{
                                                                comment.referenceName
                                                            }</td>
                                                            <td> {
                                                                comment.activeStatus === 1 &&
                                                                <span className="green">Active</span>
                                                            }
                                                                {
                                                                    comment.activeStatus === 0 &&
                                                                    <span className="red">Inactive</span>
                                                                } </td>

                                                            <td style={{
                                                                display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                    activeMenu[0].isDelete === 0 &&
                                                                    activeMenu[0].isView === 0 ? 'none' : ''
                                                            }}>
                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Buyer' onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                    className="btn btn-success btn-xs edit">

                                                                    <i className="fa fa-edit "></i>
                                                                </span>
                                                                &nbsp;

                                                                <span style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} title='View Buyer' onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                    className="btn btn-white btn-xs eye">
                                                                    <i className="fa fa-eye "></i>
                                                                </span>
                                                                &nbsp;
                                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Buyer' onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                    className="btn btn-danger btn-xs delete">
                                                                    <i className="fa fa-trash-o"></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                      </Fragment>
                                                    ))
                                                }
                                                {
                                                    totalItems === 0 ?
                                                        <tr>
                                                            <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                        </tr> : ''
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        ListTile === "Tile" &&

                                        <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">

                                            <tbody> {
                                                commentsData.map(comment => (



                                                    <div className="col-sm-4">
                                                        <div className="client_tumbline">


                                                            <img className="client_logo" src={comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath} alt="Logo" onClick={
                                                                () => setIsOpen(comment.logoPath === "" ? "assets/img/emptyImage.jpg" : window.$APIBaseURL + 'Images' + comment.logoPath)
                                                            } />

                                                            <div className="ai_button">
                                                                {comment.activeStatus === 1 && <img title="Active" alt='checked.png' src="assets/img/checked.png" />}
                                                                {comment.activeStatus === 0 && <img title="Inactive" alt='cancel.png' src="assets/img/cancel.png" />}
                                                            </div>

                                                            <table className="table_thum">
                                                                <tr className="table_rwo">
                                                                    <td className="bold_text width_10">Buyer&nbsp;:&nbsp;</td>
                                                                    <td className="bold_text width_20 BuyerTileCommentName cut_textInStyle" Title={comment.name}>
                                                                        {
                                                                            comment.name
                                                                        } </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="bold_text width_10">Reference&nbsp;:&nbsp;</td>
                                                                    <td className="bold_text width_20 BuyerTileCommentName cut_textInStyle" Title={comment.referenceName}>
                                                                        {
                                                                            comment.referenceName
                                                                        }</td>
                                                                </tr>
                                                            </table>


                                                            <span title='Edit Buyer' style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, 'Edit', getPlaceholder)}
                                                                className="btn btn-success btn-xs edit">

                                                                <i className="fa fa-edit "></i>
                                                            </span>
                                                            &nbsp;

                                                            <span title='View Buyer' style={{ display: activeMenu && activeMenu[0].isView === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, 'View', getPlaceholder)}
                                                                className="btn btn-white btn-xs eye">
                                                                <i className="fa fa-eye "></i>
                                                            </span>
                                                            &nbsp;
                                                            <span title='Delete Buyer' style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} onClick={() => pageRedirect(comment, '', getPlaceholder)}
                                                                className="btn btn-danger btn-xs delete">
                                                                <i className="fa fa-trash-o"></i>
                                                            </span>


                                                        </div>
                                                    </div>))
                                            } </tbody>
                                        </table>
                                    }
                                    <div className='norecordfound'>
                                        {
                                            totalItems === 0 && ListTile === "Tile" && <span>No Records Found</span>
                                        }
                                    </div>
                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={CompanyActionId}
                                    Msg={"Buyer information deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader} </>
    )
};

export default BuyerList