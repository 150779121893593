import React, { useState, useEffect } from "react";
import Nodify from "../../../Common/ReactNotification";
import SearchRetain from "../../../Common/SearchRetain";
import ReactNotification from "react-notifications-component";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import ProductContextSpecificationService from "../../../../services/Master/ProductContextSpecificationService"; // Replace with appropriate service
import { useSelector } from "react-redux";

const EditProductContextSpecification = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    // const [getProductContextSpecification, setProductContextSpecification] = useState({
    //     Operation: 2,
    //     ProductContSpecificationID: 0,
    //     ProductContSpecificationName: "",
    //     Createdby: currentUser.employeeinformationID,
    // });

    const [getProductContextSpecification, setProductContextSpecification] = useState([{ contSpecificationID: 0, contSpecificationName: "" }]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // Pre-fill form with existing data based on props
    function BindContextSpecificationValue(props) {
        let contextSpecData = {
            ProductContSpecificationID: 0,
            ProductContSpecificationName: "",
        };
        if (props.location.state !== undefined && props.location.state.params?.length !== 0) {
            if (props.location.state.params.ProductContSpecificationID !== 0) {
                contextSpecData.ProductContSpecificationID =
                    props.location.state.params.contSpecificationID;
                contextSpecData.ProductContSpecificationName =
                    props.location.state.params.contSpecificationName;
            }
        }
        return contextSpecData;
    }

    useEffect(() => {
        const contextSpecData = BindContextSpecificationValue(props);
        setProductContextSpecification(contextSpecData);
    }, [props]);

    const PageRedirect = (action) => {
        props.history.push({
            pathname: "/ProductContSpecList",
            state: { message: action },
        });
    };

    const ResetOperation = (action) => {
        if (action === "Back") {
            props.history.push("/ProductContSpecList");
        } else {
            const contextSpecData = BindContextSpecificationValue(props); // Reload the current data
            setProductContextSpecification(contextSpecData);
            setSubmitted(false); // Reset the submitted state
        }
    };

    const ValidationPopup = (Msg) => {
        setButtonLoader(false);
        Nodify("Warning!", "warning", Msg);
    };

    const handleChange = (e) => {
        const Value = { ...getProductContextSpecification };
        if (e.target.name === "ProductContSpecificationName") {
            Value[e.target.name] = e.target.value.trim() !== "" ? e.target.value : "";
            setSubmitted(Value[e.target.name] !== "");
        }
        setProductContextSpecification(Value);
    };

    const SaveContextSpecification = (e) => {
        e.preventDefault();
        setButtonLoader(true);

        if (getProductContextSpecification.ProductContSpecificationName.trim() === "") {
            setButtonLoader(false);
            Nodify("Warning!", "warning", "Content Specification Name is required.");
            return;
        }

        console.log(getProductContextSpecification)
        const InputData = {
             //ProductContSpecificationInformation: [getProductContextSpecification],
             ProductContSpecificationInformation: [
                {
                 Id:getProductContextSpecification.ProductContSpecificationID,
                 Name:getProductContextSpecification.ProductContSpecificationName,  
                }
        ],
            Createdby: currentUser.employeeinformationID,
            // ProductContSpecificationName: getProductContextSpecification.ProductContSpecificationName.trim(),
        };

        ProductContextSpecificationService.InsertUpdateProductContextSpecification(InputData).then((res) => {
            var page = "Remove";
            SearchRetain(page);
            if (res.data.outputResult === "1") {
                Nodify("Success!", "success", "Content Specification updated successfully.");
                PageRedirect("Edit");
            } else if (res.data.outputResult === "-1") {
                Nodify("Warning!", "warning", "This Content Specification Name already exists.");
                InputData.ProductContSpecificationName = "";
                setProductContextSpecification(InputData);
            } else {
                Nodify("Error!", "danger", "An error occurred. Please try again.");
            }
            setButtonLoader(false);
        });
    };

    return (
        <div className="page-body">
            <ReactNotification />
            <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="widget flat radius-bordered">
                        <div className="widget-header heading_box_style">
                            <h3 className="heading_style_common">Edit Content Specification</h3>
                        </div>
                        <div className="widget-body">
                            <div id="registration-form">
                                <Form>
                                    <div className="row">
                                        <div className="form-group col-sm-4">
                                            <label htmlFor="ProductContSpecificationName">
                                                Content Specification Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <span className="input-icon icon-right">
                                                <Input
                                                    placeholder="Enter Content Specification Name"
                                                    id="ProductContSpecificationName"
                                                    name="ProductContSpecificationName"
                                                    value={
                                                        getProductContextSpecification.ProductContSpecificationName
                                                    }
                                                    type="text"
                                                    maxLength="50"
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    style={{
                                                        border:
                                                            submitted &&
                                                            !getProductContextSpecification.ProductContSpecificationName
                                                                ? "1px solid red"
                                                                : "",
                                                    }}
                                                    autoFocus
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="buttons-preview" align="right" style={{ marginTop: "15px" }}>
                        <span
                            className="btn btn-primary"
                            onClick={() => ResetOperation("Back")}
                        >
                            <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                        </span>
                        <span className="btn btn-danger" onClick={ResetOperation}>
                            <i className="fa fa-times"></i>&nbsp;Reset
                        </span>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={SaveContextSpecification}
                            disabled={buttonLoader}
                        >
                            <i className="fa fa-check right"></i>&nbsp;Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProductContextSpecification;
