import {
    PRODUCTFABRICSTRUCTURE_LOADING,
    RETRIEVE_PRODUCTFABRICSTRUCTURE,
    DELETE_PRODUCTFABRICSTRUCTURE,
} from "../actions/types";

const initialState = {
    isLoadingProductFabricStructure: true,
    ProductFabricStructureList: [],
};

const ProductFabricStructureReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PRODUCTFABRICSTRUCTURE_LOADING:
            return {
                ...state,
                isLoadingProductFabricStructure: payload,
            };
        case RETRIEVE_PRODUCTFABRICSTRUCTURE:
            return {
                ...state,
                ProductFabricStructureList: payload,
            };
        case DELETE_PRODUCTFABRICSTRUCTURE:
            const updatedList = state.ProductFabricStructureList.filter(
                (item) => item.structureID !== payload.structureID
            );
            return {
                ...state,
                ProductFabricStructureList: updatedList,
            };
        default:
            return state;
    }
};

export default ProductFabricStructureReducer;
