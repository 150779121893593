import axios from "axios";
import authHeader from "../auth-header";

class ProductFabricStructure {
   

    GetProductFabricStructure() {
        return axios.get(window.$APIBaseURL + "Master/GetProductFabricStructure", {
            headers: {
                authorization: authHeader()
            }
        });
    }



    InsertUpdateProductFabricStructure(Params) {
        debugger
        return axios({
            method: 'post',
            url: window.$APIBaseURL + 'Master/InsertUpdateProductFabricStructure',
            data: Params,
            headers: {
                authorization: authHeader()
            }
        });
    }


}
export default new ProductFabricStructure();