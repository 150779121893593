import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../../Common/ReactNotification";
import validator from 'validator'
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../../Datatable";
import BuyerService from "../../../../services/Master/BuyerService"
import SelectCombobox from 'react-select';
import SearchRetain from "../../../Common/SearchRetain";
import TestReportTemp from "../../../../services/Master/TestingReortTemplateService";
import TestingReportDetail from "../../../../services/Master/TestingReportDetail";
import styleService from "../../../../services/Style/styleService"
import FinalinspectionService from "../../../../services/FinalInspection/FinalinspectionService";


const ToleranceAdd = (props) => {

 const [GetItem, SetItem] = useState([{ Value: 0, label: '' }]);
 const [GetAllItemDetails, setAllItemDetails] = useState([{ Value: 0, label: '' }]);
 const [getValues, setValues] = useState([{ ToleranceID: 0, BuyerID: 0, BuyerName: "", BrandID: 0, BrandName: '', OfferQty: '' }]);
 const [GetBrandState, setBrandState] = useState([{ Value: 0, label: '' }]);
 const [ItemList, setItemList] = useState([]);
 const [getBuyerDetails, setBuyerDetails] = useState([]);
 const [getBrandDetails, setBrandDetails] = useState([]);
 const [getBuyerBrandDetails, setBuyerBrandDetails] = useState([]);
;
 const [getExistingBuyerData, setExistingBuyerData] = useState([]);
 const [ExistingList, setExistingList] = useState([]);
 const [submitted, setSubmitted] = useState(false);
 const [buttonLoader, setButtonLoader] = useState(false);
 const { user: currentUser } = useSelector((state) => state.auth);
 
 const reducerState = useSelector((state) => state);
 const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;

 let styles = {
  control: styles => ({ ...styles, border: '1px solid red' }),

 }

 useEffect(() => {
 


  FinalinspectionService.getToleranceList(0).then((res) => {
   if (res.data) {
    setExistingBuyerData(res.data)
   }
  })





  let BuyerDetail = [];
  let BuyerListOptions = [];
  // const { user: currentUser } = useSelector((state) => state.auth);
   // const { user: currentUser } = useSelector((state) => state.auth);


  BuyerService.LoadBuyerList(2).then((res) => {

   if (res.data) {
  //   res.data.map(x => {
  //    BuyerDetail.push({
  //     label: x.name,
  //     value: x.id
  //    })
  //   }
  
  // )
  BuyerListOptions = res.data;
  setBuyerDetails(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));

    //setBuyerDetails(BuyerDetail)
   }
  })



 }, [])

 const PageRedirect = (Func) => {
  props.history.push({ pathname: "/ToleranceList", state: { message: Func } });
 }

 const handleAddFields = (event, index) => {
 
  const values = [...getValues];
  if (values[index].BrandID !== 0) {
   values.push({ ToleranceID: 0, BuyerID: 0, BuyerName: '', BrandID: 0, BrandName: '', OfferQty: '', })
   setValues(values);
   setSubmitted(false);
  }
  else {
   Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
   setSubmitted(true);
  }
 };

 const handleRemoveFields = index => {
  const values = [...getValues];
  values.splice(index, 1);
  setValues(values);
 };

 const handleDropdownChange = (event, FieldName, index) => {
 
  const values = [...getValues];
  //const valuesone = [...GetBrandState]
  let inputText = '';
  if (FieldName === "Buyer") {
   if (event !== null) {
    values[index].BuyerID = event.value;
    values[index].BuyerName = event.label;
    inputText = event.value
   } else {
    values[index].BuyerID = 0;
    values[index].BuyerName = ''
   }
    styleService.LoadBrandThemeStoryEndCustomer(8, 0).then((res) => {
     
      if (res.data) {
        setBrandDetails(res.data);
      }
    })


  }

  else if (FieldName === "Brand") {
   if (event !== null) {
    values[index].BrandID = event.value;
    values[index].BrandName = event.label;
    inputText = event.value
   } else {
    values[index].BrandID = index;
    values[index].BrandName = ''
   }
   if (getExistingBuyerData.length >= 1)
    for (var j = 0; j < (getExistingBuyerData.length); j++) {

     if (values[0].BuyerID === getExistingBuyerData[j].buyerID && values[0].BrandID === getExistingBuyerData[j].brandID) {

      values[0].BrandID = 0;
      values[0].BrandName = "";
      setValues(values);
      Nodify('Warning!', 'warning', 'This Buyer Brand Combination  already exist.');

      return false;
     }
    }
  }

  else {
   if (!isNaN(event.target.value)) {
    inputText = parseInt(event.target.value)
    if (inputText <= 100) {
     values[index].OfferQty = inputText
    } else {
     values[index].OfferQty = ''
    }
   }
   else {
    inputText = '';
    values[index].OfferQty = inputText
  }
}

  setValues(values);
 };


 const ResetOperation = (e) => {
  if (e === "Back") {
   props.history.push('/ToleranceList');
  } else {
   window.location.reload();
  }
 }

 const Savetolerance = (e) => {
 ;
  setButtonLoader(true);
  e.preventDefault();
  if (getValues[0].BuyerID === 0 || getValues[0].BrandID === 0 || getValues[0].OfferQty === 0) {
   Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
   setSubmitted(true);
   setButtonLoader(false);
   return false;
  }

  let Inputmaster = { Operation: 1, getTolerance: getValues, CreatedBy: currentUser.employeeinformationID }
  FinalinspectionService.InsertTolerance(Inputmaster).then((res) => {
   var page = "Remove";
   SearchRetain(page);

   let Func = 'Add';
   if (res.data.outputResult === "1") {
    Func = 'Add';
    PageRedirect(Func);
   } else if (res.data.outputResult === "2") {
    Func = 'Edit';
    PageRedirect(Func);
   }
   else if (res.data.outputResult === "0") {
    setButtonLoader(false);
    Nodify('Warning!', 'warning', "Error Occured!");
   }
  })

 }




 return (
  <div className="page-body">
   <ReactNotification />
   <div className="row">
    <div className="col-lg-12 col-sm-12 col-xs-12">
     <div className="widget flat radius-bordered">
      <div className="widget-header heading_box_style">
       <h3 className="heading_style_common">Add AQL Tolerance</h3>
      </div>

      <div className="widget-body">
       {
        getValues.map((x, index) => (
         <div className="row">




          <div className="col-lg-3">
           <label>Buyer Name<span className="text-danger">*</span></label>
           <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
            isDisabled={false}
            isLoading={false}
            isSearchable={true}
            isClearable={true}
            autocomplete='off'
            placeholder="Select Buyer Detail"
            styles={submitted && x.BuyerID === 0 ? styles : ''}
            onChange={event => handleDropdownChange(event, "Buyer", index)}
            options={getBuyerDetails}
            value={getBuyerDetails.filter(function (option) {
             return option.value === x.BuyerID;
            })}

           />
          </div>

          <div className="col-lg-3">
           <label>Brand Name<span className="text-danger">*</span></label>

           <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
            isDisabled={false}
            isLoading={false}
            isSearchable={true}
            isClearable={true}
            autocomplete='off'
            placeholder="Select Brand Detail"
            styles={submitted && x.BrandID === 0 ? styles : ''}
            onChange={event => handleDropdownChange(event, "Brand", index)}
            options={getBrandDetails.filter(xy => xy.buyerID === x.BuyerID)}
            value={getBrandDetails.filter(function (option) {
             return option.value === x.BrandID;
            })}
           />
          </div>

          <div className="col-lg-3">
           <label>Offer Qty Tolerance (Percentage)<span className="text-danger">*</span></label>

           <input type="text"
            className="form-control"
            id={"Name_"}
            name="Name"
            value={x.OfferQty}
            onChange={(event) => handleDropdownChange(event, '', index)}
            placeholder="Enter OfferQty"
            max='100'
            //onBlur={() => CheckDuplicate(index)}
            style={{ border: submitted && !x.OfferQty ? '1px solid red' : '' }}
           />
          </div>

          <div className="col-lg-3 paddingstatic">
           <label htmlFor=""></label>
           <br />
           {
            getValues.length >= 1 && (
             <span title='Add'
              onClick={(event) => handleAddFields(event, index)}
              className="btn btn-info btn-xs plus">
              <i className="fa fa-plus"></i>
             </span>
            )
           }
           &nbsp;
           {
            getValues.length !== 1 && (
             <span title='Delete'
              onClick={() => handleRemoveFields(index)}
              className="btn btn-danger btn-xs delete">
              <i className="fa fa-trash-o"></i>
             </span>
            )
           }
          </div>
         </div>

        ))
       }
       <div className="row">
        <div className="col-sm-12">
         <div className="buttons-preview" align="right">
          <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
           <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
          </span>
          <span className="btn btn-danger" onClick={() => ResetOperation()}>
           <i className="fa fa-times"></i>&nbsp;Reset
          </span>
          <button type="button" className="btn btn-success" disabled={buttonLoader}
           onClick={Savetolerance}
          >
           <i className="fa fa-check right"></i>&nbsp;Save
          </button>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 )


}

export default ToleranceAdd