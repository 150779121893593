import React, { useState, useEffect, useRef, Fragment } from "react";
import Nodify from "../../Common/ReactNotification";
import validator from 'validator'
import { useSelector } from "react-redux";
import ReactNotification from 'react-notifications-component';
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import TemplateService from "../../../services/Master/TemplateService";
import ItemService from "../../../services/Master/ItemService";
import BuyerService from "../../../services/Master/BuyerService"
import SelectCombobox from 'react-select';
import SearchRetain from "../../Common/SearchRetain";
import CsrService from "../../../services/Master/CsrService";
import styleService from "../../../services/Style/styleService"
import CsrTemplateService from "../../../services/Master/CsrTemplateService";
import Select from 'react-select'

const CSRTemplateAdd = (props) => {

  const [getTempName, setTempName] = useState([{ Id: 0, Name: "", }]);
  const [GetItem, SetItem] = useState([{ Value: 0, label: '', ValueOne: 0, labelOne: '' }]);
  const [GetAllItemDetails, setAllItemDetails] = useState([{ Value: 0, label: '' }]);
  const [GetBrandState, setBrandState] = useState([{ Value: 0, label: '' }]);
  const [ItemList, setItemList] = useState([]);
  const [getBuyerDetails, setBuyerDetails] = useState([]);
  const [getBrandDetails, setBrandDetails] = useState([]);
  const [getBuyerBrandDetails, setBuyerBrandDetails] = useState([]);
 ;
  const [ExistingList, setExistingList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const reducerState = useSelector((state) => state);
  const BuyerBrandDetails = [] = reducerState.auth.UserMenuList.item6;
  let BuyerListOptions = [];

  const headers = [
    { name: <span>Name<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: < span >Input Type<span className="text-danger">*</span></span>, field: "itemName", sortable: false },
    { name: "Action", field: "", sortable: false, },
  ];

  const options = [


    { value: 1, label: 'Drop Down' },
    { value: 2, label: 'Text Box' },
  ]
  let styles = {
    control: styles => ({ ...styles, border: '1px solid red' }),

  }

  useEffect(() => {
    CsrTemplateService.getAllCSRTemplateDetails(2, 0).then((response) => {
      if (response.data) {
        setExistingList(response.data);
      }
    });
  }, []);

  useEffect(() => {
    let CSRDetails = [];
    CsrService.getAllCSRDetails().then((res) => {
      if (res.data) {
        res.data.map(x => {
          CSRDetails.push({
            label: x.itemName,
            value: x.itemID
          })
        })
        setItemList(CSRDetails)
      }
    })

    let BuyerDetail = [];
    BuyerService.LoadBuyerList(2).then((res) => {

      if (res.data) {
        // res.data.map(x => {
        //   BuyerDetail.push({
        //     label: x.name,
        //     value: x.id
        //   })
        // })

        // setBuyerDetails(BuyerDetail)
        BuyerListOptions = res.data;
        debugger;
        setBuyerDetails(BuyerListOptions.filter(o => BuyerBrandDetails.some(({ buyerId }) => o.id === buyerId)));
      }
    })

    styleService.LoadBrandThemeStoryEndCustomer(6, 0).then((res) => {

      if (res.data) {
        setBrandDetails(res.data);
      }
    })

  }, [])

  const PageRedirect = (Func) => {
    props.history.push({ pathname: "/CSRTemplateList", state: { message: Func } });
  }
  // const CheckDuplicate = (index) => {

  //   const values = [...getTempName];
  //   if (ExistingList.templateList1) {
  //     for (var i = 0; i < ExistingList.templateList1.length; i++) {
  //       if (ExistingList.templateList1[i].templatename.trim().toLowerCase() === values[index].Name.trim().toLowerCase()) {
  //         values[index].Name = '';
  //         setTempName(values);
  //         Nodify('Warning!', 'warning', "This Template is already exist.");
  //         return false;
  //       }
  //     }
  //   }
  // }
  const handleAddFields = (event, index) => {
   
    const values = [...GetItem];
    if (values[index].Value !== 0 && values[index].Value !== undefined) {
      // Create a new row
      const newRow = { id: Date.now() };

      // Update the rows array
      SetItem((prevRows) => {
        // Insert the new row at the specified index
        const updatedRows = [...prevRows.slice(0, index + 1), newRow, ...prevRows.slice(index + 1)];

        // Increment the indices of rows following the insertion point
        const updatedRowsWithIds = updatedRows.map((row, i) => ({ ...row, id: i }));

        return updatedRowsWithIds;
      });
      // values.push({ Value: 0, label: "" });
      // SetItem(values);
      setSubmitted(false);
    }
    else {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
    }
  };

  const handleRemoveFields = index => {
    const values = [...GetItem];
    values.splice(index, 1);
    SetItem(values);
  };
  const handleDropdownChange = (event, FieldName) => {

    const values = [...GetAllItemDetails];
    const valuesone = [...GetBrandState]
    let inputText = '';
    if (FieldName === "Buyer") {
      if (event !== null) {
        values[0].Value = event.value;
        values[0].label = event.label;
        inputText = event.value
      } else {
        values[0].Value = 0;
        values[0].label = ''
      }
      let respected_Brand = getBrandDetails.filter(x => (x.buyerID === parseInt(inputText)) && (x.buyerMapID === 1));
      setBuyerBrandDetails(respected_Brand);
    }

    if (FieldName === "Brand") {
      if (event !== null) {
        valuesone[0].Value = event.value;
        valuesone[0].label = event.label;
        inputText = event.value
      } else {
        valuesone[0].Value = 0;
        valuesone[0].label = ''
      }
     ;
      if (ExistingList.csrTemplateEdit2) {
        for (var i = 0; i < ExistingList.csrTemplateEdit2.length; i++) {
          if (ExistingList.csrTemplateEdit2[i].brandID === valuesone[0].Value && ExistingList.csrTemplateEdit2[i].buyerID === values[0].Value) {
            //values[index].Name = '';
            valuesone[0].Value = 0;
            Nodify('Warning!', 'warning', "This Brand is already exist.");
            return false;
          }
        }

      }

    }
    setAllItemDetails(values);
    setBrandState(valuesone)
  };



  const handleInputItemchange = (event, index, FieldName) => {

    const values = [...GetItem];
    const allitem = [...GetAllItemDetails]
    const brandid = [...GetBrandState]
    if (FieldName === "Item") {
      if (event !== null) {
        values[index].Value = event.value;
        values[index].label = event.label;
      } else {
        values[index].Value = 0;
        values[index].label = ''
      }
    }


    if (FieldName === "InputType") {
      if (event !== null) {
        values[index].ValueOne = event.value;
        values[index].labelOne = event.label;
      } else {
        values[index].ValueOne = 0;
        values[index].labelOne = ''
      }
    }

    SetItem(values);

    if (values.length > 1)
      for (var j = 0; j < (GetItem.length); j++) {
        if (j !== index) {
          if (values[j].Value === values[index].Value && values[j].ValueOne === values[index].ValueOne) {
            values[index].ValueOne = 0;
            values[index].Value = 0;
            SetItem(values);
            Nodify('Warning!', 'warning', 'This Input Type is already exist in Template.');
            setSubmitted(true);
            return false;
          }
        }
      }
   ;
    if (ExistingList.csrTemplateEdit2) {
      for (var i = 0; i < ExistingList.csrTemplateEdit2.length; i++) {
        if (ExistingList.csrTemplateEdit2[i].brandID === brandid[0].Value && ExistingList.csrTemplateEdit2[i].buyerID === allitem[0].Value) {
          //values[index].Name = '';
          if (ExistingList.csrTemplateEdit2[i].inputid === values[index].ValueOne && ExistingList.csrTemplateEdit2[i].itemid === values[index].Value) {
            values[index].ValueOne = 0;
            Nodify('Warning!', 'warning', "This Input Type is already exist.");
            return false;
          }

        }
      }

    }
    setAllItemDetails(allitem);
    setBrandState(brandid)
    SetItem(values);


    //const values = [...getTempName];


  };

  const ResetOperation = (e) => {
    if (e === "Back") {
      props.history.push('/CSRTemplateList');
    } else {
      window.location.reload();
    }
  }

  const SaveWorkmanGroup = (e) => {
   ;
    setButtonLoader(true);
    e.preventDefault();
    let isvalid = true;
    let Itemid1 = []

    if (GetAllItemDetails[0].Value === 0 || GetBrandState[0].Value === 0) {
      Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
      setSubmitted(true);
      setButtonLoader(false);
      isvalid = false
      return false;
    }
    for (var i = 0; i < (GetItem.length); i++) {
      if ((GetItem[i].Value === 0 || GetItem[i].Value === undefined) || (GetItem[i].ValueOne === 0 || GetItem[i].ValueOne === undefined)) {
        Nodify('Warning!', 'warning', 'Please fill the mandatory(*) fields.');
        setSubmitted(true);
        isvalid = false
        setButtonLoader(false);
        return false;
      }
    }

    GetItem.map(x => {
      Itemid1.push({
        CommonID: 0,
        Itemid: x.Value,
        Inputid: x.ValueOne,
        Isdeleted: 0,
        InputName: x.labelOne,
      })
    })
    //let finalvalues ={Operation:1,}
    let Inputmaster = { Operation: 1, CsrTemplateInfoID: 0, BuyerID: GetAllItemDetails[0].Value, BrandID: GetBrandState[0].Value, templateInformation: Itemid1, CreatedBy: currentUser.employeeinformationID }
    CsrTemplateService.InsertCSRTemplateDetails(Inputmaster).then((res) => {
      var page = "Remove";
      SearchRetain(page);

      let Func = 'Add';
      if (res.data.outputResult === "1") {
        Func = 'Add';
        PageRedirect(Func);
      } else if (res.data.outputResult === "2") {
        Func = 'Edit';
        PageRedirect(Func);
      }
      else if (res.data.outputResult === "0") {
        setButtonLoader(false);
        Nodify('Warning!', 'warning', "Error Occured!");
      }
    })

  }

  return (
    <div className="page-body">
      <ReactNotification />
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="widget flat radius-bordered">
            <div className="widget-header heading_box_style">
              <h3 className="heading_style_common">Add CSR Template</h3>
            </div>

            <div className="widget-body">
              <div className="row">


                <div className="col-lg-3">
                  <label>Buyer Name<span className="text-danger">*</span></label>
                  <SelectCombobox className="basic-single" name="Buyer" id={"Buyer"}
                    isDisabled={false}
                    isLoading={false}
                    isSearchable={true}
                    isClearable={true}
                    autocomplete='off'
                    placeholder="Select Buyer Name"
                    styles={submitted && GetAllItemDetails[0].Value === 0 ? styles : ''}
                    onChange={event => handleDropdownChange(event, "Buyer")}
                    options={getBuyerDetails}
                    value={getBuyerDetails.filter(function (option) {
                      return option.value === GetAllItemDetails[0].Value;
                    })}

                  />
                </div>

                <div className="col-lg-3">
                  <label>Brand Name<span className="text-danger">*</span></label>
                  <SelectCombobox className="basic-single" name="Brand" id={"Brand"}
                    isDisabled={false}
                    isLoading={false}
                    isSearchable={true}
                    isClearable={true}
                    autocomplete='off'
                    placeholder="Select Brand Name"
                    styles={submitted && GetBrandState[0].Value === 0 ? styles : ''}
                    onChange={event => handleDropdownChange(event, "Brand")}
                    options={getBuyerBrandDetails}
                    value={getBuyerBrandDetails.filter(function (option) {
                      return option.value === GetBrandState[0].Value;
                    })}
                  />
                </div>

              </div>
              <div id="registration-form">
                <form>
                  {
                    <table className="table table-striped table-bordered table-hover dataTable no-footer margin-top-10" id="editabledatatable" style={{ width: "50%" }}>
                      <TableHeader headers={headers} />
                      <tbody>
                        {

                          GetItem.map((inputfeild, index) => (
                            <tr>
                              <td>
                                <SelectCombobox className="basic-single" name="Item" id={"Item" + index}
                                  isDisabled={false}
                                  isLoading={false}
                                  isSearchable={true}
                                  isClearable={true}
                                  autocomplete='off'
                                  placeholder="Select Item Name"
                                  styles={submitted && (inputfeild.Value === 0 || inputfeild.Value === undefined) ? styles : ''}
                                  onChange={event => handleInputItemchange(event, index, "Item")}
                                  options={ItemList}
                                  value={ItemList.filter(function (option) {
                                    return option.value === inputfeild.Value;
                                  })}
                                />
                              </td>
                              <td>
                                <SelectCombobox className="basic-single" name="InputType" id={"InputType" + index}
                                  isDisabled={false}
                                  isLoading={false}
                                  isSearchable={true}
                                  isClearable={true}
                                  autocomplete='off'

                                  placeholder="Select InputType"
                                  styles={submitted && (inputfeild.ValueOne === 0 || inputfeild.ValueOne === undefined) ? styles : ''}
                                  onChange={event => handleInputItemchange(event, index, "InputType")}
                                  options={options}
                                  value={options.filter(function (option) {
                                    return option.value === inputfeild.ValueOne;
                                  })}
                                />
                              </td>
                              <td style={{ width: "13%" }}>
                                {/* {
                                  GetItem.length === (index + 1) && ( */}
                                <span title='Add ItemMasterDetails Group'
                                  onClick={(event) => handleAddFields(event, index)}
                                  className="btn btn-info btn-xs plus">
                                  <i className="fa fa-plus"></i>
                                </span>
                                {/* )
                                } */}
                                &nbsp;
                                {
                                  GetItem.length !== 1 && (
                                    <span title='Delete Workmanship Group'
                                      onClick={() => handleRemoveFields(index)}
                                      className="btn btn-danger btn-xs delete">
                                      <i className="fa fa-trash-o"></i>
                                    </span>
                                  )
                                }
                              </td>
                            </tr>
                          ))

                        }

                      </tbody>
                    </table>
                  }

                  <div className="col-sm-12">
                    <div className="buttons-preview" align="right">
                      <span className="btn btn-primary" onClick={() => ResetOperation("Back")}>
                        <i className="fa fa-arrow-circle-left"></i>&nbsp;Back
                      </span>
                      <span className="btn btn-danger" onClick={() => ResetOperation()}>
                        <i className="fa fa-times"></i>&nbsp;Reset
                      </span>
                      <button type="button" className="btn btn-success" disabled={buttonLoader}
                        onClick={SaveWorkmanGroup}
                      >
                        <i className="fa fa-check right"></i>&nbsp;Save
                      </button>
                    </div>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CSRTemplateAdd