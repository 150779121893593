import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import CommonService from '../../services/Common/CommonService';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { deleteBranch } from "../../actions/branch";
import { deleteRoute } from "../../actions/route";
import { deletePurchaseOrder } from "../../actions/purchaseorder";
import { deleteStrikeoff } from "../../actions/strikeoffMaster";
import { deleteTrim } from "../../actions/trimMaster";
import { deleteTaskType } from "../../actions/taskType";
import { deleteTask } from "../../actions/task";
import { deleteRole } from "../../actions/role";
import { deleteDepartment } from "../../actions/department";
import { deleteStyle, deleteSKUStyle, deleteProgramStyle, deleteStrikeoffStyle, deleteTrimColorStyle } from "../../actions/style";
import { deleteCompany } from "../../actions/company";
import { deleteBuyerinfo } from "../../actions/buyerinfo";
import { deleteSupplier } from "../../actions/supplier";
import { deleteBuyer } from "../../actions/buyer";
import { deleteGender } from "../../actions/gender";
import { deleteSeason } from "../../actions/season";
import { deleteCurrency } from '../../actions/currency';
import { deletePantoneType } from '../../actions/pantoneType';
import { deletePantone } from '../../actions/pantone';
import { deleteFabricType } from '../../actions/fabricType';
import { deleteSize } from '../../actions/size';
import { deleteCity } from '../../actions/city';
import { deleteGrouptype } from '../../actions/grouptype';
import { deleteCategory } from '../../actions/category';
import { deleteStrikeofftype } from '../../actions/strikeofftype';
import { deleteTrimtype } from '../../actions/trimtype';
import { deleteMeasurementpoint } from '../../actions/measurementpoint';
import { deleteSwatch } from '../../actions/swatch';
import { deleteFabricPart } from '../../actions/fabricpart';
import { deleteProcessType } from '../../actions/ProcessType';
import { deleteMasterSize } from '../../actions/MasterSize';
import { deletegeneralLabdip, GetGeneralLapdipApprovalList, GetGeneralLapdipSubmissionApprovalList, GetGeneralLapdipApprovalViewList } from "../../actions/generalLabdip";
import { deletegeneralStrikeOff, GetGeneralStrikeOffApprovalList, RemoveStrikeOffRequestRow, GetGeneralStrikeOffApprovalViewList } from "../../actions/generalStrikeOff";
import { RemoveTrimRequestRow, GetGeneralTrimApprovalList, GetGeneralTrimSubmissionApprovalGridList, GetGeneralTrimApprovalViewList } from "../../actions/generalTrim";
import { LoadCourierDetails } from '../../actions/courier';
import { deleteYarnType } from '../../actions/yarntype';
import { LoadAgencyDetails } from '../../actions/agency';
import { deleteMailAttachment } from "../../actions/style";
import { loadTNADetailList } from "../../actions/tna";
import { deleteState } from "../../actions/State";

import { useDispatch } from "react-redux";
import { deleteDefect } from '../../actions/defect';
import { deleteItem } from '../../actions/item';
import { deleteTestingItem } from '../../actions/testingreport';
import { deletetestreptemp } from '../../actions/testreptemp';
import { deletetBuyCusWar } from '../../actions/buycuswar';

import { deleteQA } from '../../actions/qa';

import { deleteWorkmanshipIT } from "../../actions/workmanshipitem"
import { deleteWorkmanshipGrp } from "../../actions/workmangroup"
import { deletetemplate } from '../../actions/template';
import { deleteWarehouse } from "../../actions/warehouse"
import { deletePackageItem } from "../../actions/packageitem"
import { deleteCsrDetails, deleteCsrDropdownDetails } from '../../actions/csrdetails';
import { deleteCsrtemplate } from '../../actions/csrTemplate';
import { deleteQCCommentPoint } from '../../actions/qacommentpoint';
import { deleteTestItem } from '../../actions/testitem';
import { deleteBrandWiseStandard } from '../../actions/brandwisestandard';
import { deleteMap } from '../../actions/testname';
import { deleteAlignent } from '../../actions/alignment';

import { deleteReportModuleCategory } from "../../actions/reportmodulecategory";

import { deleteReporttemplate } from "../../actions/reporttemplate";

import { deleteColumnInforamtion } from "../../actions/columninformation";

import {deleteProductFabricType} from "../../actions/ProductFabricType";
import {deleteProductContSpec} from "../../actions/ProductContSpec";
import {deleteProductFabricStructure  } from "../../actions/ProductFabricStructure";
import {deleteProductAdditionalInfo } from "../../actions/ProductAdditionalInfo";


const SweetAlertPopup = ({

    data,
    deleteCallback,
    showpopup,
    pageActionId,
    searchParams,
    Msg
}) => {

    const [show, setShow] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        setShow(true);
    }, [data]);

    const handleClose = () => {
        setShow(false);
        deleteCallback(false);
        //    window.location.reload(false);
    };

    const deleteRow = () => {
       
        let Id = 0;
        if (pageActionId === 1) { Id = data.addressTypeID }
        else if (pageActionId === 2) { Id = data.cityID }
        else if (pageActionId === 3) { Id = data.companyBuyerSupID }
        else if (pageActionId === 4) { Id = data.buyerMapItemID }
        else if (pageActionId === 5) { Id = data.genderSizeID }
        else if (pageActionId === 6) { Id = data.seasonID }
        else if (pageActionId === 7) { Id = data.pantoneTypeID }
        else if (pageActionId === 8) { Id = data.pantoneID }
        else if (pageActionId === 9) { Id = data.groupTypeID }
        else if (pageActionId === 10) { Id = data.measurePointID }
        else if (pageActionId === 11) { Id = data.strikeOffTypeID }
        else if (pageActionId === 12) { Id = data.trimTypeID }
        else if (pageActionId === 13) { Id = data.categoryID }
        else if (pageActionId === 14) { Id = data.sizeDetailID }
        else if (pageActionId === 15) { Id = data.fabricDetailsID }
        else if (pageActionId === 16) { Id = data.strikeOffID }
        else if (pageActionId === 17) { Id = data.trimInfoID }
        else if (pageActionId === 18) { Id = data.styleID }
        else if (pageActionId === 19) { Id = data.skuID }
        else if (pageActionId === 20) { Id = data.styleProgramID }
        else if (pageActionId === 21) { Id = data.styleMeasurementID }
        else if (pageActionId === 24) { Id = data.currencyId }
        else if (pageActionId === 25) { Id = data.styleStrikeOffPrintColorID }
        else if (pageActionId === 26) { Id = data.styleTrimLogoInfoID }
        else if (pageActionId === 27) { Id = data.purchaseOrderID }
        else if (pageActionId === 28) { Id = data.taskTypeId }
        else if (pageActionId === 29) { Id = data.taskDetailsID }
        else if (pageActionId === 30) { Id = data.routeInformationID }
        else if (pageActionId === 32) { Id = data.swatchMasterID }
        else if (pageActionId === 33) { Id = data.roleID }
        else if (pageActionId === 34) { Id = data.departmentID }
        else if (pageActionId === 35) { Id = data.fabricPartID }
        else if (pageActionId === 37) { Id = data.styleSampleRequestID }
        else if (pageActionId === 38) { Id = data.tnaid }
        else if (pageActionId === 39) { Id = data.tnaid }
        else if (pageActionId === 40) { Id = data.processTypeID }
        else if (pageActionId === 41) { Id = data.sizeID }
        else if (pageActionId === 42) { Id = data.genLapdipProgramInfoId }
        else if (pageActionId === 43) { Id = data.empMenuPermissionId }
        else if (pageActionId === 44) { Id = data.genLapdipProgramInfoId }
        else if (pageActionId === 45) { Id = data.genLapdipProgramInfoId }
        else if (pageActionId === 46) { Id = data.genralLabdipTNAProgramDetailId }
        else if (pageActionId === 47) { Id = data.genStrikeOffRefernceID }
        else if (pageActionId === 48) { Id = data.genStrikeOffDetailTNAId }
        else if (pageActionId === 49) { Id = data.genStrikeOffId }
        else if (pageActionId === 50) { Id = data.genStrikeOffId }
        else if (pageActionId === 51) { Id = data.genTrimRefernceID }
        else if (pageActionId === 52) { Id = data.genTrimDetailTNAId }
        else if (pageActionId === 53) { Id = data.genTrimId }
        else if (pageActionId === 54) { Id = data.genTrimId }
        else if (pageActionId === 55) { Id = data.id }
        else if (pageActionId === 56) { Id = data.yarnTypeID }
        else if (pageActionId === 57) { Id = data.id }
        else if (pageActionId === 58) {
            Id = data.attachmentID

        }

        else if (pageActionId === 59 || pageActionId === 60 || pageActionId === 61 || pageActionId === 62
            || pageActionId === 63 || pageActionId === 64 || pageActionId === 81 || pageActionId === 83 || pageActionId === 84) { // Manual Close Delete(Parent and child)
            Id = data.Id
        }
        else if (pageActionId === 88 || pageActionId === 89) {
            Id = data.Id
        }
        else if (pageActionId === 90 || pageActionId === 91) {
            Id = data.Id
        }
        else if (pageActionId === 92 || pageActionId === 93) {
            Id = data.Id
        }
        else if (pageActionId === 95) {
            Id = data.Id
        }
        else if (pageActionId === 97) {
            Id = data.Id
        }
        else if (pageActionId === 65) {
            Id = data

        }
        else if (pageActionId === 66) {
            Id = data

        } else if (pageActionId === 67) {
            Id = data

        }
        else if (pageActionId === 68) {
            Id = data

        }
        else if (pageActionId === 69) {
            Id = data

        }
        else if (pageActionId === 70) {
            Id = data.stateID
        }
        else if (pageActionId === 71) {
            Id = data.defectTypeID
        }
        else if (pageActionId === 73) {
            Id = data.itemID
        }
        else if (pageActionId === 75) {
            Id = data.commentID
        }
        else if (pageActionId === 76) {
            Id = data.templateInfoID
        }
        else if (pageActionId === 72) {
            Id = data.workmanshipItemID
        }
        else if (pageActionId === 74) {
            Id = data.categoryID
        }
        else if (pageActionId === 77) {
            Id = data.compBuySupID
        }
        else if (pageActionId === 78) {
            Id = data.packageItemID
        }
        else if (pageActionId === 79) {
            Id = data.itemID
        }
        else if (pageActionId === 80) {
            Id = data.itemID
        }
        else if (pageActionId === 82) {
            Id = data.brandID
        }
        else if (pageActionId === 85) {
            Id = data.Id
        }
        else if (pageActionId === 86) {
            Id = data.Id
        }

        else if (pageActionId === 87) {
            Id = data.Id
        }
        else if (pageActionId === 94) {
            Id = data.itemID
        }
        else if (pageActionId === 96) {
            Id = data.tempID
        }
        else if (pageActionId === 99) {
            Id = data.buyerID

        }
        else if (pageActionId === 98) {
            Id = data.Id
        }
        else if (pageActionId === 100) {
            Id = data.Id
        }
        else if (pageActionId === 101) {
            Id = data.Id
        }
        else if (pageActionId === 114) {
            Id = data.Id
        }
        else if (pageActionId === 102) {
            Id = data.Id
        }
        else if (pageActionId === 103) {
            Id = data.Id
        }
        else if (pageActionId === 104) {
            Id = data.Id
        }
        else if (pageActionId === 105) {
            Id = data.Id
        }
        else if (pageActionId === 106) {
            Id = data.Id == undefined ? data.id : data.Id;
        }
        else if (pageActionId === 107) {
            Id = data.testNameID
        }
        else if (pageActionId === 110) {
            Id = data.Id
        }
        else if (pageActionId === 108) {
            Id = data.testItemID
        }
        else if (pageActionId === 109) {
            Id = data.testFieldID
        }
        else if (pageActionId === 112) {
            Id = data.standardID
        }
        else if (pageActionId === 113) {
            Id = data.colorDepthID
        }

        else if (pageActionId === 120) {
            Id = data.commonID

        }

        else if (pageActionId === 121) {
            Id = data.id
        }
        else if (pageActionId === 123) {
            Id = data.Id
        }
        else if (pageActionId === 124) {
            Id = data.Id
        }

        else if (pageActionId === 122) {
            Id = data.id
        }

        else if (pageActionId === 125) {
            Id = data.Id
        }

        else if (pageActionId === 127) {
            Id = data.Id
        }

        else if (pageActionId === 126) {
            Id = data.Id
        }
        else if (pageActionId === 128) {
            Id = data.Id
        }
        else if (pageActionId === 129) {
            Id = data.Id
        }

        else if (pageActionId === 130) { Id = data.reportModuleCategoryID }
        else if (pageActionId === 131) { Id = data.reportTemplateID }
        else if (pageActionId === 132) { Id = data.moduleID }
        else if (pageActionId === 133) { Id = data.productFabricTypeID }
        else if (pageActionId === 134) { Id = data.contSpecificationID }
        else if (pageActionId === 135) { Id = data.fabricStructureID }
        else if (pageActionId === 136) { Id = data.additionalInfoID }

        let Params = {
            Operation: pageActionId,
            Id: Id,
            Msg: Msg
        };
        let DeleteModule = "This Value";
        if (Params.Operation === 2) { DeleteModule = "This City" }
        else if (Params.Operation === 1) { DeleteModule = "This Branch" }
        else if (Params.Operation === 3) { DeleteModule = "This Buyer" }
        else if (Params.Operation === 5) { DeleteModule = "This Gender" }
        else if (Params.Operation === 6) { DeleteModule = "This Season" }
        else if (Params.Operation === 33) { DeleteModule = "This Role" }
        else if (Params.Operation === 34) { DeleteModule = "This Department" }
        else if (Params.Operation === 32) { DeleteModule = "This Swatch" }
        else if (Params.Operation === 40) { DeleteModule = "This processType" }


        CommonService.DeleteRecord(Params).then(res => {
           debugger;
            setShow(false);
            var Title = "Error!";
            var message = "Error occurred";
            var type = "warning";
            if (res.data.outputResult === "1") {
                Title = "Success!";
                message = Params.Msg;
                type = "success";
                if (Params.Operation === 94) {
                    dispatch(deleteTestingItem({ TestingItemID: Params.Id }));
                }
                if (Params.Operation === 96) {
                    dispatch(deletetestreptemp({ TempID: Params.Id }));
                }
                if (Params.Operation === 99) {
                    dispatch(deletetBuyCusWar({ BuyerID: Params.Id }));
                }
                if (Params.Operation === 120) {
                    dispatch(deleteMap({ CommonID: Params.Id }));
                }
                if (Params.Operation === 71) {
                    dispatch(deleteDefect({ defectTypeID: Params.Id }));
                }
                if (Params.Operation === 73) {
                    dispatch(deleteItem({ itemID: Params.Id }));
                }
                if (Params.Operation === 75) {
                    dispatch(deleteQA({ commentID: Params.Id }));
                }
                if (Params.Operation === 76) {
                    dispatch(deletetemplate({ templateInfoID: Params.Id }));
                }
                if (Params.Operation === 77) {
                    dispatch(deleteWarehouse({ compBuySupID: Params.Id }));
                }
                if (Params.Operation === 78) {
                    dispatch(deletePackageItem({ PackageItemID: Params.Id }));
                }
                if (Params.Operation === 79) {
                    dispatch(deleteCsrDetails({ itemID: Params.Id }));
                }
                if (Params.Operation === 80) {
                    dispatch(deleteCsrDropdownDetails({ itemID: Params.Id }));
                }
                if (Params.Operation === 82) {
                    dispatch(deleteCsrtemplate({ brandID: Params.Id }));
                }
                if (Params.Operation === 72) {
                    dispatch(deleteWorkmanshipIT({ workmanshipItemID: Params.Id }));
                }
                if (Params.Operation === 74) {
                    dispatch(deleteWorkmanshipGrp({ workmanshipItemID: Params.Id }));
                }
                if (Params.Operation === 1) {
                    dispatch(deleteBranch({ addressTypeID: Params.Id }));
                }
                else if (Params.Operation === 16) {
                    dispatch(deleteStrikeoff({ strikeOffID: Params.Id }));
                }
                else if (Params.Operation === 17) {
                    dispatch(deleteTrim({ trimInfoID: Params.Id }));
                }
                else if (Params.Operation === 18) {
                    dispatch(deleteStyle({ styleID: Params.Id }));
                }
                else if (Params.Operation === 19) {
                    dispatch(deleteSKUStyle({ SKUID: Params.Id }));
                }
                else if (Params.Operation === 20) {
                    dispatch(deleteProgramStyle({ styleProgramID: Params.Id }));
                }
                else if (Params.Operation === 25) {
                    // dispatch(deleteStrikeoffStyle({ styleStrikeOffPrintColorID: Params.Id }));
                }
                else if (Params.Operation === 26) {
                    // dispatch(deleteTrimColorStyle({ styleTrimLogoInfoID: Params.Id }));
                }
                else if (Params.Operation === 27) {
                    dispatch(deletePurchaseOrder({ purchaseOrderID: Params.Id }));
                }
                else if (Params.Operation === 29) {
                    dispatch(deleteTask({ taskDetailsID: Params.Id }));
                }
                else if (Params.Operation === 28) {
                    dispatch(deleteTaskType({ taskTypeId: Params.Id }));
                }
                else if (Params.Operation === 30) {
                    dispatch(deleteRoute({ routeInformationID: Params.Id }));
                }
                else if (Params.Operation === 33) {
                    dispatch(deleteRole({ roleID: Params.Id }));
                }
                else if (Params.Operation === 34) {
                    dispatch(deleteDepartment({ departmentID: Params.Id }));
                }
                else if (Params.Operation === 3) {
                    dispatch(deleteCompany({ companyBuyerSupID: Params.Id }));
                    dispatch(deleteSupplier({ companyBuyerSupID: Params.Id }));
                    dispatch(deleteBuyer({ companyBuyerSupID: Params.Id }));
                }
                else if (Params.Operation === 4) {
                    dispatch(deleteBuyerinfo({ buyerMapItemID: Params.Id }));
                }

                else if (Params.Operation === 5) {
                    dispatch(deleteGender({ genderSizeID: Params.Id }));
                }
                else if (Params.Operation === 6) {
                    dispatch(deleteSeason({ seasonID: Params.Id }));
                }
                else if (Params.Operation === 7) {
                    dispatch(deletePantoneType({ pantoneTypeID: Params.Id }));
                }
                else if (Params.Operation === 8) {
                    dispatch(deletePantone({ pantoneID: Params.Id }));
                }
                else if (Params.Operation === 24) {
                    dispatch(deleteCurrency({ currencyId: Params.Id }));

                    localStorage.removeItem('Search');
                    localStorage.removeItem('Pagination');
                }

                else if (Params.Operation === 2) {
                    dispatch(deleteCity({ cityID: Params.Id }));
                }
                else if (Params.Operation === 70) {
                    dispatch(deleteState({ StateID: Params.Id }));
                }
                else if (Params.Operation === 14) {
                    dispatch(deleteSize({ sizeDetailID: Params.Id }));
                }
                else if (Params.Operation === 15) {
                    dispatch(deleteFabricType({ fabricDetailsID: Params.Id }));
                }
                else if (Params.Operation === 9) {
                    dispatch(deleteGrouptype({ groupTypeID: Params.Id }));
                }
                else if (Params.Operation === 13) {
                    dispatch(deleteCategory({ categoryID: Params.Id }));
                }
                else if (Params.Operation === 11) {
                    dispatch(deleteStrikeofftype({ strikeOffTypeID: Params.Id }));
                }
                else if (Params.Operation === 12) {
                    dispatch(deleteTrimtype({ trimTypeID: Params.Id }));
                }
                else if (Params.Operation === 10) {
                    dispatch(deleteMeasurementpoint({ measurePointID: Params.Id }));
                }
                else if (Params.Operation === 32) {
                    dispatch(deleteSwatch({ swatchMasterID: Params.Id }));
                }
                else if (Params.Operation === 35) {
                    dispatch(deleteFabricPart({ fabricPartID: Params.Id }));
                }
                else if (Params.Operation === 40) {
                    dispatch(deleteProcessType({ processTypeID: Params.Id }));
                }
                else if (Params.Operation === 41) {
                    dispatch(deleteMasterSize({ MasterSizeID: Params.Id }));
                }
                else if (Params.Operation === 42) {
                    dispatch(deletegeneralLabdip({ genLapdipProgramInfoId: Params.Id }));
                }
                else if (Params.Operation === 44) {
                    dispatch(GetGeneralLapdipApprovalList(searchParams));
                }
                else if (Params.Operation === 45) {
                    dispatch(GetGeneralLapdipApprovalViewList(searchParams));
                }
                else if (Params.Operation === 46) {
                    dispatch(GetGeneralLapdipSubmissionApprovalList(searchParams));
                }
                else if (Params.Operation === 47) {
                    // dispatch(deletegeneralStrikeOff(searchParams));
                }
                else if (Params.Operation === 48) {
                    let addParam = { GenStrikeOffDetailTNAId: data.genStrikeOffDetailTNAId };
                    dispatch(RemoveStrikeOffRequestRow({ searchParams, ...addParam }));
                }
                else if (Params.Operation === 49) {
                    dispatch(GetGeneralStrikeOffApprovalList(searchParams));
                }
                else if (Params.Operation === 50) {
                    dispatch(GetGeneralStrikeOffApprovalViewList(searchParams));
                }
                else if (Params.Operation === 51) {
                    // dispatch(deletegeneralTrim(searchParams));
                }
                else if (Params.Operation === 52) {
                    dispatch(RemoveTrimRequestRow(searchParams));
                }
                else if (Params.Operation === 53) {
                    dispatch(GetGeneralTrimApprovalList(searchParams));
                }
                else if (Params.Operation === 54) {
                    dispatch(GetGeneralTrimApprovalViewList(searchParams));
                }
                else if (Params.Operation === 55) {
                    dispatch(LoadCourierDetails(searchParams));
                }
                else if (Params.Operation === 56) {
                    dispatch(deleteYarnType({ yarnTypeID: Params.Id }))
                }
                else if (Params.Operation === 57) {
                    dispatch(LoadAgencyDetails(searchParams));
                }
                else if (Params.Operation === 58) {

                    dispatch(deleteMailAttachment({ attachmentId: Params.Id }));
                }
                else if (Params.Operation === 106) {
                    dispatch(deleteQCCommentPoint({ QCCommentPoint: Params.Id }));
                }
                else if (Params.Operation === 108) {
                    dispatch(deleteTestItem({ TestItem: Params.testItemID }));
                }
                else if (Params.Operation === 121) {
                    dispatch(deleteBrandWiseStandard({ BrandWiseStandardID: Params.Id }));
                }

                else if (Params.Operation === 121) {
                    dispatch(deleteAlignent({ ID: Params.Id }));
                }
                if (Params.Operation === 130) {
                    dispatch(deleteReportModuleCategory({ reportModuleCategoryID: Params.Id }));
                }
                if (Params.Operation === 131) {
                    dispatch(deleteReporttemplate({ reportTemplateID: Params.Id }));
                }

                if (Params.Operation === 132) {
                    dispatch(deleteColumnInforamtion({ columnInformationID: Params.Id }));
                }
                if (Params.Operation === 133) {
                    dispatch(deleteProductFabricType({ fabricDetailsID: Params.Id }));
                }
                if (Params.Operation === 134) {
                    dispatch(deleteProductContSpec({ contSpecID: Params.Id }));
                }
                if (Params.Operation === 135) {
                    dispatch(deleteProductFabricStructure({ structureID: Params.Id }));
                }
                if (Params.Operation === 136) {
                    dispatch(deleteProductAdditionalInfo({ infoID: Params.Id }));
                }
                //  else if (Params.Operation === 59 || Params.Operation === 60 || Params.Operation === 61 || Params.Operation === 62 || Params.Operation === 63 || Params.Operation === 64) {
                //;
                //   dispatch(loadTNADetailList({ TNAId: data.TNAId }));

                //  }

            }
            else if (res.data.outputResult === "-2") {
                Title = "Warning!";
                message = DeleteModule + " is already mapped.";
                type = "warning";
            }

            store.addNotification({
                title: Title,
                message: message,
                type: type,
                insert: "top",
                container: "top-right",
                animationIn: [
                    "animate__animated", "animate__fadeIn"
                ],
                animationOut: [
                    "animate__animated", "animate__fadeOut"
                ],
                dismiss: {
                    duration: 1050,
                    onScreen: true
                }
            });
            setTimeout(() => {
                // window.location.reload(false);
                if (pageActionId === 39) { deleteCallback(data); }
                else if (pageActionId === 45) { deleteCallback(true, data); }
                else { deleteCallback(true); }
            }, 2000);
        });
    }

    return (
        <SweetAlert
            show={show}
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="primary"
            // closeOnConfirm
            // closeOnCancel

            title="Are you sure do you want to delete it?"
            onConfirm={deleteRow}
            onCancel={handleClose}
            showCloseButton

            focusCancelBtn >

            {/* I did it! */}
        </SweetAlert >
    )
};

export default SweetAlertPopup;