import React, { useState, useEffect, useMemo,Fragment } from "react";
import { TableHeader, Pagination, Search, PageCount } from "../../Datatable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { Redirect } from 'react-router-dom';
//import { useSelector } from "react-redux";
import Nodify from "../../Common/ReactNotification";
import BuyerService from "../../../services/Master/BuyerService";
import SweetAlertPopup from '../../Common/SweetAlertPopup';
import { BuyerInfoActionId } from "../../Common/PageActionNumber";
import SearchRetain from "../../Common/SearchRetain"
import CommonService from '../../../services/Common/CommonService';
import { useDispatch, useSelector } from "react-redux";
import { retrieveBuyerinfo } from "../../../actions/buyerinfo"


const BuyerList = (props) => { // Selecters
    // const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(5);
    // const { user: currentUser } = useSelector((state) => state.auth);
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [expandBuyerList, setexpandBuyerList] = useState({});
    const [getID, setID] = useState(0);
    const [getPlaceholder] = useState("Buyer / Reference / Brand / End Customer / Story / Theme ");


    const dispatch = useDispatch();

    //Enable Menu
    let activeMenu;
    const menulist = useSelector((state) => state.common.MenuList.item3);
    if (menulist !== undefined) { activeMenu = menulist.filter(i => i.menuName === "BuyerInfo"); }
    // End Enable Menu

    //reducer
    const reducerState = useSelector((state) => state);
    const { user: currentUser } = reducerState.auth;
    const comments = reducerState.buyerinfo.buyerinfoList;
    let isLoadingBuyerinfo = reducerState.buyerinfo.isLoadingBuyerinfo;

    const BuyerBrandDetails = reducerState.auth.UserMenuList.item6 !== undefined ? reducerState.auth.UserMenuList.item6 : [];

    useEffect(() => {
        // showLoader();
        // setTimeout(() => {
        //     BuyerService.GetBuyerInfoList(1, 0, 0).then((response) => {
        //         if (response.data) {

        //             setComments(response.data);
        //             hideLoader();
        dispatch(retrieveBuyerinfo());
        const state1 = props.location.state
        if (state1 !== undefined && state1 != null) {
            let Msg;
            let Type = 'success'; //default, info, warning
            let Title = 'Success!';
            if (state1.message === 'Edit') {
                Msg = 'Buyer information updated successfully.';
                // Type = 'default';
            } else if (state1.message === 'Add') {
                Msg = 'Buyer information added successfully.';
            } else {
                Msg = 'Error occured';
                Type = 'warning';
                Title = 'Error!';
            }
            window.history.replaceState(null, '')
            Nodify(Title, Type, Msg);
        }
        var page = getPlaceholder;
        var setConstraint = SearchRetain(page);
        if (setConstraint !== undefined) {
            setCurrentPage(setConstraint.CurrentPage);
            setITEMS_PER_PAGE(setConstraint.PageItems);
        }
        //         } else {
        //             hideLoader();
        //         }
        //     }).catch(() => {
        //         hideLoader();
        //     });
        // }, 500)
    }, [dispatch]);

    useEffect(() => {
        if (!isLoadingBuyerinfo) {
            hideLoader();
        } else {
            showLoader();
        }
    }, [isLoadingBuyerinfo]);


    const headers = [
        {
            name: "",
            field: "",
            sortable: false,
            widthsize:'2%',
        },
        {
            name: "#",
            field: "",
            sortable: false,
            widthsize:'3%',
        },
        {
            name: "Buyer Name",
            field: "buyerName",
            sortable: true,
            widthsize: "40%"
        },
        {
            name: " Reference Name",
            field: "buyerReferenceName",
            sortable: true,
            widthsize: "40%"
        },
        {
            name: "Action",
            widthsize: "7%",
            //field: "buyerStatusName",
            //sortable: true
            display: activeMenu && activeMenu[0].isEdit === 0 &&
                activeMenu[0].isDelete === 0 ? 'none' : ''
        },

    ];

    const childHeaders = [

        // {
        //     name: "#",
        //     field: "",
        //     sortable: false,
        //     // widthsize:'120px',
        // },
        {
            name: "Brand",
            sortable: false,
            widthsize: "5%"
        },
        {
            name: "End Customer",
            sortable: false,
            widthsize: "5%"
        },
        {
            name: "Story",
            sortable: false,
            widthsize: "5%"
        },
        {
            name: "Theme",
            sortable: false,
            widthsize: "5%"
        },
    ];

    const commentsData = useMemo(() => {
        // let computedComments = comments;

        let computedComments = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
            o.buyerID === buyerId && isAllInfo === 1 && isMapped === 0));
           let computedCommentsBuyerDetails = comments.filter(o => BuyerBrandDetails.some(({ brandId, buyerId, isAllInfo, isMapped }) =>
           isAllInfo === 0 && isMapped === 1 
           //&& o.cubeEmployeeIdList != undefined
            && o.cubeEmployeeIdList.split(',').includes(currentUser.employeeinformationID.toString())));
           debugger;
            if (computedCommentsBuyerDetails.length > 0) {
            computedComments = [...computedComments, ...computedCommentsBuyerDetails];
        }
        if (search) {
            computedComments = computedComments.filter(comment =>
            (comment.buyerName.toLowerCase().includes(search.toLowerCase())
                || comment.buyerReferenceName.toLowerCase().includes(search.toLowerCase())
                || comment.brand.toLowerCase().includes(search.toLowerCase())
                || comment.endCustomer.toLowerCase().includes(search.toLowerCase())
                || comment.story.toLowerCase().includes(search.toLowerCase())
                || comment.theme.toLowerCase().includes(search.toLowerCase())
            ));
        }
        setTotalItems(computedComments.length);
        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort((a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field]));
        }
        // Current Page slice
        let records = computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
        if (records.length === 0 && currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
        return computedComments.slice((currentPage - 1) * parseInt(ITEMS_PER_PAGE), (currentPage - 1) * parseInt(ITEMS_PER_PAGE) + parseInt(ITEMS_PER_PAGE));
    }, [
        comments,
        currentPage,
        search,
        sorting,
        ITEMS_PER_PAGE
    ]);

    if (!currentUser) {
        return <Redirect to="/login" />;
    }

    //Actions
    const pageRedirect = (buyerinfoParams, redirect, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));
        if (redirect === 'Add') {
            props.history.push('/MapItemsBuyer');
        }
        else if (redirect === 'Edit') {
            props.history.push(`/MapBuyerInfoRegister`, { params: buyerinfoParams });
        }
        else if (redirect === 'View') {
            buyerinfoParams.action = "View";
            props.history.push(`/MapBuyerInfoRegister`, { params: buyerinfoParams });
        }
        else {

            let Params = {
                Operation: BuyerInfoActionId,
                Id: buyerinfoParams.buyerMapItemID,
            };
            CommonService.CheckMappedItem(Params).then(res => {
                if (res.data.outputResult === "-2") {
                    let Title = "Warning!";

                    let message = "This " + buyerinfoParams.mapingName + " is already mapped.";
                    let type = "warning";

                    Nodify(Title, type, message);
                }
                else {
                    setID({ showPopupDelete: true, Params: buyerinfoParams });
                }
            });
        }


        // else {
        //     setID({ showPopupDelete: true, Params: buyerinfoParams });
        // }
    };

    const AddNewInfo = (BuyerMapId, buyerinfoParams, page) => {
        localStorage.setItem('Pagination', JSON.stringify({ CurrentPage: currentPage, Page: page, PageItems: ITEMS_PER_PAGE }));

        let BuyerInfoValue = {
            buyerInfoID: 0,
            buyerID: buyerinfoParams.buyerID,
            buyerMapID: BuyerMapId,
            mapingTextName: "",
            isStoryMandatory: 0,
            isThemeMandatory: 0,
            isPOFocusPONo: 0,
            buyerInfoStatus: 1,
            buyerMapItemID: 0,
        };
        if (BuyerMapId === 0) {
            props.history.push(`/MapItemsBuyer`, { params: buyerinfoParams });
        }
        else {
            props.history.push(`/MapBuyerInfoRegister`, { params: BuyerInfoValue });
        }
    }

    const handleExpandRow = (buyerID) => {
        setExpandedRows([]);
        BuyerService.GetBuyerInfoList(2, buyerID, 0).then((response) => {
            if (response.data) {
                setexpandBuyerList(response.data);
                // Expand row Start
                let currentExpandedRows = expandedRows;
                const isRowExpanded = currentExpandedRows.includes(buyerID);
                let obj = {};
                isRowExpanded ? (obj[buyerID] = false) : (obj[buyerID] = true);
                setExpandState(obj);
                currentExpandedRows = [];
                const newExpandedRows = isRowExpanded ?
                    currentExpandedRows.filter(id => id !== buyerID) :
                    currentExpandedRows.concat(buyerID);
                setExpandedRows(newExpandedRows);
                // Expand row End
            }
        });
    }

    const Delcallback = (value) => {
        dispatch(retrieveBuyerinfo());
        // if (value === true) {
        //     BuyerService.GetBuyerInfoList(1, 0, 0).then((response) => {
        //         if (response.data) {
        //             setComments(response.data);
        //         }
        //     });
        //     const BuyerID = expandBuyerList[0].buyerID;
        //     // handleExpandRow(BuyerID);
        //     BuyerService.GetBuyerInfoList(2, BuyerID, 0).then((response) => {
        //         if (response.data) {
        //             setexpandBuyerList(response.data);
        //             // Expand row Start
        //             let currentExpandedRows = expandedRows;
        //             const isRowExpanded = false;
        //             let obj = {};
        //             obj[BuyerID] = true;
        //             setExpandState(obj);
        //             currentExpandedRows = [];
        //             const newExpandedRows = isRowExpanded ?
        //                 currentExpandedRows.filter(id => id !== BuyerID) :
        //                 currentExpandedRows.concat(BuyerID);
        //             setExpandedRows(newExpandedRows);
        //             // Expand row End
        //         }
        //     });
        // }
        setID({ showPopupDelete: false, Params: [] });
        //window.location.reload(); // Commented on 14dec2023 (Bug ID : 34780)
    }

    return (
        <>
            <div className="page-body">
                <ReactNotification />
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div className="widget">
                            <div className="widget-header ">
                                <span className="widget-caption">Buyer Information</span>
                            </div>

                            <div className="widget-body">
                                <div className="dataTables_wrapper form-inline no-footer">
                                    <div className="table-toolbar pull-left">
                                        <Search onSearch={
                                            value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }
                                        }
                                            placeholder={getPlaceholder} props={props} />

                                    </div>
                                    <div className="table-toolbar pull-right" style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }}>
                                        &nbsp;

                                        <span className="btn btn-primary" onClick={() => pageRedirect('', 'Add', getPlaceholder)} title="Add Buyer Info">
                                            <i className="fa fa-plus"></i>&nbsp;Add
                                        </span>
                                    </div>


                                    <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable">
                                        <TableHeader headers={headers}
                                            onSorting={
                                                (field, order) => setSorting({ field, order })
                                            } />
                                        <tbody>
                                            {
                                                commentsData.map((comment, i) => (
                                                    <Fragment key={i}>
                                                    <>
                                                   
                                                        <tr>
                                                        <td>
                                                                <i
                                                                    className={
                                                                        expandState[comment.buyerID] ?
                                                                            'fa fa-minus-square-o clsPointer clscollapseexpand' : 'fa fa-plus-square-o clsPointer clscollapseexpand'
                                                                    }
                                                                    onClick={event => handleExpandRow(comment.buyerID)}
                                                                ></i>
                                                               
                                                            </td>
                                                            <td style={{width:'5px'}}>
                                                                {i+1}
                                                            </td>
                                                            <td>
                                                               
                                                                <span className="BuyerPaddingLeft">
                                                                    {
                                                                        comment.buyerName
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td>{
                                                                comment.buyerReferenceName
                                                            }</td>
                                                            <td style={{
                                                                display: activeMenu && activeMenu[0].isEdit === 0 &&
                                                                    activeMenu[0].isDelete === 0 ? 'none' : ''
                                                            }}>
                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Buyer' onClick={() => AddNewInfo(0, comment)}
                                                                    className="btn btn-success btn-xs edit">
                                                                    <i className="fa fa-edit "></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                       
                                                        <>
                                                            {
                                                                expandedRows.includes(comment.buyerID) ?
                                                                    <tr>
                                                                        <td colSpan="5">
                                                                            <table className="table table-bordered table-striped table-condensed flip-content" id="editabledatatable1">
                                                                                <TableHeader headers={childHeaders}
                                                                                    onSorting={
                                                                                        (field, order) => setSorting({ field, order })
                                                                                    } />
                                                                                <tbody>
                                                                                    {
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    {expandBuyerList.filter(x => x.buyerMapID === 1).length !== 0 && (
                                                                                                        <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                            <tbody>{

                                                                                                                expandBuyerList.filter(x => x.buyerMapID === 1).map((BuyerInfo, index) => (
                                                                                                                    <>
                                                                                                                   
                                                                                                                        <tr>
                                                                                                                            {/* <td>
                                                                                                                            {index + 1}
                                                                                                                            </td> */}
                                                                                                                            <td className="clsRmvBorder">{BuyerInfo.mapingTextName}</td>
                                                                                                                            <td className="clstdAction" style={{ display: activeMenu && activeMenu[0].isEdit === 0 && activeMenu[0].isDelete === 0 ? 'none' : '' }}>
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Brand' onClick={() => pageRedirect(BuyerInfo, 'Edit', getPlaceholder)}
                                                                                                                                    className="btn btn-success btn-xs add clsPointer">
                                                                                                                                    <i className="fa fa-edit "></i>
                                                                                                                                </span>
                                                                                                                                &nbsp;
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Brand' onClick={() => pageRedirect(BuyerInfo, '', getPlaceholder)}
                                                                                                                                    className="btn btn-danger btn-xs delete clsPointer">
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </td>

                                                                                                                        </tr>

                                                                                                                    </>
                                                                                                                ))
                                                                                                            }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    )}
                                                                                                    {
                                                                                                        expandBuyerList.filter(x => x.buyerMapID === 1).length === 0 && (
                                                                                                            <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td className="clsRmvBorder">-</td>
                                                                                                                        <td className="clstdAction">
                                                                                                                            <span style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} title='Add Brand' onClick={() => AddNewInfo(1, comment)}
                                                                                                                                className="btn btn-success btn-xs plus">
                                                                                                                                <i className="fa fa-plus clsPointer"></i>
                                                                                                                            </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {expandBuyerList.filter(x => x.buyerMapID === 2).length !== 0 && (
                                                                                                        <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                            <tbody>{
                                                                                                                expandBuyerList.filter(x => x.buyerMapID === 2).map((BuyerInfo, index) => (
                                                                                                                    <>

                                                                                                                        <tr>
                                                                                                                            <td className="clsRmvBorder">{BuyerInfo.mapingTextName}</td>
                                                                                                                            <td className="clstdAction">
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit End Customer' onClick={() => pageRedirect(BuyerInfo, 'Edit', getPlaceholder)}
                                                                                                                                    className="btn btn-success btn-xs add clsPointer">
                                                                                                                                    <i className="fa fa-edit "></i>
                                                                                                                                </span>
                                                                                                                                &nbsp;
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete End Customer' onClick={() => pageRedirect(BuyerInfo, '', getPlaceholder)}
                                                                                                                                    className="btn btn-danger btn-xs delete clsPointer">
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </td>

                                                                                                                        </tr>

                                                                                                                    </>
                                                                                                                ))
                                                                                                            }</tbody>
                                                                                                        </table>
                                                                                                    )}
                                                                                                    {
                                                                                                        expandBuyerList.filter(x => x.buyerMapID === 2).length === 0 && (
                                                                                                            <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td className="clsRmvBorder">-</td>
                                                                                                                        <td className="clstdAction">  <span style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} title='Add End Customer' onClick={() => AddNewInfo(2, comment)}
                                                                                                                            className="btn btn-success btn-xs plus">
                                                                                                                            <i className="fa fa-plus clsPointer"></i>
                                                                                                                        </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {expandBuyerList.filter(x => x.buyerMapID === 3).length !== 0 && (
                                                                                                        <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                            <tbody>{
                                                                                                                expandBuyerList.filter(x => x.buyerMapID === 3).map((BuyerInfo, index) => (
                                                                                                                    <>

                                                                                                                        <tr>
                                                                                                                            <td className="clsRmvBorder">{BuyerInfo.mapingTextName}
                                                                                                                            </td>
                                                                                                                            <td className="clstdAction">
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Story' onClick={() => pageRedirect(BuyerInfo, 'Edit', getPlaceholder)}
                                                                                                                                    className="btn btn-success btn-xs add clsPointer">
                                                                                                                                    <i className="fa fa-edit "></i>
                                                                                                                                </span>
                                                                                                                                &nbsp;
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Story' onClick={() => pageRedirect(BuyerInfo, '', getPlaceholder)}
                                                                                                                                    className="btn btn-danger btn-xs delete clsPointer">
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </td>

                                                                                                                        </tr>
                                                                                                                    </>
                                                                                                                ))
                                                                                                            }</tbody>
                                                                                                        </table>
                                                                                                    )}
                                                                                                    {
                                                                                                        expandBuyerList.filter(x => x.buyerMapID === 3).length === 0 && (
                                                                                                            <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td className="clsRmvBorder">-</td>
                                                                                                                        <td className="clstdAction">  <span style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} title='Add Story' onClick={() => AddNewInfo(3, comment)}
                                                                                                                            className="btn btn-success btn-xs plus">
                                                                                                                            <i className="fa fa-plus clsPointer"></i>
                                                                                                                        </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        )
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {expandBuyerList.filter(x => x.buyerMapID === 4).length !== 0 && (
                                                                                                        <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                            <tbody>{
                                                                                                                expandBuyerList.filter(x => x.buyerMapID === 4).map((BuyerInfo, index) => (
                                                                                                                    <>

                                                                                                                        <tr>
                                                                                                                            <td className="clsRmvBorder">{BuyerInfo.mapingTextName}
                                                                                                                            </td>
                                                                                                                            <td className="clstdAction">
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isEdit === 1 ? '' : 'none' }} title='Edit Theme' onClick={() => pageRedirect(BuyerInfo, 'Edit', getPlaceholder)}
                                                                                                                                    className="btn btn-success btn-xs add clsPointer">
                                                                                                                                    <i className="fa fa-edit "></i>
                                                                                                                                </span>
                                                                                                                                &nbsp;
                                                                                                                                <span style={{ display: activeMenu && activeMenu[0].isDelete === 1 ? '' : 'none' }} title='Delete Theme' onClick={() => pageRedirect(BuyerInfo, '', getPlaceholder)}
                                                                                                                                    className="btn btn-danger btn-xs delete clsPointer">
                                                                                                                                    <i className="fa fa-trash-o"></i>
                                                                                                                                </span>
                                                                                                                            </td>

                                                                                                                        </tr>

                                                                                                                    </>
                                                                                                                ))
                                                                                                            }</tbody>
                                                                                                        </table>
                                                                                                    )}
                                                                                                    {
                                                                                                        expandBuyerList.filter(x => x.buyerMapID === 4).length === 0 && (
                                                                                                            <table className="table table-hover table-striped table-condensed" style={{ width: "100%" }}>
                                                                                                                <tbody>
                                                                                                                    <tr>
                                                                                                                        <td className="clsRmvBorder">-</td>
                                                                                                                        <td className="clstdAction">  <span style={{ display: activeMenu && activeMenu[0].isAdd === 1 ? '' : 'none' }} title='Add Theme' onClick={() => AddNewInfo(4, comment)}
                                                                                                                            className="btn btn-success btn-xs plus">
                                                                                                                            <i className="fa fa-plus clsPointer"></i>
                                                                                                                        </span></td>
                                                                                                                    </tr>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        )
                                                                                                    }
                                                                                                </td>

                                                                                            </tr>
                                                                                        </>

                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr> : null
                                                            }
                                                        </>
                                                    
                                                    </>
                                                    </Fragment>
                                                ))
                                               
                                            }
                                            {
                                                totalItems === 0 ?
                                                    <tr>
                                                        <td colSpan="5" className='norecordfound'><span>No Records Found</span></td>
                                                    </tr> : ''
                                            }
                                        </tbody>
                                    </table>

                                    <PageCount onPageCount={
                                        value => {
                                            setITEMS_PER_PAGE(value);
                                            setCurrentPage(1);
                                        }
                                    } />
                                    <Pagination total={totalItems}
                                        itemsPerPage={
                                            parseInt(ITEMS_PER_PAGE)
                                        }
                                        currentPage={currentPage}
                                        onPageChange={
                                            page => setCurrentPage(page)
                                        } />
                                </div>
                            </div>
                            {
                                getID.showPopupDelete ? <SweetAlertPopup data={
                                    getID.Params
                                }
                                    deleteCallback={Delcallback}
                                    showpopup={true}
                                    pageActionId={BuyerInfoActionId}
                                    Msg={"Buyer information deleted successfully."} /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    )
};

export default BuyerList;